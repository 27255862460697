import { useContext, useEffect, useState } from "react";
import { contextualConfig } from "../components/Editor/editor_config";
import { EditorContext } from "../containers/editor/EditorLayout";
import {
  calculateNewPositionOnRotatedObjectResize,
  getCssTextObjToString,
  getCssTransformObj,
  getUpdatedGroupInnerWidgetPos,
  getZoomedValue,
  updateHeightAndTopForInactiveGroupChildren,
} from "../_helpers/utils";
import UseCheckWidgetAllignment from "./UseCheckWidgetAllignment";

const useFontList = () => {
  let { metadata, widgets, updateWidgets, dimension } = useContext(EditorContext);

  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
  let groupWidgetInitHeight = isGroupWidget && parseFloat(isGroupWidget.style.height);

  const [listFontWidget, setListFontWidget] = useState(-1);

  const { checkWidgetAllignmentForSingleWidget } = UseCheckWidgetAllignment();

  const removeList = tag => {
    let newArray;
    let htmlTexts = document.querySelectorAll("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner li");

    //Remove ul or ol tags
    document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner ${tag}`).remove();

    //remove all li tag into ul or ol tag
    htmlTexts.forEach(function (text) {
      let innerNode = document.createElement("div");
      innerNode.innerHTML = text.innerHTML;
      document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`).appendChild(innerNode);
    });

    // if the text is under a group widget
    if (isGroupWidget) {
      // Get group updated height and inner widget updated top and height according group
      let { groupDivHeight, parcentHeight, parcentTop } = getUpdatedGroupInnerWidgetPos(
        targetId,
        id,
        document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight
      );

      // update DOM and group handler
      document.getElementById(targetId).style.height = `${groupDivHeight}px`;
      document.getElementById(id).style.height = parcentHeight;
      document.getElementById(id).style.top = parcentTop;
      document.getElementById(id).removeAttribute("data-subwidget");
      document.getElementById(id).innerHTML = document.querySelector("#" + id + " .dhp-widget-inner").outerHTML;
      document.getElementById("dhp-widget-handler").style.height = `${getZoomedValue(
        groupDivHeight,
        dimension.zoom
      )}px`;

      // update height and top for inactive child widgets
      updateHeightAndTopForInactiveGroupChildren({
        group: isGroupWidget,
        groupOldHeight: groupWidgetInitHeight,
        groupNewHeight: groupDivHeight,
        activeChildId: id,
      });

      newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgets[targetWidgetIndex],
          style: {
            ...widgets[targetWidgetIndex].style,
            height: `${groupDivHeight}px`,
          },
          innerHTML: document.getElementById(targetId).innerHTML,
        },
      });
    }
    // if the text is a main widget
    else {
      const newHeight = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight;
      const { x_al, y_al } = checkWidgetAllignmentForSingleWidget(newHeight);

      const widgetObject = widgets[targetWidgetIndex];
      const {
        translate: { x: widgetTransX, y: widgetTransY },
        rotate: { theta: widgetTheta },
      } = getCssTransformObj({
        transform: widgetObject.style.transform,
      });
      const { left, top } = calculateNewPositionOnRotatedObjectResize(
        parseFloat(widgetTransX),
        parseFloat(widgetTransY),
        parseFloat(widgetObject.style.width),
        parseFloat(newHeight),
        parseFloat(widgetObject.style.width),
        parseFloat(widgetObject.style.height),
        parseFloat(widgetTheta)
      );
      const transform = getCssTransformObj({
        translateX: `${left}px`,
        translateY: `${top}px`,
        transform: widgetObject.style.transform,
        returnStr: true,
      });

      //Remove data attr
      let modifiedArray = JSON.parse(JSON.stringify(widgets));
      delete modifiedArray[targetWidgetIndex].data["data-subwidget"];
      let newDataAttr = modifiedArray[targetWidgetIndex].data;

      //Update new node element
      newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgetObject,
          style: {
            ...widgetObject.style,
            height: `${newHeight}px`,
            transform,
          },
          data: {
            ...newDataAttr,
            "data-x-allignment": x_al,
            "data-y-allignment": y_al,
          },
          innerHTML: document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").outerHTML,
        },
      });
    }

    updateWidgets(newArray);
  };

  const updateList = listIndex => {
    let newArray;

    //Remove list if it's added previously
    if (
      document.getElementById(id).getAttribute("data-subwidget") &&
      document.getElementById(id).getAttribute("data-subwidget") === contextualConfig.List[listIndex].tag.toUpperCase()
    ) {
      removeList(contextualConfig.List[listIndex].tag);
    }
    //Apply list ul or ol
    else {
      let htmlTexts;
      let bulletApplied = document.getElementById(id).getAttribute("data-subwidget");

      if (bulletApplied)
        htmlTexts = document.querySelectorAll("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner li");
      else htmlTexts = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").childNodes;

      //Create ul or ol tag
      let innerNode = document.createElement(contextualConfig.List[listIndex].tag);
      innerNode.classList = contextualConfig.List[listIndex].class;
      innerNode.style.cssText = getCssTextObjToString(contextualConfig.List[listIndex].style);

      //Add li tag before every div into ul ot ol tag
      htmlTexts.forEach(function (text) {
        let li = document.createElement("li");
        li.innerHTML = bulletApplied ? text.innerHTML : text.outerHTML ? text.outerHTML : text.textContent;
        if (text.style.color) li.style.cssText = text.style.cssText;
        innerNode.appendChild(li);
      });

      //Insert ul or ol tag to widget-inner div
      let node = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner");
      node.innerHTML = innerNode.outerHTML;

      // if the text is under a group widget
      if (isGroupWidget) {
        // Get group updated height and inner widget updated top and height according group
        let { groupDivHeight, parcentHeight, parcentTop } = getUpdatedGroupInnerWidgetPos(
          targetId,
          id,
          document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight
        );

        // update DOM and group handler
        document.getElementById(targetId).style.height = `${groupDivHeight}px`;
        document.getElementById(id).style.height = parcentHeight;
        document.getElementById(id).style.top = parcentTop;
        document.getElementById(id).setAttribute("data-subwidget", contextualConfig.List[listIndex].tag.toUpperCase());
        document.getElementById(id).innerHTML = node.outerHTML;
        document.getElementById("dhp-widget-handler").style.height = `${getZoomedValue(
          groupDivHeight,
          dimension.zoom
        )}px`;

        // update height and top for inactive child widgets
        updateHeightAndTopForInactiveGroupChildren({
          group: isGroupWidget,
          groupOldHeight: groupWidgetInitHeight,
          groupNewHeight: groupDivHeight,
          activeChildId: id,
        });

        newArray = Object.assign([...widgets], {
          [targetWidgetIndex]: {
            ...widgets[targetWidgetIndex],
            style: {
              ...widgets[targetWidgetIndex].style,
              height: `${groupDivHeight}px`,
            },
            innerHTML: document.getElementById(targetId).innerHTML,
          },
        });
      }
      // if the text is a main widget
      else {
        const newHeight = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight;
        const { x_al, y_al } = checkWidgetAllignmentForSingleWidget(newHeight);

        const widgetObject = widgets[targetWidgetIndex];
        const {
          translate: { x: widgetTransX, y: widgetTransY },
          rotate: { theta: widgetTheta },
        } = getCssTransformObj({
          transform: widgetObject.style.transform,
        });
        const { left, top } = calculateNewPositionOnRotatedObjectResize(
          parseFloat(widgetTransX),
          parseFloat(widgetTransY),
          parseFloat(widgetObject.style.width),
          parseFloat(newHeight),
          parseFloat(widgetObject.style.width),
          parseFloat(widgetObject.style.height),
          parseFloat(widgetTheta)
        );
        const transform = getCssTransformObj({
          translateX: `${left}px`,
          translateY: `${top}px`,
          transform: widgetObject.style.transform,
          returnStr: true,
        });

        //Update new node element
        newArray = Object.assign([...widgets], {
          [targetWidgetIndex]: {
            ...widgetObject,
            style: {
              ...widgetObject.style,
              height: `${newHeight}px`,
              transform,
            },
            data: {
              ...widgetObject.data,
              "data-subwidget": contextualConfig.List[listIndex].tag.toUpperCase(),
              "data-x-allignment": x_al,
              "data-y-allignment": y_al,
            },
            innerHTML: node.outerHTML,
          },
        });
      }

      updateWidgets(newArray);
    }
  };

  useEffect(() => {
    if (listFontWidget >= 0 && metadata.activeWidgetId) {
      updateList(listFontWidget);
      setListFontWidget(-1);
    }
  }, [listFontWidget]);

  return setListFontWidget;
};

export default useFontList;
