import React from "react";
import cx from "classnames";
import global from "../../../scss/dhp.scss";
import dotsImage from "../../../assets/images/circles-menu.gif";

let style = Object.assign({}, global);

export const DotLoader = () => {
  return (
    <div className={cx(style["text-center"], style["my-5"])}>
      <span>
        <img src={dotsImage} alt="" className={style["dots-loading"]} width="30" />
      </span>
    </div>
  );
};
