import React, { useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import AssetName from "./Common/AssetName";
import AssetSearch from "./Common/AssetSearch";
import AssetCategory from "./Common/AssetCategory";
import AssetLists from "./Common/AssetLists";

let style = Object.assign({}, global);

const Shape = props => {
  const [keyword, setKeyword] = useState("");
  const [category, setCategory] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [isAssetFetched, setIsAssetFetched] = useState(false);

  return (
    <div className={cx(style["editor-asset-inner"], style["shape-asset"])}>
      <AssetName handleWidgetAction={props.handleWidgetAction} assetName={props.assetName} />
      <div className={style["pr-20"]}>
        <AssetSearch
          assetName={props.assetName}
          keyword={keyword}
          setKeyword={setKeyword}
          setCategory={setCategory}
          setPageNumber={setPageNumber}
          setIsAssetFetched={setIsAssetFetched}
        />

        <AssetCategory
          category={category}
          setCategory={setCategory}
          setKeyword={setKeyword}
          setPageNumber={setPageNumber}
          setIsAssetFetched={setIsAssetFetched}
        />
      </div>

      <AssetLists
        assetName={props.assetName}
        assetType={props.assetType}
        keyword={keyword}
        assetCategory={category}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        isAssetFetched={isAssetFetched}
        setIsAssetFetched={setIsAssetFetched}
      />
    </div>
  );
};

//Props type validation
Shape.propTypes = {
  handleWidgetAction: PropTypes.func.isRequired,
  assetName: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
};

export default Shape;
