import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";
import { customRound, operateStorageData } from "../_helpers/utils";
import { DOCUMENT_PLAY_DATA_STORAGE_KEY, PAGE_PLAY_DATA_STORAGE_KEY } from "../constants/editor";

const useDocumentPlay = () => {
  const {
    pageNodes,
    blockNodes,
    pagePlayData,
    setPagePlayData,
    whichPagePlaying,
    setWhichPagePlaying,
    documentPlayData,
    setDocumentPlayData,
    setPlayingPageIdx,
    metadata,
    updateMetadata,
    handleWidgetAction,
    isTimeLineViewOpen,
  } = useContext(EditorContext);
  const [action, setAction] = useState(null);
  const [nextPageToPlay, setNextPageToPlay] = useState({
    pageId: null,
    pageIdx: null,
    nextPageObj: null,
  });

  const getDocumentData = () => {
    let pageArray = [];
    let documentDurationMs = 0;

    pageNodes.forEach((pageObj, pageIdx) => {
      const blockObj = blockNodes[pageIdx];
      const pageDurationMs = parseFloat(pageObj.pageDuration) * 1000;
      const pageStartTimeMs = documentDurationMs;
      const pageStopTimeMs = pageStartTimeMs + pageDurationMs;
      const prevPageIdx = pageNodes?.[pageIdx - 1]?.pageIdx;
      const nextPageIdx = pageNodes?.[pageIdx + 1]?.pageIdx;

      documentDurationMs += pageDurationMs;
      pageArray = [
        ...pageArray,
        {
          pageId: pageObj.pageId,
          pageIdx: pageIdx,
          blockId: blockObj.blockId,
          blockIdx: pageIdx,
          pageDurationMs,
          pageStartTimeMs,
          pageStopTimeMs,
          prevPageIdx: prevPageIdx ?? null,
          nextPageIdx: nextPageIdx ?? null,
        },
      ];
    });

    return {
      documentDurationMs,
      firstPageObj: pageArray[0],
      pageArray,
      scrollData: getScrollData(),
    };
  };

  const getScrollData = () => {
    const scrollContainer = document.getElementById("timeline-body");
    const count = Math.ceil(scrollContainer.scrollWidth / scrollContainer.offsetWidth);
    const breakPoints = [...Array(count)].map((_, i) => scrollContainer.offsetWidth * i);
    const rangePoints = breakPoints.map((p, i) => {
      const nextPoint = breakPoints[i + 1];
      return {
        min: p,
        max: nextPoint ? nextPoint - 1 : scrollContainer.scrollWidth,
      };
    });

    return { rangePoints };
  };

  const updateCanvasControllerPageNo = ({ pageNo }) => {
    const displayNode = document.querySelector(".canvas-controller .current-page");
    if (displayNode) displayNode.innerText = pageNo;
  };

  const hideInactivePages = ({ activePageId }) => {
    document.querySelectorAll(".dhp-page-canvas").forEach(page => {
      page.style.display = page.id === activePageId ? "block" : "none";
    });
  };

  const disableAssetPane = () => {
    const assetWrapper = document.querySelector(".dhp-secondAside .assets-wrapper");
    if (assetWrapper) assetWrapper.style.display = "none";
    handleWidgetAction("*", false, false, 0, "returnToWidgetList");
  };

  const stopDocumentPlay = (delay = 0) => {
    const { firstPageObj } = getDocumentData();
    const scrollContainer = document.getElementById("timeline-body");
    setTimeout(() => {
      if (scrollContainer) {
        scrollContainer.scrollLeft = 0;
        scrollContainer.dataset.scrollLeft = 0;
      }
      updateCanvasControllerPageNo({ pageNo: firstPageObj.pageIdx + 1 });
      hideInactivePages({ activePageId: firstPageObj.pageId });
      setDocumentPlayData({
        action: null,
        pageId: null,
        pageIdx: null,
        documentTimer: null,
        documentProgress: 0,
        documentDurationMs: 0,
        pageArray: [],
        scrollData: null,
      });
      setNextPageToPlay({
        pageId: null,
        pageIdx: null,
        nextPageObj: null,
      });
    }, delay);
  };

  // send target page to a spacic playing state in pause mode
  const setTargetPagePlayToCustomTime = ({
    documentTimer,
    documentProgress,
    targetPageObj,
    targetPageTimer,
    targetPageProgress,
  }) => {
    setDocumentPlayData({
      ...documentPlayData,
      action: "pause",
      pageId: targetPageObj.pageId,
      pageIdx: targetPageObj.pageIdx,
      documentTimer: documentTimer - (documentTimer % 100),
      documentProgress,
    });
    setPagePlayData({
      ...pagePlayData,
      action: "pause",
      pageId: targetPageObj.pageId,
      pageIdx: targetPageObj.pageIdx,
      pageTimer: targetPageTimer - (targetPageTimer % 100),
      pageProgress: targetPageProgress,
    });
    setWhichPagePlaying({
      action: "pause",
      playingPageIdx: targetPageObj.pageIdx,
      triggerPoint: "slider",
    });
  };

  // update document-play as per playhead new position
  const documentPlayHeadHandler = ({ transX, scaleWidth }) => {
    const { documentDurationMs, pageArray } = documentPlayData;
    const documentDuration = documentDurationMs / 1000;
    const trackWidth = documentDuration * scaleWidth;
    const documentProgress = Math.round((transX * 100) / trackWidth);

    if (documentProgress >= 1 && documentProgress <= 99) {
      const documentTimer = (documentProgress * documentDurationMs) / 100;
      const targetPageObj = pageArray.find(
        p => documentTimer >= p.pageStartTimeMs && documentTimer <= p.pageStopTimeMs
      );
      const targetPageTimer = documentTimer - targetPageObj.pageStartTimeMs;
      const targetPageProgress = targetPageTimer * (100 / targetPageObj.pageDurationMs);

      // if playhead new position is inside active-page/playing-page
      if (targetPageObj.pageIdx === pagePlayData.pageIdx) {
        setTargetPagePlayToCustomTime({
          documentTimer,
          documentProgress,
          targetPageObj,
          targetPageTimer,
          targetPageProgress,
        });
      }
      // if playhead new position is outside active-page/playing-page
      else {
        // reset environments ready for target page to play
        updateCanvasControllerPageNo({ pageNo: targetPageObj.pageIdx + 1 });
        hideInactivePages({ activePageId: targetPageObj.pageId });
        setPlayingPageIdx(targetPageObj.pageIdx);

        setTargetPagePlayToCustomTime({
          documentTimer,
          documentProgress,
          targetPageObj,
          targetPageTimer,
          targetPageProgress,
        });
      }
    } else {
      // exit play mode if progress is 0 or 100
      setWhichPagePlaying({
        action: "force_stop",
        playingPageIdx: pagePlayData.pageIdx,
      });
    }
  };

  useEffect(() => {
    if (action === "play") {
      if (!documentPlayData.documentTimer) {
        // start document/page play from begining
        const { firstPageObj } = getDocumentData();
        const newPagePlayData = {
          action: "play",
          pageId: firstPageObj.pageId,
          pageIdx: firstPageObj.pageIdx,
        };

        // reset environments ready for document play
        disableAssetPane();
        setPlayingPageIdx(firstPageObj.pageIdx);
        updateMetadata({
          ...metadata,
          activePageId: firstPageObj.pageId,
          activePageIdx: firstPageObj.pageIdx,
          activeBlockId: firstPageObj.blockId,
          activeBlockIdx: firstPageObj.blockIdx,
          activeWidgetId: false,
          activeWidgetType: false,
          activeOutSideCanvasArea: true, // show page dimension and hide page background
        });

        // document play start
        setPagePlayData({
          ...pagePlayData,
          ...newPagePlayData,
        });
        operateStorageData({
          type: "session",
          action: "set",
          key: PAGE_PLAY_DATA_STORAGE_KEY,
          isParseable: true,
          defaultData: newPagePlayData,
        });
      } else {
        // resume document/page play where it left off
        const newPagePlayData = {
          action: "play",
          pageId: documentPlayData.pageId,
          pageIdx: documentPlayData.pageIdx,
        };
        setPagePlayData({
          ...pagePlayData,
          ...newPagePlayData,
        });
        operateStorageData({
          type: "session",
          action: "set",
          key: PAGE_PLAY_DATA_STORAGE_KEY,
          isParseable: true,
          defaultData: newPagePlayData,
        });
      }
    }

    if (action === "pause") {
      const newPagePlayData = {
        action: "pause",
        pageId: documentPlayData.pageId,
        pageIdx: documentPlayData.pageIdx,
      };
      setPagePlayData({
        ...pagePlayData,
        ...newPagePlayData,
      });
      operateStorageData({
        type: "session",
        action: "set",
        key: PAGE_PLAY_DATA_STORAGE_KEY,
        isParseable: true,
        defaultData: newPagePlayData,
      });
    }

    if (action === "natural_stop") {
      stopDocumentPlay();
      setWhichPagePlaying({
        action: "natural_stop",
      });
      setPlayingPageIdx(null);
    }

    if (action === "force_stop") {
      const newPagePlayData = {
        action: "force_stop",
        pageId: documentPlayData.pageId,
        pageIdx: documentPlayData.pageIdx,
      };
      stopDocumentPlay(300);
      setPagePlayData({
        ...pagePlayData,
        ...newPagePlayData,
      });
      operateStorageData({
        type: "session",
        action: "set",
        key: PAGE_PLAY_DATA_STORAGE_KEY,
        isParseable: true,
        defaultData: newPagePlayData,
      });
      setPlayingPageIdx(null);
    }
  }, [action]);

  useLayoutEffect(() => {
    setAction(documentPlayData.action);
  }, [documentPlayData.action]);

  useEffect(() => {
    const storage_data = operateStorageData({
      type: "session",
      action: "get",
      key: DOCUMENT_PLAY_DATA_STORAGE_KEY,
      isParseable: true,
      defaultData: {
        action: "",
      },
    });

    if (nextPageToPlay.pageId && storage_data.action === "play") {
      const { pageId, pageIdx, nextPageObj } = nextPageToPlay;
      const newPagePlayData = { action: "play", pageId, pageIdx };

      // reset environments ready for next page to play
      updateCanvasControllerPageNo({ pageNo: nextPageObj.pageIdx + 1 });
      hideInactivePages({ activePageId: nextPageObj.pageId });
      setPlayingPageIdx(nextPageObj.pageIdx);

      // set page play instructions
      setPagePlayData({
        ...pagePlayData,
        ...newPagePlayData,
      });
      operateStorageData({
        type: "session",
        action: "set",
        key: PAGE_PLAY_DATA_STORAGE_KEY,
        isParseable: true,
        defaultData: newPagePlayData,
      });

      // set document play instructions
      setDocumentPlayData({
        ...documentPlayData,
        ...newPagePlayData,
      });
      operateStorageData({
        type: "session",
        action: "set",
        key: DOCUMENT_PLAY_DATA_STORAGE_KEY,
        isParseable: true,
        defaultData: newPagePlayData,
      });
    }
  }, [nextPageToPlay.pageId]);

  useEffect(() => {
    const storage_data = operateStorageData({
      type: "session",
      action: "get",
      key: DOCUMENT_PLAY_DATA_STORAGE_KEY,
      isParseable: true,
      defaultData: {
        action: "",
      },
    });

    if (
      documentPlayData.action &&
      isTimeLineViewOpen === "document-timeline" &&
      whichPagePlaying?.triggerPoint !== "slider" &&
      storage_data.action === "play"
    ) {
      const { documentDurationMs, pageArray, scrollData } = getDocumentData();
      const { prevPageIdx, nextPageIdx } = pageArray?.[pagePlayData.pageIdx] ?? {};
      const nextPageObj = pageArray?.[nextPageIdx];

      if (pagePlayData.pageTimer > 0 && pagePlayData.pageTimer <= pagePlayData.pageDurationMs) {
        // update document timer and progress on each page play interval of 100ms
        let timer = 0;
        let progress = 0;

        pageArray.forEach((pageObj, idx) => {
          if (prevPageIdx >= 0 && idx < pagePlayData.pageIdx) timer += pageObj.pageDurationMs;
        });

        timer += pagePlayData.pageTimer;
        progress = customRound((100 / documentDurationMs) * timer, 2);

        setDocumentPlayData({
          ...documentPlayData,
          pageId: pagePlayData.pageId,
          pageIdx: pagePlayData.pageIdx,
          documentTimer: timer,
          documentProgress: progress,
          documentDurationMs,
          pageArray,
          scrollData,
        });
      }

      if (pagePlayData.pageTimer === pagePlayData.pageDurationMs && storage_data.action === "play") {
        // at end of each page duration
        if (nextPageIdx) {
          // activating next page to play
          setTimeout(() => {
            setNextPageToPlay({
              pageId: nextPageObj.pageId,
              pageIdx: nextPageObj.pageIdx,
              nextPageObj,
            });
          }, 30);
        } else {
          // natural end of document play
          setDocumentPlayData({
            ...documentPlayData,
            action: "natural_stop",
          });
        }
      }
    }
  }, [pagePlayData.pageTimer]);

  return { documentPlayHeadHandler };
};

export default useDocumentPlay;
