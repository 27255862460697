import React, { useState, useEffect, useRef, useContext, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../scss/dhp.scss";

import { Icon } from "../ui/icon";
import { fetchDocumentDetails } from "../../store/actions/documentActions";
import { EditorContext } from "../../containers/editor/EditorLayout";
import { TYPE_PRESENTATION } from "../../constants/editor";
import EditorCanvasPanel from "../../containers/editor/EditorCanvasPanel";
import CanvasPageHtmlGenaretor from "../Editor/CanvasPageHtmlGenaretor";

let style = Object.assign({}, global);

const PageList = ({ setSlideIndex, slideIndex, fullScreen, published, component, docPagesMeta }) => {
  let { metadata, documentType, pageNodes, dimension } = useContext(EditorContext);
  let pageType = documentType === TYPE_PRESENTATION ? "Slide" : "Page";

  const dispatch = useDispatch();
  const { recordId } = useParams();
  const container = useRef(null);

  const [showList, setShowList] = useState(false);
  const [totalPageNumber, setTotalPageNumber] = useState();
  const [activePageNumber, setActivePageNumber] = useState();
  const [activePageListHover, setActivePageListHover] = useState(false);
  const [hide, setHide] = useState(false);
  const [scaleFactor, setScaleFactor] = useState();

  const { documentDetails } = useSelector(state => state.document);
  const docHtml = published ? documentDetails?.document?.rawdata?.contexts?.pageNodes : documentDetails?.data?.version?.rawdata?.contexts?.pageNodes;

  const pageSorterScreenWidth = 144;
  const pageSorterMaxheight = 200;
  const pageSorterMinHeight = 120;

  //Set Page Block scale properties in page sorter list
  const resizePage = () => {
    let pageListContainerHeight;

    let pageSorterScreenHeight =
      window.innerHeight > pageSorterMaxheight
        ? window.innerHeight - pageSorterMaxheight
        : window.innerHeight - pageSorterMinHeight;

    let totalPages = component === "Editor" ? pageNodes.length : docHtml.length;

    let docWidth =
      component === "Editor"
        ? dimension.width
        : parseFloat(docPagesMeta.dimension.width);

    let docHeight =
      component === "Editor"
        ? dimension.height
        : parseFloat(docPagesMeta.dimension.height);

    let scaledval = pageSorterScreenWidth / docWidth;
    setScaleFactor(scaledval);
    let translateX = (pageSorterScreenWidth - docWidth * scaledval) / 2;

    // NOTE:::: determining the height of scrollable container (ul) based on the component
    if (component === "Editor") {
      pageListContainerHeight = pageSorterScreenHeight - pageSorterMinHeight;
    } else {
      pageListContainerHeight =
        (docHeight * scaledval + 36) * totalPages > pageSorterScreenHeight - pageSorterMinHeight
          ? pageSorterScreenHeight - pageSorterMinHeight //(pageSorterScreenHeight * 0.75)
          : (docHeight * scaledval + 36) * totalPages + 20;
    }

    if (component === "Present") {
      [...document.querySelectorAll("#pagelist-container .dhp-page-canvas")]?.forEach(page => {
        page.style.transform = `scale(${scaledval}) translateX(${translateX}px)`;
        page.style.transformOrigin = "0 0";
        page.parentElement.style.height = `${docHeight * scaledval}px`;
        page.parentElement.style.width = `${docWidth * scaledval}px`;
      });
    }

    container.current.style.height = `${pageListContainerHeight}px`;
  };

  //Set active page from page sorter list from document present
  const activePage = pageId => {
    for (let i = 0; i < docHtml.length; i++) {
      if (i === pageId) document.getElementById(`fig-${i}`).classList.add("active");
      else document.getElementById(`fig-${i}`).classList.remove("active");
    }
    setSlideIndex(pageId + 1);
  };

  //close Page sorter list on outside click
  const togglePageList = e => {
    if (component !== "Editor") return;

    if (e.target.type === "submit" && e.target?.innerText === "Delete") {
      setTimeout(() => {
        setShowList(!showList);
      }, 100);
    } else {
      setShowList(!showList);
    }
  };

  const handleMouseLeave = () => {
    setActivePageListHover(false);
    document.querySelector(".page-sorter-controller").classList.remove("show-page-list-controller");
  };

  //Page list scroll event
  const scrolling = () => {
    if (component !== "Editor") return;

    setActivePageListHover(false);
    document.querySelector(".page-sorter-controller").classList.remove("show-page-list-controller");
  };

  const stopAllPageListVideosBuffering = () => {
    [...document.querySelectorAll(".pagelist-container video")]?.forEach(video => (video.src = ""));
  };

  const toggleDropdown = () => {
    if (!showList && !hide) setShowList(true);
    else setHide(!hide);
  };

  useEffect(() => {
    if (slideIndex) activePage(slideIndex - 1);
  }, [slideIndex]);

  useLayoutEffect(() => {
    if (showList) {
      if (component === "Editor") {
        setTotalPageNumber(pageNodes.length);
        setActivePageNumber(metadata.activePageIdx + 1);
      } else {
        setTotalPageNumber(docHtml.length);
        setActivePageNumber(slideIndex);
      }
    }
  }, [showList, metadata?.activePageIdx, slideIndex]);

  useLayoutEffect(() => {
    if (showList) {
      resizePage();

      if (component === "Editor")
        document.getElementById("pagelist-container").addEventListener("mouseleave", handleMouseLeave);
      window.addEventListener("resize", resizePage);

      return () => {
        if (component === "Editor" && document.getElementById("pagelist-container"))
          document.getElementById("pagelist-container").removeEventListener("mouseleave", handleMouseLeave);
        window.removeEventListener("resize", resizePage);
      };
    } else stopAllPageListVideosBuffering();
  }, [showList]);

  useEffect(() => {
    stopAllPageListVideosBuffering();
    setShowList(false);
    setHide(false);
  }, [fullScreen]);

  useEffect(() => {
    if (!documentDetails && !published && component !== "Editor") dispatch(fetchDocumentDetails(recordId));
  }, []);

  return (
    <UncontrolledDropdown
      direction="up"
      isOpen={showList}
      toggle={e => togglePageList(e)}
      tag="li"
      className={cx(style["custom-tooltip"])}>
      <DropdownToggle
        caret
        tag="div"
        className={cx(style["nav-link"], style["link-white"])}
        onClick={() => toggleDropdown()}>
        <Icon icon="ui-slide" />
      </DropdownToggle>

      <div className={cx(style["custom-tooltip-content"], style["top"])}>{pageType}s</div>

      <DropdownMenu
        className={cx(style["shadow"], style["border-0"], style["rounded"], { [style["d-none"]]: hide })}
        id="pageList">
        <DropdownItem tag="h6" className={style["page-count"]}>
          {pageType} {activePageNumber} of {totalPageNumber}
          <div className={cx(style["close-page-list"], style["link-white"])} onClick={() => toggleDropdown()}>
            <Icon icon="ui-close" />
          </div>
        </DropdownItem>

        <div className={cx(style["customScroll"], style["scroll-Y"])} id="editor-page-list-scroll" onScroll={scrolling}>
          <ul className={style["pagelist-container"]} ref={container} id="pagelist-container">
            {component === "Editor" && showList && (
              <EditorCanvasPanel
                pageSorterList={showList}
                scaleFactor={scaleFactor}
                activePageListHover={activePageListHover}
              />
            )}

            {component === "Present" &&
              docPagesMeta?.pageNodes?.map((pageMeta, i) => (
                <DropdownItem
                  tag="li"
                  className={cx(style["pagelist-item"], { [style["active"]]: slideIndex - 1 === i })}
                  key={`pagelist-${i}`}
                  onClick={() => activePage(i)}
                  id={`fig-${i}`}>
                  <CanvasPageHtmlGenaretor
                    figureWidth={pageSorterScreenWidth}
                    className={cx(style["m-0"])}
                    pageNode={pageMeta}
                    docPagesMeta={docPagesMeta}
                  />
                  <span>{i + 1}</span>
                </DropdownItem>
              ))}
          </ul>
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default PageList;

PageList.propTypes = {
  setSlideIndex: PropTypes.func,
  slideIndex: PropTypes.number,
  component: PropTypes.string.isRequired,
  published: PropTypes.bool,
  fullScreen: PropTypes.bool,
  docPagesMeta: PropTypes.object
};
