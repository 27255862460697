import { useContext } from "react";
import { W, E, NW, NE, SW, SE } from "../constants/editor";
import { getZoomedValue, getUnScaledValue, calculateNewPositionOnRotatedObjectResize, getCssTransformObj } from "../_helpers/utils";
import { EditorContext } from "../containers/editor/EditorLayout";
import UseCheckWidgetAllignment from "./UseCheckWidgetAllignment";
import useCheckWidgetPosition from "./useCheckWidgetPosition";
import useDeleteWidget from "./useDeleteWidget";
import useCollaborativeSelector from "./useCollaborativeSelector";

const useResizeTextWidget = () => {
  let { widgets, updateWidgets, dimension, setTextFontSize } = useContext(EditorContext);

  const { checkWidgetAllignmentForSingleWidget } = UseCheckWidgetAllignment();
  const deleteWidgetIfOutside = useCheckWidgetPosition();
  const setDeleteWidget = useDeleteWidget();
  const updateCollaborativeWidgetSelectionStyle = useCollaborativeSelector();

  const textResizeStart = data => {
    let noOfLines =
      getUnScaledValue(data.client.initial.height, dimension.zoom) /
      parseFloat(document.querySelector(`#${data.client.id} .dhp-widget-inner`).style.fontSize);

    data.client.initial.noOfLines = noOfLines;
  };

  const textResize = (meta, widgetHeight, widgetWidth) => {
    let mainObj = document.getElementById(meta.client.id);
    let handler = document.getElementById("dhp-widget-handler");

    if ([E, W].includes(meta.resizer.type)) {
      let resizedHeight = mainObj.querySelector(".dhp-widget-inner").offsetHeight;
      // adjust left top for rotated Text resize
      const {
        translate: { x: widgetTransX, y: widgetTransY },
        rotate: { theta: widgetTheta },
      } = getCssTransformObj({
        transform: mainObj.style.transform,
      });
      const { left, top } = calculateNewPositionOnRotatedObjectResize(
        parseFloat(widgetTransX),
        parseFloat(widgetTransY),
        parseFloat(widgetWidth),
        parseFloat(resizedHeight),
        parseFloat(mainObj.style.width),
        parseFloat(mainObj.style.height),
        parseFloat(widgetTheta)
      );
      const widgetTransformStr = getCssTransformObj({
        translateX: `${left}px`,
        translateY: `${top}px`,
        rotateAngle: widgetTheta,
        returnStr: true,
      });

      // Update text inner widget width
      mainObj.querySelector(".dhp-widget-inner").style.width = `${widgetWidth}px`;

      // Update text main div transform and height
      mainObj.style.transform = widgetTransformStr;
      mainObj.style.height = `${resizedHeight}px`;

      // set handler transform and height
      const handlerTransformStr = getCssTransformObj({
        translateX: `${getZoomedValue(left, dimension.zoom)}px`,
        translateY: `${getZoomedValue(top, dimension.zoom)}px`,
        rotateAngle: widgetTheta,
        returnStr: true,
      });
      handler.style.transform = handlerTransformStr;     
      handler.style.height = `${getZoomedValue(resizedHeight, dimension.zoom)}px`;

      // update context obj
      meta.client.current = {
        ...meta.client.current,
        height: `${resizedHeight}px`,
        transform: widgetTransformStr
      };

      updateCollaborativeWidgetSelectionStyle({ zoom: dimension.zoom });
    }

    if ([NW, NE, SE, SW].includes(meta.resizer.type)) {
      let fontSize = widgetHeight / meta.client.initial.noOfLines;
      fontSize = fontSize < 100 ? fontSize.toFixed(1) : Math.round(fontSize);

      // Update text inner widget width and font size
      mainObj.querySelector(".dhp-widget-inner").style.width = `${widgetWidth}px`;
      mainObj.querySelector(".dhp-widget-inner").style.fontSize = `${fontSize}px`;

      // Update text main div height and handler height
      let resizedHeight = mainObj.querySelector(".dhp-widget-inner").offsetHeight;
      mainObj.style.height = `${resizedHeight}px`;
      handler.style.height = `${getZoomedValue(resizedHeight, dimension.zoom)}px`;

      // update context obj
      meta.client.current = {
        ...meta.client.current,
        height: `${resizedHeight}px`,
      };

      // Update text tollbar font size value
      document.getElementById("textFontSizeDropdown").value = `${fontSize}`;
      setTextFontSize(fontSize);
    }
  };

  const textResizestop = meta => {
    // Update text tollbar font size value
    let textFontSize = document.querySelector(`#${meta.client.id} .dhp-widget-inner`).style.fontSize;
    document.getElementById("textFontSizeDropdown").value = textFontSize;
    textFontSize =
      parseFloat(textFontSize) % 1 != 0
        ? parseFloat(textFontSize).toFixed(1)
        : Math.round(textFontSize.replace("px", ""));

    setTextFontSize(textFontSize);

    const mainObj = document.getElementById(meta.client.id);
    const updateInnerHtml = mainObj.innerHTML;
    let updatedCss = meta.client.current;
    const { x_al, y_al } = checkWidgetAllignmentForSingleWidget(
      updatedCss.height,
      updatedCss.transform,
      updatedCss.width
    );
    const isDletable = deleteWidgetIfOutside(meta.client.id);

    // Check widget position during resize, if it is outside canvas area delete the widget else upadte new position
    if (isDletable) setDeleteWidget(true);
    else {
      let handler = document.getElementById("dhp-widget-handler");
      let resizedHeight = mainObj.querySelector(".dhp-widget-inner").offsetHeight;
      // adjust left top for rotated Text resize
      const {
        translate: { x: widgetTransX, y: widgetTransY },
        rotate: { theta: widgetTheta },
      } = getCssTransformObj({
        transform: mainObj.style.transform,
      });
      const { left, top } = calculateNewPositionOnRotatedObjectResize(
        parseFloat(widgetTransX),
        parseFloat(widgetTransY),
        parseFloat(updatedCss.width),
        parseFloat(resizedHeight),
        parseFloat(mainObj.style.width),
        parseFloat(mainObj.style.height),
        parseFloat(widgetTheta)
      );
      const widgetTransformStr = getCssTransformObj({
        translateX: `${left}px`,
        translateY: `${top}px`,
        rotateAngle: widgetTheta,
        returnStr: true,
      });

      // Update text main div transform and height
      mainObj.style.transform = widgetTransformStr;
      mainObj.style.height = `${resizedHeight}px`;

      // set handler transform and height
      const handlerTransformStr = getCssTransformObj({
        translateX: `${getZoomedValue(left, dimension.zoom)}px`,
        translateY: `${getZoomedValue(top, dimension.zoom)}px`,
        rotateAngle: widgetTheta,
        returnStr: true,
      });
      handler.style.transform = handlerTransformStr;
      handler.style.height = `${getZoomedValue(resizedHeight, dimension.zoom)}px`;

      updatedCss = { ...updatedCss, height: `${resizedHeight}px`, transform: widgetTransformStr };
      updateWidgets(
        widgets.map((widget, idx) => {
          if (idx === meta.client.idx) {
            return {
              ...widget,
              style: {
                ...widget.style,
                ...updatedCss,
              },
              data: {
                ...widget.data,
                "data-x-allignment": x_al,
                "data-y-allignment": y_al,
              },
              innerHTML: updateInnerHtml,
            };
          } else {
            return widget;
          }
        })
      );
    }
  };

  return [textResizeStart, textResize, textResizestop];
};

export default useResizeTextWidget;
