import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import global from "../../../scss/dhp.scss";

import AssetName from "./Common/AssetName";
import AssetsLoader from "../../ui/loader/assetsLoader";
import { Icon } from "../../ui/icon";
import {
  uploadAssetFile,
  fetchUploadAssets,
  deleteUploadAsset,
  uploadFacebookImeges,
} from "../../../store/actions/editorActions";
import Progressbar from "../../ui/progressbar";
import Modal from "../../ui/modal";
import DeleteElemModal from "../Modals/DeleteElemModal";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import useElementInnerHtml from "../../../hooks/useElementInnerHtml";
import useAddWidget from "../../../hooks/useAddWidget";
import useAlignment from "../../../hooks/useAlignment";
import { widgetConfig } from "../editor_config";
import {
  getFileType,
  RGBToHex,
  getSvgContentFromUrl,
  getImgDimensionFromUrl,
  getClassListToString,
} from "../../../_helpers/utils";
import * as constant from "../../../constants/editor";
import useDragDropWidget from "../../../hooks/useDragDropWidget";
import { DotLoader } from "../../ui/loader/dotLoader";
import useTextFocusOut from "../../../hooks/useTextFocusOut";
import useUploadFacebook from "../../../hooks/useUploadFacebook";
import facebookImageUploadModal from "../../Document/modal/facebookImageUploadModal";
import { ProcessLoader } from "../../ui/loader/processLoader";
import {
  FETCH_FACEBOOK_ALBUMS_FAILED,
  FETCH_FACEBOOK_ALBUM_IMAGES_FAILED,
  UPLOAD_FACEBOOK_IMAGE_FAILED,
} from "../../../store/actions/actionTypes";
import AssetSchemeTypeNav from "./Common/AssetSchemeTypeNav";
import { TabContent, TabPane, Tooltip } from "reactstrap";
import SliderControll from "../SliderControll";
import useAudio from "../../../hooks/useAudio";
import useDragDropAudio from "../../../hooks/useDragDropAudio";

let style = Object.assign({}, global);

const AudioAsset = props => {
  const { addAudio } = useAudio();
  const playedAudioElements = useRef({});
  const audioRef = useRef(null);
  const audiotooltipRef = useRef();
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);

  const { start: initDragDropWidget } = useDragDropAudio();

  const playAudioOnClick = e => {
    e.stopPropagation();
    // Toggle the playAudio state based on the current state of the audio
    props.onPlayAudio(props.index);
  };

  const onTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const onLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

  const onSliderChange = value => {
    audioRef.current.currentTime = value;
    setCurrentTime(value);
  };

  const onEnded = () => {
    props.onPlayAudio(null); // Indicate that audio has ended
  };

  const insertAudio = (url, duration, filename, audioFilename, source) => {
    addAudio(url, duration, filename, audioFilename, source);
  };

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip && audiotooltipRef.current.scrollWidth > audiotooltipRef.current.offsetWidth);
  };

  useEffect(() => {
    // Reset currentTime to 0 when isPlaying becomes false (audio is paused)
    if (!props.isPlaying) {
      setCurrentTime(0);
    }
  }, [props.isPlaying]);

  useEffect(() => {
    const container = document.getElementById("upload-asset-container");

    const handleWheel = () => {
      if (showTooltip) {
        setShowTooltip(false);
      }
    };

    if (container) {
      container.addEventListener("wheel", handleWheel);
    }

    return () => {
      if (container) {
        container.removeEventListener("wheel", handleWheel);
      }
    };
  }, [showTooltip]);

  return (
    <React.Fragment key={props.audio.id}>
      {props.length === props.index + 1 && (
        <li className={cx(style["col"], style["skeleton-loader-area"])} ref={props.lastAudioElement}>
          <div
            className={cx(style["asset-item"], style["audio-item"], style["p-3"])}
            data-idx={props.index}
            ref={el => (playedAudioElements.current[props.index] = el)}
            onClick={() =>
              insertAudio(
                props.audio.url,
                props.audio.duration,
                props.audio.filename,
                props.audio.audio_filename,
                constant.UPLOAD_AUDIO
              )
            }
            onMouseDown={e => {
              initDragDropWidget(
                {
                  url: props.audio.url,
                  duration: props.audio.duration,
                  filename: props.audio.filename,
                  audioFilename: props.audio.audio_filename,
                  source: constant.UPLOAD_AUDIO,
                },
                e
              );
            }}>
            <div className={cx(style["d-flex"], style["align-items-center"])}>
              <div className={cx(style["cursor-pointer"], style["icons"])} onClick={e => playAudioOnClick(e)}>
                <Icon icon={props.isPlaying ? "ui-circled-pause" : "ui-circled-play"} />
              </div>
              {!props.isPlaying && (
                <div className={cx(style["ml-3"], style["info"])}>
                  <h6
                    className={cx(style["audio-title"], style["text-truncate"], style["m-0"])}
                    ref={audiotooltipRef}
                    id={`tooltip-${props.audio.id}`}>
                    {props.audio.audio_filename}
                  </h6>
                  <Tooltip
                    placement="bottom"
                    isOpen={showTooltip}
                    target={`tooltip-${props.audio.id}`}
                    toggle={toggleTooltip}
                    boundariesElement={document.getElementById("app")}>
                    {props.audio.audio_filename}
                  </Tooltip>
                  <span className={style["audio-duration"]}>{props.audio.duration}</span>
                </div>
              )}
            </div>
            {props.isPlaying && (
              <>
                <audio
                  ref={audioRef}
                  src={props.audio.url}
                  onTimeUpdate={onTimeUpdate}
                  onLoadedMetadata={onLoadedMetadata}
                  onEnded={onEnded}
                  autoPlay
                />
                <div
                  className={cx(style["ml-3"], style["info"])}
                  onClick={e => e.stopPropagation()}
                  onMouseDown={e => e.stopPropagation()}>
                  <SliderControll
                    Slider={currentTime}
                    setSlider={onSliderChange}
                    sliderMin={0}
                    sliderMax={duration}
                    step={0.1}
                    hideSliderValue
                  />
                </div>
              </>
            )}
            <span
              className={cx(style["delete-img"], { [style["d-none"]]: props.isPlaying })}
              onClick={e => (e.stopPropagation(), props.showModal(props.audio.id, props.index, "audio"))}>
              <Icon icon="ui-trash" />
            </span>
          </div>
        </li>
      )}

      {props.length !== props.index + 1 && (
        <li className={cx(style["col"], style["skeleton-loader-area"])}>
          <div
            className={cx(style["asset-item"], style["audio-item"], style["p-3"])}
            data-idx={props.index}
            ref={el => (playedAudioElements.current[props.index] = el)}
            onClick={() =>
              insertAudio(
                props.audio.url,
                props.audio.duration,
                props.audio.filename,
                props.audio.audio_filename,
                constant.UPLOAD_AUDIO
              )
            }
            onMouseDown={e => {
              initDragDropWidget(
                {
                  url: props.audio.url,
                  duration: props.audio.duration,
                  filename: props.audio.filename,
                  audioFilename: props.audio.audio_filename,
                  source: constant.UPLOAD_AUDIO,
                },
                e
              );
            }}>
            <div className={cx(style["d-flex"], style["align-items-center"])}>
              <div className={cx(style["cursor-pointer"], style["icons"])} onClick={e => playAudioOnClick(e)}>
                <Icon icon={props.isPlaying ? "ui-circled-pause" : "ui-circled-play"} />
              </div>
              {!props.isPlaying && (
                <div className={cx(style["ml-3"], style["info"])}>
                  <h6
                    className={cx(style["audio-title"], style["text-truncate"], style["m-0"])}
                    ref={audiotooltipRef}
                    id={`tooltip-${props.audio.id}`}>
                    {props.audio.audio_filename}
                  </h6>
                  <Tooltip
                    placement="bottom"
                    isOpen={showTooltip}
                    target={`tooltip-${props.audio.id}`}
                    toggle={toggleTooltip}
                    boundariesElement={document.getElementById("app")}>
                    {props.audio.audio_filename}
                  </Tooltip>
                  <span className={style["audio-duration"]}>{props.audio.duration}</span>
                </div>
              )}
            </div>
            {props.isPlaying && (
              <>
                <audio
                  ref={audioRef}
                  src={props.audio.url}
                  onTimeUpdate={onTimeUpdate}
                  onLoadedMetadata={onLoadedMetadata}
                  onEnded={onEnded}
                  autoPlay
                />
                <div
                  className={cx(style["ml-3"], style["info"])}
                  onClick={e => e.stopPropagation()}
                  onMouseDown={e => e.stopPropagation()}>
                  <SliderControll
                    Slider={currentTime}
                    setSlider={onSliderChange}
                    sliderMin={0}
                    sliderMax={duration}
                    step={0.1}
                    hideSliderValue
                  />
                </div>
              </>
            )}
            <span
              className={cx(style["delete-img"], { [style["d-none"]]: props.isPlaying })}
              onClick={e => (e.stopPropagation(), props.showModal(props.audio.id, props.index, "audio"))}>
              <Icon icon="ui-trash" />
            </span>
          </div>
        </li>
      )}
    </React.Fragment>
  );
};
AudioAsset.propTypes = {
  audio: PropTypes.object.isRequired,
  length: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  lastAudioElement: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

const VideoAsset = props => {
  const playedVideoElement = useRef();

  const { start: initDragDropWidget } = useDragDropWidget();

  const [isAssetLoad, setIsAssetLoad] = useState(false);
  const [playVideo, setPlayVideo] = useState({});

  const playVideoOnHover = assetLoadComplete => {
    if (!playVideo[playedVideoElement.current.dataset.idx] && assetLoadComplete) {
      setPlayVideo({ [playedVideoElement.current.dataset.idx]: true });

      // removing preview loader
      let intervalId = setInterval(() => {
        let runTimeVideo = document.getElementById("video-pen");
        let overlayLoader = document.getElementById("video-overlay");
        if (runTimeVideo && runTimeVideo.readyState == 4) {
          clearInterval(intervalId);
          overlayLoader.style.display = "none";
        }
      }, 300);
    }
  };

  const stopVideoOnMouseLeave = () => {
    setPlayVideo({ [playedVideoElement.current.dataset.idx]: false });
  };

  return (
    <React.Fragment key={props.video.id}>
      {props.length === props.index + 1 && (
        <li className={cx(style["col"], style["skeleton-loader-area"])} ref={props.lastVideoElement}>
          <div
            className={cx(style["asset-item"], style["main-heading"])}
            data-idx={props.index}
            ref={playedVideoElement}
            onMouseEnter={() => playVideoOnHover(isAssetLoad)}
            onMouseLeave={stopVideoOnMouseLeave}
            onClick={() => props.insertVideoElem(props.video.url, props.video.thumb, props.video.duration, isAssetLoad)}
            onMouseDown={e => {
              isAssetLoad &&
                initDragDropWidget(
                  {
                    asset: constant.VIDEO,
                    value: props.video.url,
                    source: constant.UPLOAD_VIDEO,
                    poster: props.video.thumb,
                    duration: props.video.duration,
                  },
                  e
                );
            }}>
            {!playVideo[props.index] && !isAssetLoad && <span className={style["loader-item"]}></span>}

            {!playVideo[props.index] && (
              <img
                src={props.video.thumb}
                onLoad={() => setIsAssetLoad(true)}
                className={cx({ [style["d-none"]]: !isAssetLoad })}
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = props.video.thumb;
                }}
              />
            )}

            {playVideo[props.index] && isAssetLoad && (
              <div className={style["preview-pen"]} id="video-preview">
                <video id="video-pen" autoPlay muted loop poster={props.video.thumb}>
                  <source src={props.video.url} />
                </video>
                <div className={style["overlay"]} id="video-overlay">
                  <div className={style["loader"]}></div>
                </div>
              </div>
            )}

            {/* <div> */}
            {isAssetLoad && <span className={style["duration"]}>{props.video.duration}</span>}
            <span
              className={style["delete-img"]}
              onClick={e => (e.stopPropagation(), props.showModal(props.video.id, props.index, "video"))}>
              <Icon icon="ui-trash" />
            </span>
            {/* </div> */}
          </div>
        </li>
      )}

      {props.length !== props.index + 1 && (
        <li className={cx(style["col"], style["skeleton-loader-area"])}>
          <div
            className={cx(style["asset-item"], style["main-heading"])}
            data-idx={props.index}
            ref={playedVideoElement}
            onMouseEnter={() => playVideoOnHover(isAssetLoad)}
            onMouseLeave={stopVideoOnMouseLeave}
            onClick={() => props.insertVideoElem(props.video.url, props.video.thumb, props.video.duration, isAssetLoad)}
            onMouseDown={e => {
              isAssetLoad &&
                initDragDropWidget(
                  {
                    asset: constant.VIDEO,
                    value: props.video.url,
                    source: constant.UPLOAD_VIDEO,
                    poster: props.video.thumb,
                    duration: props.video.duration,
                  },
                  e
                );
            }}>
            {!playVideo[props.index] && !isAssetLoad && <span className={style["loader-item"]}></span>}

            {!playVideo[props.index] && (
              <img
                src={props.video.thumb}
                onLoad={() => setIsAssetLoad(true)}
                className={cx({ [style["d-none"]]: !isAssetLoad })}
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = props.video.thumb;
                }}
              />
            )}

            {playVideo[props.index] && isAssetLoad && (
              <div className={style["preview-pen"]} id="video-preview">
                <video id="video-pen" autoPlay muted loop poster={props.video.thumb}>
                  <source src={props.video.url} />
                </video>
                <div className={style["overlay"]} id="video-overlay">
                  <div className={style["loader"]}></div>
                </div>
              </div>
            )}

            <div>
              {isAssetLoad && <span className={style["duration"]}>{props.video.duration}</span>}
              <span
                className={style["delete-img"]}
                onClick={e => (e.stopPropagation(), props.showModal(props.video.id, props.index, "video"))}>
                <Icon icon="ui-trash" />
              </span>
            </div>
          </div>
        </li>
      )}
    </React.Fragment>
  );
};
VideoAsset.propTypes = {
  video: PropTypes.object.isRequired,
  length: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  lastVideoElement: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  insertVideoElem: PropTypes.func.isRequired,
};

const AssetList = props => {
  const { start: initDragDropWidget } = useDragDropWidget();
  const setTextFocusOut = useTextFocusOut();

  const [isAssetLoad, setIsAssetLoad] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const scrollLeftPane = () => {
    setTooltipOpen(false);
  };

  useEffect(() => {
    setTooltipOpen(false);
  }, [props.availableImageList]);

  useEffect(() => {
    document.querySelector(".assets-wrapper").addEventListener("scroll", scrollLeftPane);

    return () => {
      document.querySelector(".assets-wrapper")?.removeEventListener("scroll", scrollLeftPane);
    };
  }, []);

  return (
    <React.Fragment key={props.availableImageList.id}>
      {props.length === props.index + 1 && (
        <li className={cx(style["col"], style["skeleton-loader-area"])} ref={props.lastImageElement}>
          <div id={`asset-image-${props.index}`} className={cx(style["asset-item"])}>
            <div
              className={style["item-holder"]}
              onClick={() => {
                if (document.querySelector(".dhp-content-editable-true-text")) setTextFocusOut(true);
                props.insertElem(
                  props.availableImageList.url,
                  props.availableImageList.filename,
                  props.availableImageList?.duration
                );
              }}
              onMouseDown={e =>
                initDragDropWidget(
                  {
                    asset: props.assetType,
                    value: props.availableImageList.url,
                    filename: props.availableImageList.filename,
                    duration: props.availableImageList?.duration,
                  },
                  e
                )
              }>
              {!isAssetLoad && <span className={style["loader-item"]}></span>}

              <img
                src={props.availableImageList.url}
                onLoad={() => setIsAssetLoad(true)}
                className={cx({ [style["d-none"]]: !isAssetLoad })}
              />
            </div>
            <span
              className={style["delete-img"]}
              onClick={e => (e.preventDefault(), props.showModal(props.availableImageList.id, props.index))}>
              <Icon icon="ui-trash" />
            </span>
          </div>

          {isAssetLoad && (
            <Tooltip
              className="asset-hover-tooltip"
              placement="right"
              isOpen={tooltipOpen}
              target={`asset-image-${props.index}`}
              toggle={toggle}
              boundariesElement={document.getElementById("app")}>
              <div
                className={getClassListToString([
                  "image-preview",
                  getFileType(props.availableImageList.filename) === "svg" ? "svg-preview" : "preview-picture",
                ])}>
                <img src={props.availableImageList.url} alt="" className={style["img-fluid"]} />
              </div>
            </Tooltip>
          )}
        </li>
      )}
      {props.length !== props.index + 1 && (
        <li className={cx(style["col"], style["skeleton-loader-area"])}>
          <div id={`asset-image-${props.index}`} className={cx(style["asset-item"])}>
            <div
              className={style["item-holder"]}
              onClick={() => {
                if (document.querySelector(".dhp-content-editable-true-text")) setTextFocusOut(true);
                props.insertElem(
                  props.availableImageList.url,
                  props.availableImageList.filename,
                  props.availableImageList?.duration
                );
              }}
              onMouseDown={e =>
                initDragDropWidget(
                  {
                    asset: props.assetType,
                    value: props.availableImageList.url,
                    filename: props.availableImageList.filename,
                    duration: props.availableImageList?.duration,
                  },
                  e
                )
              }>
              {!isAssetLoad && <span className={style["loader-item"]}></span>}

              <img
                src={props.availableImageList.url}
                onLoad={() => setIsAssetLoad(true)}
                className={cx({ [style["d-none"]]: !isAssetLoad })}
              />
            </div>
            <span
              className={style["delete-img"]}
              onClick={e => (e.preventDefault(), props.showModal(props.availableImageList.id, props.index, "image"))}>
              <Icon icon="ui-trash" />
            </span>
          </div>

          {isAssetLoad && (
            <Tooltip
              className="asset-hover-tooltip"
              placement="right"
              isOpen={tooltipOpen}
              target={`asset-image-${props.index}`}
              toggle={toggle}
              boundariesElement={document.getElementById("app")}>
              <div
                className={getClassListToString([
                  "image-preview",
                  getFileType(props.availableImageList.filename) === "svg" ? "svg-preview" : "preview-picture",
                ])}>
                <img src={props.availableImageList.url} alt="" className={style["img-fluid"]} />
              </div>
            </Tooltip>
          )}
        </li>
      )}
    </React.Fragment>
  );
};
AssetList.propTypes = {
  availableImageList: PropTypes.object.isRequired,
  length: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  lastImageElement: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  insertElem: PropTypes.func.isRequired,
  assetType: PropTypes.string.isRequired,
};

const UploadedAssetList = props => {
  let { metadata, dimension } = useContext(EditorContext);

  const dispatch = useDispatch();

  const [showModalStatus, setShowModalStatus] = useState(false);
  const [modalParams, setModalParams] = useState("");
  const [assetInnerContent, setAssetInnerContent] = useState();
  const [widgetWidth, setWidgetWidth] = useState();
  const [widgetHeight, setWidgetHeight] = useState();
  const [dataparam, setDataParam] = useState();
  const [addWidgetClicked, setAddWidgetClicked] = useState(false);
  const [assetType, setAssetType] = useState();
  const [currentlyPlayingIndex, setCurrentlyPlayingIndex] = useState(null);

  // Function to handle audio play and puase
  const handlePlayAudio = index => {
    if (currentlyPlayingIndex === index) {
      // If the same audio is clicked again, pause it
      setCurrentlyPlayingIndex(null);
    } else {
      setCurrentlyPlayingIndex(index);
    }
  };

  const modalData = {
    title: "Delete",

    body: "Are you sure to delete this file? This action can’t be reversed.",

    btnLabel: "Delete",
  };

  const setTextFocusOut = useTextFocusOut();
  const { postion: getPosition } = useAlignment(addWidgetClicked, widgetWidth, widgetHeight, "middle-center");
  const getNewWidgetObject = useAddWidget(
    addWidgetClicked,
    assetType,
    dataparam,
    getPosition,
    widgetWidth,
    widgetHeight
  );
  useElementInnerHtml(
    addWidgetClicked,
    setAddWidgetClicked,
    assetType,
    assetInnerContent,
    dataparam,
    getNewWidgetObject
  );

  //Function to set modal params and show the delete modal
  const showModal = (recordId, index, fileType = null) => {
    setModalParams({ recordId: recordId, recordIndex: index, fileType: fileType });
    setShowModalStatus(!showModalStatus);
  };

  //Function for close modal
  const closeModal = () => {
    setShowModalStatus(!showModalStatus);
  };

  //Function for delete uploaded Asset
  const deleteUploadedAsset = () => {
    const payload = {
      recordId: modalParams.recordId,
      recordIndex: modalParams.recordIndex,
      fileType: modalParams.fileType,
    };
    dispatch(deleteUploadAsset(payload));
  };

  //Function for insert upload image
  const insertElem = async (url, fileName, duration) => {
    let innerContent;

    let paramObj = {
      "data-file-type": getFileType(fileName),
      "data-duration": duration,
    };

    if (getFileType(fileName) !== constant.SVG) {
      innerContent = url;

      const { width: imgWidth, height: imgHeight } = await getImgDimensionFromUrl(url);
      const imgAspectRatio = imgWidth / imgHeight;
      const { width: activeBlockWidth } = document.getElementById(`${metadata.activeBlockId}`)?.getBoundingClientRect();
      const width = imgWidth <= activeBlockWidth ? imgWidth : activeBlockWidth;
      const height = imgWidth <= activeBlockWidth ? imgHeight : width / imgAspectRatio;

      setWidgetWidth(width);
      setWidgetHeight(height);
    } else {
      innerContent = await getSvgContentFromUrl(url);

      //Update multicolor uploaded svg innerhtml with data attribute for different color path after insertion
      innerContent.querySelectorAll(`svg *`).forEach(node => {
        if (
          node.nodeName === "defs" ||
          node.parentNode.nodeName === "defs" ||
          node.parentNode.nodeName === "linearGradient"
        )
          return false;

        if ((node.nodeName !== "g" && node.style && RGBToHex(node.style.fill) !== "") || node?.getAttribute("fill")) {
          const currentIconColor = node?.getAttribute("fill") ? node?.getAttribute("fill") : RGBToHex(node.style.fill);
          node.dataset.class = `${currentIconColor}body`;
        }

        if (node.tagName === "title") node.remove(); // fremove title tag for uploaded svg
      });

      setWidgetWidth(innerContent?.viewBox?.baseVal?.width ?? widgetConfig[props.assetType].width);
      setWidgetHeight(innerContent?.viewBox?.baseVal?.height ?? widgetConfig[props.assetType].height);
    }

    setDataParam({ ...widgetConfig[props.assetType].dataAttr, ...paramObj });
    setAssetInnerContent(innerContent);
    setAssetType(constant.UPLOAD);
    setAddWidgetClicked(true);
  };

  const insertVideoElem = async (url, poster, duration, assetLoadComplete) => {
    if (!assetLoadComplete) return;

    const { width: imgWidth, height: imgHeight } = await getImgDimensionFromUrl(poster);
    const imgAspectRatio = imgWidth / imgHeight;
    let { width: activeBlockWidth } = document.getElementById(`${metadata.activeBlockId}`)?.getBoundingClientRect();
    activeBlockWidth = (activeBlockWidth * 100) / parseFloat(dimension.zoom);
    const width = imgWidth <= activeBlockWidth ? imgWidth : activeBlockWidth;
    const height = imgWidth <= activeBlockWidth ? imgHeight : width / imgAspectRatio;

    if (document.querySelector(".dhp-content-editable-true-text")) setTextFocusOut(true);

    setTimeout(() => {
      let paramObj = {
        "data-poster": poster,
        "data-url": url,
        "data-duration": duration,
      };

      setWidgetWidth(width);
      setWidgetHeight(height);
      setAssetInnerContent(url);
      setDataParam({ ...widgetConfig[constant.VIDEO].UPLOADVIDEO.dataAttr, ...paramObj });
      setAssetType(constant.VIDEO);
      setAddWidgetClicked(true);
    }, 1);
  };

  //Update multicolor uploaded svg innerhtml with data attribute for different color path after insertion
  useEffect(() => {
    if (
      !addWidgetClicked &&
      metadata.activeWidgetId.length > 0 &&
      document.getElementById(metadata.activeWidgetId[0])?.dataset?.fileType === constant.SVG
    ) {
      document.querySelectorAll(`#${metadata.activeWidgetId[0]} svg *`).forEach(function (node) {
        if (
          node.nodeName === "defs" ||
          node.parentNode.nodeName === "defs" ||
          node.parentNode.nodeName === "linearGradient"
        )
          return false;

        if (node.nodeName !== "g" && RGBToHex(node.style.fill) !== "") {
          let currentIconColor = RGBToHex(node.style.fill);
          node.dataset.class = `${currentIconColor}body`;
        }
      });
    }
  }, [addWidgetClicked]);

  return (
    <>
      {props.schemeType === "Images" && (
        <TabPane
          tabId="1"
          className={cx(style["customScroll"], style["scroll-Y"], style["active"], style["assets-wrapper"])}
          id="imageListWrapper">
          <ul className={cx(style["row"], style["row-cols-2"])}>
            {props.assetLoading && props.imgPageNumber === 1 && <AssetsLoader count={20} />}

            {props.availableUploadImagesLists?.map((availableImageList, index) => (
              <AssetList
                key={index}
                availableImageList={availableImageList}
                length={props.availableUploadImagesLists.length}
                index={index}
                lastImageElement={props.lastImageElement}
                showModal={showModal}
                insertElem={insertElem}
                assetType={props.assetType}
              />
            ))}
          </ul>

          {(props.availableUploadImagesLists?.length > 0 ||
            props.availableUploadVideosLists?.length > 0 ||
            props.availableUploadAudiosLists?.length > 0) &&
            props.availableUploadImagesLists?.length === 0 && (
              <div
                className={cx(
                  style["text-center"],
                  style["bg-secondary"],
                  style["rounded"],
                  style["p-2"],
                  style["w-242"]
                )}>
                No uploaded images yet
              </div>
            )}

          {props.assetLoading && props.imgPageNumber > 1 && <DotLoader />}
        </TabPane>
      )}

      {props.schemeType === "Videos" && (
        <TabPane
          tabId="2"
          className={cx(
            style["customScroll"],
            style["scroll-Y"],
            style["assets-wrapper"],
            style["video-pane"],
            style["active"]
          )}
          id="videoListWrapper">
          <ul className={cx(style["row"], style["row-cols-1"])} onContextMenu={e => e.preventDefault()}>
            {props.assetLoading && props.videoPageNumber === 1 && <AssetsLoader count={20} />}

            {props.availableUploadVideosLists?.map((video, index) => (
              <VideoAsset
                key={index}
                video={video}
                length={props.availableUploadVideosLists.length}
                index={index}
                lastVideoElement={props.lastVideoElement}
                showModal={showModal}
                insertVideoElem={insertVideoElem}
              />
            ))}
          </ul>

          {(props.availableUploadImagesLists?.length > 0 ||
            props.availableUploadVideosLists?.length > 0 ||
            props.availableUploadAudiosLists?.length > 0) &&
            props.availableUploadVideosLists?.length === 0 && (
              <div
                className={cx(
                  style["text-center"],
                  style["bg-secondary"],
                  style["rounded"],
                  style["p-2"],
                  style["w-242"]
                )}>
                No uploaded videos yet
              </div>
            )}

          {props.assetLoading && props.videoPageNumber > 1 && <DotLoader />}
        </TabPane>
      )}

      {props.schemeType === "Audio" && (
        <TabPane
          tabId="3"
          className={cx(
            style["customScroll"],
            style["scroll-Y"],
            style["assets-wrapper"],
            style["audio-pane"],
            style["active"]
          )}>
          <ul className={cx(style["row"], style["row-cols-1"])} onContextMenu={e => e.preventDefault()}>
            {props.assetLoading && props.audioPageNumber === 1 && <AssetsLoader count={20} />}

            {props.availableUploadAudiosLists?.map((audio, index) => (
              <AudioAsset
                key={index}
                audio={audio}
                length={props.availableUploadAudiosLists.length}
                index={index}
                lastAudioElement={props.lastAudioElement}
                showModal={showModal}
                isPlaying={index === currentlyPlayingIndex} // Pass whether the audio is currently playing
                onPlayAudio={handlePlayAudio} // Pass the play action handler function
              />
            ))}
          </ul>

          {(props.availableUploadImagesLists?.length > 0 ||
            props.availableUploadVideosLists?.length > 0 ||
            props.availableUploadAudiosLists?.length > 0) &&
            props.availableUploadAudiosLists?.length === 0 && (
              <div
                className={cx(
                  style["text-center"],
                  style["bg-secondary"],
                  style["rounded"],
                  style["p-2"],
                  style["w-242"]
                )}>
                No uploaded audio yet
              </div>
            )}

          {props.assetLoading && props.audioPageNumber > 1 && <DotLoader />}
        </TabPane>
      )}

      {showModalStatus && (
        <Modal
          showModal={showModalStatus}
          setShowModal={setShowModalStatus}
          component={DeleteElemModal}
          modalTitle={modalData?.title}
          modalBody={modalData?.body}
          modalButtonLabel={modalData?.btnLabel}
          deleteElem={deleteUploadedAsset}
          closeModal={closeModal}
        />
      )}
    </>
  );
};
UploadedAssetList.propTypes = {
  availableUploadImagesLists: PropTypes.array,
  assetLoading: PropTypes.bool,
  deleteImage: PropTypes.bool,
  lastImageElement: PropTypes.func.isRequired,
  lastVideoElement: PropTypes.func.isRequired,
  lastAudioElement: PropTypes.func.isRequired,
  imgPageNumber: PropTypes.number.isRequired,
  videoPageNumber: PropTypes.number.isRequired,
  assetName: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
};

// For single Asset Upload
const ProgressLoader = props => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showLoader, setShowLoader] = useState(true);
  const intervalRef = useRef(null);

  const calculateProgress = val => {
    let progress = val ?? 0;
    let maxProgress = 95;
    intervalRef.current = setInterval(() => {
      if (progress < maxProgress) {
        progress += 3;
        if (progress > maxProgress) {
          progress = maxProgress;
        }
      }
      setUploadProgress(progress);
      if (progress >= maxProgress) {
        clearInterval(intervalRef.current);
      }
    }, 400);
  };

  useEffect(() => {
    calculateProgress();
  }, []);

  useEffect(() => {
    if (props.assetId) {
      clearInterval(intervalRef.current);
      setUploadProgress(100);
      setTimeout(() => setShowLoader(false), 1000);
    }
  }, [props.assetId]);

  return <>{showLoader && <Progressbar progressbarInfo={uploadProgress} />}</>;
};

ProgressLoader.propTypes = {
  assetId: PropTypes.string,
};

const UploadAsset = props => {
  const { documentType } = useContext(EditorContext);

  const { type: actionType, status: uploadStatus } = useSelector(state => state.company);
  const userInfo = useSelector(state => state.auth?.user);

  const [acceptedTypes, setAcceptedTypes] = useState("");

  //Use delay to show the complete progress of upload
  useEffect(() => {
    if (props.progressbarInfo === 100) {
      setTimeout(() => {
        props.setisUploadDone(true);
      }, 1000);
    } else if (props.errorCount >= 1 && props.uploadedFilesNumber === props.totalUploadedFiles - props.errorCount) {
      if (props.progressbarInfo === ((props.totalUploadedFiles - props.errorCount) / props.totalUploadedFiles) * 100) {
        props.setUploadAssetError({
          status: true,
          message:
            actionType === UPLOAD_FACEBOOK_IMAGE_FAILED && uploadStatus === 401
              ? "Sign-in failed. Try again"
              : props.errorCount === 1
              ? "The file could not be uploaded"
              : `${props.errorCount} files could not be uploaded`,
        });

        setTimeout(() => {
          props.setisUploadDone(true);
          props.setUploadAssetError({ status: false, message: "" });
        }, 3000);
      }
    }
  }, [props.progressbarInfo, props.errorCount]);

  useEffect(() => {
    if (props?.facebookError) props.setUploadAssetError({ status: true, message: props.facebookError });
    else props.setUploadAssetError({ status: false, message: "" });
  }, [props?.facebookError]);

  useEffect(() => {
    if (
      [FETCH_FACEBOOK_ALBUMS_FAILED, FETCH_FACEBOOK_ALBUM_IMAGES_FAILED].includes(actionType) &&
      uploadStatus === 401
    ) {
      props.setUploadAssetError({ status: true, message: "Sign-in failed. Try again" });
      setTimeout(() => {
        props.setUploadAssetError({ status: false, message: "" });
      }, 3000);
    }
  }, [actionType]);

  useEffect(() => {
    if (documentType === constant.TYPE_INFOGRAPHIC || !props.videoEnableUser) {
      setAcceptedTypes("image/jpeg, image/png, image/gif, image/svg+xml, video/mp4, video/quicktime, video/webm");
    } else {
      setAcceptedTypes(
        "image/jpeg, image/png, image/gif, image/svg+xml, video/mp4, video/quicktime, video/webm, audio/mp3, audio/mp4a, audio/wav, audio/ogg"
      );
    }
  }, [documentType]);

  return (
    <div>
      <div className={cx(style["mb-3"], style["upload-image-wrap"], style["mr-20"])}>
        {(props.isUploadDone || props.isUploadDone === undefined) && (
          <div>
            <div className={cx(style["fileUpload"], style["w-100"])}>
              <input type="file" multiple accept={acceptedTypes} onChange={e => props.fileSelectedHandler(e)} />
              <span className={cx(style["btn"], style["btn-border"])}>
                <Icon icon="ui-upload" /> Upload
              </span>
            </div>
            <span>
              Upload Images, Videos, {documentType !== constant.TYPE_INFOGRAPHIC && props.videoEnableUser && "Audio "}{" "}
              <br />
              and GIFs
            </span>
          </div>
        )}

        {props.isUploadDone === false && (
          <div className={cx(style["upload-loader"], style["text-center"])}>
            <div className={style["download-progressbar"]}>
              {props.totalUploadedFiles > 1 && <Progressbar progressbarInfo={props.progressbarInfo} />}

              {props.totalUploadedFiles === 1 && <ProgressLoader assetId={props.assetId} />}

              <p className={style["upload-file-msg"]}>
                {props.totalUploadedFiles === 1 && <strong>Uploading File</strong>}
                {props.totalUploadedFiles > 1 && <strong>Uploading Files</strong>}
              </p>

              <p>
                {props.uploadedFilesNumber} / {props.totalUploadedFiles}
              </p>
            </div>
          </div>
        )}
      </div>

      {props.uploadAssetError.status && (
        <div
          id="uploadedImageErrorBox"
          className={cx(style["alert"], style["alert-block"], style["alert-danger"], style["mr-4"])}>
          {props.uploadAssetError.message}
        </div>
      )}
    </div>
  );
};
UploadAsset.propTypes = {
  fileSelectedHandler: PropTypes.func.isRequired,
  ulploadedImageListsLength: PropTypes.number,
  uploadAssetError: PropTypes.object,
  setUploadAssetError: PropTypes.func,
  totalUploadedFiles: PropTypes.number,
  uploadedFilesNumber: PropTypes.number,
  progressbarInfo: PropTypes.number,
  isUploadDone: PropTypes.bool,
  setisUploadDone: PropTypes.func.isRequired,
  errorCount: PropTypes.number,
  facebookError: PropTypes.string,
  uploadFromFacebook: PropTypes.func,
  isFacebookAuthenticating: PropTypes.bool,
  videoEnableUser: PropTypes.bool,
};

const Upload = props => {
  const { isUploadDone, setisUploadDone, uploadSchemeType, documentType } = useContext(EditorContext);

  const dispatch = useDispatch();
  const observer = useRef();
  const {
    uploadFromFacebook,
    albums,
    showFBUploadModal,
    setShowFBUploadModal,
    facebookError,
    setIsTokenActive,
    isFacebookAuthenticating,
  } = useUploadFacebook();

  const availableUploadImagesLists = useSelector(state => state?.editor?.availableUploadImages);
  const availableUploadVideosLists = useSelector(state => state?.editor?.availableUploadVideos);
  const availableUploadAudiosLists = useSelector(state => state?.editor?.availableUploadAudios);
  const assetLoading = useSelector(state => state?.editor?.preLoader);
  const deleteImage = useSelector(state => state?.editor?.deleteImage);
  const totalUploadImageCount = useSelector(state => state?.editor?.totalUploadImageCount);
  const totalUploadVideoCount = useSelector(state => state?.editor?.totalUploadVideoCount);
  const totalUploadAudioCount = useSelector(state => state?.editor?.totalUploadAudioCount);
  const totalUploadedFiles = useSelector(state => state?.editor?.totalUploadedFiles);
  const uploadedFilesNumber = useSelector(state => state?.editor?.uploadedFilesNumber);
  const progressbarInfo = useSelector(state => state?.editor?.progressInfo);
  const errorCount = useSelector(state => state?.editor?.errorCount);
  const assetId = useSelector(state => state?.editor?.assetId);
  const userInfo = useSelector(state => state?.auth?.user);

  const limit = 25;
  const uploadSchemes =
    documentType !== constant.TYPE_INFOGRAPHIC && userInfo.video_enabled
      ? ["Images", "Videos", "Audio"]
      : ["Images", "Videos"];

  const [imgPageNumber, setImgPageNumber] = useState(1);
  const [videoPageNumber, setVideoPageNumber] = useState(1);
  const [audioPageNumber, setAudioPageNumber] = useState(1);
  const [uploadAssetError, setUploadAssetError] = useState({ status: false, message: "" });
  const [selctedFacebookImages, setSelctedFacebookImages] = useState();
  const [schemeType, setSchemeType] = useState("Images");
  const activeTabIndex = uploadSchemes.indexOf(schemeType);

  let imgel = document.getElementById("imageListWrapper");
  let vdoEl = document.getElementById("videoListWrapper");

  // function to determine if there are more items to load
  const hasMoreItems = (totalCount, pageNumber, limit) => {
    if (totalCount > 0) {
      const lastPage = Math.floor(totalCount % limit === 0 ? totalCount / limit : totalCount / limit + 1);
      return pageNumber < lastPage;
    }
    return false;
  };

  const hasMoreImages = hasMoreItems(totalUploadImageCount, imgPageNumber, limit);
  const hasMoreVideos = hasMoreItems(totalUploadVideoCount, videoPageNumber, limit);
  const hasMoreAudios = hasMoreItems(totalUploadAudioCount, audioPageNumber, limit);

  const fileSelectedHandler = e => {
    const files = Array.from(e.target.files);

    let containsImages = files.some(file => file.type.startsWith("image"));
    let containsVideos = files.some(file => file.type.startsWith("video"));
    let containsAudio = files.some(file => file.type.startsWith("audio"));

    if (containsImages && !containsVideos && !containsAudio) {
      setSchemeType("Images");
    } else if (!containsImages && containsVideos && !containsAudio) {
      setSchemeType("Videos");
    } else if (!containsImages && !containsVideos && containsAudio) {
      setSchemeType("Audio");
    }

    files.forEach(file => {
      let fileSize = file.size / 1e6;
      let fileFormat = file.type.split("/");
      let allowedImageTypes = ["jpeg", "jpg", "png", "svg+xml", "gif"];
      let allowedVideoTypes = ["mp4", "mov", "webm", "quicktime"];
      let allowedAudioTypes = ["mp3", "mp4a", "wav", "ogg", "mpeg"];
      let maxImageSize = 5; // in MB
      let maxVideoSize = 100; // in MB
      let maxAudioSize = 10; // in MB

      if (
        file &&
        (allowedImageTypes.includes(fileFormat[1]?.toLowerCase()) ||
          allowedVideoTypes.includes(fileFormat[1]?.toLowerCase()) ||
          allowedAudioTypes.includes(fileFormat[1]?.toLowerCase())) &&
        fileSize <=
          (allowedImageTypes.includes(fileFormat[1]?.toLowerCase())
            ? maxImageSize
            : allowedVideoTypes.includes(fileFormat[1]?.toLowerCase())
            ? maxVideoSize
            : maxAudioSize)
      ) {
        setUploadAssetError({ status: false, message: "success" });

        if (allowedImageTypes.includes(fileFormat[1]?.toLowerCase())) {
          // Handle image upload
          uploadAsset(file, "image");
        } else if (allowedVideoTypes.includes(fileFormat[1].toLowerCase())) {
          // Handle video upload
          uploadAsset(file, "video");
        } else if (allowedAudioTypes.includes(fileFormat[1].toLowerCase())) {
          // Handle audio upload
          uploadAsset(file, "audio");
        }
      } else if (
        fileSize >
        (allowedImageTypes.includes(fileFormat[1]?.toLowerCase())
          ? maxImageSize
          : allowedVideoTypes.includes(fileFormat[1]?.toLowerCase())
          ? maxVideoSize
          : maxAudioSize)
      ) {
        e.target.value = ""; // cleaning the file upload field
        setUploadAssetError({
          status: true,
          message: `File Size should be maximum ${
            allowedImageTypes.includes(fileFormat[1]?.toLowerCase())
              ? maxImageSize
              : allowedVideoTypes.includes(fileFormat[1]?.toLowerCase())
              ? maxVideoSize
              : maxAudioSize
          } MB.`,
        });
      } else {
        e.target.value = ""; // cleaning the file upload field
        setUploadAssetError({ status: true, message: "Invalid Format!!" });
      }

      setTimeout(function () {
        setUploadAssetError({ status: false, message: "" });
      }, 3000);
    });
  };

  //Function to upload selected images or videos
  const uploadAsset = (file, fileType) => {
    let formData = new FormData();
    formData.append("file", file, file.name);
    dispatch(uploadAssetFile(formData, fileType));
    setisUploadDone(false);
    if (imgel) {
      imgel.scrollTop = 0;
    } else if (vdoEl) {
      vdoEl.scrollTop = 0;
    }
  };

  const facebookUploadHandler = selectedImages => {
    selectedImages?.forEach(img => {
      dispatch(uploadFacebookImeges(img));
      setisUploadDone(false);
      el.scrollTop = 0;
    });
  };

  // intersection observer Function
  const observeLastNode = useCallback(
    (node, callback) => {
      if (assetLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          callback();
        }
      });
      if (node) observer.current.observe(node);
    },
    [assetLoading]
  );

  // observeLastNode function for images
  const lastImageElement = useCallback(
    node => {
      observeLastNode(node, () => {
        if (hasMoreImages) {
          setImgPageNumber(imgPageNumber + 1);
        }
      });
    },
    [observeLastNode, hasMoreImages, imgPageNumber]
  );

  // observeLastNode function for videos
  const lastVideoElement = useCallback(
    node => {
      observeLastNode(node, () => {
        if (hasMoreVideos) {
          setVideoPageNumber(videoPageNumber + 1);
        }
      });
    },
    [observeLastNode, hasMoreVideos, videoPageNumber]
  );

  // observeLastNode function for Audios
  const lastAudioElement = useCallback(
    node => {
      observeLastNode(node, () => {
        if (hasMoreAudios) {
          setAudioPageNumber(audioPageNumber + 1);
        }
      });
    },
    [observeLastNode, hasMoreAudios, audioPageNumber]
  );

  //Fetch upload image
  useEffect(() => {
    let payLoad = {
      limit: limit,
      pageNumber: imgPageNumber,
      file_type: "image",
    };
    dispatch(fetchUploadAssets(payLoad));
  }, [imgPageNumber]);

  //Fetch upload Video
  useEffect(() => {
    let payLoad = {
      limit: limit,
      pageNumber: videoPageNumber,
      file_type: "video",
    };
    dispatch(fetchUploadAssets(payLoad));
  }, [videoPageNumber]);

  //Fetch upload Video
  useEffect(() => {
    let payLoad = {
      limit: limit,
      pageNumber: audioPageNumber,
      file_type: "audio",
    };
    dispatch(fetchUploadAssets(payLoad));
  }, [audioPageNumber]);

  useEffect(() => {
    if (selctedFacebookImages) {
      facebookUploadHandler(selctedFacebookImages);
      setShowFBUploadModal(false);
    }
  }, [selctedFacebookImages]);

  // To handle scheme type for outside image or video
  useEffect(() => {
    if (uploadSchemeType && uploadSchemeType !== schemeType) {
      setSchemeType(uploadSchemeType);
    }
  }, [uploadSchemeType]);

  return (
    <div className={cx(style["editor-asset-inner"], style["upload-asset"])} id="upload-asset-container">
      <AssetName handleWidgetAction={props.handleWidgetAction} assetName={props.assetName} showTooltip={true} />

      <UploadAsset
        fileSelectedHandler={fileSelectedHandler}
        ulploadedImageListsLength={availableUploadImagesLists?.length}
        uploadAssetError={uploadAssetError}
        setUploadAssetError={setUploadAssetError}
        totalUploadedFiles={totalUploadedFiles}
        uploadedFilesNumber={uploadedFilesNumber}
        progressbarInfo={progressbarInfo ? progressbarInfo : 0}
        isUploadDone={isUploadDone}
        setisUploadDone={setisUploadDone}
        errorCount={errorCount}
        uploadFromFacebook={uploadFromFacebook}
        facebookError={facebookError}
        isFacebookAuthenticating={isFacebookAuthenticating}
        assetId={assetId}
        videoEnableUser={userInfo.video_enabled}
      />

      {(availableUploadImagesLists?.length > 0 ||
        availableUploadVideosLists?.length > 0 ||
        availableUploadAudiosLists?.length > 0) && (
        <div className={style["pr-20"]}>
          <AssetSchemeTypeNav
            schemeTypeArray={uploadSchemes}
            setSchemeType={setSchemeType}
            schemeType={schemeType}
            assetType={props.assetType}
            activeTabIndex={activeTabIndex}
          />
        </div>
      )}

      <TabContent>
        <div onContextMenu={e => e.preventDefault()} id="uploadListWrapper">
          <UploadedAssetList
            availableUploadImagesLists={availableUploadImagesLists}
            availableUploadVideosLists={availableUploadVideosLists}
            availableUploadAudiosLists={availableUploadAudiosLists}
            assetLoading={assetLoading}
            deleteImage={deleteImage}
            lastImageElement={lastImageElement}
            lastVideoElement={lastVideoElement}
            lastAudioElement={lastAudioElement}
            imgPageNumber={imgPageNumber}
            videoPageNumber={videoPageNumber}
            audioPageNumber={audioPageNumber}
            assetName={props.assetName}
            assetType={props.assetType}
            schemeType={schemeType}
          />
        </div>
      </TabContent>

      {showFBUploadModal && (
        <Modal
          showModal={showFBUploadModal}
          setShowModal={setShowFBUploadModal}
          component={facebookImageUploadModal}
          albums={albums}
          backdrop="static"
          className="modal-xl"
          modalClassName={"modal-with-leftpane facebookimage-modal"}
          setSelctedFacebookImages={setSelctedFacebookImages}
          setIsTokenActive={setIsTokenActive}
        />
      )}
    </div>
  );
};
//Props type validation
Upload.propTypes = {
  handleWidgetAction: PropTypes.func.isRequired,
  assetName: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
};

export default Upload;
