import React, { useContext } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import useCopyWidget from "../../../hooks/useCopyWidget";

let style = Object.assign({}, global);

const Copy = props => {
  let { keyBoardEvent, updateKeyBoardEvent, rightContextMenu, updateRightContextMenu } = useContext(EditorContext);

  useCopyWidget();

  const copyMethod = () => {
    updateKeyBoardEvent({ ...keyBoardEvent, copy: true });
    if (props.rightContext)
      updateRightContextMenu({
        ...rightContextMenu,
        enable: false,
      });
  };

  return (
    <li
      className={cx({
        [style["custom-tooltip"]]: !props.rightContext,
      })}>
      <span className={style["toolset-action"]} onClick={copyMethod}>
        <Icon icon="ui-clone2" />
        {props.rightContext && (
          <>
            Copy <span>Ctrl+C</span>
          </>
        )}
      </span>
      {!props.rightContext && <div className={style["custom-tooltip-content"]}>Copy</div>}
    </li>
  );
};

Copy.propTypes = {
  rightContext: PropTypes.bool,
};

export default Copy;
