import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { TABLE, TEXT } from "../../../constants/editor";
import useFontFormatting from "../../../hooks/useFontFormatting";

let style = Object.assign({}, global);

const Italic = ({ defaultFontItalic, setFontItalic, isDisabledItalic, sourceComponent }) => {
  let { metadata, widgets, doubleClickActive, selectionRange } = useContext(EditorContext);

  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
  let timeOut;

  const [isItalic, setIsItalic] = useState();
  const [isItalicDisable, setIsItalicDisable] = useState(isDisabledItalic ?? false);

  const { updateFontStyle, checkSelectedPartFormat } = useFontFormatting();

  const fonts = JSON.parse(localStorage?.getItem("allFonts"));

  const handleOnClick = () => {
    // if double click active return false cause selcted part will be italic
    if (doubleClickActive) return;

    // set italic for table
    if (sourceComponent === TABLE) {
      setFontItalic(!defaultFontItalic);
      return;
    }

    updateFontStyle(isItalic);

    isItalic ? setIsItalic(false) : setIsItalic(true);
  };

  const loadCurrentFormatting = () => {
    timeOut = setTimeout(() => {
      if (metadata.activeWidgetId && sourceComponent !== TABLE) {
        let innerHtmlflag = document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`);
        if (innerHtmlflag) {
          let currentFontFamily = innerHtmlflag.style.fontFamily;
          let index = fonts.findIndex(
            font => font.name.toLowerCase() === currentFontFamily.replace(/["]+/g, "").toLowerCase()
          );

          if (parseInt(innerHtmlflag.style.fontWeight) === 700)
            fonts[index]?.bold_italic_weight === "" ? setIsItalicDisable(true) : setIsItalicDisable(false);
          else fonts[index]?.normal_italic_weight === "" ? setIsItalicDisable(true) : setIsItalicDisable(false);
          innerHtmlflag.style.fontStyle === "italic" && fonts[index]?.source !== "Custom" ? setIsItalic(true) : setIsItalic(false);
        }
      }
    }, 10);
  };

  // Load current formatting for selected part of text
  useEffect(() => {
    if (selectionRange) {
      let currentFontFamily = document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`).style
        .fontFamily;
      let index = fonts.findIndex(
        font => font.name.toLowerCase() === currentFontFamily.replace(/["]+/g, "").toLowerCase()
      );
      let { isBoldSelPart, isaItalicSelPart } = checkSelectedPartFormat();

      setIsItalic(isaItalicSelPart);
      if (isBoldSelPart) fonts[index]?.bold_italic_weight === "" ? setIsItalicDisable(true) : setIsItalicDisable(false);
      else fonts[index]?.normal_italic_weight === "" ? setIsItalicDisable(true) : setIsItalicDisable(false);
    }
  }, [selectionRange]);

  // Load current formatting for text
  useEffect(() => {
    if (metadata.activeWidgetType[0] === TEXT) {
      loadCurrentFormatting();
    }
    return () => {
      setIsItalicDisable();
      setIsItalic();
      clearTimeout(timeOut);
    };
  }, [metadata.activeWidgetId, widgets[targetWidgetIndex]?.innerHTML]);

  // Disable bold option for table modal font-family change
  useEffect(() => {
    setIsItalicDisable(isDisabledItalic);
  }, [isDisabledItalic]);

  // Set italic for table
  useEffect(() => {
    setIsItalic(defaultFontItalic);
  }, [defaultFontItalic]);

  return (
    <li
      className={cx(
        style["toolset-group"],
        {
          [style["active"]]: isItalic,
        },
        {
          [style["disabled"]]: isItalicDisable,
        }
      )}>
      <div className={cx(style["toolset-item"], style["italic-wrap"])} id="italic-wrap">
        <span className={cx(style["toolset-action"], style["custom-tooltip"])} onClick={handleOnClick}>
          <Icon icon="ui-italic" />
          <div className={cx(style["custom-tooltip-content"], style["top"])}>Italic</div>
        </span>
      </div>
    </li>
  );
};

Italic.propTypes = {
  isDisabledItalic: PropTypes.bool,
  sourceComponent: PropTypes.string,
  defaultFontItalic: PropTypes.bool,
  setFontItalic: PropTypes.func,
};

export default Italic;
