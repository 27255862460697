import React, { useContext, useEffect, useRef, useState } from "react";
import cx from "classnames";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import ShareModal from "../Document/modal/shareModal";
import PublishModal from "../Document/modal/publishModal";
import PublishToTemplateLibraryModal from "../Document/modal/publishToTemplateLibraryModal";
import DownloadModal from "../Document/modal/downloadModal";
import EmailModal from "../Document/modal/emailModal";
import PublishToMailchimp from "./PublishToMailchimp";
import { authenticate, documentInfo, formatUrl } from "../Document/_utils";
import Modal from "../ui/modal";
import NoDocumentThumb from "../../assets/images/no-document-thumb.png";
import { USER_STATUS_JOINED as JOINED } from "../../constants/user";
import { COMPANY_STATUS_DEACTIVATED as DEACTIVATED, COMPANY_SUPERADMIN } from "../../constants/company";
import { EditorContext } from "../../containers/editor/EditorLayout";

import global from "../../scss/dhp.scss";
import { Icon } from "../ui/icon";
import { fetchTemplateTypes } from "../../store/actions/templateActions";
import { fetchCompany } from "../../store/actions/companyActions";
import { useCheckCompanyPlanInfo } from "../../hooks/useCheckCompanyPlanInfo";
import { useContextualUpgrade } from "../../hooks/useContextualUpgrade";
import { fetchSentEmailList } from "../../store/actions/documentActions";
import publishAsCompanyTemplateModal from "../Document/modal/publishAsCompanyTemplateModal";

const style = Object.assign({}, global);

const Email = ({ action, _helpers, data }) => {
  const payloadFetch = {
    page: 1,
    limit: 25,
  };
  const dispatch = useDispatch();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const showUpgrade = useContextualUpgrade();
  const paidCompanyInfo = useCheckCompanyPlanInfo(true);
  let { pageNodes, blockNodes, backgroundColors, backgroundImages, widgets, dimension, documentType } =
    useContext(EditorContext);
  const { exists: isPublished } = documentInfo.get({...data.publish, publish_id: data?.publish?.id, id: data?.id}, "publish_id");
  const userInfo = useSelector(state => state?.auth?.user);
  const { documentDetails } = useSelector(state => state?.document);

  const upgradeToTenEmails = () => {
    if (paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && !paidCompanyInfo?.isPaid) {
      // Call showUpgrade function to show contextual upgrade modal based on your condition
      showUpgrade("sendTenEmailDocumentLink", {
        docPagesMeta: { pageNodes, blockNodes, backgroundColors, backgroundImages, widgets, dimension, documentType },
      });
    }
  };

  const handleEmailClick = () => {
    if (!showUpgradeModal && action) {
      _helpers.modal.setComponent({
        name: action.modal,
        modalClassName: action.modalClassName,
        className: action.className,
      });
      _helpers.modal.toggle();
    }
  };

  const handleUpgradeClick = e => {
    // Preventing click to the parent item
    e.stopPropagation();
    if (!showUpgradeModal) {
      setShowUpgradeModal(true);
      upgradeToTenEmails();
    }
  };

  return (
    <>
      {!(isPublished === false && data.permission === "OWNER" === false) && (
        <DropdownItem tag="a" onClick={handleEmailClick}>
          <div className={cx(style["d-flex"])}>
            <Icon icon={"ui-email"} additionalclass={"mr-2"} /> <span>Email</span>
          </div>
          <div
            className={cx(style["custom-tooltip"], style["ml-2"], {
              [style["email-tooltip"]]: !paidCompanyInfo?.isPaid,
            })}>
            <Icon icon={"ui-info"} additionalclass="font-14" />
            <div className={cx(style["custom-tooltip-content"], style["bottom"])}>
              {paidCompanyInfo?.isPaid ? (
                <span>10 emails/day</span>
              ) : (
                <span>
                  1 email/day. <u onClick={handleUpgradeClick}>Upgrade for more</u>
                </span>
              )}
            </div>
          </div>
        </DropdownItem>
      )}
    </>
  );
};

const editorMoreActions = [
  {
    name: "Share",
    value: "share",
    icon: "ui-unshare",
    tooltip: false,
    type: "modal",
    modal: ShareModal,
    modalClassName: "share-modal modal-with-leftpane",
    className: "modal-lg",
  },
  {
    name: "Share",
    value: "reshare",
    icon: "ui-share",
    tooltip: false,
    type: "modal",
    modal: ShareModal,
    modalClassName: "share-modal modal-with-leftpane",
    className: "modal-lg",
  },
  {
    name: "Publish",
    value: "publish",
    icon: "ui-publish",
    tooltip: false,
    type: "modal",
    modal: PublishModal,
    modalClassName: "publish-modal",
    className: "",
  },
  {
    name: "Unpublish",
    value: "unpublish",
    icon: "ui-unpublish",
    tooltip: false,
    type: "modal",
    modal: PublishModal,
    modalClassName: "publish-modal",
    className: "",
  },
  {
    name: "Publish as Template",
    value: "publish_as_company_template",
    icon: "ui-company-templates",
    tooltip: false,
    type: "modal",
    modal: publishAsCompanyTemplateModal,
    modalClassName: "publish-company-template-modal",
    className: "",
  },
  {
    name: "Republish as Template",
    value: "republish_as_company_template",
    icon: "ui-company-templates",
    tooltip: false,
    type: "modal",
    modal: publishAsCompanyTemplateModal,
    modalClassName: "publish-company-template-modal",
    className: "",
  },
  {
    name: "Publish to Template Library",
    value: "publish_to_template_library",
    icon: "ui-right-2",
    tooltip: false,
    type: "modal",
    modal: PublishToTemplateLibraryModal,
    modalClassName: "",
    className: "",
  },
  {
    name: "Republish to Template Library",
    value: "republish_to_template_library",
    // icon: "ui-return",
    icon: "ui-right-2",
    tooltip: false,
    type: "modal",
    modal: PublishToTemplateLibraryModal,
    modalClassName: "",
    className: "",
  },
  {
    name: "Preview",
    value: "preview",
    icon: "eye2",
    tooltip: false,
    type: "url",
    path: `/documents/{documentId}/preview`,
    search: `?ref=editor`,
  },
  {
    name: "Present",
    value: "present",
    icon: "ui-present",
    tooltip: false,
    type: "url",
    path: `/documents/{documentId}/present`,
    search: `?ref=editor`,
  },
  {
    name: "Download",
    value: "download",
    icon: "ui-download",
    tooltip: false,
    type: "modal",
    modal: DownloadModal,
    modalClassName: "download-modal",
    className: "",
  },
];

const MoreActions = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const showUpgrade = useContextualUpgrade();
  const paidCompanyInfo = useCheckCompanyPlanInfo(true);

  const { setIsDocumentSave } = useContext(EditorContext);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [openMailchimp, setOpenMailchimp] = useState(false);
  const [validActions, setValidActions] = useState([]);
  const [isManagerSuperadmin, setIsManagerSuperadmin] = useState(false);
  const [isCompanySuperuser, setIsCompanySuperuser] = useState(false);
  const [isActiveUserPresent, setIsActiveUserPresent] = useState(false);
  const [isActiveCompanyPresent, setIsActiveCompanyPresent] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalComponent, setModalComponent] = useState(false);
  const [templateGroupObj, setTemplateGroupObj] = useState({});
  const [templateTypeObj, setTemplateTypeObj] = useState({});

  const myDropdown = useRef(null);

  const userInfo = useSelector(state => state?.auth?.user);
  const { companies, users } = useSelector(state => state?.company);
  const { templateGroups } = useSelector(state => state.template);
  const superadmin = userInfo?.superadmin;
  const isCompSuperAdmin = userInfo?.company?.superadmin_company;
  const documentDetails = props?.documentdetails;

  const thumb = {
    src: documentDetails?.image?.tiny,
    loading: true,
  };

  const [documentThumb, setDocumentThumb] = useState(thumb);

  const { exists: isShared } = documentInfo.get(documentDetails, "shared");
  const { exists: isPublished } = documentInfo.get({...documentDetails.publish, publish_id: documentDetails?.publish?.id, id: documentDetails?.id}, "publish_id");
  const { data: isPublishedAsCompanyTemplate } = documentInfo.get(documentDetails, "published_company_template");

  const documentData = {
    DOC_PERMISSION: documentDetails.permission,
    DOC_IS_SHARED: isShared,
    DOC_IS_PUBLISHED: isPublished,
    DOC_IS_PUBLISHED_AS_DOCHIPO_TEMPLATE: documentDetails.published_library_template,
    DOC_IS_PUBLISHED_AS_COMPANY_TEMPLATE: isPublishedAsCompanyTemplate || documentDetails.published_company_template,
  };

  const toggle = () => {
    setDropdownOpen(prevState => !prevState);
  };

  const mapTemplateArrayToObject = () => {
    let groupObj = {};
    let typeObj = {};
    templateGroups?.template_groups?.forEach(group => {
      groupObj[group.id] = group;
      group?.type?.forEach(type => {
        typeObj[type.id] = type;
      });
    });
    setTemplateGroupObj(groupObj);
    setTemplateTypeObj(typeObj);
  };

  const _helpers = {
    modal: {
      toggle: () => setShowModal(prevState => !prevState),
      setComponent: setModalComponent,
    },
    compiledData: {
      documentThumb: documentThumb,
    },
    permission: {
      isManagerSuperadmin: isManagerSuperadmin,
      isCompanySuperuser: isCompanySuperuser,
      isActiveUserPresent: isActiveUserPresent,
      isActiveCompanyPresent: isActiveCompanyPresent,
    },
    gridView: {
      templates: {
        groupObj: templateGroupObj,
        typeObj: templateTypeObj,
      },
    },
    ref: "editor",
  };

  const actionManager = action => {
    if (action.type === "url") {
      const url = formatUrl(action, documentDetails);
      if (["present", "preview"].includes(action.value)) {
        history.push(url);
      }
    }
    if (action.type === "modal") {
      if (["publish_as_company_template"].includes(action.value) && !paidCompanyInfo?.isPaid) {
        showUpgrade("companyTemplate", documentThumb.src);
        return;
      }

      _helpers.modal.setComponent({
        name: action.modal,
        modalClassName: action.modalClassName,
        className: action.className,
      });
      _helpers.modal.toggle();
    }
  };

  const handleImageLoad = () => setDocumentThumb({ ...documentThumb, loading: false });

  const handleImageError = () => setDocumentThumb({ ...documentThumb, src: NoDocumentThumb, loading: false });

  useEffect(() => {
    if (templateGroups) mapTemplateArrayToObject();
  }, [templateGroups]);

  useEffect(() => {
    (async () => setValidActions(await authenticate(editorMoreActions, documentData, _helpers?.permission)))();
    if (dropdownOpen) setIsDocumentSave(true);
  }, [dropdownOpen]);

  useEffect(() => {
    if (!showModal) setModalComponent(false);
  }, [showModal]);

  useEffect(() => {
    if (superadmin && isCompSuperAdmin) {
      setIsManagerSuperadmin(true);
    }
  }, [superadmin]);

  useEffect(() => {
    if (userInfo && users && companies) {
      setIsActiveUserPresent(
        users.filter(user => user.email !== userInfo?.email && user?.status === JOINED).length > 0
      );
      setIsActiveCompanyPresent(
        companies.filter(company => company.id !== userInfo?.company?.id && company?.status !== DEACTIVATED).length > 0
      );
      setIsCompanySuperuser(users.find(user => user.email === userInfo?.email && user?.superuser) !== undefined);
    }
  }, [userInfo, users, companies]);

  useEffect(() => {
    const image = new Image();
    image.src = thumb.src;
    image.addEventListener("load", handleImageLoad);
    image.addEventListener("error", handleImageError);
    if (!templateGroups) dispatch(fetchTemplateTypes(isCompSuperAdmin));
    if (!companies) dispatch(fetchCompany());
    return () => {
      image.removeEventListener("load", handleImageLoad);
      image.removeEventListener("error", handleImageError);
    };
  }, []);

  return (
    <>
      <li className={cx(style["more-action"])} ref={myDropdown} {...props}>
        <UncontrolledDropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle tag="a" className={cx(style["dropdown-toggle"], style["custom-tooltip"])}>
            <Icon icon="ui-more" />
            <div className={cx(style["custom-tooltip-content"], style["bottom"])}>More</div>
          </DropdownToggle>
          <DropdownMenu
            className={cx(style["shadow"], style["border-0"], style["rounded-sm"], {
              [style["mailchimp-area"]]: openMailchimp,
            })}>
            {!openMailchimp &&
              validActions.map((action, i) => (
                <DropdownItem
                  tag="a"
                  key={i}
                  className={cx({ [style["custom-tooltip"]]: action.tooltip })}
                  onClick={() => actionManager(action)}>
                  <Icon icon={action.icon} additionalclass={"mr-2"} /> <span>{action.name}</span>
                  {action.tooltip && <div className={style["custom-tooltip-content"]}>{action.name}</div>}
                </DropdownItem>
              ))}

            {!openMailchimp && (
              <Email
                action={{
                  modal: EmailModal,
                  modalClassName: "email-modal modal-with-leftpane",
                  className: "modal-lg",
                }}
                _helpers={_helpers}
                data={documentDetails}
              />
            )}

            <PublishToMailchimp
              setDropdownOpen={setDropdownOpen}
              dropdownOpen={dropdownOpen}
              setOpenMailchimp={setOpenMailchimp}
            />
          </DropdownMenu>
        </UncontrolledDropdown>
      </li>
      {showModal && (
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          component={modalComponent.name}
          modalClassName={modalComponent?.modalClassName}
          className={modalComponent?.className}
          document={{ ...documentDetails, compiled_thumb: documentThumb.src }}
          _helpers={_helpers}
        />
      )}
    </>
  );
};

MoreActions.propTypes = {
  documentdetails: PropTypes.object,
};

Email.propTypes = {
  action: PropTypes.object,
  _helpers: PropTypes.object,
  data: PropTypes.object,
};

export default MoreActions;
