import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Input as RInput } from "reactstrap";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { Input } from "../../ui/input";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { TYPE_PRESENTATION } from "../../../constants/editor";
import CanvasPageHtmlGenaretor from "../CanvasPageHtmlGenaretor";
import { preventFormSubmitOnEnter } from "../../../_helpers/utils";

let style = Object.assign({}, global);

const LinkModal = props => {
  let { metadata, widgets, pageNodes, documentType, updateWidgets, rightContextMenu, updateRightContextMenu } =
    useContext(EditorContext);

  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
  let pageType = documentType === TYPE_PRESENTATION ? "Slide" : "Page";

  const linkOptions = pageNodes.length === 1 ? ["Website", "Email"] : ["Website", "Email", pageType];
  const emailReg = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
  const urlReg = new RegExp(
    "(http|https)?(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)"
  );

  const [linkType, setLinkType] = useState("Website");
  const [linkValue, setLinkValue] = useState("");
  const [linkPageId, setLinkPageId] = useState("");
  const [isFormValid, setIsFormValid] = useState(true);
  const [placeHolderValue, setPlaceHolderValue] = useState();
  const [defaulDropdowntValue, setDefaultDropdownValue] = useState({});
  const [linkDropdownOptions, setLinkDropdownOptions] = useState();

  //Check email and webside input field validation onchange
  const checkValidity = e => {
    if (e.target.value.length === 0) {
      setIsFormValid(true);
      setLinkValue("");
    }
    //Check validation when start typing
    else {
      let regx = linkType === "Email" ? emailReg : urlReg;

      //if valid
      if (regx.test(e.target.value.trim())) {
        setIsFormValid(true);
        setLinkValue(e.target.value.trim());
      }
      //if not valid
      else {
        setIsFormValid(false);
        setLinkValue(e.target.value.trim());
      }
    }
  };

  //Apply value after submit the form
  const applyLink = () => {
    if (
      linkValue !== undefined ||
      document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-hyperlink-type")
    ) {
      let newArray;

      //if link is removed or value is blank
      if (linkValue === undefined || linkValue === "") {
        if (isGroupWidget) {
          document.getElementById(metadata.activeWidgetId[0]).removeAttribute("data-hyperlink-type");
          document.getElementById(metadata.activeWidgetId[0]).removeAttribute("data-hyperlink-url");
          document.getElementById(metadata.activeWidgetId[0]).removeAttribute("data-hyperlink-pageid");
          document.getElementById(metadata.activeWidgetId[0]).classList.remove("dhp-widget-hyperlinked");

          newArray = Object.assign([...widgets], {
            [targetWidgetIndex]: {
              ...widgets[targetWidgetIndex],
              innerHTML: document.getElementById(targetId).innerHTML,
            },
          });
        } else {
          let modifiedArray = JSON.parse(JSON.stringify(widgets));

          delete modifiedArray[targetWidgetIndex].data["data-hyperlink-type"];
          delete modifiedArray[targetWidgetIndex].data["data-hyperlink-url"];
          delete modifiedArray[targetWidgetIndex].data["data-hyperlink-pageid"];

          let newDataAttr = modifiedArray[targetWidgetIndex].data;

          newArray = Object.assign([...widgets], {
            [targetWidgetIndex]: {
              ...widgets[targetWidgetIndex],
              data: newDataAttr,
              classLists: widgets[targetWidgetIndex].classLists.filter(
                className => className !== "dhp-widget-hyperlinked"
              ),
            },
          });
        }
      }
      //if text field value is not blank
      else {
        if (isGroupWidget) {
          document.getElementById(metadata.activeWidgetId[0]).setAttribute("data-hyperlink-type", linkType);
          document.getElementById(metadata.activeWidgetId[0]).setAttribute("data-hyperlink-url", linkValue);
          document.getElementById(metadata.activeWidgetId[0]).setAttribute("data-hyperlink-pageid", linkPageId);
          document.getElementById(metadata.activeWidgetId[0]).classList.add("dhp-widget-hyperlinked");

          newArray = Object.assign([...widgets], {
            [targetWidgetIndex]: {
              ...widgets[targetWidgetIndex],
              innerHTML: document.getElementById(targetId).innerHTML,
            },
          });
        } else {
          newArray = Object.assign([...widgets], {
            [targetWidgetIndex]: {
              ...widgets[targetWidgetIndex],
              data: {
                ...widgets[targetWidgetIndex].data,
                "data-hyperlink-type": linkType,
                "data-hyperlink-url": linkValue,
                "data-hyperlink-pageid": linkPageId,
              },
              classLists: [...widgets[targetWidgetIndex].classLists, "dhp-widget-hyperlinked"],
            },
          });
        }
      }

      updateWidgets(newArray);
    }

    props.setShowModal(!props.showModal);

    if (rightContextMenu.enable)
      updateRightContextMenu({
        ...rightContextMenu,
        enable: false,
      });
  };

  const closeLinkModal = () => {
    props.setShowModal(!props.showModal);

    if (rightContextMenu.enable)
      updateRightContextMenu({
        ...rightContextMenu,
        enable: false,
      });
  };

  useEffect(() => {
    //Set placeholder for website and email input field
    linkType === "Website"
      ? setPlaceHolderValue("http://example.com")
      : linkType === "Email"
      ? setPlaceHolderValue("test@example.com")
      : "";

    //set input field value on link type change
    if (
      document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-hyperlink-type") &&
      linkType === document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-hyperlink-type")
    )
      setLinkValue(document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-hyperlink-url"));
    else setLinkValue("");
  }, [linkType]);

  useEffect(() => {
    //clear input field if link value is blank
    if (linkValue === undefined || linkValue === "") {
      setIsFormValid(true);
      setDefaultDropdownValue({ name: "" });

      if (["Website", "Email"].includes(linkType)) document.querySelector(".linkTextField").value = null;
    }
    //set input field name on change dropdaown value for link to another page
    else if (["Page", "Slide"].includes(linkType)) {
      setDefaultDropdownValue({
        name: `${pageType} ${parseInt(linkValue) + 1}`,
      });
    }
  }, [linkValue]);

  useEffect(() => {
    //set dropdown array for link to another page
    let newarray = [...pageNodes];
    newarray.splice(metadata.activePageIdx, 1);
    setLinkDropdownOptions(newarray);

    if (!document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-hyperlink-type")) return;

    //set link type and link value if it's previously added
    setLinkType(document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-hyperlink-type"));
    setLinkValue(document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-hyperlink-url"));
    setDefaultDropdownValue({
      name: `${pageType} ${
        parseInt(document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-hyperlink-url")) + 1
      }`,
    });
  }, []);

  return (
    <>
      <h4 className={cx(style["fw-7"], style["mb-4"])}>Set Hyperlink</h4>

      <span className={cx(style["cross-modal"], style["rounded"])} onClick={closeLinkModal}>
        <Icon icon="ui-close" />
      </span>

      <Form id="linkToAnotherPageForm" onKeyDown={e => preventFormSubmitOnEnter(e)}>
        <div className={cx(style["form-group"], style["mb-3"])}>
          {linkOptions.map((linkOption, index) => (
            <React.Fragment key={index}>
              <div className={cx(style["custom-control"], style["custom-radio"], style["custom-control-inline"])}>
                <RInput
                  type="radio"
                  value={linkOption}
                  name="linkTypeRadio"
                  className="custom-control-input"
                  id={`linkTypeRadio-${index}`}
                  onChange={() => setLinkType(linkOption)}
                  checked={linkType === linkOption}
                />
                <label className={style["custom-control-label"]} htmlFor={`linkTypeRadio-${index}`}>
                  {index !== 2 ? linkOption : `Link to another ${linkOption}`}
                </label>
              </div>
            </React.Fragment>
          ))}
        </div>

        <div className={cx(style["form-group"], style["mb-3"])}>
          {["Website", "Email"].includes(linkType) && (
            <div className="link-field clearfix">
              <RInput
                type="text"
                name="linkTextField"
                className="linkTextField"
                value={linkValue}
                placeholder={placeHolderValue}
                onChange={e => checkValidity(e)}
                autoComplete="off"
              />
            </div>
          )}

          {["Page", "Slide"].includes(linkType) && (
            <Input
              type="dropdown"
              name="linkWidget"
              classNamePrefix="select"
              defaultValue={defaulDropdowntValue}
              selected={linkValue}
              getOptionValue={option => `${linkType} ${parseInt(option?.pageIdx) + 1}`}
              getOptionLabel={option => (
                <>
                  <CanvasPageHtmlGenaretor
                    figureWidth={60}
                    figureHeight={60}
                    className={"linkPageList"}
                    pageNode={option}
                    pageType={pageType}
                  />
                </>
              )}
              options={linkDropdownOptions}
              updateState={option => {
                setDefaultDropdownValue({
                  name: `${pageType} ${parseInt(option.pageIdx) + 1}`,
                }),
                  setLinkValue(option.pageIdx),
                  setLinkPageId(option.pageId);
              }}
              noOptionsMessage={() => "No results found"}
            />
          )}

          <p
            className={cx(style["removelink"], style["text-right"], style["mt-2"], {
              [style["disable"]]: linkValue === undefined || linkValue === "",
            })}>
            <span onClick={() => setLinkValue("")}>
              <Icon icon="ui-delete-link" />
              Remove Link
            </span>
          </p>
        </div>

        <Button type="button" color="primary" disabled={!isFormValid} onClick={applyLink}>
          Apply
        </Button>
      </Form>
    </>
  );
};
LinkModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default LinkModal;
