import { useContext } from "react";
import { useSelector } from "react-redux";

import { GROUP_WIDGET, SHAPE, TYPE_INFOGRAPHIC } from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";
import { getCssTransformObj, getCssTransformObjToString, getRandomString } from "../_helpers/utils";
import useClipboard from "./useClipboard";
import useSelectable from "./useSelectable";
import useUploadInsertOutsideImage from "./useUploadInsertOutsideImage";
import useWidgetHandler from "./useWidgetHandler";
import useReplaceDuplicateShapeId from "./useReplaceDuplicateShapeId";
import useUpdatePageDuration from "./useUpdatePageDuration";

const usePasteWidget = () => {
  let {
    pageNodes,
    metadata,
    widgets,
    keyBoardEvent,
    updateMetadata,
    updateWidgets,
    updateKeyBoardEvent,
    dimension,
    blockNodes,
    documentType,
  } = useContext(EditorContext);

  // used global states
  const companyInfo = useSelector(state => state?.auth?.company);
  const documentDetails = useSelector(state => state?.document?.documentDetails?.data);

  // used custom Hooks
  const { toggleEventHandlers: toggleWidgetEventHandlers } = useWidgetHandler();
  const { hideAllWidgetSelection } = useSelectable();
  const { read: readFromClipboard } = useClipboard();
  const { uploadOutsideImage } = useUploadInsertOutsideImage();
  const { replaceDuplicateIdFromSVG } = useReplaceDuplicateShapeId();

  const getNewPos = (widgetWidth, widgetHeight) => {
    let left, top;
    let parentPos = document.getElementById(metadata.activeBlockId).getBoundingClientRect();
    let zoomval = 100 / parseFloat(dimension.zoom);

    // allignment with respect to active block for single widget
    left = (parentPos.width / 2) * zoomval - widgetWidth / 2;
    top = (parentPos.height / 2) * zoomval - widgetHeight / 2;

    return { left: left, top: top };
  };

  const pasteObj = async (targetLeft, targetTop) => {
    const { dataSource, dataType, data, isValidWidget } = await readFromClipboard();
    const {
      companyId,
      widgetObj: clipboardWidgetObj,
      documentId,
      docDimension,
      blockHeight,
      clipboardAction,
    } = data ?? {
      companyId: null,
      widgetObj: null,
      documentId: null,
      docDimension: null,
      blockHeight: null,
      clipboardAction: null,
    };

    let targetWidgetIndex;
    let updatedwidgetArray = [];
    let updatedWidgetId = [];
    let updatedWidgetAssetType = [];

    //Widget can be paste only under same company
    if (isValidWidget && dataSource === "dhp" && companyId === companyInfo?._id) {
      targetLeft = clipboardWidgetObj.length > 1 ? 0 : targetLeft;
      targetTop = clipboardWidgetObj.length > 1 ? 0 : targetTop;

      clipboardWidgetObj.forEach((element, idx) => {
        if (metadata.activeWidgetId.length === 1) {
          let id = metadata.activeWidgetId[0];
          let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
          let targetId = isGroupWidget
            ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id")
            : id;
          targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
        }
        let clipBordObjAssetType = clipboardWidgetObj[idx].data["data-asset-type"];
        let newWidgetId =
          clipBordObjAssetType !== GROUP_WIDGET
            ? "dhp-widget-" + getRandomString(8)
            : "dhp-group-" + getRandomString(8);
        let widgetTransform =
          targetWidgetIndex && clipboardWidgetObj[idx].blockId === metadata.activeBlockId && clipboardAction !== "cut"
            ? widgets[targetWidgetIndex].style.transform
            : clipboardWidgetObj[idx].style.transform;
        let widgettranslateValue = getCssTransformObj({ transform: widgetTransform, exclude: ["rotate", "scale"] });
        let delta = clipboardWidgetObj[idx].blockId === metadata.activeBlockId && clipboardAction !== "cut" ? 25 : 0;
        let widgetTransformObj;

        // Copy paste item in diff size doc (widget will paste in the middle of the page/block)
        if (
          documentId !== documentDetails.id &&
          clipboardWidgetObj.length === 1 &&
          (dimension.width !== docDimension.width ||
            dimension.height !== docDimension.height ||
            dimension.orientation !== docDimension.orientation)
        ) {
          let getPosition = getNewPos(
            parseFloat(clipboardWidgetObj[idx].style.width),
            parseFloat(clipboardWidgetObj[idx].style.height)
          );
          widgetTransformObj = getCssTransformObj({
            transform: widgetTransform,
            translateX: `${targetLeft ? targetLeft : getPosition.left}px`,
            translateY: `${targetTop ? targetTop : getPosition.top}px`,
          });
        } else {
          // for infographic type document if paste widget in same doc but diff block (diff height)
          if (
            documentType === TYPE_INFOGRAPHIC &&
            blockHeight &&
            parseFloat(blockNodes[metadata.activeBlockIdx].style.height) !== parseFloat(blockHeight)
          ) {
            let getPosition = getNewPos(
              parseFloat(clipboardWidgetObj[idx].style.width),
              parseFloat(clipboardWidgetObj[idx].style.height)
            );
            widgetTransformObj = getCssTransformObj({
              transform: widgetTransform,
              translateX: `${targetLeft ? targetLeft : getPosition.left}px`,
              translateY: `${targetTop ? targetTop : getPosition.top}px`,
            });
          } else {
            widgetTransformObj = getCssTransformObj({
              transform: widgetTransform,
              translateX: `${targetLeft ? targetLeft : parseFloat(widgettranslateValue.translate.x) + delta}px`,
              translateY: `${targetTop ? targetTop : parseFloat(widgettranslateValue.translate.y) + delta}px`,
            });
          }
        }

        let widgetTransformString = getCssTransformObjToString({ transformObj: widgetTransformObj });

        // update innerWidgtes id if a group widget is coppied
        if (clipBordObjAssetType === GROUP_WIDGET) {
          let copiedObjIneerHtml = clipboardWidgetObj[idx].innerHTML;
          let targetDOM = document.createElement("div");
          targetDOM.innerHTML = copiedObjIneerHtml;
          targetDOM.querySelectorAll(".dhp-page-widget").forEach(element => {
            element.setAttribute("id", "dhp-widget-" + getRandomString(8));
          });
          targetDOM.querySelectorAll(".dhp-page-group").forEach(element => {
            element.setAttribute("id", "dhp-group-" + getRandomString(8));
          });

          replaceDuplicateIdFromSVG(targetDOM);

          clipboardWidgetObj[idx].innerHTML = targetDOM.innerHTML;
        }
        // Replace duplicate id for shape use in shape border
        if (clipBordObjAssetType === SHAPE && clipboardWidgetObj[idx].data["data-border-style"] === "solid") {
          let copiedObjIneerHtml = clipboardWidgetObj[idx].innerHTML;
          let targetDOM = document.createElement("div");
          targetDOM.innerHTML = copiedObjIneerHtml;

          replaceDuplicateIdFromSVG(targetDOM, clipboardWidgetObj[idx]);

          clipboardWidgetObj[idx].innerHTML = targetDOM.innerHTML;
        }

        //Modify new widget with new widget id and position
        let updatedClipboardObj = {
          ...clipboardWidgetObj[idx],
          pageId: metadata.activePageId,
          blockId: metadata.activeBlockId,
          id: newWidgetId,
          data: {
            ...clipboardWidgetObj[idx].data,
            "data-x-allignment":
              documentId === documentDetails.id
                ? (targetWidgetIndex || targetWidgetIndex === 0) &&
                  clipboardWidgetObj[idx].blockId === metadata.activeBlockId
                  ? false
                  : clipboardWidgetObj[idx].data["data-x-allignment"]
                : "center",
            "data-y-allignment":
              documentId === documentDetails.id
                ? (targetWidgetIndex || targetWidgetIndex === 0) &&
                  clipboardWidgetObj[idx].blockId === metadata.activeBlockId
                  ? false
                  : clipboardWidgetObj[idx].data["data-y-allignment"]
                : "middle",
          },
          style: { ...clipboardWidgetObj[idx].style, transform: widgetTransformString },
        };

        updatedwidgetArray.push(updatedClipboardObj);
        updatedWidgetId.push(newWidgetId);
        updatedWidgetAssetType.push(clipboardWidgetObj[idx].data["data-asset-type"]);
      });

      //Update Context values
      updateWidgets([...widgets, ...updatedwidgetArray]);

      // Note : timeout is given for - handler is not move to new pasted widget for keyboard event (For multi selct widget)
      setTimeout(() => {
        updateMetadata({
          ...metadata,
          activeWidgetId: updatedWidgetId,
          activeWidgetType: updatedWidgetAssetType,
        });
        updateKeyBoardEvent({ ...keyBoardEvent, paste: false });
        toggleWidgetEventHandlers({ action: "SHOW" });
        hideAllWidgetSelection({ exceptions: updatedWidgetId, type: "group" });
      }, 30);
    }

    // For outside image, upload image
    if (isValidWidget && dataSource === "non_dhp" && dataType === "image/png") {
      uploadOutsideImage(data);
    }
  };

  return { pasteObj };
};

export default usePasteWidget;
