import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

let style = Object.assign({}, global);

const CommonLineLoader = ({ width = 25, height = "24px", loaderClass, sourceComponent }) => {
  let addclass = "";

  if (loaderClass) {
    loaderClass.split(" ")?.forEach(cls => (addclass += style[cls] ? `${style[cls]} ` : ""));
    addclass = addclass.trim();
  }

  return (
    <div
      className={cx("skeleton-loader-area", addclass)}
      style={sourceComponent === "editor" ? { width: `${width}%` } : {}}>
      <span
        className={cx(style["loader-item"])}
        style={{ width: `${width}%`, height: height, borderRadius: "0.175rem" }}></span>
    </div>
  );
};

CommonLineLoader.propTypes = {
  width: PropTypes.number,
  height: PropTypes.string,
  loaderClass: PropTypes.string,
  sourceComponent: PropTypes.string,
};

export default CommonLineLoader;
