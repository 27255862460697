import { useContext } from "react";
import {
  getCssTextObj,
  getCssTextObjToString,
  getCssTransformObj,
  getStringToValidJSON,
  getUnScaledValue,
  getZoomedValue,
  setMultiAttrs,
} from "../_helpers/utils";
import { N, S, W, E, NW, NE, SW, SE, VIDEO } from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";
import UseCheckWidgetAllignment from "./UseCheckWidgetAllignment";
import useCheckWidgetPosition from "./useCheckWidgetPosition";
import useDeleteWidget from "./useDeleteWidget";
const useEightHandlerResize = () => {
  let { widgets, updateWidgets, dimension } = useContext(EditorContext);

  const { checkWidgetAllignmentForSingleWidget } = UseCheckWidgetAllignment();
  const deleteWidgetIfOutside = useCheckWidgetPosition();
  const setDeleteWidget = useDeleteWidget();

  const formatData = (type, data) => {
    let defaultData = {
      transform: { translateX: "0px", translateY: "0px", scaleX: 1, scaleY: 1 },
      crop: { n: 0, s: 0, w: 0, e: 0 },
    };
    return { ...(defaultData[type] ?? {}), ...data };
  };
  const eightHandlerResizeStart = data => {
    let resizableSideEffects = data;
    let widgetInner = resizableSideEffects.client.node.getElementsByClassName("dhp-widget-inner")[0];
    let widgetInnerCssObj = getCssTextObj(widgetInner.style.cssText);
    let widgetNode =
      VIDEO === resizableSideEffects.client.assetType
        ? widgetInner.getElementsByTagName("video")[0]
        : widgetInner.getElementsByTagName("img")[0];
    let cropData = getStringToValidJSON(widgetInner.dataset.crop) || {};
    cropData = formatData("crop", cropData);

    // check border is applied or not
    let isBorderApplied = false;
    let widgetInnerFlipable = resizableSideEffects.client.node.getElementsByClassName("flippable")[0]?.style;
    if (widgetInnerFlipable && widgetInnerFlipable?.borderStyle && widgetInnerFlipable.borderStyle !== "none")
      isBorderApplied = true;

    // crop preview start
    let cropWrapper = resizableSideEffects.handler.getElementsByClassName("crop-preview")[0];
    let targetNode = cropWrapper.getElementsByClassName("thumb")[0];
    let cropWrapperTransform = getCssTransformObj({ transform: widgetInnerCssObj.transform });

    const transformObjCropPreview = getCssTransformObj({
      translateX: `${getZoomedValue(parseFloat(cropWrapperTransform.translate.x), dimension.zoom)}px`,
      translateY: `${getZoomedValue(parseFloat(cropWrapperTransform.translate.y), dimension.zoom)}px`,
      scaleX: cropWrapperTransform.scale.x,
      scaleY: cropWrapperTransform.scale.y,
      exclude: ["rotate"],
      returnStr: true,
    });

    cropWrapper.style.cssText = `
      width: ${getZoomedValue(parseFloat(widgetInnerCssObj.width), dimension.zoom)}px;
      height: ${getZoomedValue(parseFloat(widgetInnerCssObj.height), dimension.zoom)}px;
      transform: ${transformObjCropPreview};
      transform-origin: left top;
      opacity: 0.3;
      position: relative;
    `;

    setMultiAttrs(targetNode, {
      src:
        VIDEO === resizableSideEffects.client.assetType
          ? widgetNode.getAttribute("poster")
          : widgetNode.getAttribute("src"),
      style: `filter: ${widgetNode.style?.filter}; transform: ${widgetNode.style?.transform}`,
    });

    if (VIDEO === resizableSideEffects.client.assetType) {
      widgetNode.style.objectFit = "fill";
    }
    // crop preview end

    resizableSideEffects.crop = {
      isCropping: true,
      original: {
        cropData: cropData,
      },
      preview: {
        wrapper: cropWrapper,
        node: targetNode,
        isBorderApplied
      },
    };
  };
  const eightHandlerResize = (meta, widgetHeight, widgetWidth) => {
    if (!meta.crop.preview.isBorderApplied) meta.crop.preview.wrapper.classList.remove("d-none");
    else meta.crop.preview.wrapper.classList.add("d-none");

    let widgetInnerFlipable = meta.client.node.getElementsByClassName("flippable")[0];
    let widgetInner = meta.client.node.getElementsByClassName("dhp-widget-inner")[0];
    let widgetInnerCssObj = getCssTextObj(widgetInner.style.cssText);

    let pictureWidth = parseFloat(widgetInnerCssObj?.width || 0);
    let pictureHeight = parseFloat(widgetInnerCssObj?.height || 0);
    let transformData = {};
    let cropData = getStringToValidJSON(widgetInner.dataset.crop) || {};
    let scaleData = {};
    let scaleXFactor = 1 / pictureWidth;
    let scaleYFactor = 1 / pictureHeight;
    let translateX, translateY, scaleX, scaleY, scaledWidth, scaledHeight, isFirstCropEvent;

    transformData = formatData("transform", transformData);
    cropData = formatData("crop", cropData);

    const initCropData = meta.crop.original.cropData;
    const correctedCropData = {
      n: cropData.n,
      w: cropData.w,
    };

    // North Resizer | Top
    if (meta.resizer.type === N) {
      if (widgetHeight + cropData.s <= pictureHeight) {
        // crop
        if (!isFirstCropEvent) {
          isFirstCropEvent = true;
          correctedCropData.w = initCropData.w;
          correctedCropData.e = initCropData.e;
        }

        translateY = pictureHeight - (widgetHeight + cropData.s);

        transformData = {
          ...transformData,
          translateX: -correctedCropData.w + "px",
          translateY: -translateY + "px",
        };
        cropData = {
          ...cropData,
          ...correctedCropData,
          n: translateY,
        };
      } else {
        // scale
        isFirstCropEvent = false;
        scaleX = scaleY = (widgetHeight + cropData.s) * scaleYFactor;
        scaledWidth = pictureWidth * scaleX;
        scaledHeight = pictureHeight * scaleY;
        translateX = cropData.w + (scaledWidth - widgetWidth - (cropData.w + cropData.e)) / 2;

        transformData = {
          ...transformData,
          translateX: -translateX + "px",
          translateY: 0 + "px",
          scaleX: scaleX,
          scaleY: scaleY,
        };
        cropData = {
          ...cropData,
          n: 0,
          w: translateX,
          e: scaledWidth - (widgetWidth + translateX),
        };
        scaleData = {
          ...scaleData,
          width: scaledWidth + "px",
          height: scaledHeight + "px",
        };
      }
    }

    // South Resizer | Bottom
    else if (meta.resizer.type === S) {
      if (widgetHeight + cropData.n <= pictureHeight) {
        // crop
        if (!isFirstCropEvent) {
          isFirstCropEvent = true;
          correctedCropData.w = initCropData.w;
          correctedCropData.e = initCropData.e;
        }

        transformData = {
          ...transformData,
          translateX: -correctedCropData.w + "px",
          translateY: -cropData.n + "px",
        };
        cropData = {
          ...cropData,
          ...correctedCropData,
          s: pictureHeight - (widgetHeight + cropData.n),
        };
      } else {
        // scale
        isFirstCropEvent = false;
        scaleX = scaleY = (widgetHeight + cropData.n) * scaleYFactor;
        scaledWidth = pictureWidth * scaleX;
        scaledHeight = pictureHeight * scaleY;

        translateX = cropData.w + (scaledWidth - widgetWidth - (cropData.w + cropData.e)) / 2;

        transformData = {
          ...transformData,
          translateX: -translateX + "px",
          translateY: -cropData.n + "px",
          scaleX: scaleX,
          scaleY: scaleY,
        };
        cropData = {
          ...cropData,
          s: 0,
          w: translateX,
          e: scaledWidth - (widgetWidth + translateX),
        };
        scaleData = {
          ...scaleData,
          width: scaledWidth + "px",
          height: scaledHeight + "px",
        };
      }
    }

    // West Resizer | Left
    else if (meta.resizer.type === W) {
      if (widgetWidth + cropData.e <= pictureWidth) {
        // crop
        if (!isFirstCropEvent) {
          isFirstCropEvent = true;
          correctedCropData.n = initCropData.n;
          correctedCropData.s = initCropData.s;
        }

        translateX = pictureWidth - (widgetWidth + cropData.e);

        transformData = {
          ...transformData,
          translateX: -translateX + "px",
          translateY: -correctedCropData.n + "px",
        };
        cropData = {
          ...cropData,
          ...correctedCropData,
          w: translateX,
        };
      } else {
        // scale
        isFirstCropEvent = false;
        scaleX = scaleY = (widgetWidth + cropData.e) * scaleXFactor;
        scaledWidth = pictureWidth * scaleX;
        scaledHeight = pictureHeight * scaleY;

        translateX = scaledWidth - widgetWidth - cropData.e;
        translateY = cropData.n + (scaledHeight - widgetHeight - (cropData.n + cropData.s)) / 2;

        transformData = {
          ...transformData,
          translateX: -translateX + "px",
          translateY: -translateY + "px",
          scaleX: scaleX,
          scaleY: scaleY,
        };
        cropData = {
          ...cropData,
          w: 0,
          n: translateY,
          s: scaledHeight - (widgetHeight + translateY),
        };
        scaleData = {
          ...scaleData,
          width: scaledWidth + "px",
          height: scaledHeight + "px",
        };
      }
    }

    // East Resizer | Right
    else if (meta.resizer.type === E) {
      if (widgetWidth + cropData.w <= pictureWidth) {
        // crop
        if (!isFirstCropEvent) {
          isFirstCropEvent = true;
          correctedCropData.n = initCropData.n;
          correctedCropData.s = initCropData.s;
        }

        transformData = {
          ...transformData,
          translateX: -cropData.w + "px",
          translateY: -correctedCropData.n + "px",
        };
        cropData = {
          ...cropData,
          ...correctedCropData,
          e: pictureWidth - (widgetWidth + cropData.w),
        };
      } else {
        // scale
        isFirstCropEvent = false;
        scaleX = scaleY = (widgetWidth + cropData.w) * scaleXFactor;
        scaledWidth = pictureWidth * scaleX;
        scaledHeight = pictureHeight * scaleY;

        translateY = cropData.n + (scaledHeight - widgetHeight - (cropData.n + cropData.s)) / 2;

        transformData = {
          ...transformData,
          translateX: -cropData.w + "px",
          translateY: -translateY + "px",
          scaleX: scaleX,
          scaleY: scaleY,
        };
        cropData = {
          ...cropData,
          e: 0,
          n: translateY,
          s: scaledHeight - (widgetHeight + translateY),
        };
        scaleData = {
          ...scaleData,
          width: scaledWidth + "px",
          height: scaledHeight + "px",
        };
      }
    }

    // North-West Resizer | Top-Left
    // North-East Resizer | Top-Right
    // South-West Resizer | Bottom-Left
    // South-East Resizer | Bottom-Right
    else if ([NW, NE, SW, SE].includes(meta.resizer.type)) {
      // scale up-down
      scaleX = pictureWidth / getUnScaledValue(meta.client.initial.height, dimension.zoom);
      scaleY = pictureHeight / getUnScaledValue(meta.client.initial.width, dimension.zoom);
      scaledWidth = widgetHeight * scaleX;
      scaledHeight = widgetWidth * scaleY;

      translateX = scaledWidth * (meta.crop.original.cropData.w / pictureWidth);
      translateY = scaledHeight * (meta.crop.original.cropData.n / pictureHeight);

      transformData = {
        ...transformData,
        translateX: -translateX + "px",
        translateY: -translateY + "px",
        scaleX: scaledWidth / pictureWidth,
        scaleY: scaledHeight / pictureHeight,
      };
      cropData = {
        ...cropData,
        w: translateX,
        n: translateY,
        s: scaledHeight * (meta.crop.original.cropData.s / pictureHeight),
        e: scaledWidth * (meta.crop.original.cropData.e / pictureWidth),
      };
      scaleData = {
        ...scaleData,
        width: scaledWidth + "px",
        height: scaledHeight + "px",
      };
    }

    const transformStr = getCssTransformObj({
      ...transformData,
      exclude: ["rotate"],
      returnStr: true,
    });

    const transformStrCropPreview = getCssTransformObj({
      ...transformData,
      translateX: `${getZoomedValue(parseFloat(transformData.translateX), dimension.zoom)}px`,
      translateY: `${getZoomedValue(parseFloat(transformData.translateY), dimension.zoom)}px`,
      exclude: ["rotate"],
      returnStr: true,
    });

    const cssText = getCssTextObjToString({
      ...widgetInnerCssObj,
      transform: transformStr,
      // "will-change": "transform" // bugfix line issue on resize
    });

    setMultiAttrs(widgetInner, {
      "data-crop": JSON.stringify(cropData),
      "data-scale": JSON.stringify(scaleData),
    });

    widgetInner.style.cssText = cssText;
    let targetWidget = document.getElementById(meta.client.id);
    widgetInnerFlipable.style.height = targetWidget.style.height;
    widgetInnerFlipable.style.width = targetWidget.style.width;
    widgetInnerFlipable.style.overflow = "hidden";

    // crop preview
    meta.crop.preview.wrapper.style.cssText = `
      width: ${getZoomedValue(parseFloat(widgetInnerCssObj.width), dimension.zoom)}px;
      height: ${getZoomedValue(parseFloat(widgetInnerCssObj.height), dimension.zoom)}px;
      transform: ${transformStrCropPreview};
      transform-origin: left top;
      opacity: 0.3;
      position: relative;
    `;
  };

  const eightHandlerResizeStop = meta => {
    let targetWidget = document.getElementById(meta.client.id);
    let updatedCss = meta.client.current;
    let widgetInnerFlipable = meta.client.node.getElementsByClassName("flippable")[0];
    let widgetInner = meta.client.node.getElementsByClassName("dhp-widget-inner")[0];
    let widgetInnerCssObj = getCssTextObj(widgetInner.style.cssText);
    let scaleData = getStringToValidJSON(widgetInner.dataset.scale) || {};

    const transformStr = getCssTransformObj({
      scaleX: 1,
      scaleY: 1,
      transform: widgetInner.style.transform,
      exclude: ["rotate"],
      returnStr: true,
    });

    // delete widgetInnerCssObj["will-change"]; // bugfix line issue on resize

    const cssText = getCssTextObjToString({
      ...widgetInnerCssObj,
      ...scaleData,
      transform: transformStr,
    });

    setMultiAttrs(widgetInner, {
      "data-scale": "{}",
      style: cssText
    });

    widgetInnerFlipable.style.height = targetWidget.style.height;
    widgetInnerFlipable.style.width = targetWidget.style.width;

    // update to react dom
    targetWidget.innerHTML = widgetInnerFlipable.outerHTML;

    const { x_al, y_al } = checkWidgetAllignmentForSingleWidget(
      updatedCss.height,
      updatedCss.transform,
      updatedCss.width
    );
    const isDletable = deleteWidgetIfOutside(meta.client.id);

    // Check widget position during resize, if it is outside canvas area delete the widget else upadte new position
    if (isDletable) setDeleteWidget(true);
    else {
      updateWidgets(
        widgets.map((widget, idx) => {
          if (idx === meta.client.idx) {
            return {
              ...widget,
              style: {
                ...widget.style,
                ...updatedCss,
              },
              data: {
                ...widget.data,
                "data-x-allignment": x_al,
                "data-y-allignment": y_al,
              },
              innerHTML: widgetInnerFlipable.outerHTML,
            };
          } else {
            return widget;
          }
        })
      );
    }

    let cropWrapper = meta.handler.getElementsByClassName("crop-preview")[0];

    let targetNode = cropWrapper.getElementsByClassName("thumb")[0];

    // crop preview start
    cropWrapper.style.cssText = ``;
    setMultiAttrs(targetNode, {
      src: "",
    });
    cropWrapper.classList.add("d-none");
    // crop preview end

    meta.crop.isCropping = false;
  };
  return [eightHandlerResizeStart, eightHandlerResize, eightHandlerResizeStop];
};
export default useEightHandlerResize;
