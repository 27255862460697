import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { DropdownMenu } from "reactstrap";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { contextualConfig } from "../editor_config";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import UseCheckWidgetAllignment from "../../../hooks/UseCheckWidgetAllignment";
import {
  calculateNewPositionOnRotatedObjectResize,
  getCssTransformObj,
  getUpdatedGroupInnerWidgetPos,
  getZoomedValue,
  updateHeightAndTopForInactiveGroupChildren,
} from "../../../_helpers/utils";

let style = Object.assign({}, global);

const TransformAction = () => {
  let { metadata, widgets, updateWidgets, dimension } = useContext(EditorContext);

  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
  let groupWidgetInitHeight = isGroupWidget && parseFloat(isGroupWidget.style.height);

  const [textTransform, setTextTransform] = useState();
  const [updateContext, setUpdateContext] = useState(false);
  const [loadCurrentFormatingHeight, setLoadCurrentFormatingHeight] = useState(true);

  const { checkWidgetAllignmentForSingleWidget } = UseCheckWidgetAllignment();

  //Load current formatting
  useEffect(() => {
    if (metadata.activeWidgetId) {
      let innerHtmlflag = document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`);
      setTextTransform(innerHtmlflag.style.textTransform === "inherit" ? "default" : innerHtmlflag.style.textTransform);
      setLoadCurrentFormatingHeight(true);
    }
  }, [metadata.activeWidgetId]);

  //update when textTransform value is change
  useEffect(() => {
    let newArray;

    document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style.textTransform =
      textTransform === "default" ? "inherit" : textTransform;

    // if the text is under a group widget
    if (isGroupWidget) {
      // Get group updated height and inner widget updated top and height according group
      let { groupDivHeight, parcentHeight, parcentTop } = getUpdatedGroupInnerWidgetPos(
        targetId,
        id,
        document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight
      );

      // update DOM and group handler
      document.getElementById(targetId).style.height = `${groupDivHeight}px`;
      document.getElementById(id).style.height = parcentHeight;
      document.getElementById(id).style.top = parcentTop;
      document.getElementById("dhp-widget-handler").style.height = `${getZoomedValue(
        groupDivHeight,
        dimension.zoom
      )}px`;

      // update height and top for inactive child widgets
      updateHeightAndTopForInactiveGroupChildren({
        group: isGroupWidget,
        groupOldHeight: groupWidgetInitHeight,
        groupNewHeight: groupDivHeight,
        activeChildId: id,
      });

      newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgets[targetWidgetIndex],
          style: {
            ...widgets[targetWidgetIndex].style,
            height: `${groupDivHeight}px`,
          },
          innerHTML: document.getElementById(targetId).innerHTML,
        },
      });
    }
    // if the text is a main widget
    else {
      const newHeight = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight;
      const { x_al, y_al } = checkWidgetAllignmentForSingleWidget(newHeight);

      const activeWidgetDOM = document.getElementById(metadata.activeWidgetId[0]);
      const widgetObject = widgets[targetWidgetIndex];
      const {
        translate: { x: widgetTransX, y: widgetTransY },
        rotate: { theta: widgetTheta },
      } = getCssTransformObj({
        transform: widgetObject.style.transform,
      });
      const { left, top } = calculateNewPositionOnRotatedObjectResize(
        parseFloat(widgetTransX),
        parseFloat(widgetTransY),
        parseFloat(widgetObject.style.width),
        parseFloat(newHeight),
        parseFloat(widgetObject.style.width),
        parseFloat(widgetObject.style.height),
        parseFloat(widgetTheta)
      );
      const widgetTransformStr = getCssTransformObj({
        translateX: `${left}px`,
        translateY: `${top}px`,
        transform: widgetObject.style.transform,
        returnStr: true,
      });

      // set handler
      let handler = document.getElementById("dhp-widget-handler");
      const handlerTransformStr = getCssTransformObj({
        translateX: `${getZoomedValue(left, dimension.zoom)}px`,
        translateY: `${getZoomedValue(top, dimension.zoom)}px`,
        transform: widgetObject.style.transform,
        returnStr: true,
      });

      // update handler and widget height and transform in dom
      if (!loadCurrentFormatingHeight) {
        activeWidgetDOM.style.height = `${newHeight}px`;
        activeWidgetDOM.style.transform = widgetTransformStr;
        handler.style.height = `${getZoomedValue(newHeight, dimension.zoom)}px`;
        handler.style.transform = handlerTransformStr;
      }

      newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgetObject,
          style: {
            ...widgetObject.style,
            height: `${newHeight}px`,
            transform: widgetTransformStr
          },
          data: {
            ...widgetObject.data,
            "data-x-allignment": x_al,
            "data-y-allignment": y_al,
          },
          innerHTML: document.getElementById(metadata.activeWidgetId[0]).innerHTML,
        },
      });
    }

    updateContext && updateWidgets(newArray);
    setUpdateContext(false);
  }, [textTransform, updateContext]);

  return (
    <DropdownMenu
      tag="ul"
      className={cx(style["border-0"], style["toolbar-dropdown"], style["four-item"], style["rounded"])}>
      {contextualConfig.TextTransform.map((list, index) => (
        <React.Fragment key={index}>
          <li
            className={cx(style["custom-tooltip"], {
              [style["active"]]: list === textTransform,
            })}
            onClick={() => {
              setTextTransform(list), setUpdateContext(true), setLoadCurrentFormatingHeight(false);
            }}>
            <span className={style["toolset-action"]}>
              <Icon
                icon={list === "default" ? `ui-text-${list}` : list === "lowercase" ? `ui-${list}-2` : `ui-${list}`}
              />
            </span>

            <div className={cx(style["custom-tooltip-content"], style["text-capitalize"])}>{list}</div>
          </li>
        </React.Fragment>
      ))}
    </DropdownMenu>
  );
};

export default TransformAction;
