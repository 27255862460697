const useClipboard = () => {
  //NOTE:::: written based on Singleton pattern
  const CB = {
    read: async () => {
      // NOTE:::: Checking "read" permission will easily determine the previously stored clipboard medium (navigator.clipboard / localStorage)
      // const hasPermission = await clipboard.hasPermissions(["read"]);
      // if (hasPermission) return await clipboard.read();
      // else
      return {
        dataProvider: "window.localStorage",
        dataSource: "dhp",
        dataType: "text/plain",
        data: JSON.parse(localStorage.getItem("ClipboardData")),
        isValidWidget: true,
      };
    },

    write: async data => {
      // NOTE:::: Checking "read" permission is important to know whether browser has access to clipboard reading capabilities, otherwise it's useless to write in clipboard.
      // const hasPermission = await clipboard.hasPermissions(["read", "write"]);
      // if (hasPermission) await clipboard.write(JSON.stringify(data));
      // else
      localStorage.setItem("ClipboardData", JSON.stringify(data));
    },
  };

  return { read: CB.read, write: CB.write };
};

export default useClipboard;
