import * as actionTypes from "./actionTypes";
import { resourceRequestManager as API, connectorRequestManager as connectorAPI } from "../../_helpers/requestManager";

// ******* WidgetList ********
const widgetFetchStart = () => {
  return { type: actionTypes.WIDGET_FETCH_START };
};

const widgetFetched = widgetLists => {
  return { type: actionTypes.WIDGET_FETCH_SUCCESS, widgetLists };
};

const widgetFetchFailed = error => {
  return { type: actionTypes.WIDGET_FETCH_FAILED, error };
};

// ******* AssetList ********
const assetsFetchStart = () => {
  return { type: actionTypes.ASSET_FETCH_START };
};

const assetsFetched = assetLists => {
  return { type: actionTypes.ASSET_FETCH_SUCCESS, assetLists };
};

const assetsFetchFailed = error => {
  return { type: actionTypes.ASSET_FETCH_FAILED, error };
};

// ******* AssetFilterList ********
const assetFilteringStart = () => {
  return { type: actionTypes.ASSET_FILTER_START };
};

const assetFiltered = (assetLists, pageNumber) => {
  return { type: actionTypes.ASSET_FILTER_SUCCESS, assetLists, pageNumber };
};

const assetsFilteredFailed = error => {
  return { type: actionTypes.ASSET_FILTER_FAILED, error };
};

// ******* VideoList ********
const videoFetchStart = () => {
  return { type: actionTypes.VIDEO_FETCH_START };
};

const videoFetched = (videoLists, pageNumber) => {
  return { type: actionTypes.VIDEO_FETCH_SUCCESS, videoLists, pageNumber };
};

const videoFetchFailed = error => {
  return { type: actionTypes.VIDEO_FETCH_FAILED, error };
};

// ******* Uploaded Asset ********
const uploadAssetStart = () => {
  return { type: actionTypes.UPLOAD_ASSET_START };
};

const uploadAssetSuccess = (uploadedAsset, location, fileType) => {
  return { type: actionTypes.UPLOAD_ASSET_SUCCESS, uploadedAsset, location, fileType };
};

const uploadAssetFailed = error => {
  return { type: actionTypes.UPLOAD_ASSET_FAILED, error };
};

// ******* Uploaded Asset list ********
const uploadAssetFetchStart = () => {
  return { type: actionTypes.UPLOAD_ASSET_FETCH_START };
};

const uploadAssetFetched = (uploadAssetLists, pageNumber, fileType) => {
  return { type: actionTypes.UPLOAD_ASSET_FETCH_SUCCESS, uploadAssetLists, pageNumber, fileType };
};

const uploadAssetFetchFailed = error => {
  return { type: actionTypes.UPLOAD_ASSET_FETCH_FAILED, error };
};

// ******* Delete Uploaded Asset ********
const uploadAssetDeleteStart = () => {
  return { type: actionTypes.UPLOAD_ASSET_DELETE_START };
};

const uploadAssetDeleteSuccess = (deleteSuccess, deletedRecordId, deletedRecordIndex, fileType) => {
  return {
    type: actionTypes.UPLOAD_ASSET_DELETE_SUCCESS,
    deleteSuccess,
    deletedRecordId,
    deletedRecordIndex,
    fileType,
  };
};

const uploadAssetDeleteFailed = error => {
  return { type: actionTypes.UPLOAD_ASSET_DELETE_FAILED, error };
};

// ******* Remove Image Background ********
export const removeBackgroundStart = () => {
  return { type: actionTypes.REMOVE_BACKGROUND_START };
};

export const removeBackgroundSuccess = data => {
  return { type: actionTypes.REMOVE_BACKGROUND_SUCCESS, data };
};

export const removeBackgroundFailed = error => {
  return { type: actionTypes.REMOVE_BACKGROUND_FAILED, error };
};

// ******* RegionList ********
const regionFetchStart = () => {
  return { type: actionTypes.REGION_FETCH_START };
};

const regionFetched = regions => {
  return { type: actionTypes.REGION_FETCH_SUCCESS, regions };
};

const regionFetchFailed = error => {
  return { type: actionTypes.REGION_FETCH_FAILED, error };
};

// ******* CountryList ********
const countryFetchStart = () => {
  return { type: actionTypes.COUNTRY_FETCH_START };
};

const countryFetched = countries => {
  return { type: actionTypes.COUNTRY_FETCH_SUCCESS, countries };
};

const countryFetchFailed = error => {
  return { type: actionTypes.COUNTRY_FETCH_FAILED, error };
};

// ******* Upload Facebook Image ********
const uploadFacebookImageStart = () => {
  return { type: actionTypes.UPLOAD_FACEBOOK_IMAGE_START };
};

const uploadFacebookImageSuccess = uploadedAsset => {
  return { type: actionTypes.UPLOAD_FACEBOOK_IMAGE_SUCCESS, uploadedAsset };
};

const uploadFacebookImageFailed = error => {
  return { type: actionTypes.UPLOAD_FACEBOOK_IMAGE_FAILED, error };
};

// ----------------------------------------------------------------------------------------------------------------

/* ********** Fetch widget list API ********** */
export const fetchWidgetList = isCompSuperAdmin => {
  let isSuperAdmin = isCompSuperAdmin || false;

  return dispatch => {
    dispatch(widgetFetchStart());
    return API.get(`/widget/groups?superadmin_company=${isSuperAdmin}`)
      .then(r => {
        dispatch(widgetFetched(r.data));
      })
      .catch(e => {
        dispatch(widgetFetchFailed(e));
      });
  };
};

/* ********** Fetch Asset list API ********** */
export const fetchAssetsList = payload => {
  return dispatch => {
    dispatch(assetsFetchStart());
    return API.get(`/${payload.assetType}`, {
      params: {
        category: payload.assetCategory,
        scheme_type: payload.assetSchemeType,
        keyword: payload.keyword,
        limit: payload.limit,
        page: payload.pageNumber,
      },
    })
      .then(r => {
        dispatch(assetsFetched(r.data));
      })
      .catch(e => {
        dispatch(assetsFetchFailed(e));
      });
  };
};

/* ********** Fetch asset filter API ********** */
export const filterAssetsList = payload => {
  return dispatch => {
    dispatch(assetFilteringStart());
    return API.get(`/${payload.assetType}/filter`, {
      params: {
        category: payload.assetCategory,
        scheme_type: payload.assetSchemeType,
        keyword: payload.keyword,
        limit: payload.limit,
        page: payload.pageNumber,
      },
    })
      .then(r => {
        dispatch(assetFiltered(r.data, payload.pageNumber));
      })
      .catch(e => {
        dispatch(assetsFilteredFailed(e));
      });
  };
};

/* ********** Fetch video asset API ********** */
export const fetchVideos = payload => {
  return dispatch => {
    dispatch(videoFetchStart());
    return API.get(`/stock/videos`, {
      params: {
        keyword: payload.keyword,
        limit: payload.limit,
        page: payload.pageNumber,
        view_video: payload.view_video,
        video_id: payload.video_id,
        page_token: payload.page_token,
      },
    })
      .then(r => {
        if (r.data.success) dispatch(videoFetched(r.data, payload.pageNumber));
        else dispatch(videoFetchFailed(r.data));
      })
      .catch(e => {
        dispatch(videoFetchFailed(e));
      });
  };
};

/* ********** upload Asset API ********** */
export const uploadAssetFile = (file, fileType, location) => {
  return dispatch => {
    dispatch(uploadAssetStart());
    return API.post("/uploader/assets", file, {
      headers: { "Content-Type": undefined },
      params: { file_type: fileType },
    })
      .then(r => {
        dispatch(uploadAssetSuccess(r.data, location, fileType));
      })
      .catch(e => {
        dispatch(uploadAssetFailed(e));
      });
  };
};

/* ********** Fetch uploaded asset API ********** */
export const fetchUploadAssets = payload => {
  return dispatch => {
    dispatch(uploadAssetFetchStart());
    return API.get(`/uploader/assets`, {
      params: {
        limit: payload.limit,
        page: payload.pageNumber,
        ...(payload.file_type && { file_type: payload.file_type }),
      },
    })
      .then(r => {
        dispatch(uploadAssetFetched(r.data, payload.pageNumber, payload.file_type));
      })
      .catch(e => {
        dispatch(uploadAssetFetchFailed(e));
      });
  };
};

/* ********** Delete uploaded asset API ********** */
export const deleteUploadAsset = payload => {
  return dispatch => {
    dispatch(uploadAssetDeleteStart());
    return API.delete(`/uploader/assets/${payload.recordId}`, { headers: { accept: "application/json" } })
      .then(r => {
        dispatch(uploadAssetDeleteSuccess(r.data, payload.recordId, payload.recordIndex, payload.fileType));
      })
      .catch(e => {
        dispatch(uploadAssetDeleteFailed(e));
      });
  };
};

/* ********** Fetch stock photos ********** */
const fetchStockPhotoStart = () => {
  return { type: actionTypes.STOCK_PHOTO_FETCH_START };
};
const fetchStockPhotoSuccess = (result, page, limit) => {
  return { type: actionTypes.STOCK_PHOTO_FETCH_SUCCESS, result, page, limit };
};
const fetchStockPhotoFailed = error => {
  return { type: actionTypes.STOCK_PHOTO_FETCH_FAILED, error };
};

export const fetchStockPhoto = payload => {
  const endPoint = `/stock/photos`;
  const keyword = payload.keyword.length > 0 ? payload.keyword : "Business meeting";

  return dispatch => {
    dispatch(fetchStockPhotoStart());

    return API.get(endPoint, {
      params: {
        page: payload.page,
        limit: payload.limit,
        keyword: keyword,
        orientation: payload.orientation,
        color: payload.color,
      },
    })
      .then(r => dispatch(fetchStockPhotoSuccess(r.data, payload.page, payload.limit)))
      .catch(e => dispatch(fetchStockPhotoFailed(e)));
  };
};

/* ******* Remove Image Background API ******** */
export const removePictureBackground = url => {
  return dispatch => {
    dispatch(removeBackgroundStart());
    return API.post("/remove-bg", { url })
      .then(r => {
        dispatch(removeBackgroundSuccess(r.data));
      })
      .catch(e => {
        dispatch(removeBackgroundFailed(e));
      });
  };
};

/* ********** Fetch Region list API ********** */
export const fetchRegionList = () => {
  return dispatch => {
    dispatch(regionFetchStart());
    return API.get(`/regions`)
      .then(r => {
        dispatch(regionFetched(r.data));
      })
      .catch(e => {
        dispatch(regionFetchFailed(e));
      });
  };
};

/* ********** Fetch Country list API ********** */
export const fetchCountryList = () => {
  return dispatch => {
    dispatch(countryFetchStart());
    return API.get(`/maps`)
      .then(r => {
        dispatch(countryFetched(r.data));
      })
      .catch(e => {
        dispatch(countryFetchFailed(e));
      });
  };
};

// update editor reducer
export const updateEditorReducer = payload => {
  return dispatch => {
    dispatch({ type: actionTypes.UPDATE_EDITOR_REDUCER, payload });
  };
};

/* ********** upload Facebook image API ********** */
export const uploadFacebookImeges = url => {
  return dispatch => {
    dispatch(uploadFacebookImageStart());
    return connectorAPI
      .post("/facebook/image/upload", { url })
      .then(r => {
        dispatch(uploadFacebookImageSuccess(r.data));
      })
      .catch(e => {
        dispatch(uploadFacebookImageFailed(e));
      });
  };
};
