import React, { useContext, useEffect, useRef, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../scss/dhp.scss";
import { Icon } from "../../components/ui/icon";

import { EditorContext } from "../../containers/editor/EditorLayout";
import useGetTotalDocDuration from "../../hooks/useGetTotalDocDuration";
import useSetActivePageBlock from "../../hooks/useSetActivePageBlock";
import { Popover, PopoverBody, Tooltip } from "reactstrap";
import { E, W } from "../../constants/editor";
import { floatToFloatSec, getHMSToSec, getSecToHMS } from "../../_helpers/utils";
import useSetDimension from "../../hooks/useSetDimension";

let style = Object.assign({}, global);

const AudioContainer = props => {
  let { pageNodes, metadata, rightContextMenu, updateRightContextMenu, audios, setAudios, updateMetadata } =
    useContext(EditorContext);

  const [resizableAudioContainerStyle, setResizableAudioContainerStyle] = useState();
  const [activeWidgetInfo, setAactiveWidgetInfo] = useState();
  const [showTooltip, setShowTooltip] = useState(false);
  const [isResizingAudio, setIsResizingAudio] = useState();
  const [resizingTimeDuration, setResizingTimeDuration] = useState();
  const [popoverOpen, setPopoverOpen] = useState();
  const [audioAutoScrolling, setAudioAutoScrolling] = useState();
  const [targetForTolltip, setTargetForTolltip] = useState();
  const [isDragingAudio, setIsDragingAudio] = useState();

  const audiotooltipRef = useRef();
  const resizeContainerRef = useRef();

  const { calculatedocDuration } = useGetTotalDocDuration();
  const { manageAudioFadeTiming } = useSetDimension();
  useSetActivePageBlock(activeWidgetInfo); // For set active page block widget by Hook

  // set audio container style based on scale(gape btw 0.00 to 0.01 timing)
  const setAudioContainerStyle = () => {
    let audioStartTime = props.audio?.startTime;
    let startTimePreValue = parseInt(audioStartTime.split(".")[0]);
    let startTimePostValue = parseInt(audioStartTime.split(".")[1]);
    startTimePostValue = startTimePostValue ? startTimePostValue : 0;
    let targetContainerLeft = props.scaleWidth * startTimePreValue + (props.scaleWidth / 10) * startTimePostValue;

    let audioEndTime = props.audio?.endTime;
    let preValue = parseInt(audioEndTime.split(".")[0]);
    let postValue = parseInt(audioEndTime.split(".")[1]);
    postValue = postValue ? postValue : 0;
    let targetContainerWidth = props.scaleWidth * preValue + (props.scaleWidth / 10) * postValue - targetContainerLeft;

    setResizableAudioContainerStyle({
      width: `${targetContainerWidth}px`,
      transform: `translateX(${targetContainerLeft}px)`,
    });
  };

  // set active audio
  const activeAudioContainerOnClick = targetAudioId => {
    // update metadata in context
    setAactiveWidgetInfo({
      targetPageIdx: metadata.activePageIdx,
      targetBlockIdx: metadata.activeBlockIdx,
      activeWidgetId: false,
      activeWidgetType: false,
      activeAudioId: targetAudioId,
    });
  };

  // show right context menu on right click on audio
  const showRightContextMenu = (e, targetAudioId) => {
    e.preventDefault();

    // set active widget on right click
    setAactiveWidgetInfo({
      targetPageIdx: metadata.activePageIdx,
      targetBlockIdx: metadata.activeBlockIdx,
      activeWidgetId: false,
      activeWidgetType: false,
      activeAudioId: targetAudioId,
    });

    // enable right context menu
    setTimeout(() => {
      updateRightContextMenu({
        ...rightContextMenu,
        enable: true,
        top: e.clientY,
        left: e.clientX,
        style: { ...rightContextMenu.style, top: "0px", left: "0px", visibility: "hidden" },
      });
    }, 100);
  };

  // show tooltip when audio file name text is ellipses
  const toggleTooltip = () => {
    setShowTooltip(!showTooltip && audiotooltipRef.current.scrollWidth > audiotooltipRef.current.offsetWidth);
  };

  const mouseDownSubscribers = (e, targetAudioId) => {
    activeAudioContainerOnClick(targetAudioId); // active audio widget

    // audio drag
    if (e.target.classList.contains("item") || e.target.closest(".audio-name")) {
      audioEvents.drag.start({ audioId: targetAudioId }, e);
    }
    // audio left resize
    else if (e.target.closest(".resizer").classList.contains("left-resizer")) {
      setTargetForTolltip(e.target.closest(".resizer").getAttribute("id"));
      audioEvents.resize.start({ resizer: W }, e);
    }
    // audio right resize
    else if (e.target.closest(".resizer").classList.contains("right-resizer")) {
      setTargetForTolltip(e.target.closest(".resizer").getAttribute("id"));
      audioEvents.resize.start({ resizer: E }, e);
    }
  };

  // audio resize and drag
  const audioEvents = {
    meta: false,

    // audio container drag events
    drag: {
      updateReactDom: ({ client }) => {
        let targetAudioIndex = audios.findIndex(audio => audio.id === client.id);

        // create new obj to update audio context (new start time and end time)
        const updatedAudiotArray = Object.assign([...audios], {
          [targetAudioIndex]: {
            ...audios[targetAudioIndex],
            ...client.current.duration,
          },
        });

        setAudios(updatedAudiotArray); // update context
        // update local state for audio container, as left is changed
        if (client.current.transform) {
          setResizableAudioContainerStyle({
            ...resizableAudioContainerStyle,
            transform: client.current.transform,
          });
        }
      },

      dragToScroll: ({ client, scrollContainer, mouse, reducableLeft }, e) => {
        const dataScroll = audioEvents.meta.scrollContainer.node.getAttribute("data-scroll");
        const docDuration = calculatedocDuration();
        const audioContainerWidth = parseFloat(client.node.style.width); // get current audio container width

        if (dataScroll === "enabled" && mouse.initial.directionX) {
          setTimeout(() => {
            if (
              mouse.initial.directionX === "RIGHT" &&
              scrollContainer.rect.right - (mouse.current.x + audioContainerWidth - client.pointerOffset.x) <= 100
            ) {
              const scrollAmount = scrollContainer.node.scrollLeft + 10;
              const newScrollLeft = scrollAmount >= 0 ? scrollAmount : 0;

              scrollContainer.node.scrollLeft = newScrollLeft;
              audioEvents.meta.scrollContainer.current.scrollLeft = newScrollLeft;

              let newX = parseFloat(client.node.style.transform.split("(")[1]) + 10;

              if (newX + audioContainerWidth <= parseFloat(docDuration) * props.scaleWidth) {
                client.node.style.transform = `translateX(${newX}px)`;
                client.pointerOffset.x =
                  e.clientX - reducableLeft - parseFloat(client.node.style.transform.split("(")[1]);
                client.current = {
                  transform: `translateX(${newX}px)`,
                  duration: {
                    startTime: `${parseFloat(newX / props.scaleWidth).toFixed(1)}s`,
                    endTime: `${parseFloat((newX + audioContainerWidth) / props.scaleWidth).toFixed(1)}s`,
                  },
                };
              }
            }

            if (
              mouse.initial.directionX === "LEFT" &&
              mouse.current.x - client.pointerOffset.x - scrollContainer.rect.left <= 15
            ) {
              const scrollAmount = scrollContainer.node.scrollLeft - 10;
              const newScrollLeft = scrollAmount >= 0 ? scrollAmount : 0;

              scrollContainer.node.scrollLeft = newScrollLeft;
              audioEvents.meta.scrollContainer.current.scrollLeft = newScrollLeft;

              let newX = parseFloat(client.node.style.transform.split("(")[1]) - 10;
              newX = newX < 5 ? 0 : newX;

              if (newX >= 0) {
                client.node.style.transform = `translateX(${newX}px)`;
                client.pointerOffset.x =
                  e.clientX - reducableLeft - parseFloat(client.node.style.transform.split("(")[1]);
                client.current = {
                  transform: `translateX(${newX}px)`,
                  duration: {
                    startTime: `${parseFloat(newX / props.scaleWidth).toFixed(1)}s`,
                    endTime: `${parseFloat((newX + audioContainerWidth) / props.scaleWidth).toFixed(1)}s`,
                  },
                };
              }
            }
          }, 100);
        }
      },

      start: (data, e) => {
        let targetDiv = document.querySelector(".audioSlide-container");
        let rect = targetDiv.getBoundingClientRect();
        let audioContainerLeft = parseFloat(e.target.closest(".item")?.style.transform.split("(")[1]);
        let scrollContainer = document.querySelector(".timeline-body.customScroll");

        const {
          width: scrollContainerWidth,
          left: scrollContainerLeft,
          right: scrollContainerRight,
        } = scrollContainer.getBoundingClientRect();

        // fetch all details for active audio container
        let dataProps = {
          isDragging: true,
          isFirstEvent: true,
          reducableLeft: rect.left,
          client: {
            node: e.target.closest(".item"),
            id: data.audioId,
            pointerOffset: {
              x: e.clientX - rect.left - audioContainerLeft,
            },
            initial: {
              transform: audioContainerLeft,
              duration: {
                startTime: props.audio?.startTime,
                endTime: props.audio?.endTime,
              },
            },
            current: {
              transform: false,
              duration: false,
            },
          },
          scrollContainer: {
            node: scrollContainer,
            rect: { left: scrollContainerLeft, right: scrollContainerRight },
            scrollFactor: scrollContainer.scrollWidth / scrollContainerWidth,
            scrollLeftMax: scrollContainer.scrollWidth - scrollContainerWidth,
            scrollType: false,
            initial: {
              scrollLeft: scrollContainer.scrollLeft,
            },
            current: {
              scrollLeft: false,
            },
          },
          mouse: {
            initial: {
              x: e.pageX,
              directionX: false,
            },
            current: {
              x: false,
              directionX: false,
            },
          },
        };

        audioEvents.meta = dataProps;
        setIsDragingAudio(true);
        scrollContainer.setAttribute("data-scroll", "enabled");
        document.addEventListener("mousemove", audioEvents.drag.drag);
        document.addEventListener("mouseup", audioEvents.drag.stop);
      },

      drag: e => {
        let dragMeta = audioEvents.meta;

        if (dragMeta?.isDragging) {
          if (dragMeta.isFirstEvent) dragMeta.isFirstEvent = false; // event operations

          let newX = e.clientX - dragMeta.reducableLeft - dragMeta.client.pointerOffset.x; // calculate current left during drag
          let audioContainerWidth = parseFloat(dragMeta.client.node.style.width); // get current audio container width
          let docDuration = calculatedocDuration();

          // Auto scroll during drag
          if (
            (e.pageX - dragMeta.client.pointerOffset.x - dragMeta.scrollContainer.rect.left <= 15 &&
              e.movementX <= 0) ||
            (dragMeta.scrollContainer.rect.right - (e.pageX + audioContainerWidth - dragMeta.client.pointerOffset.x) <=
              100 &&
              e.movementX >= 0)
          ) {
            dragMeta.mouse.current.x = e.pageX;

            if (!dragMeta.mouse.initial.directionX)
              dragMeta.mouse.initial.directionX = e.movementX === 0 ? false : e.movementX > 0 ? "RIGHT" : "LEFT";
            if (dragMeta.mouse.initial.directionX)
              dragMeta.mouse.current.directionX = e.movementX === 0 ? false : e.movementX > 0 ? "RIGHT" : "LEFT";

            audioEvents.drag.dragToScroll({ ...dragMeta }, e);
          }
          // Normal Drag
          else {
            // update current left in dom and update current datas which was sent from start events( new start time, end time) (min left 0 and max right total document duration)
            if (newX >= 0 && newX + audioContainerWidth <= parseFloat(docDuration) * props.scaleWidth) {
              dragMeta.client.node.style.transform = `translateX(${newX}px)`;
              dragMeta.client.current = {
                transform: `translateX(${newX}px)`,
                duration: {
                  startTime: `${parseFloat(newX / props.scaleWidth).toFixed(1)}s`,
                  endTime: `${parseFloat((newX + audioContainerWidth) / props.scaleWidth).toFixed(1)}s`,
                },
              };
            }
          }
        }
      },

      stop: () => {
        let stopMeta = audioEvents.meta;

        if (stopMeta?.isDragging) {
          if (!stopMeta.isFirstEvent) audioEvents.drag.updateReactDom({ ...stopMeta });
          stopMeta.scrollContainer.node.removeAttribute("data-scroll");
          setIsDragingAudio(false);

          document.removeEventListener("mousemove", audioEvents.drag.drag);
          document.removeEventListener("mouseup", audioEvents.drag.stop);
          stopMeta = false;
        }
      },
    },

    // widget container resize events
    resize: {
      updateReactDom: ({ client }) => {
        let newStartTime, newEndTime;
        // left resize happen
        if (parseFloat(audios[client.idx].startTime) !== parseFloat(client.current.duration.startTime)) {
          let audioTrimmedStartTime = audios[client.idx].data["data-trim"].split(",")[0];

          // if start time decrease
          if (parseFloat(audios[client.idx].startTime) > parseFloat(client.current.duration.startTime)) {
            let startTimeDiff =
              parseFloat(audios[client.idx].startTime) - parseFloat(client.current.duration.startTime);
            newStartTime = parseFloat(audioTrimmedStartTime) - startTimeDiff;
          }
          // if start time increase
          else {
            let startTimeDiff =
              parseFloat(client.current.duration.startTime) - parseFloat(audios[client.idx].startTime);
            newStartTime = parseFloat(audioTrimmedStartTime) + startTimeDiff;
          }
        }
        //right resize happen
        if (parseFloat(audios[client.idx].endTime) !== parseFloat(client.current.duration.endTime)) {
          let audioTrimmedStartTime = audios[client.idx].data["data-trim"].split(",")[0];
          let audioTrimmedEndTime = audios[client.idx].data["data-trim"].split(",")[1];
          let originalAudioDuration = parseFloat(getHMSToSec({ hms: audios[client.idx].originalDuration }));

          // if end time decrease
          if (parseFloat(audios[client.idx].endTime) > parseFloat(client.current.duration.endTime)) {
            // when current end point is less than trimmed end point of audio trim further
            if (
              parseFloat(client.current.duration.endTime) - parseFloat(client.current.duration.startTime) <
              parseFloat(audioTrimmedEndTime) - parseFloat(audioTrimmedStartTime)
            ) {
              let endTimeDiff =
                parseFloat(client.current.duration.endTime) -
                parseFloat(client.current.duration.startTime) -
                (parseFloat(audioTrimmedEndTime) - parseFloat(audioTrimmedStartTime));

              newEndTime = parseFloat(audioTrimmedEndTime) + endTimeDiff;
            }
          }
          // if end time increase (untill it reach audio originakl duration keep trimming then loop)
          else {
            let endTimeDiff = parseFloat(client.current.duration.endTime) - parseFloat(audios[client.idx].endTime);
            newEndTime =
              parseFloat(audioTrimmedEndTime) + endTimeDiff > originalAudioDuration
                ? originalAudioDuration
                : parseFloat(audioTrimmedEndTime) + endTimeDiff;
          }
        }

        newStartTime =
          newStartTime !== undefined ? newStartTime.toFixed(1) : audios[client.idx].data["data-trim"].split(",")[0];
        newEndTime =
          newEndTime !== undefined ? newEndTime.toFixed(1) : audios[client.idx].data["data-trim"].split(",")[1];
        let newTrimmedDuration = getSecToHMS({ sec: newEndTime - newStartTime });
        let { newFadeInTime, newFadeOutTime } = manageAudioFadeTiming(client.current.duration.endTime, client.idx); // manage audio fade timing depends on audio length if fade in or fade out value is applied

        let updatedAudioArray = Object.assign([...audios], {
          [client.idx]: {
            ...audios[client.idx],
            ...client.current.duration,
            data: {
              ...audios[client.idx].data,
              "data-fade-in-value": newFadeInTime,
              "data-fade-out-value": newFadeOutTime,
              "data-trim": `${newStartTime}, ${newEndTime}`,
              "data-trimmed-duration": newTrimmedDuration,
            },
          },
        });

        setAudios(updatedAudioArray); // update context
        setResizableAudioContainerStyle({
          ...resizableAudioContainerStyle,
          width: `${client.current.width}px`,
          transform: `translateX(${client.current.left}px)`,
        }); // update local state for widget container, as left and right are changed
      },

      updateTooltip: ({ client, resizer }, stime, eTime, autoscroll) => {
        setPopoverOpen(true);

        // set duration in tooltip
        let duration = parseFloat(eTime) - parseFloat(stime);
        setResizingTimeDuration(getSecToHMS({ sec: duration }));

        // set tooltip left when auto scroll is not performing
        if (document.querySelector(".audio-duration-tooltip .popover") && !autoscroll) {
          let currentClientRect = client.node.getBoundingClientRect();
          let tolltipLeft =
            resizer.type === W ? currentClientRect.left : currentClientRect.left + currentClientRect.width - 59;
          let popOverTransform = document
            .querySelector(".audio-duration-tooltip .popover")
            ?.style.transform.split("(")[1];
          popOverTransform = popOverTransform?.split(",");

          if (popOverTransform) {
            let newPopOverTransform = `translate3d(${tolltipLeft}px,${parseInt(popOverTransform[1])}px, ${parseInt(
              popOverTransform[2]
            )}px)`;
            document.querySelector(".audio-duration-tooltip .popover").style.transform = newPopOverTransform;
          }
        }
      },

      resizeOnAutoScroll: ({ delta }) => {
        const meta = audioEvents.meta;
        const {
          client: { node: container, minWidth: cminw, maxWidth: cmaxw, minLeftWidth: lminw, maxLeftWidth: lmaxw },
        } = meta;
        const {
          resizer: { type: resizer },
        } = meta;
        const { width: containerWidth } = container.getBoundingClientRect();
        const containerLeft = parseFloat(meta.client.node.style.transform.split("(")[1]);

        let left;
        let resizeData = { width: containerWidth, left: containerLeft };

        // West Resizer | Left
        if (resizer === W) {
          let width = containerWidth - delta;

          if (width < lminw) {
            resizeData = { ...resizeData, width: lminw, left: containerLeft + (containerWidth - lminw) };
          } else if (width > lmaxw) {
            resizeData = { ...resizeData, width: lmaxw, left: containerLeft + (containerWidth - lmaxw) };
          } else if (containerLeft + delta >= 0)
            resizeData = { ...resizeData, left, width, left: containerLeft + delta };
          else {
            resizeData = { ...resizeData, width: resizeData.left + containerWidth, left: 0 };
          }
        }
        // West Resizer | Right
        else if (resizer === E) {
          let width = containerWidth + delta;

          if (width < cminw) resizeData = { ...resizeData, width: cminw };
          else if (width > cmaxw) resizeData = resizeData = { ...resizeData, width: cmaxw };
          else resizeData = { ...resizeData, width };
        }

        let stime = floatToFloatSec(resizeData.left / props.scaleWidth);
        let eTime = floatToFloatSec((resizeData.left + resizeData.width) / props.scaleWidth);

        meta.client.node.style.width = `${resizeData.width}px`;
        meta.client.node.style.transform = `translateX(${resizeData.left}px)`;

        meta.client.initial.width = resizeData.width - delta;
        meta.mouse.initial.x = meta.mouse.current.x;
        meta.client.current = {
          ...resizeData,
          duration: {
            startTime: stime + "s",
            endTime: eTime + "s",
          },
        };

        audioEvents.resize.updateTooltip({ ...meta }, stime, eTime, true); // update audio duration in tooltip
        setAudioAutoScrolling(true); // set autoscroll true
      },

      autoScroll: ({
        client: { node: client },
        mouse: {
          current: { x: mcx },
          intervalId,
        },
        scrollContainer: {
          node: scrollContainer,
          rect: { left: scrollContainerLeft, right: scrollContainerRight },
          autoScrollDx: dX,
        },
      }) => {
        audioEvents.meta.mouse.intervalId = setInterval(() => {
          if (scrollContainerRight - mcx <= 100) {
            scrollContainer.scrollLeft = scrollContainer.scrollLeft + 10;
            audioEvents.resize.resizeOnAutoScroll({ delta: 10 });
          } else if (mcx - scrollContainerLeft <= 100) {
            scrollContainer.scrollLeft = scrollContainer.scrollLeft - 10;
            audioEvents.resize.resizeOnAutoScroll({ delta: -10 });
          } else clearInterval(intervalId);
        }, 30);
      },

      start: (data, e) => {
        if (e.button !== 0) return; // only applicable for left mouse click
        const container = resizeContainerRef.current;
        const scrollContainer = document.querySelector(".timeline-body.customScroll");
        const containerRect = container?.getBoundingClientRect();
        const scrollContainerRect = scrollContainer.getBoundingClientRect();
        const scrollFactor = scrollContainer.scrollWidth / scrollContainerRect.width;
        const activeAudioIndex = audios.findIndex(a => a.id === container.getAttribute("id"));
        const audioTrimmedStartTime = parseFloat(audios[activeAudioIndex].data["data-trim"].split(",")[0]);
        const audioTrimmedEndTime = parseFloat(audios[activeAudioIndex].data["data-trim"].split(",")[1]);
        const audioStartTime = audios[activeAudioIndex].startTime;
        const minLeft = parseFloat(audioStartTime) + (audioTrimmedEndTime - audioTrimmedStartTime) - 1;
        const maxleft =
          audioTrimmedStartTime === 0 || parseFloat(audioStartTime) === parseFloat(0)
            ? parseFloat(audioStartTime)
            : audioTrimmedStartTime > parseFloat(audioStartTime)
            ? 0
            : parseFloat(audioStartTime) - audioTrimmedStartTime;
        const docDuration = calculatedocDuration();

        const dataProps = {
          isResizing: true,
          isFirstEvent: true,
          client: {
            idx: activeAudioIndex,
            node: container,
            minWidth: props.scaleWidth * 1,
            maxWidth: props.scaleWidth * (parseFloat(docDuration) - parseFloat(audioStartTime)),
            minLeftWidth: props.scaleWidth * (parseFloat(audios[activeAudioIndex].endTime) - minLeft),
            maxLeftWidth: props.scaleWidth * (parseFloat(audios[activeAudioIndex].endTime) - maxleft),
            initial: {
              left: parseFloat(container.style.transform.match(/\d+/g)[0]),
              width: containerRect.width,
            },
            current: {
              left: false,
              width: false,
              duration: false,
            },
          },
          resizer: {
            node: e.target,
            type: data.resizer,
          },
          mouse: {
            initial: {
              x: e.pageX,
            },
            current: {
              x: false,
            },
            intervalId: false,
          },
          scrollContainer: {
            node: scrollContainer,
            rect: { left: scrollContainerRect.left, right: scrollContainerRect.right },
            autoScrollDx: scrollFactor * 10,
          },
        };

        audioEvents.meta = dataProps;
        document.addEventListener("mousemove", audioEvents.resize.resizing);
        document.addEventListener("mouseup", audioEvents.resize.stop);
      },

      resizing: e => {
        const meta = audioEvents.meta;

        if (meta?.isResizing) {
          const {
            client: {
              node: client,
              minWidth: cminw,
              maxWidth: cmaxw,
              minLeftWidth: lminw,
              maxLeftWidth: lmaxw,
              initial: { left: cil = 0, width: ciw = 0 },
            },
            resizer: { type: resizer },
            mouse: {
              initial: { x: mix },
              current: { x: mcx },
              intervalId,
            },
            scrollContainer: {
              rect: { left: scrollContainerLeft, right: scrollContainerRight },
            },
          } = meta;

          // event operations
          if (meta.isFirstEvent) {
            meta.isFirstEvent = false;
          }

          if (intervalId) {
            clearInterval(intervalId);
            meta.mouse.intervalId = false;
          }

          meta.mouse.current.x = e.pageX;

          // determine size of client after resize
          const dX = e.pageX - mix;

          let left, width;
          let resizeData = { left: cil, width: ciw };

          // West Resizer | Left
          if (resizer === W) {
            const delta = { l: dX, w: -dX };
            width = ciw + delta.w;

            if (width < lminw) resizeData = { ...resizeData, width: lminw, left: cil + (ciw - lminw) };
            else if (width > lmaxw) resizeData = { ...resizeData, width: lmaxw, left: cil + (ciw - lmaxw) };
            else if (cil + delta.l >= 0) resizeData = { ...resizeData, left, width, left: cil + delta.l };
            else resizeData = { ...resizeData, width: resizeData.left + ciw, left: 0 };
          }

          // East Resizer | Right
          else if (resizer === E) {
            const delta = { w: dX };
            width = ciw + delta.w;

            if (width < cminw) resizeData = { ...resizeData, width: cminw };
            else if (width > cmaxw) resizeData = resizeData = { ...resizeData, width: cmaxw };
            else resizeData = { ...resizeData, width };
          }

          // auto scroll during resize
          if ((scrollContainerRight - mcx <= 100 && width >= lminw) || (mcx - scrollContainerLeft <= 100 && width <= lmaxw)) {
            audioEvents.resize.autoScroll({ ...meta });
          }
          //apply size of client after resize(condition is given to solve auto scroll issue.. i.e : when left resizer auto scrolling happen dont exicute this code other wise width got increase from here)
          else {
            let stime = floatToFloatSec(resizeData.left / props.scaleWidth);
            let eTime = floatToFloatSec((resizeData.left + resizeData.width) / props.scaleWidth);

            client.style.width = `${resizeData.width}px`;
            client.style.transform = `translateX(${resizeData.left}px)`;
            meta.client.current = {
              ...resizeData,
              duration: {
                startTime: stime + "s",
                endTime: eTime + "s",
              },
            };

            audioEvents.resize.updateTooltip({ ...meta }, stime, eTime, false); // update audio duration in tooltip
            setAudioAutoScrolling(false); // set autoscroll false
          }

          setIsResizingAudio(true);
        }
      },

      stop: () => {
        let meta = audioEvents.meta;

        if (meta?.isResizing) {
          if (!meta.isFirstEvent) {
            audioEvents.resize.updateReactDom({ ...meta });
          }
          clearInterval(meta.mouse.intervalId);
          setIsResizingAudio(false);
          document.removeEventListener("mousemove", audioEvents.resize.resizing);
          document.removeEventListener("mouseup", audioEvents.resize.stop);
          meta = false;
          setPopoverOpen(false);
          setAudioAutoScrolling(false);
        }
      },
    },
  };

  useEffect(() => {
    if (metadata.activeAudioId && metadata.activeWidgetId)
      updateMetadata({
        ...metadata,
        activeAudioId: false,
      });
  }, [metadata.activeWidgetId]);

  useEffect(() => {
    //add active class in li
    document.querySelectorAll(".single-audio").forEach(element => {
      element.classList.remove("active");
      if (element.querySelector(".item").getAttribute("id") === metadata.activeAudioId) element.classList.add("active");
    });
  }, [metadata.activeAudioId]);

  useEffect(() => {
    setAudioContainerStyle();
  }, [props.audio, props.scaleWidth, pageNodes]);

  useEffect(() => {
    setAudioContainerStyle();
  }, []);

  return (
    <>
      <div
        id={props.audio.id}
        ref={resizeContainerRef}
        data-audio-index={props.index}
        {...props.audio.data}
        className={cx(style["item"], style["custom-tooltip"])}
        style={{ ...resizableAudioContainerStyle, backgroundColor: props.audio.color }}
        onMouseDown={e => mouseDownSubscribers(e, props.audio.id)}
        onContextMenu={e => showRightContextMenu(e, props.audio.id)}>
        {/* left resizer */}
        <div
          className={cx(style["left-resizer"], style["position-absolute"], "resizer")}
          id={`l-resizer-${props.audio.id}`}
          onMouseDown={e => mouseDownSubscribers(e, props.audio.id)}>
          <Icon icon="ui-pause" />
        </div>

        {/* audio name */}
        {!isResizingAudio && !isDragingAudio && (
          <div
            className={cx(style["audio-name"], style["d-flex"], style["align-items-center"])}
            style={{ width: `${parseFloat(resizableAudioContainerStyle?.width) - 20}px` }}
            id={`doc-timeline-${props.audio.id}`}>
            <Icon icon="ui-audio" additionalclass={"mr-2"} />
            <span className={style["text-truncate"]} ref={audiotooltipRef}>
              {props.audio.data["data-file-name"]}
            </span>

            {document.getElementById(`doc-timeline-${props.audio.id}`) && (
              <Tooltip
                placement="top"
                target={`doc-timeline-${props.audio.id}`}
                boundariesElement={document.getElementById("app")}
                isOpen={showTooltip}
                toggle={toggleTooltip}>
                {props.audio.data["data-file-name"]}
              </Tooltip>
            )}
          </div>
        )}

        {/* right resizer */}
        <div
          className={cx(style["right-resizer"], style["position-absolute"], "resizer")}
          id={`r-resizer-${props.audio.id}`}
          onMouseDown={e => mouseDownSubscribers(e, props.audio.id)}>
          <Icon icon="ui-pause" />
        </div>

        {/* show audio duration tooltip when resize */}
        <Popover
          className="audio-duration-tooltip"
          placement={"top"}
          isOpen={popoverOpen}
          target={targetForTolltip ? targetForTolltip : `r-resizer-${props.audio.id}`}
          boundariesElement={document.getElementById("app")}
          popperClassName={
            audioAutoScrolling && targetForTolltip === `r-resizer-${props.audio.id}`
              ? "ml-n5"
              : audioAutoScrolling && targetForTolltip === `l-resizer-${props.audio.id}`
              ? "mr-n5"
              : ""
          }>
          <PopoverBody>{resizingTimeDuration}</PopoverBody>
        </Popover>
      </div>
    </>
  );
};
AudioContainer.propTypes = {
  audio: PropTypes.object,
  index: PropTypes.number,
  scaleWidth: PropTypes.number,
};

const AudioTimeLineGenaretor = props => {
  let { audios } = useContext(EditorContext);

  return (
    <ul>
      {audios.map((audio, index) => (
        <li key={index} className={cx(style["custom-tooltip"], "single-audio")}>
          <AudioContainer audio={audio} index={index} scaleWidth={props.scaleWidth} />
        </li>
      ))}
    </ul>
  );
};
AudioTimeLineGenaretor.propTypes = {
  scaleWidth: PropTypes.number,
};

export default AudioTimeLineGenaretor;
