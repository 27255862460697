import { useContext, useEffect, useState } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";
import { floatToFloatSec, getHMSToSec, playMedia } from "../_helpers/utils";

let intervalId;
let allAudioData = {};

const fadeEffectObj = {
  0: ["None", "", null, undefined],
  500: ["0.5 sec"],
  1000: ["1.0 sec", "1 sec"],
  2000: ["2.0 sec", "2 sec"],
};

const fadeIntervalObj = {
  500: [0, 100, 200, 300, 400, 500],
  1000: [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000],
  2000: [0, 200, 400, 600, 800, 1000, 1200, 1400, 1600, 1800, 2000],
};

const useAudioPlay = () => {
  const { audios, pageNodes, pagePlayData, metadata, playingPageIdx } = useContext(EditorContext);
  const [timerMs, setTimerMs] = useState(null);
  const [action, setAction] = useState(null);

  const getAudioData = audioObj => {
    let fadeData = {};
    const audio = document.getElementById(`player-${audioObj.id}`);
    const durationInHMS = audioObj.data["data-duration"];
    const durationInSec = getHMSToSec({ hms: durationInHMS });

    const fadeInValue = audioObj.data["data-fade-in-value"];
    const fadeOutValue = audioObj.data["data-fade-out-value"];
    const fadeInMs = parseInt(Object.keys(fadeEffectObj).find(k => fadeEffectObj[k].includes(fadeInValue)));
    const fadeOutMs = parseInt(Object.keys(fadeEffectObj).find(k => fadeEffectObj[k].includes(fadeOutValue)));

    const trim = audioObj.data["data-trim"];
    const trimArray = trim ? trim.split(",")?.map(e => parseFloat(e)) : null;
    const isTrimmed = Boolean(trimArray?.length === 2);
    const trimStart = isTrimmed ? trimArray?.[0] : 0;
    const trimStop = isTrimmed ? trimArray?.[1] : durationInSec;

    const volume = parseInt(audioObj.data["data-volume"] ?? 100);
    const startTimeMs = parseFloat(audioObj.startTime) * 1000;
    const stopTimeMs = parseFloat(audioObj.endTime) * 1000;
    const trimStartTimeMs = trimStart * 1000;
    const trimStopTimeMs = trimStop * 1000;
    const trimmedDurationMs = trimStopTimeMs - trimStartTimeMs;

    const fadeInStartTimeMs = fadeInMs > 0 && startTimeMs;
    const fadeInStopTimeMs = fadeInMs > 0 && startTimeMs + fadeInMs;
    const fadeOutStartTimeMs = fadeOutMs > 0 && stopTimeMs - fadeOutMs;
    const fadeOutStopTimeMs = fadeOutMs > 0 && stopTimeMs;

    if (fadeInMs > 0 || fadeOutMs > 0) {
      fadeData = getFadeData({ volume, fadeInStartTimeMs, fadeInMs, fadeOutStartTimeMs, fadeOutMs });
    }

    return {
      audio,
      volume,
      startTimeMs,
      stopTimeMs,
      trimStartTimeMs,
      trimStopTimeMs,
      trimmedDurationMs,
      fadeInStartTimeMs,
      fadeInStopTimeMs,
      fadeOutStartTimeMs,
      fadeOutStopTimeMs,
      ...fadeData,
      isMuted: Boolean(volume === 0),
      isLooped: Boolean(stopTimeMs > trimStopTimeMs),
      isTrimmed: Boolean(trimStartTimeMs > 0 || trimStopTimeMs < stopTimeMs),
      isFadeIn: Boolean(fadeInMs > 0),
      isFadeOut: Boolean(fadeOutMs > 0),
    };
  };

  const getFadeData = ({ volume, fadeInStartTimeMs, fadeInMs, fadeOutStartTimeMs, fadeOutMs }) => {
    let fadeInIntervals = [];
    let fadeInVolumes = [];
    let fadeOutIntervals = [];
    let fadeOutVolumes = [];

    if (fadeInMs > 0) {
      const intervals = fadeIntervalObj[fadeInMs];
      const volumeFactor = volume / (intervals.length - 1);

      for (let i = 0; i < intervals.length; i++) {
        fadeInIntervals = [...fadeInIntervals, fadeInStartTimeMs + intervals[i]];

        if (i === 0) fadeInVolumes = [...fadeInVolumes, 0];
        if (i > 0 && i < intervals.length - 1) fadeInVolumes = [...fadeInVolumes, parseInt(i * volumeFactor)];
        if (i === intervals.length - 1) fadeInVolumes = [...fadeInVolumes, volume];
      }
    }

    if (fadeOutMs > 0) {
      const fadeoutIntervals = JSON.parse(JSON.stringify(fadeIntervalObj[fadeOutMs]));
      const intervals = fadeoutIntervals.reverse();
      const volumeFactor = volume / (intervals.length - 1);

      for (let i = intervals.length - 1; i >= 0; i--) {
        fadeOutIntervals = [...fadeOutIntervals, fadeOutStartTimeMs + intervals[i]];

        if (i === intervals.length - 1) fadeOutVolumes = [...fadeOutVolumes, volume];
        if (i > 0 && i < intervals.length - 1) fadeOutVolumes = [...fadeOutVolumes, parseInt(i * volumeFactor)];
        if (i === 0) fadeOutVolumes = [...fadeOutVolumes, 0];
      }
    }

    return { fadeInIntervals, fadeInVolumes, fadeOutIntervals, fadeOutVolumes };
  };

  const getAllAudioData = () => {
    allAudioData = {};
    audios?.forEach(audioObj => {
      const audioData = getAudioData(audioObj);
      allAudioData[audioObj.id] = audioData;
    });
  };

  const getAllPageStartTimes = () => {
    let pageStartTimes = [];
    let documentDurationMs = 0;

    pageNodes.forEach(pageObj => {
      const pageDurationMs = parseFloat(pageObj.pageDuration) * 1000;
      const pageStartTimeMs = documentDurationMs;

      documentDurationMs += pageDurationMs;
      pageStartTimes = [...pageStartTimes, pageStartTimeMs];
    });

    return pageStartTimes;
  };

  const stopDomPlay = () => {
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }
    audios?.forEach(audioObj => {
      const audio = document.getElementById(`player-${audioObj.id}`);
      if (audio) {
        audio.pause();
        audio.removeAttribute("data-playing");
      }
    });
  };

  const setAudioCurTimeToDefault = ({ pageIdx }) => {
    const allPageStartTimes = getAllPageStartTimes();
    const curPageStartTime = allPageStartTimes?.[pageIdx] ?? 0;
    const timer = curPageStartTime;

    audios?.forEach(audioObj => {
      const { audio, volume, trimStartTimeMs, isMuted, isFadeIn } = getAudioData(audioObj);
      if (audio) {
        if (isMuted) audio.muted = false;
        if (!isFadeIn) audio.volume = volume / 100;
        audio.currentTime = (trimStartTimeMs + timer) / 1000;
      }
    });
  };

  const setAudioCurTime = ({ timer }) => {
    audios?.forEach(audioObj => {
      const { audio, trimStartTimeMs, trimStopTimeMs, startTimeMs, stopTimeMs } = getAudioData(audioObj);
      if (audio) {
        const trimStartTime = trimStartTimeMs / 1000;
        const trimStopTime = trimStopTimeMs / 1000;

        if (timer > startTimeMs && timer < stopTimeMs) {
          // when audio is within playable time range
          const audioPlayedDurationMs = timer - startTimeMs;
          const audioTrimmedDurationMs = (trimStopTime - trimStartTime) * 1000;
          const audioCurTimeWithinPlayableRange =
            (audioTrimmedDurationMs > audioPlayedDurationMs
              ? audioPlayedDurationMs
              : audioPlayedDurationMs % audioTrimmedDurationMs) / 1000;

          audio.currentTime = trimStartTime + audioCurTimeWithinPlayableRange;
        } else {
          audio.currentTime = trimStartTime;
        }
      }
    });
  };

  const audioPlay = ({ timer }) => {
    audios?.forEach(audioObj => {
      const {
        audio,
        volume,
        startTimeMs,
        stopTimeMs,
        trimStartTimeMs,
        trimStopTimeMs,
        fadeInIntervals,
        fadeInVolumes,
        fadeOutIntervals,
        fadeOutVolumes,
        isMuted,
        isLooped,
        isFadeIn,
        isFadeOut,
      } = allAudioData[audioObj.id];

      if (audio) {
        if (timer === startTimeMs) {
          if (isMuted) audio.muted = false;
          if (!isFadeIn) audio.volume = volume / 100;
          audio.currentTime = trimStartTimeMs / 1000;
          playMedia(audio);
          audio.setAttribute("data-playing", "true");
        }

        // fadeIn audio effect
        if (isFadeIn && fadeInIntervals.includes(timer)) {
          const idx = fadeInIntervals.indexOf(timer);
          audio.volume = fadeInVolumes[idx] / 100;
        }

        // fadeOut audio effect
        if (isFadeOut && fadeOutIntervals.includes(timer)) {
          const idx = fadeOutIntervals.indexOf(timer);
          audio.volume = fadeOutVolumes[idx] / 100;
        }

        if (timer > startTimeMs && timer < stopTimeMs) {
          const audioCurTimeMs = floatToFloatSec(audio.currentTime) * 1000;
          if (!(audioCurTimeMs >= trimStartTimeMs && audioCurTimeMs <= trimStopTimeMs)) {
            // when audio is outside playable time range
            audio.currentTime = trimStartTimeMs / 1000;
            if (isLooped) {
              if (audio.paused) playMedia(audio);
            } else {
              audio.pause();
            }
          } else {
            if (audio.paused) playMedia(audio);
          }
        }

        if (timer === stopTimeMs) {
          audio.pause();
          if (isMuted) audio.muted = true;
          audio.removeAttribute("data-playing");
          audio.currentTime = trimStartTimeMs / 1000;
        }
      }
    });
  };

  useEffect(() => {
    if (action === "play" && timerMs >= 0 && !intervalId) {
      getAllAudioData();
      let timer = timerMs;

      if (timer === timerMs) {
        // reset audio current time at playhead change, replay and play start
        setAudioCurTime({ timer });
      }

      if (timer === 0) {
        audioPlay({ timer });
      }

      intervalId = setInterval(() => {
        timer += 100;
        audioPlay({ timer });
      }, 100);
    }

    if (["pause", "natural_stop", "force_stop"].includes(action)) {
      stopDomPlay();
    }

    return () => {
      stopDomPlay();
    };
  }, [action, timerMs]);

  useEffect(() => {
    // works for both page play and document play
    if (["play", "pause", "natural_stop", "force_stop"].includes(pagePlayData.action) && pagePlayData.pageTimer >= 0) {
      const allPageStartTimes = getAllPageStartTimes();
      const curPageStartTime = allPageStartTimes?.[pagePlayData.pageIdx] ?? 0;
      const curPagePlayingTime = pagePlayData?.pageTimer ?? 0;
      setTimerMs(curPageStartTime + curPagePlayingTime);
      setAction(pagePlayData.action);
    }
  }, [pagePlayData.action]);

  useEffect(() => {
    // when not in playing mode, pre-load audio at change of active page (so that it reduce buffering time when playing)
    if (metadata.activePageId && !playingPageIdx) {
      setAudioCurTimeToDefault({ pageIdx: metadata.activePageIdx });
    }
  }, [metadata.activePageId, playingPageIdx]);

  return {
    setAudioCurTimeToDefault,
  };
};

export default useAudioPlay;
