import React, { useContext, useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";

import { AppContext } from "../../../../contexts";
import Modal from "../../../../components/ui/modal";
import { CommonModal } from "../../../Company/CompanyModal";

function RouteLeavingGuard() {
  const { isSaveInProgress, setIsSaveInProgress, setRestartSave } = useContext(AppContext);
  const history = useHistory();

  const [isDirty, setDirty] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [lastLocation, setLastLocation] = useState();
  const [isChangeLocation, setIsChangeLocation] = useState(false);

  // Trigger on before route change
  const handleRouteChange = nextLocation => {
    setLastLocation(nextLocation);

    // Show modal if saving inprogress
    if (isDirty && !isChangeLocation && location?.pathname !== nextLocation?.pathname) {
      setRestartSave(true);
      setShowModal(true);
      return false;
    }
    return true;
  };

  const changeRoute = () => {
    setShowModal(false);
    setIsChangeLocation(true);
  };

  // Set Dirty to true if save inprogress
  useEffect(() => {
    setDirty(isSaveInProgress);
  }, [isSaveInProgress]);

  // Redirect to last location on click ok
  useEffect(() => {
    if (isChangeLocation) {
      if (isSaveInProgress) setIsSaveInProgress(false);
      history.push(lastLocation);
    }
  }, [isChangeLocation]);

  return (
    <>
      <Prompt when={isDirty} message={handleRouteChange} />
      {showModal && (
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          component={CommonModal}
          modalTitle="Leave the Editor"
          modalBody={
            <div>
              Your recent changes may not have been saved. <br />
              Are you sure to leave the editor?
            </div>
          }
          modalButtonLabel="Leave"
          successFunc={changeRoute}
        />
      )}
    </>
  );
}

export default RouteLeavingGuard;
