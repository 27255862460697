import React, { useContext, useLayoutEffect, useState } from "react";

import global from "../../scss/dhp.scss";

import { EditorContext } from "./EditorLayout";
import { AUDIO } from "../../constants/editor";
import components from "../../components/Editor/ContextualMenu/contextMenuIndex";
import { widgetConfig } from "../../components/Editor/editor_config";

let style = Object.assign({}, global);

const AudioContextualMenubar = () => {
  let { metadata } = useContext(EditorContext);

  const [contextualMenuObj, setContextualMenuObj] = useState();

  // set contextual menu bar list according active audio
  useLayoutEffect(() => {
    if (metadata.activeAudioId) {
      let contextMenuList = widgetConfig[AUDIO]?.contextualMenu?.topLevel?.map(item => ({
        name: components[item],
      }));

      setContextualMenuObj(contextMenuList);
    }
  }, [metadata.activeAudioId]);

  return (
    <>
      <ul className={style["toolbar-group"]} id="editor-toolbar">
        {contextualMenuObj?.length > 0 && contextualMenuObj?.map((item, i) => <item.name key={i} />)}
      </ul>
    </>
  );
};

export default AudioContextualMenubar;
