import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner } from "reactstrap";
import cx from "classnames";
import PropTypes from "prop-types";

import { EditorContext } from "../../../containers/editor/EditorLayout";
import { FlashAlert } from "../../ui/flashAlert";
import { removePictureBackground } from "../../../store/actions/editorActions";
import { Icon } from "../../ui/icon";

import global from "../../../scss/dhp.scss";
import { getImgDimensionFromUrl } from "../../../_helpers/utils";

let style = Object.assign({}, global);

const removeBackgroundModal = props => {
  const dispatch = useDispatch();
  let { metadata, widgets, updateWidgets, rightContextMenu, updateRightContextMenu } = useContext(EditorContext);
  const bgRemovedData = useSelector(state => state?.editor?.bgRemovedData);
  const [image, setImage] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const removeBGErrorMessages = [{
    statusCode: 400,
    message: "Could not detect the background"
  }, {
    statusCode: 400,
    code: "file_too_large",
    message: "File size should be maximum 12 MB"
  }, {
    statusCode: 402,
    message: "You do not have enough credits left"
  }, {
    statusCode: 403,
    message: "Missing API Key"
  }, {
    statusCode: 429,
    message: "You do not have enough credits left"
  }];

  const removeBackground = () => {
    if (image) {
      setProcessing(true);
      dispatch(removePictureBackground(image));
      setIsRemoved(true);
    }
  };

  const toggle = () => {
    setShowError(false);
  };

  const applyPictureToDOM = () => {
    let curWidget = document.getElementById(`${metadata.activeWidgetId[0]}`);
    let currentImage = curWidget.getElementsByTagName("img")[0];
    currentImage.setAttribute("src", image);

    // remove border from background removed images
    let targetElem = curWidget.querySelector(".flippable").style;
    if (targetElem?.borderStyle && targetElem.borderStyle !== "none") {
      targetElem.borderStyle = "none";
      targetElem.borderRadius = "0px";
      let widgetInner = curWidget.querySelector(".dhp-widget-inner");
      widgetInner.style.left = "";
      widgetInner.style.top = "";
      widgetInner.style.position = "";
    }

    props.setShowModal(false);

    // Close right context menubar if open
    if (rightContextMenu.enable)
      updateRightContextMenu({
        ...rightContextMenu,
        enable: false,
      });

    let id = metadata.activeWidgetId[0];
    let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
    let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
    let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);

    let newArray = Object.assign([...widgets], {
      [targetWidgetIndex]: {
        ...widgets[targetWidgetIndex],
        innerHTML: document.getElementById(targetId).innerHTML,
      },
    });
    updateWidgets(newArray);
    if (bgRemovedData?.success) props.setDisableAction(true);
  };

  const closeRemoveBgModal = () => {
    props.setShowModal(!props.showModal);

    // Close right context menubar if open
    if (rightContextMenu.enable)
      updateRightContextMenu({
        ...rightContextMenu,
        enable: false,
      });
  };

  useEffect(() => {
    if (isRemoved && bgRemovedData?.response?.image_url) {
      setImage(bgRemovedData.response.image_url);
      getImgDimensionFromUrl(bgRemovedData.response.image_url).then(res => {
        if (res.width > 0 && res.height > 0) setProcessing(false);
      });
    }
    if (isRemoved && ([400, 402, 403, 429].includes(bgRemovedData?.response?.status_code) || !bgRemovedData?.success)) {
      setErrorMessage(removeBGErrorMessages.find(err => err?.code === bgRemovedData?.response?.error[0]?.code || err.statusCode === bgRemovedData?.response?.status_code)?.message)
      setShowError(true);
      setProcessing(false);
    }
  }, [bgRemovedData]);

  useEffect(() => {
    if (metadata.activeWidgetId) {
      let currentImage = document.getElementById(`${metadata.activeWidgetId[0]}`).getElementsByTagName("img")[0].src;
      setImage(currentImage);
    }
  }, [props.showModal]);

  return (
    <>
      <h4 className={cx(style["fw-7"])}>Remove Background</h4>

      <span
        className={cx(style["cross-modal"], style["rounded"], { [style["d-none"]]: processing })}
        onClick={closeRemoveBgModal}>
        <Icon icon="ui-close" />
      </span>
      <div className={style["img-filter-wrap"]}>
        <div className={style["img-remove-bg"]}>{image && <img src={image} />}</div>
      </div>
      <div className={cx(style["modal-footer"], style["justify-content-start"], style["p-0"], style["border-0"])}>
        <Button type="button" color="primary" onClick={() => removeBackground()} disabled={isRemoved || processing}>
          Remove
        </Button>
        <Button type="button" color="primary" onClick={() => applyPictureToDOM()} disabled={!isRemoved || processing}>
          Insert
        </Button>
        {processing && <Spinner size="sm" color="dark" className={style["ml-5"]} />}
        <FlashAlert
          isOpen={showError}
          toggle={toggle}
          type={"error"}
          wrapperClass="ml-2"
          message={errorMessage}
        />
      </div>
    </>
  );
};

removeBackgroundModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  setDisableAction: PropTypes.func.isRequired,
};

export default removeBackgroundModal;
