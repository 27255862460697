import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import cx from "classnames";
import { Icon } from "../../ui/icon";
import Progressbar from "../../ui/progressbar";

import global from "../../../scss/dhp.scss";
import Sparkle from "../../../assets/images/sparkle.svg";
import Exclamation from "../../../assets/images/exclamation-mark.svg";
import { SUPPORT_EMAIL } from "../../../constants/env";

let style = Object.assign({}, global);

const ThirdPartyUploadModal = ({ progressbarInfo, showModal, setShowModal, fileLink, uploadError }) => {
  const sendMail = () => {
    const link = `mailto:${SUPPORT_EMAIL}`;
    window.location.href = link;
  };

  return (
    <React.Fragment>
      <h4 className={cx(style["fw-7"], style["mb-4"])}>Save to Mailchimp</h4>
      <span className={cx(style["cross-modal"], style["rounded"])} onClick={() => setShowModal(!showModal)}>
        <Icon icon="ui-close" />
      </span>

      <div
        className={cx(style["download-loader"], style["download-steps"], style["text-center"], {
          [style["d-none"]]: uploadError || fileLink,
        })}>
        <div className={style["download-progressbar"]}>
          <Progressbar progressbarInfo={progressbarInfo} />
        </div>
        <h6 className={cx(style["fw-7"], style["pb-1"], style["mt-4"])}>Please Wait</h6>
        <p className={style["mb-0"]}> Preparing your designs</p>
      </div>

      <div
        className={cx(style["download-link"], style["download-steps"], style["text-center"], {
          [style["d-none"]]: uploadError || !fileLink,
        })}>
        <img src={Sparkle} width="70" alt="" />
        <h6 className={cx(style["fw-7"], style["pb-1"], style["mt-4"])}>Your designs are saved in Mailchimp!</h6>
        <p className={style["mb-0"]}>
          All your designs are saved under <b>My Files</b> in Mailchimp.
          <br />
          <Link to={{ pathname: fileLink }} target="_blank">
            Click here
          </Link>{" "}
          to view them
        </p>
      </div>

      <div
        className={cx(style["download-error"], style["download-steps"], style["text-center"], {
          [style["d-none"]]: !uploadError,
        })}>
        <img src={Exclamation} width="70" alt="" />
        <h6 className={cx(style["fw-7"], style["pb-1"], style["mt-4"])}> We could not upload your document!</h6>
        <p className={style["mb-0"]}>
          DocHipo experienced a technical issue. Please try <br />
          after some time. If the issue continues, please <br />
          write to us at{" "}
          <Link to="" onClick={sendMail}>
            {SUPPORT_EMAIL}
          </Link>
        </p>
      </div>
    </React.Fragment>
  );
};

ThirdPartyUploadModal.propTypes = {
  progressbarInfo: PropTypes.number,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  fileLink: PropTypes.string,
  uploadError: PropTypes.bool,
};

export default ThirdPartyUploadModal;
