import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Nav, NavItem, NavLink } from "reactstrap";

import { BACKGROUND } from "../../../../constants/editor";
import { EditorContext } from "../../../../containers/editor/EditorLayout";

const AssetSchemeTypeNav = props => {
  const availableAssetSchemeTypes =
    props.schemeTypeArray || useSelector(state => state?.editor?.schemeTypes?.[props?.assetType]);
  const defaultSchemeType = useSelector(state => state?.editor?.defaultSchemeType);
  const [activeTab, setActiveTab] = useState(props.activeTabIndex ?? 0);

  // Update asset scheme types
  const updateTabTypeScheme = (index, availableAsseSchemeType) => {
    if (activeTab !== index) setActiveTab(index);
    props.setSchemeType(availableAsseSchemeType);
    props.setPageNumber && props.setPageNumber(1);
    if (availableAsseSchemeType !== props.schemeType) props.setIsAssetFetched && props.setIsAssetFetched(false);

    const dataObj = JSON.parse(sessionStorage.getItem("dataObj"));
    dataObj.schemeType = availableAsseSchemeType;
    sessionStorage.setItem("dataObj", JSON.stringify(dataObj));
    props.setCategory && props.setCategory("");
  };

  useEffect(() => {
    // Update activeTab when activeTabIndex prop changes
    props.activeTabIndex !== undefined && setActiveTab(props.activeTabIndex);
  }, [props.activeTabIndex]);

  return (
    <>
      {availableAssetSchemeTypes && (
        <Nav tabs className={props?.className}>
          {availableAssetSchemeTypes?.map((availableAssetSchemeType, index) => (
            <NavItem key={availableAssetSchemeType}>
              <NavLink
                className={activeTab === index ? "active" : ""}
                onClick={() => updateTabTypeScheme(index, availableAssetSchemeType)}>
                {availableAssetSchemeType.charAt(0).toUpperCase() + availableAssetSchemeType.slice(1)}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      )}
    </>
  );
};

//Props type validation
AssetSchemeTypeNav.propTypes = {
  schemeType: PropTypes.string,
  schemeTypeArray: PropTypes.array,
  assetType: PropTypes.string,
  setSchemeType: PropTypes.func.isRequired,
  setPageNumber: PropTypes.func,
  setIsAssetFetched: PropTypes.func,
  className: PropTypes.string,
  activeTabIndex: PropTypes.number,
  setCategory: PropTypes.func,
};
export default AssetSchemeTypeNav;
