import React, { useEffect } from "react";
import { Modal as RModal } from "reactstrap";
import PropTypes from "prop-types";
import global from "../../scss/dhp.scss";

let style = Object.assign({}, global);

const Modal = ({
  showModal,
  setShowModal,
  backdrop,
  component,
  modalClassName,
  className,
  centered,
  size,
  ...props
}) => {
  const Component = component;
  const backdropData = backdrop ? "static" : true;
  document.body.style.overflow = showModal ? "hidden" : "";

  useEffect(() => {
    return () => {
      // force clear overflow property at unmount
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <RModal
      size={size}
      centered={centered ?? true}
      isOpen={showModal}
      backdrop={backdropData}
      modalClassName={modalClassName}
      contentClassName={style["rounded-lg"]}
      className={className}
      toggle={() => setShowModal(!showModal)}>
      <Component {...{ showModal, setShowModal, ...props }} />
    </RModal>
  );
};

Modal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func,
  backdrop: PropTypes.string,
  component: PropTypes.func.isRequired,
  modalClassName: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  centered: PropTypes.bool,
};

export default Modal;
