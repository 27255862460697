import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Form, Button } from "reactstrap";
import cx from "classnames";

import {
  DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_SUCCESS,
  DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_FAILED,
  DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_INFO_SUCCESS,
  DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_INFO_FAILED,
} from "../../../store/actions/actionTypes";
import {
  publishDocumentToTemplateLibrary,
  publishDocumentToTemplateLibraryInfo,
} from "../../../store/actions/documentActions";
import { fetchTemplateCategoriesByType } from "../../../store/actions/templateActions";
import { preventFormSubmitOnEnter as preventSubmit } from "../../../_helpers/utils";
import { Input } from "../../ui/input";
import { Icon } from "../../ui/icon";
import { ProcessLoader } from "../../ui/loader/processLoader";
import { FlashAlert } from "../../ui/flashAlert";

import global from "../../../scss/dhp.scss";

let style = Object.assign({}, global);

const PublishToTemplateLibraryModal = ({ document: data, _helpers }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const alertData = {
    show: false,
    type: "",
  };
  const errorTypes = [DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_FAILED, DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_INFO_FAILED];
  const processEndTypes = [DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_SUCCESS, DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_FAILED];
  const modalName = data.published_library_template ? "Republish to DocHipo Template" : "Publish to DocHipo Template";
  const ButtonName = data.published_library_template ? "Republish" : "Publish";

  const form = {
    group: {
      value: data?.template_info?.group_id,
      valid: true,
    },
    type: {
      value: "",
      options: [],
      validation: {
        required: true,
      },
      valid: false,
    },
    category: {
      value: "",
      options: [],
      validation: {
        required: true,
      },
      valid: false,
    },
    tags: {
      value: [],
      valid: true,
    },
    premium: {
      value: false,
      valid: true,
    },
  };

  const [formData, setFormData] = useState(form);
  const [formValid, setFormValid] = useState(false);
  const [defaultType, setDefaultType] = useState();
  const [defaultCategory, setDefaultCategory] = useState();
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [alert, setAlert] = useState(alertData);
  const [disable, setDisable] = useState({ publish: false });

  const { type, template } = useSelector(state => state?.document);

  const toggleAlert = () => setAlert({ ...alert, show: !alert.show });

  const generateDependencyOptions = field => {
    let updatedForm = { ...formData };
    if (field === "type") {
      updatedForm[field].options = _helpers.gridView.templates.groupObj[data?.template_info?.group_id]?.type;
    }
    if (field === "category") {
      updatedForm[field].options = _helpers.gridView.templates.typeObj[updatedForm.type.value]?.category;

      // upgrade: show all template categories (published + unpublished) by type for manager superadmin
      const type = _helpers.gridView.templates.typeObj[updatedForm.type.value];
      dispatch(fetchTemplateCategoriesByType(type.slug, true)).then(resp => {
        updatedForm[field].options = resp?.result?.template_categories;
        setFormData(updatedForm);
      });
    }
    setFormData(updatedForm);
  };

  const checkValidity = (value, rules) => {
    let isValid = true;
    if (rules.required) isValid = value.trim() !== "" && isValid;
    return isValid;
  };

  const updateForm = (e, field) => {
    let updatedForm = { ...formData };
    if (["type", "category"].includes(field)) {
      updatedForm[field].value = e.id;
      updatedForm[field].valid = checkValidity(updatedForm[field].value, updatedForm[field].validation);
      if (field === "type" && updatedForm.type.valid) {
        generateDependencyOptions("category");
        if (updatedForm.category.valid) {
          setDefaultCategory(null);
          updatedForm.category.value = "";
          updatedForm.category.valid = false;
        }
      }
      if (field === "category" && updatedForm.category.valid) {
        setDefaultCategory(e);
      }
    }
    if (field === "tags") {
      updatedForm[field].value = e;
    }
    if (field === "premium") {
      updatedForm[field].value = e?.target?.checked ?? e;
    }
    setFormData(updatedForm);
    setFormValid(!Object.keys(formData).some(field => !formData[field].valid));
  };

  const handleSubmit = e => {
    if (formValid) {
      e.preventDefault();
      setIsMounted(true);
      let payload = {
        documentId: data.id,
        group: formData.group.value,
        type: formData.type.value,
        category: formData.category.value,
        tags: formData.tags.value,
        premium: formData.premium.value,
        enabled: true,
      };
      dispatch(publishDocumentToTemplateLibrary(payload));
      setDisable({ publish: true });
    }
  };

  const postApiCallActions = () => {
    if (type === DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_SUCCESS) {
      setIsMounted(false);
      setAlert({ ...alert, show: true, type: "success", message: "Document has been published to library" });
      setPublishSuccess(true);
    }
    if (type === DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_INFO_SUCCESS) {
      setIsMounted(false);
      if (template?.template_type) {
        updateForm({ name: template?.type, id: template?.template_type }, "type");
        setDefaultType({ name: template?.type, id: template?.template_type });
      }
      if (template?.template_category) {
        updateForm({ name: template?.category, id: template?.template_category }, "category");
      }
      if (template?.tags) updateForm(template?.tags, "tags");
      if (template?.premium) {
        updateForm(template?.premium, "premium");
        document.getElementById("premium-toggle").checked = template?.premium;
      }
    }
    if (errorTypes.includes(type)) {
      setIsMounted(false);
      setAlert({ ...alert, show: true, type: "error" });
    }
  };

  useEffect(() => {
    if (isMounted && type) postApiCallActions();
  }, [type, isMounted]);

  useEffect(() => {
    if (publishSuccess && !alert.show) {
      setPublishSuccess(false);
      _helpers.modal.toggle();

      if (_helpers.ref === "documents") {
        _helpers.gridView.fetch.setPayload({ ..._helpers.gridView.fetch.payload, page: 1 });
      }
      if (_helpers.ref === "editor") {
        history.go(0);
      }
    }
  }, [publishSuccess, alert.show]);

  useEffect(() => {
    if (_helpers.gridView.templates.groupObj) generateDependencyOptions("type");
  }, [_helpers.gridView.templates.groupObj]);

  useEffect(() => {
    if (data.published_library_template) {
      setIsMounted(true);
      dispatch(publishDocumentToTemplateLibraryInfo(data.id));
    }
  }, []);

  return (
    <React.Fragment>
      <h4 className={cx(style["fw-7"], style["mb-4"])}>{modalName}</h4>
      <Link to="#" className={cx(style["cross-modal"], style["rounded"])} onClick={_helpers?.modal?.toggle}>
        <Icon icon="ui-close" />
      </Link>

      <Form onKeyDown={e => preventSubmit(e)} onSubmit={e => handleSubmit(e)}>
        <Input
          cssModule={style}
          returnType="formGroup"
          type="text"
          label="Document Group"
          readOnly={true}
          value={_helpers.gridView.templates.groupObj[data?.template_info?.group_id]?.name}
        />

        <Input
          cssModule={style}
          returnType="formGroup"
          type="dropdown"
          label="Document Type"
          required={true}
          classNamePrefix="select"
          getOptionValue={option => option?.name}
          getOptionLabel={option => <span>{option?.name}</span>}
          options={formData.type.options}
          updateState={e => updateForm(e, "type")}
          defaultValue={defaultType}
        />

        <Input
          cssModule={style}
          returnType="formGroup"
          type="dropdown"
          label="Document Category"
          required={true}
          classNamePrefix="select"
          getOptionValue={option => option?.name}
          getOptionLabel={option => <span>{option?.name}</span>}
          options={formData.category.options}
          updateState={e => updateForm(e, "category")}
          defaultValue={defaultCategory}
        />

        <Input
          returnType="formGroup"
          type="tag"
          label="Keywords"
          defaultValue={formData.tags.value}
          updateState={e => updateForm(e, "tags")}
        />

        <div className={cx(style["custom-control"], style["custom-switch"], style["lr-label"], style["mb-4"])}>
          <span className={style["left"]}>Free</span>
          <Input
            cssModule={style}
            returnType="noGroup"
            type="checkbox"
            className={style["custom-control-input"]}
            id="premium-toggle"
            label="Premium"
            defaultChecked={formData.premium.value}
            onChange={e => updateForm(e, "premium")}
          />
        </div>

        <div className={cx(style["align-items-center"], style["d-flex"])}>
          <Button type="submit" color="primary" cssModule={style} disabled={!formValid || disable.publish}>
            {ButtonName}
          </Button>

          <ProcessLoader isOpen={disable.publish && !processEndTypes.includes(type)} />

          <FlashAlert
            isOpen={alert.show}
            toggle={toggleAlert}
            type={alert.type}
            wrapperClass="ml-2"
            message={alert.message}
          />
        </div>
      </Form>
    </React.Fragment>
  );
};

PublishToTemplateLibraryModal.propTypes = {
  document: PropTypes.object.isRequired,
  _helpers: PropTypes.object.isRequired,
};

export default PublishToTemplateLibraryModal;
