import React, { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import cx from "classnames";
import { Icon } from "../../ui/icon";
import Progressbar from "../../ui/progressbar";

import global from "../../../scss/dhp.scss";
import { useDispatch, useSelector } from "react-redux";
import { useSocket } from "../../../hooks/useSocket";
import { fetchAllCustomFonts, uploadCustomFonts } from "../../../store/actions/resourcesActions";
import { DELETE_CUSTOM_FONTS_SUCCESS, UPDATE_CUSTOM_FONTS, UPLOAD_CUSTOM_FONTS_SUCCESS } from "../../../store/actions/actionTypes";
import { CUSTOM_FONTS_UPDATE } from "../../../constants/socket";

let style = Object.assign({}, global);

const fontUploadModal = ({ showModal, setShowModal, origin, ...props }) => {
    const dispatch = useDispatch();
    const webSocket = useSocket();
    const actionType = useSelector(state => state?.resources?.type);
    const authState = useSelector(state => state?.auth);

    const userId = authState?.uid;
    const companyId = authState?.user?.company?.id;

    const uploadFontStages = [
        { action: "Select", title: "Upload a Font" },
        { action: "Confirm", title: "Confirm Right to Use" },
        { action: "Upload", title: "Upload Font" },
        { action: "Exists", title: "Font Already Exists", message: "" },
        { action: "Error", title: "Invalid Format", message: "" }];

    const [uploadFontError, setUploadFontError] = useState({ status: false, message: "" });
    const [uploadStgae, setUploadStgae] = useState(uploadFontStages[0]);
    const [fileData, setFileData] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(false);
    const isMounted = useRef(true);

    //Function to select Fonts to be uploaded
    const fontSelectedHandler = e => {
        const file = e.target.files[0]; // Get the first selected file
        if (file) {
            let fileSize = file.size / (1024 * 1024);
            const fileFormat = file.name.split(".").pop().toLowerCase();
            let allowedTypes = ["ttf", "otf", "woff", "woff2"];
            if (file && allowedTypes.includes(fileFormat) && fileSize <= 5) {
                setUploadFontError({ status: false, message: "success" });
                setUploadStgae(uploadFontStages[1]); //take Confirm Right to Use uploading font from user
                setFileData(file); // keep file in state to process upload
            } else if (fileSize > 5) {
                e.target.value = ""; // cleaning the file upload field
                setUploadFontError({ status: true, message: "File Size should be maximum 5 MB." });
            } else {
                e.target.value = ""; // cleaning the file upload field
                setUploadFontError({ status: true, message: "Invalid Format!!" });
            }

            setTimeout(function () {
                setUploadFontError({ status: false, message: "" });
            }, 3000);
        }
    };

    const handleConfirmRight = () => {
        setUploadStgae(uploadFontStages[2]); // show upload progress
        calculateProgress();
        uploadFont();
    }

    //Function to upload selected Fonts
    const uploadFont = () => {
        let formData = new FormData();
        formData.append("file", fileData, fileData.name);
        dispatch(uploadCustomFonts(formData)).then(res => {
            if (isMounted.current) {
                if (!res?.data?.status && res?.data?.message) {
                    setTimeout(() => {
                        setUploadStgae({ ...uploadFontStages[4], message: res?.data?.message }); // show Exists in DB
                    }, 200)
                }
                else if (res?.data?.upload?.message) {
                    calculateProgress(100);
                    if (origin === "editor") dispatch(fetchAllCustomFonts(companyId));
                    setTimeout(() => {
                        if (!res?.data?.upload?.updated)
                            setUploadStgae({ ...uploadFontStages[3], message: res?.data?.upload?.message }); // show Exists in DB
                    }, 200)
                    if (res?.data?.upload?.updated) {
                        if (origin === "company") {
                            props?.setFetchEnabled(true);
                            props?.setInitialFetchDone(false);
                            props?.setPayloadFetch({ ...props?.payloadFetch, page: 1 });
                        }
                        setTimeout(() => {
                            setShowModal(false);
                        }, 200)
                    }
                }
                else if (res?.data?.upload?.family) {
                    if (origin === "editor") dispatch(fetchAllCustomFonts(companyId));
                    if (origin === "company") {
                        props?.setFetchEnabled(true);
                        props?.setInitialFetchDone(false);
                        props?.setPayloadFetch({ ...props?.payloadFetch, page: 1 });
                    }
                    setTimeout(() => {
                        setShowModal(false);
                    }, 200)
                }
                else {
                    setUploadFontError({ status: true, message: "Something Went Wrong" });
                }
                setFileData(false)
            }
        })

    };

    const calculateProgress = (val) => {
        let progress = val ?? 0;
        let timer = setInterval(() => {
            if (progress < 100) {
                progress += 4;
                if (progress > 100) {
                    progress = 100;
                }
            }
            if (isMounted.current) setUploadProgress(progress)
            if (progress >= 100 || !isMounted.current) {
                clearInterval(timer);
            }
        }, 300);
    }

    useEffect(() => {
        if (origin !== "company" && actionType && [UPLOAD_CUSTOM_FONTS_SUCCESS, DELETE_CUSTOM_FONTS_SUCCESS, UPDATE_CUSTOM_FONTS].includes(actionType) && webSocket?.readyState === 1) {
            const subscribe = {
                type: CUSTOM_FONTS_UPDATE,
                userId,
                companyId,
            };
            webSocket.send(JSON.stringify(subscribe));
        }
    }, [actionType, origin]);

    useEffect(() => {
        return () => {
            setFileData(false);
            setUploadProgress(false);
            isMounted.current = false;
        };
    }, []);

    return (
        <React.Fragment>
            <h4 className={cx(style["fw-7"], style["mb-4"])}>{uploadStgae.title}</h4>
            <span className={cx(style["cross-modal"], style["rounded"])} onClick={() => setShowModal(!showModal)}>
                <Icon icon="ui-close" />
            </span>

            {/* Select Font file to upload | SECTION */}
            <div className={cx(style["text-center"], style["upload-font-modal"], style["mt-5"], { [style["d-none"]]: uploadStgae?.action !== "Select" })}>
                <div className={cx(style["fileUpload"], style["mb-2"])}>
                    <input
                        type="file"
                        accept=".ttf, .otf, .woff, .woff2"
                        onChange={e => fontSelectedHandler(e)}
                    />
                    <span className={cx(style["btn"], style["btn-border"], style["px-5"])}>
                        <Icon icon="ui-upload" additionalclass={"mr-2"} /> Upload
                    </span>
                </div>
                <p> TTF, OTF, WOFF. <br /> File Size: Maximum 5 MB </p>
                {uploadFontError.status && (
                    <div
                        className={cx(style["alert"], style["alert-block"], style["alert-danger"], style["mr-4"])}>
                        {uploadFontError.message}
                    </div>
                )}
            </div>

            {/* Confirm Authority  | SECTION  */}
            <div className={cx({ [style["d-none"]]: uploadStgae?.action !== "Confirm" })}>
                <p className={style["font-base"]}>Please confirm that you own or have the right to <br /> use this font.</p>
                <Button color="primary" type="button" onClick={handleConfirmRight}>
                    Confirm
                </Button>
            </div>

            {/* Process Upload  | SECTION  */}
            <div
                className={cx(style["download-loader"], style["download-steps"], style["text-center"], style["my-5"], {
                    [style["d-none"]]: uploadStgae?.action !== "Upload",
                })}>
                <div className={cx(style["progress"], style["download-progressbar"], style["my-4"], style["m-auto"], style["w-75"])}>
                    <Progressbar progressbarInfo={uploadProgress} />
                </div>
                <h6 className={cx(style["fw-7"], style["pb-1"], style["mt-4"])}>Please Wait</h6>
                <p className={cx(style["mb-0"], style["font-base"])}> Uploading your custom font</p>
            </div>


            {/* Handle Already Exists | SECTION  */}
            <div
                className={cx(style["download-loader"], style["download-steps"], {
                    [style["d-none"]]: !["Exists", "Error"].includes(uploadStgae?.action),
                })}>
                {uploadStgae?.message?.includes("preset") && <p className={style["font-base"]}> The font you are trying to upload is already <br /> available under preset fonts.</p>}
                {uploadStgae?.message?.includes("custom") && <p className={style["font-base"]}> The font you are trying to upload is already <br /> available under custom fonts.</p>}
                {uploadStgae?.action === "Error" && <p className={style["font-base"]}> The uploaded font file is invalid.</p>}
                <Button color="primary" type="button" onClick={() => setShowModal(!showModal)}>Okay</Button>
            </div>
        </React.Fragment>
    );
};

fontUploadModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    origin: PropTypes.string
};

export default fontUploadModal;
