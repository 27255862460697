import ClipBoard from "./ClipBoard";
import Cut from "./Cut";
import Copy from "./Copy";
import Paste from "./Paste";
import Clone from "./Clone";
import Effect from "./Effect";
import Shadow from "./Shadow";
import Border from "./Border";
import Outline from "./Outline";
import CopyStyle from "./CopyStyle";
import Animate from "./Animate";
import Opacity from "./Opacity";
import Link from "./Link";
import Arrange from "./Arrange";
import Layer from "./Layer";
import Align from "./Align";
import Rotate from "./Rotate";
import Lock from "./Lock";
import Delete from "./Delete";
import Heading from "./Heading";
import FontFamily from "./FontFamily";
import FontSize from "./FontSize";
import Bold from "./Bold";
import Italic from "./Italic";
import Underline from "./Underline";
import TextAlign from "./TextAlign";
import Spacing from "./Spacing";
import Strickthrough from "./Strickthrough";
import Transform from "./Transform";
import List from "./List";
import More from "./More";
import Color from "./Color";
import LineThickness from "./LineThickness";
import RemoveBackground from "./RemoveBackground";
import Filter from "./Filter";
import Crop from "./Crop";
import Pause from "./Pause";
import Loop from "./Loop";
import Group from "./Group";
import UnGroup from "./UnGroup";
import Qr from "./Qr";
import AiWriter from "./AiWriter";
import GridSpacing from "./GridSpacing";
import Trim from "./Trim";
import Volume from "./Volume";
import MapToolBar from "./MapToolBar";
import TableToolBar from "./TableToolBar";
import { AI_WRITER } from "../../../constants/editor";
import Fade from "./Fade";

let Components = {};

Components["ClipBoard"] = ClipBoard;
Components["Cut"] = Cut;
Components["Copy"] = Copy;
Components["Paste"] = Paste;
Components["Clone"] = Clone;

Components["Effect"] = Effect;
Components["Shadow"] = Shadow;
Components["Border"] = Border;
Components["Outline"] = Outline;

Components["CopyStyle"] = CopyStyle;
Components["Animate"] = Animate;
Components["Opacity"] = Opacity;
Components["Link"] = Link;

Components["Arrange"] = Arrange;
Components["Layer"] = Layer;
Components["Align"] = Align;
Components["Rotate"] = Rotate;

Components["Lock"] = Lock;
Components["Delete"] = Delete;

Components["Heading"] = Heading;
Components["FontFamily"] = FontFamily;
Components["FontSize"] = FontSize;
Components["Bold"] = Bold;
Components["Italic"] = Italic;
Components["Underline"] = Underline;
Components["TextAlign"] = TextAlign;
Components["Spacing"] = Spacing;
Components["Strickthrough"] = Strickthrough;
Components["Transform"] = Transform;
Components["List"] = List;
Components[AI_WRITER] = AiWriter;
Components["More"] = More;

Components["Color"] = Color;
Components["LineThickness"] = LineThickness;
Components["RemoveBackground"] = RemoveBackground;
Components["Filter"] = Filter;
Components["Crop"] = Crop;
Components["Pause"] = Pause;
Components["Loop"] = Loop;
Components["Trim"] = Trim;
Components["Volume"] = Volume;

Components["Group"] = Group;
Components["UnGroup"] = UnGroup;

Components["Qr"] = Qr;

Components["GridSpacing"] = GridSpacing;

Components["Fade"] = Fade;
Components["MapToolBar"] = MapToolBar;
Components["TableToolBar"] = TableToolBar;

export default Components;
