import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { DropdownItem } from "reactstrap";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { GROUP_WIDGET } from "../../../constants/editor";

let style = Object.assign({}, global);

const BringForward = props => {
  let { metadata, widgets } = useContext(EditorContext);

  const [isDisable, setIsDisable] = useState();
  const [isAllwidgetActive, setIsAllwidgetActive] = useState(true);
  const [isAllActiveWidgetFront, setIsAllActiveWidgetFront] = useState(true);

  useEffect(() => {
    if (isAllwidgetActive || isAllActiveWidgetFront) setIsDisable(true);
    else setIsDisable(false);
  }, [isAllwidgetActive, isAllActiveWidgetFront]);

  useEffect(() => {
    setIsAllActiveWidgetFront(true);
    metadata.activeWidgetId.forEach(id => {
      let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
      let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget")?.getAttribute("id") : id;
      let widgetIndex = widgets.findIndex(widget => widget.id === targetId);

      if (
        widgets[widgetIndex + 1]?.blockId === metadata.activeBlockId &&
        !metadata.activeWidgetId.includes(widgets[widgetIndex + 1].id)
      ) {
        setIsAllActiveWidgetFront(false);
        return;
      }
    });
  }, [metadata.activeWidgetId, widgets]);

  useEffect(() => {
    props.currentBlockWidgetList.forEach(element => {
      let selectedId =
        element.data["data-asset-type"] === GROUP_WIDGET
          ? document.querySelector(`#${element.id} .child-selected`)?.getAttribute("id")
          : element.id;

      if (!metadata.activeWidgetId.includes(selectedId)) {
        setIsAllwidgetActive(false);
        return;
      }
    });
  }, []);

  return (
    <li className={style["col"]}>
      <DropdownItem
        tag="a"
        onClick={() => props.changeWidgetLayer("bringForward")}
        className={cx({ [style["disabled"]]: isDisable })}>
        <Icon icon="ui-bring-forward" />
        Bring Forward
      </DropdownItem>
    </li>
  );
};

BringForward.propTypes = {
  changeWidgetLayer: PropTypes.func.isRequired,
  currentBlockWidgetList: PropTypes.array,
};

export default BringForward;
