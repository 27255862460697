import cx from "classnames";
import React from "react";

import global from "../../scss/dhp.scss";

import { Icon } from "../../components/ui/icon";
import CommonLineLoader from "../../components/ui/loader/commonLineLoader";

let style = Object.assign({}, global);

const EditorLoader = () => {
  return (
    <>
      {/* editor left side bar */}
      <div className={style["dhp-secondAside"]}>
        <div className={style["module-title"]}>
          {/* Documents */}
          <CommonLineLoader width={80} height="26px" loaderClass="pb-2" sourceComponent="editor" />
          {/* Document Type */}
          <CommonLineLoader width={65} height="16px" loaderClass="pb-2" sourceComponent="editor" />
        </div>

        <div className={style["editor-aside-content"]}>
          <div className={cx(style["search-field"], style["pb-3"])}>
            <CommonLineLoader width={100} height="42px" loaderClass="pb-2" sourceComponent="editor" /> {/* Search box */}
          </div>
          <div className={cx(style["customScroll"], style["scroll-Y"])}>
            <nav className={cx(style["template-groupNav"], style["pt-0"])}>
              <ul className={cx(style["nav"], style["flex-column"], style["h-100"], style["editor-sidenav-initmenu"])}>
                <li className={cx(style["template-group-item"], style["active"])}>
                  {/* Group name */}
                  <div className={cx(style["skeleton-loader-area"])}>
                    <span className={cx(style["loader-item"], style["icon"])}></span>
                    <span className={cx(style["loader-item"], style["text"])}></span>
                  </div>
                  {/* Assets */}
                  <ul className={cx(style["groupType"], style["widget-group"], style["clearfix"])}>
                    <div className={cx(style["skeleton-loader-area"])}>
                      <span className={cx(style["loader-item"], style["w-50"])} style={{height:"110px", marginRight:"1rem"}}></span>
                      <span className={cx(style["loader-item"], style["w-50"])} style={{height:"110px"}}></span>
                    </div>
                  </ul>
                </li>
                {/* Group name */}
                {[1, 2, 3, 4].map(r => (
                  <li className={style["template-group-item"]} key={r}>
                    <div className={cx(style["skeleton-loader-area"])}>
                      <span className={cx(style["loader-item"], style["icon"])}></span>
                      <span className={cx(style["loader-item"], style["text"])}></span>
                    </div>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className={cx(style["dhp-contentArea"])}>
        {/* Editor right side */}
        <div className={style["editor-wrapper"]}>
          {/* TopBar */}
          <div className={style["editor-topbar"]}>
            {/* TitleBar */}
            <div className={cx(style["titlebar"], { [style["video-titlebar"]]: process.env.IS_VIDEO_ENABLED })}>
              <CommonLineLoader width={50} height="26px" sourceComponent="editor" />

              <ul className={cx(style["toolbar-pageview"], style["w-50"])}>
                <li className={cx(style["template-group-item"], style["w-75"])}>
                  <div className={cx(style["skeleton-loader-area"])}>
                    {[1, 2, 3, 4].map(r => (
                      <span className={cx(style["loader-item"])} key={r}></span>
                    ))}
                  </div>
                </li>
              </ul>
            </div>

            {/* Toolbar */}
            <div className={style["toolbar"]}></div>
          </div>

          {/* Zoom */}
          <div className={cx(style["slide-controls"], style["editor-navigation"], style["rounded"], style["loading-time"])}>
            <div className="page-info-wdg">
              <CommonLineLoader width={100} height="36px" sourceComponent="editor" />
            </div>
          </div>

          {/* CanvasArea */}
          <div className={style["canvas-panel-area"]}>
            <span className={style["panel-toggle"]}>
              <Icon icon="ui-arrow-left" />
            </span>

            <div className={style["canvas-block"]}>
              <div className={cx(style["editor-outer-wrap"])}>
                {/* Controller section */}
                <div className={style["canvas-controller"]}>
                  <CommonLineLoader width={100} height="70px" loaderClass="pb-2" sourceComponent="editor" />
                  <CommonLineLoader width={100} height="70px" loaderClass="pb-2" sourceComponent="editor" />
                </div>

                {/* Editor */}
                <div
                  className={style["generic-editor-wrap"]}
                  style={{ width: "458.15px", height: "648.34px", background: "#fafafa" }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditorLoader;
