import React, { useContext, useEffect, useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, TabPane } from "reactstrap";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import {
  PICTURE,
  QR_CODE,
  SHAPE,
  TEXT,
  TYPE_FORM,
  JOT_FORM,
  UPLOAD,
  VIDEO,
  WHITE_COLOR,
  STOCK,
  BRAND,
  UPLOAD_VIDEO,
  SVG,
} from "../../../constants/editor";
import SliderControll from "../SliderControll";
import { contextualConfig } from "../editor_config";
import ColorBucket from "./ColorBucket";
import {
  calculateNewPositionOnRotatedObjectResize,
  changeBucketColorAccordingBackground,
  changeWidgetColorAccordingBackground,
  getCssTextObjToString,
  getCssTransformObj,
  getRandomString,
  getUpdatedGroupInnerWidgetPos,
  getZoomedValue,
  hexToRGB,
  RGBToHex,
  updateHeightAndTopForInactiveGroupChildren,
} from "../../../_helpers/utils";
import useCollaborativeSelector from "../../../hooks/useCollaborativeSelector";
import useFindHighlighter from "../../../hooks/useFindHighlighter";

let style = Object.assign({}, global);

const Border = props => {
  let { metadata, widgets, backgroundColors, updateWidgets, dimension } = useContext(EditorContext);
  let activeBlockColor;
  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
  let groupWidgetInitHeight = isGroupWidget && parseFloat(isGroupWidget.style.height);
  let targetBackgroundindex = backgroundColors.findIndex(
    backgroundColor => backgroundColor.blockId === metadata.activeBlockId
  );
  let borderForShape =
    metadata.activeWidgetType[0] === SHAPE &&
    document.getElementById(id)?.getAttribute("data-category") !== "Rectangles"
      ? true
      : false;
  if (targetBackgroundindex !== -1)
    activeBlockColor = hexToRGB(backgroundColors[targetBackgroundindex]?.style.backgroundColor);
  let defaultBorderColor =
    metadata.activeWidgetType[0] === SHAPE
      ? contextualConfig.Border.shapeColor
      : RGBToHex(changeWidgetColorAccordingBackground(activeBlockColor));
  let availableBorderStyles =
    metadata.activeWidgetType[0] === SHAPE
      ? ["none", "solid"]
      : ["none", "dotted", "dashed", "solid", "double", "groove", "ridge", "inset", "outset"];

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [borderStyle, setBorderStyle] = useState();
  const [borderWidth, setBorderWidth] = useState();
  const [borderRadious, setBorderRadious] = useState();
  const [borderColor, setBorderColor] = useState();
  const [borderIconBucketColor, setBorderIconBucketColor] = useState();
  const [borderBackgroundColor, setBorderBackgroundColor] = useState("");
  const [borderBackgroundIconBucketColor, setBorderBackgroundIconBucketColor] = useState();
  const [updateContext, setUpdateContext] = useState(false);
  const [loadCurrentFormatting, setLoadCurrentFormatting] = useState(true);
  const [bordereyedropperColor, setBordereyedropperColor] = useState();
  const [borderBackgroundeyedropperColor, setBorderBackgroundeyedropperColor] = useState();
  const [isShownBorderBackgorndColor, setIsShownBorderBackgorndColor] = useState();

  const toggle2 = () => setDropdownOpen(prevState => !prevState);

  const updateCollaborativeWidgetSelectionStyle = useCollaborativeSelector();
  const updateFindHighlighter = useFindHighlighter();

  const getNode = () => {
    let node;
    if (document.querySelector(`#${metadata.activeWidgetId[0]} svg rect`)) {
      node = document.querySelector(`#${metadata.activeWidgetId[0]} svg rect`);
    } else if (document.querySelector(`#${metadata.activeWidgetId[0]} svg circle`)) {
      node = document.querySelector(`#${metadata.activeWidgetId[0]} svg circle`);
    } else if (document.querySelector(`#${metadata.activeWidgetId[0]} svg polygon`)) {
      node = document.querySelector(`#${metadata.activeWidgetId[0]} svg polygon`);
    } else if (document.querySelector(`#${metadata.activeWidgetId[0]} svg path`)) {
      node = document.querySelector(`#${metadata.activeWidgetId[0]} svg path`);
    }
    return node;
  };

  useEffect(() => {
    if (
      borderStyle !== undefined &&
      borderWidth !== undefined &&
      borderRadious !== undefined &&
      borderColor !== undefined &&
      !borderForShape
    ) {
      let newArray;
      let currentElem = document.getElementById(metadata.activeWidgetId[0]);
      let targetElem = [TEXT, QR_CODE, SHAPE].includes(metadata.activeWidgetType[0])
        ? currentElem.querySelector(".dhp-widget-inner").style
        : [TYPE_FORM, JOT_FORM].includes(metadata.activeWidgetType[0])
        ? currentElem.querySelector(".customapp-frame")?.style
        : currentElem.querySelector(".flippable").style;
      let flipableAvailable =
        metadata.activeWidgetType[0] === PICTURE ||
        (metadata.activeWidgetType[0] === VIDEO && [STOCK, BRAND, UPLOAD_VIDEO].includes(currentElem.dataset.source)) ||
        (metadata.activeWidgetType[0] === UPLOAD && currentElem.dataset.fileType !== SVG);

      targetElem.borderStyle = borderStyle;
      targetElem.borderWidth = borderStyle === "none" ? `${0}px` : `${borderWidth}px`;
      targetElem.borderRadius = borderStyle === "none" ? `${0}px` : `${borderRadious}px`;
      targetElem.borderColor = borderStyle === "none" ? defaultBorderColor : borderColor;

      if (!updateContext && flipableAvailable) {
        currentElem.style.willChange = "filter";
        currentElem.querySelector(".dhp-widget-inner").style.willChange = "filter";
      } else {
        currentElem.style.willChange = "";
      }

      if (isGroupWidget) {
        if (metadata.activeWidgetType[0] === TEXT) {
          // Get group updated height and inner widget updated top and height according group
          let { groupDivHeight, parcentHeight, parcentTop } = getUpdatedGroupInnerWidgetPos(
            targetId,
            id,
            document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight
          );

          // update DOM and group handler
          document.getElementById(targetId).style.height = `${groupDivHeight}px`;
          document.getElementById(id).style.height = parcentHeight;
          document.getElementById(id).style.top = parcentTop;
          document.getElementById("dhp-widget-handler").style.height = `${getZoomedValue(
            groupDivHeight,
            dimension.zoom
          )}px`;

          // update height and top for inactive child widgets
          updateHeightAndTopForInactiveGroupChildren({
            group: isGroupWidget,
            groupOldHeight: groupWidgetInitHeight,
            groupNewHeight: groupDivHeight,
            activeChildId: id,
          });

          // create context obj
          newArray = Object.assign([...widgets], {
            [targetWidgetIndex]: {
              ...widgets[targetWidgetIndex],
              style: {
                ...widgets[targetWidgetIndex].style,
                height: `${groupDivHeight}px`,
              },
              innerHTML: document.getElementById(targetId).innerHTML,
            },
          });
        } else {
          // restrict shift when apply border
          if (flipableAvailable) {
            if (borderStyle === "none") {
              document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`).style.left = "";
              document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`).style.top = "";
              document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`).style.position = "";
            } else {
              document.querySelector(
                `#${metadata.activeWidgetId[0]} .dhp-widget-inner`
              ).style.left = `-${borderWidth}px`;
              document.querySelector(
                `#${metadata.activeWidgetId[0]} .dhp-widget-inner`
              ).style.top = `-${borderWidth}px`;
              document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`).style.position = `relative`;
            }
          }

          // create context obj
          newArray = Object.assign([...widgets], {
            [targetWidgetIndex]: {
              ...widgets[targetWidgetIndex],
              innerHTML: document.getElementById(targetId).innerHTML,
            },
          });
        }
      } else {
        const widgetObject = widgets[targetWidgetIndex];
        let widgetTransformStr = widgetObject.style.transform
        // update handler
        if (metadata.activeWidgetType[0] === TEXT) {
          const newHeight = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight;
          const activeWidgetDOM = document.getElementById(metadata.activeWidgetId[0]);
          const {
            translate: { x: widgetTransX, y: widgetTransY },
            rotate: { theta: widgetTheta },
          } = getCssTransformObj({
            transform: widgetObject.style.transform,
          });
          const { left, top } = calculateNewPositionOnRotatedObjectResize(
            parseFloat(widgetTransX),
            parseFloat(widgetTransY),
            parseFloat(widgetObject.style.width),
            parseFloat(newHeight),
            parseFloat(widgetObject.style.width),
            parseFloat(widgetObject.style.height),
            parseFloat(widgetTheta)
          );
           widgetTransformStr = getCssTransformObj({
            translateX: `${left}px`,
            translateY: `${top}px`,
            transform: widgetObject.style.transform,
            returnStr: true,
          });
    
          // set handler
          let handler = document.getElementById("dhp-widget-handler");
          const handlerTransformStr = getCssTransformObj({
            translateX: `${getZoomedValue(left, dimension.zoom)}px`,
            translateY: `${getZoomedValue(top, dimension.zoom)}px`,
            transform: widgetObject.style.transform,
            returnStr: true,
          });
        
          // set handler and widget new height and transform
          activeWidgetDOM.style.height = `${newHeight}px`;
          activeWidgetDOM.style.transform = widgetTransformStr;
          handler.style.height = `${getZoomedValue(newHeight, dimension.zoom)}px`;
          handler.style.transform = handlerTransformStr;
        }

        // restrict shift when apply border
        if (flipableAvailable) {
          if (borderStyle === "none") {
            document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`).style.left = "";
            document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`).style.top = "";
            document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`).style.position = "";
          } else {
            document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`).style.left = `-${borderWidth}px`;
            document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`).style.top = `-${borderWidth}px`;
            document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`).style.position = `relative`;
          }
        }

        newArray = Object.assign([...widgets], {
          [targetWidgetIndex]: {
            ...widgetObject,
            style: {
              ...widgetObject.style,
              height: `${
                metadata.activeWidgetType[0] === TEXT
                  ? document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight
                  : parseFloat(widgetObject.style.height)
              }px`,
              transform: widgetTransformStr
            },
            innerHTML: document.getElementById(targetId).innerHTML,
          },
        });
      }

      if (borderStyle !== "none") props.setIsBorderApplied(true);
      else props.setIsBorderApplied(false);
      updateContext && updateWidgets(newArray);
      borderColor && setBorderIconBucketColor(changeBucketColorAccordingBackground(hexToRGB(borderColor)));
    }
  }, [borderStyle, borderWidth, borderRadious, borderColor, updateContext]);

  useEffect(() => {
    if (borderForShape && borderStyle !== undefined && borderWidth !== undefined && borderColor !== undefined) {
      let newArray, gradNode, gradNodeId;
      let pathId = getRandomString(8);
      let insidePathId = `inside_${getRandomString(8)}`;
      let node = getNode();
      node.setAttribute("id", pathId);
      let newSvgNode = document.createElement("defs");

      if (document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-grad-scolor")) {
        gradNode = document.querySelector(`#${metadata.activeWidgetId[0]} svg linearGradient`);
        gradNodeId = document.querySelector(`#${metadata.activeWidgetId[0]} svg linearGradient`).getAttribute("id");
        node.style.fill = `url(#${gradNodeId})`;
      }

      if (borderStyle === "solid") {
        //If shape has gradient color inside
        if (gradNode) {
          newSvgNode.innerHTML = `${gradNode.outerHTML}${
            node.outerHTML
          } <clipPath id=${insidePathId}><use xlink:href="#${pathId}"></use></clipPath> <use xlink:href="#${pathId}" stroke-width=${
            parseInt(borderWidth) * 5
          } stroke="${borderColor}" fill="none" clip-path="url(#${insidePathId})"></use>`;
        }
        //If shape has no gradient color inside
        else {
          newSvgNode.innerHTML = `${
            node.outerHTML
          } <clipPath id=${insidePathId}><use xlink:href="#${pathId}"></use></clipPath> <use xlink:href="#${pathId}" stroke-width=${
            parseInt(borderWidth) * 5
          } stroke="${borderColor}" fill="none" clip-path="url(#${insidePathId})"></use>`;
        }
      } else {
        //If shape has gradient color inside
        if (gradNode) {
          newSvgNode.innerHTML = `${gradNode.outerHTML}${
            node.outerHTML
          } <clipPath id=${insidePathId}><use xlink:href="#${pathId}"></use></clipPath> <use xlink:href="#${pathId}" stroke-width=${0} stroke="${defaultBorderColor}" fill="none" clip-path="url(#${insidePathId})"></use>`;
        }
        //If shape has no gradient color inside
        else {
          newSvgNode.innerHTML = `${
            node.outerHTML
          } <clipPath id=${insidePathId}><use xlink:href="#${pathId}"></use></clipPath> <use xlink:href="#${pathId}" stroke-width=${0} stroke="${defaultBorderColor}" fill="none" clip-path="url(#${insidePathId})"></use>`;
        }
      }
      document.querySelector(`#${metadata.activeWidgetId[0]} svg`).innerHTML = newSvgNode.innerHTML;

      // create context obj
      if (isGroupWidget) {
        document.getElementById(metadata.activeWidgetId[0]).setAttribute("data-border-style", borderStyle);
        document.getElementById(metadata.activeWidgetId[0]).setAttribute("data-border-width", borderWidth);
        document.getElementById(metadata.activeWidgetId[0]).setAttribute("data-border-color", borderColor);

        newArray = Object.assign([...widgets], {
          [targetWidgetIndex]: {
            ...widgets[targetWidgetIndex],
            innerHTML: document.getElementById(targetId).innerHTML,
          },
        });
      } else {
        newArray = Object.assign([...widgets], {
          [targetWidgetIndex]: {
            ...widgets[targetWidgetIndex],
            data: {
              ...widgets[targetWidgetIndex].data,
              "data-border-style": borderStyle,
              "data-border-width": borderWidth,
              "data-border-color": borderColor,
            },
            innerHTML: document.getElementById(targetId).innerHTML,
          },
        });
      }

      updateContext && updateWidgets(newArray);

      borderColor && setBorderIconBucketColor(changeBucketColorAccordingBackground(hexToRGB(borderColor)));
    }
  }, [borderStyle, borderWidth, borderColor, updateContext]);

  useEffect(() => {
    if (!loadCurrentFormatting) {
      document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style.backgroundColor =
        borderBackgroundColor;

      //Add class for text background
      if (borderBackgroundColor !== "transparent")
        document
          .querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner")
          .classList.add("text-background-active");
      else
        document
          .querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner")
          .classList.remove("text-background-active"); //remove class for text background

      let newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgets[targetWidgetIndex],
          innerHTML: document.getElementById(targetId).innerHTML,
        },
      });

      updateWidgets(newArray);
    }

    if (borderBackgroundColor && borderBackgroundColor !== "transparent") {
      props.setIsBorderApplied(true);
      setBorderBackgroundIconBucketColor(changeBucketColorAccordingBackground(hexToRGB(borderBackgroundColor)));
    } else {
      props.setIsBorderApplied(false);
      setBorderBackgroundIconBucketColor(changeBucketColorAccordingBackground(hexToRGB(WHITE_COLOR)));
    }
  }, [borderBackgroundColor]);

  useEffect(() => {
    updateCollaborativeWidgetSelectionStyle({ zoom: dimension.zoom }); // update collaboration selection
    updateFindHighlighter();
  }, [borderWidth]);

  useEffect(() => {
    if (props.dropdownOpen) {
      let currentElem = document.getElementById(metadata.activeWidgetId[0]);
      let targetElem = [TEXT, QR_CODE, SHAPE].includes(metadata.activeWidgetType[0])
        ? currentElem.querySelector(".dhp-widget-inner").style
        : [TYPE_FORM, JOT_FORM].includes(metadata.activeWidgetType[0])
        ? currentElem.querySelector(".customapp-frame")?.style
        : currentElem.querySelector(".flippable")?.style;

      // do not show border background color if gradient is active
      if (document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-grad-scolor"))
        setIsShownBorderBackgorndColor(false);
      else setIsShownBorderBackgorndColor(true);

      //Load Current Formatting if Border is previously applied
      if (targetElem?.borderStyle && targetElem.borderStyle !== "none") {
        setBorderStyle(targetElem.borderStyle);
        setBorderWidth(parseInt(targetElem.borderWidth));
        setBorderRadious(parseInt(targetElem.borderRadius));
        setBorderColor(RGBToHex(targetElem.borderColor));
      }
      //Load Current Formatting if Border is previously applied for shape
      else if (
        currentElem.getAttribute("data-border-style") &&
        currentElem.getAttribute("data-border-style") !== "none"
      ) {
        setBorderStyle(currentElem.getAttribute("data-border-style"));
        setBorderWidth(parseInt(currentElem.getAttribute("data-border-width")));
        setBorderColor(currentElem.getAttribute("data-border-color"));
      }
      //Load Default Formatting if Border is not previously applied
      else {
        setBorderStyle(contextualConfig.Border.style);
        setBorderWidth(contextualConfig.Border.defaultWidth);
        setBorderRadious(contextualConfig.Border.defaultRadious);
        setBorderColor(defaultBorderColor);
      }

      if (targetElem && targetElem?.backgroundColor !== "transparent")
        setBorderBackgroundColor(RGBToHex(targetElem.backgroundColor));
      else setBorderBackgroundColor(contextualConfig.Border.BackgroundColor);

      setLoadCurrentFormatting(true);
    }
  }, [props.dropdownOpen, metadata.activeWidgetId[0]]);

  //update color if user chose color from eyedropper
  useEffect(() => {
    if (bordereyedropperColor) {
      setUpdateContext(true);
      setBorderColor(bordereyedropperColor);
      setBordereyedropperColor();
    }
  }, [bordereyedropperColor]);

  //update color if user chose color from eyedropper
  useEffect(() => {
    if (borderBackgroundeyedropperColor) {
      setUpdateContext(true);
      setBorderBackgroundColor(borderBackgroundeyedropperColor);
      setBorderBackgroundeyedropperColor();
    }
  }, [borderBackgroundeyedropperColor]);

  return (
    <TabPane tabId={props.idx}>
      <div className={style["line-controls-wrap"]}>
        <div className={style["slidelabel"]}>Border Style</div>
        <Dropdown isOpen={dropdownOpen} toggle={toggle2} className={style["border-style"]}>
          <DropdownToggle className={style["text-capitalize"]}>
            {borderStyle}
            <Icon icon="ui-arrow-down" />
          </DropdownToggle>

          <DropdownMenu className={cx(style["shadow"], style["border-0"], style["rounded"])}>
            {availableBorderStyles.map(availableBorderStyle => (
              <React.Fragment key={availableBorderStyle}>
                <DropdownItem
                  className={cx(style["text-capitalize"], {
                    [style["active"]]: availableBorderStyle === borderStyle,
                  })}
                  tag="a"
                  onClick={() => {
                    setBorderStyle(availableBorderStyle), setUpdateContext(true), setLoadCurrentFormatting(false);
                  }}>
                  <span className={availableBorderStyle}></span>
                  {availableBorderStyle}
                </DropdownItem>
              </React.Fragment>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>

      {borderStyle !== "none" && (
        <>
          <div className="line-controls-wrap">
            <SliderControll
              Slider={borderWidth}
              setSlider={setBorderWidth}
              sliderMin={contextualConfig.Border.minWidth}
              sliderMax={borderForShape ? contextualConfig.Border.maxShapeWidth : contextualConfig.Border.maxWidth}
              sliderLabel={"Border Width"}
              setUpdateContext={setUpdateContext}
              setLoadCurrentFormatting={setLoadCurrentFormatting}
            />
          </div>

          {!borderForShape && (
            <div className="line-controls-wrap">
              <SliderControll
                Slider={borderRadious}
                setSlider={setBorderRadious}
                sliderMin={contextualConfig.Border.minRadious}
                sliderMax={contextualConfig.Border.maxRadious}
                sliderLabel={"Border Radius"}
                setUpdateContext={setUpdateContext}
                setLoadCurrentFormatting={setLoadCurrentFormatting}
              />
            </div>
          )}

          <div className={cx(style["line-controls-wrap"], style["justify-content-start"])}>
            <div className={style["slidelabel"]}>Border Color</div>
            <ColorBucket
              color={borderColor}
              setColor={setBorderColor}
              iconBucketColor={borderIconBucketColor}
              position={"toolbar"}
              setLoadCurrentFormatting={setLoadCurrentFormatting}
              setUpdateContext={setUpdateContext}
              eyedropperColor={bordereyedropperColor}
              setEyedropperColor={setBordereyedropperColor}
            />
          </div>
        </>
      )}

      {![PICTURE, UPLOAD, VIDEO, QR_CODE, TYPE_FORM, JOT_FORM, SHAPE].includes(metadata.activeWidgetType[0]) &&
        isShownBorderBackgorndColor && (
          <div className={cx(style["line-controls-wrap"], style["justify-content-start"])} id="widgetBorderMenu">
            <div className={style["slidelabel"]}>Background</div>
            <ColorBucket
              color={borderBackgroundColor}
              setColor={setBorderBackgroundColor}
              iconBucketColor={borderBackgroundIconBucketColor}
              position={"toolbar"}
              setLoadCurrentFormatting={setLoadCurrentFormatting}
              eyedropperColor={borderBackgroundeyedropperColor}
              setEyedropperColor={setBorderBackgroundeyedropperColor}
            />
          </div>
        )}
    </TabPane>
  );
};
Border.propTypes = {
  dropdownOpen: PropTypes.bool,
  toggle2: PropTypes.func,
  idx: PropTypes.number,
  setIsBorderApplied: PropTypes.func,
};
export default Border;
