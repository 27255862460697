import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { DropdownItem } from "reactstrap";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { GROUP_WIDGET } from "../../../constants/editor";

let style = Object.assign({}, global);

const BringToFront = props => {
  let { metadata, widgets } = useContext(EditorContext);

  const [isDisable, setIsDisable] = useState();
  const [isAllwidgetActive, setIsAllwidgetActive] = useState(true);

  useEffect(() => {
    let target = props.currentBlockWidgetList[props.currentBlockWidgetList.length - 1];
    let selectedId =
      target?.data["data-asset-type"] === GROUP_WIDGET && document.querySelector(`#${target.id} .child-selected`)
        ? document.querySelector(`#${target.id} .child-selected`)?.getAttribute("id")
        : target?.id;

    if (isAllwidgetActive || metadata.activeWidgetId.includes(selectedId)) setIsDisable(true);
    else setIsDisable(false);
  }, [isAllwidgetActive, widgets]);

  useEffect(() => {
    props.currentBlockWidgetList.forEach(element => {
      let selectedId =
        element.data["data-asset-type"] === GROUP_WIDGET && document.querySelector(`#${element.id} .child-selected`)
          ? document.querySelector(`#${element.id} .child-selected`)?.getAttribute("id")
          : element.id;

      if (!metadata.activeWidgetId.includes(selectedId)) {
        setIsAllwidgetActive(false);
        return;
      }
    });
  }, []);

  return (
    <li
      className={cx({
        [style["col"]]: !props.rightContext,
      })}>
      {!props.rightContext && (
        <DropdownItem
          tag="a"
          onClick={() => props.changeWidgetLayer("bringToFront")}
          className={cx({ [style["disabled"]]: isDisable })}>
          <Icon icon="ui-bring-front" />
          Bring to Front
        </DropdownItem>
      )}

      {props.rightContext && (
        <span
          onClick={() => props.changeWidgetLayer("bringToFront")}
          className={cx({ [style["disabled"]]: isDisable })}>
          <Icon icon="ui-bring-front" />
          Bring to Front
        </span>
      )}
    </li>
  );
};

BringToFront.propTypes = {
  changeWidgetLayer: PropTypes.func.isRequired,
  currentBlockWidgetList: PropTypes.array,
  rightContext: PropTypes.bool,
};

export default BringToFront;
