import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSocket } from "./useSocket";
import { THIRDPARTY_TOKEN_STATUS } from "../constants/socket";
import { fetchFacebookAlbums } from "../store/actions/companyActions";

function useUploadFacebook() {
    const socketEndpoint = `${process.env.SOCKET_URL}`;
    const userInfo = useSelector(state => state.auth?.user);
    const { users: allUsers } = useSelector(state => state.company);

    const userEmail = userInfo?.email;
    const companyId = userInfo?.company?.id;
    const userId = allUsers?.find(user => user?.email === userEmail)?.id;
    const dispatch = useDispatch();
    const facebookOauthWindow = useRef(null);
    const isAuthorisedUser = useRef(null);
    const webSocket = useSocket();
    const [showFBUploadModal, setShowFBUploadModal] = useState(false);
    const [facebookError, setFacebookError] = useState();
    const [isTokenActive, setIsTokenActive] = useState(true);
    const [isFacebookAuthenticating, setFacebookAuthenticating] = useState(false);

    const uploadFacebook = {
        errorMessages: {
            unAuthorised: "Sign-in failed. Try again",
            noImage: "No images found"
        },
        handleErrorMessage: (errorType) => {
            if (errorType && errorType in uploadFacebook.errorMessages) {
                setFacebookError(uploadFacebook.errorMessages[errorType]);
                setTimeout(() => {
                    setFacebookError();
                }, 5000)
            }
        },
        start: () => {
            uploadFacebook.checkAccessTokenStatus({ userId, companyId })
        },
        authenticateFacebook: ({ userId, companyId }) => {
            if (facebookOauthWindow.current && !facebookOauthWindow.current.closed) facebookOauthWindow.current.focus();
            else {
                let params = `width=700,height=700,left=400,top=150`;
                /*Added this for to open a browser popup window */
                facebookOauthWindow.current = window.open(`${socketEndpoint}/auth/facebook/${companyId}/${userId}/`, "facebookOauthWindow", params);
                let timer = setInterval(() => {
                    if (facebookOauthWindow.current.closed) {
                        setFacebookAuthenticating(false);
                        clearInterval(timer);
                        if (!isAuthorisedUser?.current) uploadFacebook.handleErrorMessage("unAuthorised");
                    }
                }, 1000);
            }
        },
        checkAccessTokenStatus: ({ userId, companyId }) => {
            if (webSocket?.readyState === 1) {
                setFacebookAuthenticating(true);
                const subscribe = { type: THIRDPARTY_TOKEN_STATUS, companyId, userId, app: "Facebook" };
                webSocket.send(JSON.stringify(subscribe));
            }
        },
        fetchAlbums: () => {
            dispatch(fetchFacebookAlbums()).then((res) => {
                setFacebookAuthenticating(false);
                if (res?.error?.response?.status === 404) {
                    isAuthorisedUser.current = false;
                    uploadFacebook.handleErrorMessage("unAuthorised")
                }
                if (res?.data?.success) {
                    if (res?.data?.data?.albums.length > 0) setShowFBUploadModal(true);
                    else uploadFacebook.handleErrorMessage("noImage")
                }
            })
        }
    }

    const onMessage = useCallback(message => {
        const response = JSON.parse(message?.data);
        if (response.userId === userId && response.companyId === companyId && response.app === "Facebook") {
            if (response.status !== 200 && response.type === THIRDPARTY_TOKEN_STATUS) {
                if (response.status === 401) {
                    isAuthorisedUser.current = false;
                    facebookOauthWindow.current?.close();
                }
                else
                    uploadFacebook.authenticateFacebook({ userId, companyId })
            }
            if (response.status === 200 && response.type === THIRDPARTY_TOKEN_STATUS) {
                isAuthorisedUser.current = true;
                facebookOauthWindow.current?.close();
                uploadFacebook.fetchAlbums();
            }
        }
    }, []);

    useEffect(() => {
        if (webSocket?.readyState === 1) {
            webSocket.addEventListener("message", onMessage);

            return () => {
                webSocket.removeEventListener("message", onMessage);
            };
        }
    }, [webSocket, onMessage]);

    useEffect(() => {
        if (!isTokenActive && showFBUploadModal) {
            setShowFBUploadModal(false);
            uploadFacebook.handleErrorMessage("unAuthorised");
        }
    }, [isTokenActive])


    return { uploadFromFacebook: uploadFacebook.start, showFBUploadModal, setShowFBUploadModal, facebookError, setIsTokenActive, isFacebookAuthenticating }
}

export default useUploadFacebook