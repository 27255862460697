import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import arrow from "../../assets/images/down-arrow.gif";

const CircularProgressbar = ({ progress }) => {
  const progressbarRef = useRef(null);

  useEffect(() => {
    if (progressbarRef.current) {
      var convertedprogress = 250 - (progress * 250) / 100;
      progressbarRef.current.style.strokeDashoffset = `${convertedprogress}%`;

      /* fix for 0% */
      if (!progress || progress === 0) {
        progressbarRef.current.style.strokeDashoffset = "251%";
      }
    }
  }, [progress]);

  return (
    <>
      <div className="svgwrapper">
        <svg viewBox="0 0 100 100">
          <circle
            cx="50%"
            cy="50%"
            r="40%"
            fill="none"
            opacity="1"
            style={{ strokeDashoffset: "0%", stroke: "#cccccc", strokeWidth: "10px" }}
          />
          <circle
            ref={progressbarRef}
            cx="50%"
            cy="50%"
            r="40%"
            fill="none"
            style={{ stroke: "#00a4ef", strokeWidth: "10px" }}
          />
        </svg>
      </div>
      <div className="arrow-gif"><img src={arrow} height={13} /></div>
    </>
  );
};

export default CircularProgressbar;

CircularProgressbar.propTypes = {
  progress: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};
