import { FIND_AND_REPLACE_TOP_PADDING } from "../constants/editor";

const useDragFindAndReplace = () => {
    const dragHelper = {
        meta: false,
        startX: 0,
        startY: 0,
        start: e => {
            if(e.target.closest("#find-and-replace input")) return; // disable drag on input
            dragHelper.startX = e.clientX;
            dragHelper.startY = e.clientY;
            document.addEventListener("mousemove", dragHelper.drag);
            document.addEventListener("mouseup", dragHelper.stop);
        },
        drag: e => {
            if(e.target.closest("#find-and-replace input")) return; // disable drag on input
            const boundary = document.querySelector(".editor-wrapper");
            if (boundary) {
                let target = document.getElementById("find-and-replace");
                const boundingRect = boundary.getBoundingClientRect();
                const offsetX = e.clientX - dragHelper.startX;
                const offsetY = e.clientY - dragHelper.startY;
                let newX = Math.min(Math.max(target.offsetLeft + offsetX, 0), boundingRect.width - target.offsetWidth);
                let newY = Math.min(Math.max(target.offsetTop + offsetY, FIND_AND_REPLACE_TOP_PADDING), boundingRect.height - target.offsetHeight);
                target.style.transition = "";
                target.style.left = `${newX}px`;
                target.style.top = `${newY}px`;
                dragHelper.startX = e.clientX;
                dragHelper.startY = e.clientY;
            }
        },
        stop: () => {
            document.removeEventListener("mousemove", dragHelper.drag);
            document.removeEventListener("mouseup", dragHelper.stop);
        }
    };

    return { dragModal: dragHelper.start }
}

export default useDragFindAndReplace