import { ICON, ILLUSTRATION, STICKER, TEXT_FRAME } from "../constants/editor";
import { RGBToHex } from "../_helpers/utils";

const useSetSvgColorOnWidgetInsertion = () => {
  //Update multicolor widget's svg innerhtml with data attribute for different color path after insertion
  const setSvgColorOnWidgetInsertion = (assetType, svgContent) => {
    if ([ILLUSTRATION, STICKER, ICON, TEXT_FRAME].includes(assetType)) {
      svgContent.querySelectorAll(`svg *`).forEach(node => {
        if (
          node.nodeName === "defs" ||
          node.parentNode.nodeName === "defs" ||
          node.parentNode.nodeName === "linearGradient"
        )
          return false;

        if (node.nodeName !== "g" && node.style && RGBToHex(node.style.fill) !== "") {
          const currentIconColor = RGBToHex(node.style.fill);
          node.dataset.class = `${currentIconColor}body`;
        }
      });
    }
    return svgContent;
  };

  // Update text frame widget all contenteditable div to false
  const setContentEditable = (assetType, svgContent) => {
    if ([TEXT_FRAME].includes(assetType)) {
      svgContent.querySelectorAll(`svg .textwrap foreignObject div > div`).forEach(node => {
        node.setAttribute("contenteditable", false);
      });
    }
    return svgContent;
  };

  return { setSvgColorOnWidgetInsertion, setContentEditable };
};

export default useSetSvgColorOnWidgetInsertion;
