import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import PropTypes from "prop-types";
import global from "../../scss/dhp.scss";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { EditorContext } from "../../containers/editor/EditorLayout";
import { getUserList } from "../../store/actions/companyActions";
import Avatar from "../../assets/images/ui-user.svg";

const style = Object.assign({}, global);

const CollaborativeUsers = () => {
  const dispatch = useDispatch();
  let { socketState } = useContext(EditorContext);
  const currentUserId = useSelector(state => state?.auth?.uid);
  const companyUsers = useSelector(state => state?.company?.users);
  const [activeUsers, setActiveUsers] = useState([]);
  const [newUserAdded, setNewUserAdded] = useState(false);
  
  useEffect(() => {
    if (socketState?.activeUsers && companyUsers) {
      let activeUserIds = [];
      for (let key in socketState?.activeUsers) {
        if (key !== "undefined" && socketState?.currentUser !== key && socketState?.activeUsers[key]?.userId !== currentUserId && socketState?.activeUsers[key]?.color)
          activeUserIds.push(socketState?.activeUsers[key]?.userId);
      }
      activeUserIds = [...new Set(activeUserIds)];
      let activeUserDetails = [];
      activeUserIds?.forEach(uId => {
        let { email, firstname, lastname, id, image } = companyUsers.find(rec => rec.id === uId) ?? { email: null, firstname: null, lastname: null, id: null, image: null };
        if (!email && !id) setNewUserAdded(true)
        activeUserDetails.push({
          email,
          firstname,
          lastname,
          id,
          image: image ?? Avatar,
        });
      });
      setActiveUsers(activeUserDetails);
    } else {
      setActiveUsers([]);
    }
  }, [socketState?.activeUsers, companyUsers, currentUserId]);

  useEffect(() => {
    if (newUserAdded) dispatch(getUserList());
  }, [newUserAdded])

  useEffect(() => {
    if (!companyUsers) dispatch(getUserList());
  }, [])

  return (
    <>
      {activeUsers.length > 0 && (
        <UncontrolledDropdown tag="li" className={style["collborative-area"]}>
          <DropdownToggle tag="a" className={cx(style["nav-link"], style["p-0"])} caret>
            <UsersThumb activeUsers={activeUsers} />
          </DropdownToggle>
          <DropdownMenu className={cx(style["shadow"], style["border-0"], style["rounded-sm"])}>
            {activeUsers?.map((userInfo, i) => (
              <DropdownItem key={i} tag={"div"} className={style["py-2"]}>
                <div className={style["d-flex"]}>
                  <div className={cx(style["thumbnail-img"], style["rounded-circle"], style["user-img"], style["mr-3"])}>
                    <img
                      src={userInfo.image}
                      className={cx({ [style["default"]]: userInfo.image === Avatar })}
                      alt="Option image"
                    />
                  </div>
                  <div className={style["user-info"]}>
                    <h6 className={cx(style["m-0"], style["text-truncate"], style["font-15"])}>
                      {userInfo?.firstname} {userInfo?.lastname}
                    </h6>
                    <div className={cx(style["text-muted"], style["text-truncate"], style["font-13"])}>
                      {userInfo?.email}
                    </div>
                  </div>
                </div>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </>
  );
};
const UsersThumb = ({ activeUsers }) => {
  return (
    <>
      <span className={cx(style["rounded-circle"], style["user-img"])}>
        <img src={activeUsers[0]?.image} className={cx({ [style["default"]]: activeUsers[0]?.image === Avatar })} />
      </span>
      {activeUsers?.length > 1 && <div>+{activeUsers?.length - 1}</div>}
    </>
  );
};
UsersThumb.propTypes = {
  activeUsers: PropTypes.array,
};
export default CollaborativeUsers;