import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import Modal from "../../ui/modal";
import PictureFilterModal from "../Modals/PictureFilterModal";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { COLLAGE, VIDEO } from "../../../constants/editor";

let style = Object.assign({}, global);

const Filter = props => {
  let { metadata, changeBySocket, widgets } = useContext(EditorContext);

  const [showModal, setShowModal] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState();

  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);

  const fetchFilterFromActiveWidget = () => {
    if (metadata.activeWidgetId) {
      let curDOMElement = document.getElementById(`${metadata.activeWidgetId[0]}`);
      let curDataSource = curDOMElement.getAttribute("data-source");
      let curInnerWidget =
        ["STOCK", "BRAND", "UPLOADVIDEO"].includes(curDataSource) && metadata.activeWidgetType[0] === VIDEO
          ? curDOMElement.getElementsByTagName("video")[0]
          : metadata.activeWidgetType[0] === COLLAGE
          ? curDOMElement.getElementsByClassName("collage")[0]
          : curDOMElement.getElementsByTagName("img")[0];
      let filterApllied = curInnerWidget?.getAttribute("data-filter");

      if (
        filterApllied &&
        (filterApllied !== "normal" || curInnerWidget.getAttribute("data-filter-type") !== "default")
      )
        setIsFilterApplied(true);
      else setIsFilterApplied(false);
    }
  };

  // check filter is applied or not on active widget change
  useEffect(() => {
    fetchFilterFromActiveWidget();
  }, [metadata.activeWidgetId[0], showModal]);

  //check filter is applied or not for collaboration
  useEffect(() => {
    if (changeBySocket && widgets[targetWidgetIndex]?.innerHTML) fetchFilterFromActiveWidget();
  }, [changeBySocket, widgets[targetWidgetIndex]?.innerHTML]);

  return (
    <li
      className={cx(style["toolset-group"], {
        [style["active"]]: isFilterApplied,
      })}>
      <div className={cx(style["toolset-item"], style["filter-wrap"])}>
        <span className={cx(style["toolset-action"], style["custom-tooltip"])} onClick={() => setShowModal(!showModal)}>
          <Icon icon="ui-fantasy2" />
          {props.rightContext && "Filter"}
          {!props.rightContext && <div className={cx(style["custom-tooltip-content"], style["top"])}>Filter</div>}
        </span>
        {showModal && (
          <Modal
            component={PictureFilterModal}
            backdrop="static"
            showModal={showModal}
            setShowModal={setShowModal}
            modalClassName={"pictureFilter-Modal"}
          />
        )}
      </div>
    </li>
  );
};

Filter.propTypes = {
  rightContext: PropTypes.bool,
};

export default Filter;
