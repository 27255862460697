import React, { useContext, useEffect, useState, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Button, Form, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import cx from "classnames";
import { COMPANY_SUPERADMIN } from "../../../constants/company";
import { getUserInfo } from "../../../store/actions/authActions";
import { preventFormSubmitOnEnter as preventSubmit } from "../../../_helpers/utils";
import { documentInfo } from "../_utils";
import { Input } from "../../ui/input";
import { Icon } from "../../ui/icon";
import { ProcessLoader } from "../../ui/loader/processLoader";
import { useCheckCompanyPlanInfo } from "../../../hooks/useCheckCompanyPlanInfo";
import { useContextualUpgrade } from "../../../hooks/useContextualUpgrade";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import Crown from "../../../assets/images/ui-crown.svg";
import {
  DOCUMENT_PUBLISH_SUCCESS,
  DOCUMENT_PUBLISH_FAILED,
  DOCUMENT_PUBLISH_SET_PASSWORD_SUCCESS,
  DOCUMENT_PUBLISH_SET_PASSWORD_FAILED,
  DOCUMENT_PUBLISH_UNSET_PASSWORD_SUCCESS,
  DOCUMENT_PUBLISH_UNSET_PASSWORD_FAILED,
  DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_FAILED,
  DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_SUCCESS,
  SEND_DOCUMENT_EMAIL_SUCCESS,
  FETCH_SENT_EMAIL_LIST_SUCCESS,
  DOCUMENT_PUBLISH_HANDLE_BADGE_SUCCESS,
  DOCUMENT_PUBLISH_HANDLE_BADGE_FAILED,
  DOCUMENT_PUBLISH_EMAIL_VERIFICATION_SUCCESS,
  DOCUMENT_PUBLISH_EMAIL_VERIFICATION_FAILED,
} from "../../../store/actions/actionTypes";
import {
  fetchDocumentDetails,
  publishDocument,
  publishDocumentEmailVerification,
  publishDocumentHandleBadge,
  publishDocumentHandleDownload,
  publishDocumentSetPassword,
  publishDocumentUnsetPassword,
  sendDocumentEmail,
  fetchSentEmailList,
} from "../../../store/actions/documentActions";
import { FlashAlert } from "../../ui/flashAlert";
import { useSocket } from "../../../hooks/useSocket";
import { PUBLISH_DOCUMENT, PUBLISH_DOCUMENT_DOWNLOAD, PUBLISH_DOCUMENT_PASSWORD } from "../../../constants/socket";
import { DotLoader } from "../../ui/loader/dotLoader";
import global from "../../../scss/dhp.scss";
import { DataFormatter } from "../../ui/dataFormatter";
import TabSchemeSelector from "../../Common/TabSchemeSelector";
import { getCompanyInfo } from "../../../store/actions/companyActions";

let style = Object.assign({}, global);

const EmailModal = ({ document: data, _helpers }) => {
  const suggestionBoxWidth = 258;
  const unPaidEmailRestriction = 1;
  const paidEmailRestriction = 10;
  const fetch = {
    page: 1,
    limit: 25,
  };
  const contextData = useContext(EditorContext);
  let { pageNodes, blockNodes, backgroundColors, backgroundImages, widgets, dimension, documentType } =
    useContext(EditorContext);
  const webSocket = useSocket();
  const dispatch = useDispatch();
  const showUpgrade = useContextualUpgrade();
  const paidCompanyInfo = useCheckCompanyPlanInfo(true);
  const modifiedData = {...data.publish, publish_id: data?.publish?.id, id: data?.id};
  const companyData = useSelector(state => state?.auth?.company);

  const alertData = {
    show: false,
    type: "",
  };

  const processEndTypes = {
    publish: [DOCUMENT_PUBLISH_SUCCESS, DOCUMENT_PUBLISH_FAILED],
    set_password: [DOCUMENT_PUBLISH_SET_PASSWORD_SUCCESS, DOCUMENT_PUBLISH_SET_PASSWORD_FAILED],
    unset_password: [DOCUMENT_PUBLISH_UNSET_PASSWORD_SUCCESS, DOCUMENT_PUBLISH_UNSET_PASSWORD_FAILED],
    download: [DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_SUCCESS, DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_FAILED],
    badge: [DOCUMENT_PUBLISH_HANDLE_BADGE_SUCCESS, DOCUMENT_PUBLISH_HANDLE_BADGE_FAILED],
    email_verification: [DOCUMENT_PUBLISH_EMAIL_VERIFICATION_SUCCESS, DOCUMENT_PUBLISH_EMAIL_VERIFICATION_FAILED],
  };
  const errorTypes = [
    DOCUMENT_PUBLISH_FAILED,
    DOCUMENT_PUBLISH_SET_PASSWORD_FAILED,
    DOCUMENT_PUBLISH_UNSET_PASSWORD_FAILED,
    DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_FAILED,
    DOCUMENT_PUBLISH_HANDLE_BADGE_FAILED,
    DOCUMENT_PUBLISH_EMAIL_VERIFICATION_FAILED,
  ];

  const { exists: isPublished, data: publishId } = documentInfo.get(modifiedData, "publish_id");
  const { exists: isPasswordEnabled } = documentInfo.get(modifiedData, "password");
  const { data: isDownloadEnabled } = documentInfo.get(modifiedData, "download");
  const { data: isBadgeEnabled } = documentInfo.get({...companyData, id: data?.id}, "hide_badge");
  const { exists: isEmailVerificationEnabled, data: isEmailVerificationData } = documentInfo.get(modifiedData, "email_verification");

  const initialState = {
    email: {
      payload: {
        toRecipients: [],
        emailSubject: "",
        publishLink: "",
      },
    },
    sentEmail: {},
    tab: "email",
    published: isPublished,
    download_enabled: isDownloadEnabled,
    password_enabled: isPasswordEnabled,
    publish_id: publishId,
    toggle_publish: isPublished,
    toggle_set_password: isPasswordEnabled,
    dochipo_badge_enabled: isBadgeEnabled,
    email_verification_enabled: isEmailVerificationEnabled,
    toggle_email_verification: isEmailVerificationEnabled,
    password: {
      value: "",
      validation: {
        required: true,
        min_length: 6,
        max_length: 25,
      },
      placeholder: isPasswordEnabled ? "******" : "Minimum 6 characters",
      touched: false,
      valid: false,
      error: "",
    },
    set_password_button_label: isPasswordEnabled ? "Change" : "Save",
    email_verification: {
      value: isEmailVerificationData?.value || "",
      displayValue: (isEmailVerificationData && isEmailVerificationData?.value) || "",
      validation: {
        required: true,
      },
      verification_type_dropdown: {
        selected: {
          name: isEmailVerificationData ? isEmailVerificationData.key : "Any Email",
          value: isEmailVerificationData ? isEmailVerificationData.key : "Any Email",
        },
      },
      placeholder: "neel@gmail.com, sam@gmail.com",
      email_domain_placeholder: "example.com, domain.com",
      valid: false,
    },
  };

  const publishSchemes = ["General", "Other Settings"];
  const verificationTypes = {
    ["AE"]: "Any Email",
    ["SE"]: "Specific Email",
    ["ED"]: "Email Domain",
  };

  const [state, setState] = useState(initialState);
  const [isMounted, setIsMounted] = useState(false);
  const [alert, setAlert] = useState(alertData);
  const [disable, setDisable] = useState({
    share: false,
    shared_with: false,
    publish: false,
    set_download: false,
    unset_download: false,
    set_password: false,
    unset_password: false,
    set_badge_enable: false,
    unset_badge_enable: false,
  });
  const [step, setStep] = useState(1);
  const [isPasswordSuccessResponse, setIsPasswordSuccessResponse] = useState("");
  const [isUserDocumentOwner, setIsUserDocumentOwner] = useState(false);
  const [totalEmailCount, setTotalEmailCount] = useState({
    threeRecipients: false,
    tenRecipients: false,
    notifyUser: false,
    showMessage: false,
  });
  const [emailSentCount, setEmailCount] = useState(0);
  const [sentEmailRestriction, setSentEmailRestriction] = useState({
    oneEmailPerDay: false,
    tenEmailPerDay: false,
    emailRestrictionMessage: false,
  });
  const [sentEmailRestrictionCount, setSentEmailRestrictionCount] = useState(0);
  const [emailSendError, setEmailSendError] = useState(false);
  const [sentEmailFirstTime, setSentEmailFirstTime] = useState(false);
  const [disabledSendButton, setDisabledSendButton] = useState(false);
  const [delayedSentEmail, setDelayedSentEmail] = useState(false);
  const [nextClickDisable, setNextClickDisable] = useState(false);
  const [schemeType, setSchemeType] = useState("General");
  const activeTabIndex = publishSchemes.indexOf(schemeType);
  const [emailInput, setEmailInput] = useState();
  const [isEmailMounted, setIsEmailMounted] = useState();
  const [disableEmail, setDisableEmail] = useState({
    set_email_verification: false,
    unset_email_verification: false,
  });

  const companyId = useSelector(state => state?.auth?.user?.company?.id);
  const userInfo = useSelector(state => state?.auth?.user);
  const { type, publish_id, documentDetails, lodingEmailList, loadingEmailSend } = useSelector(
    state => state?.document
  );
  const time_format = useSelector(state => state?.auth?.user?.time_format);

  const handleBack = () => {
    if (emailSendError) setEmailSendError(false);
    setNextClickDisable(false);
    setStep(1);
  };

  const handleEmailCount = state => setTotalEmailCount({ ...totalEmailCount, showMessage: state });

  const handleSentEmailCount = (total_count, sent_EmailCount) => {
    setEmailCount(total_count);
    setSentEmailRestrictionCount(sent_EmailCount);

    if (paidCompanyInfo?.isPaid && !paidCompanyInfo?.isLoading) {
      const validateEmailRestriction = sent_EmailCount >= paidEmailRestriction;
      setSentEmailRestriction({
        oneEmailPerDay: false,
        tenEmailPerDay: validateEmailRestriction,
        emailRestrictionMessage: validateEmailRestriction,
      });
    } else if (!paidCompanyInfo?.isPaid && !paidCompanyInfo?.isLoading) {
      const validateEmailRestriction = sent_EmailCount >= unPaidEmailRestriction;
      setSentEmailRestriction({
        oneEmailPerDay: validateEmailRestriction,
        tenEmailPerDay: false,
        emailRestrictionMessage: validateEmailRestriction,
      });
    }
  };

  const handleEmailSend = () => {
    setDisabledSendButton(true);
    const documentData = documentDetails?.data?.owner;
    const emailPayload = state.email.payload;
    const payload = {
      sender_name: userInfo?.name,
      sender_email: userInfo?.email,
      to: emailPayload.toRecipients,
      subject: emailPayload.emailSubject,
      publish_url: emailPayload.publishLink,
      body: `${userInfo?.name} has sent you the following document.`,
    };

    dispatch(sendDocumentEmail(documentDetails?.data?.id, payload)).then(r => {
      if (r.type === SEND_DOCUMENT_EMAIL_SUCCESS) {
        setDelayedSentEmail(true);
        setTimeout(() => {
          if (emailSentCount === 0) setSentEmailFirstTime(true);

          if (localStorage.getItem("emailCount")) {
            const count = parseInt(localStorage.getItem("emailCount"));
            localStorage.setItem("emailCount", JSON.stringify(count + 1));
          } else localStorage.setItem("emailCount", JSON.stringify(sentEmailRestrictionCount + 1));

          dispatch(fetchSentEmailList(documentDetails?.data?.id, fetch)).then(r => {
            if (r.type === FETCH_SENT_EMAIL_LIST_SUCCESS) updateState({ field: "tab", value: "sentEmail" });
          });
          setDelayedSentEmail(false);
          let stateObj = { ...state };
          stateObj.email.payload.toRecipients = [];
          setState(stateObj);
        }, 5000);
      } else setEmailSendError(true);
    });
  };

  const handleUpgrade = () => setTotalEmailCount({ ...totalEmailCount, clickedUpgrade: true });

  const upgradeToTenRecipients = () => {
    if (paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && !paidCompanyInfo?.isPaid) {
      // Call showUpgrade function to show contexttual upgrade modal based on your condition
      showUpgrade("addTenRecipientsDocumentLink", {
        docPagesMeta: { pageNodes, blockNodes, backgroundColors, backgroundImages, widgets, dimension, documentType },
      });
    }
  };

  const upgradeToTenEmails = () => {
    if (paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && !paidCompanyInfo?.isPaid) {
      // Call showUpgrade function to show contexttual upgrade modal based on your condition
      showUpgrade("sendTenEmailDocumentLink", {
        docPagesMeta: { pageNodes, blockNodes, backgroundColors, backgroundImages, widgets, dimension, documentType },
      });
    }
  };

  const toggleAlert = () => setAlert({ ...alert, show: !alert.show });

  const generatePublishCode = () => {
    const publishUrl = `${process.env.BASE_URL}/pub/${userInfo?.company?.id}/documents/${state.publish_id}`;

    // Update the publishLink in the state
    let stateObj = { ...state };
    stateObj.email.payload.publishLink = publishUrl;
    setState(stateObj);
  };

  const handlePublishButtonClick = () => {
    if (!state.published && isUserDocumentOwner) {
      let stateObj = { ...state };

      stateObj.toggle_publish = true;
      setState(stateObj);
      setIsMounted(true);
      setNextClickDisable(true);
      dispatch(publishDocument([data.id])).then(r => {
        if (r.type === DOCUMENT_PUBLISH_SUCCESS) {
          setDisable({ ...disable, publish: true, unset_download: false, set_download: false });
          setStep(2);
        }
      });
    } else {
      const emailCount = parseInt(localStorage.getItem("emailCount"));

      if (!paidCompanyInfo?.isLoading) {
        let sentEmailRestrictionUpdated = {
          oneEmailPerDay: false,
          tenEmailPerDay: false,
          emailRestrictionMessage: false,
        };

        if (
          !paidCompanyInfo?.isPaid &&
          (sentEmailRestrictionCount >= unPaidEmailRestriction || emailCount >= unPaidEmailRestriction)
        )
          sentEmailRestrictionUpdated = {
            ...sentEmailRestrictionUpdated,
            oneEmailPerDay: true,
            emailRestrictionMessage: true,
          };
        else if (
          paidCompanyInfo?.isPaid &&
          (sentEmailRestrictionCount >= paidEmailRestriction || emailCount >= paidEmailRestriction)
        )
          sentEmailRestrictionUpdated = {
            ...sentEmailRestrictionUpdated,
            tenEmailPerDay: true,
            emailRestrictionMessage: true,
          };
        else setStep(2);

        setSentEmailRestriction(sentEmailRestrictionUpdated);
      }
    }
  };

  const handlePreviewClick = e => {
    e.preventDefault();
    window.open(state.email.payload.publishLink, "_blank");
  };

  // Function to convert email array to input format
  const convertArrayToInputFormat = emailArray => {
    return emailArray?.join(", ");
  };

  // Function to handle verification type
  const handleVerificationTypeClick = type => {
    if (verificationTypes[type] !== state.email_verification.verification_type_dropdown.selected.value) {
      updateState({ field: "email_type_dropdown", value: verificationTypes[type] });
    }
    //To retain input value between switch the type
    if (verificationTypes[type] === isEmailVerificationData?.key && isEmailVerificationData?.value) {
      const inputVal = convertArrayToInputFormat(isEmailVerificationData?.value);
      setEmailInput(inputVal);
    } else {
      setEmailInput("");
    }
  };

  const updateState = ({ field, value, type, e }) => {
    let stateObj = { ...state };

    if (["toRecipients", "emailSubject"].includes(field)) {
      stateObj.email.payload[field] = value;
      setState(stateObj);
    }

    if (field === "toggle_allow_download") {
      if (paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && !paidCompanyInfo?.isPaid) {
        // Call showUpgrade function to show contextual upgrade modal based on your condition
        let docPagesMeta =
          contextData && Object.keys(contextData).length > 0
            ? { ...contextData }
            : { ...documentDetails?.data?.version?.rawdata?.contexts };
        showUpgrade("publishDownload", {
          docPagesMeta:
            docPagesMeta && Object.keys(docPagesMeta).length > 0
              ? docPagesMeta
              : { thumb: documentDetails?.data?.thumb },
        });
        e.target.checked = stateObj.download_enabled;
        return;
      }

      setIsMounted(true);
      dispatch(publishDocumentHandleDownload({ documentId: data.id, companyId, download: e.target.checked }));
      setDisable({ ...disable, unset_download: !e.target.checked, set_download: e.target.checked });
      e.target.checked = stateObj.download_enabled;
    }

    if (field === "toggle_set_password") {
      if (paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && !paidCompanyInfo?.isPaid) {
        // Call showUpgrade function to show contextual upgrade modal based on your condition
        showUpgrade("publishPassword");
        // stop toggling
        e.target.checked = stateObj.password_enabled;
        return;
      }
      if (stateObj.password_enabled) {
        e.target.checked = true;
        setIsMounted(true);
        dispatch(publishDocumentUnsetPassword([data.id]));
        setDisable({ ...disable, unset_password: true });
      }
      if (!stateObj.password_enabled) {
        stateObj.toggle_set_password = e.target.checked;
        if (!e.target.checked) {
          stateObj.password.value = "";
          stateObj.password.touched = false;
          stateObj.password.valid = false;
          stateObj.password.error = "";
        }
      }
      setState(stateObj);
    }

    if (field === "password") {
      let password = e?.target?.value ?? value;
      if (type === "change") {
        stateObj.password.value = password;
        stateObj.password.valid =
          password.length >= stateObj.password.validation.min_length &&
          password.length <= stateObj.password.validation.max_length;
        if (stateObj.password.valid) stateObj.password.error = "";
        if (!stateObj.password.valid && stateObj.password.touched)
          updateState({ field: "password", type: "blur", value: password });
      }
      if (type === "blur") {
        stateObj.password.touched = true;
        if (password.length === 0) stateObj.password.error = "Please enter a password";
        if (password.length > 0 && password.length < stateObj.password.validation.min_length)
          stateObj.password.error = `Password must be minimum ${stateObj.password.validation.min_length} characters`;
      }
      setState(stateObj);
    }

    if (field === "set_password") {
      if (stateObj.password.valid) {
        e.preventDefault();
        setIsMounted(true);
        let payload = {
          documentId: data.id,
          password: stateObj.password.value,
        };
        dispatch(publishDocumentSetPassword(payload));
        setDisable({ ...disable, set_password: true });
      }
    }

    if (field === "tab" && stateObj.tab !== value) {
      stateObj.tab = value;
      setState(stateObj);
    }

    if (field === "toggle_badge_enable") {
      if (paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && !paidCompanyInfo?.isPaid) {
        // Call showUpgrade function to show contextual upgrade modal based on your condition
        let docPagesMeta =
          contextData && Object.keys(contextData).length > 0
            ? { ...contextData }
            : { ...documentDetails?.data?.version?.rawdata?.contexts };
        showUpgrade("publishBadge", {
          docPagesMeta:
            docPagesMeta && Object.keys(docPagesMeta).length > 0
              ? docPagesMeta
              : { thumb: documentDetails?.data?.thumb },
        });
        // stop toggling
        e.target.checked = stateObj.dochipo_badge_enabled;
        return;
      }
      setIsMounted(true);
      dispatch(publishDocumentHandleBadge({ documentId: data.id, hideBadge: e.target.checked }));
      setDisable({ ...disable, set_badge_enable: e.target.checked, unset_badge_enable: !e.target.checked });
      e.target.checked = stateObj.dochipo_badge_enabled;
    }
    if (field === "toggle_set_email_verification") {
      if (paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && !paidCompanyInfo?.isPaid) {
        // Call showUpgrade function to show contextual upgrade modal based on your condition
        showUpgrade("publishEmailVerification");
        // stop toggling
        e.target.checked = stateObj.email_verification_enabled;
        return;
      }
      if (stateObj.email_verification_enabled) {
        e.target.checked = stateObj.toggle_email_verification;
        setIsEmailMounted(true);
        dispatch(publishDocumentEmailVerification({ documentId: data.id, verification: false }));
        setDisableEmail({ ...disableEmail, unset_email_verification: true, set_email_verification: false });
      }
      if (!stateObj.email_verification_enabled) {
        e.target.checked = stateObj.toggle_email_verification;
        setIsEmailMounted(true);
        let payload = { key: "Any Email" };
        dispatch(
          publishDocumentEmailVerification({ payLoad: payload, documentId: data.id, verification: true })
        );
        setDisableEmail({ ...disableEmail, set_email_verification: true, unset_email_verification: false });
      }
      setState(stateObj);
    }
    if (field === "set_email_verification") {
      if (stateObj.email_verification.valid) {
        e.preventDefault();
        setIsEmailMounted(true);
        let payload = {
          key: stateObj.email_verification.verification_type_dropdown.selected.value,
          value: stateObj.email_verification.value,
        };
        dispatch(publishDocumentEmailVerification({ payLoad: payload, documentId: data.id, verification: true }));
        setDisableEmail({ ...disableEmail, set_email_verification: true, unset_email_verification: false });
      }
    }
    if (field === "verify_email") {
      let emails = e?.target?.value ?? value;
      setEmailInput(emails);
      const emailArray = emails.split(",").map(email => email.trim());
      stateObj.email_verification.value = emailArray;
      
      // to validate email
      const validateEmail = email => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        return emailPattern.test(email);
      };

      // to validate email domain
      const validateEmailDomain = email => {
        const domainPattern = /^[a-zA-Z][a-zA-Z0-9-]*(\.[a-zA-Z]{2,})+$/;
        return domainPattern.test(email);
      };

      let allValid;

      if (stateObj.email_verification.verification_type_dropdown.selected.value === "Specific Email") {
        // Validate all emails with specific email validation
        allValid = emailArray.every(email => validateEmail(email));
      } else if (stateObj.email_verification.verification_type_dropdown.selected.value === "Email Domain") {
        // Validate all emails' domains
        allValid = emailArray.every(email => validateEmailDomain(email));
      }

      //Check if both list are equal
      const areArraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
        return arr1.sort().every((value, index) => value === arr2.sort()[index]);
      };
      // Check for duplicates within the inputArray itself
      const hasDuplicatesInInput = new Set(emailArray).size !== emailArray.length;
      const storedEmailArray = isEmailVerificationData?.value || [];  // Existing stored emails
      //Check if duplicate or equal
      const isDuplicateOrEqual = areArraysEqual(emailArray, storedEmailArray) || hasDuplicatesInInput;
      // Make the validity true or false
      if (allValid && !isDuplicateOrEqual) {
        stateObj.email_verification.valid = true;
      } else {
        stateObj.email_verification.valid = false;
      }
      setState(stateObj);
    }
    if (field === "email_type_dropdown") {
      stateObj.email_verification.verification_type_dropdown.selected = { name: value, value: value };
      setState(stateObj);
      if (value === "Any Email" && isEmailVerificationData?.key !== value) {
        setIsEmailMounted(true);
        let payload = { key: "Any Email" };
        dispatch(publishDocumentEmailVerification({ payLoad: payload, documentId: data.id, verification: true }));
        setDisableEmail({ ...disableEmail, set_email_verification: true });
      }
    }
  };

  const postApiCallActions = () => {
    let stateObj = { ...state };

    if (type === DOCUMENT_PUBLISH_SUCCESS) {
      setIsMounted(false);
      documentInfo.set(data, { publish_id: publish_id });
      stateObj.published = true;
      stateObj.publish_id = publish_id;
      stateObj.toggle_publish = true;
      setState(stateObj);
      // check is web socket is in ready state
      if (webSocket?.readyState === 1) {
        const subscribe = { type: PUBLISH_DOCUMENT, data, publish_id: publish_id };
        webSocket.send(JSON.stringify(subscribe));
      }
    }

    if (type === DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_SUCCESS) {
      setIsMounted(false);
      documentInfo.set(data, { download: disable.set_download });
      document.getElementById("document-publish-link-toggle-allow-download").checked = disable.set_download;
      setDisable({ ...disable, set_download: false, unset_download: false });

      stateObj.download_enabled = disable.set_download;
      setState(stateObj);
      // check is web socket is in ready state
      if (webSocket?.readyState === 1) {
        const subscribe = { type: PUBLISH_DOCUMENT_DOWNLOAD, data, download: disable.set_download };
        webSocket.send(JSON.stringify(subscribe));
      }
    }

    if (type === DOCUMENT_PUBLISH_SET_PASSWORD_SUCCESS) {
      setIsMounted(false);
      documentInfo.set(data, { password: true });
      document.getElementById("document-publish-password").value = "";
      stateObj.password_enabled = true;
      stateObj.toggle_set_password = true;
      stateObj.password.value = "";
      stateObj.password.placeholder = "******";
      stateObj.password.touched = false;
      stateObj.password.valid = false;
      stateObj.password.error = "";
      stateObj.set_password_button_label = "Change";
      setState(stateObj);
      setIsPasswordSuccessResponse("Your password has been set");
      setTimeout(() => {
        setIsPasswordSuccessResponse("");
      }, 3000);
      setDisable({ ...disable, set_password: false });
      // check is web socket is in ready state
      if (webSocket?.readyState === 1) {
        const subscribe = { type: PUBLISH_DOCUMENT_PASSWORD, data, password: true };
        webSocket.send(JSON.stringify(subscribe));
      }
    }

    if (type === DOCUMENT_PUBLISH_UNSET_PASSWORD_SUCCESS) {
      setIsMounted(false);
      documentInfo.set(data, { password: null });
      document.getElementById("document-publish-link-toggle-setpassword").checked = false;
      stateObj.password_enabled = false;
      stateObj.toggle_set_password = false;
      stateObj.password.value = "";
      stateObj.password.placeholder = `Minimum ${stateObj.password.validation.min_length} characters`;
      stateObj.password.touched = false;
      stateObj.password.valid = false;
      stateObj.password.error = "";
      stateObj.set_password_button_label = "Save";
      setState(stateObj);
      setDisable({ ...disable, unset_password: false });
      // check is web socket is in ready state
      if (webSocket?.readyState === 1) {
        const subscribe = { type: PUBLISH_DOCUMENT_PASSWORD, data, password: null };
        webSocket.send(JSON.stringify(subscribe));
      }
    }
    if (type === DOCUMENT_PUBLISH_HANDLE_BADGE_SUCCESS) {
      setIsMounted(false);
      documentInfo.set(data, { hide_badge: disable.set_badge_enable });
      document.getElementById("document-toggle-dochipo-badge").checked = disable.set_badge_enable;
      setDisable({ ...disable, set_badge_enable: false, unset_badge_enable: false });
      stateObj.dochipo_badge_enabled = disable.set_badge_enable;
      setState(stateObj);
      // To Update in company setting
      dispatch(getCompanyInfo(companyId));
    }

    if (errorTypes.includes(type)) {
      setIsMounted(false);
      setAlert({ ...alert, show: true, type: "error" });
    }
  };

  const postEmailApiCallaction = () => {
    let stateObj = { ...state };
    if (type === DOCUMENT_PUBLISH_EMAIL_VERIFICATION_SUCCESS) {
      setIsEmailMounted(false);

      if (disableEmail.unset_email_verification) {
        documentInfo.set(data, {
          email_verification: null,
        });
        document.getElementById("document-publish-email-verfication").checked = false;
        stateObj.email_verification_enabled = false;
        stateObj.toggle_email_verification = false;
        stateObj.email_verification.placeholder = "neel@gmail.com, sam@gmail.com";
        stateObj.email_verification.value = "";
        stateObj.email_verification.verification_type_dropdown.selected.name = "Any Email";
        stateObj.email_verification.verification_type_dropdown.selected.value = "Any Email";
        setEmailInput("");
        setDisableEmail({ ...disableEmail, set_email_verification: false, unset_email_verification: false });
      } else if (
        disableEmail.set_email_verification &&
        stateObj.email_verification.verification_type_dropdown.selected.value == "Any Email"
      ) {
        documentInfo.set(data, {
          email_verification: {
            key: stateObj.email_verification.verification_type_dropdown.selected.value,
          },
        });
        document.getElementById("document-publish-email-verfication").checked = true;
        stateObj.email_verification_enabled = true;
        stateObj.toggle_email_verification = true;
        stateObj.email_verification.placeholder = "neel@gmail.com, sam@gmail.com";
        stateObj.email_verification.value = "";
        setEmailInput("");
        setDisableEmail({ ...disableEmail, set_email_verification: false, unset_email_verification: false });
      } else {
        documentInfo.set(data, {
          email_verification: {
            key: stateObj.email_verification.verification_type_dropdown.selected.value,
            value: stateObj.email_verification.value,
          },
        });
        stateObj.email_verification_enabled = true;
        stateObj.toggle_email_verification = true;
        stateObj.email_verification.valid = false;
        setDisableEmail({ ...disableEmail, set_email_verification: false, unset_email_verification: false });
      }
      setState(stateObj);
    }
  }

  useEffect(() => {
    const totalCount = state.email.payload.toRecipients.length;
    const isThreeRecipients = totalCount === 3;
    const isTenRecipients = totalCount === 10;
    if ((isThreeRecipients && !paidCompanyInfo?.isPaid) || (isTenRecipients && paidCompanyInfo?.isPaid))
      setTotalEmailCount({ threeRecipients: isThreeRecipients, tenRecipients: isTenRecipients, notifyUser: true });
    else if (totalEmailCount.notifyUser)
      setTotalEmailCount({ threeRecipients: false, tenRecipients: false, notifyUser: false });
  }, [state.email.payload.toRecipients]);

  useEffect(() => {
    updateState({ field: "emailSubject", value: documentDetails?.data?.document_name ?? "" });
  }, [documentDetails]);

  useEffect(() => {
    if (isMounted && type) postApiCallActions();
  }, [type, isMounted]);

  useEffect(() => {
    if (isEmailMounted && type) postEmailApiCallaction();
  }, [type, isEmailMounted]);

  useEffect(() => {
    if (state.published && userInfo) generatePublishCode();
  }, [state.published, userInfo]);

  useEffect(() => {
    if (userInfo) setIsUserDocumentOwner(data?.permission === "OWNER");
  }, [userInfo]);

  useEffect(() => {
    if (!documentDetails && documentDetails?.data?.id !== data?.id) dispatch(fetchDocumentDetails(data?.id));
  }, [data?.id]);

  useEffect(() => {
    if (state.tab === "sentEmail") setDisabledSendButton(false);
  }, [state.tab]);

  useEffect(() => {
    if (
      state.email_verification.verification_type_dropdown.selected.value !== "Any Email" &&
      (emailInput?.trim() === "")
    ) {
      setDisabledSendButton(true);
    } else {
      setDisabledSendButton(false);
    }
  }, [state.email_verification.verification_type_dropdown.selected.value, emailInput]);

  useEffect(() => {
    if (state.email_verification.displayValue) {
      const inputVal = convertArrayToInputFormat(state.email_verification.displayValue);
      setEmailInput(inputVal);
    }
  }, [state.email_verification.displayValue]);

  useEffect(() => {
    if (!userInfo) dispatch(getUserInfo());
    return () => {
      localStorage.removeItem("emailCount");
    };
  }, []);

  return (
    <React.Fragment>
      <div className={style["modal-header"]}>
        <h4 className={cx(style["fw-7"], style["mb-0"])}>Email</h4>
        <Link to="#" className={cx(style["cross-modal"], style["rounded"])} onClick={_helpers?.modal?.toggle}>
          <Icon icon="ui-close" />
        </Link>
      </div>
      <div className={cx(style["modal-tab"], style["row"], style["m-0"])}>
        <div className={cx(style["col-4"], style["p-0"])}>
          <Nav className={cx(style["nav-pills"], style["flex-column"], style["h-100"])}>
            <NavItem>
              <NavLink
                className={cx({ [style["active"]]: state.tab === "email" })}
                onClick={() => {
                  setStep(1);
                  updateState({ field: "tab", value: "email" });
                }}>
                <Icon icon="ui-email" /> Email
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={cx({ [style["active"]]: state.tab === "sentEmail" })}
                onClick={() => updateState({ field: "tab", value: "sentEmail" })}>
                <Icon icon="ui-secured-email" /> Sent Email
                <span className={style["numberof-shared"]}>{emailSentCount}</span>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className={cx(style["col-8"], style["p-0"])}>
          <TabContent activeTab={state.tab}>
            <TabPane tabId="email" className={style["email"]}>
              <Row>
                <Col sm="12">
                  <div className={cx(style["content-wrap"], style["customScroll"], style["scroll-Y"], style["mb-3"])}>
                    {step === 1 && (
                      <section>
                        <Col className={cx(style["p-0"], style["mb-3"])}>
                          <div className={cx(style["tocc-wrap"])}>
                            <Input
                              id="ToRecipients"
                              returnType="formGroup"
                              type="email_recipient"
                              label="To"
                              width="100px"
                              disabled={sentEmailRestriction.emailRestrictionMessage}
                              suggestionBoxWidth={suggestionBoxWidth}
                              clickedUpgrade={totalEmailCount?.clickedUpgrade}
                              userNotify={totalEmailCount?.notifyUser}
                              handleEmailCount={handleEmailCount}
                              defaultValue={state?.email.payload.toRecipients}
                              updateState={e => updateState({ field: "toRecipients", value: e })}
                            />
                          </div>
                        </Col>
                        <Col className={cx(style["p-0"])}>
                          <div className={cx(style["subject"])}>
                            <Input
                              cssModule={style}
                              returnType="formGroup"
                              type="text"
                              disabled={sentEmailRestriction.emailRestrictionMessage}
                              placeholder={"Subject"}
                              value={state.email.payload.emailSubject}
                              onChange={e => updateState({ field: "emailSubject", value: e.target.value })}
                            />
                          </div>
                        </Col>

                        <Col className={cx(style["p-0"])}>
                          <div>
                            <h6
                              className={cx(
                                style["d-flex"],
                                style["align-items-center"],
                                style["mb-3"],
                                style["fw-6"]
                              )}>
                              <Icon additionalclass={"font-base mr-2"} icon="ui-info" />
                              How your email will look
                            </h6>
                            <div className={cx(style["card"], style["email-card"])}>
                              <div className={style["card-body"]}>
                                <div className={style["card-header"]}>
                                  <h6 className={cx(style["m-0"], style["fw-6"], style["font-15"])}>
                                    {documentDetails?.data?.document_name}
                                  </h6>
                                </div>
                                <p>{userInfo?.name} has sent you the following document.</p>
                                <div className={cx(style["d-flex"], style["justify-content-center"])}>
                                  <span className={cx(style["btn-border"])}>View Document</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </section>
                    )}

                    {step === 2 && (
                      <section>
                        <div
                          className={cx(style["publishTool"], {
                            [style["inactivePublishUser"]]: !isUserDocumentOwner,
                          })}>
                          <div className={cx(style["d-flex"], style["align-items-center"], style["mb-2"])}>
                            <h6 className={cx(style["fw-6"], style["mb-0"])}>Document Link</h6>
                            <span className={cx(style["custom-tooltip"], style["ml-2"], style["d-flex"])}>
                              <Icon additionalclass={"font-base"} icon="ui-info" />
                              <div className={cx(style["custom-tooltip-content"], style["bottom"], style["mt-1"])}>
                                The document link will be added to <br />
                                your email body automatically
                              </div>
                            </span>
                          </div>
                          {state.published && (
                            <div className={style["publish-details"]}>
                              <div className={style["copy-url"]}>
                                <Input
                                  cssModule={style}
                                  returnType="noGroup"
                                  type="text"
                                  className={cx(style["publish-link"])}
                                  defaultValue={state.email.payload.publishLink}
                                  readOnly
                                />
                                <div className={cx(style["text-right"], style["my-2"], style["clearfix"])}>
                                  <div className={cx(style["d-flex"], style["justify-content-end"])}>
                                    <Link
                                      to="#"
                                      onClick={handlePreviewClick}
                                      className={cx(
                                        style["color-black"],
                                        style["d-flex"],
                                        style["align-items-center"]
                                      )}>
                                      <Icon icon="eye2" additionalclass={"mr-2"} /> Preview
                                    </Link>
                                  </div>
                                </div>
                              </div>

                              <>
                                <TabSchemeSelector
                                  schemeTypeArray={publishSchemes}
                                  setSchemeType={setSchemeType}
                                  schemeType={schemeType}
                                  activeTabIndex={activeTabIndex}
                                />
                                <TabContent className={style["p-0"]}>
                                  {schemeType === "General" && (
                                    <TabPane tabId="general_setting" className="d-block">
                                      <div className={cx(style["d-flex"], style["align-items-center"], style["mb-3"])}>
                                        <div className={cx(style["custom-control"], style["custom-switch"])}>
                                          <Input
                                            cssModule={style}
                                            returnType="noGroup"
                                            type="checkbox"
                                            className={style["custom-control-input"]}
                                            id="document-publish-link-toggle-allow-download"
                                            label="Allow Viewers to Download"
                                            defaultChecked={state.download_enabled}
                                            onChange={e => updateState({ field: "toggle_allow_download", e: e })}
                                            disabled={!isUserDocumentOwner}
                                          />
                                        </div>

                                        {isUserDocumentOwner && (
                                          <>
                                            <div className={cx(style["custom-tooltip"], style["ml-2"], style["d-flex"])}>
                                              <Icon icon="ui-info" additionalclass="font-14" />
                                              <div
                                                className={cx(
                                                  style["custom-tooltip-content"],
                                                  style["text-center"],
                                                  style["bottom"],
                                                  style["mt-1"]
                                                )}>
                                                Allow viewers to <br></br> download in PDF format
                                              </div>
                                            </div>
                                            <img alt="premium" width={20} src={Crown} className={cx(style["ml-2"])} />
                                          </>
                                        )}

                                        {!isUserDocumentOwner && (
                                          <div className={cx(style["ml-2"], style["info-link"])}>
                                            <span className={style["color-33"]}>
                                              <Icon icon="information" />
                                            </span>
                                            <div
                                              className={cx(
                                                style["info-link-content"],
                                                style["rounded"],
                                                style["shadow-sm"]
                                              )}>
                                              Only the Document Owner can make changes to the Publish settings.
                                            </div>
                                          </div>
                                        )}

                                        <ProcessLoader
                                          isOpen={
                                            (disable.set_download && !processEndTypes.download.includes(type)) ||
                                            (disable.unset_download && !processEndTypes.download.includes(type))
                                          }
                                          wrapperClass="ml-2"
                                        />
                                      </div>

                                      <div className={cx(style["d-flex"], style["align-items-center"], style["mb-3"])}>
                                        <div className={cx(style["custom-control"], style["custom-switch"])}>
                                          <Input
                                            cssModule={style}
                                            returnType="noGroup"
                                            type="checkbox"
                                            className={style["custom-control-input"]}
                                            id="document-publish-link-toggle-setpassword"
                                            label="Set Password"
                                            defaultChecked={state.toggle_set_password}
                                            onChange={e => updateState({ field: "toggle_set_password", e: e })}
                                            disabled={
                                              !isUserDocumentOwner ||
                                              disable.set_password ||
                                              isPasswordSuccessResponse !== ""
                                            }
                                          />
                                        </div>

                                        {isUserDocumentOwner && (
                                          <>
                                            <div className={cx(style["custom-tooltip"], style["ml-2"], style["d-flex"])}>
                                              <Icon icon="ui-info" additionalclass="font-14" />
                                              <div
                                                className={cx(style["custom-tooltip-content"], style["text-center"], style["mt-1"])}>
                                                Share document privately <br></br> with a password
                                              </div>
                                            </div>
                                            <img alt="premium" width={20} src={Crown} className={cx(style["ml-2"])} />
                                          </>
                                        )}

                                        {!isUserDocumentOwner && (
                                          <div className={cx(style["ml-2"], style["info-link"])}>
                                            <span className={style["color-33"]}>
                                              <Icon icon="information" />
                                            </span>
                                            <div
                                              className={cx(
                                                style["info-link-content"],
                                                style["rounded"],
                                                style["shadow-sm"]
                                              )}>
                                              Only the Document Owner can make changes to the Publish settings.
                                            </div>
                                          </div>
                                        )}

                                        <ProcessLoader
                                          isOpen={
                                            (disable.set_password && !processEndTypes.set_password.includes(type)) ||
                                            (disable.unset_password && !processEndTypes.unset_password.includes(type))
                                          }
                                          wrapperClass="ml-2"
                                        />
                                      </div>

                                      {state.toggle_set_password && (
                                        <div className={cx(style["password-generation"])}>
                                          <Form
                                            className={style["form-inline"]}
                                            onKeyDown={e => preventSubmit(e)}
                                            onSubmit={e => updateState({ field: "set_password", e: e })}>
                                            <Input
                                              cssModule={style}
                                              returnType="formGroup"
                                              type="password_length"
                                              limit={state.password.validation.max_length}
                                              length={state.password.value.length}
                                              showerror={state.password.error}
                                              showsuccess={isPasswordSuccessResponse}
                                              id="document-publish-password"
                                              placeholder={state.password.placeholder}
                                              maxLength={state.password.validation.max_length}
                                              onChange={e => updateState({ field: "password", type: "change", e: e })}
                                              onBlur={e => updateState({ field: "password", type: "blur", e: e })}
                                              disabled={
                                                !isUserDocumentOwner ||
                                                disable.set_password ||
                                                isPasswordSuccessResponse !== ""
                                              }
                                            />
                                            <Button
                                              type="submit"
                                              color="primary"
                                              cssModule={style}
                                              className={style["ml-auto"]}
                                              disabled={
                                                !isUserDocumentOwner ||
                                                !state.password.valid ||
                                                disable.set_password ||
                                                disable.unset_password
                                              }>
                                              {state.set_password_button_label}
                                            </Button>
                                          </Form>
                                        </div>
                                      )}
                                    </TabPane>
                                  )}
                                  {schemeType === "Other Settings" && (
                                    <TabPane tabId="other_setting" className="d-block">
                                      <div>
                                        <div
                                          className={cx(
                                            style["d-flex"],
                                            style["align-items-center"],
                                            style["mb-3"],
                                            style[
                                              !(paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN)
                                                ? "inactivePublishUser"
                                                : "activeCompanyBadge"
                                            ]
                                          )}>
                                          <div className={cx(style["custom-control"], style["custom-switch"])}>
                                            <Input
                                              cssModule={style}
                                              returnType="noGroup"
                                              type="checkbox"
                                              className={style["custom-control-input"]}
                                              id="document-toggle-dochipo-badge"
                                              label="Hide DocHipo Badge"
                                              defaultChecked={state.dochipo_badge_enabled}
                                              onChange={e => updateState({ field: "toggle_badge_enable", e: e })}
                                              disabled={!(paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN)}
                                            />
                                          </div>

                                          <>
                                            <div className={cx(style["custom-tooltip"], style["ml-2"], style["d-flex"])}>
                                              <Icon icon="ui-info" additionalclass="font-14" />
                                              <div
                                                className={cx(
                                                  style["custom-tooltip-content"],
                                                  style["text-center"],
                                                  style["mt-n1"]
                                                )}>
                                                Hide DocHipo badge from the <br></br> page footer of all published
                                                pages. <br></br> Only Admin can manage this
                                              </div>
                                            </div>
                                            <img alt="premium" width={20} src={Crown} className={cx(style["ml-2"])} />
                                          </>

                                          <ProcessLoader
                                            isOpen={
                                              (disable.set_badge_enable && !processEndTypes.badge.includes(type)) ||
                                              (disable.unset_badge_enable && !processEndTypes.badge.includes(type))
                                            }
                                            wrapperClass="ml-2"
                                          />
                                        </div>

                                        <div
                                          className={cx(style["d-flex"], style["align-items-center"], style["mb-4"])}>
                                          <div className={cx(style["custom-control"], style["custom-switch"])}>
                                            <Input
                                              cssModule={style}
                                              returnType="noGroup"
                                              type="checkbox"
                                              className={style["custom-control-input"]}
                                              id="document-publish-email-verfication"
                                              label="Email Verification"
                                              defaultChecked={state.email_verification_enabled}
                                              onChange={e =>
                                                updateState({ field: "toggle_set_email_verification", e: e })
                                              }
                                              disabled={!isUserDocumentOwner}
                                            />
                                          </div>

                                          {isUserDocumentOwner && (
                                            <>
                                              <div className={cx(style["custom-tooltip"], style["ml-2"], style["d-flex"])}>
                                                <Icon icon="ui-info" additionalclass="font-14" />
                                                <div
                                                  className={cx(
                                                    style["custom-tooltip-content"],
                                                    style["text-center"],
                                                    style["mt-1"]
                                                  )}>
                                                  Requires email address to <br></br> access the document
                                                </div>
                                              </div>
                                              <img alt="premium" width={20} src={Crown} className={cx(style["ml-2"])} />
                                            </>
                                          )}

                                          {!isUserDocumentOwner && (
                                            <div className={cx(style["ml-2"], style["info-link"])}>
                                              <span className={style["color-33"]}>
                                                <Icon icon="information" />
                                              </span>
                                              <div
                                                className={cx(
                                                  style["info-link-content"],
                                                  style["rounded"],
                                                  style["shadow-sm"]
                                                )}>
                                                Only the Document Owner can make changes to the Publish settings.
                                              </div>
                                            </div>
                                          )}

                                          {state.email_verification_enabled && (
                                            <div className={cx(style["ml-3"], style["email-dropdown"], {[style["disable"]]: !isUserDocumentOwner})}>
                                              <UncontrolledDropdown className={cx(style["w-100"])}>
                                                <DropdownToggle caret tag="a" className={style["rounded"]}>
                                                  <span>
                                                    {state.email_verification.verification_type_dropdown.selected.value}
                                                  </span>
                                                  <Icon icon="ui-arrow-down" additionalclass={"ml-1"} />
                                                </DropdownToggle>
                                                <DropdownMenu
                                                  className={cx(style["shadow"], style["border-0"], style["rounded"])}>
                                                  {Object.keys(verificationTypes).map((p, i) => (
                                                    <DropdownItem
                                                      tag="a"
                                                      key={i}
                                                      className={cx({
                                                        [style["active-item"]]:
                                                          verificationTypes[p] ===
                                                          state.email_verification.verification_type_dropdown.selected
                                                            .value,
                                                      })}
                                                      onClick={() => handleVerificationTypeClick(p)}>
                                                      {verificationTypes[p]}
                                                    </DropdownItem>
                                                  ))}
                                                </DropdownMenu>
                                              </UncontrolledDropdown>
                                            </div>
                                          )}

                                          <ProcessLoader
                                            isOpen={
                                              (disableEmail.set_email_verification &&
                                                !processEndTypes.email_verification.includes(type)) ||
                                              (disableEmail.unset_email_verification &&
                                                !processEndTypes.email_verification.includes(type))
                                            }
                                            wrapperClass="ml-2"
                                          />
                                        </div>

                                        {state.email_verification_enabled &&
                                          state.email_verification.verification_type_dropdown.selected.value !==
                                            "Any Email" && (
                                            <div className={style["multiple-email"]}>
                                              <Form
                                                onKeyDown={e => preventSubmit(e)}
                                                onSubmit={e => updateState({ field: "set_email_verification", e: e })}>
                                                <Input
                                                  className={{[style["disable"]]: !isUserDocumentOwner}}
                                                  cssModule={style}
                                                  returnType="formGroup"
                                                  type="text"
                                                  id="document-publish-email-verify"
                                                  placeholder={
                                                    !emailInput &&
                                                    state.email_verification.verification_type_dropdown.selected.value !== "Email Domain"
                                                      ? state.email_verification.placeholder
                                                      : state.email_verification.email_domain_placeholder
                                                  }
                                                  onChange={e => updateState({ field: "verify_email", e: e })}
                                                  value={emailInput}
                                                />
                                                <div className={style["right"]}>
                                                  <Button
                                                    type="submit"
                                                    color="primary"
                                                    cssModule={style}
                                                    disabled={
                                                      !state.email_verification.valid ||
                                                      disableEmail.set_email_verification ||
                                                      disableEmail.unset_email_verification ||
                                                      !emailInput
                                                    }>
                                                    Save
                                                  </Button>
                                                </div>
                                              </Form>
                                            </div>
                                          )}
                                      </div>
                                    </TabPane>
                                  )}
                                </TabContent>
                              </>
                            </div>
                          )}

                          <FlashAlert isOpen={alert.show} toggle={toggleAlert} type={alert.type} wrapperClass="mt-3" />
                        </div>
                      </section>
                    )}
                  </div>

                  <div className={cx(style["align-items-center"], style["d-flex"])}>
                    <div className={cx(style["btn-wrp-bg"], style["d-flex"])}>
                      {step === 1 && (
                        <div className={cx(style["d-flex"], style["align-items-center"])}>
                          <Button
                            type="submit"
                            color="primary"
                            cssModule={style}
                            disabled={
                              state?.email?.payload?.toRecipients?.length === 0 ||
                              state?.email?.payload?.emailSubject?.trim() === "" ||
                              (sentEmailRestriction.emailRestrictionMessage && !totalEmailCount.showMessage) ||
                              nextClickDisable
                            }
                            onClick={handlePublishButtonClick}>
                            Next
                          </Button>

                          {sentEmailRestriction.emailRestrictionMessage && (
                            <div className={cx(style["d-flex"], style["flex-column"], style["ml-3"])}>
                              {sentEmailRestriction.oneEmailPerDay && (
                                <>
                                  <span className={style["color-red"]}>{`${unPaidEmailRestriction} email/day`}</span>
                                  <span
                                    className={cx(style["color-black"], "restrict-blur")}
                                    onClick={() => {
                                      upgradeToTenEmails();
                                    }}>
                                    <u className={style["cursor-pointer"]}>Upgrade for more</u>
                                  </span>
                                </>
                              )}
                              {sentEmailRestriction.tenEmailPerDay && (
                                <>
                                  <span
                                    className={
                                      style["color-red"]
                                    }>{`You can send ${paidEmailRestriction} emails/day`}</span>
                                </>
                              )}
                            </div>
                          )}

                          {totalEmailCount.showMessage && !sentEmailRestriction.emailRestrictionMessage && (
                            <div className={cx(style["d-flex"], style["flex-column"], style["ml-3"])}>
                              {totalEmailCount.threeRecipients && (
                                <>
                                  <span className={style["color-red"]}>You can add up to 3 recipients/email</span>
                                  <span
                                    className={cx(style["color-black"], "restrict-blur")}
                                    onMouseDown={() => handleUpgrade()}
                                    onClick={() => {
                                      upgradeToTenRecipients();
                                      setTotalEmailCount({ ...totalEmailCount, clickedUpgrade: false });
                                    }}>
                                    <u className={style["cursor-pointer"]}>Upgrade for more</u>
                                  </span>
                                </>
                              )}
                              {totalEmailCount.tenRecipients && (
                                <>
                                  <span className={style["color-red"]}>You can add up to 10 recipients/email</span>
                                </>
                              )}
                            </div>
                          )}
                          <ProcessLoader isOpen={!state.published && state.toggle_publish} />
                        </div>
                      )}

                      {step === 2 && (
                        <div className={cx(style["d-flex"], style["align-items-center"])}>
                          <Button
                            type="submit"
                            color="primary"
                            cssModule={style}
                            disabled={disabledSendButton}
                            className={cx(style["mr-2"])}
                            onClick={handleEmailSend}>
                            Send
                          </Button>
                          <Button type="button" className="btn-border" cssModule={style} onClick={handleBack}>
                            Back
                          </Button>
                          <ProcessLoader
                            isOpen={
                              (lodingEmailList ?? false) || (loadingEmailSend ?? false) || (delayedSentEmail ?? false)
                            }
                          />
                          {emailSendError && (
                            <div className={cx(style["d-flex"], style["flex-column"], style["ml-3"])}>
                              <>
                                <span className={style["color-red"]}>The email couldn’t be sent.</span>
                                <span className={cx(style["color-red"])}>Please try again later.</span>
                              </>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="sentEmail" className={style["sentEmail"]}>
              <Row>
                <Col sm="12">
                  <div className={cx(style["content-wrap"])}>
                    <EmailTable
                      stateTable={state}
                      sentEmailFirstTime={sentEmailFirstTime}
                      handleSentEmailCount={handleSentEmailCount}
                      timeFormat={time_format}
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </React.Fragment>
  );
};

const EmailTable = ({ sentEmailFirstTime, handleSentEmailCount, timeFormat, stateTable }) => {
  const dispatch = useDispatch();
  const loadMoreRef = useRef();
  const tableRef = useRef(null);
  let hasMore = false;
  const fetch = {
    page: 1,
    limit: 25,
  };
  const configInfo = {
    component: {
      isStatic: true,
      tableClass: "trash-table",
      name: "Sent Email",
      slug: "sent_email",

      no_record_info: {
        text: "No records found.",
      },
    },
    appearance: {
      generic_actions: {
        ui: {
          component_info: true,
        },
      },
    },
    list_view: {
      columns: [
        {
          name: "Date",
          slug: "created_on",
          type: "date-time",
          format: "default",
          display: true,
        },
        {
          name: "Recipient",
          slug: "record",
          type: "dataobj",
          display: true,
        },
      ],
    },
  };
  const IntersectOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  };

  const [payloadFetch, setPayloadFetch] = useState(fetch);
  const [availableTableData, setAvailableTableData] = useState([]);
  const [fetchingEmailListError, setFetchingEmailListError] = useState(false);

  const { documentDetails, sentEmailList, lodingEmailList } = useSelector(state => state?.document);
  const { total_count: totalCount = 0, email_sent: sent_EmailCount = 0 } = sentEmailList ?? {
    total_count: 0,
    email_sent: 0,
  };

  const fetchRecords = () =>
    dispatch(fetchSentEmailList(documentDetails?.data?.id, payloadFetch)).then(r => {
      if (r.type !== FETCH_SENT_EMAIL_LIST_SUCCESS) setFetchingEmailListError(true);
    });
  const loadMore = () => setPayloadFetch({ ...payloadFetch, page: payloadFetch.page + 1 });

  const handleIntersect = entries => {
    if (entries[0].isIntersecting && hasMore) loadMore();
  };

  const handleRefreshEmailList = () => {
    setPayloadFetch(fetch);
    setFetchingEmailListError(false);
  };

  useLayoutEffect(() => {
    handleSentEmailCount(totalCount, sent_EmailCount);
  }, [sentEmailList]);

  useEffect(() => {
    if (sentEmailList?.shared_email)
      setAvailableTableData(
        payloadFetch.page > 1 ? [...availableTableData, ...sentEmailList?.shared_email] : sentEmailList?.shared_email
      );
    if (totalCount > 0) {
      const lastPage = Math.floor(
        totalCount % payloadFetch.limit === 0 ? totalCount / payloadFetch.limit : totalCount / payloadFetch.limit + 1
      );
      hasMore = payloadFetch.page < lastPage;
    }

    const observer = new IntersectionObserver(handleIntersect, IntersectOptions);
    if (loadMoreRef.current) observer.observe(loadMoreRef.current);
    return () => {
      if (loadMoreRef.current) observer.unobserve(loadMoreRef.current);
    };
  }, [sentEmailList, loadMoreRef]);

  useEffect(() => {
    fetchRecords();
  }, [payloadFetch]);

  useEffect(() => {
    if (stateTable.tab === "sentEmail" && tableRef.current) {
      let maxWidth = 0;

      const rows = tableRef.current.querySelectorAll("tbody tr");
      rows.forEach(cell => {
        if (cell.querySelector("td div") && cell.querySelector("td").clientWidth > maxWidth)
          maxWidth = cell.querySelector("td").clientWidth;
      });
      document.querySelector("#sent-email-table thead tr th").style.width = maxWidth + "px";
    }
  }, [stateTable.tab]);

  return (
    <div className={cx(style["table-info-wrap"], style["h-100"])}>
      {availableTableData?.length >= 1 && !fetchingEmailListError && (
        <div className={cx(style["table-responsive-custom"], style["customScroll"], style["scroll-X"])}>
          <table
            id="sent-email-table"
            ref={tableRef}
            className={cx(style["table"], style["table-borderless"], {
              [style["table-12-hours"]]: timeFormat === "12 Hours",
            })}>
            <thead>
              {availableTableData && (
                <tr>
                  {configInfo.list_view.columns.map((column, i) => (
                    <th key={i} style={column?.customStyle}>
                      {column.name}
                      {column.slug === "record" && (
                        <div className={cx(style["custom-tooltip"])}>
                          <>
                            <Icon
                              onClick={() => handleRefreshEmailList()}
                              icon="ui-rotate-right"
                              additionalclass={"cursor-pointer"}
                            />
                            <div className={cx(style["custom-tooltip-content"], style["left"])}>
                              Refresh to see the latest data
                            </div>
                          </>
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              )}
            </thead>
            <tbody className={cx(style["customScroll"], style["scroll-Y"])}>
              {availableTableData?.map((data, i) => (
                <tr key={i}>
                  <td>
                    <DataFormatter
                      type={configInfo.list_view.columns[0].type}
                      slug={configInfo.list_view.columns[0].slug}
                      format={configInfo.list_view.columns[0].format}
                      data={data}
                    />
                  </td>
                  <td>
                    <span
                      className={cx(style["d-flex"], style["align-items-center"], style["justify-content-between"])}>
                      <span>{data?.recipient[0]}</span>
                      {data?.recipient?.length > 1 && (
                        <span id={`emailCounter${i}`} className={cx(style["email-count"])}>{`+${
                          data?.recipient?.length - 1
                        }`}</span>
                      )}
                    </span>

                    {data?.recipient?.length > 1 && (
                      <UncontrolledTooltip
                        placement="bottom"
                        target={`emailCounter${i}`}
                        trigger="hover"
                        arrowClassName="d-none"
                        className={cx(style["email-list-tooltip"])}>
                        {data?.recipient?.map((val, i) => (
                          <span key={i}>
                            {val}
                            <br />
                          </span>
                        ))}
                      </UncontrolledTooltip>
                    )}
                  </td>
                </tr>
              ))}

              {lodingEmailList && payloadFetch.page > 1 && <DotLoader />}
              {!lodingEmailList && (
                <tr>
                  <td colSpan="2" style={{ height: "20px" }} ref={loadMoreRef}></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      {(availableTableData?.length === 0 || fetchingEmailListError || sentEmailFirstTime) && (
        <div
          className={cx(
            style["d-flex"],
            style["align-items-center"],
            style["justify-content-center"],
            style["h-75"],
            style["pt-5"],
            style["text-center"]
          )}>
          {availableTableData?.length === 0 && !sentEmailFirstTime && !fetchingEmailListError && (
            <>The document is not sent to anyone</>
          )}
          {(fetchingEmailListError || (sentEmailFirstTime && availableTableData?.length === 0)) && (
            <>
              It might take some time to show the data
              <br />
              here. You may please close the window
              <br />
              and come back later.
            </>
          )}
        </div>
      )}
    </div>
  );
};

EmailTable.propTypes = {
  sentEmailFirstTime: PropTypes.bool,
  handleSentEmailCount: PropTypes.func.isRequired,
};

EmailModal.propTypes = {
  document: PropTypes.object.isRequired,
  _helpers: PropTypes.object.isRequired,
};

export default EmailModal;
