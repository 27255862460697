import React, { useContext, useEffect, useState } from "react";
import { TabPane } from "reactstrap";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import SliderControll from "../SliderControll";
import ColorBucket from "./ColorBucket";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import {
  changeBucketColorAccordingBackground,
  changeWidgetColorAccordingBackground,
  hexToRGB,
  RGBToHex,
} from "../../../_helpers/utils";
import { ANIMATION, BRAND, PICTURE, STOCK, SVG, UPLOAD, UPLOAD_VIDEO, VIDEO } from "../../../constants/editor";

let style = Object.assign({}, global);

const Shadow = props => {
  let { metadata, widgets, backgroundColors, updateWidgets, togglePlayButton, updateTogglePlayButton } =
    useContext(EditorContext);

  let activeBlockColor;
  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
  let targetBackgroundindex = backgroundColors.findIndex(
    backgroundColor => backgroundColor.blockId === metadata.activeBlockId
  );

  if (targetBackgroundindex !== -1)
    activeBlockColor = hexToRGB(backgroundColors[targetBackgroundindex]?.style.backgroundColor);

  const [horaizentalShadow, setHoraizentalShadow] = useState();
  const [verticalShadow, setVerticalShadow] = useState();
  const [blur, setBlur] = useState();
  const [shadowColor, setShadowColor] = useState();
  const [iconBucketColor, setIconBucketColor] = useState();
  const [updateContext, setUpdateContext] = useState(false);
  const [eyedropperColor, setEyedropperColor] = useState();
  const [allStateValueLoaded, setAllStateValueLoaded] = useState(false);

  const stopVideio = () => {
    if (
      document.getElementById(id).getAttribute("data-asset-type") === VIDEO &&
      (document.getElementById(id).getAttribute("data-source") === STOCK ||
        document.getElementById(id).getAttribute("data-source") === BRAND ||
        document.getElementById(id).getAttribute("data-source") === UPLOAD_VIDEO) &&
      document.getElementById(id).getAttribute("data-playing") === "true"
    ) {
      setTimeout(() => {
        updateTogglePlayButton(!togglePlayButton);
      }, 10);
    }
  };

  //update color if user chose color from eyedropper
  useEffect(() => {
    if (eyedropperColor) {
      setUpdateContext(true);
      setShadowColor(eyedropperColor);
      setEyedropperColor();
    }
  }, [eyedropperColor]);

  useEffect(() => {
    if (
      horaizentalShadow !== undefined &&
      verticalShadow !== undefined &&
      blur !== undefined &&
      shadowColor !== undefined
    ) {
      let newArray;
      let currentElem = document.getElementById(metadata.activeWidgetId[0]);
      let flipableAvailable =
        metadata.activeWidgetType[0] === PICTURE ||
        metadata.activeWidgetType[0] === ANIMATION ||
        (metadata.activeWidgetType[0] === VIDEO &&
          (currentElem.dataset.source === STOCK ||
            currentElem.dataset.source === BRAND ||
            currentElem.dataset.source === UPLOAD_VIDEO)) ||
        (metadata.activeWidgetType[0] === UPLOAD && currentElem.dataset.fileType !== SVG);
      let targetElem = flipableAvailable
        ? currentElem.querySelector(".flippable")
        : currentElem.querySelector(".dhp-widget-inner");

      //If all shadow value is 0 apply filter none
      if (horaizentalShadow === 0 && verticalShadow === 0 && blur === 0) {
        targetElem.style.filter = "none";
        props.setIsShadowApplied(false);

        newArray = Object.assign([...widgets], {
          [targetWidgetIndex]: {
            ...widgets[targetWidgetIndex],
            innerHTML: document.getElementById(targetId).innerHTML,
          },
        });
      }
      //Apply filter values on widget
      else {
        targetElem.style.filter = `drop-shadow(${horaizentalShadow}px ${verticalShadow}px ${blur}px ${shadowColor})`;
        props.setIsShadowApplied(true);

        newArray = Object.assign([...widgets], {
          [targetWidgetIndex]: {
            ...widgets[targetWidgetIndex],
            innerHTML: document.getElementById(targetId).innerHTML,
          },
        });
      }

      updateContext && updateWidgets(newArray);

      setIconBucketColor(
        changeBucketColorAccordingBackground(shadowColor !== "transparent" ? hexToRGB(shadowColor) : shadowColor)
      );
      stopVideio();
    }
  }, [horaizentalShadow, verticalShadow, blur, shadowColor, updateContext]);

  useEffect(() => {
    if (metadata.activeWidgetId && props.dropdownOpen) {
      let currentElem = document.getElementById(metadata.activeWidgetId[0]);
      let flipableAvailable =
        metadata.activeWidgetType[0] === PICTURE ||
        metadata.activeWidgetType[0] === ANIMATION ||
        (metadata.activeWidgetType[0] === VIDEO &&
          (currentElem.dataset.source === STOCK ||
            currentElem.dataset.source === BRAND ||
            currentElem.dataset.source === UPLOAD_VIDEO)) ||
        (metadata.activeWidgetType[0] === UPLOAD && currentElem.dataset.fileType !== SVG);
      let targetElem = flipableAvailable
        ? currentElem.querySelector(".flippable")
        : currentElem.querySelector(".dhp-widget-inner");
      let filterValue = targetElem.style.filter;

      //Load Current Formatting if shadow is applied before
      if (filterValue && filterValue !== "none" && filterValue.includes("drop-shadow")) {
        let filterArray = filterValue.split("drop-shadow")[1].split(" ");
        let rgbValue = filterArray[0].split("(");
        rgbValue =
          rgbValue[1] === "transparent"
            ? rgbValue[1]
            : RGBToHex(`${rgbValue[1]}(${rgbValue[2]} ${filterArray[1]} ${filterArray[2]}`);

        setHoraizentalShadow(parseInt(filterArray[rgbValue === "transparent" ? 1 : 3]));
        setVerticalShadow(parseInt(filterArray[rgbValue === "transparent" ? 2 : 4]));
        setBlur(parseInt(filterArray[rgbValue === "transparent" ? 3 : 5]));
        setShadowColor(rgbValue);
      }
      //Load default Formatting if shadow is not applied before
      else {
        let defaultShadowColor = RGBToHex(changeWidgetColorAccordingBackground(activeBlockColor));

        setHoraizentalShadow(0);
        setVerticalShadow(0);
        setBlur(0);
        setShadowColor(defaultShadowColor);
      }

      setAllStateValueLoaded(true);
    }
  }, [props.dropdownOpen, metadata.activeWidgetId[0]]);

  return (
    <TabPane tabId={props.idx}>
      {allStateValueLoaded && (
        <>
          <div className={cx(style["line-controls-wrap"], style["with-input"])}>
            <SliderControll
              Slider={horaizentalShadow}
              setSlider={setHoraizentalShadow}
              sliderMin={-10}
              sliderMax={10}
              sliderLabel={"Horizontal"}
              setUpdateContext={setUpdateContext}
              sliderName={"shadow"}
            />
          </div>

          <div className={cx(style["line-controls-wrap"], style["with-input"])}>
            <SliderControll
              Slider={verticalShadow}
              setSlider={setVerticalShadow}
              sliderMin={-10}
              sliderMax={10}
              sliderLabel={"Vertical"}
              setUpdateContext={setUpdateContext}
              sliderName={"shadow"}
            />
          </div>

          <div className={cx(style["line-controls-wrap"], style["with-input"])}>
            <SliderControll
              Slider={blur}
              setSlider={setBlur}
              sliderMin={0}
              sliderMax={10}
              sliderLabel={"Blur"}
              setUpdateContext={setUpdateContext}
              sliderName={"shadow"}
            />
          </div>

          {(horaizentalShadow !== 0 || verticalShadow !== 0 || blur !== 0) && (
            <div className={cx(style["line-controls-wrap"], style["justify-content-start"])}>
              <div className={style["slidelabel"]}>Color</div>
              <ColorBucket
                color={shadowColor}
                setColor={setShadowColor}
                iconBucketColor={iconBucketColor}
                position={"toolbar"}
                setUpdateContext={setUpdateContext}
                eyedropperColor={eyedropperColor}
                setEyedropperColor={setEyedropperColor}
              />
            </div>
          )}
        </>
      )}
    </TabPane>
  );
};
Shadow.propTypes = {
  idx: PropTypes.number,
  dropdownOpen: PropTypes.bool,
  setIsShadowApplied: PropTypes.func,
};

export default Shadow;
