import React from "react";
import PropTypes from "prop-types";

import global from "../../scss/dhp.scss";

let style = Object.assign({}, global);

const MultiColorList = ({ colors }) => {
  return (
    <div id="color-list" className={style["multicolor-container"]}>
      {colors?.map((color, idx) => (
        <div key={idx} className={style["multicolor-item"]} style={{ backgroundColor: color }}>
          &nbsp;
        </div>
      ))}
    </div>
  );
};

MultiColorList.propTypes = {
  colors: PropTypes.array.isRequired,
};

export default MultiColorList;
