import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import cx from "classnames";
import { Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import { Input } from "../../ui/input";
import { useDispatch, useSelector } from "react-redux";
import { TEMPLATE_TYPE_PRESENTATION } from "../../../constants/pageSizeModal";
import { fetchDocumentDetails } from "../../../store/actions/documentActions";
import { useSocket } from "../../../hooks/useSocket";
import Progressbar from "../../ui/progressbar";
import { ALLOWED_DIMENSION, DPI_RATIO, SUPPORT_EMAIL } from "../../../constants/env";
import { Icon } from "../../ui/icon";
import { CONN_DOC_SAVE, DOCUMENT_SAVE, DOCUMENT_SAVE_AFTER, STREAMING_FAILED, STREAMING_PROGRESS, STREAMING_START, STREAMING_SUCCESS } from "../../../constants/socket";

import global from "../../../scss/dhp.scss";
import Sparkle from "../../../assets/images/sparkle.svg";
import Exclamation from "../../../assets/images/exclamation-mark.svg";
import Crown from "../../../assets/images/ui-crown.svg";
import { useContextualUpgrade } from "../../../hooks/useContextualUpgrade";
import { useCheckCompanyPlanInfo } from "../../../hooks/useCheckCompanyPlanInfo";
import { COMPANY_SUPERADMIN } from "../../../constants/company";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import CanvasPageHtmlGenaretor from "../../Editor/CanvasPageHtmlGenaretor";
import useDocumentParser from "../../../hooks/useDocumentParser";

let style = Object.assign({}, global);

const DownloadModal = ({ document, _helpers, setDocumentParsing }) => {
  let { pageNodes, blockNodes, backgroundColors, backgroundImages, widgets, dimension, documentType } = useContext(EditorContext);

  const dispatch = useDispatch();
  const webSocket = useSocket();
  const form = {
    type: {
      name: "File Type",
      value: {
        name: "PNG",
        value: "png",
      },
      options: [
        {
          name: "JPG",
          value: "jpg",
          details: "Small file size",
          premium: false,
          download_quality_available: true,
        },
        {
          name: "PNG",
          value: "png",
          details: "Ideal for web design",
          premium: false,
          download_quality_available: true,
        },
        {
          name: "PNG Transparent",
          value: "png_transparent",
          details: "No background",
          premium: true,
          download_quality_available: true,
        },
        {
          name: "PDF",
          value: "pdf",
          details: "Ideal for printing",
          premium: true,
          download_quality_available: true,
        },
        {
          name: "MP4",
          value: "mp4",
          details: "High quality video",
          premium: true,
          download_quality_available: false,
        },
        {
          name: "GIF",
          value: "gif",
          details: "Ideal for animation",
          premium: true,
          download_quality_available: false,
        },
      ],
    },
    quality: {
      name: "Quality",
      value: {
        name: "Normal",
        value: "normal",
      },
      options: [
        {
          name: "Normal",
          value: "normal",
          details: "For standard screen",
          premium: false,
        },
        {
          name: "Medium",
          value: "medium",
          details: "For retina screen",
          premium: true,
        },
        {
          name: "High",
          value: "high",
          details: "For print",
          premium: true,
        },
      ],
      disabled: false,
    },
  };

  // useContextualUpgrade Hook to show update modal
  const showUpgrade = useContextualUpgrade();

  // check is this company is paid or not
  const paidCompanyInfo = useCheckCompanyPlanInfo(true);

  const { start: initDocumentParser } = useDocumentParser();

  // Socket Connection for save document
  const saveDocSocket = useSocket(CONN_DOC_SAVE);

  const [formData, setFormData] = useState(form);
  const [downloadProgress, setDownloadProgress] = useState(false);
  const [downloadError, setDownloadError] = useState(false);
  const [downloadLink, setDownloadLink] = useState(false);
  const [selectedPages, setSelectedPages] = useState([1]);
  const [isInNewVersion, setIsInNewVersion] = useState(true);
  const [initDownloadNewVersion, setInitDownloadNewVersion] = useState();
  const [disableQuality, setDisableQuality] = useState()

  const availableTemplateGroups = useSelector(state => state?.template?.templateGroups);

  const updateForm = (option, field) => {
    if (option.premium && paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && !paidCompanyInfo?.isPaid) {
      // Call showUpgrade function to show contexttual upgrade modal based on your condition
      showUpgrade("downloadQuality");
      if (_helpers?.modal?.toggle) _helpers.modal.toggle();
      return;
    }
    let updatedForm = { ...formData };
    updatedForm[field].value = {
      name: option.name,
      value: option.value,
    };
    if (field === "type") updatedForm.quality.disabled = !option.download_quality_available;
    setFormData(updatedForm);
  };

  const documentDetails = useSelector(state => state?.document?.documentDetails);
  const companyId = useSelector(state => state?.auth?.user?.company?.id);

  const type = documentDetails?.data?.template_info?.type?.toLowerCase();

  const pages = pageNodes ? pageNodes?.map((page, index) => {
    return { id: index + 1, page };
  }) : documentDetails?.data?.version?.rawdata?.contexts?.pageNodes ?
    documentDetails?.data?.version?.rawdata?.contexts?.pageNodes?.map((page, index) => {
      return { id: index + 1, page };
    }) : documentDetails?.data?.version?.rawdata?.html?.map((page, index) => {
      return { id: index + 1, html: page };
    });

  const docPagesMeta = (pageNodes) ? { blockNodes, backgroundColors, backgroundImages, widgets, dimension, documentType } : { ...documentDetails?.data?.version?.rawdata?.contexts };

  const multiSelectPlaceholder = `Select ${type === TEMPLATE_TYPE_PRESENTATION ? "Slides" : "Pages"}`;
  const multiSelectLabel = type === TEMPLATE_TYPE_PRESENTATION ? "Slides" : "Pages";

  const quality = !formData.quality.disabled ? formData.quality.value.value : "";
  const fileType = formData.type.value.value === "png_transparent" ? "png" : formData.type.value.value;
  const transparent = formData.type.value.value === "png_transparent";

  const actionType = fileType === "pdf" ? "pdf" : ["png", "jpg"].includes(fileType) ? "image" : "media";

  const sendMail = () => {
    const link = `mailto:${SUPPORT_EMAIL}`;
    window.location.href = link;
  };

  const download = () => {
    if (!pageNodes && documentDetails?.data?.id === document?.id && !documentDetails?.data?.version?.rawdata?.contexts) {
      setIsInNewVersion(false);
      setDocumentParsing(true);
      prepareDataForSaveDocument().then(data => {
        saveDocument(data);
      })
    }
    else {
      if (webSocket?.readyState === 1 && isInNewVersion) {
        setDownloadProgress(true);
        initDownload();
      }
    };
  }

  const initDownload = () => {
    const subscribe = {
      type: STREAMING_START,
      companyId,
      entryId: document?.id,
      quality,
      transparent,
      fileType,
      favourites: selectedPages,
      fileName: documentDetails?.data?.document_name,
      actionType,
    };
    webSocket.send(JSON.stringify(subscribe));
  }

  const prepareDataForSaveDocument = async () => {
    setDownloadProgress(true);
    const contexts = await initDocumentParser({ data: documentDetails?.data })
    if (contexts?.pageNodes && availableTemplateGroups) {
      let templateGroupId = documentDetails?.data?.template_info?.group_id;
      const templateType = documentDetails?.data?.template_info?.type?.toLowerCase();
      contexts.documentType = templateType;
      availableTemplateGroups.template_groups.map(availableTemplateGroup => {
        if (availableTemplateGroup.id === templateGroupId)
          contexts.templateGroupName = availableTemplateGroup.slug;
      })
      contexts.pageMargin = contexts.margin;
      delete contexts.margin;
      delete contexts.version;
      const metaData = JSON.parse(JSON.stringify(documentDetails?.data?.version?.rawdata?.metadata));
      metaData.version = process.env.APP_VERSION;
      metaData.metaInfo = {
        template_type_id: documentDetails?.data?.template_info?.type_id,
        template_type: templateType,
      };
      setDownloadProgress(10);
      return {
        metadata: metaData, contexts
      }
    }
  }

  const saveDocument = (data) => {
    if (data && saveDocSocket.readyState === 1) {
      const subscribe = {
        type: DOCUMENT_SAVE,
        companyId: companyId,
        entryId: document?.id,
        rawdata: JSON.stringify(data),
      };
      saveDocSocket.send(JSON.stringify(subscribe));
    }
  }

  const checkDimensions = (pageWidth, pageHeight) =>{
    const disallowedDPI = [];
  
    for (const [dpiName, ratio] of Object.entries(DPI_RATIO)) {
      if (dpiName === 'normal') {
        continue; // Skip the normal DPI check
      }
      const scaledWidth = pageWidth * ratio;
      const scaledHeight = pageHeight * ratio;
  
      if (scaledWidth > ALLOWED_DIMENSION.width || scaledHeight > ALLOWED_DIMENSION.height) {
        disallowedDPI.push(dpiName);
      }
    }
  
    return disallowedDPI;
  }

  const onMessage = useCallback(message => {
    const response = JSON.parse(message?.data);

    if (response.type === STREAMING_FAILED) setDownloadError(!downloadError);

    if (response?.progress && response.type === STREAMING_PROGRESS) {
      if (initDownloadNewVersion) setDownloadProgress(20 + ((response?.progress * 80) / 100));
      else
        setDownloadProgress(response?.progress);
    }

    if (response?.downloadUrl && response.type === STREAMING_SUCCESS)
      setTimeout(() => {
        setDownloadLink(response?.downloadUrl);
      }, 1000);
  }, [initDownloadNewVersion]);

  const onMessageDocSave = useCallback(message => {
    const response = JSON.parse(message?.data);
    if (response?.document_id === document?.id)
      switch (response.type) {
        case DOCUMENT_SAVE_AFTER:
          setDownloadProgress(20);
          setDocumentParsing(false);
          setInitDownloadNewVersion(true);
          break;

        default:
          break;
      }
  }, []);

  useEffect(() => {
    if (webSocket?.readyState === 1) webSocket.addEventListener("message", onMessage);
    if (saveDocSocket?.readyState === 1) saveDocSocket.addEventListener("message", onMessageDocSave);

    return () => {
      webSocket.removeEventListener("message", onMessage);
      saveDocSocket.removeEventListener("message", onMessageDocSave)
    };

  }, [webSocket?.readyState, onMessage, saveDocSocket?.readyState]);

  useEffect(() => {
    if (!documentDetails || documentDetails?.data?.id !== document?.id) dispatch(fetchDocumentDetails(document?.id));
  }, [document]);


  useEffect(() => {
    if (initDownloadNewVersion) initDownload();
  }, [initDownloadNewVersion])

  useEffect(() => {
    if (docPagesMeta?.dimension?.height && docPagesMeta?.dimension?.width) {
      setDisableQuality(checkDimensions(parseFloat(docPagesMeta.dimension.width), parseFloat(docPagesMeta.dimension.height)))
    }
  }, [docPagesMeta?.dimension])

  return (
    <React.Fragment>
      <h4 className={cx(style["fw-7"], style["mb-4"])}>Download</h4>
      <Link to="#" className={cx(style["cross-modal"], style["rounded"], { [style["disable"]]: !isInNewVersion && downloadProgress < 20 })} onClick={_helpers?.modal?.toggle}>
        <Icon icon="ui-close" />
      </Link>
      <div
        className={cx(style["download-select"], style["download-steps"], {
          [style["d-none"]]: downloadError || downloadProgress,
        })}>
        <div className={style["left"]}>
          {pages && <>
          <div className={style["drop-list"]}>
            <div className={style["form-group"]}>
              <h6 className={cx(style["fw-7"])}>File Type</h6>
              <UncontrolledDropdown>
                <DropdownToggle caret tag="a">
                  <span className={cx(style["selected-item"], style["mr-1"])}>{formData.type.value.name}</span>
                  <Icon icon="ui-arrow-down" />
                </DropdownToggle>
                <DropdownMenu tag="div" className={cx(style["shadow"], style["border-0"], style["rounded"])}>
                  <div className={cx(style["customScroll"], style["scroll-Y"])}>
                    {formData.type.options.map(option => (
                      <DropdownItem
                        tag="a"
                        key={option.value}
                        className={cx({ [style["current-item"]]: option.value === formData.type.value.value })}
                        onClick={() => updateForm(option, "type")}>
                        <div className={cx(style["align-items-center"], style["d-flex"])}>
                          <span className={cx(style["mr-2"], style["font-15"])}>{option.name}</span>
                          {option.premium && <img alt="premium" src={Crown} className={cx(style["img-fluid"])} />}
                        </div>
                        <span className={cx(style["text-muted"], style["font-14"])}>
                          {option.details}
                        </span>
                      </DropdownItem>
                    ))}
                  </div>
                </DropdownMenu>

              </UncontrolledDropdown>
            </div>

            {pages?.length > 1 && (
              <div className={cx(style["form-group"], style["mb-0"])}>
                {pages?.length !== 1 && <h6 className={cx(style["fw-7"])}>{multiSelectLabel}</h6>}
                <Input
                  cssModule={style}
                  returnType="formGroup"
                  type="multi_select_page"
                  placeholder={multiSelectPlaceholder}
                  options={pages}
                  optionValue={"id"}
                  optionImage={"image"}
                  updateState={e => setSelectedPages(e)}
                  maxLimit={pages?.length + 1}
                  isContextData={!!pages[0]?.page}
                  docPagesMeta={docPagesMeta}
                />
              </div>
            )}

            <div className={cx(style["form-group"], { [style["disabled"]]: formData.quality.disabled })}>
              <h6 className={cx(style["fw-7"])}>Quality</h6>
              <UncontrolledDropdown disabled={formData.quality.disabled}>
                <DropdownToggle caret tag="a">
                  <span className={cx(style["selected-item"], style["mr-1"])}>{formData.quality.value.name}</span>
                  <Icon icon="ui-arrow-down" />
                </DropdownToggle>
                <DropdownMenu tag="div" className={cx(style["shadow"], style["border-0"], style["rounded"])}>
                  {formData.quality.options.map(option => (
                    <DropdownItem
                      tag="a"
                      key={option.value}
                      className={cx({ [style["current-item"]]: option.value === formData.quality.value.value }, { [style["disable"]]: disableQuality?.includes(option.value) })}
                      onClick={() => updateForm(option, "quality")}>
                      <div className={cx(style["align-items-center"], style["d-flex"])}>
                        <span className={cx(style["mr-2"])}>{option.name}</span>
                        {option.premium && <img alt="premium" src={Crown} className={cx(style["img-fluid"])} />}
                      </div>
                      <span className={cx(style["text-muted"])}>
                        <small>{option.details}</small>
                      </span>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
          <Button
            type="submit"
            color="primary"
            cssModule={style}
            disabled={selectedPages.length <= 0}
            onClick={download}>
            Download
          </Button>
          </>}
          {!pages && 
            <div className={style["drop-list"]}>
              <div className={cx(style["form-group"], style["skeleton-loader-area"], style["align-items-start"], style["flex-column"])}>
                <div className={cx(style["loader-item"], style["w-50"], style["mb-2"])} style={{ borderRadius: "0.25rem" }}></div>
                <div className={cx(style["loader-item"], style["w-100"])} style={{ height: `44px`, borderRadius: "0.25rem" }}></div>
              </div>
              <div className={cx(style["form-group"], style["skeleton-loader-area"], style["align-items-start"], style["flex-column"])}>
                <div className={cx(style["loader-item"], style["w-50"], style["mb-2"])} style={{ borderRadius: "0.25rem" }}></div>
                <div className={cx(style["loader-item"], style["w-100"])} style={{ height: `44px`, borderRadius: "0.25rem" }}></div>
              </div>
            </div>
          }
        </div>
        <div className={style["right"]}>
          <span className={style["download-thumb"]}>
            {pageNodes &&
              <CanvasPageHtmlGenaretor
                figureWidth={210}
                pageNode={pageNodes[0]}
              />}
            {!pageNodes && <img alt={document.document_name} src={document.compiled_thumb} className={style["img-fluid"]} />}
          </span>
        </div>
      </div>

      <div
        className={cx(style["download-link"], style["download-steps"], style["text-center"], {
          [style["d-none"]]: downloadError || !downloadLink,
        })}>
        <img src={Sparkle} width="70" alt="" />
        <h6 className={cx(style["fw-7"], style["pb-1"], style["mt-4"])}>Your document is ready for download!</h6>
        <p className={style["mb-0"]}>
          Your download should have started automatically. If not <br />
          <a href={downloadLink ? downloadLink : undefined} >download here</a>
        </p>
      </div>

      <div
        className={cx(style["download-error"], style["download-steps"], style["text-center"], {
          [style["d-none"]]: !downloadError,
        })}>
        <img src={Exclamation} width="70" alt="" />
        <h6 className={cx(style["fw-7"], style["pb-1"], style["mt-4"])}> We could not download your document!</h6>
        <p className={style["mb-0"]}>
          DocHipo experienced a technical issue. Please try <br />
          after some time. If the issue continues, please <br />
          write to us at{" "}
          <Link to="" onClick={sendMail}>
            {SUPPORT_EMAIL}
          </Link>
        </p>
      </div>

      <div
        className={cx(style["download-loader"], style["download-steps"], style["text-center"], {
          [style["d-none"]]: !downloadProgress || downloadError || downloadLink,
        })}>
        <div className={cx(style["progress"], style["download-progressbar"])}>
          <Progressbar progressbarInfo={downloadProgress} />
        </div>
        <h6 className={cx(style["fw-7"], style["pb-1"], style["mt-4"])}>Please Wait</h6>
        <p className={style["mb-0"]}> Preparing your document for download</p>
      </div>
    </React.Fragment>
  );
};

DownloadModal.propTypes = {
  document: PropTypes.object.isRequired,
  _helpers: PropTypes.object.isRequired,
  setDocumentParsing: PropTypes.func
};

export default DownloadModal;
