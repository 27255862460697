import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import { AppContext } from "../../../contexts";
import global from "../../../scss/dhp.scss";
import useTextFocusOut from "../../../hooks/useTextFocusOut";
import useAlignment from "../../../hooks/useAlignment";
import useAddWidget from "../../../hooks/useAddWidget";
import useElementInnerHtml from "../../../hooks/useElementInnerHtml";
import useDragDropWidget from "../../../hooks/useDragDropWidget";
import { LINE_HEIGHT, TEXT } from "../../../constants/editor";
import { widgetConfig } from "../editor_config";

let style = Object.assign({}, global);

const SingleBrandkitFont = ({ textAssests }) => {
  let { brandInfo } = useContext(AppContext);

  const textAssests2 = textAssests ?? [
    {
      name: "Add Heading",
      value: "h1",
      class: "main-heading",
      type: "heading",
    },
    {
      name: "Add Subheading",
      value: "h2",
      class: "sub-heading",
      type: "subheading",
    },
    {
      name: "Add Body Text",
      value: "normal",
      class: "default-text",
      type: "body_text",
    },
  ];

  const [assetInnerContent, setAssetInnerContent] = useState();
  const [textHeading, setTextHeading] = useState();
  const [textHeadingSize, setTextHeadingSize] = useState();
  const [addWidgetClicked, setAddWidgetClicked] = useState(false);
  const [dataparam, setDataParam] = useState();
  const [customCss, setCustomCss] = useState();
  const [headingSize, setHeadingSize] = useState();
  const [subHeadingSize, setSubHeadingSize] = useState();

  const setTextFocusOut = useTextFocusOut();
  const { postion: getPosition } = useAlignment(
    addWidgetClicked,
    widgetConfig[TEXT]?.[textHeading]?.width,
    textHeadingSize * LINE_HEIGHT,
    "middle-center"
  );
  const getNewWidgetObject = useAddWidget(addWidgetClicked, TEXT, dataparam, getPosition);
  useElementInnerHtml(
    addWidgetClicked,
    setAddWidgetClicked,
    TEXT,
    assetInnerContent,
    dataparam,
    getNewWidgetObject,
    customCss,
    "brandText"
  );
  const { start: initDragDropWidget } = useDragDropWidget();

  const fonts = JSON.parse(localStorage?.getItem("allFonts"));

  const insertText = (headingType, targetDetails) => {
    if (document.querySelector(".dhp-content-editable-true-text")) setTextFocusOut(true);

    setTimeout(() => {
      let index = fonts.findIndex(
        font => font.name.toLowerCase() === targetDetails?.font_family.replace(/["]+/g, "").toLowerCase()
      );
      let dataAttr = { ...widgetConfig[TEXT][headingType].dataAttr, "data-heading": headingType };

      setAssetInnerContent(widgetConfig.texts.content);
      setDataParam(dataAttr);
      setTextHeading(headingType);
      setTextHeadingSize(
        targetDetails?.font_size ? targetDetails?.font_size : widgetConfig[TEXT]?.[headingType]?.height * LINE_HEIGHT
      );

      if (targetDetails)
        setCustomCss({
          "font-size": targetDetails?.font_size + "px",
          "font-family": targetDetails?.font_family,
          "font-weight": targetDetails?.bold
            ? parseInt(fonts[index].bold_weight)
            : parseInt(fonts[index].normal_weight),
          "font-style": targetDetails?.italic ? "italic" : "normal",
        });
      else setCustomCss();
      setAddWidgetClicked(true);
    }, 1);
  };

  const getRelativeFontSize = (baseFontSize, textWidth) => {
    let adjustedFontSize;
    let padding = 32;
    let conteinerWidth = document.getElementById("singleBrandKit")?.getBoundingClientRect().width;
    conteinerWidth = conteinerWidth - padding;

    if (conteinerWidth < textWidth) {
      let widthRatio = conteinerWidth / textWidth; // Calculate the ratio between base width and current width
      adjustedFontSize = baseFontSize * widthRatio; // Calculate the adjusted font size
    } else adjustedFontSize = baseFontSize;

    return adjustedFontSize;
  };

  // set Dynamic font size
  useEffect(() => {
    let textHeadingWidth = document.querySelector("#singleBrandKit.main-heading div")?.getBoundingClientRect().width;
    let textHeadingFontSize = getRelativeFontSize(22, textHeadingWidth);
    setHeadingSize(textHeadingFontSize);
    let textSubHeadingWidth = document.querySelector("#singleBrandKit.sub-heading div")?.getBoundingClientRect().width;
    let textSubHeadingFontSize = getRelativeFontSize(18, textSubHeadingWidth);
    setSubHeadingSize(textSubHeadingFontSize);

    document.querySelectorAll(".singleBrandTexts").forEach(element => {
      element.style.visibility = "visible";
    });
  }, [brandInfo]);

  return (
    <>
      <div>
        <ul className={cx(style["row"], style["row-cols-1"])}>
          {textAssests2.map(textType => (
            <li key={textType.value} className={style["col"]}>
              <div
                id="singleBrandKit"
                className={cx(style["asset-item"], style[textType.class], "singleBrandTexts")}
                style={{
                  visibility: "hidden",
                  whiteSpace: "nowrap",
                  fontFamily: brandInfo?.brandDetails?.[textType.type]?.font_family || "Open Sans",
                  fontWeight: brandInfo?.brandDetails?.[textType.type]?.bold ? 700 : 400,
                  fontStyle: brandInfo?.brandDetails?.[textType.type]?.italic ? "italic" : "normal",
                  fontSize:
                    textType.type === "heading"
                      ? `${headingSize}px`
                      : textType.type === "subheading"
                      ? `${subHeadingSize}px`
                      : "16px",
                }}
                onClick={() => insertText(textType.value, brandInfo?.brandDetails?.[textType.type])}
                onMouseDown={e =>
                  initDragDropWidget(
                    {
                      asset: TEXT,
                      value: textType.value,
                      brandDetails: brandInfo?.brandDetails?.[textType.type],
                    },
                    e
                  )
                }>
                <div>{textType.name}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

//Props type validation
SingleBrandkitFont.propTypes = {
  textAssests: PropTypes.array,
};

export default SingleBrandkitFont;
