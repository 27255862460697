import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, Nav, NavItem, NavLink, TabContent } from "reactstrap";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { widgetConfig } from "../editor_config";
import LoadToolbarComponent from "./LoadToolbarComponent";
import { COLLAGE, SVG, TABLE, UPLOAD, VIDEO } from "../../../constants/editor";

let style = Object.assign({}, global);

const Arrange = props => {
  let { metadata } = useContext(EditorContext);
  let isGroupWidget = document.getElementById(metadata.activeWidgetId[0])?.closest(".dhp-page-group");

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [arrangeContextualMenubarList, setArrangeContextualMenubarList] = useState(null);

  const iconClassName = {
    Layer: "bring-front",
    Align: "left-align",
    Rotate: "rotate-right",
  };

  const toggleDropDown = () => {
    setDropdownOpen(prevState => !prevState);
  };

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const setDropdownMenu = () => {
    if (props.more) props?.setmoreSecondaryOptionClicked("Arrange");
  };

  useEffect(() => {
    if (metadata.activeWidgetId.length === 1) {
      if (
        metadata.activeWidgetType[0] === UPLOAD &&
        document.getElementById(metadata.activeWidgetId[0])?.dataset?.fileType === SVG
      ) {
        setArrangeContextualMenubarList(widgetConfig[metadata.activeWidgetType[0]][SVG]?.contextualMenu?.arrange);
      } else if (metadata.activeWidgetType[0] === VIDEO) {
        setArrangeContextualMenubarList(
          widgetConfig[metadata.activeWidgetType[0]][
            document.getElementById(metadata.activeWidgetId[0])?.dataset?.source
          ]?.contextualMenu?.arrange
        );
      } else if (metadata.activeWidgetType[0] === TABLE && isGroupWidget) {
        setArrangeContextualMenubarList(widgetConfig.shift?.contextualMenu?.arrange);
      } else {
        setArrangeContextualMenubarList(widgetConfig[metadata.activeWidgetType[0]]?.contextualMenu?.arrange);
      }
    } else {
      if (metadata.activeWidgetType.includes(COLLAGE))
        setArrangeContextualMenubarList(widgetConfig.shift?.contextualMenu?.arrange.filter(type => type !== "Rotate"));
      else setArrangeContextualMenubarList(widgetConfig.shift?.contextualMenu?.arrange);
    }

    return () => setArrangeContextualMenubarList(null);
  }, [metadata.activeWidgetId]);

  return (
    <li className={style["toolset-group"]}>
      <UncontrolledDropdown
        className={cx(style["toolset-item"], style["arrange-wrap"])}
        isOpen={dropdownOpen}
        toggle={toggleDropDown}>
        <DropdownToggle
          tag="a"
          className={cx(style["toolset-action"], style["custom-tooltip"])}
          onClick={setDropdownMenu}>
          <Icon icon="ui-bring-front" />

          {!props.more && <div className={cx(style["custom-tooltip-content"], style["top"])}>Arrange</div>}

          {props.more && "Arrange"}
        </DropdownToggle>

        {!props.more && (
          <DropdownMenu className={cx(style["shadow"], style["border-0"], style["rounded"])}>
            <Nav pills>
              {arrangeContextualMenubarList?.map((contextMenue, idx) => (
                <NavItem key={idx}>
                  <NavLink className={cx({ active: activeTab === idx })} onClick={() => toggle(idx)}>
                    <Icon icon={`ui-${iconClassName[contextMenue]}`} />
                    {contextMenue}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>

            <TabContent activeTab={activeTab} className={style["mt-3"]}>
              {arrangeContextualMenubarList?.map((contextMenue, idx) => (
                <LoadToolbarComponent
                  key={idx}
                  component={contextMenue}
                  idx={idx}
                  setDropdownOpen={setDropdownOpen}
                  dropdownOpen={dropdownOpen}
                />
              ))}
            </TabContent>
          </DropdownMenu>
        )}
      </UncontrolledDropdown>
    </li>
  );
};
Arrange.propTypes = {
  more: PropTypes.bool,
  setmoreSecondaryOptionClicked: PropTypes.func,
};

export default Arrange;
