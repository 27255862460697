import React from "react";
import PropTypes from "prop-types";
import { RMasonry as Masonry } from "../masonry";
import { getRandomNumberInRange } from "../../../_helpers/utils";
import global from "../../../scss/dhp.scss";

let style = Object.assign({}, global);

const Item = ({ module, viewType }) => {
  const config = {
    documents: {
      min: 150,
      max: 320,
    },
    templates: {
      min: 150,
      max: 320,
    },
    editor_assets: {
      min: 75,
      max: 180,
    },
    facebook_images: {
      min: 75,
      max: 120,
    },
    default: {
      min: 100,
      max: 250,
    }
  };

  const view = config[module] ?? config.default;
  const height = viewType === "tile" ? 277 : getRandomNumberInRange(view.min, view.max);

  return (
    <>
      {["documents", "templates"].includes(module) && (
        <div className={style["skeleton-loader-area"]} style={{ height: `${height}px` }}>
          <span className={style["loader-item"]}></span>
        </div>
      )}
      {["editor_assets", "facebook_images"].includes(module) && (
        <div className={style["skeleton-loader-area"]}>
          <span className={style["loader-item"]} style={{ height: `${height}px` }}></span>
        </div>
      )}
    </>
  );
};

export const MasonryLoader = ({ module, type, count }) => {
  return (
    <React.Fragment>
      {type == "masonry" && (
        <Masonry module={module}>
          {[...Array(count || 25)].map((_, i) => (
            <Item key={i} module={module} />
          ))}
        </Masonry>
      )}
      {type == "tile" && (
        <Masonry module={module}>
          {[...Array(count || 25)].map((_, i) => (
            <Item key={i} module={module} viewType="tile" />
          ))}
        </Masonry>
      )}
      {type == "item" && <Item module={module} />}
      {type == "tile-item" && <Item module={module} viewType="tile" />}
    </React.Fragment>
  );
};

Item.propTypes = {
  module: PropTypes.string.isRequired,
  viewType: PropTypes.string
};

MasonryLoader.propTypes = {
  module: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  count: PropTypes.number,
};
