import { useContext } from "react";
import {
  WIDGET_HANDLER_MARGIN_LEFT,
  WIDGET_HANDLER_MARGIN_TOP,
  GENERIC_EDITOR_WRAP_PADDING,
  TYPE_INFOGRAPHIC,
} from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";
import {
  getCssTransformObj,
  getZoomedValue,
  getWidgetAndParentsDomReference,
  getStringToValidJSON,
} from "../_helpers/utils";

const useWidgetHighlighter = () => {
  const { metadata, dimension, documentType, isTimeLineViewOpen } = useContext(EditorContext);

  const WHT = {
    meta: false,

    getHandler: () => {
      return document.getElementById("dhp-widget-highlighter");
    },

    show: ({ hoverWidgetId, activeWidgetId = metadata.activeWidgetId || [], zoom = dimension.zoom }) => {
      // checking if any of widget events are active
      const isWidgetEventActive = localStorage.getItem("widget.event.isActive") === "true";
      const { isActive: isCollageEventActive = false } = getStringToValidJSON(
        localStorage.getItem("collage.event.isActive")
      ) ?? {
        isActive: false,
      };

      // checking if active widget (in-case of single selection) is a child of a group widget
      const { widget: { isChildWidget = false, parent: { id: parentId } = {} } = {} } =
        activeWidgetId.length === 1 && getWidgetAndParentsDomReference(activeWidgetId[0]);

      // if so then considering the root widget (extreme parent) as the activeWidgetId
      if (isChildWidget) activeWidgetId = [parentId];

      // restrict widget highlighter when performing widget events (like drag, resize, rotate, collage double click) and if hovered widget and active widget(s) are same (considering both single selection and multi-selection)
      if (isWidgetEventActive || isCollageEventActive || activeWidgetId.includes(hoverWidgetId)) return;

      const handler = WHT.getHandler();
      const {
        widget: { css: widgetStyle },
        block: {
          rect: { top: activeBlockTop },
        },
        editorOuterWrapTop,
      } = getWidgetAndParentsDomReference(hoverWidgetId);

      const cssObj = {
        width: widgetStyle.width,
        height: widgetStyle.height,
        transform: widgetStyle.transform,
      };

      const {
        translate: { x: widgetTransX, y: widgetTransY },
      } = getCssTransformObj({
        transform: cssObj.transform,
      });

      const widgetTransformStr = getCssTransformObj({
        translateX: getZoomedValue(widgetTransX, zoom) + "px",
        translateY: getZoomedValue(widgetTransY, zoom) + "px",
        transform: cssObj.transform,
        returnStr: true,
      });

      const addedLeft = WIDGET_HANDLER_MARGIN_LEFT + GENERIC_EDITOR_WRAP_PADDING;
      const addedTop = WIDGET_HANDLER_MARGIN_TOP + GENERIC_EDITOR_WRAP_PADDING;
      const activePageTop = isTimeLineViewOpen ? 0 : Math.abs(activeBlockTop - editorOuterWrapTop);

      handler.classList.remove("d-none");
      handler.setAttribute("data-widget-id", hoverWidgetId);
      handler.style.cssText = `
        opacity: 1;
        display: block;
        width: ${getZoomedValue(cssObj.width, zoom)}px;
        height: ${getZoomedValue(cssObj.height, zoom)}px;
        transform: ${widgetTransformStr};
        left: ${documentType === TYPE_INFOGRAPHIC ? getZoomedValue(addedLeft, zoom) : addedLeft}px;
        top: ${activePageTop + (documentType === TYPE_INFOGRAPHIC ? getZoomedValue(addedTop, zoom) : addedTop)}px;
      `;
    },

    hide: () => {
      const handler = WHT.getHandler();
      handler.classList.add("d-none");
      handler.style.cssText = `
        opacity: 0;
      `;
    },
  };

  return {
    show: WHT.show,
    hide: WHT.hide,
  };
};

export default useWidgetHighlighter;
