import React, { useContext } from "react";

import global from "../../scss/dhp.scss";

import { EditorContext } from "./EditorLayout";

let style = Object.assign({}, global);

const EditorMapInfoTooltip = () => {
  let { mapTooltip } = useContext(EditorContext);

  return (
    <div style={mapTooltip.style}>
      <strong className={style["d-block"]}>{mapTooltip.content.areaTitle}</strong>
      <ul>
        {Object.entries(mapTooltip.content.tableCaptionObj).map(([key, value]) => {
          // Replacing underscores with spaces
          let formattedKey = key.replace(/_/g, " ");
          return (
            <li key={key}>
              {formattedKey}: {value}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default EditorMapInfoTooltip;
