import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";

import global from "../../scss/dhp.scss";

import { EditorContext } from "./EditorLayout";
import { widgetConfig } from "../../components/Editor/editor_config";
import { GIF, GROUP_WIDGET, LOCK, SVG, TEXT, TEXT_FRAME, UPLOAD, VIDEO } from "../../constants/editor";
import components from "../../components/Editor/ContextualMenu/contextMenuIndex";
import { isOnlyTextInGroup } from "../../_helpers/utils";

let style = Object.assign({}, global);

const ContextualMenubar = props => {
  let { metadata, widgets, doubleClickActive, handleWidgetAction } = useContext(EditorContext);

  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
  let widgetLockStatus = widgets[targetWidgetIndex]?.data["data-layer-locked"];

  const [contextualMenuObj, setSontextualMenuObj] = useState();

  // set contextual menu bar list according active widet type
  useLayoutEffect(() => {
    //If widget is not locked, default contextual option will shown
    if (!widgetLockStatus) {
      // context menu for single widget
      if (metadata.activeWidgetId.length === 1) {
        let fileType = document.getElementById(id)?.dataset?.fileType;
        let isOnlyTextInGroupVar = isGroupWidget ? isOnlyTextInGroup(metadata.activeWidgetType[0], id) : true;
        let contextMenuList;

        if (metadata.activeWidgetType[0] === UPLOAD && (fileType === SVG || fileType === GIF)) {
          contextMenuList = widgetConfig[metadata.activeWidgetType[0]][fileType]?.contextualMenu?.topLevel?.map(
            item => ({
              name: components[item],
            })
          );
        } else if (metadata.activeWidgetType[0] === VIDEO) {
          contextMenuList = widgetConfig[metadata.activeWidgetType[0]][
            document.getElementById(id)?.dataset?.source
          ]?.contextualMenu?.topLevel?.map(item => ({ name: components[item] }));
        } else if (metadata.activeWidgetType[0] === TEXT && isGroupWidget && !isOnlyTextInGroupVar) {
          contextMenuList = [
            { name: components["ClipBoard"] },
            { name: components["Color"] },
            { name: components["Italic"] },
            { name: components["Underline"] },
            { name: components["TextAlign"] },
            { name: components["Effect"] },
            { name: components["Animate"] },
            { name: components["Strickthrough"] },
            { name: components["Opacity"] },
            { name: components["Link"] },
            { name: components["Arrange"] },
            { name: components[LOCK] },
            { name: components["Delete"] },
          ];
        } else if (
          metadata.activeWidgetType[0] === TEXT_FRAME &&
          parseInt(document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-version")) < 3
        ) {
          // remove color option for old text frame
          let contextMenuForOldTextFrame = widgetConfig[metadata.activeWidgetType[0]]?.contextualMenu?.topLevel.filter(
            item => item !== "Color"
          );
          contextMenuList = contextMenuForOldTextFrame?.map(item => ({
            name: components[item],
          }));
        } else {
          contextMenuList = widgetConfig[metadata.activeWidgetType[0]]?.contextualMenu?.topLevel?.map(item => ({
            name: components[item],
          }));
        }

        // if group inner widget is selcted
        if (isGroupWidget && metadata.activeWidgetType[0] !== GROUP_WIDGET) {
          contextMenuList = [{ name: components["UnGroup"] }, ...contextMenuList]; // Add ungroup option in context menu if group inner widget is selcted
        }

        setSontextualMenuObj(contextMenuList);
      }
      // context menu for multiple widget
      else {
        let newContextMenu;
        if (metadata.activeWidgetType.includes("groups")) newContextMenu = widgetConfig.shift.contextualMenu.topLevel;
        else newContextMenu = widgetConfig.shift.contextualMenu.topLevel.filter(menu => menu !== "UnGroup"); // remove ungroup option from menu if there is no group widgetselcted

        setSontextualMenuObj(newContextMenu.map(item => ({ name: components[item] })));
      }
    }
    //If widget locked, only lock contextual option will shown
    else {
      setSontextualMenuObj([{ name: components[LOCK] }]);
    }

    // back to main left pain from widget animation if locked widget is active
    if (
      document.querySelector(".editor-asset-inner.widgetanimation-asset") &&
      metadata.activeWidgetId.length === 1 &&
      widgetLockStatus
    ) {
      handleWidgetAction("*", false, false, false, "returnToWidgetList");
    }

    return () => setSontextualMenuObj(null);
  }, [metadata.activeWidgetId, widgetLockStatus]);

  // add more contextual menu in text-frame double click
  useEffect(() => {
    if (
      doubleClickActive &&
      metadata.activeWidgetType[0] === TEXT_FRAME &&
      parseInt(document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-version")) < 3
    ) {
      let newList = [...widgetConfig[metadata.activeWidgetType[0]]?.contextualMenu?.topLevel];
      newList.splice(1, 0, "FontSize");

      setSontextualMenuObj(newList?.map(item => ({ name: components[item] })));
    }
  }, [doubleClickActive]);

  return (
    <>
      <ul className={style["toolbar-group"]} id="editor-toolbar">
        {contextualMenuObj?.length > 0 &&
          contextualMenuObj?.map((item, i) => <item.name key={i} toggle={props.toggle} />)}
      </ul>
    </>
  );
};
ContextualMenubar.propTypes = {
  toggle: PropTypes.func,
};

export default ContextualMenubar;
