import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { fetchAllCustomFonts, updateCustomFonts } from '../store/actions/resourcesActions';
import { injectFonts } from '../_helpers/utils';

const useCustomFonts = () => {
    const dispatch = useDispatch();
    const customFonts = useSelector(state => state?.resources?.custom_fonts?.all?.fonts);
    const companyId = useSelector(state => state.auth?.user?.company?.id) ?? useParams()?.companyId;
    
    const handleStorageChange = (e) => {
        if (e.key === 'allFonts') {
            const updatedFonts = JSON.parse(localStorage.getItem('allFonts'))?.filter(font => font?.source === "Custom") ?? [];
            dispatch(updateCustomFonts(updatedFonts));
        }
    };

    useEffect(() => {
        if (customFonts) injectFonts(customFonts.filter(font => font?.enabled))
        if (!customFonts && companyId) dispatch(fetchAllCustomFonts(companyId));

        // Listen for storage events
        window.addEventListener('storage', handleStorageChange);

        return () => {
            // Cleanup: Remove the event listener
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [companyId, customFonts]);
}


export default useCustomFonts