import React, { useContext } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import {
  ANIMATION,
  ICON,
  ILLUSTRATION,
  LINE,
  PICTURE,
  QR_CODE,
  SHAPE,
  STICKER,
  STOCK,
  SVG,
  TEXT,
  TYPE_FORM,
  JOT_FORM,
  UPLOAD,
  VIDEO,
  COLLAGE,
  TEXT_FRAME,
  BRAND,
  UPLOAD_VIDEO,
} from "../../../constants/editor";
import { hexToRGB, RGBToHex } from "../../../_helpers/utils";

let style = Object.assign({}, global);

const CopyStyle = props => {
  let { metadata, widgets, copyStyle, updateCopyStyle } = useContext(EditorContext);

  let isGroupWidget = document.getElementById(metadata.activeWidgetId[0])?.closest(".dhp-page-group");
  let colorWidgetIncludedList = [TEXT, LINE, SHAPE, ICON, ILLUSTRATION, STICKER, UPLOAD, QR_CODE, TEXT_FRAME];

  const copyCurrentWidgetStyle = () => {
    let targetWidgetIndex = widgets.findIndex(widget => widget.id === metadata.activeWidgetId[0]);

    document.getElementById("canvas-panel-area").classList.add("cursor-copy-style");

    let currentElem = document.getElementById(metadata.activeWidgetId[0]);
    let flipableAvailable =
      metadata.activeWidgetType[0] === PICTURE ||
      metadata.activeWidgetType[0] === ANIMATION ||
      (metadata.activeWidgetType[0] === VIDEO && [STOCK, BRAND, UPLOAD_VIDEO].includes(currentElem.dataset.source)) ||
      (metadata.activeWidgetType[0] === UPLOAD && currentElem.dataset.fileType !== SVG);
    let currentWidgetStyle = flipableAvailable
      ? {
          filter: currentElem.querySelector(" .dhp-widget-inner").childNodes[0].style.filter,
          shadow: currentElem.querySelector(" .flippable").style.filter,
          borderStyle: currentElem.querySelector(" .flippable").style.borderStyle,
          borderWidth: currentElem.querySelector(" .flippable").style.borderWidth,
          borderRadius: currentElem.querySelector(" .flippable").style.borderRadius,
          borderColor: currentElem.querySelector(" .flippable").style.borderColor,
        }
      : [TYPE_FORM, JOT_FORM].includes(metadata.activeWidgetType[0])
      ? {
          shadow: currentElem.querySelector(" .dhp-widget-inner").style.filter,
          borderStyle: currentElem.querySelector(" .dhp-widget-inner").childNodes[0].style.borderStyle,
          borderWidth: currentElem.querySelector(" .dhp-widget-inner").childNodes[0].style.borderWidth,
          borderRadius: currentElem.querySelector(" .dhp-widget-inner").childNodes[0].style.borderRadius,
          borderColor: currentElem.querySelector(" .dhp-widget-inner").childNodes[0].style.borderColor,
        }
      : metadata.activeWidgetType[0] === SHAPE &&
        ["Circles", "Triangles", "Basic"].includes(currentElem.dataset.category) &&
        parseInt(currentElem.dataset.version) >= 3
      ? {
          shadow: currentElem.querySelector(" .dhp-widget-inner").style.filter,
          borderStyle: currentElem.dataset.borderStyle ? currentElem.dataset.borderStyle : "none",
          borderWidth: currentElem.dataset.borderWidth,
          borderRadius: 0,
          borderColor: currentElem.dataset.borderColor,
        }
      : metadata.activeWidgetType[0] === SHAPE &&
        ["Circles", "Triangles", "Basic", "Rectangles"].includes(currentElem.dataset.category) &&
        parseInt(currentElem.dataset.version) < 3
      ? {
          shadow: currentElem.querySelector(" .dhp-widget-inner").style.filter,
        }
      : metadata.activeWidgetType[0] === COLLAGE
      ? {
          filter: currentElem.querySelector(".collage")?.style.filter,
          shadow: currentElem.querySelector(".dhp-widget-inner").style.filter,
        }
      : currentElem.querySelector(" .dhp-widget-inner").style;
    let currentWidgetCssText = currentWidgetStyle.cssText;
    let multiColorDataClassSet = [];
    let multiColorSet = [];
    let multicolorClassSet = [];

    // create color array
    if (colorWidgetIncludedList.includes(currentElem.dataset.assetType)) {
      if (
        metadata.activeWidgetType[0] === ILLUSTRATION ||
        metadata.activeWidgetType[0] === STICKER ||
        metadata.activeWidgetType[0] === ICON ||
        (metadata.activeWidgetType[0] === TEXT_FRAME &&
          parseInt(document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-version")) >= 3) ||
        (metadata.activeWidgetType[0] === UPLOAD && currentElem.dataset?.fileType === SVG)
      ) {
        currentElem.querySelectorAll(` svg *`).forEach(function (node) {
          let dataClassName = node.dataset.class;

          if (node.classList.value !== "") multicolorClassSet.push(node.classList.value);

          if (dataClassName) {
            let currentIconColor =
              window.getComputedStyle(node).fill === "rgba(0, 0, 0, 0)"
                ? "transparent"
                : RGBToHex(window.getComputedStyle(node).fill);

            if (!multiColorDataClassSet?.includes(dataClassName) && currentIconColor !== "") {
              multiColorDataClassSet.push(dataClassName);
              multiColorSet.push(hexToRGB(currentIconColor));
            }
          }
        });

        // //Manage Color for mono color Uploaded svg
        if (
          multiColorSet.length === 0 &&
          multicolorClassSet.length === 0 &&
          (metadata.activeWidgetType[0] === UPLOAD || metadata.activeWidgetType[0] === ICON)
        ) {
          let monoColor = currentElem.querySelector(` svg`)?.style?.fill;
          multiColorSet.push(monoColor);
        }
      }
      //Fetch color for Text widget
      else if (metadata.activeWidgetType[0] === TEXT) {
        multiColorSet.push(currentWidgetStyle.color);
      }
      //Fetch color for QR Code
      else if (metadata.activeWidgetType[0] === QR_CODE) {
        currentElem.querySelectorAll(` svg *`).forEach(function (node) {
          if (node.nodeName !== "title") {
            let currentIconColor =
              window.getComputedStyle(node).fill === "rgba(0, 0, 0, 0)"
                ? "transparent"
                : RGBToHex(window.getComputedStyle(node).fill);

            multiColorSet.push(hexToRGB(currentIconColor));
          }
        });
      }
      //Fetch color for mono svg
      else if (metadata.activeWidgetType[0] !== UPLOAD) {
        multiColorSet.push(currentElem.querySelector(` svg`).style.fill);
      }
    }

    updateCopyStyle({
      ...copyStyle,
      enable: true,
      widgetType: metadata.activeWidgetType[0],
      style: currentWidgetStyle,
      cssText: currentWidgetCssText,
      opacity: currentElem.querySelector(" .dhp-widget-inner").style.opacity,
      dataset: {
        ...widgets[targetWidgetIndex].data,
        ...currentElem.querySelector(" .dhp-widget-inner").dataset,
        ...currentElem.querySelector(" .dhp-widget-inner").childNodes[0]?.dataset,
        ...currentElem.querySelector(" .media-parent")?.childNodes[0]?.dataset,
      },
      color: multiColorSet,
    });
  };

  return (
    <li
      className={cx(style["toolset-group"], {
        [style["disabled"]]: isGroupWidget || widgets.length <= 1,
      })}>
      <div className={cx(style["toolset-item"], style["copy-style-wrap"])}>
        <span className={cx(style["toolset-action"], style["custom-tooltip"])} onClick={copyCurrentWidgetStyle}>
          <Icon icon="ui-copy-style" />
          {!props.more && <div className={cx(style["custom-tooltip-content"], style["top"])}>Copy Style</div>}
          {props.more && "Copy Style"}
        </span>
      </div>
    </li>
  );
};
CopyStyle.propTypes = {
  more: PropTypes.bool,
};

export default CopyStyle;
