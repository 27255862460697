import { useContext } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";
import { getCssTransformObj } from "../_helpers/utils";

const UseCheckWidgetAllignment = () => {
  let { metadata, widgets, dimension } = useContext(EditorContext);

  const checkWidgetAllignmentForSingleWidget = (height, transform, width, id) => {
    let zoomval = 100 / parseFloat(dimension.zoom);
    let parentPos = document.getElementById(metadata.activeBlockId).getBoundingClientRect();
    let isGroupWidget = document.getElementById(metadata.activeWidgetId[0])?.closest(".dhp-page-group");
    let activeWidgetId = id
      ? id
      : isGroupWidget
      ? document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-root-widget").getAttribute("id")
      : metadata.activeWidgetId[0];
    let childPosition = document.getElementById(activeWidgetId).getBoundingClientRect();
    let targetWidgetIndex = widgets.findIndex(widget => widget.id === activeWidgetId);
    let widgetTransform = transform || widgets[targetWidgetIndex].style.transform;
    let widgettranslateValue = getCssTransformObj({ transform: widgetTransform, exclude: ["rotate", "scale"] });
    let widgetLeftValue = parseFloat(widgettranslateValue.translate.x);
    let widgetTopValue = parseFloat(widgettranslateValue.translate.y);
    let widgetHeightValue = height ?? widgets[targetWidgetIndex].style.height;
    let widgetWidthValue = width ?? widgets[targetWidgetIndex].style.width;
    let horaizentalAllignment = [];
    let verticalAllignment = [];

    if (parseInt(parentPos.left) === parseInt(childPosition.left)) horaizentalAllignment.push("left");
    if (parseInt(widgetLeftValue) === parseInt((parentPos.width / 2) * zoomval - parseFloat(widgetWidthValue) / 2))
      horaizentalAllignment.push("center");
    if (parseInt(parentPos.right) === parseInt(childPosition.right)) horaizentalAllignment.push("right");

    if (parseInt(parentPos.top) === parseInt(childPosition.top)) verticalAllignment.push("top");
    if (widgetTopValue === (parentPos.height / 2) * zoomval - parseFloat(widgetHeightValue / 2))
      verticalAllignment.push("middle");
    if (parseInt(parentPos.bottom) === parseInt(childPosition.bottom)) verticalAllignment.push("bottom");

    let x_al =
      horaizentalAllignment.length === 0
        ? false
        : horaizentalAllignment.length === 3
        ? "tripple"
        : horaizentalAllignment[0];
    let y_al =
      verticalAllignment.length === 0 ? false : verticalAllignment.length === 3 ? "tripple" : verticalAllignment[0];

    return { x_al, y_al };
  };

  const checkWidgetAllignmentForMultiSelectWidget = () => {
    let activeWidgetIndexArray = [];
    let multiWidgetAllignStatus = [];
    let allLeft = true,
      allTop = true,
      allRight = true,
      allBottom = true,
      allCenter = true,
      allMiddle = true;

    // create acive widget index array
    metadata.activeWidgetId.forEach(id => {
      let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
      let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
      let widgetIndex = widgets.findIndex(widget => widget.id === targetId);
      activeWidgetIndexArray.push(widgetIndex);
    });

    let widgetRect = document.getElementById(metadata.activeWidgetId[0]).getBoundingClientRect();
    let l = parseFloat(widgetRect.left);
    let t = parseFloat(widgetRect.top);
    let r = parseFloat(widgetRect.right);
    let b = parseFloat(widgetRect.bottom);
    let c = l + parseFloat(widgetRect.width) / 2;
    let m = t + parseFloat(widgetRect.height) / 2;

    activeWidgetIndexArray.forEach(value => {
      let currentWidgetRect = document.getElementById(widgets[value].id).getBoundingClientRect();
      let widgetLeft = parseFloat(currentWidgetRect.left);
      let widgetTop = parseFloat(currentWidgetRect.top);
      let widgetRight = parseFloat(currentWidgetRect.right);
      let widgetBottom = parseFloat(currentWidgetRect.bottom);
      let widgetCenter = widgetLeft + parseFloat(currentWidgetRect.width) / 2;
      let widgetMiddle = widgetTop + parseFloat(currentWidgetRect.height) / 2;

      if (widgetLeft.toFixed(1) !== l.toFixed(1) && allLeft) allLeft = false;
      if (widgetTop.toFixed(1) !== t.toFixed(1) && allTop) allTop = false;
      if (widgetRight.toFixed(1) !== r.toFixed(1) && allRight) allRight = false;
      if (widgetBottom.toFixed(1) !== b.toFixed(1) && allBottom) allBottom = false;
      if (widgetCenter.toFixed(1) !== c.toFixed(1) && allCenter) allCenter = false;
      if (widgetMiddle.toFixed(1) !== m.toFixed(1) && allMiddle) allMiddle = false;
    });

    if (allLeft) multiWidgetAllignStatus.push("left");
    if (allTop) multiWidgetAllignStatus.push("top");
    if (allRight) multiWidgetAllignStatus.push("right");
    if (allBottom) multiWidgetAllignStatus.push("bottom");
    if (allCenter) multiWidgetAllignStatus.push("center");
    if (allMiddle) multiWidgetAllignStatus.push("middle");

    return multiWidgetAllignStatus;
  };

  return { checkWidgetAllignmentForSingleWidget, checkWidgetAllignmentForMultiSelectWidget };
};

export default UseCheckWidgetAllignment;
