import {
  DOCUMENT_FETCH_START,
  DOCUMENT_FETCH_SUCCESS,
  DOCUMENT_FETCH_FAILED,
  DOCUMENT_UPDATE_START,
  DOCUMENT_UPDATE_SUCCESS,
  DOCUMENT_UPDATE_FAILED,
  DOCUMENT_CLONE_START,
  DOCUMENT_CLONE_SUCCESS,
  DOCUMENT_CLONE_FAILED,
  DOCUMENT_SHARE_START,
  DOCUMENT_SHARE_SUCCESS,
  DOCUMENT_SHARE_FAILED,
  DOCUMENT_SHARE_UPDATE_PERMISSION_START,
  DOCUMENT_SHARE_UPDATE_PERMISSION_SUCCESS,
  DOCUMENT_SHARE_UPDATE_PERMISSION_FAILED,
  DOCUMENT_SHARE_DELETE_USER_START,
  DOCUMENT_SHARE_DELETE_USER_SUCCESS,
  DOCUMENT_SHARE_DELETE_USER_FAILED,
  DOCUMENT_SHARE_INFO_START,
  DOCUMENT_SHARE_INFO_SUCCESS,
  DOCUMENT_SHARE_INFO_FAILED,
  DOCUMENT_PUBLISH_START,
  DOCUMENT_PUBLISH_SUCCESS,
  DOCUMENT_PUBLISH_FAILED,
  DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_START,
  DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_SUCCESS,
  DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_FAILED,
  DOCUMENT_PUBLISH_SET_PASSWORD_START,
  DOCUMENT_PUBLISH_SET_PASSWORD_SUCCESS,
  DOCUMENT_PUBLISH_SET_PASSWORD_FAILED,
  DOCUMENT_PUBLISH_UNSET_PASSWORD_START,
  DOCUMENT_PUBLISH_UNSET_PASSWORD_SUCCESS,
  DOCUMENT_PUBLISH_UNSET_PASSWORD_FAILED,
  DOCUMENT_UNPUBLISH_START,
  DOCUMENT_UNPUBLISH_SUCCESS,
  DOCUMENT_UNPUBLISH_FAILED,
  DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_START,
  DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_SUCCESS,
  DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_FAILED,
  DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_INFO_START,
  DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_INFO_SUCCESS,
  DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_INFO_FAILED,
  DOCUMENT_TRANSFER_START,
  DOCUMENT_TRANSFER_SUCCESS,
  DOCUMENT_TRANSFER_FAILED,
  DOCUMENT_DELETE_START,
  DOCUMENT_DELETE_SUCCESS,
  DOCUMENT_DELETE_FAILED,
  DOCUMENT_DETAILS_FETCH_START,
  DOCUMENT_DETAILS_FETCH_SUCCESS,
  DOCUMENT_DETAILS_FETCH_FAILED,
  DOCUMENT_COMMENTS_START,
  DOCUMENT_COMMENTS_FAILED,
  DOCUMENT_COMMENTS_SUCCESS,
  INSERT_COMMENTS_START,
  INSERT_COMMENTS_FAILED,
  INSERT_COMMENTS_SUCCESS,
  APPLY_LIKE_START,
  APPLY_LIKE_FAILED,
  APPLY_LIKE_SUCCESS,
  UPDATE_COMMENT_START,
  UPDATE_COMMENT_FAILED,
  UPDATE_COMMENT_SUCCESS,
  DELETE_COMMENT_START,
  DELETE_COMMENT_FAILED,
  DELETE_COMMENT_SUCCESS,
  FETCH_DOCUMENT_VERSION_START,
  FETCH_DOCUMENT_VERSION_SUCCESS,
  FETCH_DOCUMENT_VERSION_FAILED,
  RESTORE_DOCUMENT_VERSION_FAILED,
  RESTORE_DOCUMENT_VERSION_SUCCESS,
  RESTORE_DOCUMENT_VERSION_START,
  UPDATE_DOCUMENT_VERSION_START,
  UPDATE_DOCUMENT_VERSION_FAILED,
  UPDATE_DOCUMENT_VERSION_SUCCESS,
  PUBLISHED_DOCUMENT_FETCH_START,
  PUBLISHED_DOCUMENT_FETCH_SUCCESS,
  PUBLISHED_DOCUMENT_FETCH_FAILED,
  DOCUMENT_INSERT_START,
  DOCUMENT_INSERT_SUCCESS,
  DOCUMENT_INSERT_FAILED,
  FETCH_DOCUMENT_VERSION_INFO_START,
  FETCH_DOCUMENT_VERSION_INFO_SUCCESS,
  FETCH_DOCUMENT_VERSION_INFO_FAILED,
  CHECK_DOCUMENT_CREATE_PERMISSION_START,
  CHECK_DOCUMENT_CREATE_PERMISSION_SUCCESS,
  CHECK_DOCUMENT_CREATE_PERMISSION_FAILED,
  CREATE_DOCUMENT_VERSION_START,
  CREATE_DOCUMENT_VERSION_SUCCESS,
  CREATE_DOCUMENT_VERSION_FAILED,
  GENERATE_DOCUMENT_THUMB_START,
  GENERATE_DOCUMENT_THUMB_SUCCESS,
  GENERATE_DOCUMENT_THUMB_FAILED,
  UPDATE_DOCUMENT_REDUCER,
  SEND_DOCUMENT_EMAIL_START,
  SEND_DOCUMENT_EMAIL_SUCCESS,
  SEND_DOCUMENT_EMAIL_FAILED,
  FETCH_SENT_EMAIL_LIST_START,
  FETCH_SENT_EMAIL_LIST_SUCCESS,
  FETCH_SENT_EMAIL_LIST_FAILED,
  PUBLISH_COMPANY_TEMPLATE_START,
  PUBLISH_COMPANY_TEMPLATE_SUCCESS,
  PUBLISH_COMPANY_TEMPLATE_FAILED,
  PUBLISH_COMPANY_TEMPLATE_INFO_START,
  PUBLISH_COMPANY_TEMPLATE_INFO_SUCCESS,
  PUBLISH_COMPANY_TEMPLATE_INFO_FAILED,
  FETCH_SAVED_FILTER_START,
  FETCH_SAVED_FILTER_SUCCESS,
  FETCH_SAVED_FILTER_FAILED,
  DELETE_SAVED_FILTER_START,
  DELETE_SAVED_FILTER_SUCCESS,
  DELETE_SAVED_FILTER_FAILED,
  RENAME_SAVED_FILTER_START,
  RENAME_SAVED_FILTER_SUCCESS,
  RENAME_SAVED_FILTER_FAILED,
  FETCH_APPLIED_FILTER_DOCUMENT_LIST_START,
  FETCH_APPLIED_FILTER_DOCUMENT_LIST_SUCCESS,
  FETCH_APPLIED_FILTER_DOCUMENT_LIST_FAILED,
  FETCH_APPLY_FILTER_DOCUMENT_START,
  FETCH_APPLY_FILTER_DOCUMENT_SUCCESS,
  FETCH_APPLY_FILTER_DOCUMENT_FAILED,
  FETCH_FILTER_RECORDS_START,
  FETCH_FILTER_RECORDS_SUCCESS,
  FETCH_FILTER_RECORDS_FAILED,
  CREATE_SAVE_FILTER_START,
  CREATE_SAVE_FILTER_SUCCESS,
  CREATE_SAVE_FILTER_FAILED,
  UPDATE_SAVED_FILTER_START,
  UPDATE_SAVED_FILTER_SUCCESS,
  UPDATE_SAVED_FILTER_FAILED,
  UPDATE_SAVED_FILTER_COLOR_START,
  UPDATE_SAVED_FILTER_COLOR_SUCCESS,
  UPDATE_SAVED_FILTER_COLOR_FAILED,
  DOCUMENT_PUBLISH_HANDLE_BADGE_START,
  DOCUMENT_PUBLISH_HANDLE_BADGE_SUCCESS,
  DOCUMENT_PUBLISH_HANDLE_BADGE_FAILED,
  DOCUMENT_PUBLISH_EMAIL_VERIFICATION_START,
  DOCUMENT_PUBLISH_EMAIL_VERIFICATION_SUCCESS,
  DOCUMENT_PUBLISH_EMAIL_VERIFICATION_FAILED,
  VERIFY_EMAIL_PUBLISHED_DOCUMENT_FAILED,
  VERIFY_EMAIL_PUBLISHED_DOCUMENT_SUCCESS,
  VERIFY_EMAIL_PUBLISHED_DOCUMENT_START,
  PUBLISHED_OTP_VERIFIED_DOCUMENT_FETCH_START,
  PUBLISHED_OTP_VERIFIED_DOCUMENT_FETCH_SUCCESS,
  PUBLISHED_OTP_VERIFIED_DOCUMENT_FETCH_FAILED,
} from "./actionTypes";
import qs from "qs";
import { documentRequestManager as API, screenshotRequestManager as THUMB_API } from "../../_helpers/requestManager";

const fetchDocumentStart = () => {
  return { type: DOCUMENT_FETCH_START };
};
const fetchDocumentSuccess = result => {
  return { type: DOCUMENT_FETCH_SUCCESS, result };
};
const fetchDocumentFailed = error => {
  return { type: DOCUMENT_FETCH_FAILED, error };
};

const updateDocumentStart = () => {
  return { type: DOCUMENT_UPDATE_START };
};
const updateDocumentSuccess = (result, payload) => {
  return { type: DOCUMENT_UPDATE_SUCCESS, result, payload };
};
const updateDocumentFailed = error => {
  return { type: DOCUMENT_UPDATE_FAILED, error };
};

const cloneDocumentStart = () => {
  return { type: DOCUMENT_CLONE_START };
};
const cloneDocumentSuccess = result => {
  return { type: DOCUMENT_CLONE_SUCCESS, result };
};
const cloneDocumentFailed = error => {
  return { type: DOCUMENT_CLONE_FAILED, error };
};

const shareDocumentStart = () => {
  return { type: DOCUMENT_SHARE_START };
};
const shareDocumentSuccess = result => {
  return { type: DOCUMENT_SHARE_SUCCESS, result };
};
const shareDocumentFailed = error => {
  return { type: DOCUMENT_SHARE_FAILED, error };
};

const shareDocumentUpdatePermissionStart = () => {
  return { type: DOCUMENT_SHARE_UPDATE_PERMISSION_START };
};
const shareDocumentUpdatePermissionSuccess = result => {
  return { type: DOCUMENT_SHARE_UPDATE_PERMISSION_SUCCESS, result };
};
const shareDocumentUpdatePermissionFailed = error => {
  return { type: DOCUMENT_SHARE_UPDATE_PERMISSION_FAILED, error };
};

const shareDocumentDeleteUserStart = () => {
  return { type: DOCUMENT_SHARE_DELETE_USER_START };
};
const shareDocumentDeleteUserSuccess = result => {
  return { type: DOCUMENT_SHARE_DELETE_USER_SUCCESS, result };
};
const shareDocumentDeleteUserFailed = error => {
  return { type: DOCUMENT_SHARE_DELETE_USER_FAILED, error };
};

const shareDocumentInfoStart = () => {
  return { type: DOCUMENT_SHARE_INFO_START };
};
const shareDocumentInfoSuccess = result => {
  return { type: DOCUMENT_SHARE_INFO_SUCCESS, result };
};
const shareDocumentInfoFailed = error => {
  return { type: DOCUMENT_SHARE_INFO_FAILED, error };
};

const publishDocumentStart = () => {
  return { type: DOCUMENT_PUBLISH_START };
};
const publishDocumentSuccess = result => {
  return { type: DOCUMENT_PUBLISH_SUCCESS, result };
};
const publishDocumentFailed = error => {
  return { type: DOCUMENT_PUBLISH_FAILED, error };
};

const publishDocumentHandleDownloadStart = () => {
  return { type: DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_START };
};
const publishDocumentHandleDownloadSuccess = result => {
  return { type: DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_SUCCESS, result };
};
const publishDocumentHandleDownloadFailed = error => {
  return { type: DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_FAILED, error };
};

const publishDocumentSetPasswordStart = () => {
  return { type: DOCUMENT_PUBLISH_SET_PASSWORD_START };
};
const publishDocumentSetPasswordSuccess = result => {
  return { type: DOCUMENT_PUBLISH_SET_PASSWORD_SUCCESS, result };
};
const publishDocumentSetPasswordFailed = error => {
  return { type: DOCUMENT_PUBLISH_SET_PASSWORD_FAILED, error };
};

const publishDocumentUnsetPasswordStart = () => {
  return { type: DOCUMENT_PUBLISH_UNSET_PASSWORD_START };
};
const publishDocumentUnsetPasswordSuccess = result => {
  return { type: DOCUMENT_PUBLISH_UNSET_PASSWORD_SUCCESS, result };
};
const publishDocumentUnsetPasswordFailed = error => {
  return { type: DOCUMENT_PUBLISH_UNSET_PASSWORD_FAILED, error };
};

const unpublishDocumentStart = () => {
  return { type: DOCUMENT_UNPUBLISH_START };
};
const unpublishDocumentSuccess = result => {
  return { type: DOCUMENT_UNPUBLISH_SUCCESS, result };
};
const unpublishDocumentFailed = error => {
  return { type: DOCUMENT_UNPUBLISH_FAILED, error };
};

const publishDocumentHandleBadgeStart = () => {
  return { type: DOCUMENT_PUBLISH_HANDLE_BADGE_START };
};
const publishDocumentHandleBadgeSuccess = result => {
  return { type: DOCUMENT_PUBLISH_HANDLE_BADGE_SUCCESS, result };
};
const publishDocumentHandleBadgeFailed = error => {
  return { type: DOCUMENT_PUBLISH_HANDLE_BADGE_FAILED, error };
};

const publishDocumentEmailVerificationStart = () => {
  return { type: DOCUMENT_PUBLISH_EMAIL_VERIFICATION_START };
};
const publishDocumentEmailVerificationSuccess = result => {
  return { type: DOCUMENT_PUBLISH_EMAIL_VERIFICATION_SUCCESS, result };
};
const publishDocumentEmailVerificationFailed = error => {
  return { type: DOCUMENT_PUBLISH_EMAIL_VERIFICATION_FAILED, error };
};

const publishDocumentToTemplateLibraryStart = () => {
  return { type: DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_START };
};
const publishDocumentToTemplateLibrarySuccess = result => {
  return { type: DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_SUCCESS, result };
};
const publishDocumentToTemplateLibraryFailed = error => {
  return { type: DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_FAILED, error };
};

const publishDocumentToTemplateLibraryInfoStart = () => {
  return { type: DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_INFO_START };
};
const publishDocumentToTemplateLibraryInfoSuccess = result => {
  return { type: DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_INFO_SUCCESS, result };
};
const publishDocumentToTemplateLibraryInfoFailed = error => {
  return { type: DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_INFO_FAILED, error };
};

const transferDocumentStart = () => {
  return { type: DOCUMENT_TRANSFER_START };
};
const transferDocumentSuccess = result => {
  return { type: DOCUMENT_TRANSFER_SUCCESS, result };
};
const transferDocumentFailed = error => {
  return { type: DOCUMENT_TRANSFER_FAILED, error };
};

const deleteDocumentStart = () => {
  return { type: DOCUMENT_DELETE_START };
};
const deleteDocumentSuccess = result => {
  return { type: DOCUMENT_DELETE_SUCCESS, result };
};
const deleteDocumentFailed = error => {
  return { type: DOCUMENT_DELETE_FAILED, error };
};

const fetchDocumentDetailsStart = (documentId, loading) => {
  return { type: DOCUMENT_DETAILS_FETCH_START, documentId, loading };
};
const fetchDocumentDetailsSuccess = result => {
  return { type: DOCUMENT_DETAILS_FETCH_SUCCESS, result };
};
const fetchDocumentDetailsFailed = error => {
  return { type: DOCUMENT_DETAILS_FETCH_FAILED, error };
};

const fetchDocumentCommentsStart = () => {
  return { type: DOCUMENT_COMMENTS_START };
};
const fetchDocumentCommentsFailed = error => {
  return { type: DOCUMENT_COMMENTS_FAILED, error };
};
const fetchDocumentCommentsSuccess = data => {
  return { type: DOCUMENT_COMMENTS_SUCCESS, data };
};

const insertCommentStart = () => {
  return { type: INSERT_COMMENTS_START };
};
const insertCommentFailed = error => {
  return { type: INSERT_COMMENTS_FAILED, error };
};
const insertCommentSuccess = (data, comment_id) => {
  return { type: INSERT_COMMENTS_SUCCESS, comment: data.comment, commentId: comment_id };
};

const applyLikeStart = () => {
  return { type: APPLY_LIKE_START };
};
const applyLikeFailed = error => {
  return { type: APPLY_LIKE_FAILED, error };
};
const applyLikeSuccess = (data, commentId) => {
  return { type: APPLY_LIKE_SUCCESS, ...data, commentId };
};

const updateCommentStart = () => {
  return { type: UPDATE_COMMENT_START };
};
const updateCommentFailed = error => {
  return { type: UPDATE_COMMENT_FAILED, error };
};
const updateCommentSuccess = (data, commentData) => {
  return { type: UPDATE_COMMENT_SUCCESS, data, ...commentData };
};

const deleteCommentStart = () => {
  return { type: DELETE_COMMENT_START };
};
const deleteCommentFailed = error => {
  return { type: DELETE_COMMENT_FAILED, error };
};
const deleteCommentSuccess = (data, commentId) => {
  return { type: DELETE_COMMENT_SUCCESS, data, commentId };
};

const fetchDocumentVersionStart = () => {
  return { type: FETCH_DOCUMENT_VERSION_START };
};

const fetchDocumentVersionFailed = () => {
  return { type: FETCH_DOCUMENT_VERSION_FAILED };
};

const fetchDocumentVersionSuccess = data => {
  return { type: FETCH_DOCUMENT_VERSION_SUCCESS, data };
};

const restoreDocumentVersionStart = () => {
  return { type: RESTORE_DOCUMENT_VERSION_START };
};

const restoreDocumentVersionFailed = () => {
  return { type: RESTORE_DOCUMENT_VERSION_FAILED };
};

const restoreDocumentVersionSuccess = data => {
  return { type: RESTORE_DOCUMENT_VERSION_SUCCESS, data };
};

const updateDocumentVersionStart = () => {
  return { type: UPDATE_DOCUMENT_VERSION_START };
};

const updateDocumentVersionFailed = () => {
  return { type: UPDATE_DOCUMENT_VERSION_FAILED };
};

const updateDocumentVersionSuccess = data => {
  return { type: UPDATE_DOCUMENT_VERSION_SUCCESS, data };
};

const fetchPublishedDocumentDetailsStart = () => {
  return { type: PUBLISHED_DOCUMENT_FETCH_START };
};

const fetchPublishedDocumentDetailsSuccess = result => {
  return { type: PUBLISHED_DOCUMENT_FETCH_SUCCESS, result };
};

const fetchPublishedDocumentDetailsFailed = error => {
  return { type: PUBLISHED_DOCUMENT_FETCH_FAILED, error };
};

const fetchPublishedOtpVerifiedDocumentDetailsStart = () => {
  return { type: PUBLISHED_OTP_VERIFIED_DOCUMENT_FETCH_START };
};

const fetchPublishedOtpVerifiedDocumentDetailsSuccess = result => {
  return { type: PUBLISHED_OTP_VERIFIED_DOCUMENT_FETCH_SUCCESS, result };
};

const fetchPublishedOtpVerifiedDocumentDetailsFailed = error => {
  return { type: PUBLISHED_OTP_VERIFIED_DOCUMENT_FETCH_FAILED, error };
};

const insertDocumentStart = () => {
  return { type: DOCUMENT_INSERT_START };
};
const insertDocumentSuccess = result => {
  return { type: DOCUMENT_INSERT_SUCCESS, result };
};
const insertDocumentFailed = error => {
  return { type: DOCUMENT_INSERT_FAILED, error };
};

const fetchDocumentVersionInfoStart = () => {
  return { type: FETCH_DOCUMENT_VERSION_INFO_START };
};

const fetchDocumentVersionInfoSuccess = result => {
  return { type: FETCH_DOCUMENT_VERSION_INFO_SUCCESS, result };
};

const fetchDocumentVersionInfoFailed = error => {
  return { type: FETCH_DOCUMENT_VERSION_INFO_FAILED, error };
};

const checkDocumentCreatePermissionStart = () => {
  return { type: CHECK_DOCUMENT_CREATE_PERMISSION_START };
};

const checkDocumentCreatePermissionSuccess = result => {
  return { type: CHECK_DOCUMENT_CREATE_PERMISSION_SUCCESS, result };
};

const checkDocumentCreatePermissionFailed = error => {
  return { type: CHECK_DOCUMENT_CREATE_PERMISSION_FAILED, error };
};

const createDocumentVersionStart = () => {
  return { type: CREATE_DOCUMENT_VERSION_START };
};

const createDocumentVersionSuccess = result => {
  return { type: CREATE_DOCUMENT_VERSION_SUCCESS, result };
};

const createDocumentVersionFailed = error => {
  return { type: CREATE_DOCUMENT_VERSION_FAILED, error };
};

const createDocumentThumbStart = () => {
  return { type: GENERATE_DOCUMENT_THUMB_START };
};

const createDocumentThumbSuccess = result => {
  return { type: GENERATE_DOCUMENT_THUMB_SUCCESS, result };
};

const createDocumentThumbFailed = error => {
  return { type: GENERATE_DOCUMENT_THUMB_FAILED, error };
};

const sendDocumentEmailStart = () => {
  return { type: SEND_DOCUMENT_EMAIL_START };
};
const sendDocumentEmailSuccess = result => {
  return { type: SEND_DOCUMENT_EMAIL_SUCCESS, result };
};
const sendDocumentEmailFailed = error => {
  return { type: SEND_DOCUMENT_EMAIL_FAILED, error };
};

const fetchSentEmailListStart = () => {
  return { type: FETCH_SENT_EMAIL_LIST_START };
};
const fetchSentEmailListSuccess = result => {
  return { type: FETCH_SENT_EMAIL_LIST_SUCCESS, result };
};
const fetchSentEmailListFailed = error => {
  return { type: FETCH_SENT_EMAIL_LIST_FAILED, error };
};

const publishCompanyTemplateStart = () => {
  return { type: PUBLISH_COMPANY_TEMPLATE_START };
};

const publishCompanyTemplateSuccess = result => {
  return { type: PUBLISH_COMPANY_TEMPLATE_SUCCESS, result };
};

const publishCompanyTemplateFailed = error => {
  return { type: PUBLISH_COMPANY_TEMPLATE_FAILED, error };
};

const publishCompanyTemplateInfoStart = () => {
  return { type: PUBLISH_COMPANY_TEMPLATE_INFO_START };
};

const publishCompanyTemplateInfoSuccess = result => {
  return { type: PUBLISH_COMPANY_TEMPLATE_INFO_SUCCESS, result };
};

const publishCompanyTemplateInfoFailed = error => {
  return { type: PUBLISH_COMPANY_TEMPLATE_INFO_FAILED, error };
};

const fetchSavedFilterStart = () => {
  return { type: FETCH_SAVED_FILTER_START };
};

const fetchSavedFilterSuccess = result => {
  return { type: FETCH_SAVED_FILTER_SUCCESS, result };
};

const fetchSavedFilterFailed = error => {
  return { type: FETCH_SAVED_FILTER_FAILED, error };
};

const deleteSavedFilterStart = () => {
  return { type: DELETE_SAVED_FILTER_START };
};

const deleteSavedFilterSuccess = result => {
  return { type: DELETE_SAVED_FILTER_SUCCESS, result };
};

const deleteSavedFilterFailed = error => {
  return { type: DELETE_SAVED_FILTER_FAILED, error };
};

const renameSavedFilterStart = () => {
  return { type: RENAME_SAVED_FILTER_START };
};

const renameSavedFilterSuccess = result => {
  return { type: RENAME_SAVED_FILTER_SUCCESS, result };
};

const renameSavedFilterFailed = error => {
  return { type: RENAME_SAVED_FILTER_FAILED, error };
};

const fetchAppliedFilterDocumentListStart = () => {
  return { type: FETCH_APPLIED_FILTER_DOCUMENT_LIST_START };
};

const fetchAppliedFilterDocumentListSuccess = result => {
  return { type: FETCH_APPLIED_FILTER_DOCUMENT_LIST_SUCCESS, result };
};

const fetchAppliedFilterDocumentListFailed = error => {
  return { type: FETCH_APPLIED_FILTER_DOCUMENT_LIST_FAILED, error };
};

const fetchApplyFilterDocumentStart = () => {
  return { type: FETCH_APPLY_FILTER_DOCUMENT_START };
};

const fetchApplyFilterDocumentSuccess = result => {
  return { type: FETCH_APPLY_FILTER_DOCUMENT_SUCCESS, result };
};

const fetchApplyFilterDocumentFailed = error => {
  return { type: FETCH_APPLY_FILTER_DOCUMENT_FAILED, error };
};

const fetchFilterRecordsStart = () => {
  return { type: FETCH_FILTER_RECORDS_START };
};

const fetchFilterRecordsSuccess = result => {
  return { type: FETCH_FILTER_RECORDS_SUCCESS, result };
};

const fetchFilterRecordsFailed = error => {
  return { type: FETCH_FILTER_RECORDS_FAILED, error };
};

const createSaveFilterStart = () => {
  return { type: CREATE_SAVE_FILTER_START };
};

const createSaveFilterSuccess = result => {
  return { type: CREATE_SAVE_FILTER_SUCCESS, result };
};

const createSaveFilterFailed = error => {
  return { type: CREATE_SAVE_FILTER_FAILED, error };
};

const updateSavedFilterStart = () => {
  return { type: UPDATE_SAVED_FILTER_START };
};

const updateSavedFilterSuccess = result => {
  return { type: UPDATE_SAVED_FILTER_SUCCESS, result };
};

const updateSavedFilterFailed = error => {
  return { type: UPDATE_SAVED_FILTER_FAILED, error };
};

const updateSavedFilterColorStart = () => {
  return { type: UPDATE_SAVED_FILTER_COLOR_START };
};

const updateSavedFilterColorSuccess = result => {
  return { type: UPDATE_SAVED_FILTER_COLOR_SUCCESS, result };
};

const updateSavedFilterColorFailed = error => {
  return { type: UPDATE_SAVED_FILTER_COLOR_FAILED, error };
};

const verifyEmailPublishedDocumentStart = () => {
  return { type: VERIFY_EMAIL_PUBLISHED_DOCUMENT_START };
};

const verifyEmailPublishedDocumentSuccess = result => {
  return { type: VERIFY_EMAIL_PUBLISHED_DOCUMENT_SUCCESS, result };
};

const verifyEmailPublishedDocumentFailed = error => {
  return { type: VERIFY_EMAIL_PUBLISHED_DOCUMENT_FAILED, error };
};

export const fetchDocument = payload => {
  let endPoint = `?${qs.stringify(payload)}`;

  return dispatch => {
    dispatch(fetchDocumentStart());

    return API.get(endPoint)
      .then(r => dispatch(fetchDocumentSuccess(r.data)))
      .catch(e => dispatch(fetchDocumentFailed(e)));
  };
};

export const updateDocument = (documentId, payload) => {
  let endPoint = `/${documentId}`;

  return dispatch => {
    dispatch(updateDocumentStart());

    return API.put(endPoint, payload)
      .then(r => dispatch(updateDocumentSuccess(r.data, payload)))
      .catch(e => dispatch(updateDocumentFailed(e)));
  };
};

export const cloneDocument = documentId => {
  let endPoint = `/${documentId}/clone`;

  return dispatch => {
    dispatch(cloneDocumentStart());

    return API.post(endPoint)
      .then(r => dispatch(cloneDocumentSuccess(r.data)))
      .catch(e => dispatch(cloneDocumentFailed(e)));
  };
};

export const shareDocument = payload => {
  let endPoint = `/${payload.documentId}/share?notify=${payload.notify}`;

  return dispatch => {
    dispatch(shareDocumentStart());

    return API.post(endPoint, {
      share_with: payload.shareWith,
    })
      .then(r => dispatch(shareDocumentSuccess(r.data)))
      .catch(e => dispatch(shareDocumentFailed(e)));
  };
};

export const shareDocumentUpdatePermission = payload => {
  let endPoint = `/${payload.documentId}/share`;

  return dispatch => {
    dispatch(shareDocumentUpdatePermissionStart());

    return API.put(endPoint, {
      share_with: payload.shareWith,
    })
      .then(r => dispatch(shareDocumentUpdatePermissionSuccess(r.data)))
      .catch(e => dispatch(shareDocumentUpdatePermissionFailed(e)));
  };
};

export const shareDocumentDeleteUser = payload => {
  let endPoint = `/${payload.documentId}/share`;

  return dispatch => {
    dispatch(shareDocumentDeleteUserStart());

    return API.delete(endPoint, {
      data: {
        share_with: payload.shareWith,
      },
    })
      .then(r => dispatch(shareDocumentDeleteUserSuccess(r.data)))
      .catch(e => dispatch(shareDocumentDeleteUserFailed(e)));
  };
};

export const shareDocumentInfo = documentId => {
  let endPoint = `/${documentId}/share`;

  return dispatch => {
    dispatch(shareDocumentInfoStart());

    return API.get(endPoint)
      .then(r => dispatch(shareDocumentInfoSuccess(r.data)))
      .catch(e => dispatch(shareDocumentInfoFailed(e)));
  };
};

export const publishDocument = documentId => {
  let endPoint = `/${documentId}/publish`;

  return dispatch => {
    dispatch(publishDocumentStart());

    return API.put(endPoint)
      .then(r => dispatch(publishDocumentSuccess(r.data)))
      .catch(e => dispatch(publishDocumentFailed(e)));
  };
};

export const publishDocumentHandleDownload = payload => {
  let endPoint = `/${payload.documentId}/publish/grant-download?download=${payload.download}`;

  return dispatch => {
    dispatch(publishDocumentHandleDownloadStart());

    return API.put(endPoint)
      .then(r => dispatch(publishDocumentHandleDownloadSuccess(r.data)))
      .catch(e => dispatch(publishDocumentHandleDownloadFailed(e)));
  };
};

export const publishDocumentSetPassword = payload => {
  let endPoint = `/${payload.documentId}/publish/set-password?${qs.stringify({ password: payload.password })}`;

  return dispatch => {
    dispatch(publishDocumentSetPasswordStart());

    return API.put(endPoint)
      .then(r => dispatch(publishDocumentSetPasswordSuccess(r.data)))
      .catch(e => dispatch(publishDocumentSetPasswordFailed(e)));
  };
};

export const publishDocumentUnsetPassword = documentId => {
  let endPoint = `/${documentId}/publish/unset-password`;

  return dispatch => {
    dispatch(publishDocumentUnsetPasswordStart());

    return API.put(endPoint)
      .then(r => dispatch(publishDocumentUnsetPasswordSuccess(r.data)))
      .catch(e => dispatch(publishDocumentUnsetPasswordFailed(e)));
  };
};

export const unpublishDocument = documentId => {
  let endPoint = `/${documentId}/unpublish`;

  return dispatch => {
    dispatch(unpublishDocumentStart());

    return API.put(endPoint)
      .then(r => dispatch(unpublishDocumentSuccess(r.data)))
      .catch(e => dispatch(unpublishDocumentFailed(e)));
  };
};

export const publishDocumentHandleBadge = payload => {
  let endPoint = `/${payload.documentId}/publish/toggle-badge?hide_badge=${payload.hideBadge}`;

  return dispatch => {
    dispatch(publishDocumentHandleBadgeStart());

    return API.put(endPoint)
      .then(r => dispatch(publishDocumentHandleBadgeSuccess(r.data)))
      .catch(e => dispatch(publishDocumentHandleBadgeFailed(e)));
  };
};

export const publishDocumentEmailVerification = payload => {
  let endPoint = `/${payload.documentId}/publish/email-verification?verification=${payload.verification}`;

  return dispatch => {
    dispatch(publishDocumentEmailVerificationStart());

    const request = payload.payLoad 
      ? API.put(endPoint, payload.payLoad)
      : API.put(endPoint);

    return request
      .then(r => dispatch(publishDocumentEmailVerificationSuccess(r.data)))
      .catch(e => dispatch(publishDocumentEmailVerificationFailed(e)));
  };
};

export const publishDocumentToTemplateLibrary = payload => {
  let endPoint = `/${payload.documentId}/publish/library`;

  return dispatch => {
    dispatch(publishDocumentToTemplateLibraryStart());

    return API.post(endPoint, {
      group: payload.group,
      type: payload.type,
      category: payload.category,
      tags: payload.tags,
      premium: payload.premium,
      enabled: payload.enabled,
    })
      .then(r => dispatch(publishDocumentToTemplateLibrarySuccess(r.data)))
      .catch(e => dispatch(publishDocumentToTemplateLibraryFailed(e)));
  };
};

export const publishDocumentToTemplateLibraryInfo = documentId => {
  let endPoint = `/${documentId}/published`;

  return dispatch => {
    dispatch(publishDocumentToTemplateLibraryInfoStart());

    return API.get(endPoint)
      .then(r => dispatch(publishDocumentToTemplateLibraryInfoSuccess(r.data)))
      .catch(e => dispatch(publishDocumentToTemplateLibraryInfoFailed(e)));
  };
};

export const transferDocument = payload => {
  let endPoint = `/${payload.documentId}/transfer`;

  return dispatch => {
    dispatch(transferDocumentStart());

    return API.post(endPoint, {
      company: payload.companyId,
    })
      .then(r => dispatch(transferDocumentSuccess(r.data)))
      .catch(e => dispatch(transferDocumentFailed(e)));
  };
};

export const deleteDocument = documentIds => {
  return dispatch => {
    dispatch(deleteDocumentStart());

    return API.post(`/delete`, {
      documentList: documentIds,
    })
      .then(r => dispatch(deleteDocumentSuccess(r.data)))
      .catch(e => dispatch(deleteDocumentFailed(e)));
  };
};

export const fetchDocumentDetails = (documentId, loading) => {
  let endPoint = `/${documentId}`;
  return dispatch => {
    dispatch(fetchDocumentDetailsStart(documentId, loading));

    return API.get(endPoint)
      .then(r => dispatch(fetchDocumentDetailsSuccess(r.data)))
      .catch(e => {
        dispatch(fetchDocumentDetailsFailed(e.response?.data ?? e.message));
      });
  };
};

export const fetchDocumentComments = id => {
  return dispatch => {
    dispatch(fetchDocumentCommentsStart());
    return API.get(`/${id}/comments`)
      .then(r => dispatch(fetchDocumentCommentsSuccess(r.data)))
      .catch(e => dispatch(fetchDocumentCommentsFailed(e)));
  };
};

export const insertComment = (id, commentData) => {
  let { comment, comment_id } = commentData;
  return dispatch => {
    dispatch(insertCommentStart());
    return API.post(`/${id}/comments`, { comment, comment_id })
      .then(r => dispatch(insertCommentSuccess(r.data, comment_id)))
      .catch(e => dispatch(insertCommentFailed(e)));
  };
};

export const applyLike = commentId => {
  return dispatch => {
    dispatch(applyLikeStart());
    return API.post(`/comments/${commentId}/like`)
      .then(r => dispatch(applyLikeSuccess(r.data, commentId)))
      .catch(e => dispatch(applyLikeFailed(e)));
  };
};

export const updateComment = commentData => {
  let { commentId, comment } = commentData;
  return dispatch => {
    dispatch(updateCommentStart());
    return API.put(`/comments/${commentId}`, { comment_id: commentId, comment: comment })
      .then(r => dispatch(updateCommentSuccess(r.data, commentData)))
      .catch(e => dispatch(updateCommentFailed(e)));
  };
};

export const deleteComment = commentId => {
  return dispatch => {
    dispatch(deleteCommentStart());
    return API.delete(`/comments/${commentId}`)
      .then(r => dispatch(deleteCommentSuccess(r.data, commentId)))
      .catch(e => dispatch(deleteCommentFailed(e)));
  };
};

// For fetch doument versions
export const getDocumentVersions = documentId => {
  return dispatch => {
    dispatch(fetchDocumentVersionStart());
    return API.get(`/${documentId}/versions`)
      .then(r => dispatch(fetchDocumentVersionSuccess(r.data)))
      .catch(e => dispatch(fetchDocumentVersionFailed(e)));
  };
};

// For restore doument versions
export const restoreDocumentVersion = (documentId, versionId) => {
  return dispatch => {
    dispatch(restoreDocumentVersionStart());
    return API.put(`/${documentId}/versions/${versionId}/restore`)
      .then(r => dispatch(restoreDocumentVersionSuccess(r.data)))
      .catch(e => dispatch(restoreDocumentVersionFailed(e)));
  };
};

// For update doument versions
export const updateDocumentVersion = (versionId, payload = {}) => {
  return dispatch => {
    dispatch(updateDocumentVersionStart());
    return API.put(`/version/${versionId}/update`, payload)
      .then(r => dispatch(updateDocumentVersionSuccess(r.data)))
      .catch(e => dispatch(updateDocumentVersionFailed(e)));
  };
};

export const fetchPublishedDocumentDetails = (company_id, document_hash, password) => {
  let endPoint = `/pub/${company_id}/${document_hash}`;

  return dispatch => {
    dispatch(fetchPublishedDocumentDetailsStart());

    return API.post(endPoint, password ? { password } : {}, {
      headers: {
        "X-Auth": "NoAuth",
      },
    })
      .then(r => dispatch(fetchPublishedDocumentDetailsSuccess(r.data)))
      .catch(e => dispatch(fetchPublishedDocumentDetailsFailed(e)));
  };
};


export const fetchPublishedOtpVerifiedDocumentDetails = (company_id, document_hash, payload) => {
  let endPoint = `/pub/${company_id}/${document_hash}`;

  return dispatch => {
    dispatch(fetchPublishedOtpVerifiedDocumentDetailsStart());

    return API.post(endPoint, payload, {
      headers: {
        "X-Auth": "NoAuth",
      },
    })
      .then(r => dispatch(fetchPublishedOtpVerifiedDocumentDetailsSuccess(r.data)))
      .catch(e => dispatch(fetchPublishedOtpVerifiedDocumentDetailsFailed(e)));
  };
};

export const insertDocument = ({ payload, ref = null }) => {
  const endPoint = ref === "company-template" ? `/?ref=${ref}` : `/`;

  return dispatch => {
    dispatch(insertDocumentStart());

    return API.post(endPoint, payload)
      .then(r => dispatch(insertDocumentSuccess(r.data)))
      .catch(e => dispatch(insertDocumentFailed(e)));
  };
};

// Fetch Document version by version id
export const fetchDocumentVersionInfo = (documentId, versionId) => {
  let endPoint = `/${documentId}`;
  if (versionId) endPoint = `${endPoint}?version_id=${versionId}`;

  return dispatch => {
    dispatch(fetchDocumentVersionInfoStart());

    return API.get(endPoint)
      .then(r => dispatch(fetchDocumentVersionInfoSuccess(r.data)))
      .catch(e => dispatch(fetchDocumentVersionInfoFailed(e)));
  };
};

// Check document create permission for free company
export const checkDocumentCreatePermission = () => {
  return dispatch => {
    dispatch(checkDocumentCreatePermissionStart());

    return API.get(`/create/permission`)
      .then(r => dispatch(checkDocumentCreatePermissionSuccess(r.data)))
      .catch(e => dispatch(checkDocumentCreatePermissionFailed(e)));
  };
};

// Create document version
export const createDocumentVersion = documentId => {
  return dispatch => {
    dispatch(createDocumentVersionStart());

    return API.post(`/${documentId}/versions`)
      .then(r => dispatch(createDocumentVersionSuccess(r.data)))
      .catch(e => dispatch(createDocumentVersionFailed(e)));
  };
};

// Create document screenshot
export const createDocumentThumb = payload => {
  return dispatch => {
    dispatch(createDocumentThumbStart());

    return THUMB_API.post(`/screenshot`, payload)
      .then(r => dispatch(createDocumentThumbSuccess(r.data)))
      .catch(e => dispatch(createDocumentThumbFailed(e)));
  };
};

// update document reducer
export const updateDocumentReducer = payload => {
  return dispatch => {
    dispatch({ type: UPDATE_DOCUMENT_REDUCER, payload });
  };
};

//send email document
export const sendDocumentEmail = (document_id, payload) => {
  return dispatch => {
    dispatch(sendDocumentEmailStart());

    return API.post(`/${document_id}/share/email`, payload)
      .then(r => dispatch(sendDocumentEmailSuccess(r.data)))
      .catch(e => dispatch(sendDocumentEmailFailed(e)));
  };
};

export const fetchSentEmailList = (document_id, payload) => {
  const { page, limit } = payload;

  return dispatch => {
    dispatch(fetchSentEmailListStart());

    return API.get(`${document_id}/share/email?page=${page}&limit=${limit}`)
      .then(r => dispatch(fetchSentEmailListSuccess(r.data)))
      .catch(e => dispatch(fetchSentEmailListFailed(e)));
  };
};

// Publish documnt as company template
export const publishCompanyTemplate = ({ documentId, brandId }) => {
  const payload = brandId ? { brand: brandId } : {};

  return dispatch => {
    dispatch(publishCompanyTemplateStart());

    return API.post(`/${documentId}/publish/company/template`, payload)
      .then(r => dispatch(publishCompanyTemplateSuccess(r.data)))
      .catch(e => dispatch(publishCompanyTemplateFailed(e)));
  };
};

export const publishCompanyTemplateInfo = documentId => {
  return dispatch => {
    dispatch(publishCompanyTemplateInfoStart());

    return API.get(`/${documentId}/publish/company/template`)
      .then(r => dispatch(publishCompanyTemplateInfoSuccess(r.data)))
      .catch(e => dispatch(publishCompanyTemplateInfoFailed(e)));
  };
};

export const fetchSavedFilterList = document_view => {
  return dispatch => {
    dispatch(fetchSavedFilterStart());

    return API.get(`/records/filter?view=${document_view}`)
      .then(r => dispatch(fetchSavedFilterSuccess(r.data)))
      .catch(e => dispatch(fetchSavedFilterFailed(e)));
  };
};

export const deleteSavedFilter = filter_id => {
  return dispatch => {
    dispatch(deleteSavedFilterStart());

    return API.delete(`/records/filter/${filter_id}`)
      .then(r => dispatch(deleteSavedFilterSuccess(r.data)))
      .catch(e => dispatch(deleteSavedFilterFailed(e)));
  };
};

export const renameSavedFilter = (filter_id, name) => {
  const payload = {
    name: name,
  };
  return dispatch => {
    dispatch(renameSavedFilterStart());

    return API.put(`/records/filter/${filter_id}`, payload)
      .then(r => dispatch(renameSavedFilterSuccess(r.data)))
      .catch(e => dispatch(renameSavedFilterFailed(e)));
  };
};

export const appliedFilterDocumentList = (payload, filter_id) => {
  const endPoint = `/filter?${qs.stringify(payload)}&filter_id=${filter_id}`;

  return dispatch => {
    dispatch(fetchAppliedFilterDocumentListStart());

    return API.post(endPoint)
      .then(r => dispatch(fetchAppliedFilterDocumentListSuccess(r.data)))
      .catch(e => dispatch(fetchAppliedFilterDocumentListFailed(e)));
  };
};

export const applyFilterFetchDocument = (payload, data) => {
  const endPoint = `/filter?${qs.stringify(payload)}`;

  return dispatch => {
    dispatch(fetchApplyFilterDocumentStart());

    return API.post(endPoint, data)
      .then(r => dispatch(fetchApplyFilterDocumentSuccess(r.data)))
      .catch(e => dispatch(fetchApplyFilterDocumentFailed(e)));
  };
};

export const fetchFilterRecordsDetails = filter_id => {
  return dispatch => {
    dispatch(fetchFilterRecordsStart());

    return API.get(`/records/filter/${filter_id}`)
      .then(r => dispatch(fetchFilterRecordsSuccess(r.data)))
      .catch(e => dispatch(fetchFilterRecordsFailed(e)));
  };
};

export const createSaveFilter = payload => {
  return dispatch => {
    dispatch(createSaveFilterStart());

    return API.post(`/records/filter`, payload)
      .then(r => dispatch(createSaveFilterSuccess(r.data)))
      .catch(e => dispatch(createSaveFilterFailed(e)));
  };
};

export const updateSavedFilter = (filter_id, payload) => {
  return dispatch => {
    dispatch(updateSavedFilterStart());

    return API.put(`records/filter/${filter_id}`, payload)
      .then(r => dispatch(updateSavedFilterSuccess(r.data)))
      .catch(e => dispatch(updateSavedFilterFailed(e)));
  };
};

export const changeSavedFilterColor = (filter_id, color) => {
  const payload = {
    color: color,
  };
  return dispatch => {
    dispatch(updateSavedFilterColorStart());

    return API.put(`/records/filter/${filter_id}`, payload)
      .then(r => dispatch(updateSavedFilterColorSuccess(r.data)))
      .catch(e => dispatch(updateSavedFilterColorFailed(e)));
  };
};

export const verifyEmailPublishedDocument = (payload) => {
  let endPoint = `/${payload.document_id}/publish/email-verification/send-otp`;

  return dispatch => {
    dispatch(verifyEmailPublishedDocumentStart());

    return API.post(endPoint, payload, {
      headers: {
        "X-Auth": "NoAuth",
      },
    })
      .then(r => dispatch(verifyEmailPublishedDocumentSuccess(r.data)))
      .catch(e => dispatch(verifyEmailPublishedDocumentFailed(e)));
  };
};
