import { useContext, useEffect } from "react";
import _ from "lodash";
import { TYPE_INFOGRAPHIC } from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";
import {
  getCssTransformObj,
  getUnScaledValue,
  getWidgetAndParentsDomReference,
  getZoomedValue,
  removeDuplicateFromArray,
  widgetQuery,
} from "../_helpers/utils";
import useWidgetHandler from "./useWidgetHandler";
import useWidgetHighlighter from "./useWidgetHighlighter";
import useGroupUngroup from "./useGroupUngroup";

const useSelectable = () => {
  const {
    widgets,
    metadata,
    updateMetadata,
    isDocumentReady,
    documentType,
    dimension,
    retainRotation,
    setRetainRotation,
    actionOnRotateModal,
    isTimeLineViewOpen
  } = useContext(EditorContext);

  const {
    show: show_WH,
    hide: hide_WH,
    groupSelectionShow: show_GWH,
    toggleEventHandlers: toggleWidgetEventHandlers,
  } = useWidgetHandler();

  const { hide: hide_WHT } = useWidgetHighlighter();
  const { getShiftSelection } = useGroupUngroup()

  const ACTIVE_WIDGET_ID = metadata.activeWidgetId;
  let isGroupWidget = document.getElementById(ACTIVE_WIDGET_ID[0])?.closest(".dhp-page-group.dhp-root-widget");
  let targetId = isGroupWidget?.id ?? ACTIVE_WIDGET_ID[0];
  const ACTIVE_WIDGET_STYLE = widgets[widgets.findIndex(w => w.id === targetId)]?.style;

  const selectable = {
    meta: false,

    start: ({
      activeWidgetId = metadata.activeWidgetId,
      activeBlockId = metadata.activeBlockId,
      zoom,
      css,
      finalUpdate,
      isCollaborationChanges,
    }) => {
      if (!isDocumentReady && !isCollaborationChanges) return;

      const { isInactiveWidget, isSingleWidget, isMultiWidget, data } = widgetQuery(activeWidgetId);

      if (isInactiveWidget) {
        hide_WH();
        selectable.hideAllWidgetSelection({ type: "all" });
      }

      if (isSingleWidget) {
        const {
          widget: {
            id: widgetId,
            node: widgetNode,
            parent: { id: widgetParentId, node: widgetParentNode },
          },
        } = data;

        selectable.hideAllWidgetSelection({ type: "all" });

        if (data.widget.isChildWidget) {
          widgetNode.classList.add("child-selected");
          widgetParentNode.classList.add("single-selected");
          show_WH({ activeWidgetId: widgetParentId, zoom, css, finalUpdate });
        } else {
          widgetNode.classList.add("single-selected");
          show_WH({ activeWidgetId: widgetId, zoom, css, finalUpdate });
        }
      }

      if (isMultiWidget) {
        const blockNode = document.getElementById(activeBlockId);
        const WidgetNodes = activeWidgetId.map(widgetId => document.getElementById(widgetId));

        selectable.handleMultiSelectionAndUpdateReactDom({
          selected: {
            block: blockNode,
            widgets: WidgetNodes,
          },
        });
      }
    },

    dragSelect: {
      meta: false,

      getSelectedBlock: ({ selector, selected }) => {
        const selectorRect = selector.node.getBoundingClientRect();
        const blocks = document.querySelectorAll(".dhp-page-block");
        const count = blocks.length;

        for (let i = 0; i < count; i++) {
          const block = blocks[i];
          const blockRect = block.getBoundingClientRect();
          if (
            ((blockRect.left >= selectorRect.left && blockRect.left <= selectorRect.right) ||
              (blockRect.right >= selectorRect.left && blockRect.right <= selectorRect.right) ||
              (selectorRect.left >= blockRect.left && selectorRect.right <= blockRect.right)) &&
            ((blockRect.top >= selectorRect.top && blockRect.top <= selectorRect.bottom) ||
              (blockRect.bottom >= selectorRect.top && blockRect.bottom <= selectorRect.bottom) ||
              (selectorRect.top >= blockRect.top && selectorRect.bottom <= blockRect.bottom))
          ) {
            if (!selected.block) selectable.dragSelect.meta.selected.block = block;
            break;
          } else {
            selectable.dragSelect.meta.selected.block = null;
          }
        }
      },

      getSelectedWidgets: ({ selector, selected }) => {
        if (selected.block) {
          const selectedWidgets = [];
          const selectorRect = selector.node.getBoundingClientRect();
          const blockWidgets = selected.block.querySelectorAll(".dhp-root-widget");
          const count = blockWidgets.length;

          for (let i = 0; i < count; i++) {
            const widget = blockWidgets[i];
            const widgetRect = widget.getBoundingClientRect();
            const isWidgetLocked = widget.classList.contains("draggable-disabled");
            if (
              !isWidgetLocked &&
              ((widgetRect.left >= selectorRect.left && widgetRect.left <= selectorRect.right) ||
                (widgetRect.right >= selectorRect.left && widgetRect.right <= selectorRect.right) ||
                (selectorRect.left >= widgetRect.left && selectorRect.right <= widgetRect.right)) &&
              ((widgetRect.top >= selectorRect.top && widgetRect.top <= selectorRect.bottom) ||
                (widgetRect.bottom >= selectorRect.top && widgetRect.bottom <= selectorRect.bottom) ||
                (selectorRect.top >= widgetRect.top && selectorRect.bottom <= widgetRect.bottom))
            ) {
              selectedWidgets.push(widget);
              widget.classList.add("drag-selected");
            } else {
              widget.classList.remove("drag-selected");
            }
          }
          selectable.dragSelect.meta.selected.widgets = selectedWidgets;
        }
      },

      toggleSelection: ({ action, selector, selected }) => {
        if (action === "SHOW") {
          selectable.dragSelect.getSelectedBlock({ selector, selected });
          selectable.dragSelect.getSelectedWidgets({ selector, selected });
        }
        if (action === "HIDE") {
          selectable.hideAllWidgetSelection({ type: "group" });
        }
      },

      start: e => {
        const isWidget = e.target.closest(".dhp-page-widget");
        const isWidgetGrouped = e.target.closest(".dhp-page-group");
        const isWidgetHandler = e.target.closest(".dhp-widget-handler");
        const isWidgetGroupSelected = e.target.closest(".group-selected");

        if (!isWidget && !isWidgetGrouped && !isWidgetHandler && !isWidgetGroupSelected) {
          hide_WH();
          selectable.dragSelect.toggleSelection({ action: "HIDE" });

          const selector = document.getElementById("dhp-area-selection");
          const selectorParentRect = selector.parentElement.getBoundingClientRect();

          let props = {
            isDragging: true,
            selector: {
              node: selector,
              initial: {
                left: e.clientX - selectorParentRect.left,
                top: e.clientY - selectorParentRect.top + selector.parentElement.scrollTop,
              },
            },
            mouse: {
              initial: {
                x: e.clientX,
                y: e.clientY,
              },
            },
            selected: {
              block: null,
              widgets: [],
            },
          };

          selectable.dragSelect.meta = props;
          document.addEventListener("mousemove", selectable.dragSelect.drag);
          document.addEventListener("mouseup", selectable.dragSelect.stop);

          selector.style.cssText = `
            width: ${0}px; 
            height: ${0}px;
            left: ${props.selector.initial.left}px; 
            top: ${props.selector.initial.top}px;
          `;
        }
      },

      drag: e => {
        let meta = selectable.dragSelect.meta;
        if (meta?.isDragging) {
          let pos = {};
          const dX = e.clientX - meta.mouse.initial.x;
          const dY = e.clientY - meta.mouse.initial.y;

          if (dX >= 0 && dY < 0) {
            pos = {
              top: meta.selector.initial.top + dY,
            };
          } else if (dX < 0 && dY >= 0) {
            pos = {
              left: meta.selector.initial.left + dX,
            };
          } else if (dX < 0 && dY < 0) {
            pos = {
              left: meta.selector.initial.left + dX,
              top: meta.selector.initial.top + dY,
            };
          }

          meta.selector.node.classList.remove("d-none");
          meta.selector.node.style.width = `${Math.abs(dX)}px`;
          meta.selector.node.style.height = `${Math.abs(dY)}px`;
          Object.keys(pos).forEach(key => (meta.selector.node.style[key] = `${pos[key]}px`));
          selectable.dragSelect.toggleSelection({ action: "SHOW", ...meta });
        }
      },

      stop: () => {
        let meta = selectable.dragSelect.meta;
        if (meta?.isDragging) {
          meta.selector.node.classList.add("d-none");
          selectable.handleMultiSelectionAndUpdateReactDom({ ...meta });
          document.removeEventListener("mousemove", selectable.dragSelect.drag);
          document.removeEventListener("mouseup", selectable.dragSelect.stop);
          meta = false;
        }
      },
    },

    shiftSelect: {
      toggleSelection: ({ selectedWidget, isSelectedWidgetLocked }) => {
        if (selectedWidget && !isSelectedWidgetLocked) {
          selectedWidget.classList.toggle("group-selected");
        }
      },

      filterDuplicateSelections: ({ previousSelection }) => {
        let previousWidgetId = null;
        const filteredWidgets = [];

        [...previousSelection, ...document.querySelectorAll(".group-selected")].forEach(widget => {
          if (widget.id !== previousWidgetId) {
            previousWidgetId = widget.id;
            filteredWidgets.push(widget);
          }
        });

        return filteredWidgets;
      },

      filterCurrentSelectionFromPreselectedWidgets: ({ currentSelection, previousSelection }) => {
        const filteredWidgets = [];
        previousSelection.forEach(preselectedWidget => {
          const isPreselectedWidgetLocked = preselectedWidget.classList.contains("draggable-disabled");
          const isPreAndCurrentSelectedWidgetSame = preselectedWidget.id === currentSelection?.id;
          if (!isPreselectedWidgetLocked && !isPreAndCurrentSelectedWidgetSame) filteredWidgets.push(preselectedWidget);
        });

        return filteredWidgets;
      },

      getPreselectedBlockAndWidgets: ({ currentSelection }) => {
        const groupSelectedWidgets = document.querySelectorAll(".group-selected");
        const singleSelectedWidgets = document.querySelectorAll(".single-selected");

        const preselectedWidgets = selectable.shiftSelect.filterCurrentSelectionFromPreselectedWidgets({
          currentSelection,
          previousSelection:
            groupSelectedWidgets.length > 0
              ? groupSelectedWidgets
              : singleSelectedWidgets.length > 0
              ? singleSelectedWidgets
              : [],
        });

        const preselectedBlock = preselectedWidgets?.length > 0 ? preselectedWidgets[0].parentElement : null;

        return {
          preselectedBlock,
          preselectedWidgets,
        };
      },

      getSelectedBlockAndWidgets: e => {
        const selectedWidget = e.target.closest(".dhp-root-widget");
        const selectedBlock = selectedWidget && selectedWidget.parentElement;
        const isSelectedWidgetLocked = selectedWidget && selectedWidget.classList.contains("draggable-disabled");

        const { preselectedBlock, preselectedWidgets } = selectable.shiftSelect.getPreselectedBlockAndWidgets({
          currentSelection: selectedWidget,
        });

        if (!preselectedBlock && (!selectedWidget || isSelectedWidgetLocked)) {
          return {
            block: null,
            widgets: [],
          };
        } else if (!preselectedBlock && selectedWidget && !isSelectedWidgetLocked) {
          selectable.shiftSelect.toggleSelection({ selectedWidget, isSelectedWidgetLocked });

          return {
            block: selectedBlock,
            widgets: selectable.shiftSelect.filterDuplicateSelections({ previousSelection: preselectedWidgets }),
          };
        } else if (preselectedBlock && !selectedWidget) {
          return {
            block: preselectedBlock,
            widgets: selectable.shiftSelect.filterDuplicateSelections({ previousSelection: preselectedWidgets }),
          };
        } else if (
          selectedWidget &&
          preselectedBlock &&
          (preselectedBlock.id !== selectedBlock.id || isSelectedWidgetLocked)
        ) {
          return {
            block: preselectedBlock,
            widgets: document.querySelectorAll(".group-selected"),
          };
        } else if (
          selectedWidget &&
          preselectedBlock &&
          preselectedBlock.id === selectedBlock.id &&
          !isSelectedWidgetLocked
        ) {
          selectable.shiftSelect.toggleSelection({ selectedWidget, isSelectedWidgetLocked });

          return {
            block: document.querySelectorAll(".group-selected").length > 0 ? preselectedBlock : null,
            widgets: selectable.shiftSelect.filterDuplicateSelections({ previousSelection: preselectedWidgets }),
          };
        }
      },

      start: e => {
        const { block, widgets } = selectable.shiftSelect.getSelectedBlockAndWidgets(e);

        if (block) {
          const filteredWidgets = removeDuplicateFromArray(widgets);
          selectable.handleMultiSelectionAndUpdateReactDom({ selected: { block, widgets: filteredWidgets }, action: filteredWidgets.length >= 1 ? "ShiftSelect" : false });
        }
      },
    },

    selectAll: {
      getSelectedBlockAndWidgets: () => {
        const activeBlock = document.getElementById(metadata.activeBlockId);
        const activeBlockWidgets = activeBlock.querySelectorAll(".dhp-root-widget");
        const count = activeBlockWidgets.length;

        for (let i = 0; i < count; i++) {
          const widget = activeBlockWidgets[i];
          const isWidgetLocked = widget.classList.contains("draggable-disabled");
          if (!isWidgetLocked) widget.classList.add("group-selected");
        }

        return {
          block: activeBlock,
          widgets: document.querySelectorAll(".group-selected"),
        };
      },

      start: () => {
        const { block, widgets } = selectable.selectAll.getSelectedBlockAndWidgets();
        selectable.handleMultiSelectionAndUpdateReactDom({ selected: { block, widgets }});
      },
    },

    hideAllWidgetSelection: ({ exceptions = [], type = "single" } = {}) => {
      const config = {
        group: {
          name: "group-selected",
          query: ".group-selected",
        },
        child: {
          name: "child-selected",
          query: ".child-selected",
        },
        single: {
          name: "single-selected",
          query: ".single-selected",
        },
        all: {
          name: ["group-selected", "child-selected", "single-selected"],
          query: ".group-selected, .child-selected, .single-selected",
        },
      };
      const classConfig = config[type] ?? { name: "", query: "" };

      document.querySelectorAll(classConfig.query).forEach(elem => {
        if (!exceptions?.includes(elem.id)) {
          if (type !== "all") elem.classList.remove(classConfig.name);
          else classConfig.name.forEach(cls => elem.classList.remove(cls));
        }
      });
    },

    handleMultiSelectionAndUpdateReactDom: ({ selected, action }) => {
      if (selected.widgets.length >= 2) {
        const selectionArea = {
          left: null,
          right: null,
          top: null,
          bottom: null,
        };
        const selectedWidgetIds = [];
        const selectedWidgetTypes = [];

        const {
          block: { id: blockId, idx: blockIdx, node: blockNode, rect: blockRect },
          page: { id: pageId, idx: pageIdx, node: pageNode },
        } = getWidgetAndParentsDomReference(selected.widgets[0].id);

        selected.widgets.forEach(widget => {
          widget.classList.remove("single-selected");
          widget.classList.remove("drag-selected");
          widget.classList.add("group-selected");
          document
            .querySelectorAll(`#${widget.id} .child-selected`)
            .forEach(child => child.classList.remove("child-selected"));

          selectedWidgetIds.push(widget.id);
          selectedWidgetTypes.push(widget.dataset.assetType);

          const { left, right, top, bottom } = widget.getBoundingClientRect();
          if (!selectionArea.top || top < selectionArea.top) selectionArea.top = top;
          if (!selectionArea.left || left < selectionArea.left) selectionArea.left = left;
          if (!selectionArea.right || right > selectionArea.right) selectionArea.right = right;
          if (!selectionArea.bottom || bottom > selectionArea.bottom) selectionArea.bottom = bottom;
        });

        selectionArea.top = selectionArea.top - blockRect.top;
        selectionArea.left = selectionArea.left - blockRect.left;
        selectionArea.right = selectionArea.right - blockRect.left;
        selectionArea.bottom = selectionArea.bottom - blockRect.top;
        let selectionCss, isRotatedSelection = false;
        // apply selection with the rotate angle apllied in previous selection
        if (document.getElementById("dhp-widget-handler")?.style?.transform && action) {
          const { handlerSelection } = getShiftSelection();
          selectionCss = {
            transform: handlerSelection.transform,
            width: handlerSelection.width,
            height: handlerSelection.height,
          };
          isRotatedSelection = true
          show_GWH({
            activeBlock: selected.block,
            css: selectionCss,
            finalUpdate: true,
            theta: handlerSelection.theta,
            activeWidgetType: selectedWidgetTypes
          });
        } else {
          selectionCss = {
            transform: `translate(${selectionArea.left}px, ${selectionArea.top}px) scale(1, 1) rotate(0deg)`,
            width: `${selectionArea.right - selectionArea.left}px`,
            height: `${selectionArea.bottom - selectionArea.top}px`,
          };
        }
        
        if (!retainRotation && !isRotatedSelection) {
          show_GWH({
            activeBlock: selected.block,
            css: selectionCss,
            finalUpdate: true,
            activeWidgetType: selectedWidgetTypes
          });
        }
        updateMetadata({
          ...metadata,
          pageController: {
            ...metadata.pageController,
            style: {
              ...metadata.pageController.style,
              top:
                blockIdx === 0
                  ? 0
                  : documentType === TYPE_INFOGRAPHIC
                  ? getZoomedValue(blockNode.offsetTop, dimension.zoom) + "px"
                  : pageNode.offsetTop + "px",
            },
          },
          activePageId: pageId,
          activePageIdx: pageIdx,
          activeBlockId: blockId,
          activeBlockIdx: blockIdx,
          activeWidgetId: selectedWidgetIds,
          activeWidgetType: selectedWidgetTypes,
          activeOutSideCanvasArea: false,
          disableAutoScroll: false,
        });
      }

      if (selected.widgets.length === 1) {
        const {
          widget: { id: widgetId, node: widgetNode, data: widgetData },
          block: { id: blockId, idx: blockIdx, node: blockNode },
          page: { id: pageId, idx: pageIdx, node: pageNode },
        } = getWidgetAndParentsDomReference(selected.widgets[0].id);

        widgetNode.classList.remove("drag-selected");
        widgetNode.classList.remove("group-selected");
        setTimeout(() => toggleWidgetEventHandlers({ action: "SHOW" }), 50);

        updateMetadata({
          ...metadata,
          pageController: {
            ...metadata.pageController,
            style: {
              ...metadata.pageController.style,
              top:
                blockIdx === 0
                  ? 0
                  : documentType === TYPE_INFOGRAPHIC
                  ? getZoomedValue(blockNode.offsetTop, dimension.zoom) + "px"
                  : pageNode.offsetTop + "px",
            },
          },
          activePageId: pageId,
          activePageIdx: pageIdx,
          activeBlockId: blockId,
          activeBlockIdx: blockIdx,
          activeWidgetId: [widgetId],
          activeWidgetType: [widgetData.assetType],
          activeOutSideCanvasArea: false,
          disableAutoScroll: false,
        });
      }
    },
    updateHandler: (zoom) => {
      const blockNode = document.getElementById(metadata.activeBlockId);
      const editorOuterWrapTop = document.querySelectorAll(".dhp-page-canvas")[0].getBoundingClientRect().top;
      const activeBlockTop = blockNode.getBoundingClientRect().top;
      const activePageTop = isTimeLineViewOpen ? 0 : Math.abs(activeBlockTop - editorOuterWrapTop);
      let handlerStyle = document.getElementById("dhp-widget-handler").style;
      if (!handlerStyle.transform) return;
      let height = getZoomedValue(getUnScaledValue(handlerStyle.height, dimension.zoom), zoom);
      let width = getZoomedValue(getUnScaledValue(handlerStyle.width, dimension.zoom), zoom);
      const {
        translate: { x: handlerTransX, y: handlerTransY },
        rotate: { theta: handlerTheta },
      } = getCssTransformObj({
        transform: handlerStyle.transform,
      });
      handlerStyle.transform = getCssTransformObj({
        translateX: `${getZoomedValue(getUnScaledValue(handlerTransX, dimension.zoom), zoom)}px`,
        translateY: `${getZoomedValue(getUnScaledValue(handlerTransY, dimension.zoom), zoom)}px`,
        rotateAngle: handlerTheta,
        returnStr: true,
      });
      handlerStyle.width = `${width}px`;
      handlerStyle.height = `${height}px`;
      handlerStyle.top = `${activePageTop}px`;
    },
  };

  useEffect(() => {
    if (!ACTIVE_WIDGET_ID && isDocumentReady) {
      hide_WHT();
      setRetainRotation(false);
      sessionStorage.removeItem("selectionCss");
      selectable.start({ finalUpdate: true });
    }
  }, [ACTIVE_WIDGET_ID]);

  useEffect(() => {
    // if (ACTIVE_WIDGET_STYLE && isDocumentReady && ACTIVE_WIDGET_ID.length === 1) { // Note: Was for bugfix of weired behaviour of drag widgets across pages, shift selection was appearing on source page then moving to dropped page. But the issue is not appearing now. So reverted to previous state as handler not moving for shift selected widgets after clone.
    if (ACTIVE_WIDGET_STYLE && isDocumentReady) {
      if (actionOnRotateModal) return; 
      hide_WHT();
      selectable.start({ finalUpdate: true });
    }
  }, [ACTIVE_WIDGET_STYLE]);

  return {
    start: selectable.start,
    dragSelectStart: selectable.dragSelect.start,
    shiftSelectStart: selectable.shiftSelect.start,
    selectAllStart: selectable.selectAll.start,
    hideAllWidgetSelection: selectable.hideAllWidgetSelection,
    updateHandler: selectable.updateHandler
  };
};

export default useSelectable;
