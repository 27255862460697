import React, { useContext, useEffect, useRef, useState } from "react";
import { EditorContext } from "./EditorLayout";
import { PAGE_PLAY_DATA_STORAGE_KEY } from "../../constants/editor";
import { operateStorageData } from "../../_helpers/utils";
import SliderControll from "../../components/Editor/SliderControll";
import global from "../../scss/dhp.scss";

let style = Object.assign({}, global);

const PageBottomOption = () => {
  const progressbarRef = useRef(null);
  const { pagePlayData, setPagePlayData, setWhichPagePlaying } = useContext(EditorContext);
  const [progress, setProgress] = useState();
  const [updateContext, setUpdateContext] = useState(false);

  const togglePlayOnHoverProgressbar = action => {
    const progressbar = progressbarRef.current;

    if (action === "hoverIn" && pagePlayData.action === "play") {
      const newPagePlayData = {
        action: "pause",
        pageId: pagePlayData.pageId,
        pageIdx: pagePlayData.pageIdx,
      };
      progressbar.dataset.lastAction = "play";
      setPagePlayData({
        ...pagePlayData,
        ...newPagePlayData,
      });
      operateStorageData({
        type: "session",
        action: "set",
        key: PAGE_PLAY_DATA_STORAGE_KEY,
        isParseable: true,
        defaultData: newPagePlayData,
      });
      setWhichPagePlaying({
        action: "pause",
        playingPageIdx: pagePlayData.pageIdx,
      });
    }

    if (action === "hoverOut" && progressbar.dataset.lastAction === "play" && !updateContext) {
      const newPagePlayData = {
        action: "play",
        pageId: pagePlayData.pageId,
        pageIdx: pagePlayData.pageIdx,
      };
      progressbar.dataset.lastAction = "";
      setPagePlayData({
        ...pagePlayData,
        ...newPagePlayData,
      });
      operateStorageData({
        type: "session",
        action: "set",
        key: PAGE_PLAY_DATA_STORAGE_KEY,
        isParseable: true,
        defaultData: newPagePlayData,
      });
      setWhichPagePlaying({
        action: "play",
        playingPageIdx: pagePlayData.pageIdx,
      });
    }
  };

  useEffect(() => {
    if (!updateContext) {
      setProgress(pagePlayData.pageProgress);
    }
  }, [pagePlayData.pageProgress]);

  useEffect(() => {
    if (progress >= 0 && updateContext) {
      if (progress >= 1 && progress <= 99) {
        const timer = (progress * pagePlayData.pageDurationMs) / 100;
        setUpdateContext(false);
        setPagePlayData({
          ...pagePlayData,
          action: "pause",
          pageTimer: timer - (timer % 100),
          pageProgress: progress,
        });
        setWhichPagePlaying({
          action: "pause",
          playingPageIdx: pagePlayData.pageIdx,
          triggerPoint: "slider",
        });
      } else {
        // exit play mode if progress is 0 or 100
        setWhichPagePlaying({
          action: "force_stop",
          playingPageIdx: pagePlayData.pageIdx,
        });
      }
    }
  }, [progress, updateContext]);

  return (
    <div className={style["dhp-page-progress-bar"]} ref={progressbarRef}>
      <SliderControll
        Slider={progress}
        setSlider={setProgress}
        sliderMax={100}
        sliderMin={0}
        setUpdateContext={setUpdateContext}
        hideSliderValue
        events={{
          onMouseEnter: () => togglePlayOnHoverProgressbar("hoverIn"),
          onMouseLeave: () => togglePlayOnHoverProgressbar("hoverOut"),
        }}
      />
      {/* <div className={style["progress-line"]} style={{ width: `${pagePlayData.pageProgress}%` }}></div> */}
    </div>
  );
};

export default PageBottomOption;
