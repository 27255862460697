import { useContext } from "react";
import { TYPE_INFOGRAPHIC } from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";
import useSetDimension from "./useSetDimension";

const useDeletePage = () => {
  let {
    metadata,
    pageNodes,
    updateMetadata,
    updatePageNodes,
    blockNodes,
    backgroundColors,
    backgroundImages,
    widgets,
    updateWidgets,
    documentType,
    dimension,
    audios,
  } = useContext(EditorContext);

  const { setTotalHeight, setAudioDurationBasedOnPageDuration } = useSetDimension();

  const deletePage = () => {
    let targetIndexPrevPart = [],
      targetIndexNextPart = [],
      reindexedPageArray = [];

    pageNodes.slice(0, metadata.activePageIdx).forEach(element => {
      targetIndexPrevPart.push({ ...element });
    });

    pageNodes.slice(metadata.activePageIdx + 1).forEach(element => {
      targetIndexNextPart.push({ ...element });
    });

    reindexedPageArray = targetIndexPrevPart.concat(targetIndexNextPart);

    reindexedPageArray.map((item, idx) => (item["pageIdx"] = idx));

    updatePageNodes(reindexedPageArray);
    deleteBlock(reindexedPageArray);
  };

  const deleteBlock = updatedPageNodesobj => {
    let targetIndexPrevPart = [],
      targetIndexNextPart = [],
      updatedBlockNodeObj = [],
      LinkedWidgetList = [],
      linkedWidgetIncList = [];
    let newArray = Object.assign([...widgets]);

    blockNodes.slice(0, metadata.activeBlockIdx).forEach(element => {
      targetIndexPrevPart.push({ ...element });
    });

    blockNodes.slice(metadata.activeBlockIdx + 1).forEach(element => {
      targetIndexNextPart.push({ ...element });
    });

    updatedBlockNodeObj = targetIndexPrevPart.concat(targetIndexNextPart);

    updatedBlockNodeObj.map((item, idx) => (item["blockIdx"] = idx));

    // update new active block in context
    let activeBlockId =
      metadata.activeBlockIdx === updatedBlockNodeObj.length
        ? updatedBlockNodeObj[metadata.activeBlockIdx - 1].blockId
        : updatedBlockNodeObj[metadata.activeBlockIdx].blockId;
    let activeBlockIdx =
      metadata.activeBlockIdx === updatedBlockNodeObj.length ? metadata.activeBlockIdx - 1 : metadata.activeBlockIdx;
    let activePageId = updatedBlockNodeObj[activeBlockIdx].pageId;
    let activePageIdx = documentType === TYPE_INFOGRAPHIC ? 0 : activeBlockIdx;

    // get the widget list which are linked with the deleted block and the blocks appear after deleted block
    document.querySelectorAll(".dhp-widget-hyperlinked").forEach(element => {
      // widget list that lined to deleted page
      if (
        parseInt(element.getAttribute("data-hyperlink-url")) === parseInt(metadata.activeBlockIdx) &&
        element.getAttribute("id")
      ) {
        LinkedWidgetList.push(element.getAttribute("id"));
      }
      // widget list that pages cames after deleted pages
      if (
        parseInt(element.getAttribute("data-hyperlink-url")) > parseInt(metadata.activeBlockIdx) &&
        element.getAttribute("id")
      ) {
        linkedWidgetIncList.push(element.getAttribute("id"));
      }
    });

    // remove links from widget array
    LinkedWidgetList.forEach(id => {
      let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
      let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
      let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);

      if (isGroupWidget) {
        document.getElementById(id).removeAttribute("data-hyperlink-type");
        document.getElementById(id).removeAttribute("data-hyperlink-url");
        document.getElementById(id).removeAttribute("data-hyperlink-pageid");
        document.getElementById(id).classList.remove("dhp-widget-hyperlinked");

        newArray = Object.assign([...newArray], {
          [targetWidgetIndex]: {
            ...widgets[targetWidgetIndex],
            innerHTML: document.getElementById(targetId).innerHTML,
          },
        });
      } else {
        let modifiedArray = JSON.parse(JSON.stringify(widgets));

        delete modifiedArray[targetWidgetIndex].data["data-hyperlink-type"];
        delete modifiedArray[targetWidgetIndex].data["data-hyperlink-url"];
        delete modifiedArray[targetWidgetIndex].data["data-hyperlink-pageid"];

        let newDataAttr = modifiedArray[targetWidgetIndex].data;

        newArray = Object.assign([...newArray], {
          [targetWidgetIndex]: {
            ...widgets[targetWidgetIndex],
            data: newDataAttr,
            classLists: widgets[targetWidgetIndex].classLists.filter(
              className => className !== "dhp-widget-hyperlinked"
            ),
          },
        });
      }
    });

    //update links in widget array
    linkedWidgetIncList.forEach(id => {
      let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
      let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
      let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
      let linkedPageIdx = parseInt(document.getElementById(id).getAttribute("data-hyperlink-url"));

      if (isGroupWidget) {
        document.getElementById(id).setAttribute("data-hyperlink-url", linkedPageIdx - 1);

        newArray = Object.assign([...newArray], {
          [targetWidgetIndex]: {
            ...widgets[targetWidgetIndex],
            innerHTML: document.getElementById(targetId).innerHTML,
          },
        });
      } else {
        newArray = Object.assign([...newArray], {
          [targetWidgetIndex]: {
            ...widgets[targetWidgetIndex],
            data: {
              ...widgets[targetWidgetIndex].data,
              "data-hyperlink-url": linkedPageIdx - 1,
            },
          },
        });
      }
    });

    // remove active block and used widgets, bg color, bg image in active block from context at a time
    if (updatedPageNodesobj) {
      //audios which have same end time like doc duration, update thet audio's end time also as page duration time update
      if (audios?.length > 0) {
        let newAudioArray = setAudioDurationBasedOnPageDuration(
          pageNodes[metadata.activePageIdx].pageDuration,
          "delete-page"
        );
        updateWidgets(
          newArray.filter(widget => widget.blockId !== metadata.activeBlockId),
          updatedPageNodesobj,
          updatedBlockNodeObj,
          backgroundColors.filter(backgroundColor => backgroundColor.blockId !== metadata.activeBlockId),
          backgroundImages.filter(backgroundImage => backgroundImage.blockId !== metadata.activeBlockId),
          activeBlockIdx,
          false,
          false,
          false,
          newAudioArray
        );
      } else
        updateWidgets(
          newArray.filter(widget => widget.blockId !== metadata.activeBlockId),
          updatedPageNodesobj,
          updatedBlockNodeObj,
          backgroundColors.filter(backgroundColor => backgroundColor.blockId !== metadata.activeBlockId),
          backgroundImages.filter(backgroundImage => backgroundImage.blockId !== metadata.activeBlockId),
          activeBlockIdx
        );
    } else
      updateWidgets(
        newArray.filter(widget => widget.blockId !== metadata.activeBlockId),
        false,
        updatedBlockNodeObj,
        backgroundColors.filter(backgroundColor => backgroundColor.blockId !== metadata.activeBlockId),
        backgroundImages.filter(backgroundImage => backgroundImage.blockId !== metadata.activeBlockId),
        activeBlockIdx
      );

    updateMetadata({
      ...metadata,
      activePageId: activePageId,
      activePageIdx: activePageIdx,
      activeBlockId: activeBlockId,
      activeBlockIdx: activeBlockIdx,
      activeWidgetId: false,
      activeWidgetType: false,
      activeOutSideCanvasArea: false,
      disableAutoScroll: false,
    });

    if (documentType !== TYPE_INFOGRAPHIC) setTotalHeight(updatedBlockNodeObj, dimension.label);
    else setTotalHeight(updatedBlockNodeObj);
  };

  return { deletePage, deleteBlock };
};

export default useDeletePage;
