import { useCallback, useEffect, useRef, useState } from "react";

const useFullScreen = (isFullScreen, showLaserCursor, currentSlide, totalSlides, origin ) => {
  const [fullScreen, setFullScreen] = useState(isFullScreen);
  const intervalId = useRef(null);
  const idleDurationSecs = 6; // X number of seconds

  const resetIdleTimeout = () => {
    // Clears the existing timeout
    if (intervalId.current) {
      if (!showLaserCursor) document.getElementsByTagName("body")[0].style.cursor = "auto";
      document.getElementsByClassName("editor-navigation")[0]?.classList?.remove("d-none");
      clearInterval(intervalId.current);
    }
    intervalId.current = setInterval(() => {
      if (!showLaserCursor) document.getElementsByTagName("body")[0].style.cursor = "none";
      else {
        document.getElementsByClassName("cursor")[0]?.classList?.add("d-none");
        document.getElementsByClassName("cursor-follower")[0]?.classList?.add("d-none");
      }
      document.getElementsByClassName("editor-navigation")[0]?.classList?.add("d-none");
    }, idleDurationSecs * 1000);
  };

  // handle touch swipe
  let touchStartX = 0;
  let touchEndX = 0;
  const handleTouchStart = (e) => {
    touchStartX = e.changedTouches[0].clientX;
  }
  const handleTouchEnd = (e) => {
    touchEndX = e.changedTouches[0].clientX;
    if (touchEndX - touchStartX > 50 && currentSlide !== 1) {
      // Swipe to the right
      document.getElementById("prev")?.click();
    } else if (touchStartX - touchEndX > 50 && currentSlide !== totalSlides) {
      // Swipe to the left
      document.getElementById("next")?.click()
    }
  }

  useEffect(() => {
    if (!fullScreen) {
      document.getElementsByClassName("editor-navigation")[0]?.classList?.remove("d-none");
      clearInterval(intervalId.current);
      return;
    }

    ["click", "touchstart", "mousemove", "keydown"].forEach(evt => document.addEventListener(evt, resetIdleTimeout));

    return () => {
      ["click", "touchstart", "mousemove", "keydown"].forEach(evt =>
        document.removeEventListener(evt, resetIdleTimeout)
      );
    };
  }, [fullScreen, currentSlide, totalSlides]);

  const toggleFullScreen = useCallback(isFullScreen => {
    let docElm = document.documentElement;
    if (!isFullScreen) {
      if (docElm.requestFullscreen) docElm.requestFullscreen();
      else if (docElm.mozRequestFullScreen) docElm.mozRequestFullScreen();
      else if (docElm.webkitRequestFullScreen) docElm.webkitRequestFullScreen();
      else if (docElm.msRequestFullscreen) docElm.msRequestFullscreen();
      setFullScreen(true);
    } else {
      if (document.exitFullscreen) document.exitFullscreen();
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
      else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
      else if (document.msExitFullscreen) document.msExitFullscreen();
      setFullScreen(false);
    }
  }, [isFullScreen]);

  const escapeFullScreen = useCallback(() => {
    if (window.innerHeight !== screen.height) setFullScreen(false);
  }, []);

  useEffect(() => {
    if(origin!=="play")document.getElementsByTagName("body")[0].style.userSelect = "none";
    ["fullscreenchange", "webkitfullscreenchange", "mozfullscreenchange", "MSFullscreenChange"].forEach(evt =>
      document.addEventListener(evt, escapeFullScreen)
    );

    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd)

    return () => {
      if(origin!=="play") document.getElementsByTagName("body")[0].style.userSelect = "";
      ["fullscreenchange", "webkitfullscreenchange", "mozfullscreenchange", "MSFullscreenChange"].forEach(evt =>
        document.removeEventListener(evt, escapeFullScreen)
      );
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd)
    };
  }, [currentSlide, totalSlides]);

  return [fullScreen, toggleFullScreen];
};

export default useFullScreen;
