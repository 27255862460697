import React, { useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Tooltip } from "reactstrap";

import global from "../../../scss/dhp.scss";
import { Icon } from "../../../components/ui/icon";

let style = Object.assign({}, global);

const BrandkitTabPaneSubTitle = ({ title, isIcon = "block", tooltipId, tooltipContent }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <div className={cx(style["d-flex"], style["align-items-center"], style["justify-content-between"])}>
      <span className="fw-7 mb-2">{title}</span>
      {isIcon === "block" && (
        <>
          <span className={cx(style[`d-${isIcon}`], style["cursor-pointer"])} id={tooltipId ?? "defaultId"}>
            <Icon icon="ui-info" additionalclass={"font-base"} />
          </span>
          <Tooltip placement="bottom" isOpen={tooltipOpen} target={tooltipId ?? "defaultId"} toggle={toggle}>
            {tooltipContent}
          </Tooltip>
        </>
      )}
    </div>
  );
};
BrandkitTabPaneSubTitle.propTypes = {
  title: PropTypes.string.isRequired,
  isIcon: PropTypes.string,
  tooltipId: PropTypes.string,
  tooltipContent: PropTypes.string,
};

export default BrandkitTabPaneSubTitle;
