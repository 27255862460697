import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { TABLE, TEXT } from "../../../constants/editor";
import useFontFormatting from "../../../hooks/useFontFormatting";

let style = Object.assign({}, global);

const Bold = ({ defaultFontBold, setFontBold, isDisabledBold, sourceComponent }) => {
  let { metadata, widgets, doubleClickActive, selectionRange } = useContext(EditorContext);

  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
  let timeOut;

  const [isBold, setIsBold] = useState();
  const [isBoldDisable, setIsBoldDisable] = useState(isDisabledBold ?? false);

  const { updateFontWeight, checkSelectedPartFormat } = useFontFormatting();

  const fonts = JSON.parse(localStorage?.getItem("allFonts"));

  const handleOnClick = () => {
    // if double click active return false cause selcted part will be bold
    if (doubleClickActive) return;

    // set bold for table
    if (sourceComponent === TABLE) {
      setFontBold(!defaultFontBold);
      return;
    }

    updateFontWeight(isBold);

    isBold ? setIsBold(false) : setIsBold(true);
  };

  const loadCurrentFormatting = () => {
    timeOut = setTimeout(() => {
      if (metadata.activeWidgetId && sourceComponent !== TABLE) {
        let innerHtmlflag = document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`);

        if (innerHtmlflag) {
          let currentFontFamily = innerHtmlflag.style.fontFamily;
          let index = fonts.findIndex(
            font => font.name.toLowerCase() === currentFontFamily.replace(/["]+/g, "").toLowerCase()
          );

          if (innerHtmlflag.style.fontStyle === "italic" && fonts[index]?.source !== "Custom")
            fonts[index]?.bold_italic_weight === "" ? setIsBoldDisable(true) : setIsBoldDisable(false);
          else fonts[index]?.bold_weight === "" ? setIsBoldDisable(true) : setIsBoldDisable(false);
          parseInt(innerHtmlflag.style.fontWeight) === parseInt(fonts[index].normal_weight)
            ? setIsBold(false)
            : setIsBold(true);
        }
      }
    }, 10);
  };

  // Load current formatting for selected part of text
  useEffect(() => {
    if (selectionRange) {
      let currentFontFamily = document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`).style
        .fontFamily;
      let index = fonts.findIndex(
        font => font.name.toLowerCase() === currentFontFamily.replace(/["]+/g, "").toLowerCase()
      );
      let { isBoldSelPart, isaItalicSelPart } = checkSelectedPartFormat();

      setIsBold(isBoldSelPart);
      if (isaItalicSelPart) fonts[index]?.bold_italic_weight === "" ? setIsBoldDisable(true) : setIsBoldDisable(false);
      else fonts[index]?.bold_weight === "" ? setIsBoldDisable(true) : setIsBoldDisable(false);
    }
  }, [selectionRange]);

  // Load current formatting for text
  useEffect(() => {
    if (metadata.activeWidgetType[0] === TEXT) {
      loadCurrentFormatting();
    }
    return () => {
      setIsBoldDisable();
      setIsBold();
      clearTimeout(timeOut);
    };
  }, [metadata.activeWidgetId, widgets[targetWidgetIndex]?.innerHTML]);

  // Disable bold option for table modal font-family change
  useEffect(() => {
    setIsBoldDisable(isDisabledBold);
  }, [isDisabledBold]);

  // Set font bold for table
  useEffect(() => {
    setIsBold(defaultFontBold);
  }, [defaultFontBold]);

  return (
    <li
      className={cx(
        style["toolset-group"],
        {
          [style["active"]]: isBold,
        },
        {
          [style["disabled"]]: isBoldDisable,
        }
      )}>
      <div className={cx(style["toolset-item"], style["bold-wrap"])} id="bold-wrap">
        <span className={cx(style["toolset-action"], style["custom-tooltip"])} onClick={handleOnClick}>
          <Icon icon="bold2" />
          <div className={cx(style["custom-tooltip-content"], style["top"])}>Bold</div>
        </span>
      </div>
    </li>
  );
};

Bold.propTypes = {
  isDisabledBold: PropTypes.bool,
  sourceComponent: PropTypes.string,
  defaultFontBold: PropTypes.bool,
  setFontBold: PropTypes.func,
};

export default Bold;
