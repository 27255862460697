import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import cx from "classnames";
import global from "../../../scss/dhp.scss";

import ColorBucket from "./ColorBucket";
import {
  changeBucketColorAccordingBackground,
  getRandomString,
  hexToRGB,
  RGBToHex,
  splitLinerGradientValues,
} from "../../../_helpers/utils";
import MulticolorBucket from "./MulticolorBucket";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { ICON, ILLUSTRATION, SHAPE, STICKER, SVG, TEXT, TEXT_FRAME, UPLOAD } from "../../../constants/editor";

let style = Object.assign({}, global);

const Color = () => {
  let {
    metadata,
    widgets,
    updateWidgets,
    changeBySocket,
    doubleClickActive,
    setTextSelectedAreaColor,
    selectionRange,
    doubleClickSelctedareaColor,
  } = useContext(EditorContext);
  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);

  const [color, setColor] = useState();
  const [multiColors, setMultiColors] = useState([]);
  const [multiColorDatas, setMultiColorDatas] = useState([]);
  const [previousColor, setPreviousColor] = useState();
  const [currentColor, setCurrentColor] = useState();
  const [iconBucketColor, setIconBucketColor] = useState("dark");
  const [loadCurrentFormatting, setLoadCurrentFormatting] = useState(true);
  const [updateContext, setUpdateContext] = useState(false);
  const [eyedropperColor, setEyedropperColor] = useState();
  const [colorPickerGradient, setColorPickerGradient] = useState();
  const [isGradientActive, setIsGradientActive] = useState();

  const fetchGradientColorFromActiveWidget = () => {
    // if active widget has gradient color
    if (document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-grad-scolor")) {
      let multiColorSet = [];
      let curElem = document.getElementById(metadata.activeWidgetId[0]);

      setLoadCurrentFormatting(true);
      setIsGradientActive(true);
      setColorPickerGradient(
        `linear-gradient(${curElem.getAttribute("data-grad-degree")}deg, ${curElem.getAttribute(
          "data-grad-scolor"
        )} ${curElem.getAttribute("data-grad-spercen")}, ${curElem.getAttribute(
          "data-grad-ecolor"
        )} ${curElem.getAttribute("data-grad-epercen")})`
      );
      multiColorSet.push(curElem.getAttribute("data-grad-scolor"));
      setMultiColors(multiColorSet);
      setColor(multiColorSet);
    }
    // if active widget has not any gradient color applied yet
    else {
      fetchColorFromActiveWidget();

      // do not show gradient option if text has background color applied
      if (
        metadata.activeWidgetType[0] === TEXT &&
        document
          .querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`)
          .classList.contains("text-background-active")
      ) {
        setColorPickerGradient(false);
      } else setColorPickerGradient(true);
    }
  };

  const fetchColorFromActiveWidget = () => {
    if (metadata.activeWidgetId) {
      let multiColorDataClassSet = [];
      let multiColorSet = [];
      let multiColorDataSet = [];
      let multicolorClassSet = [];

      if (
        metadata.activeWidgetType[0] === ILLUSTRATION ||
        metadata.activeWidgetType[0] === STICKER ||
        metadata.activeWidgetType[0] === ICON ||
        metadata.activeWidgetType[0] === TEXT_FRAME ||
        (metadata.activeWidgetType[0] === UPLOAD &&
          document.getElementById(metadata.activeWidgetId[0])?.dataset?.fileType === SVG)
      ) {
        document.querySelectorAll(`#${metadata.activeWidgetId[0]} svg *`).forEach(function (node) {
          let dataClassName = node.dataset.class;

          if (node.classList.value !== "") multicolorClassSet.push(node.classList.value);

          if (dataClassName) {
            let currentIconColor =
              window.getComputedStyle(node).fill === "rgba(0, 0, 0, 0)"
                ? "transparent"
                : window.getComputedStyle(node).fill;
            currentIconColor = RGBToHex(currentIconColor);

            if (!multiColorDataClassSet?.includes(dataClassName) && currentIconColor !== "") {
              multiColorDataClassSet.push(dataClassName);

              multiColorDataSet.push({
                color: currentIconColor,
                className: dataClassName,
              });

              multiColorSet.push(hexToRGB(currentIconColor));
            }
          }
        });

        //Manage Color for mono color Uploaded svg
        if (
          multiColorSet.length === 0 &&
          multicolorClassSet.length === 0 &&
          (metadata.activeWidgetType[0] === UPLOAD || metadata.activeWidgetType[0] === ICON)
        ) {
          let monoColor = document.querySelector(`#${metadata.activeWidgetId[0]} svg`)?.style?.fill;
          multiColorSet.push(monoColor);
          monoColor && setColor(RGBToHex(monoColor));
        }
        setMultiColorDatas(multiColorDataSet);
        setMultiColors(multiColorSet);
      }
      //Fetch color for Text widget
      else if (metadata.activeWidgetType[0] === TEXT) {
        let activeElm = document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`);
        let textColor = activeElm.style.color;

        activeElm.querySelectorAll("*").forEach(node => {
          if (node.style?.color && node.style?.color !== textColor) {
            textColor = "Mixed Colors";
          }
        });

        multiColorSet.push(textColor);
        setMultiColors(multiColorSet);
        setColor(textColor === "transparent" || textColor === "Mixed Colors" ? textColor : RGBToHex(textColor));
      }
      //Fetch color for mono svg
      else {
        let svgColor = document.querySelector(`#${metadata.activeWidgetId[0]} svg`)?.style.fill;
        if (svgColor) {
          multiColorSet.push(svgColor);
          setMultiColors(multiColorSet);
          setColor(svgColor === "transparent" ? svgColor : RGBToHex(svgColor));
        }
      }
      setLoadCurrentFormatting(true);
    }
  };

  const getNode = () => {
    let node;
    if (document.querySelector(`#${metadata.activeWidgetId[0]} svg rect`)) {
      node = document.querySelector(`#${metadata.activeWidgetId[0]} svg rect`);
    } else if (document.querySelector(`#${metadata.activeWidgetId[0]} svg circle`)) {
      node = document.querySelector(`#${metadata.activeWidgetId[0]} svg circle`);
    } else if (document.querySelector(`#${metadata.activeWidgetId[0]} svg polygon`)) {
      node = document.querySelector(`#${metadata.activeWidgetId[0]} svg polygon`);
    } else if (document.querySelector(`#${metadata.activeWidgetId[0]} svg path`)) {
      node = document.querySelector(`#${metadata.activeWidgetId[0]} svg path`);
    }
    return node;
  };

  //Apply gradient color to text and Shape when gradient is active
  useEffect(() => {
    if (colorPickerGradient && isGradientActive) {
      let { gradDeg, startColor, startPercent, endColor, endPercent } = splitLinerGradientValues(colorPickerGradient);
      if (!startColor || startColor === "undefined") return;

      if (!loadCurrentFormatting) {
        let newArray;

        if (metadata.activeWidgetType[0] === TEXT) {
          let activeNode = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner");
          activeNode.style.backgroundImage = colorPickerGradient;
          activeNode.style.webkitTextFillColor = "transparent";

          //Support text decoration for gradient text
          if (activeNode.style.textDecoration && activeNode.style.textDecoration !== "none")
            activeNode.style.textDecorationColor = activeNode.style.color;
        }

        if (metadata.activeWidgetType[0] === SHAPE) {
          let newSvgNode = document.createElement("defs");
          let gradId = `grad_${getRandomString(8)}`;
          let node = document.querySelector(`#${metadata.activeWidgetId[0]} svg`);
          if (node.querySelector("linearGradient")) node.querySelector("linearGradient").remove();
          let svgPath = getNode();
          svgPath.style.fill = `url(#${gradId})`;

          newSvgNode.innerHTML = `<linearGradient id="${gradId}" x1="0%" y1="0%" x2="100%" y2="0%" gradientTransform="rotate(${gradDeg})">
        <stop offset="${startPercent}" style="stop-color:${startColor};stop-opacity:1"></stop>
        <stop offset="${endPercent}" style="stop-color:${endColor};stop-opacity:1"></stop>
        </linearGradient> ${node.innerHTML}`;

          document.querySelector(`#${metadata.activeWidgetId[0]} svg`).innerHTML = newSvgNode.innerHTML;
        }

        if (isGroupWidget) {
          document.getElementById(metadata.activeWidgetId[0]).setAttribute("data-grad-degree", gradDeg);
          document.getElementById(metadata.activeWidgetId[0]).setAttribute("data-grad-scolor", startColor);
          document.getElementById(metadata.activeWidgetId[0]).setAttribute("data-grad-spercen", startPercent);
          document.getElementById(metadata.activeWidgetId[0]).setAttribute("data-grad-ecolor", endColor);
          document.getElementById(metadata.activeWidgetId[0]).setAttribute("data-grad-epercen", endPercent);

          newArray = Object.assign([...widgets], {
            [targetWidgetIndex]: {
              ...widgets[targetWidgetIndex],
              innerHTML: document.getElementById(targetId).innerHTML,
            },
          });
        } else {
          newArray = Object.assign([...widgets], {
            [targetWidgetIndex]: {
              ...widgets[targetWidgetIndex],
              data: {
                ...widgets[targetWidgetIndex].data,
                "data-grad-degree": gradDeg,
                "data-grad-scolor": startColor,
                "data-grad-spercen": startPercent,
                "data-grad-ecolor": endColor,
                "data-grad-epercen": endPercent,
              },
              innerHTML: document.getElementById(targetId).innerHTML,
            },
          });
        }

        updateContext && updateWidgets(newArray);
      }

      setIconBucketColor(
        changeBucketColorAccordingBackground([
          startColor === "transparent" ? startColor : hexToRGB(startColor),
          endColor === "transparent" ? endColor : hexToRGB(endColor),
        ])
      );
    }
  }, [colorPickerGradient, isGradientActive, updateContext]);

  //update color if user chose color from eyedropper
  useEffect(() => {
    if (eyedropperColor) {
      setUpdateContext(true);
      setColor(eyedropperColor);
      setEyedropperColor();
    }
  }, [eyedropperColor]);

  //Apply color to svg when gradient is not active
  useEffect(() => {
    if (isGradientActive) return;

    if (color || multiColors) {
      let sourceColor = color
        ? color === "transparent" || color === "Mixed Colors"
          ? color
          : hexToRGB(color)
        : multiColors;

      if (!loadCurrentFormatting) {
        let newArray;

        if (
          metadata.activeWidgetType[0] === ILLUSTRATION ||
          metadata.activeWidgetType[0] === STICKER ||
          metadata.activeWidgetType[0] === ICON ||
          metadata.activeWidgetType[0] === TEXT_FRAME ||
          (metadata.activeWidgetType[0] === UPLOAD &&
            document.getElementById(metadata.activeWidgetId[0])?.dataset?.fileType === SVG)
        ) {
          document.querySelectorAll(`#${metadata.activeWidgetId[0]} svg *`).forEach(function (node) {
            let dataClassName = node.dataset.class;
            if (previousColor && previousColor === dataClassName) {
              node.style.fill = currentColor;
            }
          });

          //Apply color for mono svg in upload widget
          if (
            multiColors.length === 1 &&
            (metadata.activeWidgetType[0] === UPLOAD || metadata.activeWidgetType[0] === ICON)
          ) {
            document.querySelector(`#${metadata.activeWidgetId[0]} svg`).style.fill = color;
          }
        }
        //Apply color for text widget
        else if (metadata.activeWidgetType[0] === TEXT) {
          if (doubleClickActive) {
            let sel = window.getSelection();
            if (sel.rangeCount) setTextSelectedAreaColor(color);
          } else {
            let activeNode = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner");

            // Remove Gradient
            if (document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-grad-scolor")) {
              activeNode.style.backgroundImage = "";
              activeNode.style.webkitTextFillColor = "";

              //Support text decoration for gradient text
              if (activeNode.style.textDecoration && activeNode.style.textDecoration !== "none") {
                let decorationValue = activeNode.style.textDecoration.split(" ")[0];
                activeNode.style.textDecorationColor = "";
                activeNode.style.textDecorationLine = "";
                activeNode.style.textDecorationThickness = "";
                activeNode.style.textDecorationStyle = "";
                activeNode.style.textDecoration = decorationValue;
              }
            }

            activeNode.style.color = color;

            // remove formatting from all child div and apply main formatting
            activeNode.querySelectorAll("*").forEach(node => {
              if (node.style?.color) {
                node.style.color = color;
              }
            });
          }
        }
        //Apply color for Shape widget
        else if (metadata.activeWidgetType[0] === SHAPE) {
          // Remove Gradient
          if (document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-grad-scolor")) {
            let node = document.querySelector(`#${metadata.activeWidgetId[0]} svg`);
            if (node.querySelector("linearGradient")) node.querySelector("linearGradient").remove();
            let svgPath = getNode();
            svgPath.style.fill = "";
          }

          document.querySelector(`#${metadata.activeWidgetId[0]} svg`).style.fill = color;
        }
        //Apply color for mono svg widget
        else {
          document.querySelector(`#${metadata.activeWidgetId[0]} svg`).style.fill = color;
        }

        if (isGroupWidget) {
          document.getElementById(metadata.activeWidgetId[0]).removeAttribute("data-grad-degree");
          document.getElementById(metadata.activeWidgetId[0]).removeAttribute("data-grad-scolor");
          document.getElementById(metadata.activeWidgetId[0]).removeAttribute("data-grad-spercen");
          document.getElementById(metadata.activeWidgetId[0]).removeAttribute("data-grad-ecolor");
          document.getElementById(metadata.activeWidgetId[0]).removeAttribute("data-grad-epercen");

          newArray = Object.assign([...widgets], {
            [targetWidgetIndex]: {
              ...widgets[targetWidgetIndex],
              innerHTML: document.getElementById(targetId).innerHTML,
            },
          });
        } else {
          let modifiedArray = JSON.parse(JSON.stringify(widgets));

          delete modifiedArray[targetWidgetIndex].data["data-grad-degree"];
          delete modifiedArray[targetWidgetIndex].data["data-grad-scolor"];
          delete modifiedArray[targetWidgetIndex].data["data-grad-spercen"];
          delete modifiedArray[targetWidgetIndex].data["data-grad-ecolor"];
          delete modifiedArray[targetWidgetIndex].data["data-grad-epercen"];

          let newDataAttr = modifiedArray[targetWidgetIndex].data;

          newArray = Object.assign([...widgets], {
            [targetWidgetIndex]: {
              ...widgets[targetWidgetIndex],
              data: newDataAttr,
              innerHTML: document.getElementById(targetId).innerHTML,
            },
          });
        }

        updateContext && updateWidgets(newArray);
        setUpdateContext(false);
      }
      setIconBucketColor(changeBucketColorAccordingBackground(sourceColor));
    }
  }, [color, multiColors, updateContext, isGradientActive]);

  // Fetch color from selected part of text
  useEffect(() => {
    if (selectionRange && !["bold", "italic", "underline"].includes(selectionRange)) {
      let sel, activeHtml, selectedPart, parentEl;

      sel = window.getSelection();

      // get parent elemet of selected part
      if (window.getSelection) {
        if (sel.rangeCount) {
          parentEl = sel.getRangeAt(0).commonAncestorContainer;
          if (parentEl.nodeType != 1) {
            parentEl = parentEl.parentNode;
          }
        }
      }

      // get html of selected part
      if (sel.rangeCount) {
        let container = document.createElement("div");
        for (let i = 0, len = sel.rangeCount; i < len; ++i) {
          container.appendChild(sel.getRangeAt(i).cloneContents());
        }
        activeHtml = container.innerHTML;
        selectedPart = document.createElement("span");
        selectedPart.innerHTML = activeHtml;
      }

      let parentColor = parentEl?.style?.color
        ? parentEl.style?.color
        : document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`).style.color;
      let selColor = parentColor;

      selectedPart?.querySelectorAll("*").forEach(node => {
        if (node.style?.color && parentColor !== node.style?.color) {
          selColor = "Mixed Colors";
        }
      });

      selColor = doubleClickSelctedareaColor ? doubleClickSelctedareaColor : selColor;

      setMultiColors(selColor);
      setColor(selColor === "transparent" || selColor === "Mixed Colors" ? selColor : RGBToHex(selColor));
      setLoadCurrentFormatting(true);
    }
  }, [selectionRange, doubleClickSelctedareaColor]);

  //Fetch color from active widget
  useEffect(() => {
    // set gradient color option only for text widget and mentioed shapes
    if (
      metadata.activeWidgetType[0] === TEXT ||
      (metadata.activeWidgetType[0] === SHAPE &&
        ["Basic", "Circles", "Triangles", "Rectangles"].includes(
          document.getElementById(id).getAttribute("data-category")
        ) &&
        parseInt(document.getElementById(id).getAttribute("data-version")) >= 3)
    ) {
      fetchGradientColorFromActiveWidget();
    } else {
      fetchColorFromActiveWidget();
      setColorPickerGradient(false);
    }

    return () => {
      setColor();
      setMultiColors([]);
      setMultiColorDatas([]);
      setIsGradientActive();
      setColorPickerGradient();
    };
  }, [metadata.activeWidgetId]);

  //Fetch color from active widget for collaboration
  useEffect(() => {
    if (changeBySocket && widgets[targetWidgetIndex]?.innerHTML) {
      setTimeout(() => {
        fetchColorFromActiveWidget();
        setLoadCurrentFormatting(false);
        setUpdateContext(false);
      }, 10);
    }
  }, [changeBySocket, widgets[targetWidgetIndex]?.innerHTML]);

  //Fetch color from active widget when double click is active
  useEffect(() => {
    if (metadata.activeWidgetType[0] === TEXT) {
      if (!doubleClickActive) fetchGradientColorFromActiveWidget();
      else setColorPickerGradient(false);
    }
  }, [doubleClickActive]);

  return (
    multiColors.length > 0 && (
      <>
        <li className={style["toolset-group"]}>
          <div className={cx(style["toolset-item"], style["color-wrap"])}>
            <span className={cx(style["toolset-action"])}>
              {multiColorDatas.length === 0 && color !== undefined && (
                <ColorBucket
                  color={color}
                  setColor={setColor}
                  iconBucketColor={iconBucketColor}
                  position={"toolbar"}
                  loadCurrentFormatting={loadCurrentFormatting}
                  setLoadCurrentFormatting={setLoadCurrentFormatting}
                  setUpdateContext={setUpdateContext}
                  eyedropperColor={eyedropperColor}
                  setEyedropperColor={setEyedropperColor}
                  colorPickerGradient={colorPickerGradient}
                  setColorPickerGradient={setColorPickerGradient}
                  isGradientActive={isGradientActive}
                  setIsGradientActive={setIsGradientActive}
                />
              )}

              {multiColorDatas.length > 0 && (
                <MulticolorBucket
                  multiColors={multiColors}
                  setMultiColors={setMultiColors}
                  multiColorDatas={multiColorDatas}
                  setMultiColorDatas={setMultiColorDatas}
                  setPreviousColor={setPreviousColor}
                  setCurrentColor={setCurrentColor}
                  iconBucketColor={iconBucketColor}
                  position={"toolbar"}
                  setLoadCurrentFormatting={setLoadCurrentFormatting}
                  setUpdateContext={setUpdateContext}
                />
              )}
            </span>
          </div>
        </li>
      </>
    )
  );
};

export default Color;
