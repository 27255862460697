import React from "react";
import PropTypes from "prop-types";

import global from "../../scss/dhp.scss";

let style = Object.assign({}, global);

const Progressbar = props => {
  return (
    <div
      className={style["progress-bar"]}
      role="progressbar"
      aria-valuenow={props.progressbarInfo}
      aria-valuemin="0"
      aria-valuemax="100"
      style={{ width: props.progressbarInfo + "%" }}></div>
  );
};

Progressbar.propTypes = {
  progressbarInfo: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

export default Progressbar;
