import React, { useContext } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import usePasteWidget from "../../../hooks/usePasteWidget";
import { TYPE_INFOGRAPHIC } from "../../../constants/editor";
import { getZoomedValue } from "../../../_helpers/utils";

let style = Object.assign({}, global);

const Paste = props => {
  let {
    keyBoardEvent,
    updateKeyBoardEvent,
    rightContextMenu,
    updateRightContextMenu,
    metadata,
    documentType,
    dimension,
  } = useContext(EditorContext);

  const { pasteObj } = usePasteWidget();

  const pasteMethod = () => {
    let parentRect = document.querySelector(".generic-editor-wrap").getBoundingClientRect();
    let targetLeft = rightContextMenu.style.left - parentRect.left;
    let targetTop = rightContextMenu.style.top - parentRect.top;

    if (documentType === TYPE_INFOGRAPHIC) {
      let totalHeight = 0;
      document.querySelectorAll(".dhp-page-block").forEach(element => {
        if (parseInt(element.getAttribute("data-block-idx")) < metadata.activeBlockIdx) {
          let zoomedHeight = getZoomedValue(parseFloat(element.style.height), dimension.zoom);
          totalHeight = totalHeight + zoomedHeight;
        }
      });
      targetTop = targetTop - totalHeight;
    } else {
      targetTop =
        targetTop -
        (parseFloat(document.querySelector(".dhp-page-canvas").style.height) * metadata.activeBlockIdx +
          24 * metadata.activeBlockIdx);
    }

    updateKeyBoardEvent({ ...keyBoardEvent, paste: true });

    if (props.rightContext) {
      pasteObj(targetLeft, targetTop);
      updateRightContextMenu({
        ...rightContextMenu,
        enable: false,
      });
    } else pasteObj();
  };

  return (
    <li
      className={cx({
        [style["custom-tooltip"]]: !props.rightContext,
      })}>
      <span className={style["toolset-action"]} onClick={pasteMethod}>
        <Icon icon="ui-paste" />
        {props.rightContext && (
          <>
            Paste<span>Ctrl+V</span>
          </>
        )}
      </span>
      {!props.rightContext && <div className={style["custom-tooltip-content"]}>Paste</div>}
    </li>
  );
};

Paste.propTypes = {
  rightContext: PropTypes.bool,
};

export default Paste;
