import React, { useContext } from "react";
import cx from "classnames";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { TABLE } from "../../../constants/editor";

let style = Object.assign({}, global);

const TableToolBar = () => {
  let { metadata } = useContext(EditorContext);
  let isGroupWidget = document.getElementById(metadata.activeWidgetId[0])?.closest(".dhp-page-group");

  // handle click on table from toolbar
  const handleTableIconClick = () => {
    const element = document.getElementById(metadata?.activeWidgetId[0]);
    if (metadata?.activeWidgetType[0] === TABLE && element) {
      const dblClickEvent = new MouseEvent("dblclick", {
        bubbles: true,
        cancelable: true,
        view: window,
      });

      // Dispatch the double-click event
      element.dispatchEvent(dblClickEvent);
    }
  };

  return (
    <li className={cx(style["toolset-group"], { [style["disabled"]]: isGroupWidget })}>
      <div className={cx(style["toolset-item"], style["qr-wrap"])}>
        <span className={cx(style["toolset-action"], style["custom-tooltip"])} onClick={handleTableIconClick}>
          <Icon icon="ui-table" />

          <div className={cx(style["custom-tooltip-content"], style["top"])}>Update Table</div>
        </span>
      </div>
    </li>
  );
};

export default TableToolBar;
