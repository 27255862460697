import cx from "classnames";
import React from "react";
import PropTypes from "prop-types";

import global from "../../scss/dhp.scss";

import { Icon } from "../ui/icon";

let style = Object.assign({}, global);

const FitOnScreen = props => {
  return (
    <>
      <li className={style["custom-tooltip"]}>
        <div onClick={e => (e.preventDefault(), props.setFitToScreen(true))} className={cx(style["custom-tooltip"], style["link-white"])} id="fitOnScreen">
          <Icon icon="ui-expand" />
          <div className={cx(style["custom-tooltip-content"], style["top"])}>Fit on Screen</div>
        </div>
      </li>
    </>
  );
};

//Props type validation
FitOnScreen.propTypes = {
  setFitToScreen: PropTypes.func.isRequired,
};

export default FitOnScreen;
