import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";

import { EditorContext } from "../containers/editor/EditorLayout";
import useClipboard from "./useClipboard";
import { TYPE_INFOGRAPHIC } from "../constants/editor";

const useCopyWidget = () => {
  let {
    metadata,
    widgets,
    keyBoardEvent,
    updateKeyBoardEvent,
    updateClipboardObj,
    dimension,
    documentType,
    blockNodes,
  } = useContext(EditorContext);

  const { write: writeToClipboard } = useClipboard();
  const companyInfo = useSelector(state => state?.auth?.company);
  const documentDetails = useSelector(state => state?.document?.documentDetails?.data);

  const copy = async () => {
    let targetWidgetIndexes = [];
    let copyWidgetsArray = [];

    // Get target widget Index
    for (let i = 0; i < metadata.activeWidgetId.length; i++) {
      let id = metadata.activeWidgetId[i];
      let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
      let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
      targetWidgetIndexes.push(widgets.findIndex(widget => widget.id === targetId));
    }

    // Copy targetindex Widget
    targetWidgetIndexes.sort().map(targetWidgetIndex => {
      let cloneObj = widgets[targetWidgetIndex];
      copyWidgetsArray.push(cloneObj);
    });

    // create obj to stro in local storage
    let targetObj = {
      companyId: companyInfo?._id,
      documentId: documentDetails.id,
      docDimension: dimension,
      widgetObj: copyWidgetsArray,
    };

    if (documentType === TYPE_INFOGRAPHIC)
      targetObj = { ...targetObj, blockHeight: blockNodes[metadata.activeBlockIdx].style.height };

    await writeToClipboard(targetObj);

    //Update Context values
    updateClipboardObj(true);
    updateKeyBoardEvent({ ...keyBoardEvent, copy: false });
  };

  useEffect(() => {
    if (!metadata.activeWidgetId || !keyBoardEvent.copy) return;
    copy();
  }, [keyBoardEvent.copy]);
};

export default useCopyWidget;
