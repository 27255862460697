import { useContext } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";

const useCloneBackground = () => {
  let { backgroundColors, backgroundImages } = useContext(EditorContext);

  const cloneBackground = (targetBlockId, currentBlockId, currentPageId) => {
    let i = backgroundColors.findIndex(backgroundColor => backgroundColor.blockId === targetBlockId);
    let index = backgroundImages.findIndex(backgroundImage => backgroundImage.blockId === targetBlockId);
    let updatedBackgroundColorObj, updatedBackgroundImageObj;

    // Clone background color
    if (i > -1) {
      let cloneObj = backgroundColors[i];

      updatedBackgroundColorObj = {
        blockId: currentBlockId,
        pageId: currentPageId,
        style: { ...cloneObj.style },
      };

      updatedBackgroundColorObj = [...backgroundColors, updatedBackgroundColorObj];
    }

    // Clone background Image
    if (index > -1) {
      let cloneObj = backgroundImages[index];

      updatedBackgroundImageObj = {
        blockId: currentBlockId,
        pageId: currentPageId,
        style: { ...cloneObj.style },
      };

      updatedBackgroundImageObj = [...backgroundImages, updatedBackgroundImageObj];
    }

    return { updatedBackgroundColorObj, updatedBackgroundImageObj };
  };

  return { cloneBackground };
};

export default useCloneBackground;
