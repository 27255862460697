import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import cx from "classnames";

import {
  DOCUMENT_PERMISSION_RO as RO,
  DOCUMENT_PERMISSION_RW as RW,
  DOCUMENT_PERMISSION_FULL as FULL,
} from "../../../constants/document";
import { USER_STATUS_JOINED as JOINED } from "../../../constants/user";
import {
  DOCUMENT_SHARE_SUCCESS,
  DOCUMENT_SHARE_FAILED,
  DOCUMENT_SHARE_UPDATE_PERMISSION_SUCCESS,
  DOCUMENT_SHARE_UPDATE_PERMISSION_FAILED,
  DOCUMENT_SHARE_DELETE_USER_SUCCESS,
  DOCUMENT_SHARE_DELETE_USER_FAILED,
  DOCUMENT_SHARE_INFO_SUCCESS,
  DOCUMENT_SHARE_INFO_FAILED,
} from "../../../store/actions/actionTypes";
import { getUserInfo } from "../../../store/actions/authActions";
import { getUserList } from "../../../store/actions/companyActions";
import {
  shareDocument,
  shareDocumentUpdatePermission,
  shareDocumentDeleteUser,
  shareDocumentInfo,
} from "../../../store/actions/documentActions";
import { documentInfo } from "../_utils";
import { Input } from "../../ui/input";
import { Icon } from "../../ui/icon";
import { ProcessLoader } from "../../ui/loader/processLoader";
import { FlashAlert } from "../../ui/flashAlert";

import global from "../../../scss/dhp.scss";
import userAvatar from "../../../assets/images/ui-user.svg";

let style = Object.assign({}, global);

const SharedUserThumb = ({ userImg }) => {
  const thumb = {
    src: userImg,
    loading: true,
    default: false,
  };

  const [userThumb, setUserThumb] = useState(thumb);

  const handleImageLoad = () => setUserThumb({ ...userThumb, loading: false });

  const handleImageError = () => setUserThumb({ ...userThumb, src: userAvatar, loading: false, default: true });

  useEffect(() => {
    const image = new Image();
    image.src = thumb.src;
    image.addEventListener("load", handleImageLoad);
    image.addEventListener("error", handleImageError);
    return () => {
      image.removeEventListener("load", handleImageLoad);
      image.removeEventListener("error", handleImageError);
    };
  }, []);

  return (
    <figure>
      {!userThumb.loading && (
        <img
          src={userThumb.src}
          className={cx(style["img-fluid"], {
            [style["default"]]: userThumb.default,
          })}
          alt="User image"
        />
      )}
    </figure>
  );
};

const ShareModal = ({ document: data, _helpers }) => {
  const dispatch = useDispatch();
  const alertData = {
    show: false,
    tab: false,
    type: "",
  };
  const processEndTypes = {
    share: [DOCUMENT_SHARE_SUCCESS, DOCUMENT_SHARE_FAILED],
  };
  const errorTypes = {
    [DOCUMENT_SHARE_FAILED]: "share",
    [DOCUMENT_SHARE_UPDATE_PERMISSION_FAILED]: "shared_with",
    [DOCUMENT_SHARE_DELETE_USER_FAILED]: "shared_with",
    [DOCUMENT_SHARE_INFO_FAILED]: "share",
  };
  const saveProcess = {
    updatePermission: false,
    deleteUser: false,
  };

  const permissions = {
    [RO]: "Read Only",
    [RW]: "Read Write",
    [FULL]: "Full Access",
  };

  const initialState = {
    share: {
      user_multiselect: [],
      permission_dropdown: {
        selected: {
          name: permissions[RO],
          value: RO,
        },
      },
      notify_users: false,
      valid: false,
    },
    shared_with: {
      permission_dropdown: {},
      update_permission_obj: {},
      delete_user_obj: {},
      valid: false,
    },
    tab: "share",
  };

  const [state, setState] = useState(initialState);
  const [isMounted, setIsMounted] = useState(false);
  const [alert, setAlert] = useState(alertData);
  const [defaultUser, setDefaultUser] = useState([]);
  const [sharedInfoSuccess, setSharedInfoSuccess] = useState(false);
  const [shareSuccess, setShareSuccess] = useState(false);
  const [sharedWithSuccess, setSharedWithSuccess] = useState(false);
  const [saveProcessDone, setSaveProcessDone] = useState(saveProcess);
  const [validUsers, setValidUsers] = useState([]);
  const [disable, setDisable] = useState({
    share: false,
    shared_with: false,
  });

  const userInfo = useSelector(state => state?.auth?.user);
  const users = useSelector(state => state?.company?.users);
  const { type, shared_users: sharedUsers } = useSelector(state => state?.document);

  const toggleAlert = () => setAlert({ ...alert, show: !alert.show });

  const getValidUsers = () => {
    let sharedUserIds = sharedUsers.map(user => user.id);
    setValidUsers(
      users.filter(
        user =>
          user.email !== userInfo?.email &&
          user?.status === JOINED &&
          !user?.superuser &&
          !sharedUserIds.includes(user.id)
      )
    );
  };

  const updateState = ({ field, value, processSharedInfo, userId }) => {
    let stateObj = { ...state };

    if (field === "user_multiselect" && stateObj.tab === "share") {
      stateObj.share.user_multiselect = value;
      stateObj.share.valid = value.length > 0;
      setState(stateObj);
    }

    if (field === "permission_dropdown" && stateObj.tab === "share" && !processSharedInfo && !userId) {
      stateObj.share.permission_dropdown.selected = { name: permissions[value], value: value };
      setState(stateObj);
    }

    if (field === "notify_users" && stateObj.tab === "share") {
      stateObj.share.notify_users = value;
      setState(stateObj);
    }

    if (field === "permission_dropdown") {
      if (processSharedInfo && !userId) {
        let processedInfo = {};
        sharedUsers.forEach(user => {
          processedInfo[user.id] = {
            user_data: user,
            selected: {
              name: permissions[user.permission],
              value: user.permission,
            },
          };
        });
        stateObj.shared_with.permission_dropdown = processedInfo;
        setState(stateObj);
      }

      if (stateObj.tab === "shared_with" && !processSharedInfo) {
        stateObj.shared_with.permission_dropdown[userId].selected = { name: permissions[value], value: value };

        if (stateObj.shared_with.permission_dropdown[userId].user_data.permission !== value) {
          stateObj.shared_with.update_permission_obj[userId] = {
            id: userId,
            permission: value,
          };
        } else {
          delete stateObj.shared_with.update_permission_obj[userId];
        }

        stateObj.shared_with.valid =
          [
            ...Object.keys(stateObj.shared_with.update_permission_obj),
            ...Object.keys(stateObj.shared_with.delete_user_obj),
          ].length > 0;
        setState(stateObj);
      }
    }

    if (field === "delete_user_obj" && stateObj.tab === "shared_with") {
      stateObj.shared_with.delete_user_obj[userId] = value;

      if (stateObj.shared_with.update_permission_obj[userId]) {
        delete stateObj.shared_with.update_permission_obj[userId];

        // restore last known permission
        stateObj.shared_with.permission_dropdown[userId].selected = {
          name: permissions[stateObj.shared_with.permission_dropdown[userId].user_data.permission],
          value: stateObj.shared_with.permission_dropdown[userId].user_data.permission,
        };
      }

      stateObj.shared_with.valid =
        [
          ...Object.keys(stateObj.shared_with.update_permission_obj),
          ...Object.keys(stateObj.shared_with.delete_user_obj),
        ].length > 0;
      setState(stateObj);
    }

    if (field === "share" && stateObj.tab === "share") {
      setIsMounted(true);
      let payload = {
        documentId: data.id,
        notify: stateObj.share.notify_users,
        shareWith: stateObj.share.user_multiselect.map(userId => ({
          id: userId,
          permission: stateObj.share.permission_dropdown.selected.value,
        })),
      };
      dispatch(shareDocument(payload));
      stateObj.share.valid = false;
      setState(stateObj);
      setDisable({ ...disable, share: true });
    }

    if (field === "save" && stateObj.tab === "shared_with") {
      setIsMounted(true);
      if (Object.keys(stateObj.shared_with.update_permission_obj).length > 0) {
        let payload = {
          documentId: data.id,
          shareWith: Object.keys(stateObj.shared_with.update_permission_obj).map(
            userId => stateObj.shared_with.update_permission_obj[userId]
          ),
        };
        dispatch(shareDocumentUpdatePermission(payload));
      } else {
        setSaveProcessDone({ ...saveProcessDone, updatePermission: true });
      }

      if (Object.keys(stateObj.shared_with.delete_user_obj).length > 0) {
        let payload = {
          documentId: data.id,
          shareWith: Object.keys(stateObj.shared_with.delete_user_obj).map(userId => userId),
        };
        dispatch(shareDocumentDeleteUser(payload));
      } else {
        setSaveProcessDone({ ...saveProcessDone, deleteUser: true });
      }
      stateObj.shared_with.valid = false;
      setState(stateObj);
      setDisable({ ...disable, shared_with: true });
    }

    if (field === "tab" && stateObj.tab !== value) {
      stateObj.tab = value;
      setState(stateObj);
    }
  };

  const postApiCallActions = () => {
    let stateObj = { ...state };
    if (type === DOCUMENT_SHARE_SUCCESS) {
      setShareSuccess(true);
      dispatch(shareDocumentInfo(data.id));
      document.getElementById("document-share-notify-users").checked = false;
      stateObj.share = initialState.share;
      setState(stateObj);
      setDefaultUser([]);
      setDisable({ ...disable, share: false });
    }
    if (
      [
        DOCUMENT_SHARE_UPDATE_PERMISSION_SUCCESS,
        DOCUMENT_SHARE_DELETE_USER_SUCCESS,
        DOCUMENT_SHARE_UPDATE_PERMISSION_FAILED,
        DOCUMENT_SHARE_DELETE_USER_FAILED,
      ].includes(type)
    ) {
      const saveType = [DOCUMENT_SHARE_DELETE_USER_SUCCESS, DOCUMENT_SHARE_DELETE_USER_FAILED].includes(type)
        ? "deleteUser"
        : "updatePermission";
      setSaveProcessDone({ ...saveProcessDone, [saveType]: true });
    }
    if (type === DOCUMENT_SHARE_INFO_SUCCESS) {
      setIsMounted(false);
      setSharedInfoSuccess(true);
      if (shareSuccess) {
        setShareSuccess(false);
        updateState({ field: "tab", value: "shared_with" });
      }
      if (sharedWithSuccess) {
        let stateObj = { ...state };
        stateObj.shared_with.update_permission_obj = {};
        stateObj.shared_with.delete_user_obj = {};
        setState(stateObj);
        setSharedWithSuccess(false);
      }
      documentInfo.set(data, { shared: sharedUsers?.length > 0 ? true : null });
    }
    if (Object.keys(errorTypes).includes(type)) {
      if (![DOCUMENT_SHARE_UPDATE_PERMISSION_FAILED, DOCUMENT_SHARE_DELETE_USER_FAILED].includes(type)) {
        setIsMounted(false);
      }
      setDisable({ ...disable, share: false, shared_with: false });
      setAlert({ ...alert, show: true, type: "error", tab: errorTypes[type] });
    }
  };

  useEffect(() => {
    if (saveProcessDone.updatePermission && saveProcessDone.deleteUser) {
      setSaveProcessDone(saveProcess);
      setSharedWithSuccess(true);
      dispatch(shareDocumentInfo(data.id));
      setDisable({ ...disable, shared_with: false });
      setAlert({ ...alert, show: true, type: "success", tab: "shared_with", message: "Your record has been updated" });
    }
  }, [saveProcessDone]);

  useEffect(() => {
    if (isMounted && type) postApiCallActions();
  }, [type, isMounted]);

  useEffect(() => {
    if (userInfo && users && sharedInfoSuccess) {
      setSharedInfoSuccess(false);
      getValidUsers();
      updateState({ field: "permission_dropdown", processSharedInfo: true });
    }
  }, [userInfo, users, sharedInfoSuccess]);

  useEffect(() => {
    setIsMounted(true);
    dispatch(shareDocumentInfo(data.id));
    if (!userInfo) dispatch(getUserInfo());
    if (!users) dispatch(getUserList());
  }, []);

  return (
    <React.Fragment>
      <div className={style["modal-header"]}>
        <h4 className={cx(style["fw-7"], style["mb-0"])}>Share</h4>
        <Link to="#" className={cx(style["cross-modal"], style["rounded"])} onClick={_helpers?.modal?.toggle}>
          <Icon icon="ui-close" />
        </Link>
      </div>
      <div className={cx(style["share-modal-tab"], style["row"], style["m-0"])}>
        <div className={cx(style["col-4"], style["p-0"])}>
          <Nav className={cx(style["nav-pills"], style["flex-column"], style["h-100"])}>
            <NavItem>
              <NavLink
                className={cx({ [style["active"]]: state.tab === "share" })}
                onClick={() => updateState({ field: "tab", value: "share" })}>
                <Icon icon="ui-add-user" /> Share
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={cx({ [style["active"]]: state.tab === "shared_with" })}
                onClick={() => updateState({ field: "tab", value: "shared_with" })}>
                <Icon icon="ui-share" /> Shared with{" "}
                <span className={style["numberof-shared"]}>
                  {Object.keys(state.shared_with.permission_dropdown).length}
                </span>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className={cx(style["col-8"], style["p-0"])}>
          <TabContent activeTab={state.tab}>
            <TabPane tabId="share">
              <Row>
                <Col sm="12">
                  <div className={style["add-user-wrap"]}>
                    <Row>
                      <Col sm="8" className={style["pr-4"]}>
                        <div className={cx(style["select-user"])}>
                          <Input
                            cssModule={style}
                            returnType="formGroup"
                            type="multi_select"
                            placeholder="Choose User"
                            noOptionMessage="No results found"
                            options={validUsers}
                            optionValue={"id"}
                            optionImage={"image"}
                            optionTextPrimary={["firstname", "lastname"]}
                            optionTextMuted={["email"]}
                            updateState={e => updateState({ field: "user_multiselect", value: e })}
                            defaultValue={defaultUser}
                          />
                        </div>
                      </Col>
                      <Col sm="4" className={style["pl-0"]}>
                        <div className={cx(style["form-group"], style["access"])}>
                          <UncontrolledDropdown className={cx(style["w-100"])}>
                            <DropdownToggle caret tag="a" className={style["rounded"]}>
                              <span>{state.share.permission_dropdown.selected.name}</span>
                              <Icon icon="ui-arrow-down" />
                            </DropdownToggle>
                            <DropdownMenu
                              className={cx(style["shadow"], style["border-0"], style["rounded"], style["w-100"])}>
                              {Object.keys(permissions).map((p, i) => (
                                <DropdownItem
                                  key={i}
                                  className={cx({
                                    [style["active-item"]]: p === state.share.permission_dropdown.selected.value,
                                  })}
                                  onClick={() =>
                                    p !== state.share.permission_dropdown.selected.value &&
                                    updateState({ field: "permission_dropdown", value: p })
                                  }>
                                  {permissions[p]}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </Col>
                      <Col>
                        <div className={cx(style["custom-control"], style["custom-switch"])}>
                          <Input
                            cssModule={style}
                            returnType="noGroup"
                            type="checkbox"
                            className={style["custom-control-input"]}
                            id="document-share-notify-users"
                            label="Notify selected user(s)"
                            onChange={e => updateState({ field: "notify_users", value: e.target.checked })}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className={cx(style["align-items-center"], style["d-flex"])}>
                    <div className={style["btn-wrp-bg"]}>
                      <Button
                        type="submit"
                        color="primary"
                        cssModule={style}
                        disabled={!state.share.valid}
                        onClick={() => updateState({ field: "share" })}>
                        Share
                      </Button>
                    </div>

                    <ProcessLoader isOpen={disable.share && !processEndTypes.share.includes(type)} />

                    <FlashAlert
                      isOpen={alert.show && alert.tab === "share"}
                      toggle={toggleAlert}
                      type={alert.type}
                      wrapperClass="ml-2"
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="shared_with">
              <Row>
                <Col sm="12">
                  <div className={cx(style["shareWith-wrap"])}>
                    <div
                      className={cx(style["share-with-empty"], style["h-100"], {
                        [style["d-none"]]: Object.keys(state.shared_with.permission_dropdown).length > 0,
                      })}>
                      The document is not shared with any user
                    </div>
                    <ul className={cx(style["acces-list"], style["customScroll"], style["scroll-Y"])}>
                      {Object.keys(state.shared_with.permission_dropdown).map(userId => (
                        <li
                          key={userId}
                          className={cx({ [style["seleted"]]: state.shared_with.delete_user_obj[userId] })}>
                          <SharedUserThumb userImg={state.shared_with.permission_dropdown[userId].user_data?.image} />
                          <h6 className={cx(style["fw-6"], style["mb-0"])}>
                            {state.shared_with.permission_dropdown[userId].user_data.firstname}{" "}
                            {state.shared_with.permission_dropdown[userId].user_data.lastname}{" "}
                            <span>{state.shared_with.permission_dropdown[userId].user_data.email}</span>
                          </h6>
                          <div className={style["accesstype"]}>
                            <UncontrolledDropdown
                              className={cx(style["w-100"])}
                              disabled={state.shared_with.delete_user_obj[userId] ? true : false}>
                              <DropdownToggle caret tag="a" className={style["rounded"]}>
                                <span>{state.shared_with.permission_dropdown[userId].selected.name}</span>
                                <Icon icon="ui-arrow-down" />
                              </DropdownToggle>
                              <DropdownMenu
                                className={cx(style["shadow"], style["border-0"], style["rounded"], style["w-100"])}>
                                {Object.keys(permissions).map((p, i) => (
                                  <DropdownItem
                                    key={i}
                                    className={cx({
                                      [style["active-item"]]:
                                        p === state.shared_with.permission_dropdown[userId].selected.value,
                                    })}
                                    onClick={() =>
                                      p !== state.shared_with.permission_dropdown[userId].selected.value &&
                                      updateState({ field: "permission_dropdown", value: p, userId: userId })
                                    }>
                                    {permissions[p]}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                          <div className={style["access-remove"]}>
                            <Link
                              to="#"
                              onClick={() =>
                                updateState({
                                  field: "delete_user_obj",
                                  value: state.shared_with.permission_dropdown[userId].user_data,
                                  userId: userId,
                                })
                              }>
                              <Icon icon="ui-minus-circle" />
                            </Link>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className={cx(style["align-items-center"], style["d-flex"])}>
                    <div
                      className={cx(style["btn-wrp-bg"], {
                        [style["invisible"]]: Object.keys(state.shared_with.permission_dropdown).length === 0,
                      })}>
                      <Button
                        type="submit"
                        color="primary"
                        cssModule={style}
                        disabled={!state.shared_with.valid}
                        onClick={() => updateState({ field: "save" })}>
                        Save
                      </Button>
                    </div>

                    <ProcessLoader
                      isOpen={disable.shared_with && !(saveProcessDone.updatePermission && saveProcessDone.deleteUser)}
                    />

                    <FlashAlert
                      isOpen={alert.show && alert.tab === "shared_with"}
                      toggle={toggleAlert}
                      type={alert.type}
                      wrapperClass="ml-2"
                      message={alert.message}
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </React.Fragment>
  );
};

SharedUserThumb.propTypes = {
  userImg: PropTypes.string.isRequired,
};

ShareModal.propTypes = {
  document: PropTypes.object.isRequired,
  _helpers: PropTypes.object.isRequired,
};

export default ShareModal;
