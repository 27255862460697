import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import useTextFocusOut from "./useTextFocusOut";
import useAlignment from "./useAlignment";
import useAddWidget from "./useAddWidget";
import useElementInnerHtml from "./useElementInnerHtml";
import { widgetConfig } from "../components/Editor/editor_config";
import { AI, DOCHIPO, EXPAND, REWRITE, SUMMARIZE, TEXT } from "../constants/editor";
import { calculateNewPositionOnRotatedObjectResize, getCssTextObjToString, getCssTransformObj } from "../_helpers/utils";
import { fetchAiWriterExtend } from "../store/actions/aiActions";
import { EditorContext } from "../containers/editor/EditorLayout";
import { AppContext } from "../contexts";
import useWidgetHandler from "./useWidgetHandler";

const useAiWriter = () => {
  const assetType = TEXT;
  const headingType = "normal";
  const aiTextConfig = widgetConfig[assetType][headingType];

  const [assetInnerContent, setAssetInnerContent] = useState();
  const [addWidgetClicked, setAddWidgetClicked] = useState(false);
  const [dataparam, setDataParam] = useState();
  const [dimension, setDimension] = useState();
  const [customCss, setCustomCss] = useState();

  const dispatch = useDispatch();
  const setTextFocusOut = useTextFocusOut();
  const { updateFlashMessage } = useContext(AppContext);
  let { widgets, updateWidgets } = useContext(EditorContext);
  const { toggleEventHandlers: toggleWidgetEventHandlers } = useWidgetHandler();
  const { postion: getPosition } = useAlignment(addWidgetClicked, dimension?.width, dimension?.height, "middle-center");
  const getNewWidgetObject = useAddWidget(
    addWidgetClicked,
    assetType,
    dataparam,
    getPosition,
    dimension?.width,
    dimension?.height
  );
  useElementInnerHtml(
    addWidgetClicked,
    setAddWidgetClicked,
    assetType,
    assetInnerContent,
    dataparam,
    getNewWidgetObject,
    customCss
  );

  const aiWriter = {
    deformatter: ({ type, data }) => {
      if (type === AI) {
        const pattern = {
          bullet: /^\s*(?:[A-Z]+\.|[a-z]\)|•|-)\s+/gm,
          number: /^\s*(?:[\d]+\.|[a-z]\))\s+/gm,
          deformat: /^\s*(?:[\dA-Z]+\.|[a-z]\)|•|-)\s+/gm,
        };

        const format = {
          bullet: pattern.bullet.test(data),
          number: pattern.number.test(data),
        };

        const result = {
          formatted: data,
          deformated: data.replace(pattern.deformat, ""),
          formatType: format.bullet ? "bullet" : format.number ? "number" : "plain",
        };

        return result;
      }

      if (type === DOCHIPO) {
        let prompt = "";
        const listQuery = {
          bullet: ".unorder-list",
          number: ".order-list",
        };
        const { formatType, node } = data;

        if (formatType === "plain") prompt = node?.innerText;
        else {
          node
            ?.querySelector(listQuery[formatType])
            ?.querySelectorAll("li")
            ?.forEach((e, i) => {
              const listIdentifier = formatType === "bullet" ? "-" : i + 1;
              prompt += `${listIdentifier} ${e.innerText}\n`;
            });
        }

        return prompt;
      }
    },

    formatter: ({ event, type, data }) => {
      if (type === DOCHIPO) {
        let dataProps = {};
        let innerContent;
        let widgetDimension;
        const config = {
          bullet: {
            tag: "ul",
            class: "unorder-list",
            style: "list-style-type: square;",
            data: {
              "data-subwidget": "UL",
            },
          },
          number: {
            tag: "ol",
            class: "order-list",
            style: "list-style-type: decimal;",
            data: {
              "data-subwidget": "OL",
            },
          },
        };
        const { formatType, formatted, deformated } = aiWriter.deformatter({ type: AI, data });

        if (formatType === "plain") innerContent = `<div>${formatted}</div>`;
        else {
          let fragments = "";
          const splitLines = deformated.split(/\r\n|\r|\n/);
          splitLines.forEach(e => (fragments += `<li><div>${e}</div></li>`));
          const listType = config[formatType];
          dataProps = listType.data;
          innerContent = `<${listType.tag} class="${listType.class}" style="${listType.style}">${fragments}</${listType.tag}>`;
        }

        if (event === "ADD") widgetDimension = aiWriter.getWidgetDimension({ innerContent });

        return { dataProps, innerContent, widgetDimension };
      }

      if (type === AI) {
        const promptPrefix = {
          bullet: " bullet list of",
          number: " number list of",
          plain: "",
        };

        const {
          actionName,
          widgetData: { id, node, formatType },
        } = data;

        const innerText = aiWriter.deformatter({ type: DOCHIPO, data: { formatType, node } });
        const prompt = `${actionName}${promptPrefix[formatType]}:\n\n${innerText}`;

        return { prompt, id, node };
      }
    },

    addWidget: ({ dataProps, innerContent, widgetDimension }) => {
      if (document.querySelector(".dhp-content-editable-true-text")) setTextFocusOut(true);

      setTimeout(() => {
        setAssetInnerContent(innerContent);
        setDataParam({
          ...aiTextConfig.dataAttr,
          ...dataProps,
          "data-heading": headingType,
        });
        setDimension(widgetDimension);
        setCustomCss({ width: `${widgetDimension.width}px`, "text-align": "left" });
        setAddWidgetClicked(true);
      }, 1);
    },

    updateWidget: ({ prompt, id, node }) => {
      aiWriter.toggleInprogress({ widetId: id, actionType: "add" });

      dispatch(fetchAiWriterExtend({ promt: prompt })).then(resp => {
        const output = resp?.result?.data?.text;

        if (output) {
          const modifiedNode = node;
          const { innerContent } = aiWriter.formatter({ event: "UPDATE", type: DOCHIPO, data: output });
          modifiedNode.innerHTML = innerContent;
          const { width, height } = aiWriter.getWidgetDimension({ innerNode: modifiedNode });

          const widgetObject = widgets.find(wz=> wz.id === id);
          const {
            translate: { x: widgetTransX, y: widgetTransY },
            rotate: { theta: widgetTheta },
          } = getCssTransformObj({
            transform: widgetObject.style.transform,
          });
          const { left, top } = calculateNewPositionOnRotatedObjectResize(
            parseFloat(widgetTransX),
            parseFloat(widgetTransY),
            parseFloat(width),
            parseFloat(height),
            parseFloat(widgetObject.style.width),
            parseFloat(widgetObject.style.height),
            parseFloat(widgetTheta)
          );
          const transform = getCssTransformObj({
            translateX: `${left}px`,
            translateY: `${top}px`,
            transform: widgetObject.style.transform,
            returnStr: true,
          });

          updateWidgets(
            widgets.map(widget =>
              widget.id === id
                ? {
                    ...widget,
                    style: {
                      ...widget.style,
                      width,
                      height,
                      transform
                    },
                    innerHTML: modifiedNode.outerHTML,
                  }
                : widget
            )
          );
        } else {
          // error (In-case API failure)
          updateFlashMessage({
            type: "error",
            message:
              resp?.error?.response?.data?.message === "Rate limit exceeded"
                ? "Max limit reached for the current month."
                : "The service is currently unavailable. Please try again later.",
          });
        }

        aiWriter.toggleInprogress({ widetId: id, actionType: "remove" });
      });
    },

    getWidgetDimension: ({ innerContent, innerNode }) => {
      let width, height, widgetInnerNode;

      if (innerContent) {
        width = 238;
        const { classList: classStr, style } = aiTextConfig.innerHTML;
        const styleStr = getCssTextObjToString({ ...style, width: `${width}px` });
        widgetInnerNode = `<div class="${classStr}" style="${styleStr}">${innerContent}</div>`;
      }

      const sandBox = document.createElement("div");
      sandBox.innerHTML = widgetInnerNode ?? innerNode.outerHTML;
      sandBox.style.cssText = "position:absolute; top:-9999px; opacity:0;";
      document.body.appendChild(sandBox);
      if (innerNode) width = sandBox.offsetWidth;
      height = sandBox.offsetHeight;
      sandBox.parentNode.removeChild(sandBox);

      return { width, height };
    },

    validateAiActions: ({ widgetObj }) => {
      const listType = widgetObj.data["data-subwidget"];
      const sandBox = document.createElement("div");
      sandBox.innerHTML = widgetObj.innerHTML;

      const format = {
        bullet: listType === "UL",
        number: listType === "OL",
      };

      const result = {
        id: widgetObj.id,
        node: sandBox.querySelector(".primary-text-widget.dhp-widget-inner"),
        formatType: format.bullet ? "bullet" : format.number ? "number" : "plain",
        validity: {
          [REWRITE]: true,
          [SUMMARIZE]: true,
          [EXPAND]: sandBox.innerText.length < 750,
        },
      };

      return result;
    },

    toggleInprogress: ({ widetId, actionType }) => {
      document.getElementById(widetId)?.classList?.[actionType]?.("dhp-widget-in-progress-loader");
      document.getElementById("editorBody")?.classList?.[actionType]?.("pe-none");
      localStorage.setItem("widget.event.isActive", actionType === "add" ? "true" : "false");
      toggleWidgetEventHandlers({ action: actionType === "add" ? "HIDE" : "SHOW" });
    },

    start: ({ event, type, data }) => {
      const result = aiWriter.formatter({ event, type, data });
      if (event === "ADD") aiWriter.addWidget(result);
      if (event === "UPDATE") aiWriter.updateWidget(result);
    },
  };

  return { start: aiWriter.start, validateAiActions: aiWriter.validateAiActions };
};

export default useAiWriter;
