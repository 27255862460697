import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Button } from "reactstrap";
import cx from "classnames";

import { DOCUMENT_DELETE_SUCCESS, DOCUMENT_DELETE_FAILED } from "../../../store/actions/actionTypes";
import { deleteDocument } from "../../../store/actions/documentActions";
import { preventFormSubmitOnEnter as preventSubmit } from "../../../_helpers/utils";
import { Icon } from "../../ui/icon";
import { ProcessLoader } from "../../ui/loader/processLoader";
import { FlashAlert } from "../../ui/flashAlert";

import global from "../../../scss/dhp.scss";

let style = Object.assign({}, global);

const DeleteModal = ({ document, _helpers }) => {
  const dispatch = useDispatch();
  const alertData = {
    show: false,
    type: "",
  };
  const processEndTypes = [DOCUMENT_DELETE_SUCCESS, DOCUMENT_DELETE_FAILED];
  const updatedDocuments = _helpers.gridView.documents.data.filter(d => d.id !== document.id);
  const updatedTotal = _helpers.gridView.total.data - 1;

  const [isMounted, setIsMounted] = useState(false);
  const [alert, setAlert] = useState(alertData);
  const [disable, setDisable] = useState({ delete: false });

  const { type } = useSelector(state => state?.document);

  const toggleAlert = () => setAlert({ ...alert, show: !alert.show });

  const handleSubmit = e => {
    e.preventDefault();
    setIsMounted(true);
    dispatch(deleteDocument([document.id]));
    setDisable({ delete: true });
  };

  useEffect(() => {
    if (isMounted && type && type === DOCUMENT_DELETE_SUCCESS) {
      setIsMounted(false);
      _helpers.gridView.documents.setData(updatedDocuments);
      _helpers.gridView.total.setData(updatedTotal);
      _helpers.gridView.noDocument.setData(updatedTotal === 0);
      _helpers.modal.toggle();
    }
    if (isMounted && type && type === DOCUMENT_DELETE_FAILED) {
      setIsMounted(false);
      setAlert({ ...alert, show: true, type: "error" });
    }
  }, [type, isMounted]);

  return (
    <React.Fragment>
      <h4 className={cx(style["fw-7"], style["mb-4"])}>Delete</h4>
      <Link to="#" className={cx(style["cross-modal"], style["rounded"])} onClick={_helpers?.modal?.toggle}>
        <Icon icon="ui-close" />
      </Link>

      <Form onKeyDown={e => preventSubmit(e)} onSubmit={e => handleSubmit(e)}>
        <div className={cx(style["delete-msg"], style["font-base"], style["color-33"], style["mb-4"])}>
          Are you sure to delete this document?
        </div>

        <div className={cx(style["align-items-center"], style["d-flex"])}>
          <Button type="submit" color="danger" disabled={disable.delete}>
            Delete
          </Button>

          <ProcessLoader isOpen={disable.delete && !processEndTypes.includes(type)} />
        </div>
      </Form>

      <FlashAlert isOpen={alert.show} toggle={toggleAlert} type={alert.type} wrapperClass="mt-3" />
    </React.Fragment>
  );
};

DeleteModal.propTypes = {
  document: PropTypes.object.isRequired,
  _helpers: PropTypes.object.isRequired,
};

export default DeleteModal;
