import React, { useEffect, useRef, useState } from 'react'
import cx from "classnames";
import PropTypes from "prop-types";
import { Icon } from '../../ui/icon'
import global from "../../../scss/dhp.scss";
import { Button, Input, Label, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RMasonry as Masonry } from "../../ui/masonry";
import { DotLoader } from '../../ui/loader/dotLoader';
import { fetchFacebookAlbumImages } from '../../../store/actions/companyActions';
import { MasonryLoader } from '../../ui/loader/masonryLoader';
import NoPreview from "../../../assets/images/no-preview.jpg";
import { FETCH_FACEBOOK_ALBUMS_FAILED, FETCH_FACEBOOK_ALBUM_IMAGES_FAILED } from '../../../store/actions/actionTypes';

let style = Object.assign({}, global);

const FacebookImage = ({ image, selectedImages, setSelectedImages }) => {
    const [imageState, setImageState] = useState({ ...image, loading: true })
    const [active, setActive] = useState(false);
    const handleImageLoad = () => setImageState({ ...imageState, loading: false });
    const handleImageError = () => setImageState({ ...imageState, source: NoPreview, loading: false });

    const changeHandler = (e) => {
        let imageSource = e.target.value;
        if (e.target.checked) {
            setSelectedImages([...selectedImages, imageSource]);
            setActive(true)
        }
        else
            if (selectedImages.includes(imageSource)) {
                setSelectedImages(selectedImages.filter(src => src !== imageSource))
                setActive(false);
            }
    }

    useEffect(() => {
        const image = new Image();
        image.src = imageState.source;
        image.addEventListener("load", handleImageLoad);
        image.addEventListener("error", handleImageError);
        return () => {
            image.removeEventListener("load", handleImageLoad);
            image.removeEventListener("error", handleImageError);
        };
    }, [image]);

    return (
        <>
            {imageState.loading && <div><MasonryLoader module="facebook_images" type="masonry" count={1} /></div>}
            {!imageState.loading && (<div className={cx(style["custom-control"], style["custom-checkbox"], style["image-checkbox"], { [style["active"]]: active })}>
                <Input
                    type="checkbox"
                    className={style["custom-control-input"]}
                    value={imageState.source}
                    id={imageState.id}
                    onChange={e => changeHandler(e)} />
                <Label className={style["custom-control-label"]} htmlFor={imageState.id}>
                    <img src={imageState.source} className="img-fluid" />
                </Label>
            </div>)}
        </>
    )
}

FacebookImage.propTypes = {
    image: PropTypes.object.isRequired,
    selectedImages: PropTypes.array,
    setSelectedImages: PropTypes.func.isRequired
}

const UploadButton = ({ selectedImages, setSelctedFacebookImages, state }) => {
    return (
        <div className={cx(style["align-items-center"], style["d-flex"], style["Mfooter"])}>
            <div className={style["btn-wrp-bg"]}>
                {(state?.count > 0 || selectedImages?.length > 0) && <Button
                    type="button"
                    color="primary"
                    cssModule={style}
                    disabled={selectedImages.length === 0}
                    onClick={() => setSelctedFacebookImages(selectedImages)}>
                    Upload
                </Button>}
            </div>
            {selectedImages.length > 0 && <div className={style["ml-2"]}>{selectedImages.length} Selected</div>}
        </div>
    )
}

UploadButton.propTypes = {
    selectedImages: PropTypes.array,
    setSelctedFacebookImages: PropTypes.func.isRequired,
    state: PropTypes.object
}

const facebookImageUploadModal = ({ showModal, setShowModal, setSelctedFacebookImages, setIsTokenActive }) => {
    const { facebookImages: { data: fbData }, loading, type: actionType, status } = useSelector((state) => state.company);
    const [state, setState] = useState(fbData?.albums ? fbData?.albums[0] : false);
    const [loadingData, setLoadingData] = useState(false);
    const [selectedImages, setSelectedImages] = useState([])
    const dispatch = useDispatch();
    const fetch = {
        albumId: false,
        limit: 25,
        page: null,
    };
    const [payloadFetch, setPayloadFetch] = useState(fetch);
    const loadMoreRefs = useRef({});

    const handleIntersection = (albumId) => (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting && !loadingData) {
                setLoadingData(true);
                const { before, after } = fbData?.album_images?.[albumId]?.paging?.cursors ?? {};
                if (
                    before &&
                    after &&
                    before !== after &&
                    fbData?.album_images?.[albumId]?.images.length + 1 < state?.count
                ) {
                    setPayloadFetch({
                        ...payloadFetch,
                        page: after,
                        albumId: albumId,
                    });
                }
                setLoadingData(false);
            }
        });
    };

    useEffect(() => {
        if ([FETCH_FACEBOOK_ALBUMS_FAILED, FETCH_FACEBOOK_ALBUM_IMAGES_FAILED].includes(actionType) && status === 401)
            setIsTokenActive(false)
    }, [actionType])

    useEffect(() => {
        if (payloadFetch.albumId && state.count > 0) dispatch(fetchFacebookAlbumImages(payloadFetch))
    }, [payloadFetch]);

    useEffect(() => {
        if (!fbData?.album_images?.[state?.id]) {
            setPayloadFetch({ ...payloadFetch, page: null, albumId: state?.id });
        }
    }, [state]);

    useEffect(() => {
        const observers = {};

        fbData?.albums?.forEach((album) => {
            observers[album.id] = new IntersectionObserver(handleIntersection(album.id), {
                root: null,
                rootMargin: '0px',
                threshold: 0,
            });

            if (loadMoreRefs.current[album.id]) {
                observers[album.id].observe(loadMoreRefs.current[album.id]);
            }
        });

        return () => {
            Object.values(observers).forEach((observer) => observer.disconnect());
        };
    }, [fbData, state]);

    return (
        <React.Fragment>
            <div className={cx(style["modal-header"], style["d-flex"], style["justify-content-between"])}>
                <h4 className={cx(style["fw-7"])}>Facebook Image Library</h4>
                <div>
                    <span className={cx(style["cross-modal"], style["rounded"])} onClick={() => setShowModal(!showModal)}>
                        <Icon icon="ui-close" />
                    </span>
                </div>
            </div>
            <div className={cx(style["modal-tab"], style["row"], style["m-0"])}>
                <div className={cx(style["col-4"], style["p-0"])}>
                    <Nav className={cx(style["nav-pills"], style["flex-column"], style["customScroll"], style["scroll-Y"])}>
                        {fbData?.albums?.map((album, i) => (
                            <NavItem key={album.id}>
                                <NavLink
                                    className={cx({ [style["active"]]: state?.id === album.id || (!state && i === 0) })}
                                    onClick={() => setState(album)}
                                > {album.name}
                                    <span className={style["numberof-shared"]}>
                                        {album.count}
                                    </span>
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                </div>
                <div className={cx(style["col-8"], style["p-0"])}>
                    <TabContent activeTab={state?.id}>
                        {fbData?.albums?.map((album) => (
                            <TabPane tabId={album.id} key={album.id} className={cx(style["customScroll"], style["scroll-Y"], style["pr-1"])}>

                                {!fbData?.album_images?.[album.id]?.images && state?.count > 0 &&
                                    <MasonryLoader module="facebook_images" type="masonry" count={(state.count ?? 0 - (fbData.album_images[album.id].images.length ?? 0) + 1) > fetch.limit ? fetch.limit : (state.count ?? 0 - (fbData.album_images[album.id].images.length ?? 0) + 1)} />}

                                {fbData?.album_images?.[album.id]?.images &&
                                    <Masonry module="facebook_images">
                                        {fbData?.album_images?.[album.id]?.images?.map((image) => (
                                            <FacebookImage key={image.id} image={image} selectedImages={selectedImages} setSelectedImages={setSelectedImages} />
                                        ))}
                                    </Masonry>
                                }

                                {!state?.count > 0 && <div className={cx(style["d-flex"], style["align-items-center"], style["justify-content-center"], style["h-100"])}>No images found</div>}

                                {!loading && state?.count > 0 && <div ref={(ref) => (loadMoreRefs.current[album.id] = ref)} style={{ height: '20px' }}></div>}

                                {loading && fbData?.album_images?.[album.id]?.images.length < state.count && <DotLoader />}
                            </TabPane>
                        ))}
                    </TabContent>
                    <UploadButton selectedImages={selectedImages} setSelctedFacebookImages={setSelctedFacebookImages} state={state} />
                </div>
            </div>
        </React.Fragment>
    )
}

export default facebookImageUploadModal


