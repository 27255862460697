import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { contextualConfig } from "../editor_config";
import { EditorContext } from "../../../containers/editor/EditorLayout";

let style = Object.assign({}, global);

const Fade = props => {
  const { metadata, audios, setAudios, rightContextMenu, updateRightContextMenu } = useContext(EditorContext);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loadFormatting, setLoadFormatting] = useState(true);
  const [fadeInValue, setFadeInValue] = useState();
  const [fadeOutValue, setFadeOutValue] = useState();
  const [isAciveFade, setIsAciveFade] = useState();
  const [maxValueForFadeIn, setMaxValueForFadeIn] = useState();
  const [maxValueForFadeOut, setMaxValueForFadeOut] = useState();

  const toggleDropdown = () => {
    if (props.rightContext) {
      document.querySelector(".fade-action-toolbar").click();
      updateRightContextMenu({
        ...rightContextMenu,
        enable: false,
      });
    } else setDropdownOpen(prevState => !prevState);
  };

  const checkFadeDisableOptions = () => {
    let targetId = metadata.activeAudioId;
    let targetAudioIndex = audios.findIndex(audio => audio.id === targetId);
    if (targetAudioIndex < 0) return;
    let totalAudioTime = parseFloat(audios[targetAudioIndex].endTime) - parseFloat(audios[targetAudioIndex].startTime);
    let fadeOutTime = fadeOutValue !== "None" ? fadeOutValue : false;
    let fadeInTime = fadeInValue !== "None" ? fadeInValue : false;

    if (fadeOutTime) {
      fadeOutTime = parseFloat(fadeOutTime);
    } else {
      fadeOutTime = 0;
    }
    let fadeInResult = totalAudioTime - fadeOutTime;

    if (fadeInTime) {
      fadeInTime = parseFloat(fadeInTime);
    } else {
      fadeInTime = 0;
    }
    let fadeOutResult = totalAudioTime - fadeInTime;

    setMaxValueForFadeIn(fadeInResult);
    setMaxValueForFadeOut(fadeOutResult);
  };

  useEffect(() => {
    // update fade in fade out value in audio context
    if (!loadFormatting) {
      let newArray = Object.assign([...audios]);
      let targetAudioIndex = audios.findIndex(audio => audio.id === metadata.activeAudioId);
      newArray = Object.assign([...newArray], {
        [targetAudioIndex]: {
          ...newArray[targetAudioIndex],
          data: {
            ...newArray[targetAudioIndex].data,
            "data-fade-in-value": fadeInValue,
            "data-fade-out-value": fadeOutValue,
          },
        },
      });

      setAudios(newArray);
    }

    checkFadeDisableOptions();
  }, [fadeInValue, fadeOutValue, loadFormatting, audios]);

  useEffect(() => {
    // load current formatting
    if (metadata.activeAudioId) {
      let targetAudioIndex = audios.findIndex(audio => audio.id === metadata.activeAudioId);
      if (targetAudioIndex < 0) return;
      setLoadFormatting(true);

      // if fade in or fade out applied in active audio fetch that value
      if (audios[targetAudioIndex].data["data-fade-in-value"] || audios[targetAudioIndex].data["data-fade-out-value"]) {
        setFadeInValue(audios[targetAudioIndex].data["data-fade-in-value"]);
        setFadeOutValue(audios[targetAudioIndex].data["data-fade-out-value"]);
      }
      // if fade in or fade out is not applied in active audio set default value
      else {
        setFadeInValue("None");
        setFadeOutValue("None");
      }

      // set active if fade in or fade out is applied
      if (
        audios[targetAudioIndex].data["data-fade-in-value"] === "None" &&
        audios[targetAudioIndex].data["data-fade-out-value"] === "None"
      )
        setIsAciveFade(false);
      else setIsAciveFade(true);
    }
  }, [metadata.activeAudioId, audios]);

  return (
    <li
      className={cx(style["toolset-group"], {
        [style["active"]]: isAciveFade,
      })}>
      <UncontrolledDropdown
        className={cx(style["toolset-item"], style["fade-wrap"])}
        isOpen={dropdownOpen}
        toggle={toggleDropdown}>
        <DropdownToggle
          tag="span"
          className={cx(style["toolset-action"], style["custom-tooltip"], {
            ["fade-action-toolbar"]: !props.rightContext,
          })}>
          <Icon icon="ui-fade" />
          {props.rightContext && <>Fade</>}
          {!props.rightContext && <div className={cx(style["custom-tooltip-content"], style["top"])}>Fade</div>}
        </DropdownToggle>

        <DropdownMenu className={cx(style["shadow"], style["border-0"], style["rounded"])}>
          {/* Fade In Dropdown */}
          <div className={cx(style["line-controls-wrap"], style["p-0"])}>
            <div className={style["slidelabel"]}>Fade In</div>
            <UncontrolledDropdown className={style["border-style"]}>
              <DropdownToggle>
                {fadeInValue}
                <Icon icon="ui-arrow-down" />
              </DropdownToggle>

              <DropdownMenu className={cx(style["shadow-sm"], style["border-0"], style["rounded"])}>
                {contextualConfig.Fade.map((value, i) => (
                  <DropdownItem
                    tag="a"
                    key={i}
                    onClick={() => {
                      setLoadFormatting(false), setFadeInValue(value);
                    }}
                    className={cx(
                      {
                        [style["disabled"]]: parseFloat(value) > maxValueForFadeIn,
                      },
                      {
                        [style["active"]]: parseFloat(value) === parseFloat(fadeInValue) || value === fadeInValue,
                      }
                    )}>
                    {value}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>

          {/* Fade Out Dropdown */}
          <div className={cx(style["line-controls-wrap"], style["mb-0"], style["p-0"])}>
            <div className={style["slidelabel"]}>Fade Out</div>
            <UncontrolledDropdown className={style["border-style"]}>
              <DropdownToggle>
                {fadeOutValue}
                <Icon icon="ui-arrow-down" />
              </DropdownToggle>

              <DropdownMenu className={cx(style["shadow-sm"], style["border-0"], style["rounded"])}>
                {contextualConfig.Fade.map((value, i) => (
                  <DropdownItem
                    tag="a"
                    key={i}
                    onClick={() => {
                      setLoadFormatting(false), setFadeOutValue(value);
                    }}
                    className={cx(
                      {
                        [style["disabled"]]: parseFloat(value) > maxValueForFadeOut,
                      },
                      {
                        [style["active"]]: parseFloat(value) === parseFloat(fadeOutValue) || value === fadeOutValue,
                      }
                    )}>
                    {value}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </li>
  );
};
Fade.propTypes = {
  rightContext: PropTypes.bool,
};

export default Fade;
