import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { DropdownMenu } from "reactstrap";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { EditorContext } from "../../../containers/editor/EditorLayout";
import SliderControll from "../SliderControll";

let style = Object.assign({}, global);

const WidgetOpacity = props => {
  let { metadata, widgets, updateWidgets } = useContext(EditorContext);
  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);

  const [opacity, setOpacity] = useState();
  const [opacityInputValue, setOpacityInputValue] = useState();
  const [updateContext, setUpdateContext] = useState(false);

  //Load current widget opacity
  useEffect(() => {
    if (metadata.activeWidgetId) {
      let innerHtmlflag = document.querySelector(`#${id} .dhp-widget-inner`);
      let opacity = innerHtmlflag.style.opacity;

      setOpacity(Math.round(opacity * 100));
      setOpacityInputValue(Math.round(opacity * 100));
    }
  }, [metadata.activeWidgetId]);

  //Update widget opacity
  useEffect(() => {
    if (targetWidgetIndex === undefined) return;

    document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style.opacity = opacity / 100;

    let newArray;
    let isGroupWidget = document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-page-group");
    let targetId = isGroupWidget
      ? document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-root-widget").getAttribute("id")
      : metadata.activeWidgetId[0];

    if (isGroupWidget) {
      newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgets[targetWidgetIndex],
          innerHTML: document.getElementById(targetId).innerHTML,
        },
      });
    } else {
      newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgets[targetWidgetIndex],
          innerHTML: document.getElementById(metadata.activeWidgetId[0]).innerHTML,
        },
      });
    }

    updateContext && updateWidgets(newArray);

    if (!props.isFromMore) {
      if (opacity < 100) props.setIsOpacityApplied(true);
      else props.setIsOpacityApplied(false);
    }
  }, [opacity, updateContext]);

  return (
    <DropdownMenu className={cx(style["shadow"], style["border-0"], style["rounded"])}>
      <SliderControll
        Slider={opacity}
        setSlider={setOpacity}
        SliderInputValue={opacityInputValue}
        setSliderInputValue={setOpacityInputValue}
        sliderMax={100}
        sliderMin={0}
        setUpdateContext={setUpdateContext}
      />
    </DropdownMenu>
  );
};
WidgetOpacity.propTypes = {
  setIsOpacityApplied: PropTypes.func,
  isFromMore: PropTypes.bool,
};

export default WidgetOpacity;
