import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import FontFaceObserver from "fontfaceobserver";
import LoadingBar, { hideLoading } from "react-redux-loading-bar";

import { fetchDocumentDetails, updateDocumentReducer } from "../../store/actions/documentActions";
import { getResourceEntries } from "../../store/actions/resourcesActions";
import { fetchTemplateDetails } from "../../store/actions/templateActions";
import { Icon } from "../ui/icon";
import Modal from "../ui/modal";
import {
  alignSvg,
  displayAbstractShapes,
  prepareVideoHtml,
  stopAllResourceBuffering,
  getTemplateInfo,
  fileDownload,
} from "../../_helpers/utils";

import global from "../../scss/dhp.scss";
import Free from "../../assets/images/ui-love.svg";
import Premium from "../../assets/images/ui-crown.svg";
import NotFound from "../public/NotFound";
import { useContextualUpgrade } from "../../hooks/useContextualUpgrade";
import { useCheckCompanyPlanInfo } from "../../hooks/useCheckCompanyPlanInfo";
import { COMPANY_SUPERADMIN } from "../../constants/company";
import ContextualUpgrade from "../Subscription/ContextualUpgrade";
import { useDocumentCreatePermission } from "../../hooks/useDocumentCreatePermission";
import Tour from "../User/Tour";
import { TR_PREVIEW } from "../../constants/user";
import { CompanyModal } from "../Company/CompanyModal";
import { DOCUMENT_PERMISSION_RO } from "../../constants/document";
import CanvasPageHtmlGenaretor from "../Editor/CanvasPageHtmlGenaretor";
import useDocumentParser from "../../hooks/useDocumentParser";
import ParserLoader from "./ParserLoader";
import { HYPERLINK_TYPE_EMAIL, HYPERLINK_TYPE_PAGE, HYPERLINK_TYPE_SLIDE, HYPERLINK_TYPE_WEBSITE } from "../../constants/previewPresent";
import { fetchCompanyTemplateDetails, getCompanyInfo, getCompanyPublicConfig } from "../../store/actions/companyActions";
import { getUserInfo } from "../../store/actions/authActions";
import { AppContext } from "../../contexts";
import { useSocket } from "../../hooks/useSocket";
import { EXTERNAL_STREAMING_FAILED, EXTERNAL_STREAMING_PROGRESS, EXTERNAL_STREAMING_START, EXTERNAL_STREAMING_SUCCESS } from "../../constants/socket";
import CircularProgressbar from "../ui/circularProgressbar";
import useCustomFonts from "../../hooks/useCustomFonts";
import useDeletedCustomFonts from "../../hooks/useDeletedCustomFonts";
import MapInfoTooltip from "./MapInfoTooltip";
import useVideo from "../../hooks/useVideo";
import { MAP } from "../../constants/editor";
import { GET_COMPANY_PUBLIC_CONFIG_SUCCESS } from "../../store/actions/actionTypes";
import HTMLPlayer from "./HTMLPlayer";
import useAudioPlayer from "../../hooks/useAudioPlayer";

let style = Object.assign({}, global);

const Preview = ({ versionSlug, versionData, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const webSocket = useSocket();
  const { start: initDocumentParser } = useDocumentParser();
  useCustomFonts(); // use active custom fonts
  useDeletedCustomFonts(); // use deleted custom fonts
  const { videoPlayer } = useVideo();

  const { updateActiveCompany } = useContext(AppContext);
  const getSlugRecordId = () => {
    if (versionData) return { slug: versionSlug, recordId: versionData?.id };
    else return useParams();
  };

  const myParam = useLocation().search;
  const currentRef = new URLSearchParams(myParam)?.get("ref");

  const { slug, recordId } = getSlugRecordId();
  const previewOverflow = useRef(null);
  const previewBox = useRef(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalComponent, setModalComponent] = useState(false);
  const [alert, setAlert] = useState(false);
  const [showPreview, setShowPreview] = useState();
  const [docPagesMeta, setDocPagesMeta] = useState();
  const [downloadProgress, setDownloadProgress] = useState(false);
  const [downloadError, setDownloadError] = useState(false);
  const [isVideoPresent, setIsVideoPresent] = useState(false);
  const [muted, setMuted] = useState(true);
  const [showMapTooltip, setShowMapTooltip] = useState(false);
  const [isToggleMute, setIsToggleMute] = useState(false);
  const [isShowBadge, setIsShowBadge] = useState(false);
  const [isVideoTypeDocument, setIsVideoTypeDocument] = useState(false);
  const published = props?.published ?? false;

  useAudioPlayer({ docPagesMeta, muted, origin: "preview" }) //audio player

  const toggleMute = mute => {
    setIsToggleMute(true);
    setMuted(mute);
    document.querySelectorAll("video, audio").forEach(v => (v.muted = mute));
  };

  // useContextualUpgrade Hook to show update modal
  const showUpgrade = useContextualUpgrade();

  // Custom hook to check document create permission
  const [checkToDocumentCreate, isCreate] = useDocumentCreatePermission();

  // check is this company is paid or not
  const paidCompanyInfo = useCheckCompanyPlanInfo(true);

  const authState = useSelector(state => state?.auth);
  const fonts = useSelector(state => state?.resources?.fonts);
  const companyState = useSelector(state => state?.company);
  const recordDetails = published
    ? props?.documentDetails
    : versionData
      ? versionData
      : slug === "documents" && !versionData
        ? useSelector(state => state?.document?.documentDetails)
        : slug === "templates"
          ? useSelector(state => state?.template?.templateDetails)
          : useSelector(state => state?.company?.companyTemplateInfo);

  const success = !versionData && recordDetails?.data?.id === recordId ? recordDetails?.success ?? true : true;

  const recordData = published
    ? recordDetails?.document?.rawdata
    : versionData
      ? recordDetails?.version?.rawdata
      : recordDetails?.data?.filedata
        ? JSON.parse(recordDetails?.data?.filedata)
        : recordDetails?.data?.version?.rawdata;

  const docName = published
    ? recordDetails?.name
    : slug === "documents"
      ? recordDetails?.data?.document_name
      : recordDetails?.data?.name;

  const docHtml = recordData?.contexts?.pageNodes;
  const docFonts = recordData?.metadata?.fonts;
  const dimension = recordData?.metadata?.dimension;

  const recordFullData = published
    ? { template_type: recordDetails?.type, version: { rawdata: recordDetails?.document?.rawdata } } // for Published
    : versionData
      ? recordDetails // for version
      : recordDetails?.data?.filedata
        ? { template_info: { type: recordDetails?.data?.type }, version: { rawdata: JSON.parse(recordDetails?.data?.filedata) } } // for Template
        : recordDetails?.data; // for Document

  const invalidLinkModal = () => {
    return (
      <React.Fragment>
        <h4 className={cx(style["fw-7"], style["mb-4"])}>Invalid link</h4>

        <span
          aria-label="Close"
          className={cx(style["cross-modal"], style["rounded"])}
          onClick={() => setShowModal(false)}>
          <Icon icon="ui-close" />
        </span>

        <div>
          <div className={cx(style["delete-msg"], style["font-base"], style["color-33"], style["mb-4"])}>
            Sorry! The link is invalid
          </div>
        </div>
      </React.Fragment>
    );
  };

  const resizePreview = () => {
    if (!previewOverflow.current && !previewBox.current) return;
    let screenWidth = window.innerWidth;
    let totalPages = docHtml?.length;
    let { height: docHeight, width: docWidth } = dimension;
    // below code applying on dhp-page-canvas width to auto to fix wrong page width
    if (document.getElementsByClassName("dhp-page-canvas")[0] && !props.preserveWidth)
      document.getElementsByClassName("dhp-page-canvas")[0].style.width = `auto`;

    docWidth = parseInt(docWidth) + 120;
    if (docWidth > screenWidth) {
      let scaledval = screenWidth / docWidth;
      let translateX = (screenWidth - (docWidth * scaledval - 120)) / 2;
      previewOverflow.current.style.transform = `scale(${scaledval}) translateX(${translateX}px)`;
      previewBox.current.style.display = "flex";
      previewBox.current.style.height = `${(parseInt(docHeight) + 60) * scaledval * totalPages + (published ? 10 : 60) * scaledval}px`;
    } else {
      let translateX = (screenWidth - (docWidth - 120)) / 2;
      previewOverflow.current.style.transform = `scale(1) translateX(${translateX}px)`;
      previewBox.current.style.display = "flex";
      previewBox.current.style.height = `${(parseInt(docHeight) + 60) * totalPages + (published ? 10 : 60)}px`;
    }
    previewOverflow.current.style.willChange = "transform";  // resolved background line issue in preview
    document.querySelectorAll(".dhp-widgets").forEach(wz => {
      wz.style.backfaceVisibility = "";
      wz.querySelectorAll("img").forEach(img => img.setAttribute("draggable", false));
    })
  };

  const loadGoogleFonts = () => {
    let usedFonts = docFonts?.map(name => name.toLowerCase());
    let filteredFonts = fonts?.filter(f => usedFonts.includes(f.name.toLowerCase()));
    let promises = [];
    filteredFonts?.forEach(font => {
      let link = document.createElement("link");
      link.href = font.value;
      link.rel = "stylesheet";
      let linkSelector = document.querySelector('link[href="' + font.value + '"]');
      if (!linkSelector) {
        // append font cdn if not available in dom
        document.head.appendChild(link);

        if (font.normal_weight)
          promises.push(new FontFaceObserver(font.name, { weight: font.normal_weight, style: "normal" }).load());
        if (font.bold_weight)
          promises.push(new FontFaceObserver(font.name, { weight: font.bold_weight, style: "normal" }).load());
        if (font.normal_italic_weight)
          promises.push(new FontFaceObserver(font.name, { weight: font.normal_italic_weight, style: "italic" }).load());
        if (font.bold_italic_weight)
          promises.push(new FontFaceObserver(font.name, { weight: font.bold_italic_weight, style: "italic" }).load());
      }
    });
    Promise.all(promises)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setAlert(!alert);
      });
  };

  const playAllVideos = () => {
    document.querySelectorAll("video")?.forEach(video => {
      // video.play();
      const widget = video.closest(".dhp-widgets");
      if (widget) videoPlayer({ event: "PLAY", widgetNode: widget, videoNode: video });
    });
  };

  const linkToWebsite = widget => {
    let hyperLinkURL = widget.getAttribute("data-hyperlink-url");
    hyperLinkURL = hyperLinkURL.match(/^https?:/) ? hyperLinkURL : '//' + hyperLinkURL
    window.open(hyperLinkURL, "_blank");
  };

  const linkToEmail = widget => {
    window.location = "mailto:" + widget.getAttribute("data-hyperlink-url");
  };

  const linkToPage = widget => {
    let hyperlinkurl = widget.getAttribute("data-hyperlink-url");
    let targetPgeId = document.getElementById(`dhp-page-${hyperlinkurl}`);
    if (targetPgeId) targetPgeId.scrollIntoView({ behavior: "smooth" });
    else {
      setModalComponent({
        name: invalidLinkModal,
      });
      setShowModal(!showModal);
    }
  };

  const onExit = () => {
    stopAllResourceBuffering(document, "video");
    if (props.location?.state?.redirectFromPublic) history.push("/templates");
    else {
      if (window.history.length > 1) { window.history.back(); return false; }
      else window.close();
    }
  };

  const onReload = () => {
    location.reload();
  };

  const dynamicRouting = () => {
    stopAllResourceBuffering(document, "video");

    if (slug === "documents") {
      history.push(`/editor/${recordId}`);
    }

    if (slug === "templates" && paidCompanyInfo?.active_user_count) {
      const croppedThumb = recordDetails?.data?.thumb?.replace("/screenshot.png", "/screenshot-t.png") ?? "";
      if (
        recordDetails?.data?.premium &&
        paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN &&
        !paidCompanyInfo?.isPaid
      ) {
        // Call showUpgrade function to show contexttual upgrade modal based on your condition
        showUpgrade("premiumTemplate", croppedThumb);
      } else {
        checkToDocumentCreate();
      }
    }

    if (slug === "company-templates") {
      history.push({
        pathname: "/documents/create",
        state: getTemplateInfo({
          ...recordDetails?.data,
          thumb: recordDetails?.data?.thumb?.replace("/screenshot-t.png", "/screenshot-c.png") ?? "",
          ref: "company-template",
        }),
      });
    }
  };

  // download as PDF from public page
  const handleDownload = () => {
    if (webSocket?.readyState === 1 && props?.companyId) {
      setDownloadProgress(true);
      const subscribe = {
        type: EXTERNAL_STREAMING_START,
        companyId: props.companyId,
        entryId: recordDetails.id,
        quality: "low",
        fileName: recordDetails.id,
        desiredFileName: `${(docName.trim()).replace(/[^a-zA-Z0-9 ]/g, "")}.pdf`,
        external: true
      };
      webSocket.send(JSON.stringify(subscribe));
    }
  };

  //load company information if not
  useEffect(() => {
    if (!authState.company && authState?.user?.company?.id) {
      dispatch(getCompanyInfo(authState?.user?.company?.id));
      updateActiveCompany(authState?.user?.company)
    }
  }, [authState.user]);

  useEffect(() => {
    if (!authState?.user?.company?.id && !published) dispatch(getUserInfo());
    if (props?.companyId) dispatch(getCompanyPublicConfig(props?.companyId));
  }, []);

  // To Hide or Show the badge
  useEffect(() => {
    if (authState.type === GET_COMPANY_PUBLIC_CONFIG_SUCCESS && !companyState?.publicCompanyConfig?.hide_badge) setIsShowBadge(true);
  }, [companyState]);

  // Redirect to next action if document create permission ok
  useEffect(() => {
    if (isCreate) {
      history.push({
        pathname: "/documents/create",
        state: getTemplateInfo({
          ...recordDetails?.data,
          thumb: recordDetails?.data?.thumb?.replace("/screenshot.png", "/screenshot-c.png") ?? "",
        }),
      });
    }
  }, [isCreate]);

  useEffect(() => {
    if ((docFonts && fonts && recordDetails?.data?.id === recordId) || versionData) loadGoogleFonts();
  }, [recordFullData, fonts]);

  useEffect(() => {
    if (!dimension || loading || (recordDetails?.data?.id !== recordId && !versionData)) return;

    setIsVideoPresent(document.querySelectorAll("video, audio").length > 0);

    resizePreview();
    prepareVideoHtml();
    if (!isToggleMute && !isVideoTypeDocument) playAllVideos();
    alignSvg();
    displayAbstractShapes();
    window.addEventListener("resize", resizePreview);
    return () => {
      window.removeEventListener("resize", resizePreview);
    };
  }, [loading, dimension, docPagesMeta]);

  useEffect(() => {
    if (loading) return;

    document.querySelectorAll(`div[data-hyperlink-type='${HYPERLINK_TYPE_WEBSITE}']`).forEach(widget => {
      widget.addEventListener("click", () => linkToWebsite(widget));
    });

    document.querySelectorAll(`div[data-hyperlink-type=${HYPERLINK_TYPE_EMAIL}]`).forEach(widget => {
      widget.addEventListener("click", () => linkToEmail(widget));
    });

    document.querySelectorAll(`div[data-hyperlink-type=${HYPERLINK_TYPE_PAGE}], div[data-hyperlink-type=${HYPERLINK_TYPE_SLIDE}]`).forEach(widget => {
      widget.addEventListener("click", () => linkToPage(widget));
    });

    // For hide YouTube loader for version preview modal
    if (versionData) dispatch(hideLoading());

    return () => {
      document.querySelectorAll(`div[data-hyperlink-type=${HYPERLINK_TYPE_WEBSITE}]`).forEach(widget => {
        widget.removeEventListener("click", () => linkToWebsite(widget));
      });

      document.querySelectorAll(`div[data-hyperlink-type=${HYPERLINK_TYPE_EMAIL}]`).forEach(widget => {
        widget.removeEventListener("click", () => linkToEmail(widget));
      });

      document.querySelectorAll(`div[data-hyperlink-type=${HYPERLINK_TYPE_PAGE}], div[data-hyperlink-type=${HYPERLINK_TYPE_SLIDE}]`).forEach(widget => {
        widget.removeEventListener("click", () => linkToPage(widget));
      });
    };
  }, [docPagesMeta]);

  useEffect(() => {
    if (success) setShowPreview("document_success");
    else setShowPreview("document_not_found");
  }, [success]);

  useEffect(() => {
    if (!docPagesMeta && recordFullData?.version && !loading) {
      if (recordFullData?.version?.rawdata?.contexts?.pageNodes.length > 0) {
        setDocPagesMeta(recordFullData.version.rawdata.contexts);
        setIsVideoTypeDocument(Boolean(recordFullData.version.rawdata.contexts?.templateGroupName?.toLowerCase() === "video"))
      }
      else {
        initDocumentParser({ data: recordFullData }).then((meta) => {
          setDocPagesMeta(meta);
          setIsVideoTypeDocument(Boolean(meta.templateGroupName?.toLowerCase() === "video"))
        })
      }
    }
  }, [recordDetails, loading]);

  useEffect(() => {
    if (published) {
      if (docPagesMeta?.pageNodes)
        document.getElementById("show-presentation-view")?.classList?.remove("disable");
      else
        document.getElementById("show-presentation-view")?.classList?.add("disable");
    }
  }, [docPagesMeta])

  useEffect(() => {
    if (docPagesMeta?.widgets?.length > 0) {
      setShowMapTooltip(docPagesMeta.widgets.filter(wz => wz.data["data-asset-type"] === MAP)?.length > 0)
    }
  }, [docPagesMeta?.widgets])

  useEffect(() => {
    if (slug === "company-templates") dispatch(fetchCompanyTemplateDetails(recordId));
    if (slug === "documents") dispatch(updateDocumentReducer({ flashMessage: null }));
    if (currentRef === "editor" || (!versionData && !published)) {
      if (slug === "documents") dispatch(fetchDocumentDetails(recordId));
      if (slug === "templates") dispatch(fetchTemplateDetails(recordId));
      setLoading(true);
    }
    if (currentRef === "info") dispatch(fetchDocumentDetails(recordId));

    if (!fonts) {
      dispatch(getResourceEntries("fonts"));
      setLoading(true);
    }
    // Add classes
    document.body.classList.add("preview-body");

    return () => {
      //  Remove classes
      document.body.classList.remove("preview-body");
    }

  }, []);


  useEffect(() => {
    if (webSocket?.readyState === 1) {
      webSocket.addEventListener("message", onMessage);
      return () => {
        webSocket.removeEventListener("message", onMessage);
      };
    }
  }, [webSocket]);

  const onMessage = useCallback(message => {
    const response = JSON.parse(message?.data);

    if (response.type === EXTERNAL_STREAMING_FAILED) setDownloadError(!downloadError);

    if (response?.progress && response.type === EXTERNAL_STREAMING_PROGRESS) setDownloadProgress(response?.progress);

    if (response?.downloadUrl && response.type === EXTERNAL_STREAMING_SUCCESS) {
      setTimeout(() => {
        fileDownload(response?.downloadUrl, `${(docName.trim()).replace(/[^a-zA-Z0-9 ]/g, "")}.pdf`);
        setDownloadProgress(false)
      }, 2000);
    }
  }, []);

  return (
    <>
      {!versionData && <LoadingBar style={{ zIndex: "100002", height: "2px", backgroundColor: "#e4504f" }} />}
      {!loading && showPreview === "document_success" && (
        <div>
          {/* Company updated checking */}
          {authState.user?.company_updated === false && (
            <Modal
              showModal={true}
              modalClassName="p-0 company-modal"
              className="modal-lg"
              component={CompanyModal}
              type="update"
              backdrop="static"
            />
          )}
          {/* For contextual upgrade modal */}
          <ContextualUpgrade />
          {!versionData && (
            <header className={cx(style["dhp-header"], style["preview-header"])}>
              <div className={style["header-left"]}>
                <h3 className={cx(style["fw-7"], style["m-0"], style["text-truncate"])}>{docName}</h3>
              </div>

              {alert && (
                <>
                  <div className={style["header-center"]}>
                    <div className={cx(style["alert"], style["alert-danger"], style["alert-block"], style["d-none"])}>
                      Some fonts couldn&apos;t be loaded.{" "}
                      <span onClick={onReload} className={cx(style["cursor-pointer"], style["link-blue"])}>
                        Reload
                      </span>
                    </div>
                  </div>
                </>
              )}

              {published && (
                <div className={style["header-right"]}>
                  {isVideoPresent && !isVideoTypeDocument && (
                    <span
                      className={cx(style["publish-preview"], style["custom-tooltip"], style["rounded"], {
                        [style["toggle-mute"]]: !props?.documentDetails?.download,
                        [style["toggle-mute-download"]]: props?.documentDetails?.download,
                      })}
                      onClick={() => toggleMute(!muted)}>
                      <Icon icon={`ui-${muted ? "volume-mute" : "volume"}`} />
                      <div className={cx(style["custom-tooltip-content"], style["bottom"])}>
                        {muted ? "Unmute" : "Mute"}
                      </div>
                    </span>
                  )}
                  {props?.documentDetails?.download && !downloadProgress && (
                    <span
                      className={cx(style["publish-preview"], style["custom-tooltip"], style["rounded"], {
                        [style["mr-5"]]: !isVideoTypeDocument,
                      })}
                      onClick={handleDownload}>
                      <Icon icon="ui-download" />
                      <div className={cx(style["custom-tooltip-content"], style["bottom"])}>Download</div>
                    </span>
                  )}
                  {props?.documentDetails?.download && downloadProgress && (
                    <span
                      className={cx(style["publish-preview"], style["custom-tooltip"], style["rounded"], {
                        [style["mr-5"]]: !isVideoTypeDocument,
                      })}
                      style={{ cursor: "progress", alignItems: "center", display: "flex" }}>
                      <CircularProgressbar progress={downloadProgress} />
                    </span>
                  )}
                </div>
              )}

              {!published && (
                <div className={style["header-right"]}>
                  <ul className={cx(style["d-flex"], style["align-items-center"])}>
                    {isVideoPresent && !isVideoTypeDocument && (
                      <li>
                        <span id="toggleMute"
                          className={cx(style["edit-preview"], style["custom-tooltip"], style["rounded"])}
                          onClick={() => toggleMute(!muted)}>
                          <Icon icon={`ui-${muted ? "volume-mute" : "volume"}`} />
                          <div className={cx(style["custom-tooltip-content"], style["bottom"])}>
                            {muted ? "Unmute" : "Mute"}
                          </div>
                        </span>
                      </li>
                    )}
                    {slug === "templates" && (
                      <li>
                        <img
                          src={recordDetails?.data?.premium ? Premium : Free}
                          width="20"
                          className={style["mr-2"]}
                          alt=""
                        />{" "}
                        {recordDetails?.data?.premium ? "Premium" : "Free"}
                      </li>
                    )}
                    {recordDetails?.data?.permission !== DOCUMENT_PERMISSION_RO && (
                      <li>
                        <span
                          className={cx(style["edit-preview"], style["custom-tooltip"], style["rounded"], {
                            [style["disable"]]: !docPagesMeta?.pageNodes || !authState?.user?.company?.id,
                          })}
                          onClick={() => dynamicRouting()}
                          id="usethistemplate">
                          <Icon icon={slug === "documents" ? "ui-pencil" : "ui-cursor"} />
                          <div className={cx(style["custom-tooltip-content"], style["bottom"])}>
                            {slug === "documents" ? "Go to Editor" : "Use this Template"}
                          </div>
                        </span>
                        {recordDetails?.data?.premium && <Tour targetId="usethistemplate" tourType={TR_PREVIEW} />}
                      </li>
                    )}
                    <li>
                      <span
                        className={cx(style["close-preview"], style["custom-tooltip"], style["rounded"], {
                          [style["disable"]]: !docPagesMeta?.pageNodes,
                        })}
                        onClick={e => onExit(e)}>
                        <Icon icon="ui-close" />
                        <div className={cx(style["custom-tooltip-content"], style["bottom"])}>Close</div>
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            </header>
          )}

          <div
            className={cx(style["preview-content"], style["customScroll"], [style["scroll-Y"]])}
            style={{
              overflowY: docPagesMeta?.pageNodes ? "" : "hidden",
              height: docPagesMeta?.pageNodes ? "" : "calc(100vh - 70px)",
            }}>
            {(!docPagesMeta || !docPagesMeta?.pageNodes) && <ParserLoader origin={"preview"} />}
            {!isVideoTypeDocument && (
              <div className={style["preview-box"]} id="preview-box" ref={previewBox}>
                <div id="preview-overflow" ref={previewOverflow}>
                  {docPagesMeta?.pageNodes?.map((pageMeta, i) => (
                    <CanvasPageHtmlGenaretor
                      figureWidth={parseInt(dimension?.width)}
                      key={`preview-page-${i}`}
                      id={`dhp-page-${i}`}
                      className={"preview-page"}
                      pageNode={pageMeta}
                      docPagesMeta={docPagesMeta}
                      isPreviewPresentMode={false}
                    />
                  ))}
                </div>
              </div>
            )}
            {isVideoTypeDocument && (
              <HTMLPlayer
                docPagesMeta={docPagesMeta}
                dimension={dimension}
                origin={"preview"}
                type={slug}
                recordDetails={recordDetails}
                published={published}
              />
            )}

            {published && docPagesMeta?.pageNodes && (
              <div
                className={cx(style["preview-footer"], style["pb-4"], style["pt-4"], {
                  [style["d-none"]]: !isShowBadge && isVideoTypeDocument,
                })}>
                {isShowBadge && (
                  <div>
                    <Link to={{ pathname: "https://www.dochipo.com/" }} target="_blank">
                      Made in{" "}
                      <span className={style["logo-text"]}>
                        <span className={style["logo-red"]}>D</span>
                        <span className={style["logo-yellow"]}>o</span>
                        <span className={style["logo-green"]}>c</span>Hipo
                      </span>
                    </Link>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {showModal && (
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          component={modalComponent.name}
          modalClassName={modalComponent?.modalClassName}
          className={modalComponent?.className}
          module={modalComponent?.module}
          assetUrl={modalComponent?.assetUrl}
        />
      )}
      {showPreview === "document_not_found" && <NotFound />}
      {showMapTooltip && <MapInfoTooltip />}

      {docPagesMeta?.audios?.map((audio, idx) => (
        <audio id={`player-${audio.id}`} key={idx} src={audio.src} preload="auto" data-audio-id={audio.id}></audio>
      ))}
    </>
  );
};

//Props type validation
Preview.propTypes = {
  versionSlug: PropTypes.string,
  versionData: PropTypes.object,
  published: PropTypes.bool,
  documentDetails: PropTypes.object,
  location: PropTypes.object,
  preserveWidth: PropTypes.bool,
  companyId: PropTypes.string,
};

export default Preview;
