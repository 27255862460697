import React from "react";
import PropTypes from "prop-types";

const LoadFlagImage = ({ isoCode, ...props }) => {
  const image = require(`../../assets/images/icons/flags/${isoCode}.svg`).default;
  return <img width="20" src={image} {...props} />;
};

LoadFlagImage.propTypes = {
  isoCode: PropTypes.string.isRequired,
};

export default LoadFlagImage;
