import React, { useContext, useEffect, useState, useRef } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../scss/dhp.scss";
import { Icon } from "../../components/ui/icon";

import SliderControll from "../../components/Editor/SliderControll";
import { EditorContext } from "./EditorLayout";
import { BRAND, STOCK, UPLOAD_VIDEO, VIDEO } from "../../constants/editor";
import CanvasPageHtmlGenaretor from "../../components/Editor/CanvasPageHtmlGenaretor";
import DocumentTimeLineGenaretor from "../../components/Editor/DocumentTimeLineGenaretor";
import useAddPage from "../../hooks/useAddPage";
import { debounceTrailing, getDocumentDuration, operateStorageData } from "../../_helpers/utils";
import * as constant from "../../constants/editor";
import PageTimeLineGenaretor from "../../components/Editor/PageTimeLineGenaretor";
import { Input } from "../../components/ui/input";
import useSetActivePageBlock from "../../hooks/useSetActivePageBlock";
import AudioTimeLineGenaretor from "../../components/Editor/AudioTimeLineGenaretor";
import { UncontrolledTooltip } from "reactstrap";
import useGoToPage from "../../hooks/useGoToPage";
import useGetTotalDocDuration from "../../hooks/useGetTotalDocDuration";
import usePagePlay from "../../hooks/usePagePlay";
import useAudioPlay from "../../hooks/useAudioPlay";
import useDocumentPlay from "../../hooks/useDocumentPlay";

let style = Object.assign({}, global);

const VideoTimeline = props => {
  let {
    setIsTimeLineViewOpen,
    isTimeLineViewOpen,
    pageNodes,
    updateScrollEvent,
    documentType,
    widgets,
    audios,
    pagePlayData,
    setPagePlayData,
    whichPagePlaying,
    setWhichPagePlaying,
    documentPlayData,
    setDocumentPlayData,
    playingPageIdx,
    metadata,
    updateMetadata,
    isVideoTrimApplied,
  } = useContext(EditorContext);
  let scaleWidthRef = useRef(160);
  const { setVideoCurTimeToDefault } = usePagePlay();
  const { setAudioCurTimeToDefault } = useAudioPlay();
  const { documentPlayHeadHandler } = useDocumentPlay();

  const pageType = documentType === constant.TYPE_PRESENTATION ? "Slide" : "Page";
  const RANGE = (scaleWidthRef.current * 1) / 100;
  const MAX_WIDTH = RANGE * 100 + scaleWidthRef.current;
  const timeLineScale = [
    { scaleValue: "0:00" },
    { scaleValue: "0:01" },
    { scaleValue: "0:02" },
    { scaleValue: "0:03" },
    { scaleValue: "0:04" },
    { scaleValue: "0:05" },
    { scaleValue: "0:06" },
    { scaleValue: "0:07" },
    { scaleValue: "0:08" },
    { scaleValue: "0:09" },
    { scaleValue: "0:10" },
    { scaleValue: "0:11" },
  ];

  // All local states
  const [scaleWidth, setScaleWidth] = useState();
  const [scaleSlider, setScaleSlider] = useState();
  const [timelineScaleValue, setTimelineScaleValue] = useState(timeLineScale);
  const [detection, setDetection] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [isResizingPageTimeLine, setIsResizingPageTimeLine] = useState({ value: null, event: null, isResizing: false });
  const [isAddDisable, setIsAddDisable] = useState(false);
  const [activeContainerInfo, setActiveContainerInfo] = useState();
  const [timelinePageDropdownValue, setTimelinePageDropdownValue] = useState({
    name: `${pageType} ${parseInt(metadata.activePageIdx) + 1}`,
  });
  const [widgetResizeDetect, setWidgetResizeDetect] = useState();
  const [scrollSecMaxWidth, setScrollSecMaxWidth] = useState();
  const [playHeadLeft, setPlayHeadLeft] = useState(0);
  // document play and page play functionality from timeline
  const [isDocumentPlaying, setIsDocumentPlaying] = useState(false);
  const [isPagePlaying, setIsPagePlaying] = useState(false);
  const [forcedStop, setForcedStop] = useState(false);
  const [overflowHidden, setOverFlowHidden] = useState(false);

  // All custom hooks
  const { addPage } = useAddPage();
  useSetActivePageBlock(activeContainerInfo); // For set active page block by Hook
  const { goToPage } = useGoToPage();
  const { calculatedocDuration } = useGetTotalDocDuration();

  const findIdx = widgets.findIndex(widget => widget.id === metadata?.activeWidgetId?.[0]);
  let widgetInfo = widgets[findIdx];
  const isVideoTypeWidget =
    widgetInfo?.data["data-asset-type"] === VIDEO &&
    [STOCK, BRAND, UPLOAD_VIDEO].includes(widgetInfo?.data["data-source"]);

  const toggleTimeLineView = debounceTrailing(() => {
    if (playingPageIdx !== null) return; // disabled if in playing mode

    if (isTimeLineViewOpen === "page-timeline") setIsTimeLineViewOpen("document-timeline");
    else setIsTimeLineViewOpen("page-timeline");

    props.setFitToScreen(true);
    document.getElementById("timeline-body").scrollTop = 0;

    // document play and page play functionality from timeline
    if (["page-timeline", "document-timeline"].includes(isTimeLineViewOpen)) {
      setForcedStop(false);
      setPagePlayData({
        action: null,
        pageId: null,
        pageIdx: null,
        pageTimer: null,
        pageProgress: 0,
        pageDurationMs: 0,
        scrollData: null,
      });
      setDocumentPlayData({
        action: null,
        pageId: null,
        pageIdx: null,
        documentTimer: null,
        documentProgress: 0,
        documentDurationMs: 0,
        pageArray: [],
        scrollData: null,
      });
      setWhichPagePlaying({});
      operateStorageData({ type: "session", action: "remove", key: constant.DOCUMENT_PLAY_DATA_STORAGE_KEY });
      operateStorageData({ type: "session", action: "remove", key: constant.PAGE_PLAY_DATA_STORAGE_KEY });
      updateMetadata({ ...metadata, activeWidgetId: false, activeWidgetType: false });

      setTimeout(() => {
        document.getElementById("timeline-body").scrollTo(0, 0);
      }, 300);
    }

    if (isTimeLineViewOpen === "page-timeline") {
      // when switching to document timeline resetting media elements current time located on first page (because document play always starts from first page even if active page is different)
      const firstPageObj = pageNodes[0];
      setVideoCurTimeToDefault({ pageIdx: 0, pageId: firstPageObj.pageId });
      setAudioCurTimeToDefault({ pageIdx: 0 });
    }
  }, 300);

  const loadScale = (noOfIteration = 1) => {
    let newArray = [];
    if (noOfIteration < 0) {
      let array = [...timelineScaleValue];
      for (let i = 0; i < Math.abs(noOfIteration); i++) {
        array.pop();
      }
      setTimelineScaleValue(array);
    } else {
      Array.from({ length: noOfIteration }, (_, index) => {
        let lastScaleValue =
          newArray.length === 0
            ? timelineScaleValue[timelineScaleValue.length - 1]?.scaleValue
            : newArray[newArray.length - 1]?.scaleValue;

        if (!lastScaleValue) lastScaleValue = timeLineScale[timeLineScale.length - 1].scaleValue;
        let minuteValue = lastScaleValue.split(":")[0];
        let secondsValue = lastScaleValue.split(":")[1];
        let newSecondValue = parseInt(secondsValue, 10) + 1;

        let newMinuteValue;

        if (newSecondValue > 59) {
          newMinuteValue = parseInt(minuteValue, 10) + 1;
          newSecondValue = 0;
        }

        if (newSecondValue.toString().length == 1) {
          newSecondValue = newSecondValue.toString();
          newSecondValue = "0" + newSecondValue;
        }

        let newMinutesValue = newMinuteValue ?? minuteValue;

        let time;

        if (secondsValue) time = newMinutesValue?.toString() + ":" + newSecondValue;

        newArray.push({ scaleValue: time });
      });
      setTimelineScaleValue([...timelineScaleValue, ...newArray]);
    }
  };

  // This is used when opeing document line for first time
  const calculatePageBodyTimeLineWidth = (scaleWidth, category) => {
    const timeoutId = setTimeout(() => {
      let sum;
      let width;

      let widthOfHeader = document.getElementsByClassName("timeline-header")[0].clientWidth - 24;

      let scrollContainer = document.getElementById("timeline-body");

      sum = calculateWidthOfPage();
      width = calculateWidthOfTimeline();

      if (widthOfHeader > sum) {
        const numberOfList = Math.ceil(widthOfHeader / scaleWidth - timeLineScale.length);
        loadScale2(numberOfList);
        setOverFlowHidden(true);
        scrollContainer.scrollLeft = 0;
      } else {
        let number = sum / scaleWidth - timelineScaleValue.length;
        loadScale(Math.ceil(number));
        setOverFlowHidden(false);
      }

      // if (sum > width) loadScale(Math.ceil(number));
      // if (sum < width) loadScale2(numberOfList - 1);
    }, 430);

    return () => {
      clearTimeout(timeoutId);
    };
  };

  const handleSliderManipulation = action => {
    if (action === "increment" && scaleSlider < MAX_WIDTH) {
      setScaleWidth(prevState => prevState + RANGE);
      setScaleSlider(prevState => prevState + RANGE);
    }

    if (action === "decrement" && scaleSlider > scaleWidthRef.current) {
      setScaleWidth(prevState => prevState - RANGE);
      setScaleSlider(prevState => prevState - RANGE);
    }
  };

  const add = () => {
    if (isAddDisable) return;
    updateScrollEvent(true); // To prevent auto scroll
    addPage(pageNodes.length);
  };

  const calculateWidthOfPageLayers = () => {
    let highestWidth = 0;
    document.querySelectorAll(".widget .widget-animation").forEach(item => {
      const transformValue = parseInt(item.querySelector(".item").style.transform.split("(")[1], 10);

      if (highestWidth < item.clientWidth + transformValue) {
        highestWidth = item.clientWidth + transformValue;
      }
    });
    return highestWidth;
  };

  const calculateWidthOfPage = () => {
    let sum = 0;
    document.querySelectorAll(".scene-container li")?.forEach(val => {
      sum = sum + val.offsetWidth;
    });
    return sum;
  };

  const calculateWidthOfTimeline = () => document.querySelector(".time-container")?.offsetWidth;

  const calculateWidthOfPageTimeline = () => {
    const totalTimeLineWidth = document.querySelector(".time-container")?.offsetWidth;
    const totalLayerWidth = document.querySelector(".time-container .layer")?.offsetWidth;

    return totalTimeLineWidth - totalLayerWidth;
  };

  const handleLeftPanelToggle = () => {
    if (isTimeLineViewOpen) {
      const timeoutId = setTimeout(() => {
        if (isTimeLineViewOpen === "document-timeline") {
          const sum = calculateWidthOfPage();
          const width = calculateWidthOfTimeline();

          let widthOfHeader = document.getElementsByClassName("timeline-header")[0].clientWidth - 24;

          let scrollContainer = document.getElementById("timeline-body");

          if (widthOfHeader > sum) {
            const numberOfList = Math.ceil(widthOfHeader / scaleWidth - timeLineScale.length);
            loadScale2(numberOfList);
            setOverFlowHidden(true);
            scrollContainer.scrollLeft = 0;
          } else {
            const numberOfList = Math.ceil(width / scaleWidth - timeLineScale.length);
            let number = sum / scaleWidth - timelineScaleValue.length;
            setOverFlowHidden(false);
            if (sum < width) loadScale2(numberOfList - 1);
            else loadScale(Math.ceil(number));
          }
        } else {
          const sum = calculateWidthOfPageLayers();
          const widthOfScale =
            document.getElementsByClassName("timeline-header")[0].clientWidth - constant.PAGE_TIMELINE_LEFT_SECTION;
          const width = document.getElementsByClassName("page-duration-line")[0]?.offsetLeft - 203;

          const expectedWidthOfScale = 1.0375 * width + 24.4;
          const epsilon = 20;

          let highWidth = 0;
          if (sum < width) highWidth = width;
          else highWidth = sum;

          if (widthOfScale < highWidth) {
            const numberOfList = Math.ceil(highWidth / scaleWidth - timeLineScale.length);
            loadScale2(numberOfList);
            setOverFlowHidden(false);
          } else if (Math.abs(widthOfScale - expectedWidthOfScale) <= epsilon && widthOfScale > highWidth) {
            setOverFlowHidden(true);

            const numberOfList = Math.ceil(widthOfScale / scaleWidth - timeLineScale.length);

            loadScale2(numberOfList);
          } else if (Math.abs(widthOfScale - expectedWidthOfScale) >= epsilon && widthOfScale > highWidth) {
            const numberOfList = Math.ceil(widthOfScale / scaleWidth - timeLineScale.length);
            setOverFlowHidden(true);
            loadScale2(numberOfList);
          }
        }
      }, 450);

      return () => clearTimeout(timeoutId);
    }
  };

  const loadScale2 = (noOfIteration = 1) => {
    let newArray = [];
    if (noOfIteration < 0) {
      let array = [...timeLineScale];
      for (let i = 0; i < Math.abs(noOfIteration); i++) {
        array.pop();
      }
      setTimelineScaleValue(array);
    } else {
      Array.from({ length: noOfIteration }, (_, index) => {
        let lastScaleValue =
          newArray.length === 0
            ? timeLineScale[timeLineScale.length - 1]?.scaleValue
            : newArray[newArray.length - 1]?.scaleValue;

        let minuteValue = lastScaleValue.split(":")[0];
        let secondsValue = lastScaleValue.split(":")[1];
        let newSecondValue = parseInt(secondsValue, 10) + 1;

        let newMinuteValue;

        if (newSecondValue > 59) {
          newMinuteValue = parseInt(minuteValue, 10) + 1;
          newSecondValue = 0;
        }

        if (newSecondValue.toString().length == 1) {
          newSecondValue = newSecondValue.toString();
          newSecondValue = "0" + newSecondValue;
        }

        let newMinutesValue = newMinuteValue ?? minuteValue;

        let time;

        if (secondsValue) time = newMinutesValue?.toString() + ":" + newSecondValue;

        newArray.push({ scaleValue: time });
      });
      setTimelineScaleValue([...timeLineScale, ...newArray]);
    }
  };

  const calculatePageTimeLineWidth = (scaleWidth, layersWidth) => {
    const timeoutId = setTimeout(() => {
      const sum = calculateWidthOfPageLayers();
      const widthOfScale =
        document.getElementsByClassName("timeline-header")[0].clientWidth - constant.PAGE_TIMELINE_LEFT_SECTION;
      const width = document.getElementsByClassName("page-duration-line")[0]?.offsetLeft - 203;

      let scrollContainer = document.getElementById("timeline-body");

      let highWidth = 0;
      if (sum < width) highWidth = width;
      else highWidth = sum;

      if (widthOfScale < highWidth) {
        setOverFlowHidden(false);
        const numberOfList = Math.ceil(highWidth / scaleWidth - timeLineScale.length);
        loadScale2(numberOfList);
      } else {
        setOverFlowHidden(true);
        scrollContainer.scrollLeft = 0;
        const numberOfList = Math.ceil(widthOfScale / scaleWidth - timeLineScale.length);

        loadScale2(numberOfList + 1);
      }
    }, 40);

    return () => {
      clearTimeout(timeoutId);
    };
  };

  const calculateMaxWidthOfWidgetBar = () => {
    let leftStaticAreaWidth = constant.PAGE_TIMELINE_LEFT_SECTION;
    let currentBlockWidgetList = widgets.filter(widget => widget.pageId === metadata.activePageId);
    let pageDuration = pageNodes[metadata.activePageIdx]?.pageDuration;
    let targetTime = pageDuration;

    if (currentBlockWidgetList.length > 0) {
      currentBlockWidgetList.forEach(widget => {
        if (parseFloat(widget.duration?.endTime) > parseFloat(pageDuration)) {
          targetTime = widget.duration.endTime;
          return;
        }
      });
    }

    if (targetTime) {
      let bValue = parseInt(targetTime.split(".")[0]);
      let aValue = parseInt(targetTime.split(".")[1]);
      aValue = aValue ? aValue : 0;
      let widgetMaxWidth = scaleWidth * bValue + (scaleWidth / 10) * aValue + leftStaticAreaWidth;
      setScrollSecMaxWidth(widgetMaxWidth);
    }
  };

  const pageDurationLineCalculation = widthCalc => {
    const sum = calculateWidthOfPageLayers();
    const widthOfScale =
      document.getElementsByClassName("timeline-header")[0].clientWidth - constant.PAGE_TIMELINE_LEFT_SECTION;
    const width = widthCalc;

    let highWidth = 0;
    if (sum < width) highWidth = width;
    else highWidth = sum;

    if (widthOfScale < highWidth) {
      const numberOfList = Math.ceil(highWidth / scaleWidth - timeLineScale.length);
      loadScale2(numberOfList);
    } else {
      const numberOfList = Math.ceil(widthOfScale / scaleWidth - timeLineScale.length);
      loadScale2(numberOfList - 0.5);
    }
  };

  const playHeadDragEvent = {
    meta: false,

    drag: {
      dragToScroll: ({ client, scrollContainer, mouse, reducableLeft }, e) => {
        setTimeout(() => {
          if (mouse.initial.directionX === "RIGHT" && scrollContainer.rect.right - mouse.current.x <= 100) {
            const docDuration = calculatedocDuration();
            const scrollAmount = scrollContainer.node.scrollLeft + 10;
            const newScrollLeft = scrollAmount >= 0 ? scrollAmount : 0;

            scrollContainer.node.scrollLeft = newScrollLeft;
            playHeadDragEvent.meta.scrollContainer.current.scrollLeft = newScrollLeft;

            let newX = parseFloat(client.node.style.transform.split("(")[1]) + 10;

            if (newX <= parseFloat(docDuration) * scaleWidth) {
              client.node.style.transform = `translateX(${newX}px)`;
              client.pointerOffset.x =
                e.clientX - reducableLeft - parseFloat(client.node.style.transform.split("(")[1]);
              client.current = {
                transform: `translateX(${newX}px)`,
              };
            }
          }

          if (
            mouse.initial.directionX === "LEFT" &&
            mouse.current.x - (scrollContainer.rect.left + constant.PAGE_TIMELINE_LEFT_SECTION) <= 15
          ) {
            const scrollAmount = scrollContainer.node.scrollLeft - 10;
            const newScrollLeft = scrollAmount >= 0 ? scrollAmount : 0;

            scrollContainer.node.scrollLeft = newScrollLeft;
            playHeadDragEvent.meta.scrollContainer.current.scrollLeft = newScrollLeft;

            let newX = parseFloat(client.node.style.transform.split("(")[1]) - 10;
            newX = newX < 5 ? 0 : newX;

            if (newX >= 0) {
              client.node.style.transform = `translateX(${newX}px)`;
              client.pointerOffset.x =
                e.clientX - reducableLeft - parseFloat(client.node.style.transform.split("(")[1]);
              client.current = {
                transform: `translateX(${newX}px)`,
              };
            }
          }
        }, 100);
      },

      start: e => {
        let targetDiv = document.querySelector(".timeline-body");
        let rect = targetDiv.getBoundingClientRect();
        let pHleft = parseFloat(e.target.closest(".timeHandler-container")?.style.transform.split("(")[1]);
        let scrollContainer = document.querySelector(".timeline-body.customScroll");

        const {
          width: scrollContainerWidth,
          left: scrollContainerLeft,
          right: scrollContainerRight,
        } = scrollContainer.getBoundingClientRect();

        // fetch all details for active playhead
        let dataProps = {
          isDragging: true,
          isFirstEvent: true,
          reducableLeft: rect.left,
          client: {
            node: e.target.closest(".timeHandler-container"),
            pointerOffset: {
              x: e.clientX - rect.left - pHleft,
            },
            initial: {
              transform: pHleft,
            },
            current: {
              transform: false,
            },
            drag: {
              width: null,
            },
          },
          scrollContainer: {
            node: scrollContainer,
            rect: { left: scrollContainerLeft, right: scrollContainerRight },
            scrollFactor: scrollContainer.scrollWidth / scrollContainerWidth,
            scrollLeftMax: scrollContainer.scrollWidth - scrollContainerWidth,
            scrollType: false,
            initial: {
              scrollLeft: scrollContainer.scrollLeft,
            },
            current: {
              scrollLeft: false,
            },
          },
          mouse: {
            initial: {
              x: e.pageX,
              directionX: false,
            },
            current: {
              x: false,
              directionX: false,
            },
          },
        };

        playHeadDragEvent.meta = dataProps;
        document.addEventListener("mousemove", playHeadDragEvent.drag.drag);
        document.addEventListener("mouseup", playHeadDragEvent.drag.stop);
      },

      drag: e => {
        let dragMeta = playHeadDragEvent.meta;

        if (dragMeta?.isDragging) {
          if (dragMeta.isFirstEvent) dragMeta.isFirstEvent = false; // event operations

          let docDuration = calculatedocDuration();
          let newX = e.clientX - dragMeta.reducableLeft - dragMeta.client.pointerOffset.x; // calculate current left during drag

          // Auto scroll during drag
          if (
            e.pageX - dragMeta.scrollContainer.rect.left <= 15 ||
            dragMeta.scrollContainer.rect.right - e.pageX <= 100
          ) {
            dragMeta.mouse.current.x = e.pageX;

            if (!dragMeta.mouse.initial.directionX)
              dragMeta.mouse.initial.directionX = e.movementX === 0 ? false : e.movementX > 0 ? "RIGHT" : "LEFT";
            if (dragMeta.mouse.initial.directionX)
              dragMeta.mouse.current.directionX = e.movementX === 0 ? false : e.movementX > 0 ? "RIGHT" : "LEFT";

            playHeadDragEvent.drag.dragToScroll({ ...dragMeta }, e);
          }
          // update current left in dom (min left 0 and max right upto document duration)
          else if (newX >= 0 && newX <= parseFloat(docDuration) * scaleWidth) {
            dragMeta.client.node.style.transform = `translateX(${newX}px)`;
            dragMeta.client.current = {
              transform: `translateX(${newX}px)`,
            };
          }
        }
      },

      stop: () => {
        let stopMeta = playHeadDragEvent.meta;

        if (stopMeta?.isDragging) {
          if (stopMeta.client.current.transform) {
            const transX = parseFloat(stopMeta.client.current.transform.split("(")[1]);
            setPlayHeadLeft(transX);
            documentPlayHeadHandler({ transX, scaleWidth });
          }
          document.removeEventListener("mousemove", playHeadDragEvent.drag.drag);
          document.removeEventListener("mouseup", playHeadDragEvent.drag.stop);
          stopMeta = false;
        }
      },
    },
  };

  // document play and page play functionality from timeline
  const toggleDocumentPlay = () => {
    setForcedStop(false);
    setIsDocumentPlaying(!isDocumentPlaying);
  };

  const togglePagePlay = () => {
    setForcedStop(false);
    setIsPagePlaying(!isPagePlaying);
  };

  useEffect(() => {
    setTimelinePageDropdownValue({
      name: `${pageType} ${parseInt(metadata.activePageIdx) + 1}`,
    });
  }, [metadata.activePageIdx]);

  useEffect(() => {
    if (detection) {
      const timeoutId = setTimeout(() => {
        if (isTimeLineViewOpen === "document-timeline") {
          const sum = calculateWidthOfPage();
          const width = calculateWidthOfTimeline();

          let widthOfHeader = document.getElementsByClassName("timeline-header")[0].clientWidth - 24;

          let scrollContainer = document.getElementById("timeline-body");

          if (widthOfHeader > sum) {
            const numberOfList = Math.ceil(widthOfHeader / scaleWidth - timeLineScale.length);
            loadScale2(numberOfList);
            setOverFlowHidden(true);
            scrollContainer.scrollLeft = 0;
          } else {
            setOverFlowHidden(false);
            const numberOfList = Math.ceil(width / scaleWidth - timeLineScale.length);
            let number = sum / scaleWidth - timelineScaleValue.length;

            if (sum > width) loadScale(Math.ceil(number));
            if (sum < width) loadScale2(numberOfList - 1);
          }
        } else {
          const sum = calculateWidthOfPageLayers();
          const widthOfScale =
            document.getElementsByClassName("timeline-header")[0].clientWidth - constant.PAGE_TIMELINE_LEFT_SECTION;
          const width = document.getElementsByClassName("page-duration-line")[0]?.offsetLeft - 203;

          let scrollContainer = document.getElementById("timeline-body");

          const expectedWidthOfScale = 1.0375 * width + 24.4;
          const epsilon = 20;

          let highWidth = 0;
          if (sum < width) highWidth = width;
          else highWidth = sum;

          if (widthOfScale < highWidth) {
            setOverFlowHidden(false);
            const numberOfList = Math.ceil(highWidth / scaleWidth - timeLineScale.length);
            loadScale2(numberOfList);
          } else if (Math.abs(widthOfScale - expectedWidthOfScale) <= epsilon && widthOfScale > highWidth) {
            setOverFlowHidden(true);
            scrollContainer.scrollLeft = 0;
            const numberOfList = Math.ceil(widthOfScale / scaleWidth - timeLineScale.length);
            loadScale2(numberOfList);
          } else if (Math.abs(widthOfScale - expectedWidthOfScale) >= epsilon && widthOfScale > highWidth) {
            setOverFlowHidden(true);
            scrollContainer.scrollLeft = 0;
            const numberOfList = Math.ceil(widthOfScale / scaleWidth - timeLineScale.length);

            loadScale2(numberOfList);
          }
        }
      }, 20);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [scaleWidth]);

  useEffect(() => {
    if (isResizing) {
      setOverFlowHidden(false);
      const timeoutId = setTimeout(() => {
        const sum = calculateWidthOfPage();
        const width = calculateWidthOfTimeline();

        let widthOfHeader = document.getElementsByClassName("timeline-header")[0].clientWidth - 24;

        let scrollContainer = document.getElementById("timeline-body");

        if (widthOfHeader > sum) {
          const numberOfList = Math.ceil(widthOfHeader / scaleWidth - timeLineScale.length);
          loadScale2(numberOfList);
          setOverFlowHidden(true);
          scrollContainer.scrollLeft = 0;
        } else {
          const numberOfList = Math.ceil(width / scaleWidth - timeLineScale.length);
          const number = sum / scaleWidth - timelineScaleValue.length;
          if (sum > width) loadScale(Math.ceil(number));
          if (sum < width) loadScale2(numberOfList - 1);
          if (Math.abs(sum) - Math.abs(width) > 56) loadScale(Math.ceil(number));
          setOverFlowHidden(false);
        }
      }, 10);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isResizing]);

  useEffect(() => {
    if (detection) {
      const timeoutId = setTimeout(() => {
        const sum = calculateWidthOfPage();
        const width = calculateWidthOfTimeline();

        let widthOfHeader = document.getElementsByClassName("timeline-header")[0].clientWidth - 24;

        let scrollContainer = document.getElementById("timeline-body");

        if (widthOfHeader > sum) {
          const numberOfList = Math.ceil(widthOfHeader / scaleWidth - timeLineScale.length);
          loadScale2(numberOfList);
          setOverFlowHidden(true);
          scrollContainer.scrollLeft = 0;
        } else {
          const numberOfList = Math.ceil(width / scaleWidth - timeLineScale.length);
          let number = sum / scaleWidth - timelineScaleValue.length;
          if (sum > width) loadScale(Math.ceil(number));
          if (sum < width) loadScale2(numberOfList - 1);
          setOverFlowHidden(false);
        }
      }, 5);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [pageNodes.length]);

  useEffect(() => {
    setScaleWidth(scaleSlider);
  }, [scaleSlider]);

  useEffect(() => {
    if (isTimeLineViewOpen === "page-timeline") {
      const timeoutId = setTimeout(() => {
        const sum = calculateWidthOfPageLayers();
        const widthOfScale =
          document.getElementsByClassName("timeline-header")[0].clientWidth - constant.PAGE_TIMELINE_LEFT_SECTION;
        const width = document.getElementsByClassName("page-duration-line")[0]?.offsetLeft - 203;

        let scrollContainer = document.getElementById("timeline-body");

        const expectedWidthOfScale = 1.0375 * width + 24.4;

        const epsilon = 20;

        let highWidth = 0;
        if (sum < width) highWidth = width;
        else highWidth = sum;

        if (widthOfScale < highWidth) {
          setOverFlowHidden(false);
          const numberOfList = Math.ceil(highWidth / scaleWidth - timeLineScale.length);
          loadScale2(numberOfList);
        } else if (Math.abs(widthOfScale - expectedWidthOfScale) <= epsilon && widthOfScale > highWidth) {
          setOverFlowHidden(true);
          scrollContainer.scrollLeft = 0;
          const numberOfList = Math.ceil(widthOfScale / scaleWidth - timeLineScale.length);
          loadScale2(numberOfList);
        } else if (Math.abs(widthOfScale - expectedWidthOfScale) >= epsilon && widthOfScale > highWidth) {
          setOverFlowHidden(true);
          scrollContainer.scrollLeft = 0;
          const numberOfList = Math.ceil(widthOfScale / scaleWidth - timeLineScale.length);
          loadScale2(numberOfList);
        }
      }, 40);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [metadata.activePageIdx]);

  useEffect(() => {
    if (isResizingPageTimeLine.event === "videoDurationLine-resizing") {
      const timeoutIdOne = setTimeout(() => {
        const widgetHighestWidth = calculateWidthOfPageLayers();

        const widthOfScale =
          document.getElementsByClassName("timeline-header")[0].clientWidth - constant.PAGE_TIMELINE_LEFT_SECTION;

        let scrollContainer = document.getElementById("timeline-body");

        let longestWidth;

        if (isResizingPageTimeLine.value.width - constant.PAGE_TIMELINE_LEFT_SECTION < widgetHighestWidth) {
          longestWidth = widgetHighestWidth;
        } else {
          longestWidth = isResizingPageTimeLine.value.width - constant.PAGE_TIMELINE_LEFT_SECTION;
        }

        if (widthOfScale > longestWidth) {
          const numberOfList = Math.ceil(widthOfScale / scaleWidth - timeLineScale.length);

          setOverFlowHidden(true);
          scrollContainer.scrollLeft = 0;

          loadScale2(numberOfList);
        } else {
          if (isResizingPageTimeLine.value.width >= widgetHighestWidth)
            pageDurationLineCalculation(isResizingPageTimeLine.value.width);
          else pageDurationLineCalculation(widgetHighestWidth);
          setOverFlowHidden(false);
        }
      }, 10);

      return () => {
        clearTimeout(timeoutIdOne);
      };
    } else {
      const timeoutIdTwo = setTimeout(() => {
        const pageDurationLineLeft = document.getElementsByClassName("page-duration-line")[0]?.offsetLeft - 203;
        const widgetHighestWidth = calculateWidthOfPageLayers();

        const widthOfScale =
          document.getElementsByClassName("timeline-header")[0].clientWidth - constant.PAGE_TIMELINE_LEFT_SECTION;

        let scrollContainer = document.getElementById("timeline-body");

        let newHighest = Math.max(isResizingPageTimeLine?.value?.width || 0, widgetHighestWidth);

        newHighest = Math.max(newHighest, pageDurationLineLeft);

        if (widthOfScale > newHighest) {
          const numberOfList = Math.ceil(widthOfScale / scaleWidth - timeLineScale.length);
          loadScale2(numberOfList);
          setOverFlowHidden(true);
          scrollContainer.scrollLeft = 0;
        } else {
          setOverFlowHidden(false);
          if (newHighest >= pageDurationLineLeft) pageDurationLineCalculation(newHighest);
          else pageDurationLineCalculation(pageDurationLineLeft);
        }
      }, 2);

      return () => {
        clearTimeout(timeoutIdTwo);
      };
    }
  }, [isResizingPageTimeLine]);

  useEffect(() => {
    if (isTimeLineViewOpen === "page-timeline") {
      const timeoutId = setTimeout(() => {
        const sum = calculateWidthOfPageLayers();
        const widthOfScale =
          document.getElementsByClassName("timeline-header")[0].clientWidth - constant.PAGE_TIMELINE_LEFT_SECTION;
        const width = document.getElementsByClassName("page-duration-line")[0]?.offsetLeft - 203;

        const expectedWidthOfScale = 1.0375 * width + 24.4;
        let scrollContainer = document.getElementById("timeline-body");

        const epsilon = 20;

        let highWidth = 0;
        if (sum < width) highWidth = width;
        else highWidth = sum;

        if (widthOfScale < highWidth) {
          const numberOfList = Math.ceil(highWidth / scaleWidth - timeLineScale.length);
          loadScale2(numberOfList);
          setOverFlowHidden(false);
        } else if (Math.abs(widthOfScale - expectedWidthOfScale) <= epsilon && widthOfScale > highWidth) {
          const numberOfList = Math.ceil(widthOfScale / scaleWidth - timeLineScale.length);
          loadScale2(numberOfList);
          setOverFlowHidden(true);
          scrollContainer.scrollLeft = 0;
        } else if (Math.abs(widthOfScale - expectedWidthOfScale) >= epsilon && widthOfScale > highWidth) {
          const numberOfList = Math.ceil(widthOfScale / scaleWidth - timeLineScale.length);
          loadScale2(numberOfList);
          setOverFlowHidden(true);
          scrollContainer.scrollLeft = 0;
        }
      }, 10);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [widgets.length, isVideoTrimApplied]);

  useEffect(() => {
    if (isVideoTypeWidget && !widgetInfo?.data["data-loop"]) calculatePageTimeLineWidth(scaleWidth, "page-timeline");
  }, [widgetInfo?.data["data-loop"]]);

  useEffect(() => {
    // Disable page add option if total doc duration riched
    if (pageNodes.length > 0 && documentType !== constant.TYPE_INFOGRAPHIC) {
      let getDocDurationInfo = getDocumentDuration(pageNodes, metadata.activePageIdx);
      let availableTime = getDocDurationInfo.document.available;

      if (availableTime < 1) setIsAddDisable(true);
      else setIsAddDisable(false);
    }

    // if play head left is greater than doc time set its left to max doc duration
    let docDuration = calculatedocDuration();
    if (parseFloat(docDuration) * scaleWidth < parseFloat(playHeadLeft)) {
      setPlayHeadLeft(parseFloat(docDuration) * scaleWidth);
    }
  }, [pageNodes.length, pageNodes[metadata.activePageIdx]?.pageDuration]);

  useEffect(() => {
    if (isTimeLineViewOpen === "document-timeline") {
      scaleWidthRef.current = 56;
      setScaleWidth(56);
      setScaleSlider(56);
      calculatePageBodyTimeLineWidth(56, "document-timeline");
      setDetection(true);
      setOverFlowHidden(false);
    } else {
      setScaleWidth(160);
      scaleWidthRef.current = 160;
      setScaleSlider(160);
      setDetection(true);
      calculatePageTimeLineWidth(160, "page-timeline");
    }

    sessionStorage.setItem("timeline-status", isTimeLineViewOpen); // set timeline status in session storage to hold the prafarence when refresh the page

    // after timeline close go to active page
    const timeOutId2 = setTimeout(() => {
      let zoomVal = document.querySelector(".zoom-percent input").value;
      if (!isTimeLineViewOpen) goToPage(zoomVal);
    }, 500);

    return () => {
      clearTimeout(timeOutId2);
    };
  }, [isTimeLineViewOpen]);

  useEffect(() => {
    if (isTimeLineViewOpen === "page-timeline") calculateMaxWidthOfWidgetBar();
  }, [widgets, isTimeLineViewOpen]);

  useEffect(() => {
    calculateMaxWidthOfWidgetBar();
  }, [metadata.activePageIdx, pageNodes[metadata.activePageIdx]?.pageDuration, scaleWidth]);

  useEffect(() => {
    document.querySelector(".panel-toggle")?.addEventListener("click", handleLeftPanelToggle);

    return () => {
      document.querySelector(".panel-toggle")?.removeEventListener("click", handleLeftPanelToggle);
    };
  });

  useEffect(() => {
    if (isTimeLineViewOpen && isTimeLineViewOpen === "document-timeline") {
      // when add an audio 1st time fit on screen as document timeline height is increased
      if (audios?.length === 0 || audios?.length === 1) {
        setTimeout(() => {
          props.setFitToScreen(true);
        }, 600);
      }
    }
  }, [audios?.length]);

  // document play and page play functionality from timeline
  // toggle document play
  useEffect(() => {
    if (isTimeLineViewOpen === "document-timeline" && !forcedStop) {
      const newDocumentPlayData = {
        action: isDocumentPlaying ? "play" : "pause",
      };
      setDocumentPlayData({
        ...documentPlayData,
        ...newDocumentPlayData,
      });
      operateStorageData({
        type: "session",
        action: "set",
        key: constant.DOCUMENT_PLAY_DATA_STORAGE_KEY,
        isParseable: true,
        defaultData: newDocumentPlayData,
      });
    }
  }, [isDocumentPlaying, isTimeLineViewOpen, forcedStop]);

  // toggle page play
  useEffect(() => {
    if (isTimeLineViewOpen === "page-timeline" && !forcedStop) {
      const newPagePlayData = {
        action: isPagePlaying ? "play" : "pause",
        pageId: metadata.activePageId,
        pageIdx: parseInt(metadata.activePageIdx),
      };
      setPagePlayData({
        ...pagePlayData,
        ...newPagePlayData,
      });
      operateStorageData({
        type: "session",
        action: "set",
        key: constant.PAGE_PLAY_DATA_STORAGE_KEY,
        isParseable: true,
        defaultData: newPagePlayData,
      });
    }
  }, [isPagePlaying, isTimeLineViewOpen, forcedStop]);

  // stop timeline play
  useEffect(() => {
    if (["natural_stop"].includes(whichPagePlaying?.action)) {
      if (isTimeLineViewOpen === "document-timeline") {
        setIsDocumentPlaying(false);
        setForcedStop(true);
        setWhichPagePlaying({});
      }
      if (isTimeLineViewOpen === "page-timeline") {
        setIsPagePlaying(false);
        setForcedStop(true);
        setWhichPagePlaying({});
      }
    } else if (["pause", "force_stop"].includes(whichPagePlaying?.action)) {
      if (isTimeLineViewOpen === "document-timeline") {
        const newDocumentPlayData = {
          action: whichPagePlaying?.action,
        };
        setIsDocumentPlaying(false);
        setDocumentPlayData({
          ...documentPlayData,
          ...newDocumentPlayData,
        });
        operateStorageData({
          type: "session",
          action: "set",
          key: constant.DOCUMENT_PLAY_DATA_STORAGE_KEY,
          isParseable: true,
          defaultData: newDocumentPlayData,
        });
        setForcedStop(true);
        setWhichPagePlaying({});
      }
      if (isTimeLineViewOpen === "page-timeline") {
        const newPagePlayData = {
          action: whichPagePlaying?.action,
          pageId: metadata.activePageId,
          pageIdx: parseInt(metadata.activePageIdx),
        };
        setIsPagePlaying(false);
        setPagePlayData({
          ...pagePlayData,
          ...newPagePlayData,
        });
        operateStorageData({
          type: "session",
          action: "set",
          key: constant.PAGE_PLAY_DATA_STORAGE_KEY,
          isParseable: true,
          defaultData: newPagePlayData,
        });
        setForcedStop(true);
        setWhichPagePlaying({});
      }
    }
  }, [whichPagePlaying?.action]);

  // auto-slide playhead for document-timeline
  useEffect(() => {
    if (documentPlayData?.documentProgress >= 0 && whichPagePlaying?.triggerPoint !== "slider") {
      const documentDuration = documentPlayData?.documentDurationMs / 1000;
      const trackWidth = documentDuration * scaleWidth;
      const playheadTransX = documentPlayData.documentProgress * (trackWidth / 100);
      setPlayHeadLeft(playheadTransX);

      // auto scroll page scene along with document playhead (keep the playhead visible in viewport)
      const scrollContainer = document.getElementById("timeline-body");
      documentPlayData?.scrollData?.rangePoints?.forEach(range => {
        if (
          playheadTransX >= range.min &&
          playheadTransX <= range.max &&
          parseInt(scrollContainer.dataset.scrollLeft ?? 0) !== range.min
        ) {
          scrollContainer.scrollLeft = range.min;
          scrollContainer.dataset.scrollLeft = range.min;
        }
      });

      // set document play head height :  32 px for => scene-container margin (16px) + audioSlide-container margin (16px)
      let docPlayHeadHeight =
        document.querySelector(".time-container")?.offsetHeight +
        document.querySelector(".scene-container")?.offsetHeight +
        document.querySelector(".audioSlide-container")?.offsetHeight +
        32;

      if (document.querySelector(".document-timeline-playhead"))
        document.querySelector(".document-timeline-playhead").style.height = `${docPlayHeadHeight}px`;
    }
  }, [documentPlayData?.documentProgress]);

  // toggle document/page play on hover-in/out the timeline
  const togglePlayOnHoverTimeline = action => {
    const scrollContainer = document.getElementById("timeline-body");
    const playData = isTimeLineViewOpen === "document-timeline" ? documentPlayData : pagePlayData;
    const setPlayData = isTimeLineViewOpen === "document-timeline" ? setDocumentPlayData : setPagePlayData;
    const playingData = isTimeLineViewOpen === "document-timeline" ? isDocumentPlaying : isPagePlaying;
    const togglePlayButton = isTimeLineViewOpen === "document-timeline" ? toggleDocumentPlay : togglePagePlay;

    if (action === "hoverIn" && playData.action === "play") {
      scrollContainer.dataset.lastAction = "play";
      togglePlayButton(!playingData);
      setPlayData({
        ...playData,
        action: "pause",
      });
    }
    if (action === "hoverOut" && scrollContainer.dataset.lastAction === "play") {
      scrollContainer.dataset.lastAction = "";
      togglePlayButton(!playingData);
      setPlayData({
        ...playData,
        action: "play",
      });
    }
  };

  return (
    <div
      id="timeline-wraper"
      className={cx(style["timeline-wraper"], style["rounded-md"], style["mb-2"], style[`${isTimeLineViewOpen}`], {
        [style["show-audio"]]: audios?.length > 0,
      })}>
      <div className={style["timeline-header"]}>
        <div className={style["left"]}>
          <ul className={cx(style["d-flex"], style["align-items-center"])}>
            {/* Document player */}
            {isTimeLineViewOpen === "document-timeline" && (
              <li className={cx(style["play"], style["mr-2"])}>
                <span
                  className={cx(style["d-flex"], style["cursor-pointer"], "document-timeline-player")}
                  id="document-timeline-player"
                  onMouseDown={() => toggleDocumentPlay()}>
                  <Icon icon={isDocumentPlaying ? "ui-pause-squared" : "ui-play-squared"} />
                  <UncontrolledTooltip
                    placement="top"
                    target="document-timeline-player"
                    boundariesElement={document.getElementById("app")}>
                    {isDocumentPlaying ? "Pause" : "Play"}
                  </UncontrolledTooltip>
                </span>
              </li>
            )}

            {/* Page player */}
            {isTimeLineViewOpen === "page-timeline" && (
              <li className={cx(style["play"], style["mr-2"])}>
                <span
                  className={cx(style["d-flex"], style["cursor-pointer"], "page-timeline-player")}
                  id="page-timeline-player"
                  onMouseDown={() => togglePagePlay()}>
                  <Icon icon={isPagePlaying ? "ui-pause-squared" : "ui-play-squared"} />
                  <UncontrolledTooltip
                    placement="top"
                    target="page-timeline-player"
                    boundariesElement={document.getElementById("app")}>
                    {isPagePlaying ? "Pause" : "Play"}
                  </UncontrolledTooltip>
                </span>
              </li>
            )}

            {/* Page dropdown for page timeline */}
            {isTimeLineViewOpen === "page-timeline" && (
              <li className={cx({ [style["disable"]]: playingPageIdx !== null })}>
                <div>
                  <Input
                    type="dropdown"
                    name="pageTimeLineDropdown"
                    classNamePrefix="select"
                    defaultValue={timelinePageDropdownValue}
                    selected={timelinePageDropdownValue}
                    getOptionValue={option => `${pageType} ${parseInt(option?.pageIdx) + 1}`}
                    getOptionLabel={option => (
                      <>
                        <CanvasPageHtmlGenaretor
                          figureWidth={60}
                          figureHeight={60}
                          className={"timelinePageList"}
                          pageNode={option}
                          pageType={pageType}
                        />
                      </>
                    )}
                    options={[...pageNodes]}
                    updateState={option => {
                      setTimelinePageDropdownValue({
                        name: `${pageType} ${parseInt(option.pageIdx) + 1}`,
                      }),
                        setActiveContainerInfo({
                          targetPageIdx: option.pageIdx,
                          targetBlockIdx: option.pageIdx,
                          disableAutoScroll: false,
                        });
                    }}
                    noOptionsMessage={() => "No results found"}
                  />
                </div>
              </li>
            )}
          </ul>
        </div>
        <div className={cx(style["right"], { [style["disable"]]: playingPageIdx !== null })}>
          <ul className={cx(style["px-1"])}>
            <li onClick={toggleTimeLineView}>
              <span className={cx(style["d-flex"], style["cursor-pointer"])}>
                <Icon icon="ui-arrow-right2" additionalclass="mr-2 font-22"></Icon> Show&nbsp;
                {isTimeLineViewOpen === "page-timeline" ? "Document" : "Page"} Timeline
              </span>
            </li>
            <li>
              <div className={cx(style["d-flex"], style["align-items-center"])}>
                <span
                  className={cx(style["d-flex"], style["cursor-pointer"], style["mr-2"])}
                  onClick={() => handleSliderManipulation("decrement")}>
                  <Icon icon="ui-minus-circle"></Icon>
                </span>
                <SliderControll
                  Slider={scaleSlider}
                  setSlider={setScaleSlider}
                  step={RANGE}
                  sliderMax={MAX_WIDTH}
                  sliderMin={scaleWidthRef.current}
                  hideSliderValue={true}
                />
                <span
                  className={cx(style["d-flex"], style["cursor-pointer"], style["ml-2"])}
                  onClick={() => handleSliderManipulation("increment")}>
                  <Icon icon="ui-plus-circle"></Icon>
                </span>
              </div>
            </li>
            <li
              onClick={() => {
                updateMetadata({
                  ...metadata,
                  activeWidgetId: false,
                  activeWidgetType: false,
                });
                setIsTimeLineViewOpen(false);
                props.setFitToScreen(true);
                if (document.getElementById("active-page-selctor"))
                  document.getElementById("active-page-selctor").style.visibility = "hidden";

                setTimeout(() => {
                  if (document.getElementById("active-page-selctor"))
                    document.getElementById("active-page-selctor").style.visibility = "visible";
                }, 500);
              }}>
              <span className={cx(style["rounded"], style["cross-timeline"])}>
                <Icon icon="ui-close"></Icon>
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div
        id="timeline-body"
        className={cx(style["timeline-body"], style["customScroll"], style["scroll-X"], style["scroll-Y"])}
        style={{ overflowX: overflowHidden ? "hidden" : "" }}
        onMouseEnter={() => togglePlayOnHoverTimeline("hoverIn")}
        onMouseLeave={() => togglePlayOnHoverTimeline("hoverOut")}>
        {/*  Timeline scale section */}
        <div
          className={cx(style["time-container"], style["d-flex"])}
          style={isTimeLineViewOpen === "page-timeline" ? { minWidth: `${scrollSecMaxWidth}px` } : {}}>
          {isTimeLineViewOpen === "page-timeline" && (
            <div className={cx(style["layer"], style["mr-3"])}>
              <span>Layers</span>
            </div>
          )}

          <ul className="timeScale12">
            {timelineScaleValue.map((scaleValue, idx) => (
              <li key={idx}>
                <div style={{ width: `${scaleWidth}px` }}>
                  <span className="begin_line"></span>
                  <span className="text">{scaleValue?.scaleValue}</span>
                  <span className="middle_line"></span>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Page Timeline section */}
        {isTimeLineViewOpen === "page-timeline" && (
          <div className={cx(style["widget-container"])}>
            <PageTimeLineGenaretor
              scaleWidth={scaleWidth}
              setWidgetResizeDetect={setWidgetResizeDetect}
              setIsResizingPageTimeLine={setIsResizingPageTimeLine}
            />
          </div>
        )}

        {/* Document Timeline section */}
        {isTimeLineViewOpen === "document-timeline" && (
          <>
            <ul className={cx(style["scene-container"], style["d-flex"], style["mt-3"], style["align-items-center"])}>
              <li
                id="document-timeline-sort-path-display"
                className={cx(style["document-timeline-sort-path-display"], style["d-none"])}></li>

              {pageNodes.map((pageNode, idx) => (
                <DocumentTimeLineGenaretor
                  key={pageNode.pageId}
                  pageNode={pageNode}
                  index={idx}
                  toggle={props.toggle}
                  scaleWidth={scaleWidth}
                  setIsResizing={setIsResizing}
                />
              ))}

              {/* add button after secenes */}
              <li style={{ paddingLeft: "10px" }}>
                <div
                  className={cx(
                    style["add-scene"],
                    {
                      [style["disabled"]]: isAddDisable,
                    },
                    { [style["disable"]]: playingPageIdx !== null }
                  )}
                  onClick={add}>
                  <Icon icon="ui-plus"></Icon>
                </div>
              </li>

              {/* document play head */}
              {documentPlayData.documentProgress > 0 && documentPlayData.documentProgress < 100 && (
                <div
                  className={cx(style["timeHandler-container"], "document-timeline-playhead")}
                  onMouseDown={e => playHeadDragEvent.drag.start(e)}
                  style={{ transform: `translateX(${playHeadLeft}px)` }}>
                  <div className={style["timeHandler-line"]}></div>
                  <div className={style["timeHandler-pointer"]}></div>
                </div>
              )}
            </ul>

            {/* Audio Section */}
            {audios?.length > 0 && (
              <div
                className={cx(style["audioSlide-container"], style["mt-2"], {
                  [style["disable"]]: playingPageIdx !== null,
                })}>
                <AudioTimeLineGenaretor scaleWidth={scaleWidth} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
VideoTimeline.propTypes = {
  setFitToScreen: PropTypes.func,
  toggle: PropTypes.func,
};

export default VideoTimeline;
