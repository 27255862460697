import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import global from "../../scss/dhp.scss";
import { EditorContext } from "../../containers/editor/EditorLayout";
import { RGBToHex } from "../../_helpers/utils";
import { contextualConfig } from "./editor_config";

let style = Object.assign({}, global);

const DoubleRangeSlider = props => {
  let { metadata } = useContext(EditorContext);
  let pos1 = 0,
    pos3 = 0;

  const [startColor, setStartColor] = useState();
  const [startColorPercentage, setStartColorPercentage] = useState();
  const [startColorPosition, setStartColorPosition] = useState();
  const [isStartNodeActive, setIsStartNodeActive] = useState();
  const [endColor, setEndColor] = useState();
  const [endColorPercentage, setEndColorPercentage] = useState();
  const [endColorPosition, setEndColorPosition] = useState();
  const [isEndNodeActive, setIsEndNodeActive] = useState();
  const [gradientColor, setGradientColor] = useState();
  const [isSwapColor, setIsSwapColor] = useState(false);

  const closeDragElement = () => {
    props.setUpdateContext(true);
    document.onmouseup = null;
    document.onmousemove = null;
  };

  const elementDrag = e => {
    props.setUpdateContext(false);
    let elmnt = document.querySelector(".gradient_button.active");
    e = e || window.event;
    e.preventDefault();

    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos3 = e.clientX;

    // set the element's new position:
    if (elmnt.offsetLeft - pos1 >= 0 && elmnt.offsetLeft - pos1 <= 200) {
      if (elmnt.getAttribute("id") === "startNode") setStartColorPosition(elmnt.offsetLeft - pos1);
      if (elmnt.getAttribute("id") === "endNode") setEndColorPosition(elmnt.offsetLeft - pos1);
    }
  };

  const handleMouseDown = (value, e) => {
    if (value === "start") {
      document.getElementById("endNode").classList.remove("active");
      document.getElementById("startNode").classList.add("active");
      setIsStartNodeActive(true);
      setIsEndNodeActive(false);
      props.setColor(!isSwapColor ? startColor : endColor);
    }
    if (value === "end") {
      document.getElementById("startNode").classList.remove("active");
      document.getElementById("endNode").classList.add("active");
      setIsEndNodeActive(true);
      setIsStartNodeActive(false);
      props.setColor(!isSwapColor ? endColor : startColor);
    }

    e = e || window.event;
    e.preventDefault();
    pos3 = e.clientX; // get the mouse cursor position at startup:

    // call a function whenever the cursor moves:
    document.onmouseup = closeDragElement;
    document.onmousemove = elementDrag;
  };

  useEffect(() => {
    if (!props.loadCurrentFormatting) {
      if (startColorPosition < endColorPosition) {
        setStartColorPercentage(startColorPosition / 2);
        if (isSwapColor) {
          setStartColor(endColor);
          setEndColor(startColor);
          setIsSwapColor(false);
        }
      } else if (startColorPosition > endColorPosition) {
        setEndColorPercentage(startColorPosition / 2);
        if (!isSwapColor) {
          setStartColor(endColor);
          setEndColor(startColor);
        }
        setIsSwapColor(true);
      }
    }
  }, [startColorPosition]);

  useEffect(() => {
    if (!props.loadCurrentFormatting) {
      if (endColorPosition > startColorPosition) {
        setEndColorPercentage(endColorPosition / 2);
        if (isSwapColor) {
          setStartColor(endColor);
          setEndColor(startColor);
          setIsSwapColor(false);
        }
      } else if (endColorPosition < startColorPosition) {
        setStartColorPercentage(endColorPosition / 2);
        if (!isSwapColor) {
          setStartColor(endColor);
          setEndColor(startColor);
        }
        setIsSwapColor(true);
      }
    }
  }, [endColorPosition]);

  useEffect(() => {
    if (!props.loadCurrentFormatting) {
      setGradientColor(
        `linear-gradient(90deg, ${startColor} ${startColorPercentage}%, ${endColor} ${endColorPercentage}%)`
      );
      props.setColorPickerGradient(
        `linear-gradient(${props.degree}deg, ${startColor} ${startColorPercentage}%, ${endColor} ${endColorPercentage}%)`
      );
    }
  }, [startColor, endColor, startColorPercentage, endColorPercentage, props.degree]);

  useEffect(() => {
    if (!props.loadCurrentFormatting) {
      if (isStartNodeActive) {
        if (!isSwapColor) setStartColor(props.color);
        else setEndColor(props.color);
      } else if (isEndNodeActive) {
        if (!isSwapColor) setEndColor(props.color);
        else setStartColor(props.color);
      }
    }
  }, [props.color]);

  useEffect(() => {
    let curElem = document.getElementById(metadata.activeWidgetId[0]);

    document.getElementById("endNode").classList.remove("active");
    document.getElementById("startNode").classList.add("active");
    setIsStartNodeActive(true);
    setIsEndNodeActive(false);
    setIsSwapColor(false);

    // Load current formatting if active widget has gradient color applied
    if (document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-grad-scolor")) {
      props.setColor(curElem.getAttribute("data-grad-scolor"));
      setStartColor(curElem.getAttribute("data-grad-scolor"));
      setStartColorPercentage(parseFloat(curElem.getAttribute("data-grad-spercen")));
      setStartColorPosition(parseFloat(curElem.getAttribute("data-grad-spercen")) * 2);
      setEndColor(curElem.getAttribute("data-grad-ecolor"));
      setEndColorPercentage(parseFloat(curElem.getAttribute("data-grad-epercen")));
      setEndColorPosition(parseFloat(curElem.getAttribute("data-grad-epercen")) * 2);
    }
    // Load current formatting if active widget has not gradient color applied
    else {
      if (props.color === "Mixed Colors") {
        let sColor = RGBToHex(curElem.querySelector(".dhp-widget-inner")?.style?.color);
        props.setColor(sColor);
        setStartColor(sColor);
      } else {
        setStartColor(props.color);
      }
      setStartColorPercentage(0);
      setStartColorPosition(0);
      setEndColor("#000000");
      setEndColorPercentage(100);
      setEndColorPosition(200);
    }
  }, []);

  return (
    <div className={cx(style["shadow-sm"], style["gradient-slider"])} style={{ background: gradientColor }}>
      <span
        className={cx(style["shadow"], style["gradient_button"])}
        style={
          (!isSwapColor && startColor === "transparent") || (isSwapColor && endColor === "transparent")
            ? {
                left: `${startColorPosition}px`,
                backgroundImage: `url(${contextualConfig.colorUrl.midium})`,
                "background-size": "100%",
              }
            : { left: `${startColorPosition}px`, background: !isSwapColor ? startColor : endColor }
        }
        // style={{ left: `${startColorPosition}px`, background: !isSwapColor ? startColor : endColor }}
        id="startNode"
        onMouseDown={e => handleMouseDown("start", e)}></span>
      <span
        className={cx(style["shadow"], style["gradient_button"])}
        style={
          (!isSwapColor && endColor === "transparent") || (isSwapColor && startColor === "transparent")
            ? { left: `${endColorPosition}px`, backgroundImage: `url(${contextualConfig.colorUrl.midium})` }
            : { left: `${endColorPosition}px`, background: !isSwapColor ? endColor : startColor }
        }
        // style={{ left: `${endColorPosition}px`, background: !isSwapColor ? endColor : startColor }}
        id="endNode"
        onMouseDown={e => handleMouseDown("end", e)}></span>
    </div>
  );
};

//Props type validation
DoubleRangeSlider.propTypes = {
  color: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  setColor: PropTypes.func,
  degree: PropTypes.number,
  setColorPickerGradient: PropTypes.func,
  setUpdateContext: PropTypes.func,
  setLoadCurrentFormatting: PropTypes.func,
  loadCurrentFormatting: PropTypes.bool,
};

export default DoubleRangeSlider;
