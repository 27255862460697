import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Button } from "reactstrap";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";

let style = Object.assign({}, global);

const DeleteElemModal = props => {
  const close = () => {
    props.closeModal && props.closeModal();
  };

  const handleSubmit = () => {
    if (props.deleteElem) props.deleteElem();
    close();
  };

  return (
    <React.Fragment>
      <h4 className={cx(style["fw-7"], style["mb-4"])}>{props.modalTitle}</h4>

      <span aria-label="Close" className={cx(style["cross-modal"], style["rounded"])} onClick={close}>
        <Icon icon="ui-close" />
      </span>

      <div>
        <div className={cx(style["delete-msg"], style["font-base"], style["color-33"], style["mb-4"])}>
          {props.modalBody}
        </div>

        <Button type="submit" color="danger" onClick={handleSubmit}>
          {props.modalButtonLabel}
        </Button>
      </div>
    </React.Fragment>
  );
};

DeleteElemModal.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  modalBody: PropTypes.string.isRequired,
  modalButtonLabel: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  deleteElem: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
};

export default DeleteElemModal;
