import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import Modal from "../../ui/modal";
import LinkModal from "../Modals/LinkModal";
import { EditorContext } from "../../../containers/editor/EditorLayout";

let style = Object.assign({}, global);

const Link = props => {
  let { metadata, widgets } = useContext(EditorContext);

  const [showModalStatus, setShowModalStatus] = useState(false);
  const [isLinkActive, setIsLinkActive] = useState();

  //Function to show the delete modal
  const showModal = () => {
    setShowModalStatus(!showModalStatus);
  };

  useEffect(() => {
    setIsLinkActive(document.getElementById(metadata.activeWidgetId[0])?.getAttribute("data-hyperlink-type"));
  }, [widgets]);

  return (
    <>
      <li
        className={cx(style["toolset-group"], {
          [style["active"]]: isLinkActive,
        })}>
        <div className={cx(style["toolset-item"], style["link-wrap"])}>
          <span className={cx(style["toolset-action"], style["custom-tooltip"])} onClick={showModal}>
            <Icon icon="ui-link" />

            {!props.more && !props.rightContext && (
              <div className={cx(style["custom-tooltip-content"], style["top"])}>Link</div>
            )}

            {(props.more || props.rightContext) && "Link"}
          </span>
        </div>
      </li>

      {showModalStatus && (
        <Modal
          showModal={showModalStatus}
          setShowModal={setShowModalStatus}
          component={LinkModal}
          modalClassName={"link-modal"}
        />
      )}
    </>
  );
};
Link.propTypes = {
  more: PropTypes.bool,
  rightContext: PropTypes.bool,
};

export default Link;
