import { useContext } from "react";
import {
  // SHAPE,
  TEXT,
  LINE,
  PICTURE,
  UPLOAD,
  SVG,
  VIDEO,
  YOUTUBE,
  TABLE,
  GROUP_WIDGET,
  STOCK,
  MAP,
  COLLAGE,
  BRAND,
  UPLOAD_VIDEO,
} from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";
import useResizeLineWidget from "./useResizeLineWidget";
import useResizeTextWidget from "./useResizeTextWidget";
import useCropToShapeResize from "./useCropToShapeResize";
import useEightHandlerResize from "./useEightHandlerResize";
import useTableResize from "./useTableResize";
import UseCheckWidgetAllignment from "./UseCheckWidgetAllignment";
import useCheckWidgetPosition from "./useCheckWidgetPosition";
import useDeleteWidget from "./useDeleteWidget";
import {
  // applyFilledRectangleShapeSmartResize,
  applyGroupWidgetSmartResize,
  getChildWidgetInitials,
} from "../_helpers/utils";
import useResizeMapWidget from "./useResizeMapWidget";
import useCollage from "./useCollage";

const useResizableSideEffects = () => {
  const { widgets, updateWidgets, dimension, updateTogglePlayButton, metadata, setTextFontSize } = useContext(EditorContext);

  const setResizeLineWidget = useResizeLineWidget();
  const [textResizeStart, textResize, textResizestop] = useResizeTextWidget();
  const [eightHandlerResizeStart, eightHandlerResize, eightHandlerResizeStop] = useEightHandlerResize();
  const [cropToShapeResizeStart, cropToShapeResize, cropToShapeResizeStop] = useCropToShapeResize();
  const { checkWidgetAllignmentForSingleWidget } = UseCheckWidgetAllignment();
  const deleteWidgetIfOutside = useCheckWidgetPosition();
  const setDeleteWidget = useDeleteWidget();
  const [tableResizeStart, tableResize, tableResizeStop] = useTableResize();
  const [mapResizeStart, mapResize, mapResizestop] = useResizeMapWidget();
  const { collageResize, collageResizeStop } = useCollage();

  const resizableSideEffects = {
    meta: false,

    start: data => {
      resizableSideEffects.meta = data;

      //  FOR TEXT RESIZE
      if (data.client.assetType === TEXT) textResizeStart(resizableSideEffects.meta);

      // 8 handler RESIZE
      if (
        resizableSideEffects.meta.client.dataset?.cropToShape !== "true" &&
        [UPLOAD, PICTURE, VIDEO].includes(resizableSideEffects.meta.client.assetType) &&
        SVG !== resizableSideEffects.meta.client.dataset.fileType &&
        resizableSideEffects.meta.client.dataset.source !== YOUTUBE
      ) {
        if (
          VIDEO === resizableSideEffects.meta.client.assetType &&
          [STOCK, BRAND, UPLOAD_VIDEO].includes(resizableSideEffects.meta.client.dataset.source)
        ) {
          updateTogglePlayButton(false);
        }
        eightHandlerResizeStart(data);
      }

      // crop-to-shape RESIZE
      if (resizableSideEffects.meta.client.dataset?.cropToShape === "true") {
        if (
          VIDEO === resizableSideEffects.meta.client.assetType &&
          [STOCK, BRAND, UPLOAD_VIDEO].includes(resizableSideEffects.meta.client.dataset.source)
        ) {
          updateTogglePlayButton(false);
        }
        cropToShapeResizeStart(data);
      }

      // FOR TABLE RESIZE
      if (data.client.assetType === TABLE) tableResizeStart(resizableSideEffects.meta);

      if (data.client.assetType === GROUP_WIDGET) {
        let childInitials = getChildWidgetInitials(data.client.id);
        resizableSideEffects.meta.client.initial.childInitials = childInitials;

        // stop group video when resize
        if (
          metadata.activeWidgetId.length === 1 &&
          metadata.activeWidgetType[0] === VIDEO &&
          resizableSideEffects.meta.client.dataset.source !== YOUTUBE
        ) {
          updateTogglePlayButton(false);
        }
      }

      //  FOR MAP RESIZE
      if (data.client.assetType === MAP) mapResizeStart(resizableSideEffects.meta);
    },

    resize: ({ width: widgetWidth, height: widgetHeight, left: widgetLeft, top: widgetTop }) => {
      let meta = resizableSideEffects.meta;

      // FOR SHAPE RESIZE
      // if (
      //   meta.client.assetType === SHAPE &&
      //   meta.client.dataset.category === "Rectangles" &&
      //   meta.client.dataset.scheme === "filled"
      // ) {
      //   let resizedData = { height: widgetHeight, width: widgetWidth };
      //   applyFilledRectangleShapeSmartResize({ widgetId: meta.client.id, resizedData });
      // }

      // FOR LINE RESIZE
      if (meta.client.assetType === LINE) {
        let lineThickness = document.querySelector(`#${meta.client.id} svg`).getAttribute("data-linethickness");

        setResizeLineWidget({
          lineThickness: parseInt(lineThickness),
          contextMenu: false,
          updateContext: false,
          widgetWidth: widgetWidth,
          widgetLeft: widgetLeft,
          widgetTop: widgetTop,
          meta: meta,
        });
      }

      // FOR TEXT RESIZE
      if (meta.client.assetType === TEXT) textResize(meta, widgetHeight, widgetWidth);

      // 8 handler RESIZE
      if (
        meta.client.dataset?.cropToShape !== "true" &&
        meta.crop?.isCropping &&
        [UPLOAD, PICTURE, VIDEO].includes(meta.client.assetType) &&
        SVG !== meta.client.dataset.fileType &&
        meta.client.dataset.source !== YOUTUBE
      ) {
        widgetWidth = widgetWidth ?? meta.client.initial.width;
        widgetHeight = widgetHeight ?? meta.client.initial.height;
        eightHandlerResize(meta, widgetHeight, widgetWidth);
      }

      // crop-to-shape RESIZE
      if (meta.client.dataset?.cropToShape === "true") {
        cropToShapeResize(meta, widgetWidth);
      }

      // FOR TABLE RESIZE
      if (meta.client.assetType === TABLE) tableResize(meta, widgetWidth);

      // FOR GROUP and SHIFT SELECT RESIZE
      if (meta.client.assetType === GROUP_WIDGET) {
        let resizedData = { height: widgetHeight };
        applyGroupWidgetSmartResize({
          widgetId: meta.client.id,
          initialValues: meta.client.initial,
          canvasZoom: dimension.zoom,
          resizedData,
          setTextFontSize,
        });
      }

      // FOR MAP RESIZE
      if (meta.client.assetType === MAP) mapResize(meta, widgetHeight, widgetWidth);

      // FOR COLLAGE RESIZE
      if (meta.client.assetType === COLLAGE) collageResize({ meta, widgetWidth, widgetHeight });
    },

    stop: () => {
      let meta = resizableSideEffects.meta;
      const updatedCss = meta.client.current;

      // FOR SHAPE RESIZE
      // if (meta.client.assetType === SHAPE) {
      //   // updating innerHtml of shapes which are modifying while resize
      //   const updatedInnerHtml =
      //     meta.client.dataset.category === "Rectangles" && meta.client.dataset.scheme === "filled"
      //       ? { innerHTML: document.getElementById(meta.client.id).innerHTML }
      //       : {};

      //   const { x_al, y_al } = checkWidgetAllignmentForSingleWidget(
      //     updatedCss.height,
      //     updatedCss.transform,
      //     updatedCss.width
      //   );
      //   const isDletable = deleteWidgetIfOutside(meta.client.id);

      //   // Check widget position during resize, if it is outside canvas area delete the widget else upadte new position
      //   if (isDletable) setDeleteWidget(true);
      //   else {
      //     updateWidgets(
      //       widgets.map((widget, idx) => {
      //         if (idx === meta.client.idx) {
      //           return {
      //             ...widget,
      //             style: {
      //               ...widget.style,
      //               ...updatedCss,
      //             },
      //             data: {
      //               ...widget.data,
      //               "data-x-allignment": x_al,
      //               "data-y-allignment": y_al,
      //             },
      //             ...updatedInnerHtml,
      //           };
      //         } else {
      //           return widget;
      //         }
      //       })
      //     );
      //   }
      // }

      // FOR LINE, GROUP_WIDGET, UPLOADED SVG, YOUTUBE VIDEO RESIZE
      if (
        [LINE, GROUP_WIDGET].includes(meta.client.assetType) ||
        (meta.client.assetType === UPLOAD && SVG === meta.client.dataset.fileType) ||
        (meta.client.assetType === VIDEO && meta.client.dataset.source === YOUTUBE)
      ) {
        const updatedInnerHtml = document.getElementById(meta.client.id).innerHTML;
        const { x_al, y_al } = checkWidgetAllignmentForSingleWidget(
          updatedCss.height,
          updatedCss.transform,
          updatedCss.width
        );
        const isDletable = deleteWidgetIfOutside(meta.client.id);

        // Check widget position during resize, if it is outside canvas area delete the widget else upadte new position
        if (isDletable) setDeleteWidget(true);
        else {
          updateWidgets(
            widgets.map((widget, idx) => {
              if (idx === meta.client.idx) {
                return {
                  ...widget,
                  style: {
                    ...widget.style,
                    ...updatedCss,
                  },
                  data: {
                    ...widget.data,
                    "data-x-allignment": x_al,
                    "data-y-allignment": y_al,
                  },
                  innerHTML: updatedInnerHtml,
                };
              } else {
                return widget;
              }
            })
          );
        }
      }

      // FOR TEXT RESIZE
      if (meta.client.assetType === TEXT) textResizestop(meta);

      // 8 handler RESIZE
      if (
        meta.client.dataset?.cropToShape !== "true" &&
        meta.crop?.isCropping &&
        [UPLOAD, PICTURE, VIDEO].includes(meta.client.assetType) &&
        SVG !== meta.client.dataset.fileType &&
        meta.client.dataset.source !== YOUTUBE
      ) {
        eightHandlerResizeStop(meta);
      }

      // crop-to-shape RESIZE
      if (meta.client.dataset?.cropToShape === "true") {
        cropToShapeResizeStop(meta);
      }

      // FOR TABLE RESIZE
      if (meta.client.assetType === TABLE) tableResizeStop(meta);

      // FOR MAP RESIZE
      if (meta.client.assetType === MAP) mapResizestop(meta);
      
      // FOR COLLAGE RESIZE
      if (meta.client.assetType === COLLAGE) collageResizeStop(meta);

      meta = false;
    },
  };

  return { start: resizableSideEffects.start, resize: resizableSideEffects.resize, stop: resizableSideEffects.stop };
};

export default useResizableSideEffects;
