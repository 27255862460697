import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import cx from "classnames";

import global from "../../../scss/dhp.scss";

import { Input } from "../../ui/input";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { contextualConfig } from "../editor_config";
import UseCheckWidgetAllignment from "../../../hooks/UseCheckWidgetAllignment";
import {
  calculateNewPositionOnRotatedObjectResize,
  getCssTransformObj,
  getUpdatedGroupInnerWidgetPos,
  getZoomedValue,
  updateHeightAndTopForInactiveGroupChildren,
} from "../../../_helpers/utils";

let style = Object.assign({}, global);

const Heading = () => {
  let { metadata, widgets, updateWidgets, dimension, changeBySocket, setTextFontSize } = useContext(EditorContext);

  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);

  const [headingValue, setHeadingValue] = useState();
  const [displayValue, setDisplayValue] = useState();
  const [loadCurrentFormatingHeight, setLoadCurrentFormatingHeight] = useState(true);

  const { checkWidgetAllignmentForSingleWidget } = UseCheckWidgetAllignment();

  const fetchHeadingTypeFromActiveWidget = () => {
    if (metadata.activeWidgetId) {
      let activeWidgetHeadingValue = document.getElementById(metadata.activeWidgetId[0])?.dataset?.heading;
      let index = contextualConfig.Heading.findIndex(heading => heading.value === activeWidgetHeadingValue);
      if (index !== -1) setDisplayValue({ name: contextualConfig.Heading[index].name });
      setLoadCurrentFormatingHeight(true);
    }
  };

  //Load current widget heading
  useLayoutEffect(() => {
    setTimeout(() => {
      fetchHeadingTypeFromActiveWidget();
    }, 10);
  }, [metadata.activeWidgetId]);

  //Apply heading when value is change
  useEffect(() => {
    if (headingValue) {
      //Change font size according to heading type
      let newArray;
      let index = contextualConfig.Heading.findIndex(heading => heading.value === headingValue);
      let fontSize = contextualConfig.Heading[index]["font-size"];
      let dataAttribute = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").dataset;
      let groupWidgetInitHeight = isGroupWidget && parseFloat(isGroupWidget.style.height);

      document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style.fontSize = `${fontSize}px`;
      setTextFontSize(fontSize);

      //Create node element according to heading type to change tagName
      let node = document.createElement(headingValue === "normal" ? "div" : headingValue);
      node.innerHTML = document.querySelectorAll("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner")[0].innerHTML;
      node.classList = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").classList;
      node.style.cssText = document.querySelector(
        "#" + metadata.activeWidgetId[0] + " .dhp-widget-inner"
      ).style.cssText;
      if (dataAttribute) {
        for (const [key, value] of Object.entries(dataAttribute)) {
          node.setAttribute(`data-${key}`, value);
        }
      }

      // if the text is under a group widget
      if (isGroupWidget) {
        // Get group updated height and inner widget updated top and height according group
        let { groupDivHeight, parcentHeight, parcentTop } = getUpdatedGroupInnerWidgetPos(
          targetId,
          id,
          document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight
        );

        // update DOM and group handler
        document.getElementById(targetId).style.height = `${groupDivHeight}px`;
        document.getElementById(id).style.height = parcentHeight;
        document.getElementById(id).style.top = parcentTop;
        document.getElementById(id).setAttribute("data-heading", headingValue);
        document.getElementById("dhp-widget-handler").style.height = `${getZoomedValue(
          groupDivHeight,
          dimension.zoom
        )}px`;

        // update height and top for inactive child widgets
        updateHeightAndTopForInactiveGroupChildren({
          group: isGroupWidget,
          groupOldHeight: groupWidgetInitHeight,
          groupNewHeight: groupDivHeight,
          activeChildId: id,
        });

        //Update new node element
        newArray = Object.assign([...widgets], {
          [targetWidgetIndex]: {
            ...widgets[targetWidgetIndex],
            style: {
              ...widgets[targetWidgetIndex].style,
              height: `${groupDivHeight}px`,
            },
            innerHTML: document.getElementById(targetId).innerHTML,
          },
        });
      }
      // if the text is a main widget
      else {
        const newHeight = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight;
        const { x_al, y_al } = checkWidgetAllignmentForSingleWidget(newHeight);

        const activeWidgetDOM = document.getElementById(metadata.activeWidgetId[0]);
        const widgetObject = widgets[targetWidgetIndex];
        const {
          translate: { x: widgetTransX, y: widgetTransY },
          rotate: { theta: widgetTheta },
        } = getCssTransformObj({
          transform: widgetObject.style.transform,
        });
        const { left, top } = calculateNewPositionOnRotatedObjectResize(
          parseFloat(widgetTransX),
          parseFloat(widgetTransY),
          parseFloat(widgetObject.style.width),
          parseFloat(newHeight),
          parseFloat(widgetObject.style.width),
          parseFloat(widgetObject.style.height),
          parseFloat(widgetTheta)
        );
        const widgetTransformStr = getCssTransformObj({
          translateX: `${left}px`,
          translateY: `${top}px`,
          transform: widgetObject.style.transform,
          returnStr: true,
        });

        // set handler
        let handler = document.getElementById("dhp-widget-handler");
        const handlerTransformStr = getCssTransformObj({
          translateX: `${getZoomedValue(left, dimension.zoom)}px`,
          translateY: `${getZoomedValue(top, dimension.zoom)}px`,
          transform: widgetObject.style.transform,
          returnStr: true,
        });

        // update handler and widget height in dom
        if (!loadCurrentFormatingHeight) {
          activeWidgetDOM.style.height = `${newHeight}px`;
          activeWidgetDOM.style.transform = widgetTransformStr;
          handler.style.height = `${getZoomedValue(newHeight, dimension.zoom)}px`;
          handler.style.transform = handlerTransformStr;
        }

        //Update new node element
        newArray = Object.assign([...widgets], {
          [targetWidgetIndex]: {
            ...widgetObject,
            style: {
              ...widgetObject.style,
              height: `${newHeight}px`,
              transform: widgetTransformStr,
            },
            data: {
              ...widgetObject.data,
              "data-heading": headingValue,
              "data-x-allignment": x_al,
              "data-y-allignment": y_al,
            },
            innerHTML: node.outerHTML,
          },
        });
      }

      updateWidgets(newArray);
    }
  }, [headingValue]);

  //check Heading is applied or not for collaboration
  useEffect(() => {
    if (changeBySocket && widgets[targetWidgetIndex]?.innerHTML) fetchHeadingTypeFromActiveWidget();
  }, [changeBySocket, widgets[targetWidgetIndex]?.innerHTML]);

  return (
    <li className={style["toolset-group"]}>
      <div className={cx(style["toolset-item"], style["heading-wrap"], style["font-common-action"])}>
        <div className={style["custom-tooltip"]}>
          {displayValue && (
            <Input
              type="dropdown"
              name="heading"
              classNamePrefix="select"
              defaultValue={displayValue}
              getOptionValue={option => option.name}
              getOptionLabel={option => (
                <span className={option.name.toLowerCase().replace(/ /g, "")}>{option.name}</span>
              )}
              options={contextualConfig.Heading}
              updateState={option => (setHeadingValue(option.value), setLoadCurrentFormatingHeight(false))}
              noOptionsMessage={() => "No results found"}
            />
          )}

          <div className={cx(style["custom-tooltip-content"], style["top"])}>Heading</div>
        </div>
      </div>
    </li>
  );
};

export default Heading;
