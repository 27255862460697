import React from "react";
import PropTypes from "prop-types";
import global from "../../../scss/dhp.scss";
import processImage from "../../../assets/images/process-loader.gif";

let style = Object.assign({}, global);

const getCompiledClassString = (classStr, returnStr = "") => {
  if (!classStr) return false;
  classStr.split(" ")?.forEach(cls => (returnStr += style[cls] ? `${style[cls]} ` : ""));
  return returnStr.trim();
};

export const ProcessLoader = ({ isOpen, ...props }) => {
  const data = {
    wrapperClass: getCompiledClassString(props?.wrapperClass) || style["ml-2"],
    imgClass: getCompiledClassString(props?.imgClass) || "",
    imgSrc: props?.imgSrc?.length > 0 ? props.imgSrc : processImage,
    imgWidth: parseInt(props?.imgWidth ?? 0) || "16",
    imgAlt: props?.imgAlt || "processing...",
  };

  return (
    isOpen && (
      <div className={data.wrapperClass}>
        <img className={data.imgClass} src={data.imgSrc} width={data.imgWidth} alt={data.imgAlt} />
      </div>
    )
  );
};

ProcessLoader.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  wrapperClass: PropTypes.string,
  imgClass: PropTypes.string,
  imgSrc: PropTypes.string,
  imgWidth: PropTypes.string,
  imgAlt: PropTypes.string,
};
