import { useContext, useState } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";

import global from "../scss/dhp.scss";
import { getHMSToSec, getRandomString, getSecToHMS, searchNodeByPoint } from "../_helpers/utils";
import circledPlay from "../../src/assets/images/ui-circled-play.svg";
import { AUDIO } from "../constants/editor";
import { widgetConfig } from "../components/Editor/editor_config";

let style = Object.assign({}, global);

const useDragDropAudio = () => {
  const { audios, setAudios, metadata, updateMetadata, pageNodes, isTimeLineViewOpen, setIsTimeLineViewOpen } =
    useContext(EditorContext);

  //Function to determine if the mouse is inside wrappper or not
  const isMouseInsideWrapper = e => {
    const wrapper = document.querySelector(".editor-wrapper");

    const wrapperRect = wrapper.getBoundingClientRect();

    return (
      wrapperRect.left <= e.clientX &&
      e.clientX <= wrapperRect.right &&
      wrapperRect.top <= e.clientY &&
      e.clientY <= wrapperRect.bottom
    );
  };

  const DND = {
    meta: null,

    updateAudioContext: (data, activePageIndex) => {
      const durations = pageNodes.map(node => parseFloat(node.pageDuration) || 0);
      const startTime = durations.slice(0, activePageIndex).reduce((total, duration) => total + duration, 0);
      const endTime = durations.reduce((total, duration) => total + duration, 0);
      //array of colors
      const colors = ["#FAEABC", "#D0D0F0", "#FBE7C6", "#F6D0CA", "#CADEEE", "#F5D8C3", "#DDEBD5", "#F9E3C3"];
      let lastColor = audios?.length > 0 ? audios[audios.length - 1].color : null;

      // Extract the file type from the file name
      const getFileType = filename => {
        return filename.split(".").pop() || "";
      };

      const fileType = getFileType(data.filename);
      const durationInSec = getHMSToSec({ hms: data.duration });
      const trimDuration = endTime > durationInSec ? durationInSec : endTime - startTime;
      const newAudioId = `dhp-audio-${getRandomString(8)}`;

      // Function to get a random color skipping the last used color
      const getRandomColor = () => {
        let availableColors = lastColor ? colors.filter(color => color !== lastColor) : colors;
        const randomIndex = Math.floor(Math.random() * availableColors.length);
        return availableColors[randomIndex];
      };

      let newAudio = {
        id: newAudioId,
        src: data.url,
        startTime: `${startTime}s`,
        endTime: `${endTime}s`,
        originalDuration: `${data.duration}s`,
        data: {
          ...widgetConfig[AUDIO][data.source].dataAttr,
          "data-file-type": fileType,
          "data-file-name": data.audioFilename,
          "data-duration": data.duration,
          "data-trim": `${0}, ${trimDuration}`,
          "data-trimmed-duration": getSecToHMS({ sec: trimDuration }),
        },
        color: getRandomColor(),
      };
      if (!audios || !Array.isArray(audios)) {
        setAudios([newAudio]);
      } else {
        setAudios([...audios, newAudio]);
      }

      if (isTimeLineViewOpen) {
        if (isTimeLineViewOpen === "page-timeline") {
          setIsTimeLineViewOpen("document-timeline"); // when insert any audio if page timeline vieww is open switch to documnet timeline
        }

        setTimeout(() => {
          document.getElementById(newAudioId).scrollIntoView(); // scroll to last added audio if it is not in viewport
          updateMetadata({
            ...metadata,
            activeWidgetId: false,
            activeWidgetType: false,
            activeAudioId: newAudioId,
          });
        }, 100);
      }
    },

    updateActivePageAndBlock: dropData => {
      // BUGFIX ~ delete active widgets when performing DND
      const correctedMeta = !document.getElementById(metadata.activeWidgetId[0])
        ? { activeWidgetId: false, activeWidgetType: false }
        : {};

      updateMetadata({
        ...metadata,
        activePageId: dropData.pageId,
        activePageIdx: parseInt(dropData.pageIdx),
        activeBlockId: dropData.blockId,
        activeBlockIdx: parseInt(dropData.blockIdx),
        ...correctedMeta,
      });
    },

    validateDropZone: (data, e) => {
      const { node: isDroppedOnBlock = false } = searchNodeByPoint({
        className: "dhp-page-block",
        x: e.pageX,
        y: e.pageY,
      });

      if (isDroppedOnBlock) {
        const dropData = {
          pageId: isDroppedOnBlock.closest(".dhp-page-canvas").getAttribute("id"),
          pageIdx: isDroppedOnBlock.closest(".dhp-page-canvas").getAttribute("data-page-idx"),
          blockId: isDroppedOnBlock.getAttribute("id"),
          blockIdx: isDroppedOnBlock.getAttribute("data-block-idx"),
          blockNode: isDroppedOnBlock,
        };
        DND.updateActivePageAndBlock(dropData);
        DND.updateAudioContext(data, dropData.pageIdx);
      } else {
        // Check if the mouse is within the wrapper area
        const isInsideWrapper = isMouseInsideWrapper(e);
        if (isInsideWrapper) {
          DND.updateAudioContext(data, metadata?.activePageIdx);
        }
      }
    },

    generateDragPathHTML: props => {
      if (props.dragPath) {
        props.dragPath.node.innerHTML = `
        <div class="${style["d-flex"]} ${style["align-items-center"]} ${style["bg-white"]} ${style["p-3"]} ${style["audio-drop"]}">
            <div class="${style["play-image"]}">
              <img src="${circledPlay}" alt="" />
            </div>
            <div class="${style["ml-3"]} ${style["info"]}">
                <h6 class="${style["m-0"]} ${style["text-truncate"]}">${props.client.data.audioFilename}</h6>
                ${props.client.data.duration}
            </div>
        </div>`;
      }
    },

    toggleDragPath: ({ action, ...meta }) => {
      if (action === "SHOW" && meta.dragPath.node) {
        meta.dragPath.node.classList.remove("d-none");
        meta.client.node.classList.add("disable-pointer");
        document.getElementsByTagName("body")[0].classList.add("grabbing");
      } else if (action === "HIDE" && meta.dragPath.node) {
        meta.dragPath.node.innerHTML = "";
        meta.dragPath.node.style.cssText = "";
        meta.dragPath.node.classList.add("d-none");
        meta.client.node.classList.remove("disable-pointer");
        document.getElementsByTagName("body")[0].classList.remove("grabbing");
        document.getElementsByTagName("body")[0].classList.remove("not-allow");
      }
    },

    updateDragPathCss: ({ e, ...meta }) => {
      const dragPathDim = {
        width: meta.dragPath.node.clientWidth,
        height: meta.dragPath.node.clientHeight,
      };

      const pos = {
        left: `${e.pageX - dragPathDim.width / 2}px`,
        top: `${e.pageY - dragPathDim.height / 2}px`,
      };

      meta.dragPath.node.style.transform = `translate(${pos.left}, ${pos.top})`;
      if (!meta.dragPath.node.style.width) meta.dragPath.node.style.width = `${dragPathDim.width}px`;
    },

    start: (data, e) => {
      e.preventDefault();

      const dragPath = document.getElementById("drag-path-display");

      let props = {
        isDragging: true,
        client: {
          node: e.target,
          data: data,
        },
        dragPath: {
          node: dragPath,
        },
      };

      DND.meta = props;
      DND.generateDragPathHTML({ ...props });
      document.addEventListener("mousemove", DND.drag);
      document.addEventListener("mouseup", DND.stop);
    },

    drag: e => {
      let meta = DND.meta;
      if (meta?.isDragging) {
        e.preventDefault();
        const isInsideWrapper = isMouseInsideWrapper(e);
        // Update cursor
        if (isInsideWrapper) {
          document.getElementsByTagName("body")[0].classList.remove("not-allow");
        } else {
          document.getElementsByTagName("body")[0].classList.remove("grabbing");
          document.getElementsByTagName("body")[0].classList.add("not-allow");
        }
        DND.toggleDragPath({ action: "SHOW", ...meta });
        DND.updateDragPathCss({ e, ...meta });
      }
    },

    stop: e => {
      let meta = DND.meta;
      if (meta?.isDragging) {
        e.preventDefault();
        DND.toggleDragPath({ action: "HIDE", ...meta });
        DND.validateDropZone(meta.client.data, e);
        document.removeEventListener("mousemove", DND.drag);
        document.removeEventListener("mouseup", DND.stop);
        meta = false;
      }
    },
  };

  return {
    start: DND.start,
  };
};

export default useDragDropAudio;
