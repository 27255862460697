import { useContext } from "react";
import { GROUP_WIDGET, SHAPE } from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";
import { getCssTransformObj, getCssTransformObjToString, getRandomString } from "../_helpers/utils";
import useSelectable from "./useSelectable";
import useReplaceDuplicateShapeId from "./useReplaceDuplicateShapeId";
import useSetDimension from "./useSetDimension";

const useCloneWidget = () => {
  let {
    metadata,
    widgets,
    keyBoardEvent,
    updateMetadata,
    updateWidgets,
    updateKeyBoardEvent,
    setRetainRotation,
    audios,
    setAudios,
    isTimeLineViewOpen,
    setIsTimeLineViewOpen,
  } = useContext(EditorContext);

  const { hideAllWidgetSelection } = useSelectable();
  const { replaceDuplicateIdFromSVG } = useReplaceDuplicateShapeId();
  const { setAudioDurationBasedOnPageDuration } = useSetDimension();

  // Clone widget during keyboard event ctrl+d or clone button clicked
  const cloneWidget = () => {
    if (!metadata.activeWidgetId) return;

    let updatedwidgetArray = [];
    let updatedWidgetId = [];
    let updatedWidgetAssetType = [];
    let targetWidgetIndexes = [];

    for (let i = 0; i < metadata.activeWidgetId.length; i++) {
      let id = metadata.activeWidgetId[i];
      let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
      let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
      targetWidgetIndexes.push(widgets.findIndex(widget => widget.id === targetId));
    }

    targetWidgetIndexes.sort().map(targetWidgetIndex => {
      //Copy Widget
      let cloneObj = widgets[targetWidgetIndex];

      //Paste Widget
      let targetDOM;
      let clonedObjAssetType = cloneObj.data["data-asset-type"];
      let newWidgetId =
        clonedObjAssetType !== GROUP_WIDGET ? "dhp-widget-" + getRandomString(8) : "dhp-group-" + getRandomString(8);
      let widgetTransform = cloneObj.style.transform;
      let widgettranslateValue = getCssTransformObj({ transform: widgetTransform, exclude: ["rotate", "scale"] });
      let widgetTransformObj = getCssTransformObj({
        transform: widgetTransform,
        translateX: `${parseFloat(widgettranslateValue.translate.x) + 25}px`,
        translateY: `${parseFloat(widgettranslateValue.translate.y) + 25}px`,
      });
      let widgetTransformString = getCssTransformObjToString({ transformObj: widgetTransformObj });

      // update innerWidgtes id if a group widget is cloned
      if (clonedObjAssetType === GROUP_WIDGET) {
        let copiedObjIneerHtml = cloneObj.innerHTML;
        targetDOM = document.createElement("div");
        targetDOM.innerHTML = copiedObjIneerHtml;
        targetDOM.querySelectorAll(".dhp-page-widget").forEach(element => {
          element.setAttribute("id", "dhp-widget-" + getRandomString(8));
        });
        targetDOM.querySelectorAll(".dhp-page-group").forEach(element => {
          element.setAttribute("id", "dhp-group-" + getRandomString(8));
        });
        replaceDuplicateIdFromSVG(targetDOM);
      }
      // Replace duplicate id for shape use in shape border
      if (
        clonedObjAssetType === SHAPE &&
        (cloneObj.data["data-border-style"] === "solid" || cloneObj.data["data-grad-scolor"])
      ) {
        let copiedObjIneerHtml = cloneObj.innerHTML;
        targetDOM = document.createElement("div");
        targetDOM.innerHTML = copiedObjIneerHtml;

        replaceDuplicateIdFromSVG(targetDOM, cloneObj);
      }

      let updatedClipboardObj = {
        ...cloneObj,
        pageId: metadata.activePageId,
        blockId: metadata.activeBlockId,
        id: newWidgetId,
        style: { ...cloneObj.style, transform: widgetTransformString },
        data: {
          ...cloneObj.data,
          "data-x-allignment": false,
          "data-y-allignment": false,
        },
        innerHTML: targetDOM ? targetDOM.innerHTML : cloneObj.innerHTML,
      };

      updatedwidgetArray.push(updatedClipboardObj);
      updatedWidgetId.push(newWidgetId);
      updatedWidgetAssetType.push(cloneObj.data["data-asset-type"]);
    });

    updateWidgets([...widgets, ...updatedwidgetArray]);

    // Note : timeout is given for - handler is not move to new cloned widget for keyboard event (For multi selct widget)
    setTimeout(() => {
      setRetainRotation(false);
      updateMetadata({
        ...metadata,
        activeWidgetId: updatedWidgetId,
        activeWidgetType: updatedWidgetAssetType,
      });
      updateKeyBoardEvent({ ...keyBoardEvent, clone: false });
      hideAllWidgetSelection({ exceptions: updatedWidgetId, type: "group" });
    }, 30);
  };

  // Clone widget during page or block clone
  const cloneBlockWidgets = (
    targetBlockId,
    currentBlockId,
    currentBlockIdx,
    currentPageId,
    currentPageIdx,
    reindexedPageArray,
    reindexedBlockArray,
    updatedBackgroundColorObj,
    updatedBackgroundImageObj
  ) => {
    let updatedwidgetArray = [];
    let targetWidgetIndexes = [];
    let linkedWidgetIncList = [];
    let newArray = Object.assign([...widgets]);

    // get the widget list which are linked with the block appear after added block
    document.querySelectorAll(".dhp-widget-hyperlinked").forEach(element => {
      if (
        parseInt(element.getAttribute("data-hyperlink-url")) > parseInt(metadata.activeBlockIdx) &&
        element.getAttribute("id")
      ) {
        linkedWidgetIncList.push(element.getAttribute("id"));
      }
    });

    //update links in widget array
    linkedWidgetIncList.forEach(id => {
      let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
      let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
      let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
      let linkedPageIdx = parseInt(document.getElementById(id).getAttribute("data-hyperlink-url"));

      if (isGroupWidget) {
        document.getElementById(id).setAttribute("data-hyperlink-url", linkedPageIdx + 1);

        newArray = Object.assign([...newArray], {
          [targetWidgetIndex]: {
            ...widgets[targetWidgetIndex],
            innerHTML: document.getElementById(targetId).innerHTML,
          },
        });
      } else {
        newArray = Object.assign([...newArray], {
          [targetWidgetIndex]: {
            ...widgets[targetWidgetIndex],
            data: {
              ...widgets[targetWidgetIndex].data,
              "data-hyperlink-url": linkedPageIdx + 1,
            },
          },
        });
      }
    });

    let clonedWidgetArray = newArray.filter(widget => widget.blockId === targetBlockId);

    for (let i = 0; i < clonedWidgetArray.length; i++) {
      targetWidgetIndexes.push(newArray.findIndex(widget => widget.id === clonedWidgetArray[i].id));
    }

    targetWidgetIndexes.map(targetWidgetIndex => {
      //Copy Widget
      let cloneObj = newArray[targetWidgetIndex];
      let targetDOM;
      let clonedObjAssetType = cloneObj.data["data-asset-type"];
      let newWidgetId =
        clonedObjAssetType !== GROUP_WIDGET ? "dhp-widget-" + getRandomString(8) : "dhp-group-" + getRandomString(8);

      // update innerWidgtes id if a group widget is cloned
      if (clonedObjAssetType === GROUP_WIDGET) {
        let copiedObjIneerHtml = cloneObj.innerHTML;
        targetDOM = document.createElement("div");
        targetDOM.innerHTML = copiedObjIneerHtml;
        targetDOM.querySelectorAll(".dhp-page-widget").forEach(element => {
          element.setAttribute("id", "dhp-widget-" + getRandomString(8));
          element.classList.remove(".group-selected");
        });
        targetDOM.querySelectorAll(".dhp-page-group").forEach(element => {
          element.setAttribute("id", "dhp-group-" + getRandomString(8));
          element.classList.remove(".group-selected");
        });
        replaceDuplicateIdFromSVG(targetDOM);
      }
      // Replace duplicate id for shape use in shape border
      if (
        clonedObjAssetType === SHAPE &&
        (cloneObj.data["data-border-style"] === "solid" || cloneObj.data["data-grad-scolor"])
      ) {
        let copiedObjIneerHtml = cloneObj.innerHTML;
        targetDOM = document.createElement("div");
        targetDOM.innerHTML = copiedObjIneerHtml;

        replaceDuplicateIdFromSVG(targetDOM, cloneObj);
      }

      //Paste Widget
      let updatedwidgetObj = {
        ...cloneObj,
        pageId: currentPageId,
        blockId: currentBlockId,
        id: newWidgetId,
        style: { ...cloneObj.style },
        innerHTML: targetDOM ? targetDOM.innerHTML : cloneObj.innerHTML,
      };

      updatedwidgetArray.push(updatedwidgetObj);
    });

    updateMetadata({
      ...metadata,
      activePageId: reindexedPageArray ? currentPageId : metadata.activePageId,
      activePageIdx: reindexedPageArray ? currentPageIdx : metadata.activePageIdx,
      activeBlockId: currentBlockId,
      activeBlockIdx: currentBlockIdx,
      activeWidgetId: false,
      activeWidgetType: false,
      activeOutSideCanvasArea: false,
      disableAutoScroll: false,
    });

    //audios which have same end time like doc duration, update thet audio's end time also as page duration time update
    if (audios?.length > 0) {
      let newAudioArray = setAudioDurationBasedOnPageDuration(
        reindexedPageArray[currentPageIdx].pageDuration,
        "clone-page"
      );

      updateWidgets(
        [...newArray, ...updatedwidgetArray],
        reindexedPageArray,
        reindexedBlockArray,
        updatedBackgroundColorObj,
        updatedBackgroundImageObj,
        currentBlockIdx,
        false,
        false,
        false,
        newAudioArray
      );
    } else
      updateWidgets(
        [...newArray, ...updatedwidgetArray],
        reindexedPageArray,
        reindexedBlockArray,
        updatedBackgroundColorObj,
        updatedBackgroundImageObj,
        currentBlockIdx
      );
  };

  // Clone audio during keyboard event ctrl+d or clone button clicked
  const cloneAudio = () => {
    let updatedAudioArray = [];
    let targetAudioIndexes = audios.findIndex(audio => audio.id === metadata.activeAudioId);
    let cloneObj = audios[targetAudioIndexes];
    let newAudioId = "dhp-audio-" + getRandomString(8);

    let updatedAudioObj = {
      ...cloneObj,
      id: newAudioId,
    };
    updatedAudioArray.push(updatedAudioObj);

    setAudios([...audios, ...updatedAudioArray]);
    updateMetadata({
      ...metadata,
      activeAudioId: newAudioId,
    });

    if (isTimeLineViewOpen) {
      if (isTimeLineViewOpen === "page-timeline") {
        setIsTimeLineViewOpen("document-timeline"); // when insert any audio if page timeline vieww is open switch to documnet timeline
      }

      // scroll to last added audio if it is not in viewport
      setTimeout(() => {
        document.getElementById(newAudioId).scrollIntoView();
      }, 100);
    }
  };

  return { cloneWidget, cloneBlockWidgets, cloneAudio };
};

export default useCloneWidget;
