import React, { useState, useEffect, useRef, useCallback } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { TabContent, TabPane } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import global from "../../../scss/dhp.scss";

import AssetsLoader from "../../ui/loader/assetsLoader";
import AssetName from "./Common/AssetName";
import AssetSchemeTypeNav from "./Common/AssetSchemeTypeNav";
import AssetSearch from "./Common/AssetSearch";
import { fetchVideos } from "../../../store/actions/editorActions";
import useElementInnerHtml from "../../../hooks/useElementInnerHtml";
import useAddWidget from "../../../hooks/useAddWidget";
import useAlignment from "../../../hooks/useAlignment";
import { widgetConfig } from "../editor_config";
import { stopAllResourceBuffering } from "../../../_helpers/utils";
import { STOCK, YOUTUBE } from "../../../constants/editor";
import useDragDropWidget from "../../../hooks/useDragDropWidget";
import { DotLoader } from "../../ui/loader/dotLoader";
import useTextFocusOut from "../../../hooks/useTextFocusOut";

let style = Object.assign({}, global);

const ActiveStockVideo = props => {
  return (
    <div className={style["preview-pen"]} id="video-preview">
      <video id="video-pen" autoPlay muted loop poster={props.thumb}>
        <source src={props.url} />
      </video>
      <div className={style["overlay"]} id="video-overlay">
        <div className={style["loader"]}></div>
      </div>
    </div>
  );
};
//Props type validation
ActiveStockVideo.propTypes = {
  thumb: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

const StockVideoList = props => {
  const playedVideoElement = useRef();

  let lowQualityUrl;
  const { video_files, source } = props.availableVideoList;
  if (source === "pixabay") lowQualityUrl = video_files?.tiny?.url;
  else lowQualityUrl = Object.values(video_files ?? {}).find(({ width, quality }) => width <= 640 && quality === "sd")?.link;

  const [isAssetLoad, setIsAssetLoad] = useState(false);
  const [assetInnerContent, setAssetInnerContent] = useState();
  const [addWidgetClicked, setAddWidgetClicked] = useState(false);
  const [param, setParam] = useState();

  const setTextFocusOut = useTextFocusOut();
  const { postion: getPosition } = useAlignment(
    addWidgetClicked,
    widgetConfig[props.assetType].width,
    widgetConfig[props.assetType].height,
    "middle-center"
  );
  const getNewWidgetObject = useAddWidget(addWidgetClicked, props.assetType, param, getPosition);
  useElementInnerHtml(
    addWidgetClicked,
    setAddWidgetClicked,
    props.assetType,
    assetInnerContent,
    param,
    getNewWidgetObject
  );

  const insertElem = (url, source, poster, duration, assetLoadComplete) => {
    if (!assetLoadComplete) return;

    if (document.querySelector(".dhp-content-editable-true-text")) setTextFocusOut(true);

    setTimeout(() => {
      let paramObj = {
        "data-origin": source,
        "data-poster": poster,
        "data-url": url,
        "data-duration": duration,
      };
      setAssetInnerContent(url);
      setParam({ ...widgetConfig[props.assetType].STOCK.dataAttr, ...paramObj });
      setAddWidgetClicked(true);
    }, 1);
  };

  const playVideoOnHover = assetLoadComplete => {
    if (!props.playVideo[playedVideoElement.current.dataset.idx] && assetLoadComplete) {
      props.setPlayVideo({ [playedVideoElement.current.dataset.idx]: true });

      // removing preview loader
      let intervalId = setInterval(() => {
        let runTimeVideo = document.getElementById("video-pen");
        let overlayLoader = document.getElementById("video-overlay");
        if (runTimeVideo && runTimeVideo.readyState == 4) {
          clearInterval(intervalId);
          overlayLoader.style.display = "none";
        }
      }, 300);
    }
  };

  const stopVideoOnMouseLeave = () => {
    props.setPlayVideo({ [playedVideoElement.current.dataset.idx]: false });
  };

  return (
    <React.Fragment key={props.availableVideoList.poster}>
      {props.length === props.index + 1 && (
        <li className={cx(style["col"], style["skeleton-loader-area"])} ref={props.lastStockVideoElement}>
          <div
            className={cx(style["asset-item"], style["main-heading"])}
            data-idx={props.index}
            ref={playedVideoElement}
            onMouseEnter={() => playVideoOnHover(isAssetLoad)}
            onMouseLeave={stopVideoOnMouseLeave}
            onClick={() =>
              insertElem(
                props.availableVideoList.url,
                props.availableVideoList.source,
                props.availableVideoList.poster,
                props.availableVideoList.duration,
                isAssetLoad
              )
            }
            onMouseDown={e => {
              isAssetLoad &&
                props.initDragDropWidget(
                  {
                    asset: props.assetType,
                    value: props.availableVideoList.url,
                    source: STOCK,
                    origin: props.availableVideoList.source,
                    poster: props.availableVideoList.poster,
                    duration: props.availableVideoList.duration,
                  },
                  e
                );
            }}>
            {!props.playVideo[props.index] && !isAssetLoad && <span className={style["loader-item"]}></span>}

            {!props.playVideo[props.index] && (
              <img
                src={props.availableVideoList.thumb}
                onLoad={() => setIsAssetLoad(true)}
                className={cx({ [style["d-none"]]: !isAssetLoad })}
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = props.availableVideoList.thumb;
                }}
              />
            )}

            {props.playVideo[props.index] && isAssetLoad && (
              <ActiveStockVideo thumb={props.availableVideoList.thumb} url={lowQualityUrl} />
            )}
          </div>

          {isAssetLoad && <span className={style["duration"]}>{props.availableVideoList.duration}</span>}
        </li>
      )}

      {props.length !== props.index + 1 && (
        <li className={cx(style["col"], style["skeleton-loader-area"])}>
          <div
            className={cx(style["asset-item"], style["main-heading"])}
            data-idx={props.index}
            ref={playedVideoElement}
            onMouseEnter={() => playVideoOnHover(isAssetLoad)}
            onMouseLeave={stopVideoOnMouseLeave}
            onClick={() =>
              insertElem(
                props.availableVideoList.url,
                props.availableVideoList.source,
                props.availableVideoList.poster,
                props.availableVideoList.duration,
                isAssetLoad
              )
            }
            onMouseDown={e => {
              isAssetLoad &&
                props.initDragDropWidget(
                  {
                    asset: props.assetType,
                    value: props.availableVideoList.url,
                    source: STOCK,
                    origin: props.availableVideoList.source,
                    poster: props.availableVideoList.poster,
                    duration: props.availableVideoList.duration,
                  },
                  e
                );
            }}>
            {!props.playVideo[props.index] && !isAssetLoad && <span className={style["loader-item"]}></span>}

            {!props.playVideo[props.index] && (
              <img
                src={props.availableVideoList.thumb}
                onLoad={() => setIsAssetLoad(true)}
                className={cx({ [style["d-none"]]: !isAssetLoad })}
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = props.availableVideoList.thumb;
                }}
              />
            )}

            {props.playVideo[props.index] && isAssetLoad && (
              <ActiveStockVideo thumb={props.availableVideoList.thumb} url={lowQualityUrl} />
            )}
          </div>

          {isAssetLoad && <span className={style["duration"]}>{props.availableVideoList.duration}</span>}
        </li>
      )}
    </React.Fragment>
  );
};
//Props type validation
StockVideoList.propTypes = {
  availableVideoList: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  lastStockVideoElement: PropTypes.func.isRequired,
  playVideo: PropTypes.object.isRequired,
  setPlayVideo: PropTypes.func.isRequired,
  assetType: PropTypes.string.isRequired,
  initDragDropWidget: PropTypes.func.isRequired,
};

const YoutubeVideoList = props => {
  const [isAssetLoad, setIsAssetLoad] = useState(false);
  const [assetInnerContent, setAssetInnerContent] = useState();
  const [addWidgetClicked, setAddWidgetClicked] = useState(false);
  const [param, setParam] = useState();

  const setTextFocusOut = useTextFocusOut();
  const { postion: getPosition } = useAlignment(
    addWidgetClicked,
    widgetConfig[props.assetType].width,
    widgetConfig[props.assetType].height,
    "middle-center"
  );
  const getNewWidgetObject = useAddWidget(addWidgetClicked, props.assetType, param, getPosition);
  useElementInnerHtml(
    addWidgetClicked,
    setAddWidgetClicked,
    props.assetType,
    assetInnerContent,
    param,
    getNewWidgetObject
  );

  const insertElem = (thumb, url, key) => {
    if (document.querySelector(".dhp-content-editable-true-text")) setTextFocusOut(true);

    setTimeout(() => {
      let paramObj = {
        "data-url": url,
        "data-key": key,
      };
      setAssetInnerContent(thumb);
      setParam({ ...widgetConfig[props.assetType].YOUTUBE.dataAttr, ...paramObj });
      setAddWidgetClicked(true);
    }, 1);
  };

  return (
    <React.Fragment key={props.availableVideoList.id}>
      {props.length === props.index + 1 && (
        <li className={cx(style["col"], style["skeleton-loader-area"])} ref={props.lastYouTubeVideoElement}>
          <div
            className={cx(style["asset-item"], style["flex-column"], style["main-heading"])}
            data-idx={props.index}
            onClick={() =>
              insertElem(props.availableVideoList.hq_thumb, props.availableVideoList.url, props.availableVideoList.id)
            }
            onMouseDown={e =>
              props.initDragDropWidget(
                {
                  asset: props.assetType,
                  value: props.availableVideoList.hq_thumb,
                  source: YOUTUBE,
                  url: props.availableVideoList.url,
                  key: props.availableVideoList.id,
                },
                e
              )
            }>
            {!isAssetLoad && <span className={style["loader-item"]}></span>}

            <img
              src={props.availableVideoList.hq_thumb}
              alt={props.availableVideoList.channel_title}
              onLoad={() => setIsAssetLoad(true)}
              className={cx({ [style["d-none"]]: !isAssetLoad })}
            />

            {isAssetLoad && (
              <>
                <span className={style["video-title"]} title={props.availableVideoList.title}>
                  {props.availableVideoList.title}
                </span>
                <span className={style["video-channel-name"]} title={props.availableVideoList.channel_title}>
                  {props.availableVideoList.channel_title}
                </span>
              </>
            )}
          </div>
        </li>
      )}
      {props.length !== props.index + 1 && (
        <li className={cx(style["col"], style["skeleton-loader-area"])}>
          <div
            className={cx(style["asset-item"], style["flex-column"], style["main-heading"])}
            data-idx={props.index}
            onClick={() =>
              insertElem(props.availableVideoList.hq_thumb, props.availableVideoList.url, props.availableVideoList.id)
            }
            onMouseDown={e =>
              props.initDragDropWidget(
                {
                  asset: props.assetType,
                  value: props.availableVideoList.hq_thumb,
                  source: YOUTUBE,
                  url: props.availableVideoList.url,
                  key: props.availableVideoList.id,
                },
                e
              )
            }>
            {!isAssetLoad && <span className={style["loader-item"]}></span>}

            <img
              src={props.availableVideoList.hq_thumb}
              alt={props.availableVideoList.channel_title}
              onLoad={() => setIsAssetLoad(true)}
              className={cx({ [style["d-none"]]: !isAssetLoad })}
            />

            {isAssetLoad && (
              <>
                <span className={style["video-title"]} title={props.availableVideoList.title}>
                  {props.availableVideoList.title}
                </span>
                <span className={style["video-channel-name"]} title={props.availableVideoList.channel_title}>
                  {props.availableVideoList.channel_title}
                </span>
              </>
            )}
          </div>
        </li>
      )}
    </React.Fragment>
  );
};
//Props type validation
YoutubeVideoList.propTypes = {
  availableVideoList: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  lastYouTubeVideoElement: PropTypes.func.isRequired,
  assetType: PropTypes.string.isRequired,
  initDragDropWidget: PropTypes.func.isRequired,
};

const VideoWrapper = props => {
  const dispatch = useDispatch();
  const observer = useRef();
  const videoListRef = useRef();

  const [playVideo, setPlayVideo] = useState({});

  const availableVideoLists = useSelector(state => state?.editor?.availableVideos);
  const nextPageToken = useSelector(state => state?.editor?.pageToken);
  const videoLoading = useSelector(state => state?.editor?.loading);
  const hasMoreVideos = useSelector(state => state?.editor?.hasMoreVideos);

  const { start: initDragDropWidget } = useDragDropWidget();

  //Observe the last node for pagination and update page number on scroll for Stock Videos
  const lastStockVideoElement = useCallback(
    node => {
      if (videoLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMoreVideos) {
          props.setStockVideoPageNumber(props.stockVideoPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [videoLoading, hasMoreVideos]
  );

  //Observe the last node for pagination and update page number on scroll for YoutubeVideos
  const lastYouTubeVideoElement = useCallback(
    node => {
      if (videoLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMoreVideos) {
          props.setYouTubeVideoPageNumber(props.youTubeVideoPageNumber + 1);
          props.setYouTubePageToken(nextPageToken);
        }
      });

      if (node) observer.current.observe(node);
    },
    [videoLoading, hasMoreVideos]
  );

  // Fetch videos api(pagination, keword, scheme type change)
  useEffect(() => {
    let payLoad = {
      limit: 25,
      view_video: props.schemeType === "Stock Videos" ? "Stock" : props.schemeType,
      video_id: false,
      pageNumber: props.schemeType === "Stock Videos" ? props.stockVideoPageNumber : props.youTubeVideoPageNumber,
    };
    if (props.keyword) payLoad.keyword = props.keyword;
    if (props.youTubePageToken) payLoad.page_token = props.youTubePageToken;

    if (props.stockVideoPageNumber === 1 || props.youTubeVideoPageNumber === 1) {
      if (videoListRef.current) videoListRef.current.scrollTop = 0;
      stopAllResourceBuffering(videoListRef.current, "img");
    }

    dispatch(fetchVideos(payLoad));
  }, [props.keyword, props.schemeType, props.stockVideoPageNumber, props.youTubePageToken]);

  useEffect(() => {
    return () => stopAllResourceBuffering(videoListRef.current, "img");
  }, []);

  return (
    <div
      className={cx(style["customScroll"], style["scroll-Y"], style["assets-wrapper"])}
      onContextMenu={e => e.preventDefault()}
      ref={videoListRef}>
      <ul className={cx(style["row"], style["row-cols-1"])}>
        {videoLoading && (props.stockVideoPageNumber === 1 || props.youTubeVideoPageNumber === 1) && (
          <AssetsLoader count={20} />
        )}

        {availableVideoLists?.length > 0 &&
          availableVideoLists?.map((availableVideoList, index) => (
            <React.Fragment key={index}>
              {props.schemeType === "Stock Videos" && (
                <StockVideoList
                  availableVideoList={availableVideoList}
                  index={index}
                  length={availableVideoLists.length}
                  lastStockVideoElement={lastStockVideoElement}
                  playVideo={playVideo}
                  setPlayVideo={setPlayVideo}
                  assetType={props.assetType}
                  initDragDropWidget={initDragDropWidget}
                />
              )}
              {props.schemeType === "YouTube" && (
                <YoutubeVideoList
                  availableVideoList={availableVideoList}
                  index={index}
                  length={availableVideoLists.length}
                  lastYouTubeVideoElement={lastYouTubeVideoElement}
                  assetType={props.assetType}
                  initDragDropWidget={initDragDropWidget}
                />
              )}
            </React.Fragment>
          ))}

        {!videoLoading && availableVideoLists?.length === 0 && (
          <li className={cx(style["col"])}>
            <div className={cx(style["alert"], style["alert-block"], style["alert-danger"])}>
              No matching videos found
            </div>
          </li>
        )}
      </ul>
      {videoLoading && (props.stockVideoPageNumber > 1 || props.youTubeVideoPageNumber > 1) && <DotLoader />}
    </div>
  );
};
//Props type validation
VideoWrapper.propTypes = {
  keyword: PropTypes.string.isRequired,
  schemeType: PropTypes.string.isRequired,
  stockVideoPageNumber: PropTypes.number,
  setStockVideoPageNumber: PropTypes.func,
  youTubePageToken: PropTypes.string,
  setYouTubePageToken: PropTypes.func,
  youTubeVideoPageNumber: PropTypes.number,
  setYouTubeVideoPageNumber: PropTypes.func,
  assetType: PropTypes.string.isRequired,
};

const StockVideo = props => {
  const [stockKeyword, setStockKeyword] = useState("");
  const [stockVideoPageNumber, setStockVideoPageNumber] = useState(1);

  return (
    <TabPane tabId="1" className={style["active"]}>
      <AssetSearch
        assetName={props.assetName}
        setKeyword={setStockKeyword}
        setPageNumber={setStockVideoPageNumber}
        schemeType={props.schemeType}
      />
      <VideoWrapper
        keyword={stockKeyword}
        schemeType={props.schemeType}
        stockVideoPageNumber={stockVideoPageNumber}
        setStockVideoPageNumber={setStockVideoPageNumber}
        assetType={props.assetType}
      />
    </TabPane>
  );
};
//Props type validation
StockVideo.propTypes = {
  assetName: PropTypes.string.isRequired,
  schemeType: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
};

const YoutubeVideo = props => {
  const [youTubeKeyword, setYouTubeKeyword] = useState("");
  const [youTubePageToken, setYouTubePageToken] = useState("");
  const [youTubeVideoPageNumber, setYouTubeVideoPageNumber] = useState(1);

  return (
    <TabPane tabId="2" className={style["active"]}>
      <AssetSearch
        assetName={props.assetName}
        setKeyword={setYouTubeKeyword}
        setPageNumber={setYouTubeVideoPageNumber}
        setYouTubePageToken={setYouTubePageToken}
      />
      <VideoWrapper
        keyword={youTubeKeyword}
        schemeType={props.schemeType}
        youTubePageToken={youTubePageToken}
        setYouTubePageToken={setYouTubePageToken}
        youTubeVideoPageNumber={youTubeVideoPageNumber}
        setYouTubeVideoPageNumber={setYouTubeVideoPageNumber}
        assetType={props.assetType}
      />
    </TabPane>
  );
};
//Props type validation
YoutubeVideo.propTypes = {
  assetName: PropTypes.string.isRequired,
  schemeType: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
};

const Video = props => {
  const videoSchemes = ["Stock Videos", "YouTube"];
  const [schemeType, setSchemeType] = useState("Stock Videos");

  return (
    <div className={cx(style["editor-asset-inner"], style["video-asset"])}>
      <AssetName handleWidgetAction={props.handleWidgetAction} assetName={props.assetName} />
      <div className={style["pr-20"]}>
        <AssetSchemeTypeNav schemeTypeArray={videoSchemes} setSchemeType={setSchemeType} />
      </div>
      <TabContent>
        {schemeType === "Stock Videos" && (
          <StockVideo assetName={props.assetName} schemeType={schemeType} assetType={props.assetType} />
        )}
        {schemeType === "YouTube" && (
          <YoutubeVideo assetName={props.assetName} schemeType={schemeType} assetType={props.assetType} />
        )}
      </TabContent>
    </div>
  );
};
//Props type validation
Video.propTypes = {
  handleWidgetAction: PropTypes.func.isRequired,
  assetName: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
};

export default Video;
