import React, { useContext, useEffect, useState } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import cx from "classnames";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import SliderControll from "../SliderControll";
import useCollage from "../../../hooks/useCollage";
import { COLLAGE } from "../../../constants/editor";

let style = Object.assign({}, global);

const GridSpacing = () => {
  const { metadata } = useContext(EditorContext);
  const { fetchGridGap, applyGridGap } = useCollage();

  const [gridSpacing, setGridSpacing] = useState();
  const [gridSpacingInputValue, setGridSpacingInputValue] = useState();
  const [updateContext, setUpdateContext] = useState(false);

  const widgetId = metadata.activeWidgetId[0];
  const widgetType = metadata.activeWidgetType[0];

  //Load current formatting | grid spacing
  useEffect(() => {
    if (widgetId && widgetType === COLLAGE) {
      const { gridGap } = fetchGridGap({ widgetId });
      setGridSpacing(gridGap);
      setGridSpacingInputValue(gridGap);
    }
  }, [widgetId, widgetType]);

  //Update widget | grid spacing
  useEffect(() => {
    if (gridSpacing) {
      if (gridSpacing > 25) applyGridGap({ widgetId, gridSpacing: 25, finalUpdate: true });
      else applyGridGap({ widgetId, gridSpacing, finalUpdate: updateContext });
    }
  }, [gridSpacing, updateContext]);

  return (
    <li className={style["toolset-group"]}>
      <UncontrolledDropdown className={cx(style["toolset-item"], style["grid-spacing-wrap"])}>
        <DropdownToggle tag="span" className={cx(style["toolset-action"], style["custom-tooltip"])}>
          <Icon icon="ui-collage" />

          <div className={cx(style["custom-tooltip-content"], style["top"])}>Grid Spacing</div>
        </DropdownToggle>

        <DropdownMenu className={cx(style["shadow"], style["border-0"], style["rounded"])}>
          <SliderControll
            Slider={gridSpacing}
            setSlider={setGridSpacing}
            SliderInputValue={gridSpacingInputValue}
            setSliderInputValue={setGridSpacingInputValue}
            sliderMax={25}
            sliderMin={5}
            setUpdateContext={setUpdateContext}
          />
        </DropdownMenu>
      </UncontrolledDropdown>
    </li>
  );
};

export default GridSpacing;
