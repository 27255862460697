import React, { useContext, useEffect, useState } from 'react'
import cx from "classnames";
import PropTypes from "prop-types";
import AssetName from "./Common/AssetName";
import { Input } from '../../ui/input';
import { TEMPLATE_TYPE_PRESENTATION } from '../../../constants/pageSizeModal';
import { useDispatch, useSelector } from 'react-redux';
import { EditorContext } from '../../../containers/editor/EditorLayout';
import { Button, Spinner } from 'reactstrap';
import { fetchAiTranslateLanguages, fetchAiTranslateUsage } from '../../../store/actions/aiActions';
import { Icon } from "../../ui/icon";
import useAiTranslate from '../../../hooks/useAiTranslate';
import global from "../../../scss/dhp.scss";

let style = Object.assign({}, global);

const AiTranslate = props => {
    let { pageNodes, metadata, setIsTimeLineViewOpen } = useContext(EditorContext);
    const dispatch = useDispatch();
    const documentDetails = useSelector(state => state.document.documentDetails);
    const { used: totalCredits, languages: { data: languages } = {} } = useSelector(state => state.ai) || {};
    const [selectedPages, setSelectedPages] = useState([1]);
    const [destinationLanguage, setDestinationLanguage] = useState(false);
    const [defaultValue, setDefaultValue] = useState({ name: "Select Language" });
    const [disable, setDisable] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);

    const type = documentDetails?.data?.template_info?.type?.toLowerCase();
    const pages = pageNodes?.map((page, index) => {
        return { id: index + 1, page }
    })
    const multiSelectPlaceholder = `${type === TEMPLATE_TYPE_PRESENTATION ? "Slide" : "Page"} ${metadata.activePageIdx + 1}`;
    const multiSelectLabel = type === TEMPLATE_TYPE_PRESENTATION ? "Slide" : "Page";
    const selectedPageIds = pageNodes.filter(page => selectedPages?.includes(page.pageIdx + 1)).map(page => page.pageId);

    const handleTranslate =  useAiTranslate({
        selectedPageIds,
        destinationLanguage,
        setDestinationLanguage,
        setDefaultValue,
        setDisable,
        setIsProcessing,
        setErrorMessage,
        setSuccessMessage
    });

    useEffect(() => {
        if (destinationLanguage && selectedPages?.length > 0) setDisable(false)
        else setDisable(true)
    }, [destinationLanguage, selectedPages])

    useEffect(() => {
        setTimeout(() => {
            if (errorMessage) setErrorMessage(false)
            if (successMessage) setSuccessMessage(false)
        }, 5000)
    }, [errorMessage, successMessage])

    useEffect(() => {
        setDefaultValue({ name: "Select Language" })
        dispatch(fetchAiTranslateUsage());
        if (!languages) dispatch(fetchAiTranslateLanguages());
    }, [])

    return (
      <div className={cx(style["editor-asset-inner"], style["ai-translate-asset"])}>
        <AssetName handleWidgetAction={props.handleWidgetAction} assetName={props.assetName} />
        {pages?.length > 1 && (
          <div className={cx(style["mr-3"], style["mb-0"], style["pr-1"])}>
            {pages?.length !== 1 && <h6 className={cx(style["fw-7"])}>{multiSelectLabel}</h6>}
            <Input
              cssModule={style}
              type="multi_select_page"
              placeholder={multiSelectPlaceholder}
              origin="ai-translate"
              options={pages}
              optionValue={"id"}
              optionImage={"image"}
              updateState={e => setSelectedPages(e)}
              maxLimit={pages?.length + 1}
              isContextData={!!pageNodes}
            />
          </div>
        )}

        <div className={cx(style["mr-3"], style["mb-0"], style["pr-1"])}>
          <Input
            type="dropdown"
            name="language"
            label="Translate To"
            defaultValue={defaultValue}
            getOptionValue={option => option.name}
            getOptionLabel={option => <span>{option.name}</span>}
            options={languages}
            updateState={option => setDestinationLanguage(option.name)}
            noOptionsMessage={() => "No results found"}
          />
        </div>

        {errorMessage && (
          <div className={cx(style["mr-3"], style["pr-1"])}>
            <div
              className={cx(
                style["alert"],
                style["alert-block"],
                style["alert-danger"],
                style["py-2"],
                style["text-left"]
              )}>
              {errorMessage}
            </div>
          </div>
        )}

        {successMessage && (
          <div
            className={cx(
              style["success-message"],
              style["mr-3"],
              style["pr-1"],
              style["d-flex"],
              style["align-items-center"]
            )}>
            <Icon icon="tick-success" additionalclass="mr-2 color-success" />
            {successMessage}
          </div>
        )}

        <div className={cx(style["mt-auto"], style["fixed-bottom"])}>
          <div
            className={cx(
              style["pb-2"],
              style["d-flex"],
              style["align-items-center"],
              style["justify-content-end"],
              style["usage-info"]
            )}>
            {`Usage: ${totalCredits ?? 0}/250`} <Icon icon="information" additionalclass="ml-2 font-base" />
            <div className={cx(style["usage-info-content"], style["rounded"], style["shadow-sm"])}>
              250 pages per month
            </div>
          </div>
          <div className={style["d-flex"]}>
            <Button
              color="primary"
              type="button"
              onClick={() => {
                setIsTimeLineViewOpen(false), props.setFitToScreen(true), handleTranslate();
              }}
              disabled={disable || isProcessing}
              className={style["btn-block"]}>
              Translate{" "}
              {isProcessing && (
                <Spinner
                  size="sm"
                  color="dark"
                  className={style["m-auto"]}
                  style={{ position: "absolute", right: "15%" }}
                />
              )}
            </Button>
          </div>
        </div>
      </div>
    );
}

AiTranslate.propTypes = {
    handleWidgetAction: PropTypes.func.isRequired,
    assetName: PropTypes.string.isRequired,
    setFitToScreen: PropTypes.func,
};

export default AiTranslate