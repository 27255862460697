import React, { useContext } from "react";
import cx from "classnames";
import { DropdownMenu } from "reactstrap";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { contextualConfig } from "../editor_config";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import useFontList from "../../../hooks/useFontList";

let style = Object.assign({}, global);

const ListAction = () => {
  let { metadata } = useContext(EditorContext);

  const setListFontWidget = useFontList(-1);

  return (
    <DropdownMenu
      tag="ul"
      className={cx(style["border-0"], style["toolbar-dropdown"], style["two-item"], style["rounded"])}>
      {contextualConfig.List.map((list, index) => (
        <React.Fragment key={index}>
          <li
            className={cx(style["custom-tooltip"], {
              [style["active"]]:
                document.getElementById(metadata.activeWidgetId[0])?.getAttribute("data-subwidget") &&
                list.tag ===
                  document.getElementById(metadata.activeWidgetId[0])?.getAttribute("data-subwidget").toLowerCase(),
            })}>
            <span className={style["toolset-action"]} onClick={() => setListFontWidget(index)}>
              <Icon icon={list.name === "Bullets" ? "ui-list" : "ui-numbered-list"} />
            </span>

            <div className={style["custom-tooltip-content"]}>{list.name}</div>
          </li>
        </React.Fragment>
      ))}
    </DropdownMenu>
  );
};

export default ListAction;
