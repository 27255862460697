import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Button, DropdownItem, Spinner } from "reactstrap";
import cx from "classnames";
import { Input } from "../ui/input";
import { useParams } from "react-router-dom";
import Modal from "../ui/modal";
import ThirdPartyUploadModal from "../Document/modal/thirdPartyUploadModal";
import { useSocket } from "../../hooks/useSocket";
import { Icon } from "../ui/icon";
import { TEMPLATE_TYPE_PRESENTATION } from "../../constants/pageSizeModal";
import {
  THIRDPARTY_TOKEN_STATUS,
  THIRDPARTY_UPLOAD_FAILED,
  THIRDPARTY_UPLOAD_PROGRESS,
  THIRDPARTY_UPLOAD_START,
  THIRDPARTY_UPLOAD_SUCCESS,
} from "../../constants/socket";

import global from "../../scss/dhp.scss";
import { EditorContext } from "../../containers/editor/EditorLayout";

let style = Object.assign({}, global);
const socketEndpoint = `${process.env.SOCKET_URL}`;

// uploading design into mailchimp
const SaveToMailchimp = ({ backAction, setShowUploadModal, uploadToMailchimp, setDropdownOpen }) => {
  const { id } = useParams();
  let { pageNodes } = useContext(EditorContext);
  const {
    email: userEmail,
    company: { id: companyId },
  } = useSelector(state => state.auth?.user);
  const { users: allUsers } = useSelector(state => state.company);

  const userId = allUsers?.find(user => user?.email === userEmail)?.id;

  const {
    documentDetails,
    documentDetails: {
      data: { template_info : {type : type}},
    },
  } = useSelector(state => state?.document);

  const pages = pageNodes?.map((page, index) => {
    return { id: index + 1, page };
  });

  const [selectedPages, setSelectedPages] = useState([1]);
  const [btnDisable, setBtnDisable] = useState(false);

  const multiSelectPlaceholder = `Select ${type.toLowerCase() === TEMPLATE_TYPE_PRESENTATION ? "Slides" : "Pages"}`;

  const uploadMailchimp = () => {
    setShowUploadModal(true);
    setDropdownOpen(true);
    setBtnDisable(true);
    const subscribeAtion = {
      type: THIRDPARTY_UPLOAD_START,
      componentEntryId: id,
      companyId,
      selectedPages,
      userId,
      fileName: documentDetails?.data?.document_name,
      app: "Mailchimp"
    };
    uploadToMailchimp(subscribeAtion);
  };

  return (
    <React.Fragment>
      <DropdownItem toggle={false} tag="a" onClick={backAction} className={style["pl-2"]}>
        <Icon icon="ui-arrow-left" />
        <span className={cx(style["fw-7"], style["ml-2"])}>Mailchimp</span>
      </DropdownItem>

      <div className={cx(style["px-3"], style["font-15"], style["mb-4"], style["mt-2"])}>Save designs to Mailchimp</div>
      {pages.length > 1 && (
        <div className={style["px-3"]}>
          <Input
            cssModule={style}
            returnType="formGroup"
            type="multi_select_page"
            placeholder={multiSelectPlaceholder}
            options={pages}
            optionValue={"id"}
            optionImage={"image"}
            updateState={e => setSelectedPages(e)}
            maxLimit={10}
            isContextData={true}
          />
        </div>
      )}
      <div className={style["px-3"]}>
        <Button
          color="primary"
          className={cx(style["btn-block"], style["my-2"])}
          disabled={selectedPages.length < 1 || btnDisable}
          onClick={uploadMailchimp}>
          Save
        </Button>
      </div>
    </React.Fragment>
  );
};

const PublishToMailchimp = ({ dropdownOpen, setDropdownOpen, setOpenMailchimp }) => {
  const webSocket = useSocket();
  const { loading: isLoading } = useSelector(state => state?.auth);
  const [showAlert, setShowAlert] = useState(false);
  const userInfo = useSelector(state => state.auth?.user);

  const userEmail = userInfo?.email;
  const companyId = userInfo?.company?.id;
  const { users: allUsers } = useSelector(state => state.company);
  const [showMailchimp, setShowMailchimp] = useState();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [maichimpFileLink, setMaichimpFileLink] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const userId = allUsers?.find(user => user?.email === userEmail)?.id;

  let mailchimpOauthWindow = useRef(null);

  const authernticateMailchimp = () => {
    setShowAlert(false);
    if (mailchimpOauthWindow.current && !mailchimpOauthWindow.current.closed) mailchimpOauthWindow.current.focus();
    else {
      let params = `width=500,height=700,left=400,top=150`;
      /*Added this for to open a browser popup window */
      mailchimpOauthWindow.current = window.open(
        `${socketEndpoint}/auth/mailchimp/${companyId}/${userId}/`,
        "mailchimpOauthWindow",
        params
      );
      let timer = setInterval(() => {
        if (mailchimpOauthWindow.current.closed) {
          clearInterval(timer);
          if (dropdownOpen) setShowAlert(true);
        }
      }, 1000);
    }
  };

  const checkMailchimpTokenStatus = () => {
    setShowAlert(false);
    setShowLoader(true);
    if (webSocket?.readyState === 1) {
      const subscribe = { type: THIRDPARTY_TOKEN_STATUS, companyId, userId, app: "Mailchimp" };
      webSocket.send(JSON.stringify(subscribe));
    }
  };

  const closeMailchimpOauthWindow = () => {
    if (mailchimpOauthWindow.current) mailchimpOauthWindow.current.close();
  };

  const backAction = () => {
    setShowMailchimp();
    setShowLoader(false);
    setOpenMailchimp(false);
  };

  const uploadToMailchimp = subscribe => {
    if (webSocket?.readyState === 1)
      webSocket.send(JSON.stringify(subscribe));
  };

  const onMessage = useCallback(message => {
    const response = JSON.parse(message?.data);
    if (response.userId === userId && response.companyId === companyId && response.app === "Mailchimp") {
      if (response.status !== 200 && response.type === THIRDPARTY_TOKEN_STATUS) {
        setOpenMailchimp(true);
        setShowMailchimp("Connect to Mailchimp");
        setShowUploadModal(false);
      }

      if (response.status === 200 && response.type === THIRDPARTY_TOKEN_STATUS) {
        setOpenMailchimp(true);
        closeMailchimpOauthWindow();
        setShowMailchimp("Save to Mailchimp");
      }

      if (response.type === THIRDPARTY_UPLOAD_FAILED) setUploadError(!uploadError);

      if (response?.progress && response.type === THIRDPARTY_UPLOAD_PROGRESS) setUploadProgress(response?.progress);

      if (response?.mailchimpMyFilesUrl && response.type === THIRDPARTY_UPLOAD_SUCCESS)
        setTimeout(() => {
          setMaichimpFileLink(response?.mailchimpMyFilesUrl);
        }, 1000);
    }
  }, []);

  useEffect(() => {
    if (webSocket?.readyState === 1) {
      webSocket.addEventListener("message", onMessage);

      return () => {
        webSocket.removeEventListener("message", onMessage);
      };
    }
  }, [webSocket, onMessage]);

  useEffect(() => {
    if (!showUploadModal && maichimpFileLink) {
      setDropdownOpen(false);
    }
  }, [showUploadModal]);

  useEffect(() => {
    return () => {
      setOpenMailchimp(false);
    };
  }, []);

  return (
    <React.Fragment>
      {!showMailchimp && (
        <DropdownItem tag="a" toggle={false} onClick={checkMailchimpTokenStatus}>
          <Icon icon="ui-mailchimp" additionalclass="mr-2" /> <span>Mailchimp</span>
          {showLoader && <Spinner size="sm" color="dark" className={style["ml-2"]} />}
        </DropdownItem>
      )}
      {showMailchimp === "Connect to Mailchimp" && (
        <React.Fragment>
          <DropdownItem toggle={false} tag="a" onClick={backAction} className={style["pl-2"]}>
            <Icon icon="ui-arrow-left" />
            <span className={cx(style["fw-7"], style["ml-2"])}>Mailchimp</span>
          </DropdownItem>

          <div className={style["px-3"]}>
            <div className={cx(style["mailchimp-cont"], style["text-center"], style["mb-3"])}>
              <div className={cx(style["text-left"], style["mailchimp-txt"], style["mb-3"])}>
                Save designs to Mailchimp
              </div>{" "}
              <Icon icon="ui-mailchimp2" />
            </div>
            {showAlert && (
              <div className={style["mc-status-msg"]}>
                You have not given enough permissions to publish. Please try again
              </div>
            )}
          </div>

          <div className={style["px-3"]}>
            <Button
              type="submit"
              color="primary"
              className={cx(style["btn-block"], style["btn-loader"], style["my-2"])}
              disabled={isLoading}
              onClick={authernticateMailchimp}>
              Connect <Icon icon="arrow-right" additionalclass="ml-2" />
            </Button>
          </div>
        </React.Fragment>
      )}
      {showMailchimp === "Save to Mailchimp" && (
        <SaveToMailchimp
          setShowUploadModal={setShowUploadModal}
          uploadToMailchimp={uploadToMailchimp}
          backAction={backAction}
          setDropdownOpen={setDropdownOpen}
        />
      )}
      {showUploadModal && (
        <Modal
          showModal={showUploadModal}
          setShowModal={setShowUploadModal}
          component={ThirdPartyUploadModal}
          progressbarInfo={uploadProgress}
          fileLink={maichimpFileLink}
          modalClassName={"download-modal"}
          uploadError={uploadError}
          backdrop="static"
        />
      )}
    </React.Fragment>
  );
};

SaveToMailchimp.propTypes = {
  backAction: PropTypes.func.isRequired,
  setShowUploadModal: PropTypes.func.isRequired,
  uploadToMailchimp: PropTypes.func.isRequired,
  setDropdownOpen: PropTypes.func.isRequired,
};

PublishToMailchimp.propTypes = {
  dropdownOpen: PropTypes.bool.isRequired,
  setDropdownOpen: PropTypes.func.isRequired,
  setOpenMailchimp: PropTypes.func.isRequired,
};

export default PublishToMailchimp;
