import { useContext } from "react";
import { useSelector } from "react-redux";
import { EditorContext } from "../containers/editor/EditorLayout";
import useClipboard from "./useClipboard";
import { TYPE_INFOGRAPHIC } from "../constants/editor";

const useCutWidget = () => {
  let {
    metadata,
    widgets,
    keyBoardEvent,
    updateMetadata,
    updateWidgets,
    updateKeyBoardEvent,
    updateClipboardObj,
    dimension,
    documentType,
    blockNodes,
  } = useContext(EditorContext);

  const { write: writeToClipboard } = useClipboard();
  const companyInfo = useSelector(state => state?.auth?.company);
  const documentDetails = useSelector(state => state?.document?.documentDetails?.data);

  const cutWidget = async () => {
    if (!metadata.activeWidgetId) return;

    let targetWidgetIdArary = [];
    let targetWidgetIndexes = [];
    let cutWidgetsArray = [];

    // Get target widget Index and Id
    for (let i = 0; i < metadata.activeWidgetId.length; i++) {
      let id = metadata.activeWidgetId[i];
      let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
      let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
      targetWidgetIdArary.push(targetId);
      targetWidgetIndexes.push(widgets.findIndex(widget => widget.id === targetId));
    }

    // Copy targetindex Widget
    targetWidgetIndexes.sort().map(targetWidgetIndex => {
      let cloneObj = widgets[targetWidgetIndex];
      cutWidgetsArray.push(cloneObj);
    });

    // create obj to store in local storage
    let targetObj = {
      companyId: companyInfo?._id,
      documentId: documentDetails.id,
      docDimension: dimension,
      widgetObj: cutWidgetsArray,
      clipboardAction: "cut",
    };

    if (documentType === TYPE_INFOGRAPHIC)
      targetObj = { ...targetObj, blockHeight: blockNodes[metadata.activeBlockIdx].style.height };

    await writeToClipboard(targetObj);

    //Cut widget from editor
    let newWidget = widgets.filter(widget => !targetWidgetIdArary.includes(widget.id));

    //Update Context values
    updateWidgets(newWidget);
    updateMetadata({ ...metadata, activeWidgetId: false, activeWidgetType: false });
    updateClipboardObj(true);
    updateKeyBoardEvent({ ...keyBoardEvent, cut: false });
  };

  return { cutWidget };
};

export default useCutWidget;
