import React, { useContext, useEffect, useState } from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import cx from "classnames";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import SliderControll from "../SliderControll";
import {
  BLUR,
  BRIGHTNESS,
  COLLAGE,
  CONTRAST,
  GRAYSCALE,
  HUE,
  INVERT,
  LABELBLUR,
  LABELBRIGHTNESS,
  LABELCONTRAST,
  LABELGRAYSCALE,
  LABELHUE,
  LABELINVERT,
  LABELSATURATE,
  LABELSEPIA,
  SATURATE,
  SEPIA,
  VIDEO,
} from "../../../constants/editor";

import global from "../../../scss/dhp.scss";

let style = Object.assign({}, global);

const ImageFilterModal = ({ ...props }) => {
  const availableColorFilters = [
    {
      name: "Normal",
      slug: "normal",
      value: "brightness(100%) contrast(100%)",
    },
    {
      name: "Grayscale",
      slug: "grayscale",
      value: "grayscale(1)",
    },
    {
      name: "Lark",
      slug: "lark",
      value: "contrast(90%)",
    },
    {
      name: "Clarendon",
      slug: "clarendon",
      value: "contrast(120%) saturate(1.35)",
    },
    {
      name: "Gingham",
      slug: "gingham",
      value: "brightness(105%) hue-rotate(-10deg)",
    },
    {
      name: "Brooklyn",
      slug: "brooklyn",
      value: "contrast(90%) brightness(110%)",
    },
    {
      name: "Earlybird",
      slug: "earlybird",
      value: "contrast(90%) sepia(20%)",
    },
    {
      name: "Inkwell",
      slug: "inkwell",
      value: "sepia(30%) contrast(110%) brightness(110%) grayscale(1)",
    },
    {
      name: "Lo-Fi",
      slug: "lofi",
      value: "saturate(1.1) contrast(150%)",
    },
    {
      name: "Mayfair",
      slug: "mayfair",
      value: "contrast(110%) saturate(1.1)",
    },
    {
      name: "Nashville",
      slug: "nashville",
      value: "sepia(20%) contrast(120%) brightness(105%) saturate(1.2)",
    },
    {
      name: "Slumber",
      slug: "slumber",
      value: "saturate(.66) brightness(105%)",
    },
    {
      name: "Valencia",
      slug: "valencia",
      value: "contrast(108%) brightness(108%) sepia(8%)",
    },
    {
      name: "Walden",
      slug: "walden",
      value: "brightness(110%) hue-rotate(-10deg) sepia(30%) saturate(1.6)",
    },
    {
      name: "X-pro II",
      slug: "xpro2",
      value: "sepia(30%)",
    },
  ];

  const initialFilterValues = {
    filterType: "",
    values: [
      {
        type: "grayscale",
        value: 0,
        unit: "",
      },
      { type: "blur", value: 0, unit: "px" },
      {
        type: "invert",
        value: 0,
        unit: "%",
      },
      {
        type: "sepia",
        value: 0,
        unit: "%",
      },
      {
        type: "saturate",
        value: 1,
        unit: "",
      },
      { type: "hue", value: 0, unit: "deg" },
      { type: "brightness", value: 100, unit: "%" },
      { type: "contrast", value: 100, unit: "%" },
    ],
  };

  const [showAdvanceOptions, setShowAdvanceOptions] = useState(false);
  const [brightness, setBrightness] = useState(initialFilterValues.values.find(fv => fv.type === BRIGHTNESS).value);
  const [contrast, setContrast] = useState(initialFilterValues.values.find(fv => fv.type === CONTRAST).value);
  const [hue, setHue] = useState(initialFilterValues.values.find(fv => fv.type === HUE).value);
  const [saturate, setSaturate] = useState(initialFilterValues.values.find(fv => fv.type === SATURATE).value);
  const [grayscale, setGrayscale] = useState(initialFilterValues.values.find(fv => fv.type === GRAYSCALE).value);
  const [blur, setBlur] = useState(initialFilterValues.values.find(fv => fv.type === BLUR).value);
  const [invert, setInvert] = useState(initialFilterValues.values.find(fv => fv.type === INVERT).value);
  const [sepia, setSepia] = useState(initialFilterValues.values.find(fv => fv.type === SEPIA).value);
  const [state, setState] = useState(false);
  const [filterState, setFilterState] = useState(initialFilterValues);
  const [filterApplied, setFilterApplied] = useState(false);
  let {
    metadata,
    widgets,
    updateWidgets,
    rightContextMenu,
    updateRightContextMenu,
    togglePlayButton,
    updateTogglePlayButton,
  } = useContext(EditorContext);

  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);

  const selectFilterType = (type, value) => {
    setState({ ...state, type });
    parseFilterUpdateFilterState(type, value);
  };

  const applyEffect = (filterType, value) => {
    if (!filterApplied && (state?.url || state?.dom)) {
      let initialFilters = filterState;
      initialFilters.values.map(f => {
        if (f.type === filterType) f.value = parseFloat(value);
      });
      setFilterState({ ...filterState, values: initialFilters.values });
    }
  };

  const parseFilterUpdateFilterState = (filterType, style) => {
    let filteparams = style.split(" ");
    let currentFilterState = initialFilterValues;
    filteparams.forEach(filteparam => {
      let getFilterNames = filteparam.split("(");
      let getFilterName = getFilterNames[0] === "hue-rotate" ? HUE : getFilterNames[0];
      let filterParamValue = parseFloat(getFilterNames[1]);
      currentFilterState.values.map(f => {
        if (f.type === getFilterName) f.value = filterParamValue;
      });
    });
    setFilterState({ filterType, values: currentFilterState.values });
  };

  const parseAppliedFilterUpdateFilterState = (filterType, style) => {
    let filteparams = style.split(" ");
    let appliedFilterState = initialFilterValues;
    filteparams.forEach(filteparam => {
      let getFilterNames = filteparam.split("(");
      let getFilterName = getFilterNames[0] === "hue-rotate" ? HUE : getFilterNames[0];
      let filterParamValue = [BRIGHTNESS, CONTRAST, SEPIA, INVERT].includes(getFilterName)
        ? (parseFloat(getFilterNames[1]) * 100).toFixed(0)
        : parseFloat(getFilterNames[1]);
      appliedFilterState.values.map(f => {
        if (f.type === getFilterName) f.value = filterParamValue;
      });
    });
    setFilterState({ filterType, values: appliedFilterState.values });
  };

  const generateFilterCSS = () => {
    let cssStr = "";
    filterState.values.forEach(fv => {
      let filterType = fv.type === HUE ? "hue-rotate" : fv.type;
      let curInitialValue = initialFilterValues.values.find(f => f.type === fv.type);
      let curAppliedValue = [BRIGHTNESS, CONTRAST, SEPIA, INVERT].includes(fv.type)
        ? parseFloat(parseFloat(fv.value) / 100).toFixed(2)
        : parseFloat(fv.value);
      cssStr += [BRIGHTNESS, CONTRAST, SEPIA, INVERT].includes(fv.type)
        ? `${filterType}(${curAppliedValue}) `
        : `${filterType}(${curAppliedValue}${curInitialValue.unit}) `;
    });
    return cssStr;
  };

  const setSliderValues = () => {
    setFilterApplied(false);
    filterState?.values?.forEach(filter => {
      switch (filter.type) {
        case BRIGHTNESS:
          setBrightness(filter.value);
          break;
        case CONTRAST:
          setContrast(filter.value);
          break;
        case HUE:
          setHue(filter.value);
          break;
        case SATURATE:
          setSaturate(filter.value);
          break;
        case GRAYSCALE:
          setGrayscale(filter.value);
          break;
        case BLUR:
          setBlur(filter.value);
          break;
        case INVERT:
          setInvert(filter.value);
          break;
        case SEPIA:
          setSepia(filter.value);
          break;
        default:
          break;
      }
    });
  };

  const applyFilter = () => {
    props.setShowModal(!props.showModal);

    // Close right context menubar if open
    if (rightContextMenu.enable)
      updateRightContextMenu({
        ...rightContextMenu,
        enable: false,
      });

    const activeWidgetType = metadata.activeWidgetType[0];
    let curDOMElement = document.getElementById(`${id}`);
    let curDataSource = curDOMElement.getAttribute("data-source");
    let curInnerWidget =
      (curDataSource === "STOCK" || curDataSource === "BRAND" || curDataSource === "UPLOADVIDEO") && activeWidgetType === VIDEO
        ? curDOMElement.getElementsByTagName("video")[0]
        : activeWidgetType === COLLAGE
          ? curDOMElement.getElementsByClassName("collage")[0]
          : curDOMElement.getElementsByTagName("img")[0];

    let dataFilterType = showAdvanceOptions ? "advanced" : "default";
    curInnerWidget.style.filter = `${state.currentValue}`;
    curInnerWidget.setAttribute("data-filter-type", dataFilterType);
    curInnerWidget.setAttribute("data-filter", state.type);

    let newArray = Object.assign([...widgets], {
      [targetWidgetIndex]: {
        ...widgets[targetWidgetIndex],
        innerHTML: document.getElementById(targetId).innerHTML,
      },
    });
    updateWidgets(newArray);
  };

  const closeFilterModal = () => {
    props.setShowModal(!props.showModal);

    // Close right context menubar if open
    if (rightContextMenu.enable)
      updateRightContextMenu({
        ...rightContextMenu,
        enable: false,
      });
  };

  const resizeDOM = (id, dia) => {
    let curDOMElement = document.getElementById(`${id}`);
    let containerDia = { height: dia.h, width: dia.w }
    let height = parseFloat(curDOMElement.style.height), width = parseFloat(curDOMElement.style.width);
    let scale = Math.min(containerDia.height / height, containerDia.width / width);
    return { height, width, scale };
  }

  useEffect(() => {
    if (metadata.activeWidgetId) {
      const activeWidgetType = metadata.activeWidgetType[0];
      if (
        activeWidgetType === VIDEO &&
        document.getElementById(id).getAttribute("data-playing") === "true"
      )
        updateTogglePlayButton(!togglePlayButton);
      let curDOMElement = document.getElementById(`${id}`);
      let curDataSource = curDOMElement.getAttribute("data-source");
      let curInnerWidget =
        (curDataSource === "STOCK" || curDataSource === "BRAND" || curDataSource === "UPLOADVIDEO") && activeWidgetType === VIDEO
          ? curDOMElement.getElementsByTagName("video")[0]
          : activeWidgetType === COLLAGE
            ? curDOMElement.getElementsByClassName("collage")[0]
            : curDOMElement.getElementsByTagName("img")[0];
      let url =
        (curDataSource === "STOCK" || curDataSource === "BRAND" || curDataSource === "UPLOADVIDEO") && activeWidgetType === VIDEO
          ? curDOMElement.getAttribute("data-poster")
          : activeWidgetType === COLLAGE
            ? curInnerWidget.querySelectorAll("img, video")[0]?.src
            : curInnerWidget.src;

      let newState = { url };
      let dataFilterType = curInnerWidget.getAttribute("data-filter-type");
      let dataFilter = curInnerWidget.getAttribute("data-filter");
      if (!dataFilterType || !["advanced", "default"].includes(dataFilterType)) {
        newState = {
          ...newState,
          type: availableColorFilters[0].slug,
        };
        parseFilterUpdateFilterState(availableColorFilters[0].slug, availableColorFilters[0].value);
      } else {
        if (dataFilterType === "advanced") setShowAdvanceOptions(true);
        newState = { ...newState, type: dataFilter, currentValue: curInnerWidget.style.filter };
        parseAppliedFilterUpdateFilterState(dataFilter, curInnerWidget.style.filter);
        setFilterApplied(true);
      }

      if (activeWidgetType === COLLAGE) {
        const smallDia = resizeDOM(id, { h: 100, w: 100 });
        const largeDia = resizeDOM(id, { h: 320, w: 230 })
        const sandBox = document.createElement("div");
        sandBox.innerHTML = curDOMElement.innerHTML;

        sandBox.getElementsByClassName("dhp-widget-inner")[0].style.filter = "none";
        sandBox.getElementsByClassName("collage")[0].style.filter = "none";
        setState({ ...state, ...newState, dom: sandBox.innerHTML, dia: { ...smallDia, scaleL: largeDia.scale } });
      }
      else {
        setState(newState);
      }
    }
  }, [props.showModal]);

  useEffect(() => {
    if (state) {
      let currentValue = generateFilterCSS();
      setState({ ...state, currentValue });
      setSliderValues();
    }
  }, [filterState]);

  useEffect(() => {
    applyEffect(BRIGHTNESS, brightness);
  }, [brightness]);

  useEffect(() => {
    applyEffect(CONTRAST, contrast);
  }, [contrast]);

  useEffect(() => {
    applyEffect(HUE, hue);
  }, [hue]);

  useEffect(() => {
    applyEffect(SATURATE, saturate);
  }, [saturate]);

  useEffect(() => {
    applyEffect(GRAYSCALE, grayscale);
  }, [grayscale]);

  useEffect(() => {
    applyEffect(BLUR, blur);
  }, [blur]);

  useEffect(() => {
    applyEffect(INVERT, invert);
  }, [invert]);

  useEffect(() => {
    applyEffect(SEPIA, sepia);
  }, [sepia]);

  useEffect(() => {
    if (!showAdvanceOptions && state?.type) {
      let filterValue = availableColorFilters?.filter(f => f?.slug === state?.type);
      selectFilterType(state?.type, filterValue[0].value);
    }
  }, [showAdvanceOptions]);

  return (
    <>
      <h4 className={cx(style["fw-7"], style["mb-4"])}>Filter</h4>
      <span className={cx(style["cross-modal"], style["rounded"])} onClick={closeFilterModal}>
        <Icon icon="ui-close" />
      </span>
      <div className={cx(style["img-filter-wrap"])}>
        <div className={cx(style["filter-thumb-wrap"], { [style["active"]]: !showAdvanceOptions })}>
          {availableColorFilters.map((filter, i) => (
            <div className={style["img-box"]} key={i}>
              {!state?.dom && <div
                className={cx(style["imgfilterlink"], { [style["active"]]: state?.type === filter.slug })}
                onClick={() => selectFilterType(filter.slug, filter.value)}
                data-filter={filter.value}>
                <img src={state?.url} className={filter.slug} style={{ filter: filter.value }} />
              </div>}
              {state?.dom && <div
                className={cx(style["imgfilterlink"], style["align-items-center"], style["d-flex"], style["justify-content-center"], { [style["active"]]: state?.type === filter.slug })}
                onClick={() => selectFilterType(filter.slug, filter.value)}
                data-filter={filter.value}>
                <div dangerouslySetInnerHTML={{ __html: state?.dom }} style={{
                  height: state.dia.height,
                  width: state.dia.width,
                  filter: filter.value,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: `translate(-50%, -50%) scale(${state.dia.scale})`
                }} className={filter.slug} ></div>
              </div>}
              <span>{filter.name}</span>
            </div>
          ))}
        </div>
        <div className={cx(style["advanced-option"], { [style["active"]]: showAdvanceOptions })} style={{ transition: state?.dom ? "none" : ".4s ease-in-out", WebkitTransition: state?.dom ? "none" : ".4s ease-in-out" }}>
          <div className={style["advanced-option-leftbg"]}>
            {!state?.dom && <div className={style["imgfilterlink"]} data-filter={state?.type}>
              <img src={state?.url} className={state?.type} style={{ filter: state?.currentValue }} />
            </div>}
            {state?.dom && <div className={cx(style["align-items-center"], style["d-flex"], style["imgfilterlink"], style["justify-content-center"])} data-filter={state?.type}>
              <div className={state?.type} style={{
                transform: `scale(${state.dia.scaleL})`,
                height: state.dia.height,
                width: state.dia.width,
                filter: state?.currentValue,
                position: "absolute"
              }} dangerouslySetInnerHTML={{ __html: state?.dom }}></div>
            </div>}
          </div>

          <div className={style["advanced-option-rightbg"]}>
            <ul className={style["otherfilter"]}>
              <li>
                <SliderControll
                  Slider={brightness.toString()}
                  setSlider={setBrightness}
                  sliderMin={50}
                  sliderMax={250}
                  step={1}
                  sliderLabel={LABELBRIGHTNESS}
                />
              </li>
              <li>
                <SliderControll
                  Slider={contrast.toString()}
                  setSlider={setContrast}
                  sliderMin={50}
                  sliderMax={250}
                  step={1}
                  sliderLabel={LABELCONTRAST}
                />
              </li>
              <li>
                <SliderControll
                  Slider={hue.toString()}
                  setSlider={setHue}
                  sliderMin={0}
                  sliderMax={250}
                  step={1}
                  sliderLabel={LABELHUE}
                />
              </li>
              <li>
                <SliderControll
                  Slider={saturate.toString()}
                  setSlider={setSaturate}
                  sliderMin={1}
                  sliderMax={5}
                  step={0.01}
                  sliderLabel={LABELSATURATE}
                />
              </li>
              <li>
                <SliderControll
                  Slider={grayscale.toString()}
                  setSlider={setGrayscale}
                  sliderMin={0}
                  sliderMax={1}
                  step={0.01}
                  sliderLabel={LABELGRAYSCALE}
                />
              </li>
              <li>
                <SliderControll
                  Slider={blur.toString()}
                  setSlider={setBlur}
                  sliderMin={0}
                  sliderMax={10}
                  step={0.01}
                  sliderLabel={LABELBLUR}
                />
              </li>
              <li>
                <SliderControll
                  Slider={invert.toString()}
                  setSlider={setInvert}
                  sliderMin={0}
                  sliderMax={100}
                  step={1}
                  sliderLabel={LABELINVERT}
                />
              </li>
              <li>
                <SliderControll
                  Slider={sepia.toString()}
                  setSlider={setSepia}
                  sliderMin={0}
                  sliderMax={100}
                  step={1}
                  sliderLabel={LABELSEPIA}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={cx(style["modal-footer"], style["justify-content-start"], style["p-0"], style["border-0"])}>
        <Button type="button" color="primary" onClick={applyFilter}>
          Apply
        </Button>
        <Button
          type="button"
          className={cx(style["btn-border"])}
          outline
          color=""
          onClick={() => setShowAdvanceOptions(!showAdvanceOptions)}>
          {!showAdvanceOptions ? "Adjust" : "Back"}
        </Button>
      </div>
    </>
  );
};

ImageFilterModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default ImageFilterModal;
