import {
  TEMPLATE_GROUP_START,
  TEMPLATE_GROUP_SUCCESS,
  TEMPLATE_GROUP_FAILED,
  TEMPLATE_PAGE_SIZE_START,
  TEMPLATE_PAGE_SIZE_FAILED,
  TEMPLATE_PAGE_SIZE_SUCCESS,
  TEMPLATE_DETAILS_FETCH_START,
  TEMPLATE_DETAILS_FETCH_SUCCESS,
  TEMPLATE_DETAILS_FETCH_FAILED,
  TEMPLATE_TYPE_START,
  TEMPLATE_TYPE_SUCCESS,
  TEMPLATE_TYPE_FAILED,
  TEMPLATE_UNPUBLISH_START,
  TEMPLATE_UNPUBLISH_SUCCESS,
  TEMPLATE_UNPUBLISH_FAILED,
  FETCH_BLANK_TEMPLATE_SIZE_START,
  FETCH_BLANK_TEMPLATE_SIZE_SUCCESS,
  FETCH_BLANK_TEMPLATE_SIZE_FAILED,
  TEMPLATE_CATEGORIES_BY_TYPE_START,
  TEMPLATE_CATEGORIES_BY_TYPE_SUCCESS,
  TEMPLATE_CATEGORIES_BY_TYPE_FAILED,
  TEMPLATE_SEARCH_START,
  TEMPLATE_SEARCH_SUCCESS,
  TEMPLATE_SEARCH_FAILED,
} from "./actionTypes";
import { templateRequestManager as API } from "../../_helpers/requestManager";

// GET templates/groups
export const fetchTemplateGroupStart = () => {
  return { type: TEMPLATE_GROUP_START };
};

export const fetchTemplateGroupSuccess = data => {
  return { type: TEMPLATE_GROUP_SUCCESS, data };
};

export const fetchTemplateGroupFailed = error => {
  return { type: TEMPLATE_GROUP_FAILED, error };
};

// GET templates/page-size
export const fetchTemplatePageSizesStart = () => {
  return { type: TEMPLATE_PAGE_SIZE_START };
};

export const fetchTemplatePageSizesFailed = error => {
  return { type: TEMPLATE_PAGE_SIZE_FAILED, error };
};

export const fetchTemplatePageSizesSuccess = data => {
  return { type: TEMPLATE_PAGE_SIZE_SUCCESS, data };
};

// Get template/details
const fetchTemplateDetailsStart = templateId => {
  return { type: TEMPLATE_DETAILS_FETCH_START, templateId };
};
const fetchTemplateDetailsSuccess = result => {
  return { type: TEMPLATE_DETAILS_FETCH_SUCCESS, result };
};
const fetchTemplateDetailsFailed = error => {
  return { type: TEMPLATE_DETAILS_FETCH_FAILED, error };
};

export const fetchTemplateTypeStart = () => {
  return { type: TEMPLATE_TYPE_START };
};

export const fetchTemplateTypeSuccess = data => {
  // Generate template groups
  let groupData = JSON.parse(JSON.stringify(data.template_types));
  let templateGroup = [];
  Object.keys(groupData)?.forEach((key, idx) => {
    let groupKeyInfo = {
      id: groupData[key][0].group_id,
      index: idx,
      name: key,
      slug: key.replace(/\s+/g, "-").toLowerCase(),
    };
    groupData[key].map(d => {
      d.id = d.type_id;
      delete d.type_id;
      delete d.group;
      delete d.group_id;
    });
    groupKeyInfo["type"] = groupData[key];
    templateGroup.push(groupKeyInfo);
  });

  return { type: TEMPLATE_TYPE_SUCCESS, data, templateGroup };
};

export const fetchTemplateTypeFailed = error => {
  return { type: TEMPLATE_TYPE_FAILED, error };
};

const unpublishTemplateStart = () => {
  return { type: TEMPLATE_UNPUBLISH_START };
};
const unpublishTemplateSuccess = result => {
  return { type: TEMPLATE_UNPUBLISH_SUCCESS, result };
};
const unpublishTemplateFailed = error => {
  return { type: TEMPLATE_UNPUBLISH_FAILED, error };
};

const fetchBlankTemplateSizeStart = () => {
  return { type: FETCH_BLANK_TEMPLATE_SIZE_START };
};
const fetchBlankTemplateSizeSuccess = result => {
  return { type: FETCH_BLANK_TEMPLATE_SIZE_SUCCESS, result };
};
const fetchBlankTemplateSizeFailed = error => {
  return { type: FETCH_BLANK_TEMPLATE_SIZE_FAILED, error };
};

//Template Search
export const fetchTemplateSearchStart = () => {
  return { type: TEMPLATE_SEARCH_START };
};

export const fetchTemplateSearchSuccess = (result, filterType) => {
  return { type: TEMPLATE_SEARCH_SUCCESS, result, filterType };
};

export const fetchTemplateSearchFailed = error => {
  return { type: TEMPLATE_SEARCH_FAILED, error };
};

export const fetchTemplateGroups = () => {
  return dispatch => {
    dispatch(fetchTemplateGroupStart());
    return API.get("/groups")
      .then(r => dispatch(fetchTemplateGroupSuccess(r.data)))
      .catch(e => dispatch(fetchTemplateGroupFailed(e)));
  };
};

export const fetchTemplatePageSizes = () => {
  return dispatch => {
    dispatch(fetchTemplatePageSizesStart());
    return API.get("/page-sizes")
      .then(r => dispatch(fetchTemplatePageSizesSuccess(r.data)))
      .catch(e => dispatch(fetchTemplatePageSizesFailed(e)));
  };
};

export const fetchTemplateDetails = templateId => {
  return dispatch => {
    dispatch(fetchTemplateDetailsStart(templateId));
    return API.get(`/${templateId}`)
      .then(r => dispatch(fetchTemplateDetailsSuccess(r.data)))
      .catch(e => dispatch(fetchTemplateDetailsFailed(e)));
  };
};

export const fetchTemplateTypes = isCompSuperAdmin => {
  return dispatch => {
    dispatch(fetchTemplateTypeStart());
    return API.get("/types", {
      params: isCompSuperAdmin ? { superadmin_company: true } : {},
    })
      .then(r => dispatch(fetchTemplateTypeSuccess(r.data)))
      .catch(e => dispatch(fetchTemplateTypeFailed(e)));
  };
};

export const unpublishTemplate = templateId => {
  return dispatch => {
    dispatch(unpublishTemplateStart());
    return API.put(`/${templateId}`)
      .then(r => dispatch(unpublishTemplateSuccess(r.data)))
      .catch(e => dispatch(unpublishTemplateFailed(e)));
  };
};

export const fetchBlankTemplateSize = () => {
  return dispatch => {
    dispatch(fetchBlankTemplateSizeStart());
    return API.get("/blank-templates")
      .then(r => dispatch(fetchBlankTemplateSizeSuccess(r.data)))
      .catch(e => dispatch(fetchBlankTemplateSizeFailed(e)));
  };
};

const fetchTemplateCategoriesByTypeStart = () => {
  return { type: TEMPLATE_CATEGORIES_BY_TYPE_START };
};
const fetchTemplateCategoriesByTypeSuccess = result => {
  return { type: TEMPLATE_CATEGORIES_BY_TYPE_SUCCESS, result };
};
const fetchTemplateCategoriesByTypeFailed = error => {
  return { type: TEMPLATE_CATEGORIES_BY_TYPE_FAILED, error };
};

export const fetchTemplateCategoriesByType = (type_slug, superadmin = false) => {
  return dispatch => {
    dispatch(fetchTemplateCategoriesByTypeStart());
    return API.get(`/categories?template_type=${type_slug}&superadmin=${superadmin}`)
      .then(r => dispatch(fetchTemplateCategoriesByTypeSuccess(r.data)))
      .catch(e => dispatch(fetchTemplateCategoriesByTypeFailed(e)));
  };
};

export const fetchTemplateSearch = (superAdmin, filterType) => {
  return dispatch => {
    dispatch(fetchTemplateSearchStart());
    return API.get(`/categories/filter?mod=${filterType}&superadmin_company=${superAdmin}`)
      .then(r => dispatch(fetchTemplateSearchSuccess(r.data, filterType)))
      .catch(e => dispatch(fetchTemplateSearchFailed(e)));
  };
};
