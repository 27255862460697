import { useContext } from "react";

import * as constant from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";

const useGoToPage = () => {
  let { documentType, metadata, blockNodes, dimension, isTimeLineViewOpen } = useContext(EditorContext);

  const goToPage = zoom => {
    if (metadata.activeBlockId != "" && documentType !== constant.TYPE_INFOGRAPHIC && !metadata.disableAutoScroll) {
      let currentZoom = zoom ? parseFloat(zoom) / 100 : dimension.zoom / 100;
      let activePageHeight = parseFloat(blockNodes[metadata.activePageIdx].style.height);
      let targetOffset = (activePageHeight * currentZoom + 26) * metadata.activePageIdx;

      if (targetOffset >= 0) {
        if (isTimeLineViewOpen) document.querySelector("#canvas-panel-area").scrollTop = 0;
        else {
          setTimeout(function () {
            document.querySelector("#canvas-panel-area").scrollTop = targetOffset;
          }, 500);
        }
      }
    }
  };

  return { goToPage };
};

export default useGoToPage;
