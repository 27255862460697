import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import SliderControll from "../SliderControll";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import useResizeLineWidget from "../../../hooks/useResizeLineWidget";

let style = Object.assign({}, global);

const LineThickness = props => {
  let { metadata, rightContextMenu, updateRightContextMenu, copyStyle, updateCopyStyle } = useContext(EditorContext);

  let isGroupWidget = document.getElementById(metadata.activeWidgetId[0])?.closest(".dhp-page-group");

  const [lineThickness, setLineThickness] = useState();
  const [lineThicknessInputValue, setLineThicknessInputValue] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [updateContext, setUpdateContext] = useState(false);

  const setResizeLineWidget = useResizeLineWidget();

  const toggle = () => {
    setDropdownOpen(prevState => !prevState);

    // If linethickness click from right context menu
    if (props.rightContext) {
      updateRightContextMenu({
        ...rightContextMenu,
        enable: false,
        clickedMenu: "lineThickness",
      });
    }
    // When click outside on line thickness
    else {
      updateRightContextMenu({
        ...rightContextMenu,
        clickedMenu: "",
      });
    }
  };

  useEffect(() => {
    if (lineThickness && !isGroupWidget) {
      setResizeLineWidget({
        lineThickness: parseInt(lineThickness),
        contextMenu: true,
        updateContext: updateContext,
      });
    }
    setUpdateContext(false);
  }, [lineThickness, updateContext]);

  //Load current formatting
  useEffect(() => {
    if (metadata.activeWidgetId) {
      let innerHtmlflag = document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`);
      setLineThickness(innerHtmlflag.getAttribute("data-linethickness"));
    }
  }, [metadata.activeWidgetId]);

  // copy style linethickness
  useEffect(() => {
    if (metadata.activeWidgetId) {
      if (!copyStyle.enable && copyStyle.lineThicknessFlag) {
        setUpdateContext(true);
        setLineThickness(copyStyle.lineThicknessFlag);
        updateCopyStyle({ ...copyStyle, lineThicknessFlag: false });
      }
    }
  }, [copyStyle]);

  // when linethickness click from right context menu
  useEffect(() => {
    if (rightContextMenu?.clickedMenu === "lineThickness") {
      setDropdownOpen(true);
    }
  }, [rightContextMenu.clickedMenu]);

  return (
    <li
      className={cx(style["toolset-group"], {
        [style["disabled"]]: isGroupWidget,
      })}>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        className={cx(style["toolset-item"], style["line-thickness-wrap"])}>
        <DropdownToggle tag="span" className={cx(style["toolset-action"], style["custom-tooltip"])}>
          <Icon icon="ui-lines" />
          {props.rightContext && "Line Thickness"}
          {!props.rightContext && (
            <div className={cx(style["custom-tooltip-content"], style["top"])}>Line Thickness</div>
          )}
        </DropdownToggle>

        {!props.rightContext && (
          <DropdownMenu className={cx(style["shadow"], style["border-0"], style["rounded"])}>
            <SliderControll
              Slider={lineThickness}
              setSlider={setLineThickness}
              SliderInputValue={lineThicknessInputValue}
              setSliderInputValue={setLineThicknessInputValue}
              sliderMax={100}
              sliderMin={1}
              setUpdateContext={setUpdateContext}
            />
          </DropdownMenu>
        )}
      </Dropdown>
    </li>
  );
};

LineThickness.propTypes = {
  rightContext: PropTypes.bool,
};

export default LineThickness;
