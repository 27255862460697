import React, { useContext } from "react";
import cx from "classnames";
import { EditorContext } from "../../containers/editor/EditorLayout";
import global from "../../scss/dhp.scss";
import useRotatable from "../../hooks/useRotatable";
import useResizable from "../../hooks/useResizable";
import useCollage from "../../hooks/useCollage";
import { widgetQuery } from "../../_helpers/utils";
import { RESIZE, ROTATE } from "../../constants/editor";

let style = Object.assign({}, global);

const WidgetHandler = () => {
  const { metadata, widgetHandler } = useContext(EditorContext);
  const { start: initRotatable } = useRotatable();
  const { start: initResizable } = useResizable();
  const { fetchCollageEvent, collageItemResize: initCollageItemResize } = useCollage();

  const getActiveWidgetId = () => {
    let activeWidgetId;
    const { isSingleWidget, isMultiWidget, data: domRef } = widgetQuery(metadata.activeWidgetId);

    if (isSingleWidget) {
      const {
        widget: {
          id: widgetId,
          isChildWidget,
          parent: { id: widgetParentId },
        },
      } = domRef;

      activeWidgetId = [isChildWidget ? widgetParentId : widgetId];
    }

    if (isMultiWidget) {
      activeWidgetId = metadata.activeWidgetId;
    }

    return activeWidgetId;
  };

  const mouseDownSubscribers = (domEvent, dhpEvent, resizer) => {
    const isLeftClick = domEvent.button === 0;
    let { isActive: isCollageEventActive, widgetId } = fetchCollageEvent();

    // NOTE:::: Only when left mouse button is pressed i.e. MouseEvent.button = 0
    if (isLeftClick) {
      if (isCollageEventActive) initCollageItemResize({ widgetId, resizer, domEvent });
      else {
        widgetId = getActiveWidgetId();

        if (dhpEvent === ROTATE) initRotatable({ widgetId }, domEvent);
        if (dhpEvent === RESIZE) initResizable({ widgetId, resizer }, domEvent);
      }
    }
  };

  return (
    <div id="dhp-widget-handler" className={cx(style["dhp-widget-handler"], style["d-none"])}>
      <div className={style["draggable"]}>
        <div className={cx(style["tooltip"], style["d-none"])}></div>
      </div>

      <div className={style["rotatable"]}>
        <div
          className={cx(style["rotator"], { [style["invisible"]]: !widgetHandler?.handler?.rotator })}
          onMouseDown={e => mouseDownSubscribers(e, ROTATE)}></div>
        <div className={cx(style["tooltip"], style["d-none"])}></div>
      </div>

      <div className={style["resizable"]}>
        <div className={style["resizers"]}>
          {widgetHandler?.handler?.resizers?.map((resizer, i) => (
            <div
              key={i}
              className={cx(style[resizer?.baseClass], style[resizer?.resizerClass])}
              style={{ opacity: resizer?.opacity, zIndex: resizer?.zIndex }}
              onMouseDown={e => mouseDownSubscribers(e, RESIZE, resizer?.resizerClass)}></div>
          ))}
        </div>
        <div className={cx(style["tooltip"], style["d-none"])}></div>
      </div>

      <div className={cx(style["crop-preview"], style["d-none"])}>
        <img className={style["thumb"]} />
      </div>

      <div className={cx(style["media-loader"], style["d-none"])}>
        <div className={style["loader-position"]}>
          <div className={style["loader-wrapper"]}>
            <div className={cx(style["loader-spinner"], "spinner-border", "text-light")} role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetHandler;
