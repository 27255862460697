import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Input as RInput, Form } from "reactstrap";
import { debounceTrailing as debounce, preventFormSubmitOnEnter as preventSubmit } from "../../../_helpers/utils";

import { DotLoader } from "../../ui/loader/dotLoader";
import AssetsLoader from "../../ui/loader/assetsLoader";
import { Icon } from "../../ui/icon";
import global from "../../../scss/dhp.scss";
import crown from "../../../assets/images/ui-crown.svg";

import AssetName from "./Common/AssetName";
import { useContextualUpgrade } from "../../../hooks/useContextualUpgrade";
import { useCheckCompanyPlanInfo } from "../../../hooks/useCheckCompanyPlanInfo";
import { COMPANY_SUPERADMIN } from "../../../constants/company";

import {
  fetchAiWriterTone,
  createAiWriterTextCompletion,
  updateAiWriterTextCompletion,
  fetchAiWriterTextCompletionList,
  deleteAiWriterTextCompletion,
  fetchAiWriterTextCompletionDetails,
} from "../../../store/actions/aiActions";
import Modal from "../../ui/modal";
import DeleteElemModal from "../Modals/DeleteElemModal";
import { DELETE_AI_WRITER_TEXT_COMPLETION_SUCCESS } from "../../../store/actions/actionTypes";
import CommonLineLoader from "../../ui/loader/commonLineLoader";
import { Input } from "../../ui/input";
import useAiWriter from "../../../hooks/useAiWriter";
import { DOCHIPO } from "../../../constants/editor";

let style = Object.assign({}, global);

const AISectionName = ({ sectionName, setSectionName, handleWidgetAction }) => {
  const handleBack = e => {
    e.preventDefault();

    if (!sectionName.disabled) {
      //Explanation in EditorLayout.js
      handleWidgetAction("ai-writer", "AI Writer", "ai_writers", 0, false, "text", "AI Writer");
      setSectionName({ id: "collection", name: "AI Writer" });
    }
  };

  return (
    <>
      {!sectionName.hidden && (
        <h6 className={cx(style["mb-4"], style["fw-6"], style["d-flex"], style["align-items-center"], style["pr-4"])}>
          <Link
            to="#"
            className={cx({
              [style["disable"]]: sectionName.disabled,
            })}
            onClick={e => handleBack(e)}>
            <Icon icon="ui-arrow-left" additionalclass="mr-2" />
          </Link>
          <span className={style["text-truncate"]}>{sectionName.name}</span>
        </h6>
      )}
    </>
  );
};
//Props type validation
AISectionName.propTypes = {
  sectionName: PropTypes.object.isRequired,
  setSectionName: PropTypes.func.isRequired,
  handleWidgetAction: PropTypes.func.isRequired,
};

const AIWriterCollection = ({ setSectionName, collectionId, setCollectionId }) => {
  const NO_RESULTS = "No record found";
  let hasMore = false;

  const loadMoreRef = useRef();
  const dispatch = useDispatch();
  const showUpgrade = useContextualUpgrade();
  const paidCompanyInfo = useCheckCompanyPlanInfo(true);
  const { textExtended, textCompletionList, loadingList } = useSelector(state => state.ai);

  const IntersectOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  };
  const fetch = {
    page: 1,
    limit: 50,
    keyword: "",
  };

  const [textCompletion, setTextCompletion] = useState([]);
  const [payloadFetch, setPayloadFetch] = useState(fetch);
  const [totalCredits, setTotalCredits] = useState();
  const [showModal, setShowModal] = useState(false);

  const { used: usedCreditsFromExtend = undefined } = textExtended ?? { used: undefined };
  const {
    total_collection: totalCollection = 0,
    total_count: totalCount = 0,
    message = "",
    data,
    used: usedCredits = undefined,
  } = textCompletionList ?? { totalCollection: 0, totalCount: 0, message: "", data: [], used: undefined };

  const loadMore = () => setPayloadFetch({ ...payloadFetch, page: payloadFetch.page + 1 });
  const fetchRecords = () => dispatch(fetchAiWriterTextCompletionList(payloadFetch));
  const toggle = () => setShowModal(prevState => !prevState);

  const upgradeSubscriptionCheck = () => {
    if (paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && !paidCompanyInfo?.isPaid) {
      showUpgrade("aiWriter");
    } else {
      setCollectionId();
      setSectionName({ id: "generator", name: "AI Writer" });
    }
  };

  const applyDebounce = debounce(keyword => {
    setPayloadFetch({ ...payloadFetch, keyword: keyword, page: 1 });
  }, 500);

  const handleIntersect = entries => {
    if (entries[0].isIntersecting && hasMore) loadMore();
  };
  const handleDeleteCollection = id => {
    setCollectionId(id);
    setShowModal(true);
  };

  const deleteHandler = () => {
    if (collectionId) {
      dispatch(deleteAiWriterTextCompletion(collectionId)).then(r => {
        if (r.type === DELETE_AI_WRITER_TEXT_COMPLETION_SUCCESS) {
          const updatedText = textCompletion.filter(list => list.id !== collectionId);
          setTextCompletion(updatedText);
          setCollectionId();
          if (updatedText.length === 0) fetchRecords();
        }
      });
    }
  };
  const handleCollectionDetail = id => {
    setCollectionId(id);
    setSectionName({ id: "generator", name: "AI Writer" });
  };

  useEffect(() => {
    if (usedCreditsFromExtend >= 0) setTotalCredits(usedCreditsFromExtend);
  }, [usedCreditsFromExtend]);

  useEffect(() => {
    if (usedCredits >= 0) setTotalCredits(usedCredits);
  }, [usedCredits]);

  useEffect(() => {
    if (!data && message === NO_RESULTS) setTextCompletion([]);
    if (data) setTextCompletion(payloadFetch.page > 1 ? [...textCompletion, ...data] : data);

    if (totalCount > 0) {
      const lastPage = Math.floor(
        totalCount % payloadFetch.limit === 0 ? totalCount / payloadFetch.limit : totalCount / payloadFetch.limit + 1
      );
      hasMore = payloadFetch.page < lastPage;
    }

    const observer = new IntersectionObserver(handleIntersect, IntersectOptions);
    if (loadMoreRef.current) observer.observe(loadMoreRef.current);

    return () => {
      if (loadMoreRef.current) observer.unobserve(loadMoreRef.current);
    };
  }, [textCompletionList]);

  useEffect(() => {
    fetchRecords();
  }, [payloadFetch]);

  return (
    <>
      <div className={cx(style["generate-collection"])}>
        <Button
          type="submit"
          color="primary"
          className={style["btn-border"]}
          cssModule={style}
          disabled={false}
          onClick={() => upgradeSubscriptionCheck()}>
          <Icon icon="ui-sparkling" additionalclass="mr-2 font-20" />
          Generate
          <span className={cx(style["crown-icon"], style["ml-2"])}>
            <img src={crown} width="20" className={style["img-fluid"]} alt="Upgrade" />
          </span>
        </Button>
        <span className={style["mt-2"]}>Ask AI to create content instantly on any topic</span>
      </div>

      {paidCompanyInfo?.isPaid && totalCredits >= 0 && (
        <div className={cx(style["mr-20"], style["mt-2"], style["ai-info-link"])}>
          <Link
            to="#"
            className={cx(
              style["d-flex"],
              style["justify-content-end"],
              style["align-items-center"],
              style["color-33"]
            )}>
            {`Usage: ${totalCredits}/250`} <Icon icon="information" additionalclass="ml-2 font-base" />
          </Link>
          <div className={cx(style["info-link-content"], style["rounded"], style["shadow-sm"])}>
            {" "}
            250 AI Writer uses per month
          </div>
        </div>
      )}

      {totalCollection > 0 && (
        <div className={cx(style["display-collection"], style["pt-3"])}>
          <p className={cx(style["label"], style["border-bottom"], style["mr-20"], style["pb-1"])}>All Collections</p>
          <div className={cx(style["search-field"], style["p-0"], style["pr-20"], style["mb-3"], style["mr-0"])}>
            <div className={cx(style["search-area"], style["clearfix"])}>
              <span className={style["searchicon"]}>
                <Icon icon="ui-search"></Icon>
              </span>
              <RInput
                type="text"
                id="aiCollectionSearch"
                className={style["form-control"]}
                placeholder={`Search Collections`}
                autoComplete="off"
                onChange={e => applyDebounce(e.target.value.trim())}
              />
            </div>
          </div>

          {!loadingList && message === NO_RESULTS && (
            <div className={cx(style["alert"], style["alert-block"], style["mr-20"], style["alert-danger"])}>
              No results found
            </div>
          )}

          <div className={cx(style["customScroll"], style["scroll-Y"], style["assets-wrapper"])}>
            {loadingList && payloadFetch.page === 1 && (
              <div className={cx(style["assets-wrapper"], style["pr-0"])}>
                <ul>
                  <AssetsLoader count={payloadFetch.limit} />
                </ul>
              </div>
            )}

            {!(loadingList && payloadFetch.page === 1) && (
              <ul>
                {textCompletion?.map(text => (
                  <TextAsset
                    key={text.id}
                    text={text}
                    handleCollectionDetail={handleCollectionDetail}
                    handleDeleteCollection={handleDeleteCollection}
                  />
                ))}
              </ul>
            )}

            {!loadingList && <div style={{ height: "20px" }} ref={loadMoreRef}></div>}

            {loadingList && payloadFetch.page > 1 && <DotLoader />}
          </div>

          {showModal && (
            <Modal
              showModal={showModal}
              setShowModal={setShowModal}
              component={DeleteElemModal}
              modalTitle={"Delete"}
              modalBody={"Are you sure to delete this collection? This action can’t be reversed."}
              modalButtonLabel={"Delete"}
              deleteElem={deleteHandler}
              closeModal={toggle}
            />
          )}
        </div>
      )}
    </>
  );
};

AIWriterCollection.propTypes = {
  setSectionName: PropTypes.func,
  collectionId: PropTypes.string,
  setCollectionId: PropTypes.func,
};

const TextAsset = ({ text, handleDeleteCollection, handleCollectionDetail }) => {
  return (
    <li className={cx(style["text-left"])}>
      <div className={style["asset-item"]}>
        <span
          className={cx(style["line3-truncate"])}
          onClick={() => {
            handleCollectionDetail(text.id);
          }}>
          {text.promt}
        </span>
        <span
          className={style["delete-img"]}
          onClick={() => {
            handleDeleteCollection(text.id);
          }}>
          <Icon icon="ui-trash" />
        </span>
      </div>
    </li>
  );
};

TextAsset.propTypes = {
  text: PropTypes.object.isRequired,
  handleDeleteCollection: PropTypes.func,
  handleCollectionDetail: PropTypes.func,
};

const AIWriterGenerator = ({ collectionId, setCollectionId, sectionName, setSectionName }) => {
  const CREATE = "CREATE";
  const UPDATE = "UPDATE";
  const POPULATE = "POPULATE";
  const defaultTone = { name: "Professional" };

  const { start: addToCanvas } = useAiWriter();
  const dispatch = useDispatch();
  const {
    inProgress,
    loadingDetail,
    errorMessage,
    tone,
    textCompletion,
    updatedTextCompletion,
    textCompletionDetails,
  } = useSelector(state => state.ai);

  const [toneValue, setToneValue] = useState(defaultTone);
  const [toneList, setToneList] = useState([]);
  const [formFields, setFormFields] = useState({
    describe: "",
    tone: "Professional",
  });
  const [availableText, setAvailableText] = useState();
  const [charCount, setCharCount] = useState(0);

  const updateFormData = ({ type, value }) => {
    if (type === "describe") setCharCount(value.trim().length);
    setFormFields({ ...formFields, [type]: value });
  };

  const handleSubmit = () => {
    const payload = {
      ...formFields,
      promt: formFields.describe.trim(),
    };

    if (!collectionId) dispatch(createAiWriterTextCompletion(payload));
    else dispatch(updateAiWriterTextCompletion(collectionId, payload));
  };

  const handleDataFeed = ({ type, data }) => {
    const {
      text = "",
      promt: prompt = "",
      tone = "",
      id = null,
    } = data ?? { text: "", prompt: "", tone: "", id: null };

    if (prompt !== "") setSectionName({ id: "generator", name: prompt });

    if ([CREATE, UPDATE, POPULATE].includes(type)) {
      setToneValue({ name: tone });
      setAvailableText(text);

      if (type === CREATE) setCollectionId(id);
      if (type === POPULATE) {
        setCharCount(prompt.trim().length);
        setFormFields({ describe: prompt, tone: tone });
      }
    }
  };

  useEffect(() => {
    if (textCompletion) handleDataFeed({ type: CREATE, data: textCompletion });
  }, [textCompletion]);

  useEffect(() => {
    if (updatedTextCompletion) handleDataFeed({ type: UPDATE, data: updatedTextCompletion });
  }, [updatedTextCompletion]);

  useEffect(() => {
    if (textCompletionDetails) handleDataFeed({ type: POPULATE, data: textCompletionDetails });
  }, [textCompletionDetails]);

  useEffect(() => {
    if (inProgress) setSectionName({ ...sectionName, name: "AI Writer", disabled: true });
  }, [inProgress]);

  useEffect(() => {
    if (loadingDetail) setSectionName({ ...sectionName, name: "AI Writer", hidden: true });
  }, [loadingDetail]);

  useEffect(() => {
    if (errorMessage) setSectionName({ ...sectionName, hidden: false, disabled: false });
  }, [errorMessage]);

  useEffect(() => {
    if (tone) setToneList(tone);
    else dispatch(fetchAiWriterTone());
  }, [tone]);

  useEffect(() => {
    if (collectionId) dispatch(fetchAiWriterTextCompletionDetails(collectionId));
    setToneValue(defaultTone);

    return () => {
      setCollectionId();
    };
  }, []);

  return (
    <>
      {loadingDetail && (
        <div className={style["mr-20"]}>
          <CommonLineLoader width={100} height="24px" loaderClass="pb-3" sourceComponent="editor" />
          <CommonLineLoader width={100} height="110px" loaderClass="pb-3" sourceComponent="editor" />
          <CommonLineLoader width={100} height="110px" loaderClass="pb-3" sourceComponent="editor" />
          <CommonLineLoader width={100} height="54px" loaderClass="pb-3" sourceComponent="editor" />
        </div>
      )}

      {!loadingDetail && (
        <>
          {collectionId && !inProgress && (
            <>
              <div className={cx(style["mb-2"], style["mr-20"], style["form-group"])}>
                <label>Output</label>
                <div className={cx(style["output-textarea"], style["border"], style["rounded"])}>
                  <div className={cx(style["customScroll"], style["scroll-Y"])}>{availableText}</div>
                </div>
              </div>
            </>
          )}

          {inProgress && (
            <>
              <div className={cx(style["assets-wrapper"])}>
                <ul className={cx(style["row"], style["row-cols-12"])}>
                  <AssetsLoader count={1} />
                </ul>
              </div>

              <div className={cx(style["text-center"], style["pr-4"])}>
                Generating content for{" "}
                <span
                  className={cx(
                    style["fw-6"],
                    style["line3-truncate"],
                    style["pt-1"]
                  )}>{`${formFields.describe}`}</span>
              </div>
            </>
          )}

          {errorMessage && (
            <div className={cx(style["text-center"], style["pr-4"])}>
              <div className={cx(style["alert"], style["alert-block"], style["alert-danger"])}>{errorMessage}</div>
            </div>
          )}

          {!inProgress && (
            <div
              className={cx(
                style["generate-query"],
                style["assets-wrapper"],
                style["customScroll"],
                style["scroll-Y"],
                {
                  [style["aigenerate-query-more"]]: collectionId,
                }
              )}>
              {!errorMessage && (
                <Form onKeyDown={e => preventSubmit(e)} onSubmit={e => preventSubmit(e)}>
                  <div className={style["mb-2"]}>
                    <Input
                      // cssModule={style}
                      type="multiline"
                      label="Describe"
                      required={true}
                      placeholder={"Example: Write in about 25 words how QR code helps a business"}
                      onChange={e => updateFormData({ type: "describe", value: e.target.value })}
                      multilineClass="m-0"
                      maxLength={150}
                      value={formFields.describe}
                      title=""
                    />
                    <div className={cx(style["d-flex"], style["justify-content-end"])}>{`${charCount}/150`}</div>
                  </div>
                  <div className={cx(style["line-controls-wrap"], style["flex-column"], style["align-items-baseline"])}>
                    <div className={cx(style["slidelabel"], style["w-100"], style["m-0"], style["fw-6"])}>Tone</div>
                    <Input
                      type="dropdown"
                      formGroupClass={cx(style["mb-0"], style["w-100"])}
                      name="tone"
                      classNamePrefix="select"
                      defaultValue={toneValue}
                      selected={formFields.tone}
                      getOptionValue={toneList => toneList.name}
                      getOptionLabel={toneList => <span>{toneList.name}</span>}
                      options={toneList}
                      updateState={toneList => updateFormData({ type: "tone", value: toneList.name })}
                      noOptionsMessage={() => "No results found"}
                    />
                  </div>
                </Form>
              )}

              <div className={cx(style["mt-auto"], style["text-center"], style["fixed-bottom"])}>
                {!errorMessage && (
                  <Button
                    color={collectionId ? "" : "primary"}
                    className={cx(style["btn-block"], {
                      [style["btn-border"]]: collectionId,
                    })}
                    cssModule={style}
                    disabled={charCount === 0}
                    onClick={() => handleSubmit()}>
                    {collectionId ? "Rewrite Content" : "Generate Content"}
                  </Button>
                )}

                {collectionId && (
                  <Button
                    color="primary"
                    className={style["btn-block"]}
                    cssModule={style}
                    onClick={() => addToCanvas({ event: "ADD", type: DOCHIPO, data: availableText })}>
                    Add to Canvas
                  </Button>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

AIWriterGenerator.propTypes = {
  collectionId: PropTypes.string,
  setCollectionId: PropTypes.func,
  setSectionName: PropTypes.func,
  sectionName: PropTypes.object,
};

const AiWriter = ({ assetName, handleWidgetAction }) => {
  const [sectionName, setSectionName] = useState({ id: "collection", name: "" });
  const [collectionId, setCollectionId] = useState();

  return (
    <div className={cx(style["editor-asset-inner"], style["ai-writer-asset"])}>
      {sectionName.id === "collection" && (
        <>
          <AssetName assetName={assetName} handleWidgetAction={handleWidgetAction} />
          <AIWriterCollection
            setSectionName={setSectionName}
            collectionId={collectionId}
            setCollectionId={setCollectionId}
          />
        </>
      )}
      {sectionName.id === "generator" && (
        <>
          <AISectionName
            sectionName={sectionName}
            setSectionName={setSectionName}
            handleWidgetAction={handleWidgetAction}
          />
          <AIWriterGenerator
            collectionId={collectionId}
            setCollectionId={setCollectionId}
            setSectionName={setSectionName}
            sectionName={sectionName}
          />
        </>
      )}
    </div>
  );
};
//Props type validation
AiWriter.propTypes = {
  assetName: PropTypes.string.isRequired,
  handleWidgetAction: PropTypes.func.isRequired,
};

export default AiWriter;
