import React, { useContext, useEffect, useState } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import PropTypes from "prop-types";
import cx from "classnames";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { BRAND, STOCK, UPLOAD_VIDEO, VIDEO } from "../../../constants/editor";
import { Icon } from "../../ui/icon";
import SliderControll from "../SliderControll";
import useVideo from "../../../hooks/useVideo";
import useAudio from "../../../hooks/useAudio";
import global from "../../../scss/dhp.scss";

let style = Object.assign({}, global);

const Volume = props => {
  const { widgets, audios, metadata, rightContextMenu, updateRightContextMenu } = useContext(EditorContext);
  const { fetchVolume: fetchVideoVolume, applyVolume: applyVideoVolume } = useVideo();
  const { fetchVolume: fetchAudioVolume, applyVolume: applyAudioVolume } = useAudio();

  const [volume, setVolume] = useState();
  const [volumeInputValue, setVolumeInputValue] = useState();
  const [updateContext, setUpdateContext] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const widgetId = metadata.activeWidgetId[0];
  const widgetType = metadata.activeWidgetType[0];
  const widgetObj = widgets?.find(w => w.id === widgetId);

  const audioId = metadata.activeAudioId;
  const audioObj = audios?.find(a => a.id === audioId);

  const toggleDropdown = () => {
    if (props.rightContext) {
      document.querySelector(".volume-action-toolbar").click();
      updateRightContextMenu({
        ...rightContextMenu,
        enable: false,
      });
    } else setDropdownOpen(prevState => !prevState);
  };

  //Load current formatting (get volume) | reset when drag-and-replace [[FOR VIDEO]]
  useEffect(() => {
    const source = document.getElementById(widgetId)?.dataset?.source;
    if (widgetId && widgetType === VIDEO && [STOCK, BRAND, UPLOAD_VIDEO].includes(source)) {
      const { volume } = fetchVideoVolume({ widgetId });
      setVolume(volume);
      setVolumeInputValue(volume);
    }
  }, [widgetId, widgetType, widgetObj?.data]);

  //Load current formatting (get volume) [[FOR AUDIO]]
  useEffect(() => {
    if (audioId && audioObj) {
      const { volume } = fetchAudioVolume({ audioObj });
      setVolume(volume);
      setVolumeInputValue(volume);
    }
  }, [audioId, audioObj?.data]);

  //Update widget | set volume
  useEffect(() => {
    if (volume >= 0 && widgetId) applyVideoVolume({ widgetId, volume, finalUpdate: updateContext });
    if (volume >= 0 && audioId) applyAudioVolume({ audioId, audioObj, volume, finalUpdate: updateContext });
  }, [volume, updateContext]);

  return (
    <li className={style["toolset-group"]}>
      <UncontrolledDropdown
        className={cx(style["toolset-item"], style["volume-wrap"])}
        isOpen={dropdownOpen}
        toggle={toggleDropdown}>
        <DropdownToggle
          tag="span"
          className={cx(style["toolset-action"], style["custom-tooltip"], {
            ["volume-action-toolbar"]: !props.rightContext,
          })}>
          <Icon icon={`ui-${volume === 0 ? "volume-mute" : "volume"}`} />
          {props.rightContext && "Volume"}
          {!props.rightContext && <div className={cx(style["custom-tooltip-content"], style["top"])}>Volume</div>}
        </DropdownToggle>

        <DropdownMenu className={cx(style["shadow"], style["border-0"], style["rounded"])}>
          <SliderControll
            Slider={volume}
            setSlider={setVolume}
            SliderInputValue={volumeInputValue}
            setSliderInputValue={setVolumeInputValue}
            sliderMax={100}
            sliderMin={0}
            setUpdateContext={setUpdateContext}
          />
        </DropdownMenu>
      </UncontrolledDropdown>
    </li>
  );
};

Volume.propTypes = {
  rightContext: PropTypes.bool,
};

export default Volume;
