import React, { useContext, useEffect } from "react";
import cx from "classnames";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import useVideo from "../../../hooks/useVideo";
import { VIDEO, BRAND, STOCK, UPLOAD_VIDEO } from "../../../constants/editor";

let style = Object.assign({}, global);

const Pause = () => {
  let { metadata, togglePlayButton, updateTogglePlayButton, widgets } = useContext(EditorContext);
  const { videoPlayer } = useVideo();

  useEffect(() => {
    if (metadata.activeWidgetId?.length === 1) {
      // video will play after insert
      setTimeout(() => {
        const activeWidget = document.getElementById(metadata.activeWidgetId);
        const { assetType = null, source = null, playing = null } = activeWidget.dataset ?? {};
        if (assetType === VIDEO && [STOCK, BRAND, UPLOAD_VIDEO].includes(source) && playing === "true") {
          toggleMediaPlay(true);
        }
      }, 60);
    }
  }, [metadata.activeWidgetId]);

  const toggleMediaPlay = play => {
    const isPlaying = play ?? !togglePlayButton;
    updateTogglePlayButton(isPlaying);

    videoPlayer({
      event: isPlaying ? "PLAY" : "PAUSE",
      widgetId: metadata.activeWidgetId[0],
    });
  };

  return (
    <li className={style["toolset-group"]}>
      <div className={cx(style["toolset-item"], style["pause-wrap"])}>
        <span className={cx(style["toolset-action"], style["custom-tooltip"])} onClick={() => toggleMediaPlay()}>
          <Icon icon={`ui-${togglePlayButton ? "pause" : "play"}`} />
          <div className={cx(style["custom-tooltip-content"], style["top"])}>{togglePlayButton ? "Pause" : "Play"}</div>
        </span>
      </div>
    </li>
  );
};

export default Pause;
