import React, { useContext } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { TYPE_BROCHURE, TYPE_INFOGRAPHIC, TYPE_YOUTUBE_BANNER } from "../../../constants/editor";
import { EditorContext } from "../../../containers/editor/EditorLayout";

let style = Object.assign({}, global);

const WidgetGrid = props => {
  let { dimension } = useContext(EditorContext);
  let zoomval = 100 / parseFloat(dimension.zoom);
  let borderstyle = `${zoomval}px dashed rgb(204, 0, 204)`;

  return (
    <>
      <div
        className={`grid-line snap-line-left-${props.grid.widgetId} vertical-lines`}
        data-id={props.grid.widgetId}
        data-line="vertical"
        style={{ left: `${props.grid.left}px`, borderLeft: borderstyle }}></div>
      <div
        className={`grid-line snap-line-vertical-${props.grid.widgetId} vertical-lines`}
        data-id={props.grid.widgetId}
        data-line="vertical"
        style={{ left: `${props.grid.center}px`, borderLeft: borderstyle }}></div>
      <div
        className={`grid-line snap-line-right-${props.grid.widgetId} vertical-lines`}
        data-id={props.grid.widgetId}
        data-line="vertical"
        style={{ left: `${props.grid.right}px`, borderLeft: borderstyle }}></div>

      <div
        className={`grid-line snap-line-top-${props.grid.widgetId} horaizental-lines`}
        data-id={props.grid.widgetId}
        data-line="horaizental"
        style={{ top: `${props.grid.top}px`, borderTop: borderstyle }}></div>
      <div
        className={`grid-line snap-line-horaizental-${props.grid.widgetId} horaizental-lines`}
        data-id={props.grid.widgetId}
        data-line="horaizental"
        style={{ top: `${props.grid.middle}px`, borderTop: borderstyle }}></div>
      <div
        className={`grid-line snap-line-bottom-${props.grid.widgetId} horaizental-lines`}
        data-id={props.grid.widgetId}
        data-line="horaizental"
        style={{ top: `${props.grid.bottom}px`, borderTop: borderstyle }}></div>
    </>
  );
};
//Props type validation
WidgetGrid.propTypes = {
  grid: PropTypes.object.isRequired,
};

const MarginGrid = () => {
  let { dimension, snapGrid, documentType } = useContext(EditorContext);
  return (
    <div
      className={cx(style["grid-line"], style["snap-line-block-margin"])}
      data-id={documentType === TYPE_INFOGRAPHIC ? snapGrid?.blockGrid?.pageId : snapGrid?.blockGrid?.blockId}
      data-line="margin"
      style={
        documentType === TYPE_INFOGRAPHIC
          ? {
              left: `${snapGrid?.blockGrid?.marginPos}px`,
              top: `${snapGrid?.blockGrid?.marginPos}px`,
              right: `${snapGrid?.blockGrid?.marginPos}px`,
              bottom: `${snapGrid?.blockGrid?.marginPos}px`,
              height: `${dimension.height - snapGrid?.blockGrid?.marginPos * 2}px`,
            }
          : {
              left: `${snapGrid?.blockGrid?.marginPos}px`,
              top: `${snapGrid?.blockGrid?.marginPos}px`,
              right: `${snapGrid?.blockGrid?.marginPos}px`,
              bottom: `${snapGrid?.blockGrid?.marginPos}px`,
            }
      }></div>
  );
};

const BlockMiddleGrid = () => {
  let { snapGrid } = useContext(EditorContext);

  return (
    <div
      className={cx(style["grid-line"], style["snap-line-block-horaizental"])}
      data-id={snapGrid?.blockGrid?.blockId}
      data-line="horaizental"
      style={{ top: `${snapGrid?.blockGrid?.middle}px` }}></div>
  );
};

const BlockCenterGrid = () => {
  let { dimension, snapGrid, documentType } = useContext(EditorContext);

  return (
    <div
      className={cx(style["grid-line"], style["snap-line-block-vertical"])}
      data-id={documentType === TYPE_INFOGRAPHIC ? snapGrid?.blockGrid?.pageId : snapGrid?.blockGrid?.blockId}
      data-line="vertical"
      style={{
        height: documentType === TYPE_INFOGRAPHIC ? `${dimension.height}px` : "100%",
        left: `${snapGrid?.blockGrid?.center}px`,
      }}></div>
  );
};

const GuideLineGrid = () => {
  let { metadata, documentType } = useContext(EditorContext);

  return (
    <>
      {documentType.toLowerCase() === TYPE_YOUTUBE_BANNER && (
        <>
          <div
            className={`grid-line g1 snap-line-guide-horaizental`}
            data-id={metadata.activeBlockId}
            data-line="horaizental"
            style={{ top: document.getElementById("g-1").style.top }}></div>

          <div
            className={`grid-line g2 snap-line-guide-horaizental `}
            data-id={metadata.activeBlockId}
            data-line="horaizental"
            style={{ top: document.getElementById("g-2").style.top }}></div>

          <div
            className={`grid-line g3 snap-line-guide-vertical `}
            data-id={metadata.activeBlockId}
            data-line="vertical"
            style={{ left: document.getElementById("g-3").style.left }}></div>

          <div
            className={`grid-line g4 snap-line-guide-vertical `}
            data-id={metadata.activeBlockId}
            data-line="vertical"
            style={{ left: document.getElementById("g-4").style.left }}></div>
        </>
      )}

      {documentType.toLowerCase() === TYPE_BROCHURE && (
        <>
          <div
            className={`grid-line g1 snap-line-guide-vertical `}
            data-id={metadata.activeBlockId}
            data-line="vertical"
            style={{ left: document.getElementById("g-1").style.left }}></div>

          <div
            className={`grid-line g2 snap-line-guide-vertical `}
            data-id={metadata.activeBlockId}
            data-line="vertical"
            style={{ left: document.getElementById("g-2").style.left }}></div>
        </>
      )}
    </>
  );
};

const WidgetContainerGrid = props => {
  return (
    <>
      <div
        className={`grid-line snap-line-left-${props.grid.widgetContainerIdx} vertical-lines`}
        data-id={props.grid.widgetContainerIdx}
        data-line="vertical"
        style={{ top: "0px", left: `${props.grid.left}px`, borderLeft: `1px dashed rgb(204, 0, 204)` }}></div>
      <div
        className={`grid-line snap-line-right-${props.grid.widgetContainerIdx} vertical-lines`}
        data-id={props.grid.widgetContainerIdx}
        data-line="vertical"
        style={{ top: "0px", left: `${props.grid.right}px`, borderLeft: `1px dashed rgb(204, 0, 204)` }}></div>
    </>
  );
};
//Props type validation
WidgetContainerGrid.propTypes = {
  grid: PropTypes.object.isRequired,
};

const SnapGrid = props => {
  return (
    <>
      {props.component === "widget" && <WidgetGrid {...props} />}
      {props.component === "margin" && <MarginGrid />}
      {props.component === "block-middle" && <BlockMiddleGrid />}
      {props.component === "block-center" && <BlockCenterGrid />}
      {props.component === "guide-line" && <GuideLineGrid />}
      {props.component === "widget-container" && <WidgetContainerGrid {...props} />}
    </>
  );
};
//Props type validation
SnapGrid.propTypes = {
  component: PropTypes.string.isRequired,
};

export default SnapGrid;
