import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DOCUMENT_CREATE_STATUS } from "../constants/document";
import { checkDocumentCreatePermission } from "../store/actions/documentActions";
import { useContextualUpgrade } from "./useContextualUpgrade";

export const useDocumentCreatePermission = () => {
  const dispatch = useDispatch();
  const documentCreateStatus = useSelector(state => state.document?.documentCreateStatus);

  // useContextualUpgrade Hook to show update modal
  const showUpgrade = useContextualUpgrade();
  const [isCreate, setIsCreate] = useState(false);
  const [isApiCallDone, setIsApiCallDone] = useState(false);

  const checkToDocumentCreate = useCallback(() => {
    dispatch(checkDocumentCreatePermission());
    setIsApiCallDone(true);
  }, []);

  useEffect(() => {
    if (documentCreateStatus && isApiCallDone) {
      setIsApiCallDone(false);
      if (documentCreateStatus.toLowerCase() === DOCUMENT_CREATE_STATUS) {
        // Call showUpgrade function to show contexttual upgrade modal based on your condition
        showUpgrade("unlimitedDocs");
      } else {
        setIsCreate(true);
      }
    }
  }, [documentCreateStatus]);

  useEffect(() => {
    if (isCreate) setIsCreate(false);
  }, [isCreate]);

  // Return show upgrade modal
  return [checkToDocumentCreate, isCreate];
};
