import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import WaveSurfer from "wavesurfer.js";

const formWaveSurferOptions = ref => ({
  container: ref,
  waveColor: "#c9cbd3",
  progressColor: "#ed7d31",
  cursorColor: "black",
  interact: false,
  barWidth: 2.5,
  barRadius: 3,
  responsive: true,
  height: 55,
  barGap: 1.5,
  // If true, normalize by the maximum peak instead of 1.0.
  normalize: false,
  // Use the PeakCache to improve rendering speed of large waveforms.
  partialRender: true,
});

const Waveform = ({ url, setWaveFormLoading }) => {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);

  useEffect(() => {
    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);
    wavesurfer.current.load(url);
    wavesurfer.current.on("ready", () => {
      setWaveFormLoading(false);
    });
    return () => wavesurfer.current.destroy();
  }, [url]);

  return (
    <div>
      <div id="waveform" ref={waveformRef} />
    </div>
  );
};

Waveform.propTypes = {
  setWaveFormLoading: PropTypes.func,
  url: PropTypes.string,
};

export default Waveform;
