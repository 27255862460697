import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Input } from "../../ui/input";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { contextualConfig } from "../editor_config";
import { TABLE, TEXT, TEXT_FRAME } from "../../../constants/editor";
import UseCheckWidgetAllignment from "../../../hooks/UseCheckWidgetAllignment";
import {
  calculateNewPositionOnRotatedObjectResize,
  getCssTransformObj,
  getUpdatedGroupInnerWidgetPos,
  getZoomedValue,
  updateHeightAndTopForInactiveGroupChildren,
} from "../../../_helpers/utils";

let style = Object.assign({}, global);

const FontSize = ({ defaultFontSizeSet, setFontSize, defaultFontList, sourceComponent }) => {
  let { metadata, widgets, doubleClickActive, updateWidgets, dimension, textFontSize, setTextFontSize } =
    useContext(EditorContext);

  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
  let items = [];

  const [defaultValue, setDefaultValue] = useState();

  const { checkWidgetAllignmentForSingleWidget } = UseCheckWidgetAllignment();

  const availableFontSizes = defaultFontList ?? contextualConfig.FontSize;

  availableFontSizes.forEach(availableFontSize => {
    items.push({
      name: availableFontSize,
    });
  });

  const updateFontSizeOnUserInput = e => {
    if (e && ((e.type === "keyup" && 13 === e.keyCode) || e.type === "blur")) {
      let value =
        parseFloat(e.target.value) % 1 != 0 ? parseFloat(e.target.value).toFixed(1) : Math.round(e.target.value);
      updateFontSize(value < 1 ? 1 : value > 900 ? 900 : value);
    }

    setTextFontSize(e.target.value);
  };

  const updateFontSize = size => {
    setTextFontSize(size);

    if (sourceComponent === TABLE) {
      if (setFontSize) setFontSize(size);
      return;
    }

    let mainelem = document.getElementById(metadata.activeWidgetId[0]);
    let groupWidgetInitHeight = isGroupWidget && parseFloat(isGroupWidget.style.height);
    let newArray;

    if (metadata.activeWidgetType[0] === TEXT) mainelem.querySelector(".dhp-widget-inner").style.fontSize = `${size}px`;
    if (metadata.activeWidgetType[0] === TEXT_FRAME) {
      let activeTextDiv = mainelem?.querySelector(".dhp-widget-inner .active-text");
      let activeTextParentDiv = activeTextDiv.classList.contains("title")
        ? activeTextDiv?.parentElement
        : activeTextDiv.closest(".title")?.parentElement;

      activeTextParentDiv.style.fontSize = `${size}px`;
    }

    // if the text is under a group widget
    if (isGroupWidget) {
      // Get group updated height and inner widget updated top and height according group
      let { groupDivHeight, parcentHeight, parcentTop } = getUpdatedGroupInnerWidgetPos(
        targetId,
        id,
        document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight
      );

      // update DOM and group handler
      document.getElementById(targetId).style.height = `${groupDivHeight}px`;
      document.getElementById(id).style.height = parcentHeight;
      document.getElementById(id).style.top = parcentTop;
      document.getElementById("dhp-widget-handler").style.height = `${getZoomedValue(
        groupDivHeight,
        dimension.zoom
      )}px`;

      // update height and top for inactive child widgets
      updateHeightAndTopForInactiveGroupChildren({
        group: isGroupWidget,
        groupOldHeight: groupWidgetInitHeight,
        groupNewHeight: groupDivHeight,
        activeChildId: id,
      });

      newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgets[targetWidgetIndex],
          style: {
            ...widgets[targetWidgetIndex].style,
            height:
              metadata.activeWidgetType[0] === TEXT_FRAME
                ? widgets[targetWidgetIndex].style.height
                : `${groupDivHeight}px`,
          },
          innerHTML: document.getElementById(targetId).innerHTML,
        },
      });
    }
    // if the text is a main widget
    else {
      const { x_al, y_al } = checkWidgetAllignmentForSingleWidget(
        document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight
      );

      const widgetObject = widgets[targetWidgetIndex];
      const {
        translate: { x: widgetTransX, y: widgetTransY },
        rotate: { theta: widgetTheta },
      } = getCssTransformObj({
        transform: widgetObject.style.transform,
      });
      // calculate new transform
      const { left, top } = calculateNewPositionOnRotatedObjectResize(
        parseFloat(widgetTransX),
        parseFloat(widgetTransY),
        parseFloat(widgetObject.style.width),
        parseFloat(
          metadata.activeWidgetType[0] === TEXT_FRAME
            ? widgetObject.style.height
            : document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight
        ),
        parseFloat(widgetObject.style.width),
        parseFloat(widgetObject.style.height),
        parseFloat(widgetTheta)
      );
      const widgetTransformStr = getCssTransformObj({
        translateX: `${left}px`,
        translateY: `${top}px`,
        transform: widgetObject.style.transform,
        returnStr: true,
      });

      newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgetObject,
          style: {
            ...widgetObject.style,
            height:
              metadata.activeWidgetType[0] === TEXT_FRAME
                ? widgetObject.style.height
                : `${document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight}px`,
            transform: widgetTransformStr,
          },
          data: {
            ...widgetObject.data,
            "data-x-allignment": x_al,
            "data-y-allignment": y_al,
          },
          innerHTML: document.getElementById(metadata.activeWidgetId[0]).innerHTML,
        },
      });
    }

    updateWidgets(newArray);
  };

  //Load current widget font size
  useLayoutEffect(() => {
    if (metadata.activeWidgetId && sourceComponent !== TABLE) {
      let mainElem = document.getElementById(metadata.activeWidgetId[0]);
      let targetElem;

      if (metadata.activeWidgetType[0] === TEXT) targetElem = mainElem?.querySelector(`.dhp-widget-inner`);
      if (metadata.activeWidgetType[0] === TEXT_FRAME)
        targetElem = mainElem.querySelector(`.dhp-widget-inner .active-text`)?.parentElement;

      let fontSizeValue =
        parseFloat(targetElem?.style?.fontSize) % 1 != 0
          ? parseFloat(targetElem?.style?.fontSize).toFixed(1)
          : Math.round(targetElem?.style?.fontSize.replace("px", ""));

      if (!isNaN(fontSizeValue)) {
        setDefaultValue({ name: fontSizeValue });
        setTextFontSize(fontSizeValue);
      }
    }
  }, [metadata.activeWidgetId]);

  //Load text frame widget font size after doubleclick
  useEffect(() => {
    if (doubleClickActive && metadata.activeWidgetType[0] === TEXT_FRAME) {
      let activeTextDiv = document?.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner .active-text`);
      let activeTextParentDiv = activeTextDiv.classList.contains("title")
        ? activeTextDiv?.parentElement
        : activeTextDiv.closest(".title")?.parentElement;
      let fontSizeValue = activeTextParentDiv?.style?.fontSize.replace("px", "");

      if (!isNaN(fontSizeValue)) setDefaultValue({ name: fontSizeValue });
    }
  }, [doubleClickActive]);

  // Set default font side from parent component
  useEffect(() => {
    if (defaultFontSizeSet) {
      setDefaultValue({ name: defaultFontSizeSet });
      setTextFontSize(defaultFontSizeSet);
    }
  }, [defaultFontSizeSet]);

  useEffect(() => {
    if (textFontSize) setDefaultValue({ name: parseInt(textFontSize) });
  }, [textFontSize]);

  return (
    <li className={style["toolset-group"]}>
      <div className={cx(style["toolset-item"], style["fontsize-wrap"], style["font-common-action"])}>
        <div className={cx(style["custom-tooltip"])}>
          <div className={cx(style["position-relative"], style["customInputSelect"])}>
            <input
              id="textFontSizeDropdown"
              className={style["form-control"]}
              type="number"
              value={textFontSize}
              onKeyUp={e => updateFontSizeOnUserInput(e)}
              onBlur={e => updateFontSizeOnUserInput(e)}
              onChange={e => updateFontSizeOnUserInput(e)}
            />
            <Input
              type="dropdown"
              name="family"
              classNamePrefix="select"
              defaultValue={defaultValue}
              getOptionValue={option => option.name}
              getOptionLabel={option => <span>{option.name}</span>}
              options={items}
              updateState={option => updateFontSize(option.name)}
              noOptionsMessage={() => "No results found"}
            />
          </div>

          <div className={cx(style["custom-tooltip-content"], style["top"])}>Font Size</div>
        </div>
      </div>
    </li>
  );
};

FontSize.propTypes = {
  defaultFontSizeSet: PropTypes.number,
  setFontSize: PropTypes.func,
  defaultFontList: PropTypes.array,
  sourceComponent: PropTypes.string,
};

export default FontSize;
