import React from "react";
import PropTypes from "prop-types";

import Components from "./contextMenuIndex";

const LoadToolbarComponent = props => {
  const ComponentToRender = Components[props.component];

  return (
    <>
      {ComponentToRender && (
        <ComponentToRender
          rightContext={props.rightContext}
          more={props.more}
          dropdownOpen={props.dropdownOpen}
          toggle2={props.toggle2}
          idx={props.idx}
          setmoreSecondaryOptionClicked={props.setmoreSecondaryOptionClicked}
          setDropdownOpen={props.setDropdownOpen}
          setIsShadowApplied={props.setIsShadowApplied}
          setIsBorderApplied={props.setIsBorderApplied}
          setIsOutLineApplied={props.setIsOutLineApplied}
          toggle={props.toggle}
        />
      )}
    </>
  );
};
LoadToolbarComponent.propTypes = {
  component: PropTypes.string.isRequired,
  more: PropTypes.bool,
  dropdownOpen: PropTypes.bool,
  toggle2: PropTypes.func,
  idx: PropTypes.number,
  setmoreSecondaryOptionClicked: PropTypes.func,
  setDropdownOpen: PropTypes.func,
  rightContext: PropTypes.bool,
  setIsShadowApplied: PropTypes.func,
  setIsBorderApplied: PropTypes.func,
  setIsOutLineApplied: PropTypes.func,
  toggle: PropTypes.func,
};

export default LoadToolbarComponent;
