import RenameModal from "./modal/renameModal";
import ShareModal from "./modal/shareModal";
import PublishModal from "./modal/publishModal";
import publishToTemplateLibraryModal from "./modal/publishToTemplateLibraryModal";
import TransferModal from "./modal/transferModal";
import DownloadModal from "./modal/downloadModal";
import DeleteModal from "./modal/deleteModal";
import publishAsCompanyTemplateModal from "./modal/publishAsCompanyTemplateModal";

let slug = "documents";

export const actions = [
  {
    name: "Go to Editor",
    value: "go_to_editor",
    icon: "ui-pencil",
    tooltip: true,
    type: "url",
    path: `/editor/{documentId}`,
    search: "",
  },
  {
    name: "Preview",
    value: "preview",
    icon: "eye2",
    tooltip: true,
    type: "url",
    path: `/${slug}/{documentId}/preview`,
    search: "",
    // search: `?ref=${slug}`,
  },
];

export const moreActions = [
  {
    name: "Edit",
    value: "edit",
    icon: "ui-edit",
    tooltip: false,
    type: "url",
    path: `/${slug}/{documentId}/update`,
    search: "",
  },
  {
    name: "Rename",
    value: "rename",
    icon: "ui-rename",
    tooltip: false,
    type: "modal",
    modal: RenameModal,
    modalClassName: "",
    className: "",
  },
  {
    name: "Clone",
    value: "clone",
    icon: "ui-clone2",
    tooltip: false,
    type: "function",
  },
  {
    name: "Share",
    value: "share",
    icon: "ui-unshare",
    tooltip: false,
    type: "modal",
    modal: ShareModal,
    modalClassName: "share-modal modal-with-leftpane",
    className: "modal-lg",
  },
  {
    name: "Share",
    value: "reshare",
    icon: "ui-share",
    tooltip: false,
    type: "modal",
    modal: ShareModal,
    modalClassName: "share-modal modal-with-leftpane",
    className: "modal-lg",
  },
  {
    name: "Publish",
    value: "publish",
    icon: "ui-publish",
    tooltip: false,
    type: "modal",
    modal: PublishModal,
    modalClassName: "publish-modal",
    className: "",
  },
  {
    name: "Unpublish",
    value: "unpublish",
    icon: "ui-unpublish",
    tooltip: false,
    type: "modal",
    modal: PublishModal,
    modalClassName: "publish-modal",
    className: "",
  },
  {
    name: "Publish as Template",
    value: "publish_as_company_template",
    icon: "ui-company-templates",
    tooltip: true,
    type: "modal",
    modal: publishAsCompanyTemplateModal,
    modalClassName: "publish-company-template-modal",
    className: "",
  },
  {
    name: "Republish as Template",
    value: "republish_as_company_template",
    icon: "ui-company-templates",
    tooltip: true,
    type: "modal",
    modal: publishAsCompanyTemplateModal,
    modalClassName: "publish-company-template-modal",
    className: "",
  },
  {
    name: "Publish to Template Library",
    value: "publish_to_template_library",
    icon: "ui-right-2",
    tooltip: true,
    type: "modal",
    modal: publishToTemplateLibraryModal,
    modalClassName: "",
    className: "",
  },
  {
    name: "Republish to Template Library",
    value: "republish_to_template_library",
    // icon: "ui-return",
    icon: "ui-right-2",
    tooltip: true,
    type: "modal",
    modal: publishToTemplateLibraryModal,
    modalClassName: "",
    className: "",
  },
  {
    name: "Transfer",
    value: "transfer",
    icon: "ui-transfer",
    tooltip: false,
    type: "modal",
    modal: TransferModal,
    modalClassName: "",
    className: "",
  },
  {
    name: "Present",
    value: "present",
    icon: "ui-present",
    tooltip: false,
    type: "url",
    path: `/${slug}/{documentId}/present`,
    search: "",
    // search: `?ref=${slug}`,
  },
  {
    name: "Download",
    value: "download",
    icon: "ui-download",
    tooltip: false,
    type: "modal",
    modal: DownloadModal,
    modalClassName: "download-modal",
    className: "",
  },
  {
    name: "Delete",
    value: "delete",
    icon: "ui-trash",
    tooltip: false,
    type: "modal",
    modal: DeleteModal,
    modalClassName: "",
    className: "",
  },
];

export const documentMetadata = {
  version: "",
  dimension: {
    dpi: 72,
    size: "a4",
    width: 595,
    height: 842,
    zoom: 100,
    zoomLevel: 0,
    orientation: "portrait",
    unit: "px",
  },
  grid: {
    enabled: false,
    size: 15,
    unit: "px",
    color: "black",
  },
  margin: {
    enabled: false,
    value: 1,
    unit: "px",
    color: "#5b9bd5",
    style: "dotted",
    width: 2,
  },
  publishing: {
    enabled: false,
  },
  copiedBrowserItem: false,
  canvasBackgroundColor: "",
  canvasBackgroundImage: "",
  canvasBackgroundImageOpacity: "",
  canvasBackgroundColorWhite: "",
  durations: [],
  transition: [],
  fonts: [],
};

export const orderByOptions = [
  {
    name: "Document Owner",
    value: "owner_name",
  },
  {
    name: "Document Name",
    value: "document_name",
  },
  {
    name: "Created Time",
    value: "created_on",
  },
  {
    name: "Last Modified Time",
    value: "modified_on",
  },
];

export const colorPalette = [
  "#FBE7C6",
  "#B7E7E6",
  "#F5CAC3",
  "#B6D1E8",
  "#C8DFBB",
  "#ECD5BB",
  "#D4CEF6",
  "#FED9B7",
  "#A8DADC",
  "#F4E285",
  "#F3D5B5",
  "#A0E7E5",
  "#ECD5BB",
  "#B6D1E8",
  "#C8DFBB",
  "#D4CEF6",
  "#A0E7E5",
  "#F4E285",
  "#B6D1E8",
  "#F5CAC3",
  "#F4E285",
  "#A8DADC",
  "#FED9B7",
  "#C8DFBB",
  "#D4CEF6",
];

export const gridViewTypes = [
  { name: "Gallery View", value: "GALLERYVIEW", icon: "ui-collages", },
  { name: "Tile View", value: "TILEVIEW", icon: "ui-slide", },
  { name: "List View", value: "LISTVIEW", icon: "ui-list", },
];

export const defaultViews = [
  {
    name: "All Documents",
    value: "ALL",
  },
  {
    name: "My Documents",
    value: "ME",
  },
  {
    name: "Shared with Me",
    value: "SHARED",
  },
];
