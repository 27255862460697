import { useContext } from "react";
import { widgetConfig } from "../components/Editor/editor_config";

import * as constant from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";
import useGetTotalDocDuration from "./useGetTotalDocDuration";
import { getHMSToSec, getSecToHMS } from "../_helpers/utils";

const useSetDimension = () => {
  let {
    documentType,
    metadata,
    pageNodes,
    blockNodes,
    dimension,
    updateDimension,
    updateMetadata,
    pageMargin,
    updatePageMargin,
    updateWidgets,
    widgets,
    audios,
  } = useContext(EditorContext);

  const { calculatedocDuration } = useGetTotalDocDuration();

  const manageAudioFadeTiming = (newEndTime, targetAudioIndex) => {
    let totalAudioTime = parseFloat(newEndTime) - parseFloat(audios[targetAudioIndex].startTime);
    let audioFadeInTime =
      audios[targetAudioIndex].data["data-fade-in-value"] !== "None"
        ? parseFloat(audios[targetAudioIndex].data["data-fade-in-value"])
        : 0;
    let audioFadeOutTime =
      audios[targetAudioIndex].data["data-fade-out-value"] !== "None"
        ? parseFloat(audios[targetAudioIndex].data["data-fade-out-value"])
        : 0;
    let newFadeInTime, newFadeOutTime;

    if (audioFadeInTime + audioFadeOutTime > totalAudioTime) {
      let halfTime = totalAudioTime / 2;

      if (audios[targetAudioIndex].data["data-fade-in-value"] !== "None") {
        newFadeInTime = `${halfTime >= 2 ? 2 : halfTime >= 1 && halfTime < 2 ? 1 : 0.5} sec`;
      } else newFadeInTime = "None";

      if (audios[targetAudioIndex].data["data-fade-out-value"] !== "None") {
        newFadeOutTime = `${halfTime >= 2 ? 2 : halfTime >= 1 && halfTime < 2 ? 1 : 0.5} sec`;
      } else newFadeOutTime = "None";
    } else {
      newFadeInTime = audios[targetAudioIndex].data["data-fade-in-value"];
      newFadeOutTime = audios[targetAudioIndex].data["data-fade-out-value"];
    }

    return { newFadeInTime, newFadeOutTime };
  };

  const getNewDocDuration = (newDuration, index, actionName) => {
    let docTime = 0;

    pageNodes.forEach(pageNode => {
      newDuration = actionName === "change-duration" ? newDuration : 0;
      docTime += pageNode.pageIdx === parseInt(index) ? parseFloat(newDuration) : parseFloat(pageNode.pageDuration);
    });

    return docTime.toFixed(1);
  };

  const setAudioDurationBasedOnPageDuration = (newDuration, actionName, index, applyType) => {
    let docDuration = calculatedocDuration();
    let activePageIndex = index ? index : metadata.activePageIdx;
    let activePagePrevDuration =
      applyType && (applyType === "All Pages" || applyType === "All Slides")
        ? docDuration
        : actionName === "change-duration"
        ? pageNodes[activePageIndex].pageDuration
        : 0;
    let newArray = Object.assign([...audios]);

    audios.forEach(targetAudioId => {
      let targetAudioIndex = audios.findIndex(audio => audio.id === targetAudioId.id);
      let originalAudioDuration = parseFloat(getHMSToSec({ hms: audios[targetAudioIndex].originalDuration }));
      let audioDurationEndTime = parseFloat(audios[targetAudioIndex].endTime);
      let audioTrimmedStartTime = parseFloat(audios[targetAudioIndex].data["data-trim"].split(",")[0]);
      let audioTrimmedEndTime = parseFloat(audios[targetAudioIndex].data["data-trim"].split(",")[1]);

      if (actionName === "add-page" || actionName === "clone-page") {
        if (parseFloat(docDuration) === parseFloat(audioDurationEndTime)) {
          // trim audio timing
          let endTimeDiff = audioDurationEndTime + parseFloat(newDuration) - parseFloat(docDuration);
          let newTrimmedEndTime =
            audioTrimmedEndTime + endTimeDiff > originalAudioDuration
              ? originalAudioDuration
              : audioTrimmedEndTime + endTimeDiff;
          let newTrimmedDuration = getSecToHMS({ sec: newTrimmedEndTime - audioTrimmedStartTime });
          let newEndingTime = audioDurationEndTime + parseFloat(newDuration);

          newArray = Object.assign([...newArray], {
            [targetAudioIndex]: {
              ...newArray[targetAudioIndex],
              endTime: `${newEndingTime.toFixed(1)}s`,
              data: {
                ...audios[targetAudioIndex].data,
                "data-trim": `${audioTrimmedStartTime.toFixed(1)}, ${newTrimmedEndTime.toFixed(1)}`,
                "data-trimmed-duration": newTrimmedDuration,
              },
            },
          });
        }
      }

      if (actionName === "delete-page" || actionName === "change-duration") {
        let newEndTime;
        let audioDurationStartTime = parseFloat(audios[targetAudioIndex].startTime);

        // if audio end time and doc duration time is same modifie audio end time when page duration modifie or page delete
        if (parseFloat(docDuration) === parseFloat(audioDurationEndTime)) {
          newEndTime =
            actionName === "change-duration"
              ? audioDurationEndTime - parseFloat(activePagePrevDuration) + parseFloat(newDuration)
              : audioDurationEndTime - parseFloat(newDuration);
        }
        // if audio end time and doc duration time is not same
        else {
          // but audio end time is greater than doc duration time after page duartion resize or page delete then update audio suration end time
          let newDocDuration =
            applyType && (applyType === "All Pages" || applyType === "All Slides")
              ? newDuration
              : getNewDocDuration(newDuration, activePageIndex, actionName);
          if (parseFloat(newDocDuration) < parseFloat(audios[targetAudioIndex].endTime))
            newEndTime = parseFloat(newDocDuration);
          // else do not update audio end time
          else newEndTime = parseFloat(audios[targetAudioIndex].endTime);
        }

        // if the audio start time is greater than total document duration delete that audio
        if (newEndTime - audioDurationStartTime < 1) {
          newArray = newArray.filter(audio => audio.id !== targetAudioId.id);
        } else {
          let targetAudioIndex = newArray.findIndex(audio => audio.id === targetAudioId.id);
          let { newFadeInTime, newFadeOutTime } = manageAudioFadeTiming(newEndTime, targetAudioIndex); // manage audio fade timing depends on audio length if fade in or fade out value is applied
          let newTrimmedEndTime;

          // ---------------------- Audio Trimming -------------------------------
          // if duration decrease or page delete
          if (newEndTime < audioDurationEndTime) {
            // when current end point is less than trimmed end point of audio trim further
            if (
              parseFloat(newEndTime) - parseFloat(audios[targetAudioIndex].startTime) <
              audioTrimmedEndTime - audioTrimmedStartTime
            ) {
              let endTimeDiff =
                parseFloat(newEndTime) -
                parseFloat(audios[targetAudioIndex].startTime) -
                (audioTrimmedEndTime - audioTrimmedStartTime);
              newTrimmedEndTime = audioTrimmedEndTime + endTimeDiff;
            }
          }
          // if duration increase
          else {
            let nt = audioTrimmedStartTime + (newEndTime - audioDurationStartTime);
            newTrimmedEndTime = nt - audioTrimmedStartTime > originalAudioDuration ? originalAudioDuration : nt;
          }

          newTrimmedEndTime = newTrimmedEndTime !== undefined ? newTrimmedEndTime : audioTrimmedEndTime;
          let newTrimmedDuration = getSecToHMS({ sec: newTrimmedEndTime - audioTrimmedStartTime });
          // ---------------------- Audio Trimming end -------------------------------

          newArray = Object.assign([...newArray], {
            [targetAudioIndex]: {
              ...newArray[targetAudioIndex],
              endTime: `${newEndTime.toFixed(1)}s`,
              data: {
                ...newArray[targetAudioIndex].data,
                "data-fade-in-value": newFadeInTime,
                "data-fade-out-value": newFadeOutTime,
                "data-trim": `${audioTrimmedStartTime.toFixed(1)}, ${newTrimmedEndTime.toFixed(1)}`,
                "data-trimmed-duration": newTrimmedDuration,
              },
            },
          });
        }
      }
    });

    return newArray;
  };

  const setDimensionAddBlockEvent = (pageObj, blockObj) => {
    let newPageObj, newBlockObj, reindexedPageArray, reindexedBlockArray;
    let linkedWidgetIncList = [];
    let newArray = Object.assign([...widgets]);

    if (pageObj) {
      // set width on pageObj
      if (documentType === constant.TYPE_INFOGRAPHIC)
        newPageObj = { ...pageObj, style: { ...pageObj.style, width: dimension.width + "px" } };
      else
        newPageObj = {
          ...pageObj,
          style: {
            ...pageObj.style,
            width: dimension.width + "px",
            height: dimension.height + "px",
          },
        };

      // reindexing page array
      reindexedPageArray = [...pageNodes.slice(0, pageObj.pageIdx), newPageObj];

      pageNodes.slice(pageObj.pageIdx).forEach(element => {
        reindexedPageArray.push({ ...element });
      });

      reindexedPageArray.map((item, idx) => (item["pageIdx"] = idx));
    }

    if (blockObj) {
      // set width, height on blockObj
      if (documentType === constant.TYPE_INFOGRAPHIC) {
        newBlockObj = {
          ...blockObj,
          style: {
            ...blockObj.style,
            width: dimension.width + "px",
            height: pageObj ? parseInt(dimension.height) + "px" : "300px",
          },
        };
      } else {
        newBlockObj = {
          ...blockObj,
          style: {
            ...blockObj.style,
            width: dimension.width + "px",
            height: dimension.height + "px",
            transformOrigin: "0 0",
          },
        };
      }

      // reindexing page and block arary
      reindexedBlockArray = [
        ...blockNodes.slice(0, blockObj.blockIdx),
        newBlockObj,
        // ...blockNodes.slice(blockObj.blockIdx),
      ];

      blockNodes.slice(blockObj.blockIdx).forEach(element => {
        reindexedBlockArray.push({ ...element });
      });

      /** Problematic code.
       * Objects are always passed by ref in JS.
       * changing anything in the object will update all instances of it
       */
      reindexedBlockArray.map((item, idx) => (item["blockIdx"] = idx));
    }

    // get the widget list which are linked with the block appear after added block
    document.querySelectorAll(".dhp-widget-hyperlinked").forEach(element => {
      if (
        parseInt(element.getAttribute("data-hyperlink-url")) > parseInt(metadata.activeBlockIdx) &&
        element.getAttribute("id")
      ) {
        linkedWidgetIncList.push(element.getAttribute("id"));
      }
    });

    //update links in widget array
    linkedWidgetIncList.forEach(id => {
      let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
      let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
      let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
      let linkedPageIdx = parseInt(document.getElementById(id).getAttribute("data-hyperlink-url"));

      if (isGroupWidget) {
        document.getElementById(id).setAttribute("data-hyperlink-url", linkedPageIdx + 1);

        newArray = Object.assign([...newArray], {
          [targetWidgetIndex]: {
            ...widgets[targetWidgetIndex],
            innerHTML: document.getElementById(targetId).innerHTML,
          },
        });
      } else {
        newArray = Object.assign([...newArray], {
          [targetWidgetIndex]: {
            ...widgets[targetWidgetIndex],
            data: {
              ...widgets[targetWidgetIndex].data,
              "data-hyperlink-url": linkedPageIdx + 1,
            },
          },
        });
      }
    });

    //audios which have same end time like doc duration, update thet audio's end time also as page duration time update
    if (audios?.length > 0) {
      let newAudioArray = setAudioDurationBasedOnPageDuration(pageObj.pageDuration, "add-page");
      updateWidgets(
        newArray,
        reindexedPageArray,
        reindexedBlockArray,
        false,
        false,
        blockObj.blockIdx,
        false,
        false,
        false,
        newAudioArray
      );
    } else updateWidgets(newArray, reindexedPageArray, reindexedBlockArray, false, false, blockObj.blockIdx);

    updateMetadata({
      ...metadata,
      activePageId: pageObj ? pageObj.pageId : metadata.activePageId,
      activePageIdx: pageObj ? pageObj.pageIdx : metadata.activePageIdx,
      activeBlockId: blockObj.blockId,
      activeBlockIdx: blockObj.blockIdx,
      activeWidgetId: false,
      activeWidgetType: false,
      activeOutSideCanvasArea: false,
      disableAutoScroll: false,
    });
    if (pageObj) setTotalHeight(reindexedBlockArray, dimension.label);
    else setTotalHeight(reindexedBlockArray); // update size to custom when infographic block add second time onwards
  };

  const setTotalHeight = (newBlockArray, size, newBlockWidth, newZoom, newDimension, displayUnit) => {
    let calculatedHeight = 0;
    let zoom = newZoom ? newZoom : dimension.zoom;
    let curDimension = newDimension ? newDimension : dimension;
    //  calculate total page-height
    if (constant.TYPE_INFOGRAPHIC === documentType) {
      newBlockArray?.map(blockInfo => {
        calculatedHeight += parseFloat(blockInfo.style.height);
      });
      calculatedHeight = calculatedHeight !== 0 ? calculatedHeight : curDimension.height;

      // setting editor-outer-wrap height on add/clone block
      let totalPageHeight = (calculatedHeight * zoom) / 100;
      document.querySelector(".editor-outer-wrap").style.height = `${parseInt(totalPageHeight) + 80}px`;
    } else {
      calculatedHeight = curDimension.height;
    }

    // set width & height to editor config data
    updateDimension({
      ...curDimension,
      zoom,
      label: size ?? "Custom",
      width: newBlockWidth ?? curDimension.width,
      height: parseFloat(calculatedHeight),
      displayUnit: displayUnit ? displayUnit : curDimension.displayUnit,
    });

    // update page margin for infographic type document on block add/delete/clone
    if (documentType === constant.TYPE_INFOGRAPHIC && pageMargin.enabled) {
      let marginvalue = pageMargin.value ? pageMargin.value : widgetConfig.page_margin.defaultSize;
      updatePageMargin({
        ...pageMargin,
        style: {
          ...pageMargin.style,
          height: `${parseInt(calculatedHeight) - marginvalue * 2}px`,
        },
      });
    }
  };

  return { setDimensionAddBlockEvent, setTotalHeight, setAudioDurationBasedOnPageDuration, manageAudioFadeTiming };
};

export default useSetDimension;
