import React, { useContext, useEffect, useState } from "react";
import { DropdownItem, DropdownMenu } from "reactstrap";
import cx from "classnames";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { widgetConfig } from "../editor_config";
import { AI, AI_WRITER, EXPAND, REWRITE, SUMMARIZE, TEXT } from "../../../constants/editor";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import useAiWriter from "../../../hooks/useAiWriter";

let style = Object.assign({}, global);

const AiWriterAction = () => {
  const aiConfig = widgetConfig[TEXT].contextualMenu[AI_WRITER] ?? [];
  const iconConfig = {
    [REWRITE]: "ui-rewrite",
    [SUMMARIZE]: "ui-summarize",
    [EXPAND]: "ui-expand1",
  };
  const actionConfig = {
    [REWRITE]: REWRITE,
    [SUMMARIZE]: "Shorten",
    [EXPAND]: EXPAND,
  };

  const { start: updateToCanvas, validateAiActions } = useAiWriter();
  const { metadata, widgets } = useContext(EditorContext);

  const [aiOptions, setAiOptions] = useState([]);
  const [widgetData, setWidgetData] = useState();

  const generateAiOptions = () => {
    const widgetIdx = widgets.findIndex(widget => widget.id === metadata.activeWidgetId[0]);
    const widgetObj = widgets[widgetIdx];
    const result = validateAiActions({ widgetObj });

    setAiOptions(
      aiConfig.map(e => {
        return {
          name: e,
          icon: iconConfig[e],
          isDisabled: !result.validity[e],
        };
      })
    );
    setWidgetData(result);
  };

  const handleAiAction = actionName => {
    updateToCanvas({ event: "UPDATE", type: AI, data: { actionName: actionConfig[actionName], widgetData } });
  };

  useEffect(() => {
    if (metadata.activeWidgetId) generateAiOptions();
  }, [metadata.activeWidgetId]);

  return (
    <DropdownMenu className={cx(style["shadow"], style["border-0"], style["rounded"])}>
      {aiOptions.map((option, i) => (
        <DropdownItem
          key={i}
          tag="a"
          className={cx({ [style["disable"]]: option.isDisabled })}
          onClick={() => !option.isDisabled && handleAiAction(option.name)}>
          <Icon icon={option.icon} />
          {option.name} Content
        </DropdownItem>
      ))}
    </DropdownMenu>
  );
};

export default AiWriterAction;
