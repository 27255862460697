import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { floatToFloatSec, getCssTransformObj, getSecToHMS } from "../../_helpers/utils";
import { W, E, DRAG, RESIZE } from "../../constants/editor";
import re_90_png from "../../assets/images/90a.png";
import global from "../../scss/dhp.scss";
import Waveform from "./Waveform";
import CommonLineLoader from "../ui/loader/commonLineLoader";

let style = Object.assign({}, global);

const defaultFunc = () => null;

const Trimmer = ({ onChange = defaultFunc, onKeyUp = defaultFunc, ...props }) => {
  const [state, setState] = useState({});
  const [value, setValue] = useState([0, 100]);
  const [tooltip, setTooltip] = useState({ [W]: value[0], [E]: value[1] });
  const [showTooltipOnTrim, setShowTooltipOnTrim] = useState(false);
  const [showTooltipOnHover, setShowTooltipOnHover] = useState(false);
  const [sliderCss, setSliderCss] = useState({ width: 100, transform: "translate(0px, 0px)" });
  const [waveFormLoading, setWaveFormLoading] = useState(true);

  const config = {
    trackHeight: props?.trackHeight || 5,
  };

  const trimSlider = {
    meta: false,

    common: {
      getSliderCss: ({ range }) => {
        const [start, stop] = range;
        const width = stop - start;
        const { trackWidth } = trimSlider.common.getSliderNodeAndDataRef();
        const sliderLeft = (trackWidth / 100) * start;
        const transform = getCssTransformObj({
          translateX: sliderLeft + "px",
          exclude: ["scale", "rotate"],
          returnStr: true,
        });
        return { width, transform };
      },

      getRangeSecToRange: ({ range: [lSec, rSec], duration = props.maxDuration }) => {
        const start = lSec * (100 / duration);
        const stop = rSec * (100 / duration);
        return [start, stop];
      },

      getRangeToRangeSec: ({ range: [start, stop], duration = props.maxDuration }) => {
        const lSec = start * (duration / 100);
        const rSec = stop * (duration / 100);
        const lHms = getSecToHMS({ sec: lSec });
        const rHms = getSecToHMS({ sec: rSec });
        return {
          rangeSec: [floatToFloatSec(lSec), floatToFloatSec(rSec)],
          rangeHms: [lHms, rHms],
          rangeDiff: floatToFloatSec(rSec - lSec),
          rangeDiffHms: getSecToHMS({ sec: rSec - lSec }),
          tooltip: { [W]: lHms, [E]: rHms },
        };
      },

      getRangeData: ({ range, data = {} }) => {
        const [start, stop] = range;
        if (props.showUnitInSec) {
          const rangeSecData = trimSlider.common.getRangeToRangeSec({ range });
          data = {
            range,
            ...rangeSecData,
          };
        } else {
          data = {
            range,
            rangeDiff: stop - start,
            tooltip: { [W]: Math.round(start), [E]: Math.round(stop) },
          };
        }
        return data;
      },

      getSliderNodeAndDataRef: () => {
        const track = document.querySelector(".trimmer .track");
        const slider = document.querySelector(".trimmer .slider");
        const { width: trackWidth, left: trackLeft } = track.getBoundingClientRect();
        const { width: sliderWidth } = slider.getBoundingClientRect();
        const { translate: { x: sliderTransX = "" } = {} } = getCssTransformObj({ transform: slider.style.transform });

        return {
          track,
          trackWidth,
          trackLeft,
          slider,
          sliderWidth,
          sliderTransX: parseFloat(sliderTransX),
          sliderTransform: slider.style.transform,
          sliderMinWidth: props.showUnitInSec ? (trackWidth / props.maxDuration) * props.minDuration : trackWidth / 100,
          sliderMaxWidth: {
            [W]: parseFloat(sliderTransX) + sliderWidth,
            [E]: trackWidth - parseFloat(sliderTransX),
          },
          sliderDragRange: {
            xMin: 0,
            xMax: trackWidth - sliderWidth,
          },
          sliderPos: {
            xMin: trackLeft + parseFloat(sliderTransX),
            xMax: trackLeft + parseFloat(sliderTransX) + sliderWidth,
          },
        };
      },

      toggleTooltipAndCursor: ({ action, event }) => {
        const styleId = "cursorStyle";
        const config = {
          [DRAG]: "grabbing",
          [RESIZE]: `url(${re_90_png}) 15 15, auto`,
        };

        if (action === "SHOW" && !showTooltipOnTrim) {
          const styleElm = document.createElement("style");
          styleElm.innerHTML = `*{cursor: ${config[event]}!important;}`;
          styleElm.id = styleId;
          document.head.appendChild(styleElm);
          setShowTooltipOnTrim(true);
        }

        if (action === "HIDE") {
          document.getElementById(styleId)?.remove();
          setShowTooltipOnTrim(false);
          setShowTooltipOnHover(false);
        }
      },

      updateRange: finalUpdate => {
        const { trackWidth, sliderWidth, sliderTransX } = trimSlider.common.getSliderNodeAndDataRef();
        const start = (100 / trackWidth) * sliderTransX;
        const stop = (100 / trackWidth) * (sliderTransX + sliderWidth);
        const data = trimSlider.common.getRangeData({ range: [start, stop] });

        if (finalUpdate) onKeyUp({ event: "onKeyUp", ...data });
        else {
          setValue(data.range);
          setTooltip(data.tooltip);
          onChange({ event: "onChange", ...data });
        }
      },
    },

    drag: {
      start: e => {
        const { slider, sliderTransX, sliderTransform, sliderDragRange } = trimSlider.common.getSliderNodeAndDataRef();

        const props = {
          isDragging: true,
          isFirstEvent: true,
          client: {
            node: slider,
            pointerOffset: {
              x: e.clientX - sliderTransX,
            },
            initial: {
              transform: sliderTransform,
            },
            dragRange: sliderDragRange,
          },
        };

        trimSlider.meta = props;
        document.addEventListener("mousemove", trimSlider.drag.dragging);
        document.addEventListener("mouseup", trimSlider.drag.stop);
      },

      dragging: e => {
        const meta = trimSlider.meta;
        if (meta?.isDragging) {
          const {
            client: {
              node: cNode,
              pointerOffset: { x: cpoX },
              initial: { transform: ciTrans },
              dragRange: { xMin: cdrxMin, xMax: cdrxMax },
            },
          } = meta;

          // determine new drag position of client
          const transX = e.clientX - cpoX;
          const clientTransX = transX > cdrxMax ? cdrxMax : transX < cdrxMin ? cdrxMin : transX;

          cNode.style.transform = getCssTransformObj({
            translateX: clientTransX + "px",
            transform: ciTrans,
            exclude: ["scale", "rotate"],
            returnStr: true,
          });

          // event operations
          if (meta.isFirstEvent) {
            meta.isFirstEvent = false;
            cNode.style.cursor = "grabbing";
            trimSlider.common.toggleTooltipAndCursor({ action: "SHOW", event: DRAG });
          } else trimSlider.common.updateRange();
        }
      },

      stop: () => {
        let meta = trimSlider.meta;
        if (meta?.isDragging) {
          if (!meta.isFirstEvent) trimSlider.common.updateRange(true);
          trimSlider.common.toggleTooltipAndCursor({ action: "HIDE" });
          meta.client.node.style.cursor = "grab";
          document.removeEventListener("mousemove", trimSlider.drag.dragging);
          document.removeEventListener("mouseup", trimSlider.drag.stop);
          meta = false;
        }
      },
    },

    resize: {
      start: (e, re) => {
        const resizer = re ?? (e.target.classList.contains(W) ? W : E);
        const { slider, sliderWidth, sliderTransX, sliderTransform, sliderMinWidth, sliderMaxWidth } =
          trimSlider.common.getSliderNodeAndDataRef();

        const props = {
          isResizing: true,
          isFirstEvent: true,
          client: {
            node: slider,
            minWidth: sliderMinWidth,
            maxWidth: sliderMaxWidth[resizer],
            initial: {
              left: sliderTransX,
              width: sliderWidth,
              transform: sliderTransform,
            },
          },
          resizer: {
            type: resizer,
          },
          mouse: {
            initial: {
              x: e.clientX,
              y: e.clientY,
            },
          },
        };

        trimSlider.meta = props;
        document.addEventListener("mousemove", trimSlider.resize.resizing);
        document.addEventListener("mouseup", trimSlider.resize.stop);
      },

      resizing: e => {
        const meta = trimSlider.meta;
        if (meta?.isResizing) {
          const {
            client: {
              node: client,
              minWidth: cminw,
              maxWidth: cmaxw,
              initial: { left: cil, width: ciw, transform: clientInitTrans },
            },
            resizer: { type: resizerType },
            mouse: {
              initial: { x: mix },
            },
          } = meta;

          // determine new resized position of client
          const dX = e.clientX - mix;

          let width;
          let resizeData = { left: cil, width: ciw };

          // West Resizer | Left
          if (resizerType === W) {
            const delta = { l: dX, w: -dX };
            width = ciw + delta.w;

            if (width < cminw) resizeData = { ...resizeData, width: cminw, left: cil + (ciw - cminw) };
            else if (width > cmaxw) resizeData = { ...resizeData, width: cmaxw, left: 0 };
            else resizeData = { ...resizeData, width, left: cil + delta.l };
          }

          // East Resizer | Right
          else if (resizerType === E) {
            const delta = { w: dX };
            width = ciw + delta.w;

            if (width < cminw) resizeData = { ...resizeData, width: cminw };
            else if (width > cmaxw) resizeData = resizeData = { ...resizeData, width: cmaxw };
            else resizeData = { ...resizeData, width };
          }

          // determine size and position of client after resize
          client.style.width = resizeData.width + "px";
          client.style.transform = getCssTransformObj({
            translateX: resizeData.left + "px",
            transform: clientInitTrans,
            exclude: ["scale", "rotate"],
            returnStr: true,
          });

          // event operations
          if (meta.isFirstEvent) {
            meta.isFirstEvent = false;
            trimSlider.common.toggleTooltipAndCursor({ action: "SHOW", event: RESIZE });
          } else trimSlider.common.updateRange();
        }
      },

      stop: () => {
        let meta = trimSlider.meta;
        if (meta?.isResizing) {
          if (!meta.isFirstEvent) trimSlider.common.updateRange(true);
          trimSlider.common.toggleTooltipAndCursor({ action: "HIDE" });
          document.removeEventListener("mousemove", trimSlider.resize.resizing);
          document.removeEventListener("mouseup", trimSlider.resize.stop);
          meta = false;
        }
      },
    },

    dragResize: {
      start: e => {
        const { trackLeft, slider, sliderTransX, sliderTransform, sliderPos } =
          trimSlider.common.getSliderNodeAndDataRef();

        if (e.clientX < sliderPos.xMin || e.clientX > sliderPos.xMax) {
          // clicked on track but outside slider
          let resizer, resizeData;

          if (e.clientX < sliderPos.xMin) {
            resizer = W;
            resizeData = { width: sliderPos.xMax - e.clientX, left: e.clientX - trackLeft };
          } else if (e.clientX > sliderPos.xMax) {
            resizer = E;
            resizeData = { width: e.clientX - sliderPos.xMin, left: sliderTransX };
          }

          slider.style.width = resizeData.width + "px";
          slider.style.transform = getCssTransformObj({
            translateX: resizeData.left + "px",
            transform: sliderTransform,
            exclude: ["scale", "rotate"],
            returnStr: true,
          });
          trimSlider.resize.start(e, resizer);
        }
      },
    },
  };

  const mouseDownSubscribers = e => {
    // Only when left mouse button is pressed
    if (e.button === 0) {
      if (e.target.classList.contains("slider")) trimSlider.drag.start(e);
      if (e.target.classList.contains("resizer") && !props.showWaveform) trimSlider.resize.start(e);
      if (e.target.classList.contains("track") && !props.showWaveform) trimSlider.dragResize.start(e);
    }
  };

  const mouseHoverSubscribers = e => {
    if (e.type === "mouseenter") setShowTooltipOnHover(true);
    if (e.type === "mouseleave" && !showTooltipOnTrim) setShowTooltipOnHover(false);
  };

  useEffect(() => {
    setState(config);

    if (props.defaultValue) {
      const range = props.showUnitInSec
        ? trimSlider.common.getRangeSecToRange({ range: props.defaultValue })
        : props.defaultValue;
      const { tooltip } = trimSlider.common.getRangeData({ range });
      const sliderCss = trimSlider.common.getSliderCss({ range });

      setValue(range);
      setTooltip(tooltip);
      setSliderCss(sliderCss);
    }
  }, [props.defaultValue]);

  return (
    <>
      {!props.showWaveform && (
        <div className={style["trimmer"]} onMouseDown={mouseDownSubscribers}>
          <div className={style["track"]} style={{ height: `${state.trackHeight}px` }}>
            <div
              className={style["slider"]}
              style={{
                width: `${sliderCss.width}%`,
                height: `${state.trackHeight}px`,
                transform: sliderCss.transform,
              }}
              onMouseEnter={mouseHoverSubscribers}
              onMouseLeave={mouseHoverSubscribers}>
              <div className={style["resizers"]}>
                {[W, E].map((resizer, i) => (
                  <div
                    key={i}
                    className={cx(style["resizer"], style[resizer])}
                    onMouseEnter={mouseHoverSubscribers}
                    onMouseLeave={mouseHoverSubscribers}>
                    {showTooltipOnTrim && (
                      <div className={cx(style["custom-trim-tooltip"], style["top"])}>{tooltip[resizer]}</div>
                    )}
                    {showTooltipOnHover && (
                      <div className={cx(style["custom-trim-tooltip"], style["top"])}>{tooltip[resizer]}</div>
                    )}
                  </div>
                ))}
              </div>
              {props.sliderProgress > 0 && (
                <div
                  className={style["progress-bar"]}
                  style={{
                    width: `${props.sliderProgress}%`,
                    height: `${state.trackHeight}px`,
                  }}></div>
              )}
            </div>
          </div>
        </div>
      )}

      {waveFormLoading && props.showWaveform && (
        <div className={style["trimmer-loader"]}>
          <CommonLineLoader width={100} height="55px" loaderClass="" sourceComponent="editor" />
        </div>
      )}

      {props.showWaveform && (
        <div className={style["trimmer"]} onMouseDown={mouseDownSubscribers}>
          <Waveform url={props.src} setWaveFormLoading={setWaveFormLoading} />
          <div
            className={style["track"]}
            style={{
              height: `${55}px`,
              position: "absolute",
              top: "0px",
              background: "transparent",
              cursor: "default",
            }}>
            <div
              className={style["slider"]}
              style={{
                width: `${sliderCss.width}%`,
                height: `${55}px`,
                transform: sliderCss.transform,
                display : waveFormLoading ? "none" : "block"
              }}
              onMouseEnter={mouseHoverSubscribers}
              onMouseLeave={mouseHoverSubscribers}>
              {props.sliderProgress > 0 && (
                <div
                  className={style["progress-bar"]}
                  style={{
                    width: `${props.sliderProgress}%`,
                    height: `${53}px`,
                    background: "transparent",
                    borderRight: "2px solid #333",
                    zIndex: "1000",
                  }}></div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Trimmer.propTypes = {
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  defaultValue: PropTypes.array,
  sliderProgress: PropTypes.number,
  trackHeight: PropTypes.number,
  showUnitInSec: PropTypes.bool,
  maxDuration: PropTypes.number,
  minDuration: PropTypes.number,
  showWaveform: PropTypes.bool,
  src: PropTypes.string,
};

export default Trimmer;
