import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UncontrolledTooltip } from "reactstrap";
import cx from "classnames";
import PropTypes from "prop-types";

import { Icon } from "../ui/icon";
import global from "../../scss/dhp.scss";

import {
  TR_ALL_TEMPLATE,
  TR_CREATE_DOCUMENT,
  TR_EDITOR_STEP_1,
  TR_EDITOR_STEP_2,
  TR_EDITOR_STEP_3,
  TR_PREVIEW,
  TR_TEMPLATE_TYPE,
} from "../../constants/user";
import { updateUserTourConfig } from "../../store/actions/companyActions";
import { getUserInfo } from "../../store/actions/authActions";

let style = Object.assign({}, global);

const Tour = ({ targetId, tourType }) => {
  const dispatch = useDispatch();

  const [showTooltip, setShowTooltip] = useState(false);
  const [currTourInfo, setCurrTourInfo] = useState(false);
  const [currentTargetId, setCurrentTargetId] = useState(targetId);
  const [extraProps, setExtraProps] = useState({});

  const { uid: userId, user: userInfo } = useSelector(state => state.auth);
  const isTourConfigUpdated = useSelector(state => state.company?.isTourConfigUpdated);
  const tourData = tourType => {
    let tourSteps = {
      [TR_ALL_TEMPLATE]: {
        title: "Start here",
        content: "Choose a document type to see all the beautiful templates.",
        placement: "left",
      },
      [TR_CREATE_DOCUMENT]: {
        title: "Document name",
        content: "Enter a relevant and cool document name.",
        placement: "top-end",
        arrowClassName: "arrow-create-form",
      },
      [TR_PREVIEW]: {
        title: "Use this template",
        content: "To customize this template, click here. For premium templates, you need a pro edition.",
        placement: "bottom",
        popperClassName: "preview-popper",
      },
      [TR_TEMPLATE_TYPE]: {
        title: "Select a template",
        content: "Choose a template and start customizing.",
        placement: "top",
      },
      [TR_EDITOR_STEP_1]: {
        title: "Add widgets",
        content: "Simply choose a widget, drag and drop on the editor.",
        placement: "left",
        clickOption: "Next",
        nextTarget: "tr_editor_step_2",
        popperClassName: "sidebar-tooltip",
        stage: "1/3",
      },
      [TR_EDITOR_STEP_2]: {
        title: "Customize",
        content: "Click on a widget and a toolbar will appear above. Double click to edit text.",
        placement: "left",
        clickOption: "Next",
        boundaries: "editor-wrapper",
        nextTarget: "tr_editor_step_3",
        popperClassName: "editor-canvas-tooltip",
        stage: "2/3",
      },
      [TR_EDITOR_STEP_3]: {
        targetId: "more-options",
        title: "Final step",
        content: "Preview, present, publish and download your document.",
        placement: "bottom",
        clickOption: "Watch tutorials",
        nextTarget: "final-step",
        targetLink: "https://www.dochipo.com/tutorials/",
      },
    };
    if (tourSteps[tourType]) setCurrTourInfo(tourSteps[tourType]);
  };

  // Cholse tooltip popup
  const closeTourPopup = () => {
    setShowTooltip(false);
  };

  const nextTour = () => {
    // Next tour func
    if ([TR_EDITOR_STEP_2, TR_EDITOR_STEP_3].includes(currTourInfo?.nextTarget)) {
      setExtraProps({});
      setShowTooltip(false);
      tourData(currTourInfo?.nextTarget);
      if (currTourInfo?.nextTarget === TR_EDITOR_STEP_2) {
        setExtraProps({ boundariesElement: document.querySelector(`.${currTourInfo?.boundaries}`) });
        setCurrentTargetId(document.getElementById("generic-editor-wrap")?.childNodes[0]?.id);
      } else setCurrentTargetId("more-options");

      setTimeout(() => setShowTooltip(true), 100);
    } else {
      if (currTourInfo.nextTarget === "final-step") {
        // For final step redirect to the tutorial page
        window.open(currTourInfo.targetLink, "_blank", "noopener,noreferrer");
      }
    }
  };

  // Show tooltip based on tour type
  useEffect(() => {
    setTimeout(() => {
      let isDomPresent = document.getElementById(currentTargetId);
      if (!showTooltip && isDomPresent && userInfo?.tour_config?.[tourType] === false && userInfo?.company_updated) {
        tourData(tourType);
        setShowTooltip(true);
      }
    }, 1000);
  }, [userInfo]);

  // Show tooltip based on tour type
  useEffect(() => {
    if (showTooltip && userInfo?.tour_config) {
      let payload = { ...userInfo.tour_config, [tourType]: true };
      if (tourType === TR_EDITOR_STEP_1) payload = { ...payload, [TR_EDITOR_STEP_2]: true, [TR_EDITOR_STEP_3]: true };
      dispatch(updateUserTourConfig(userId, payload));
    }
  }, [showTooltip]);

  // Fetch user info
  useEffect(() => {
    if (isTourConfigUpdated) dispatch(getUserInfo());
  }, [isTourConfigUpdated]);

  return (
    <div>
      {showTooltip && (
        <UncontrolledTooltip
          className={style["tour"]}
          popperClassName={cx({ [style[currTourInfo.popperClassName]]: currTourInfo.popperClassName })}
          arrowClassName={cx({ [style[currTourInfo.arrowClassName]]: currTourInfo.arrowClassName })}
          isOpen={showTooltip}
          placement={currTourInfo.placement}
          target={currentTargetId}
          {...extraProps}>
          <div className={style["tour-inner"]}>
            <div className={cx(style["d-flex"], style["justify-content-between"])}>
              <h4 className={style["tour-title"]}>{currTourInfo.title}</h4>
              <span className={style["cursor-pointer"]} onClick={closeTourPopup}>
                <Icon icon="cross" />
              </span>
            </div>

            <p className="m-0">{currTourInfo.content} </p>
            {currTourInfo.clickOption && (
              <div className={cx(style["d-flex"], style["justify-content-between"], style["mt-3"])}>
                <div className="d-flex align-items-center cursor-pointer h-20" onClick={nextTour}>
                  <span>{currTourInfo.clickOption}</span>
                  <Icon icon="ui-arrow-right" additionalclass="font-20" />
                </div>
                {currTourInfo.stage && <span>{currTourInfo.stage}</span>}
              </div>
            )}
          </div>
        </UncontrolledTooltip>
      )}
    </div>
  );
};

// Props type validation
Tour.propTypes = {
  targetId: PropTypes.string.isRequired,
  tourType: PropTypes.string.isRequired,
};

export default Tour;
