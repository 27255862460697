// for Template Groups
export const TYPE_BUSINESS_ESSENTIALS = "business-essentials";
export const TYPE_GENERAL_PURPOSE = "general-purpose";
export const TYPE_WEB_BANNER = "web-banner";
export const TYPE_SOCIAL_GRAPHIC = "social-graphic";

// for Document Type
export const TYPE_INFOGRAPHIC = "infographic";
export const TYPE_FLYER = "flyer";
export const TYPE_POSTER = "poster";
export const TYPE_PRESENTATION = "presentation";
export const TYPE_BOOK_COVER = "book cover";
export const TYPE_BUSINESS_CARD = "business card";
export const TYPE_CALENDAR = "calendar";
export const TYPE_CARD = "card";
export const TYPE_CERTIFICATE = "certificate";
export const TYPE_INVITATION = "invitation";
export const TYPE_LOGO = "logo";
export const TYPE_MAGAZINE_COVER = "magazine cover";
export const TYPE_RESUME = "resume";
export const TYPE_BLOG_BANNER = "blog banner";
export const TYPE_BLOG_GRAPHIC = "blog graphic";
export const TYPE_EMAIL_HEADER = "email header";
export const TYPE_FACEBOOK_COVER = "facebook cover";
export const TYPE_FACEBOOK_EVENT_COVER = "facebook event cover";
export const TYPE_LINKEDIN_BANNER = "linkedin banner";
export const TYPE_TWITTER_HEADER = "twitter header";
export const TYPE_FACEBOOK_POST = "facebook post";
export const TYPE_INSTAGRAM_POST = "instagram post";
export const TYPE_LINKEDIN_POST = "linkedin post";
export const TYPE_PINTEREST_GRAPHIC = "pinterest graphic";
export const TYPE_TUMBLR_GRAPHIC = "tumblr graphic";
export const TYPE_TWITTER_POST = "twitter post";
export const TYPE_YOUTUBE_THUMBNAIL = "youtube thumbnail";
export const TYPE_YOUTUBE_BANNER = "youtube banner";
export const TYPE_BROCHURE = "brochure";

// for Widget Type
export const TEXT = "texts";
export const TEXT_FRAME = "text_frames";
export const BACKGROUND = "backgrounds";
export const LINE = "lines";
export const SHAPE = "shapes";
export const ICON = "icons";
export const STICKER = "stickers";
export const ILLUSTRATION = "illustrations";
export const PICTURE = "pictures";
export const UPLOAD = "uploads";
export const VIDEO = "videos";
export const ANIMATION = "animations";
export const TABLE = "tables";
export const GROUP_WIDGET = "groups";
export const TYPE_FORM = "typeforms";
export const JOT_FORM = "jotforms";
export const QR_CODE = "qr_code";
export const THEME = "themes";
export const MAP = "maps";
export const COLLAGE = "collages";
export const AUDIO = "audios";

// For widget slug
export const VERSION = "version";

// for page setup
export const PAGE_MARGIN = "page_margin";
export const PAGE_TRANSITION = "page_transition";
export const WIDGET_ANIMATION = "widget_animation";

// for widget data attributes
export const DOCHIPO = "DOCHIPO";
export const STOCK = "STOCK";
export const YOUTUBE = "YOUTUBE";
export const LIBRARY = "LIBRARY";
export const AI = "AI";
export const SVG = "svg";
export const GIF = "gif";
export const MONO = "mono";
export const DECORATED = "Decorated";
export const BASIC = "Basic";
export const SOLID = "solid";
export const SOLO = "solo";
export const SCENE = "scene";
export const BRAND = "BRAND";
export const UPLOAD_VIDEO = "UPLOADVIDEO";
export const UPLOAD_AUDIO = "UPLOADAUDIO";

// for widget toolbar
export const TRANSFORM = "Transform";
export const LIST = "List";
export const ARRANGE = "Arrange";
export const OPACITY = "Opacity";
export const LOCK = "Lock";
export const CLIPBOARD = "ClipBoard";
export const DELETE = "Delete";
export const GROUP = "Group";
export const UNGROUP = "UnGroup";
export const AI_WRITER = "AiWriter";
export const REWRITE = "Rewrite";
export const SUMMARIZE = "Summarize";
export const EXPAND = "Expand";

// for color code
export const WHITE_COLOR = "#ffffff";
export const BLACK_COLOR = "#000000";

// for widget drag-drop action types
export const INSERT = "INSERT";
export const REPLACE = "REPLACE";

// for color filter types
export const BRIGHTNESS = "brightness";
export const CONTRAST = "contrast";
export const HUE = "hue";
export const SATURATE = "saturate";
export const GRAYSCALE = "grayscale";
export const BLUR = "blur";
export const INVERT = "invert";
export const SEPIA = "sepia";

export const LABELBRIGHTNESS = "Brightness";
export const LABELCONTRAST = "Contrast";
export const LABELHUE = "Hue";
export const LABELSATURATE = "Saturation";
export const LABELGRAYSCALE = "Grayscale";
export const LABELBLUR = "Blur";
export const LABELINVERT = "Invert";
export const LABELSEPIA = "Sepia";

// for widget handler (resizers)
export const N = "n";
export const S = "s";
export const W = "w";
export const E = "e";
export const NW = "nw";
export const NE = "ne";
export const SW = "sw";
export const SE = "se";

// for widget events
export const SELECT = "SELECT";
export const DRAG = "DRAG";
export const ROTATE = "ROTATE";
export const RESIZE = "RESIZE";

// for widget handler margin
export const WIDGET_HANDLER_MARGIN_LEFT = 0;
export const WIDGET_HANDLER_MARGIN_TOP = 0;
export const GENERIC_EDITOR_WRAP_PADDING = 0;

// for fit to screen
export const PAGE_MARGIN_LEFT_RIGHT = 0;
// export const PAGE_MARGIN_TOP_BOTTOM = 24 + 28 + 4; // 1.5 rem top padding of ".canvas-panel-area" + ".page-play-wrap" height + space around ".page-play-wrap"
export const PAGE_MARGIN_TOP_BOTTOM = 20; // Need to change with the above line, once video related fit-on-screen issue will be fixed.
export const PAGE_MARGIN_TOP_BOTTOM_WITH_TIMELINE = 2;

// Time to generate Screenshot and Version
export const DOCUMENT_SAVE_TIME_COUNT = 1 * 100;
export const GENERATE_SCREENSHOT_TIME_COUNT = 30 * 1000; // 30 sec
export const GENERATE_VERSION_TIME_COUNT = 3600 * 1000; // 60 min

export const LINE_HEIGHT = 1.4; // default line height for text widget

// for crop-to-shape
export const CROP_TO_SHAPE_HEIGHT = 210;
export const CROP_TO_SHAPE_WIDTH = 210;
export const CROP_TO_SHAPE_PANEL_HEIGHT = 320;
export const CROP_TO_SHAPE_PANEL_WIDTH = 480;
export const CROP_TO_SHAPE_LEFT_RIGHT_PADDING = 78;
export const CROP_TO_SHAPE_TOP_BOTTOM_PADDING = 68;

// hotlink data
export const STOCK_SERVICE_PROVIDERS = {
  pexels: { name: "Pexels", url: { photo: "//www.pexels.com", video: "//www.pexels.com/videos/", audio: "" } },
  pixabay: {
    name: "Pixabay",
    url: {
      photo: "//pixabay.com/photos/search/",
      video: "//pixabay.com/videos/search/",
      audio: "//pixabay.com/music/",
    },
  },
  unsplash: { name: "Unsplash", url: { photo: "//www.unsplash.com/", video: "", audio: "" } },
};
export const UTM_PARAMS = "?utm_source=dochipo&utm_medium=referral";

export const THIRD_PARTY_APPS = ["typeforms", "jotforms"];
export const THIRD_PARTY_PROVIDER = ["Typeform", "Jotform"];

export const FIND_AND_REPLACE_WIDTH = 422;
export const FIND_AND_REPLACE_TOP_PADDING = -58;

export const PAGE_TIMELINE_LEFT_SECTION = 234;
export const PAGE_DURATION_DROP_DOWN_PADDING = 17;

export const PAGE_PLAY_DATA_STORAGE_KEY = "dhp.editor.pageplay.data";
export const DOCUMENT_PLAY_DATA_STORAGE_KEY = "dhp.editor.documentplay.data";