import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";

let style = Object.assign({}, global);

const Strickthrough = props => {
  let { metadata, widgets, updateWidgets, changeBySocket } = useContext(EditorContext);

  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
  let activeNode = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner");

  const [isStrikeThrough, setIsStrikeThrough] = useState();

  const updateTextDecoration = () => {
    let gradActive = document.getElementById(id).getAttribute("data-grad-scolor");
    let textDecorationValue = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style
      .textDecoration;

    //Support text decoration for gradient text
    if (gradActive) {
      textDecorationValue = textDecorationValue.split(" ")[0];

      document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style.textDecoration =
        textDecorationValue === "line-through" ? "none" : "line-through";

      if (textDecorationValue !== "line-through")
        document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style.textDecorationColor =
          document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style.color;
    } else {
      document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style.textDecoration =
        textDecorationValue === "line-through" ? "none" : "line-through";
    }

    // remove formatting from all child div and apply main formatting
    activeNode.querySelectorAll("*").forEach(node => {
      if (node.style?.textDecoration) {
        node.style.textDecoration = "none";
      }
    });

    let newArray = Object.assign([...widgets], {
      [targetWidgetIndex]: {
        ...widgets[targetWidgetIndex],
        innerHTML: document.getElementById(targetId).innerHTML,
      },
    });

    updateWidgets(newArray);
    textDecorationValue === "line-through" ? setIsStrikeThrough("none") : setIsStrikeThrough("line-through");
  };

  const fetchStrikeThroughFromActiveWidget = () => {
    if (metadata.activeWidgetId) {
      let gradActive = document.getElementById(id).getAttribute("data-grad-scolor");
      let innerHtmlflag = document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`);

      if (gradActive) setIsStrikeThrough(innerHtmlflag.style.textDecoration?.split(" ")[0]);
      else setIsStrikeThrough(innerHtmlflag.style.textDecoration);
    }
  };

  //Load current formatting
  useEffect(() => {
    fetchStrikeThroughFromActiveWidget();
  }, [metadata.activeWidgetId]);

  //check StrikeThrough is applied or not for collaboration
  useEffect(() => {
    if (changeBySocket && widgets[targetWidgetIndex]?.innerHTML) fetchStrikeThroughFromActiveWidget();
  }, [changeBySocket, widgets[targetWidgetIndex]?.innerHTML]);

  return (
    <li
      className={cx(style["toolset-group"], {
        [style["active"]]: isStrikeThrough === "line-through",
      })}>
      <div className={cx(style["toolset-item"], style["strickthrough-wrap"])}>
        <span className={cx(style["toolset-action"], style["custom-tooltip"])} onClick={updateTextDecoration}>
          <Icon icon="ui-strikethrough" />
          {!props.more && <div className={cx(style["custom-tooltip-content"], style["top"])}>Strikethrough</div>}
          {props.more && "Strikethrough"}
        </span>
      </div>
    </li>
  );
};
Strickthrough.propTypes = {
  more: PropTypes.bool,
};

export default Strickthrough;
