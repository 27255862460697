"use strict";
import { useCallback, useRef, useState } from "react";

const useStateHistory = (defaultValue, { capacity = 50 } = {}) => {
  const [value, setValue] = useState(defaultValue);
  const historyRef = useRef([value]);
  const pointerRef = useRef(0);

  const set = useCallback(
    v => {
      const resolvedValue = typeof v === "function" ? v(value) : v;
      let updatedValue = {
        ...historyRef.current[pointerRef.current],
        [resolvedValue.type]: resolvedValue.value,
        ...(resolvedValue.optionalPageData && { pageNodes: resolvedValue.optionalPageData }),
        ...(resolvedValue.optionalBlockData && { blockNodes: resolvedValue.optionalBlockData }),
        ...(resolvedValue.optionalBgcolorData && { backgroundColors: resolvedValue.optionalBgcolorData }),
        ...(resolvedValue.optionalBgImageData && { backgroundImages: resolvedValue.optionalBgImageData }),
        ...(resolvedValue.optionalAudioData && { audios: resolvedValue.optionalAudioData }),
        ...(resolvedValue.optionalActiveBlockIndex !== undefined && {
          activeBlockIndex: resolvedValue.optionalActiveBlockIndex,
        }),
        ...(resolvedValue.currentDimension && { currentDimension: resolvedValue.currentDimension }),
        ...{ resizedStatus: resolvedValue.resizedStatus ?? false },
        ...{ currentDocumentType: resolvedValue.currentDocumentType ?? false },
      };

      if (historyRef.current[pointerRef.current] !== resolvedValue) {
        if (pointerRef.current < historyRef.current.length - 1) {
          historyRef.current.splice(pointerRef.current + 1);
        }
        historyRef.current.push(updatedValue);

        while (historyRef.current.length > capacity) {
          historyRef.current.shift();
        }
        pointerRef.current = historyRef.current.length - 1;
      }
      setValue(updatedValue);
    },
    [capacity, value]
  );

  const back = useCallback(() => {
    if (pointerRef.current <= 1) return;
    pointerRef.current--;
    setValue(historyRef.current[pointerRef.current]);
  }, []);

  const forward = useCallback(() => {
    if (pointerRef.current >= historyRef.current.length - 1) return;
    pointerRef.current++;
    setValue(historyRef.current[pointerRef.current]);
  }, []);

  const go = useCallback(index => {
    if (index < 0 || index >= historyRef.current.length - 1) return;
    pointerRef.current = index;
    setValue(historyRef.current[pointerRef.current]);
  }, []);

  return [
    value,
    set,
    {
      history: historyRef.current,
      pointer: pointerRef.current,
      back,
      forward,
      go,
    },
  ];
};

export default useStateHistory;
