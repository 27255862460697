import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { widgetConfig } from "../components/Editor/editor_config";
import { SVG } from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";
import { uploadAssetFile } from "../store/actions/editorActions";
import { getFileType, getImgDimensionFromUrl, getSvgContentFromUrl, RGBToHex } from "../_helpers/utils";
import useAddWidget from "./useAddWidget";
import useAlignment from "./useAlignment";
import useElementInnerHtml from "./useElementInnerHtml";

const useUploadInsertOutsideImage = () => {
  let { metadata, keyBoardEvent, updateKeyBoardEvent, handleWidgetAction, setisUploadDone, isUploadDone, setUploadSchemeType } = useContext(EditorContext);

  const dispatch = useDispatch();

  // used global states
  const { progressInfo, availableUploadImages, uploadLocation } = useSelector(state => state.editor);

  // Local states
  const [assetType, setAssetType] = useState();
  const [assetInnerContent, setAssetInnerContent] = useState();
  const [widgetWidth, setWidgetWidth] = useState();
  const [widgetHeight, setWidgetHeight] = useState();
  const [dataparam, setDataParam] = useState();
  const [addWidgetClicked, setAddWidgetClicked] = useState(false);

  // used custom Hooks
  const { postion: getPosition } = useAlignment(addWidgetClicked, widgetWidth, widgetHeight, "middle-center");
  const getNewWidgetObject = useAddWidget(
    addWidgetClicked,
    assetType,
    dataparam,
    getPosition,
    widgetWidth,
    widgetHeight
  );
  useElementInnerHtml(
    addWidgetClicked,
    setAddWidgetClicked,
    assetType,
    assetInnerContent,
    dataparam,
    getNewWidgetObject
  );

  const uploadOutsideImage = file => {
    // Return if an upload is already in progress
     if (!isUploadDone) {
       return;
     }

    handleWidgetAction("upload", "Upload", "uploads", 1, false, "graphics-media");
    setAssetType("uploads");

    // upload files
    const formData = new FormData();
    formData.append("file", file, file.name ? file.name : "png");
    dispatch(uploadAssetFile(formData, "image", "clipboard"));
    setUploadSchemeType("Images")

    setisUploadDone(false);
    if (document.getElementById("imageListWrapper")) document.getElementById("imageListWrapper").scrollTop = 0;
    updateKeyBoardEvent({ ...keyBoardEvent, paste: false });
  };

  const insertUploadedImage = async ({ url, filename }) => {
    if (!assetType) return;

    let innerContent;

    if (getFileType(filename) !== SVG) {
      innerContent = url;

      const { width: imgWidth, height: imgHeight } = await getImgDimensionFromUrl(url);
      const imgAspectRatio = imgWidth / imgHeight;
      const { width: activeBlockWidth } = document.getElementById(`${metadata.activeBlockId}`)?.getBoundingClientRect();
      const width = imgWidth <= activeBlockWidth ? imgWidth : activeBlockWidth;
      const height = imgWidth <= activeBlockWidth ? imgHeight : width / imgAspectRatio;

      setWidgetWidth(width);
      setWidgetHeight(height);
    } else {
      innerContent = await getSvgContentFromUrl(url);

      //Update multicolor uploaded svg innerhtml with data attribute for different color path after insertion
      innerContent.querySelectorAll(`svg *`).forEach(node => {
        if (
          node.nodeName === "defs" ||
          node.parentNode.nodeName === "defs" ||
          node.parentNode.nodeName === "linearGradient"
        )
          return false;

        if (node.nodeName !== "g" && node.style && RGBToHex(node.style.fill) !== "") {
          const currentIconColor = RGBToHex(node.style.fill);
          node.dataset.class = `${currentIconColor}body`;
        }
      });

      setWidgetWidth(innerContent?.viewBox?.baseVal?.width ?? widgetConfig[props.assetType].width);
      setWidgetHeight(innerContent?.viewBox?.baseVal?.height ?? widgetConfig[props.assetType].height);
    }

    setDataParam({ ...widgetConfig[assetType].dataAttr, "data-file-type": getFileType(filename) });
    setAssetInnerContent(innerContent);
    setAddWidgetClicked(true);
    setTimeout(() => {
      setAssetType();
    }, 1000);
  };

  useEffect(() => {
    if (progressInfo === 100 && uploadLocation === "clipboard") {
      const lastUploadedImageFromClipboard = availableUploadImages[0];

      insertUploadedImage(lastUploadedImageFromClipboard);

      setTimeout(() => {
        setisUploadDone(true);
        setUploadSchemeType(null)
      }, 1000);
    }
  }, [progressInfo]);

  return { uploadOutsideImage };
};

export default useUploadInsertOutsideImage;
