import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";

let style = Object.assign({}, global);

const Animate = props => {
  let { metadata, handleWidgetAction, widgets } = useContext(EditorContext);

  const [isActive, setIsActive] = useState();

  const checkAnimationTypeOfMultiWidgetsEntry = () => {
    let toggleStatusForMultiSelection;
    let animationType = document
      .getElementById(metadata.activeWidgetId[0])
      ?.getAttribute("data-entrance-animation-type");
    toggleStatusForMultiSelection = true;

    // if all selected widget have animation then check wether there type is same or not. If same return true else return false.
    metadata.activeWidgetId.forEach(id => {
      let activeElem = document.getElementById(id);
      if (
        activeElem &&
        (!activeElem.getAttribute("data-entrance-animation") ||
          activeElem.getAttribute("data-entrance-animation") === "false" ||
          activeElem.getAttribute("data-entrance-animation-type") !== animationType)
      ) {
        toggleStatusForMultiSelection = false;
        return toggleStatusForMultiSelection;
      }
    });

    return toggleStatusForMultiSelection;
  };

  const checkAnimationTypeOfMultiWidgetsExit = () => {
    let toggleStatusForMultiSelection;
    let animationType = document.getElementById(metadata.activeWidgetId[0])?.getAttribute("data-exit-animation-type");
    toggleStatusForMultiSelection = true;

    // if all selected widget have same type animation then return true else return false.
    metadata.activeWidgetId.forEach(id => {
      let activeElem = document.getElementById(id);
      if (
        activeElem &&
        (!activeElem.getAttribute("data-exit-animation") ||
          activeElem.getAttribute("data-exit-animation") === "false" ||
          activeElem.getAttribute("data-exit-animation-type") !== animationType)
      ) {
        toggleStatusForMultiSelection = false;
        return toggleStatusForMultiSelection;
      }
    });

    return toggleStatusForMultiSelection;
  };

  // handle click on animation from toolbar
  const handleAnimationIconClick = () => {
    if (document.getElementById("editorBody").classList.contains("hide-secondAside")) props.toggle();
    handleWidgetAction("widget-animation", "Widget Animation", "widget_animation", 1, false, "graphics-media");
  };

  useEffect(() => {
    if (metadata.activeWidgetId) {
      // fetch for multi select widget if animation is active or not
      if (metadata.activeWidgetId.length > 1) {
        let entryAnimatios = checkAnimationTypeOfMultiWidgetsEntry();
        let extrAnimations = checkAnimationTypeOfMultiWidgetsExit();
        if (entryAnimatios || extrAnimations) setIsActive(true);
        else setIsActive(false);
      }
      // fetch for single/group/group-inner select widget if animation is active or not
      else {
        let isGroupWidget = document.getElementById(metadata.activeWidgetId[0])?.closest(".dhp-page-group");
        let targetId = isGroupWidget
          ? document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-root-widget")?.getAttribute("id")
          : metadata.activeWidgetId[0];
        let activeElem = document.getElementById(targetId);
        if (
          activeElem?.getAttribute("data-entrance-animation") === "true" ||
          activeElem?.getAttribute("data-exit-animation") === "true"
        )
          setIsActive(true);
        else setIsActive(false);
      }
    }
  }, [metadata.activeWidgetId, widgets]);

  return (
    <li
      className={cx(style["toolset-group"], {
        [style["active"]]: isActive,
      })}>
      <div className={cx(style["toolset-item"], style["animate-wrap"])}>
        <span className={cx(style["toolset-action"], style["custom-tooltip"])} onClick={handleAnimationIconClick}>
          <Icon icon="ui-animate" />
          {!props.more && <div className={cx(style["custom-tooltip-content"], style["top"])}>Animate</div>}
          {props.more && "Animate"}
        </span>
      </div>
    </li>
  );
};
Animate.propTypes = {
  more: PropTypes.bool,
  toggle: PropTypes.func,
};

export default Animate;
