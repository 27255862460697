import React, { useContext, useEffect, useRef, useState, useLayoutEffect } from "react";
import { Button, DropdownMenu, DropdownToggle, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import cx from "classnames";

import global from "../../scss/dhp.scss";
import { Input } from "../../components/ui/input";
import { Icon } from "../../components/ui/icon";

import { EditorContext } from "../../containers/editor/EditorLayout";
import useSetActivePageBlock from "../../hooks/useSetActivePageBlock";
import { getDocumentDuration, operateStorageData } from "../../_helpers/utils";
import useUpdatePageDuration from "../../hooks/useUpdatePageDuration";
import useCheckPlayButtonDisable from "../../hooks/useCheckPlayButtonDisable";
import { PAGE_PLAY_DATA_STORAGE_KEY, PAGE_DURATION_DROP_DOWN_PADDING, TYPE_PRESENTATION } from "../../constants/editor";

let style = Object.assign({}, global);

const PageTopOption = ({ pageIndex, ...props }) => {
  let {
    metadata,
    dimension,
    pageNodes,
    isTimeLineViewOpen,
    setIsTimeLineViewOpen,
    handleWidgetAction,
    updateScrollEvent,
    audios,
    widgets,
    pagePlayData,
    setPagePlayData,
    changeBySocket,
    updateMetadata,
    playingPageIdx,
    whichPagePlaying,
    setWhichPagePlaying,
    documentType,
  } = useContext(EditorContext);

  const MIN_SECONDS = 1;
  const MAX_SECONDS = 180;
  const DEFAULT_SECONDS = "5.0";
  const pageDurationDropDownWidth = 227;
  const timeInputRef = useRef();
  const pageDurationApplyOptions =
    documentType === TYPE_PRESENTATION ? ["This Slide", "All Slides"] : ["This Page", "All Pages"];

  const [videoTimeFormatted, setVideoTimeFormatted] = useState("0:05.0");
  const [maxSeconds, setMaxSeconds] = useState(MAX_SECONDS);
  const [minSeconds, setMinSeconds] = useState(MIN_SECONDS);
  const [inputSeconds, setInputSeconds] = useState(DEFAULT_SECONDS);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showApplyButton, setShowApplyButton] = useState(true);
  const [activeContainerInfo, setActiveContainerInfo] = useState();
  const [toggleTopOption, setToggleTopOption] = useState({
    firstOption: false,
    secondOption: false,
    thirdOption: false,
    fourthOption: false,
  });
  const [dropDownPositionShift, setDropDownPositionShift] = useState(false);
  const [isPlayButtonDisable, setIsPlayButtonDisable] = useState();
  const [isPagePlaying, setIsPagePlaying] = useState(false);
  const [forcedStop, setForcedStop] = useState(false);
  const [dynamicStyle, setDynamicStyle] = useState(false);
  const [pageDurationApplyType, setPageDurationApplyType] = useState();
  const [isAllPageDurationButtonDisable, setIsAllPageDurationButtonDisable] = useState();

  useSetActivePageBlock(activeContainerInfo);
  const { updatePageDuration } = useUpdatePageDuration();
  const { checkIsVideoTypePage } = useCheckPlayButtonDisable();

  const handleInputChange = e => {
    const inputValue = e ? e.trim() : "";

    if (inputValue === "") {
      setShowApplyButton(true);
      setInputSeconds("");
      return;
    }

    const parsedInput = parseFloat(inputValue);
    if (isNaN(parsedInput)) return;

    const { activePage, document } = getDocumentDuration(pageNodes, metadata.activePageIdx);

    const documentAvailable = parseFloat(document.available.toFixed(1));
    const activePageAvailable = parseFloat(activePage.available.toFixed(1));
    const activeCurrentAvailable = parseFloat(activePage.current.toFixed(1));

    const newMaxSecond = parseFloat(
      (activeCurrentAvailable + Math.min(documentAvailable, activePageAvailable)).toFixed(1)
    );
    setMaxSeconds(newMaxSecond);

    const formattedInput = parsedInput.toFixed(1);
    const clampedInput = Math.min(Math.max(formattedInput, 1.0), newMaxSecond);

    setInputSeconds(clampedInput.toString());
    setShowApplyButton(false);
  };

  const handleDropDown = () => setDropdownOpen(false);

  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    var remainingSeconds = seconds % 60;
    var formattedTime = minutes + ":" + (remainingSeconds < 10 ? "0" : "") + remainingSeconds.toFixed(1);
    return formattedTime;
  };

  const parseTime = timeString => {
    const timeComponents = timeString.split(":");
    const secondsCompoenent = timeComponents[1].split(".");
    const minutes = parseInt(timeComponents[0], 10);
    const seconds = parseInt(secondsCompoenent[0], 10);
    const milliseconds = parseInt(secondsCompoenent[1], 10) / 10;
    const totalSeconds = minutes * 60 + seconds + milliseconds;
    return totalSeconds;
  };

  const increaseTime = () => {
    const INCREASE_AMOUNT = 0.1;
    let newValue;
    const { activePage, document } = getDocumentDuration(pageNodes, metadata.activePageIdx);

    const documentAvailable = parseFloat(document.available.toFixed(1));
    const activePageAvailable = parseFloat(activePage.available.toFixed(1));
    const activeCurrentAvailable = parseFloat(activePage.current.toFixed(1));

    const newMaxSecond = parseFloat(
      (activeCurrentAvailable + Math.min(documentAvailable, activePageAvailable)).toFixed(1)
    );
    setMaxSeconds(newMaxSecond);

    if (parseFloat(inputSeconds) >= newMaxSecond) return;
    let newSecondValue = parseFloat(inputSeconds);

    if (newSecondValue <= newMaxSecond) {
      newValue = (newSecondValue + INCREASE_AMOUNT).toFixed(1);
      setInputSeconds(newValue);
    }

    if (timeInputRef.current !== parseFloat(newValue)) setShowApplyButton(false);
    else setShowApplyButton(true);
  };

  const decreaseTime = () => {
    const DECREASE_AMOUNT = 0.1;
    let newSecondValue = parseFloat(inputSeconds);
    let newValue;

    if (parseFloat(inputSeconds) <= minSeconds) return;

    if (newSecondValue > 1) {
      newValue = (newSecondValue - DECREASE_AMOUNT).toFixed(1);
      setInputSeconds(newValue);
    }

    if (timeInputRef.current !== parseFloat(newValue)) setShowApplyButton(false);
    else setShowApplyButton(true);
  };

  const topOptionCalc = () => {
    let pageWidth = document.querySelector(".dhp-page-block")?.getBoundingClientRect().width;

    if (pageWidth < (pageDurationDropDownWidth / 100) * 68) setDropDownPositionShift(true);
    else setDropDownPositionShift(false);

    let pageTopIconWidth = JSON.parse(window.sessionStorage.getItem("icon_Size_Width"));
    let pageTopOptionWidth = JSON.parse(window.sessionStorage.getItem("page_Top_Option_Width"));

    let [firstIconSize, secondIconSize, thirdIconSize] = pageTopIconWidth ?? [];
    let firstSet = pageTopOptionWidth - firstIconSize;
    let secondSet = pageTopOptionWidth - (firstIconSize + secondIconSize);
    let thirdSet = pageTopOptionWidth - (firstIconSize + secondIconSize + thirdIconSize);

    if (pageWidth > pageTopOptionWidth) {
      setToggleTopOption({ firstOption: false, secondOption: false, thirdOption: false, fourthOption: false });
    } else if (firstSet <= pageWidth && pageWidth < pageTopOptionWidth) {
      setToggleTopOption({
        firstOption: true,
        secondOption: false,
        thirdOption: false,
        fourthOption: false,
      });
    } else if (secondSet <= pageWidth && pageWidth < firstSet) {
      setToggleTopOption({
        firstOption: true,
        secondOption: true,
        thirdOption: false,
        fourthOption: false,
      });
    } else if (thirdSet <= pageWidth && pageWidth < secondSet) {
      setToggleTopOption({
        firstOption: true,
        secondOption: true,
        thirdOption: true,
        fourthOption: false,
      });
    } else setToggleTopOption({ firstOption: true, secondOption: true, thirdOption: true, fourthOption: true });
  };

  const handleApply = () => {
    if (inputSeconds === "") setVideoTimeFormatted(formatTime(DEFAULT_SECONDS));
    else setVideoTimeFormatted(formatTime(inputSeconds));

    updatePageDuration(inputSeconds, false, pageDurationApplyType);

    timeInputRef.current = inputSeconds;
    setDropdownOpen(false);
  };

  const toggle = () => {
    timeInputRef.current = inputSeconds;
    setShowApplyButton(true);
    setDropdownOpen(prevState => !prevState);
  };

  // Set Active container to active page
  const setActivePage = currentActiveIndex => {
    updateScrollEvent(true);

    setActiveContainerInfo({
      targetPageIdx: currentActiveIndex,
      targetBlockIdx: currentActiveIndex,
      disableAutoScroll: false,
    });
  };

  const handleTransitionClick = () => {
    if (document.getElementById("editorBody").classList.contains("hide-secondAside")) props.toggle();
    handleWidgetAction("page-transition", "Page Transition", "page_transition", 5, false, "page-setup");
  };

  const togglePagePlay = () => {
    setForcedStop(false);
    setIsPagePlaying(!isPagePlaying);
  };

  useEffect(() => {
    if (pageNodes[pageIndex]) {
      let pageDuration = pageNodes[pageIndex].pageDuration;
      setInputSeconds(pageDuration);
      setVideoTimeFormatted(formatTime(parseFloat(pageDuration)));
    }
  }, [pageNodes[pageIndex].pageDuration]);

  useEffect(() => {
    if (pageNodes.length > 1) {
      // if given duration is less than equal 30 min apply to all button is enable
      if (parseFloat(inputSeconds) * pageNodes.length <= 1800) setIsAllPageDurationButtonDisable(false);
      // else apply to all button is disable
      else {
        setIsAllPageDurationButtonDisable(true);
        if (pageDurationApplyType === "All Pages" || pageDurationApplyType === "All Slides")
          setPageDurationApplyType(documentType === TYPE_PRESENTATION ? "This Slide" : "This Page");
      }
    }
  }, [inputSeconds]);

  useEffect(() => {
    if (dropdownOpen) {
      const parsedVideoTime = parseTime(videoTimeFormatted);
      timeInputRef.current = parsedVideoTime;
      setInputSeconds(parsedVideoTime);
      setPageDurationApplyType(documentType === TYPE_PRESENTATION ? "This Slide" : "This Page");
    }
  }, [dropdownOpen]);

  useEffect(() => {
    setInputSeconds(pageNodes[metadata.activePageIdx]?.pageDuration);
  }, [pageNodes]);

  useEffect(() => {
    if (changeBySocket && pageNodes[pageIndex]) {
      let pageDuration = pageNodes[pageIndex].pageDuration;
      setInputSeconds(pageDuration);
      setVideoTimeFormatted(formatTime(pageDuration));
    }
  }, [changeBySocket, pageNodes]);

  useLayoutEffect(() => {
    topOptionCalc();
  }, [dimension]);

  useLayoutEffect(() => {
    const timeOut = setTimeout(() => {
      topOptionCalc();
    }, 5);
    return () => clearTimeout(timeOut);
  }, [isTimeLineViewOpen]);

  useEffect(() => {
    // check all conditions for page play option keep disable or not
    let isVideoTypePage = checkIsVideoTypePage(pageNodes[pageIndex].pageId, pageIndex, "page-play");
    setIsPlayButtonDisable(isVideoTypePage ? false : true);
  }, [pageNodes[metadata.activePageIdx]?.pageTransition, audios?.length, widgets]);

  useEffect(() => {
    if (!isTimeLineViewOpen && !forcedStop) {
      const newPagePlayData = {
        action: isPagePlaying ? "play" : "pause",
        pageId: pageNodes[pageIndex].pageId,
        pageIdx: parseInt(pageIndex),
      };
      setPagePlayData({
        ...pagePlayData,
        ...newPagePlayData,
      });
      operateStorageData({
        type: "session",
        action: "set",
        key: PAGE_PLAY_DATA_STORAGE_KEY,
        isParseable: true,
        defaultData: newPagePlayData,
      });
    }
  }, [isPagePlaying, isTimeLineViewOpen, forcedStop]);

  useLayoutEffect(() => {
    if (!isTimeLineViewOpen && pageNodes[whichPagePlaying?.playingPageIdx]?.pageId === pageNodes[pageIndex].pageId) {
      if (["natural_stop"].includes(whichPagePlaying?.action)) {
        setIsPagePlaying(false);
        setForcedStop(true);
        setWhichPagePlaying({});
      } else if (["pause", "force_stop"].includes(whichPagePlaying?.action)) {
        const newPagePlayData = {
          action: whichPagePlaying?.action,
          pageId: pageNodes[pageIndex].pageId,
          pageIdx: parseInt(pageIndex),
        };
        setIsPagePlaying(false);
        setForcedStop(true);
        setPagePlayData({
          ...pagePlayData,
          ...newPagePlayData,
        });
        operateStorageData({
          type: "session",
          action: "set",
          key: PAGE_PLAY_DATA_STORAGE_KEY,
          isParseable: true,
          defaultData: newPagePlayData,
        });
        setWhichPagePlaying({});
      } else if (["play"].includes(whichPagePlaying?.action)) {
        setIsPagePlaying(true);
        setForcedStop(false);
        setWhichPagePlaying({});
      }
    }
  }, [whichPagePlaying?.action]);

  useEffect(() => {
    const pageDuration = parseFloat(pageNodes[pageIndex ?? 0].pageDuration);
    setVideoTimeFormatted(formatTime(pageDuration));
    setInputSeconds(pageDuration);
    timeInputRef.current = pageDuration;

    // check all conditions for page play option keep disable or not
    let isVideoTypePage = checkIsVideoTypePage(pageNodes[pageIndex].pageId, pageIndex, "page-play");
    setIsPlayButtonDisable(isVideoTypePage ? false : true);
  }, []);

  useEffect(() => {
    let { activePage, document } = getDocumentDuration(pageNodes, metadata.activePageIdx);
    let newSecond = Math.min(activePage.available, parseFloat(document.available.toFixed(1)));
    let newMaxSecond = activePage.current + newSecond;
    setMaxSeconds(newMaxSecond);
    setMinSeconds(activePage?.applicable?.min);
  }, []);

  useEffect(() => {
    if (!window.sessionStorage?.getItem("page_Top_Option_Width")) {
      const pageTopOptionWidth = document.getElementById("page-top-option").getBoundingClientRect().width;
      const iconSizes = Array.from(document.querySelectorAll(".page-play-wrap > ul > li"))
        .slice(0, 4)
        .map(list => list.getBoundingClientRect().width);

      window.sessionStorage.setItem("page_Top_Option_Width", JSON.stringify(pageTopOptionWidth));
      window.sessionStorage.setItem("icon_Size_Width", JSON.stringify(iconSizes));
    }
  }, []);

  useEffect(() => {
    let screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    let { right: dropDownRight } = document
      .getElementsByClassName("pageDuration-DropdownMenu")[metadata.activePageIdx]
      .getBoundingClientRect();

    if (dropDownRight + PAGE_DURATION_DROP_DOWN_PADDING > screenWidth) setDynamicStyle(true);
    else setDynamicStyle(false);
  }, [dropdownOpen]);

  useEffect(() => {
    window.addEventListener("scroll", handleDropDown, true);

    return () => window.removeEventListener("scroll", handleDropDown, true);
  }, []);

  return (
    <div className={style["page-play-wrap"]} onMouseDown={() => setActivePage(pageIndex)}>
      <ul
        id="page-top-option"
        className={cx(
          style["d-flex"],
          style["align-items-center"],
          { [style["shift-right"]]: dropDownPositionShift },
          style["justify-content-end"]
        )}>
        <li
          className={cx(
            { [style["d-none"]]: toggleTopOption.firstOption },
            {
              [style["active"]]: pageNodes[pageIndex]?.pageTransition?.enabled,
            },
            { [style["disable"]]: playingPageIdx !== null }
          )}
          onClick={handleTransitionClick}
          id={`page-top-transition-icon-${pageIndex}`}>
          <Icon icon="ui-transition"></Icon>
          <UncontrolledTooltip
            placement="top"
            target={`page-top-transition-icon-${pageIndex}`}
            boundariesElement={document.getElementById("app")}>
            Transition
          </UncontrolledTooltip>
        </li>

        <li
          className={cx(
            { [style["d-none"]]: toggleTopOption.secondOption },
            { [style["disable"]]: playingPageIdx !== null }
          )}
          onClick={() => {
            setIsTimeLineViewOpen("page-timeline");
            document.querySelector(".canvas-controller").style.display = "none";
            if (document.getElementById("active-page-selctor"))
              document.getElementById("active-page-selctor").style.display = "none";

            props.setFitToScreen(true);

            setTimeout(() => {
              updateMetadata({
                ...metadata,
                pageController: {
                  ...metadata.pageController,
                  style: { ...metadata.pageController.style, top: 0 + "px" },
                },
              });
              document.querySelector(".canvas-controller").style.display = "block";
              if (document.getElementById("active-page-selctor"))
                document.getElementById("active-page-selctor").style.display = "block";
            }, 500);
          }}
          id={`page-top-page-timeline-icon-${pageIndex}`}>
          <Icon icon="ui-timeline"></Icon>
          <UncontrolledTooltip
            placement="top"
            target={`page-top-page-timeline-icon-${pageIndex}`}
            boundariesElement={document.getElementById("app")}>
            Page Timeline
          </UncontrolledTooltip>
        </li>

        <li
          className={cx(
            { [style["d-none"]]: toggleTopOption.thirdOption },
            {
              [style["disabled"]]: isPlayButtonDisable,
            },
            { [style["disable"]]: playingPageIdx !== null && playingPageIdx !== parseInt(pageIndex) },
            "page-play-icon"
          )}
          id={`page-top-play-icon-${pageIndex}`}
          onClick={() => togglePagePlay()}>
          <Icon icon={isPagePlaying ? "ui-pause" : "ui-play"} />
          <UncontrolledTooltip
            placement="top"
            target={`page-top-play-icon-${pageIndex}`}
            boundariesElement={document.getElementById("app")}>
            {isPagePlaying ? "Pause" : "Play"}
          </UncontrolledTooltip>
        </li>

        <UncontrolledDropdown
          isOpen={dropdownOpen}
          tag="li"
          className={cx(
            style["duration-drop"],
            { [style["active"]]: dropdownOpen },
            { [style["d-none"]]: toggleTopOption.fourthOption },
            { [style["disable"]]: playingPageIdx !== null }
          )}
          toggle={toggle}>
          <DropdownToggle
            tag="a"
            className={cx(style["dropdown-toggle"])}
            id={`page-top-page-duration-icon-${pageIndex}`}>
            <span>{videoTimeFormatted}</span>
            <UncontrolledTooltip
              placement="top"
              target={`page-top-page-duration-icon-${pageIndex}`}
              boundariesElement={document.getElementById("app")}>
              Page Duration
            </UncontrolledTooltip>
          </DropdownToggle>

          <DropdownMenu
            className={cx(
              style["shadow"],
              style["border-0"],
              style["rounded-sm"],
              { [style["duration-drop-down"]]: !dynamicStyle },
              "pageDuration-DropdownMenu"
            )}>
            <div className={cx(style["d-flex"], style["align-items-end"], style["justify-content-center"])}>
              <Input
                returnType="formGroup"
                type="spinner"
                className="custom-width"
                spinnerClass="input-spinner"
                value={inputSeconds}
                label={"Seconds"}
                max={180}
                min={1}
                onChange={e => handleInputChange(e.currentTarget.value)}
                onDecrease={() => decreaseTime()}
                onIncrease={() => increaseTime()}
              />
              <Button
                type="button"
                color="primary"
                cssModule={style}
                disabled={showApplyButton}
                onClick={() => handleApply()}>
                Apply
              </Button>
            </div>
            {pageNodes.length > 1 && parseInt(pageIndex) === metadata.activePageIdx && (
              <div
                className={cx(
                  style["d-flex"],
                  style["align-items-end"],
                  style["justify-content-center"],
                  style["mt-3"]
                )}>
                {pageDurationApplyOptions.map((pageDurationApplyOption, index) => (
                  <React.Fragment key={index}>
                    <div
                      className={cx(style["custom-radio-list"], {
                        [style["disabled"]]:
                          (pageDurationApplyOption === "All Pages" || pageDurationApplyOption === "All Slides") &&
                          isAllPageDurationButtonDisable,
                      })}>
                      <Input
                        text={pageDurationApplyOption}
                        type="radio"
                        name="durationTypeRadio"
                        className={cx(style["custom-control-input"])}
                        id={`durationTypeRadio-${index}-${metadata.activePageIdx}`}
                        onChange={() => {
                          setPageDurationApplyType(pageDurationApplyOption), setShowApplyButton(false);
                        }}
                        checked={pageDurationApplyType === pageDurationApplyOption}
                      />
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </ul>
    </div>
  );
};

export default PageTopOption;
