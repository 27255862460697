import React from "react";
import cx from "classnames";
import { Row, Col } from "reactstrap";
import global from "../../../scss/dhp.scss";

let style = Object.assign({}, global);

const ColumnLayoutLoader = ({
  layout,
  section,
  col_per_section,
  show_page_label,
  show_section_label,
  show_field_label,
  show_field,
  loader_height,
}) => {
  const col_layout_class = {
    1: "12",
    2: "6",
    3: "4",
    4: "3",
    6: "2",
    12: "1",
  };

  const col_layout_width = {
    1: {
      page: 75,
      section: 50,
      label: 45,
      field: 100,
    },
    2: {
      page: 35,
      section: 30,
      label: 50,
      field: 100,
    },
    3: {
      page: 25,
      section: 20,
      label: 45,
      field: 100,
    },
    4: {
      page: 20,
      section: 15,
      label: 40,
      field: 100,
    },
    6: {
      page: 15,
      section: 10,
      label: 40,
      field: 100,
    },
    12: {
      page: 15,
      section: 10,
      label: 50,
      field: 100,
    },
  };

  const config = {
    layout: parseInt(layout) || 2,
    section: parseInt(section) || 1,
    col_per_section: col_per_section,
    show_page_label: show_page_label || false,
    show_section_label: show_section_label || false,
    show_field_label: show_field_label || false,
    show_field: show_field || false,
    loader_height: loader_height || "auto",
  };

  return (
    <div style={{ height: config.loader_height }}>
      {config.show_page_label && (
        <ul
          className={cx(style["skeleton-loader-area"], style["flex-column"], style["align-items-start"], style["mb-4"])}
          style={{ width: `${col_layout_width[config.layout]?.page}%` }}>
          <li className={cx(style["loader-item"], style["my-2"])}></li>
        </ul>
      )}

      {[...Array(config.section)].map((_, i) => (
        <div key={`loader-layout-section-${i}`} className={style["mb-3"]}>
          {config.show_section_label && (
            <ul
              className={cx(
                style["skeleton-loader-area"],
                style["flex-column"],
                style["align-items-start"],
                style["mb-3"]
              )}
              style={{ width: `${col_layout_width[config.layout]?.section}%` }}>
              <li className={cx(style["loader-item"], style["my-2"])}></li>
            </ul>
          )}

          <Row key={`loader-layout-row-${i}`}>
            {[...Array(config.col_per_section[i + 1] ?? 2)].map((_, i) => (
              <Col key={`loader-layout-col-${i}`} lg={col_layout_class[config.layout]}>
                <ul
                  className={cx(
                    style["skeleton-loader-area"],
                    style["flex-column"],
                    style["align-items-start"],
                    style["mb-3"]
                  )}>
                  {config.show_field_label && (
                    <li
                      className={cx(style["loader-item"], style["my-2"])}
                      style={{ width: `${col_layout_width[config.layout]?.label}%` }}></li>
                  )}

                  {config.show_field && (
                    <li
                      className={cx(style["loader-item"], style["my-2"])}
                      style={{ width: `${col_layout_width[config.layout]?.field}%` }}></li>
                  )}
                </ul>
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </div>
  );
};

export default ColumnLayoutLoader;
