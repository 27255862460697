import { useCallback, useContext } from "react";
import { AppContext } from "../contexts";

export const useContextualUpgrade = () => {
  const { upgradeModalStatus, updateUpgradeModalStatus, updateUpgradeModalData } = useContext(AppContext);

  const showUpgrade = useCallback(
    (upgradeType, widgetUrl, data) => {
      updateUpgradeModalData({ upgradeType, widgetUrl, data });
      updateUpgradeModalStatus(true);
    },
    [upgradeModalStatus]
  );

  // Return show upgrade modal
  return showUpgrade;
};
