import React, { useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import moment from "moment";

import "react-calendar/dist/Calendar.css";
import CustomCalendar from "react-calendar";
import { Icon } from "./icon";
import global from "../../scss/dhp.scss";

let style = Object.assign({}, global);

const Calendar = ({ dateFormat, updateState, id, defaultValue }) => {
  const [value, setValue] = useState("");
  const [calendarValue, setCalendarValue] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);

  const handleFocus = () => setShowCalendar(false);

  const handleDDMMYYYY = (input, formatSeparator) => {
    if (input.length === 1 && parseInt(input, 10) > 3) input = "0" + input;

    if (input.length === 3 && parseInt(input.substring(2, 3), 10) > 1)
      input = input.substring(0, 2) + "0" + input.substring(2);

    if (input.length >= 2) {
      const day = parseInt(input.substring(0, 2), 10);
      let month = parseInt(input.substring(2, 4), 10);

      if (month === 2 && day > 29) input = "29" + `${formatSeparator}` + input.substring(2);
      else if ([4, 6, 9, 11].includes(month) && day > 30) input = "30" + `${formatSeparator}` + input.substring(2);
      else if ([1, 3, 5, 7, 8, 10, 12].includes(month) && day > 31)
        input = "31" + `${formatSeparator}` + input.substring(2);
      else input = ("0" + day).slice(-2) + `${formatSeparator}` + input.substring(2);
    }

    if (input.length >= 5) {
      const month = parseInt(input.substring(3, 5), 10);
      const day = parseInt(input.substring(0, 2), 10);

      if (day > 29 && month === 2) input = "29" + `${formatSeparator}` + input.substring(3);
      const validMonth = month > 12 ? "12" : ("0" + month).slice(-2);
      input = input.substring(0, 3) + validMonth + `${formatSeparator}` + input.substring(5);
    }

    if (input.length >= 10) {
      const day = parseInt(input.substring(0, 2), 10);
      let month = parseInt(input.substring(3, 5), 10);
      let maxDaysInMonth = 31;
      const year = parseInt(input.substring(6, 10), 10);
      const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
      maxDaysInMonth = isLeapYear ? 29 : 28;

      if (month === 2 && day > maxDaysInMonth)
        input = maxDaysInMonth + `${formatSeparator}` + "02" + `${formatSeparator}` + year;

      const currentYear = new Date().getFullYear();
      input = input.substring(0, 6) + (year > currentYear ? currentYear : ("0000" + year).slice(-4));
    }

    return input;
  };

  const handleMMDDYYYY = (input, formatSeparator) => {
    if (input.length === 1 && parseInt(input, 10) > 1) input = "0" + input;

    if (input.length >= 2) {
      let month = parseInt(input.substring(0, 2), 10);
      if (month > 12) month = 12;
      input = ("0" + month).slice(-2) + `${formatSeparator}` + input.substring(2);
    }

    if (input.length === 4) {
      let day = parseInt(input.substring(3, 5), 10);
      if (day > 3) input = input.substring(0, 2) + `${formatSeparator}` + ("0" + day);
    }

    if (input.length >= 5) {
      let day = parseInt(input.substring(3, 5), 10);
      const month = parseInt(input.substring(0, 2), 10);
      const daysInMonth = new Date(new Date().getFullYear(), month, 0).getDate();

      if (day > daysInMonth) day = daysInMonth;
      input = input.substring(0, 3) + ("0" + day).slice(-2) + `${formatSeparator}` + input.substring(5);
    }

    if (input.length >= 10) {
      let day = parseInt(input.substring(3, 5), 10);
      let month = parseInt(input.substring(0, 2), 10);
      let maxDaysInMonth = 31;
      const year = parseInt(input.substring(6, 10), 10);
      const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
      maxDaysInMonth = isLeapYear ? 29 : 28;

      if (month === 2 && day > maxDaysInMonth)
        input = "02" + `${formatSeparator}` + maxDaysInMonth + `${formatSeparator}` + year;

      const currentYear = new Date().getFullYear();
      input = input.substring(0, 6) + (year > currentYear ? currentYear : ("0000" + year).slice(-4));
    }

    return input;
  };

  const isInvalidDate = value => value instanceof Date && isNaN(value.getTime());

  const handleChange = e => {
    let input = e.replace(/\D/g, "");
    const separator = dateFormat.substring(2, 3);
    const dateFormatter = dateFormat.split(separator).join("");

    let newFormat;
    if (dateFormatter === "MMDDYYYY") newFormat = handleMMDDYYYY(input, separator);
    else newFormat = handleDDMMYYYY(input, separator);

    const longFormdate = moment(newFormat, dateFormat).toDate();

    setCalendarValue(isInvalidDate(longFormdate) ? new Date() : longFormdate);
    setValue(newFormat);
  };

  const handleCalendar = () => setShowCalendar(prevState => !prevState);

  const onChange = value => {
    setCalendarValue(value?.value);
    setValue(moment(value?.value).format(dateFormat));
  };

  const handleKeyDown = e => {
    if (e.which === 8) setValue(value.slice(0, value.length - 1));
  };

  useEffect(() => {
    if (defaultValue !== "" && defaultValue)
      setCalendarValue(
        isInvalidDate(moment(defaultValue, dateFormat).toDate())
          ? new Date()
          : moment(defaultValue, dateFormat).toDate()
      );
    setValue(defaultValue);
  }, [defaultValue, id]);

  useEffect(() => {
    updateState(value);
  }, [value]);

  useEffect(() => {
    if (defaultValue !== "" && defaultValue) setCalendarValue(moment(defaultValue, dateFormat).toDate());
    setValue(defaultValue);
  }, []);

  return (
    <>
      <input
        id={id}
        className="form-control"
        type="text"
        pattern="[0-9]"
        placeholder={dateFormat}
        value={value}
        onChange={e => handleChange(e.target.value)}
        onKeyDown={e => handleKeyDown(e)}
        onFocus={() => handleFocus()}
      />
      <div
        className={cx(
          style["d-flex"],
          style["align-items-center"],
          style["justify-content-center"],
          style["calendar-icon"],
          style["p-2"]
        )}
        onClick={() => handleCalendar()}>
        <Icon icon="ui-calendar" />
      </div>
      {showCalendar && (
        <CustomCalendar
          onChange={value =>
            onChange({
              value: value,
            })
          }
          onClickDay={() => handleFocus()}
          value={calendarValue}
        />
      )}
    </>
  );
};

Calendar.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  updateState: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
};

export default Calendar;
