import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Input as RInput } from "reactstrap";
import cx from "classnames";

import { Icon } from "./icon";

import global from "../../scss/dhp.scss";

let style = Object.assign({}, global);

const TagsInput = ({ updateState, ...props }) => {
  const [tags, setTags] = useState([]);

  const removeTags = indexToRemove => {
    let result = tags.filter((_, index) => index !== indexToRemove);
    setTags(result);
    updateState(result);
  };

  const addTags = e => {
    let tag = e.target.value.trim();
    if (tag !== "" && e.which === 13) {
      setTags([...tags, tag]);
      updateState([...tags, tag]);
      e.target.value = "";
    }
  };

  useEffect(() => {
    if (props?.defaultValue && Array.isArray(props?.defaultValue)) setTags(props.defaultValue);
  }, [props?.defaultValue]);

  return (
    <div className={style["tags-input"]}>
      <ul className={style["tags"]}>
        {tags.map((tag, i) => (
          <li key={i} className={style["tag-item"]}>
            <span className={cx(style["tag-title"], style["mr-2"])}>{tag}</span>
            <span className={style["tag-close-icon"]} onClick={() => removeTags(i)}>
              <Icon icon="ui-close" />
            </span>
          </li>
        ))}
      </ul>

      <RInput autoComplete="off" type="text" placeholder={props?.placeholder} onKeyUp={e => addTags(e)} />
    </div>
  );
};

TagsInput.propTypes = {
  updateState: PropTypes.func.isRequired,
  defaultValue: PropTypes.array,
  placeholder: PropTypes.string,
};

export default TagsInput;
