import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Input as RInput, Toast, ToastBody, ToastHeader } from "reactstrap";
import cx from "classnames";
import { useSelector } from "react-redux";
import userAvatar from "../../assets/images/ui-user.svg";

import { Icon } from "./icon";

import global from "../../scss/dhp.scss";

let style = Object.assign({}, global);

const SharedUserThumb = ({ userImg }) => {
  const thumb = {
    src: userImg,
    loading: true,
    default: false,
  };

  const [userThumb, setUserThumb] = useState(thumb);

  const handleImageLoad = () => setUserThumb({ ...userThumb, loading: false });

  const handleImageError = () => setUserThumb({ ...userThumb, src: userAvatar, loading: false, default: true });

  useEffect(() => {
    const image = new Image();
    image.src = thumb.src;
    image.addEventListener("load", handleImageLoad);
    image.addEventListener("error", handleImageError);
    return () => {
      image.removeEventListener("load", handleImageLoad);
      image.removeEventListener("error", handleImageError);
    };
  }, []);

  return (
    <figure>
      {!userThumb.loading && (
        <img
          src={userThumb.src}
          className={cx(style["img-fluid"], {
            [style["default"]]: userThumb.default,
          })}
          alt="User image"
        />
      )}
    </figure>
  );
};

const EmailRecipient = ({
  updateState,
  defaultValue,
  handleEmailCount,
  userNotify,
  clickedUpgrade,
  suggestionBoxWidth,
  ...props
}) => {
  const [tags, setTags] = useState([]);
  const [newLeft, setNewLeft] = useState();
  const [blur, setBlur] = useState(false);
  const [searchedUser, setSearchedUser] = useState([]);
  const [emailCount, setEmailCount] = useState(0);
  const [addRecipients, setAddRecipients] = useState();
  const [deleteClick, setDeleteClick] = useState(false);
  const [focus, setFocus] = useState(null);
  const validUser = useRef();
  const isUserPresent = useRef();

  const { users: allUsers } = useSelector(state => state?.company);

  const filterUser = () => {
    validUser.current = allUsers?.filter(user => user.status === "JOINED");
  };

  const checkDuplicateEmail = email => [...tags].includes(email);

  const isEmailValid = text => {
    const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    return text.match(emailRegex);
  };

  const handleMouseDown = () => {
    setDeleteClick(true);
  };

  const handleBlur = () => {
    if (!deleteClick && !clickedUpgrade) {
      let inputBox = document.getElementById(props?.id);
      if (tags.length > 0) {
        inputBox.value = tags[0];
        setEmailCount(tags.length - 1);
        setTags([]);
      } else if (tags.length === 0 && defaultValue?.length === 0) inputBox.value = "";
      setBlur(true);
      setSearchedUser([]);
      setAddRecipients([]);
      if (userNotify) handleEmailCount(false);
    }
  };

  const handleFocus = id => {
    setFocus(id);
    if (blur && defaultValue?.length > 0) {
      let inputBox2 = document.getElementById(props?.id);
      setBlur(false);
      setTags(defaultValue);
      inputBox2.value = "";
    } else if (tags.length > 0 && !deleteClick) {
      setBlur(false);
      setTags(defaultValue);
    }
  };

  const showMarkerPosition = e => {
    const { currentTarget: input } = e;
    const inputValue = e.target.value.trim();
    const { offsetLeft, offsetWidth } = input;

    if (userNotify) {
      if (inputValue.length > 0) handleEmailCount(true);
      else handleEmailCount(false);
    }

    if (inputValue.length > 0) {
      if (!validUser?.current) filterUser();
      const users = validUser?.current?.filter(user => {
        return user.email.toLowerCase().includes(inputValue) && !checkDuplicateEmail(user.email);
      });
      isUserPresent.current = users?.length === 0 ? false : true;
      setSearchedUser(!userNotify ? users ?? [] : []);

      const inputBoundaryRight = offsetLeft + offsetWidth;
      if (offsetWidth < suggestionBoxWidth) setNewLeft(inputBoundaryRight - suggestionBoxWidth);
      else setNewLeft(offsetLeft);
    }

    if (!isUserPresent?.current && !userNotify && isEmailValid(inputValue) && !checkDuplicateEmail(inputValue)) {
      const newUserRecipients = { id: new Date().getTime().toString(), name: "Add Recipient", email: inputValue };
      setAddRecipients([newUserRecipients]);
    } else setAddRecipients([]);

    if (inputValue.length === 0) {
      setSearchedUser([]);
      setNewLeft(offsetLeft);
    }
  };

  const addTags = e => {
    let tag = e.target.value.trim();
    if (tag !== "" && e.which === 13 && isEmailValid(tag) && !checkDuplicateEmail(tag)) {
      if (userNotify) {
        setAddRecipients([]);
        return;
      }
      setTags([...tags, tag]);
      updateState([...tags, tag]);
      setAddRecipients([]);
      e.target.value = "";
    }
  };

  const removeTags = indexToRemove => {
    if (deleteClick) {
      let result = tags.filter((_, index) => index !== indexToRemove);
      setTags(result);
      updateState(result);
      setEmailCount(result.length - 1);
      if (addRecipients?.length > 0) setAddRecipients([]);
      if (searchedUser?.length > 0) setSearchedUser([]);
      let inputBox = document.getElementById(focus);
      inputBox.focus();
      setDeleteClick(false);
    }
  };

  const handleSuggestion = email => {
    if (!checkDuplicateEmail(email) && deleteClick) {
      let inputBox = document.getElementById(focus);
      setSearchedUser([]);
      setAddRecipients([]);
      if (userNotify) {
        inputBox.value = email;
        inputBox.focus();
        setDeleteClick(false);
        return;
      }
      inputBox.value = "";
      inputBox.focus();
      setTags([...tags, email]);
      updateState([...tags, email]);
      setDeleteClick(false);
    }
  };

  useEffect(() => {
    if (!clickedUpgrade && userNotify) handleBlur();
  }, [clickedUpgrade]);

  useEffect(() => {
    if (defaultValue?.length > 0 && Array.isArray(defaultValue)) {
      let inputBox = document.getElementById(props?.id);
      inputBox.value = defaultValue[0];
      if (defaultValue?.length > 1) setEmailCount(defaultValue?.length - 1);
      setBlur(true);
      inputBox.blur();
    }
  }, []);

  return (
    <>
      <div className={style["tags-input"]}>
        <ul className={style["tags"]}>
          {tags?.map((tag, i) => (
            <li key={i} className={style["tag-item"]}>
              <span className={cx(style["tag-title"], style["mr-2"])}>{tag}</span>
              <span
                className={style["tag-close-icon"]}
                onClick={() => removeTags(i)}
                onMouseDown={() => handleMouseDown()}>
                <Icon icon="ui-close" />
              </span>
            </li>
          ))}
        </ul>

        <RInput
          autoComplete="off"
          type="text"
          onFocus={() => handleFocus(props?.id)}
          onBlur={() => handleBlur()}
          onKeyUp={e => addTags(e)}
          onChange={e => showMarkerPosition(e)}
          style={{ width: props?.width }}
          {...props}
        />
        {blur && emailCount > 0 && <span className={style["email-count"]}>{`+${emailCount}`}</span>}
      </div>
      <div>
        {searchedUser?.length > 0 && (
          <Toast
            id={"suggestionModal"}
            className={cx(style["border-0"], style["shadow"], style["rounded"])}
            style={{ left: `${newLeft}px` }}>
            <ToastHeader>Users</ToastHeader>
            <ToastBody className={cx(style["p-0"])}>
              <ul className={style["email-list"]}>
                {searchedUser?.map(userDetails => (
                  <li
                    key={userDetails?.id}
                    onClick={() => handleSuggestion(userDetails?.email)}
                    onMouseDown={() => handleMouseDown()}>
                    <div className={cx(style["d-flex"])}>
                      <SharedUserThumb userImg={userDetails?.image} />
                      <div className={cx(style["d-flex"], style["flex-column"], style["ml-3"], style["user-info"])}>
                        <h6
                          className={cx(
                            style["m-0"],
                            style["text-truncate"]
                          )}>{`${userDetails?.firstname} ${userDetails?.lastname}`}</h6>
                        <div className={cx(style["text-muted"], style["text-truncate"], style["font-13"])}>
                          {userDetails?.email}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </ToastBody>
          </Toast>
        )}
        {addRecipients?.length > 0 && (
          <Toast className={cx(style["border-0"], style["shadow"], style["rounded"])} style={{ left: `${newLeft}px` }}>
            <ToastBody className={cx(style["p-0"])}>
              <ul className={style["email-list"]}>
                {addRecipients?.map((userDetails, id) => (
                  <li
                    key={id}
                    onClick={() => handleSuggestion(userDetails?.email)}
                    onMouseDown={() => handleMouseDown()}>
                    <div className={cx(style["d-flex"])}>
                      <figure>
                        <img src={userAvatar} alt="User Img" />
                      </figure>
                      <div className={cx(style["d-flex"], style["flex-column"], style["ml-3"], style["user-info"])}>
                        <h6 className={cx(style["m-0"], style["text-truncate"])}>{userDetails?.name}</h6>
                        <div className={cx(style["text-muted"], style["text-truncate"])}>{userDetails?.email}</div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </ToastBody>
          </Toast>
        )}
      </div>
    </>
  );
};

EmailRecipient.propTypes = {
  updateState: PropTypes.func.isRequired,
  defaultValue: PropTypes.array,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  handleEmailCount: PropTypes.func,
  userNotify: PropTypes.bool,
  clickedUpgrade: PropTypes.bool,
  suggestionBoxWidth: PropTypes.number.isRequired,
  width: PropTypes.string,
};

SharedUserThumb.propTypes = {
  userImg: PropTypes.string,
};

export default EmailRecipient;
