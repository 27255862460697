// Company User
export const NEW_USER = "New User";
export const USER_ALREADY_JOINED = "User already joined";
export const USER_JOINED = "User joined";
// COMPANY STATUS
export const COMPANY_STATUS_DEACTIVATED = "DEACTIVATED";
export const COMPANY_SUPERADMIN = "owner";

// create or update company
export const CREATE_COMPANY = "New Company Information";
export const UPDATE_COMPANY = "Update Company Information";

// ListView minWidth
export const LIST_MIN_WIDTH = "150px";
