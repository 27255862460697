import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import cx from "classnames";
import PropTypes from "prop-types";

import { Input } from "../../../ui/input";
import { Icon } from "../../../ui/icon";
import global from "../../../../scss/dhp.scss";
import { EditorContext } from "../../../../containers/editor/EditorLayout";
import { PICTURE } from "../../../../constants/editor";

let style = Object.assign({}, global);

const AssetCategory = props => {
  const defaultCategory = { name: "All" };
  const [categories, setCategories] = useState([defaultCategory]);
  const [defaultValue, setDefaultValue] = useState(defaultCategory);
  const [isGroupInCategory, setIsGroupInCategory] = useState();
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { categories: availableAssetCategories, loading } = useSelector(state => state.editor);

  // Update asset category
  const updateCategory = option => {
    if (!isGroupInCategory) setDefaultValue({ name: option });
    else setDefaultValue(option === "" ? "All" : option);

    props.setCategory(option === "All" ? "" : option);
    props.setPageNumber(1);
    // props.setIsAssetFetched && props.setIsAssetFetched(false);

    if (props.setKeyword) {
      props.setKeyword(!props.staticCategory ? "" : option === "All" ? "" : option);
      document.getElementById("assetSearch").value = null;
    }
  };

  const toggleCategoryTypeDropdown = () => {
    setDropdownOpen(prevState => !prevState);

    if (dropdownOpen) {
      setSearchKeyWord("");
      return;
    }

    // set dropdown position on selected item
    setTimeout(() => {
      const currentDropdown = document.querySelectorAll(".dropdown-menu-right.show")[0];
      if (currentDropdown) {
        const selectedEl = currentDropdown.querySelectorAll(".active.dropdown-item")[0];
        if (selectedEl) {
          selectedEl.scrollIntoView({ block: "nearest", inline: "start" });
        }
      }
    }, 15);
  };

  const loadCategoryList = () => {
    const source = props.staticCategory ?? availableAssetCategories ?? [];

    // Category which has group name
    if (source[0]?.group) {
      setCategories([{ group: "All" }, ...source]);
      setIsGroupInCategory(true);
      if (props.category === "") setDefaultValue("All");
    }
    // Category which dose not have any group name
    else {
      const options = source.map(c => ({ name: c }));
      setCategories([defaultCategory, ...options]);
      setIsGroupInCategory(false);
      if (props.category === "") setDefaultValue({ name: "All" });
    }
  };

  useEffect(() => {
    const source = props.staticCategory ?? availableAssetCategories ?? [];

    if (!isGroupInCategory && source && !source[0]?.group)
      setDefaultValue({ name: props.category == "" ? "All" : props.category });
    else setDefaultValue(props.category == "" ? "All" : props.category);

    props.setIsAssetFetched && props.setIsAssetFetched(false);
    setSearchKeyWord("");
  }, [props.category]);

  useEffect(() => {
    if (!loading && props.assetType === PICTURE) loadCategoryList();
  }, [availableAssetCategories, loading]);

  useEffect(() => {
    if (props.assetType !== PICTURE) loadCategoryList();
  }, [availableAssetCategories]);

  useEffect(() => {
    if (availableAssetCategories) {
      const newList = availableAssetCategories
        ?.map(category => ({
          ...category,
          options: category.options?.filter(option => option.name.toLowerCase().includes(searchKeyWord.toLowerCase())),
        }))
        .filter(category => category?.options?.length > 0);

      if (searchKeyWord === "") setCategories([{ group: "All" }, ...newList]);
      else setCategories(newList);
    }
  }, [searchKeyWord]);

  return (
    <>
      {!isGroupInCategory && (
        <Input
          type="dropdown"
          name="category"
          classNamePrefix="select"
          defaultValue={defaultValue}
          selected={props.category}
          getOptionValue={option => option.name}
          getOptionLabel={option => <span>{option.name}</span>}
          options={categories}
          updateState={option => updateCategory(option.name)}
          noOptionsMessage={() => "No results found"}
        />
      )}

      {isGroupInCategory && (
        <UncontrolledDropdown
          className={cx(style["border-style"], style["category-by-group"])}
          isOpen={dropdownOpen}
          toggle={toggleCategoryTypeDropdown}>
          <DropdownToggle>
            {defaultValue}
            <Icon icon="ui-arrow-down" />
          </DropdownToggle>
          <DropdownMenu right className={cx(style["shadow-sm"], style["border-0"], style["rounded"])}>
            <div className={cx(style["search"], style["px-3"], style["pt-2"])}>
              <Input
                cssModule={style}
                returnType="inputGroup"
                addonType="prepend"
                type="search"
                id="search-category"
                name="search-category"
                onChange={e => setSearchKeyWord(e.target.value.trim())}
                autoFocus={true}
              />
            </div>

            <div className={cx(style["customScroll"], style["scroll-Y"])}>
              {categories.length === 0 && <div className={cx(style["not-found"])}>No results found</div>}

              {categories.length > 0 &&
                categories?.map((category, i) => (
                  <React.Fragment key={i}>
                    {category.group && (
                      <div className={style["mb-2"]} key={i}>
                        {category.group === "All" && (
                          <DropdownItem
                            className={cx({
                              [style["active"]]: "" === props.category,
                            })}
                            onClick={() => updateCategory("")}>
                            {category.group}
                          </DropdownItem>
                        )}
                        {category.group !== "All" && <DropdownItem header>{category.group}</DropdownItem>}

                        {category.options?.map((option, idx) => (
                          <DropdownItem
                            tag="a"
                            key={idx}
                            className={cx({
                              [style["active"]]: option.name === props.category,
                            })}
                            onClick={() => updateCategory(option.name)}>
                            {option.name}
                          </DropdownItem>
                        ))}
                      </div>
                    )}
                  </React.Fragment>
                ))}
            </div>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </>
  );
};

//Props type validation
AssetCategory.propTypes = {
  setCategory: PropTypes.func.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  setKeyword: PropTypes.func,
  category: PropTypes.string,
  staticCategory: PropTypes.array,
  setIsAssetFetched: PropTypes.func,
};
export default AssetCategory;
