import { useContext } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";
import useSetDimension from "./useSetDimension";
import { widgetConfig } from "../components/Editor/editor_config";
import * as constant from "../constants/editor";
import { getHMSToSec, getSecToHMS } from "../_helpers/utils";

const useUpdatePageDuration = () => {
  let { metadata, blockNodes, widgets, updateWidgets, pageNodes, audios } = useContext(EditorContext);

  const { setAudioDurationBasedOnPageDuration } = useSetDimension();

  const transitionSpeedUpdateBasedOnPageDuration = (newDuration, Pindex) => {
    let activePageDuration = newDuration
      ? parseFloat(newDuration)
      : parseFloat(pageNodes[Pindex ? Pindex : metadata.activePageIdx].pageDuration);
    let index = widgetConfig.page_transition.speeds.findIndex(
      speed => speed.name === pageNodes[Pindex ? Pindex : metadata.activePageIdx].pageTransition.speed
    );
    let activePageTransitionSpeedValue = parseFloat(widgetConfig.page_transition.speeds[index].value);
    let activePageTransitionSpeed;

    // Check page duration and transition speed relationship
    if (activePageTransitionSpeedValue >= activePageDuration) {
      activePageTransitionSpeed = activePageDuration > 1.0 ? "Medium" : "Fast";
    } else {
      activePageTransitionSpeed = pageNodes[Pindex ? Pindex : metadata.activePageIdx].pageTransition.speed;
    }

    return activePageTransitionSpeed;
  };

  const widgetAnimationSpeedUpdateBasedOnWidgetDuration = (targetWidget, newDuration) => {
    let newEntraceAnimationSpeed, newExitAnimationSpeed;

    // calculate target widget's entrance animation speed
    if (targetWidget.data["data-entrance-animation"]) {
      let WidgetEntranceAnimationSpeed = targetWidget.data["data-entrance-animation-speed"];
      let WidgetEntranceAnimationSpeedValue = parseFloat(
        widgetConfig.widget_animation.speeds[
          widgetConfig.widget_animation.speeds.findIndex(speed => speed.name === WidgetEntranceAnimationSpeed)
        ].value
      );

      // if widget enrance animation spped is greater than new duration update entrance animation speed
      if (WidgetEntranceAnimationSpeedValue >= parseFloat(newDuration)) {
        newEntraceAnimationSpeed = parseFloat(newDuration) === 2 ? "Medium" : "Fast";
      }
      // if not kept previous one
      else {
        newEntraceAnimationSpeed = WidgetEntranceAnimationSpeed;
      }
    }
    // calculate target widget's exit animation speed
    if (targetWidget.data["data-exit-animation"]) {
      let WidgetExitAnimationSpeed = targetWidget.data["data-exit-animation-speed"];
      let WidgetExitAnimationSpeedValue = parseFloat(
        widgetConfig.widget_animation.speeds[
          widgetConfig.widget_animation.speeds.findIndex(speed => speed.name === WidgetExitAnimationSpeed)
        ].value
      );

      // 1st check widget has entrace animation applied or not, if applied exit animation speed will be <= (new-duration - entanceanimation speed)
      if (targetWidget.data["data-entrance-animation"]) {
        let newEntraceAnimationSpeedValue = parseFloat(
          widgetConfig.widget_animation.speeds[
            widgetConfig.widget_animation.speeds.findIndex(speed => speed.name === newEntraceAnimationSpeed)
          ].value
        );
        let targetValue = parseFloat(newDuration) - newEntraceAnimationSpeedValue;

        // if exit animation speed is greater than (new-duration - entanceanimation speed) update exit animation speed
        if (WidgetExitAnimationSpeedValue > targetValue) {
          newExitAnimationSpeed = targetValue === 2 ? "Slow" : targetValue === 1 ? "Medium" : "Fast";
        }
        // else kept previous one
        else {
          newExitAnimationSpeed = WidgetExitAnimationSpeed;
        }
      }
      // if not exit animation speed will be less than new duration
      else {
        // if exit animation speed is greater than new duration update exit animation speed
        if (WidgetExitAnimationSpeedValue >= parseFloat(newDuration)) {
          newExitAnimationSpeed = parseFloat(newDuration) === 2 ? "Medium" : "Fast";
        }
        // else kept previous one
        else {
          newExitAnimationSpeed = WidgetExitAnimationSpeed;
        }
      }
    }

    return { newEntraceAnimationSpeed, newExitAnimationSpeed };
  };

  const setWidgetDurationBasedOnPageDuration = (newDuration, index, applyType) => {
    let targetBlock = index ? blockNodes[index].blockId : metadata.activeBlockId;
    let activeBlockWidgetList =
      applyType && (applyType === "All Pages" || applyType === "All Slides")
        ? widgets
        : widgets.filter(widget => widget.blockId === targetBlock);
    let newArray = Object.assign([...widgets]);

    activeBlockWidgetList.forEach(targetWidget => {
      let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetWidget.id);
      let pageIdx = pageNodes.findIndex(pageNode => pageNode.pageId === widgets[targetWidgetIndex].pageId);
      let pageDuration = pageNodes[pageIdx].pageDuration;
      let WidgetDurationEndTime = widgets[targetWidgetIndex].duration?.endTime
        ? widgets[targetWidgetIndex].duration.endTime
        : pageDuration;
      let widgetDurationStartTime = widgets[targetWidgetIndex].duration?.startTime
        ? widgets[targetWidgetIndex].duration.startTime
        : 0;

      // upadte widget duration end time if widget duration end time and page duration time is equal
      if (parseFloat(pageDuration) === parseFloat(WidgetDurationEndTime)) {
        // calculate end time for video type widget
        if (
          targetWidget.data["data-asset-type"] === constant.VIDEO &&
          [constant.STOCK, constant.BRAND, constant.UPLOAD_VIDEO].includes(targetWidget.data["data-source"])
        ) {
          let activeVideoDuration = getHMSToSec({ hms: widgets[targetWidgetIndex].data["data-duration"] });

          // if page dutaion is increased
          if (parseFloat(newDuration) > parseFloat(pageDuration)) {
            // if video is in loop then increase video end time (repeat trimmed part in loop till end time)
            if (targetWidget.data["data-loop"]) {
              // When update the page duration check if any widget has applied widget animation and if their entrance or exit speed is greater than new widget duration update that widget animation speed accordingly
              let { newEntraceAnimationSpeed, newExitAnimationSpeed } = widgetAnimationSpeedUpdateBasedOnWidgetDuration(
                targetWidget,
                parseFloat(newDuration) - parseFloat(widgetDurationStartTime)
              );

              newArray = Object.assign([...newArray], {
                [targetWidgetIndex]: {
                  ...newArray[targetWidgetIndex],
                  duration: {
                    ...newArray[targetWidgetIndex].duration,
                    endTime: `${newDuration}`,
                    totalTime: `${(parseFloat(newDuration) - parseFloat(widgetDurationStartTime)).toFixed(1)}s`,
                  },
                  data: {
                    ...newArray[targetWidgetIndex].data,
                    "data-entrance-animation-speed": newEntraceAnimationSpeed,
                    "data-exit-animation-speed": newExitAnimationSpeed,
                  },
                },
              });
            }
            // else trimmed video till its original duration reach after that stop increasing video end time
            else if (
              !targetWidget.data["data-loop"] &&
              widgets[targetWidgetIndex].data["data-trimmed-duration"] !== ""
            ) {
              let activeVideoTrimmedDuration = getHMSToSec({
                hms: widgets[targetWidgetIndex].data["data-trimmed-duration"],
              });
              let activeVideoTrimmedStartPoint = parseFloat(widgets[targetWidgetIndex].data["data-trim"].split(",")[0]);
              let activeVideoTrimmedEndPoint = parseFloat(newArray[targetWidgetIndex].data["data-trim"].split(",")[1]);
              let videoRestExtendTime = parseFloat(activeVideoDuration) - activeVideoTrimmedEndPoint;
              let videoTargetLengthTime = parseFloat(activeVideoTrimmedDuration) + videoRestExtendTime;
              let newVideoEndTime =
                parseFloat(newDuration) > videoTargetLengthTime ? videoTargetLengthTime : parseFloat(newDuration);

              // When update the page duration check if any widget has applied widget animation and if their entrance or exit speed is greater than new widget duration update that widget animation speed accordingly
              let { newEntraceAnimationSpeed, newExitAnimationSpeed } = widgetAnimationSpeedUpdateBasedOnWidgetDuration(
                targetWidget,
                parseFloat(newVideoEndTime) - parseFloat(widgetDurationStartTime)
              );

              newArray = Object.assign([...newArray], {
                [targetWidgetIndex]: {
                  ...newArray[targetWidgetIndex],
                  duration: {
                    ...newArray[targetWidgetIndex].duration,
                    endTime: `${newVideoEndTime}s`,
                    totalTime: `${(parseFloat(newVideoEndTime) - parseFloat(widgetDurationStartTime)).toFixed(1)}s`,
                  },
                  data: {
                    ...newArray[targetWidgetIndex].data,
                    "data-trimmed-duration": getSecToHMS({ sec: newVideoEndTime }),
                    "data-trim": `${activeVideoTrimmedStartPoint},${
                      activeVideoTrimmedEndPoint + (parseFloat(newDuration) - parseFloat(pageDuration)) <=
                      parseFloat(activeVideoDuration)
                        ? activeVideoTrimmedEndPoint + (parseFloat(newDuration) - parseFloat(pageDuration))
                        : parseFloat(activeVideoDuration)
                    }`,
                    "data-entrance-animation-speed": newEntraceAnimationSpeed,
                    "data-exit-animation-speed": newExitAnimationSpeed,
                  },
                },
              });
            }
          }

          // if page duration time decrease
          if (parseFloat(newDuration) < parseFloat(pageDuration)) {
            // if video is in loop and trimmed then on decrease video duration end point will decrease till trimmed part not react after that video duration end point will not change and page duration line will ovarlap
            if (targetWidget.data["data-loop"] && widgets[targetWidgetIndex].data["data-trimmed-duration"] !== "") {
              let activeVideoTrimmedDuration = getHMSToSec({
                hms: widgets[targetWidgetIndex].data["data-trimmed-duration"],
              });
              let newVideoEndTime =
                parseFloat(newDuration) < activeVideoTrimmedDuration
                  ? parseFloat(activeVideoTrimmedDuration)
                  : parseFloat(newDuration);

              // When update the page duration check if any widget has applied widget animation and if their entrance or exit speed is greater than new widget duration update that widget animation speed accordingly
              let { newEntraceAnimationSpeed, newExitAnimationSpeed } = widgetAnimationSpeedUpdateBasedOnWidgetDuration(
                targetWidget,
                parseFloat(newVideoEndTime) - parseFloat(widgetDurationStartTime)
              );

              newArray = Object.assign([...newArray], {
                [targetWidgetIndex]: {
                  ...newArray[targetWidgetIndex],
                  duration: {
                    ...newArray[targetWidgetIndex].duration,
                    endTime: `${newVideoEndTime}s`,
                    totalTime: `${(parseFloat(newVideoEndTime) - parseFloat(widgetDurationStartTime)).toFixed(1)}s`,
                  },
                  data: {
                    ...newArray[targetWidgetIndex].data,
                    "data-entrance-animation-speed": newEntraceAnimationSpeed,
                    "data-exit-animation-speed": newExitAnimationSpeed,
                  },
                },
              });
            }
            // if video is not in loop or loop but not trimmed then video end point will trim till 1 sec
            else {
              let activeVideoTrimmedStartPoint = widgets[targetWidgetIndex].data["data-trim"]
                ? parseFloat(widgets[targetWidgetIndex].data["data-trim"].split(",")[0])
                : 0;
              let activeVideoTrimmedEndPoint = widgets[targetWidgetIndex].data["data-trim"]
                ? parseFloat(widgets[targetWidgetIndex].data["data-trim"].split(",")[1])
                : parseFloat(getHMSToSec({ hms: widgets[targetWidgetIndex].data["data-duration"] }));

              // When update the page duration check if any widget has applied widget animation and if their entrance or exit speed is greater than new widget duration update that widget animation speed accordingly
              let { newEntraceAnimationSpeed, newExitAnimationSpeed } = widgetAnimationSpeedUpdateBasedOnWidgetDuration(
                targetWidget,
                parseFloat(newDuration) - parseFloat(widgetDurationStartTime)
              );

              newArray = Object.assign([...newArray], {
                [targetWidgetIndex]: {
                  ...newArray[targetWidgetIndex],
                  duration: {
                    ...newArray[targetWidgetIndex].duration,
                    endTime: `${newDuration}`,
                    totalTime: `${(parseFloat(newDuration) - parseFloat(widgetDurationStartTime)).toFixed(1)}s`,
                  },
                  data: {
                    ...newArray[targetWidgetIndex].data,
                    "data-trimmed-duration": getSecToHMS({ sec: newDuration }),
                    "data-trim": `${activeVideoTrimmedStartPoint},${
                      activeVideoTrimmedEndPoint - (parseFloat(pageDuration) - parseFloat(newDuration))
                    }`,
                    "data-entrance-animation-speed": newEntraceAnimationSpeed,
                    "data-exit-animation-speed": newExitAnimationSpeed,
                  },
                },
              });
            }
          }
        }
        // calculate end time for other type widget
        else {
          let newTotalTime = parseFloat(newDuration) - parseFloat(widgetDurationStartTime);
          let newEndTime = parseFloat(newDuration);

          if (newTotalTime < 1) {
            newTotalTime = 1;
            newEndTime = parseFloat(widgetDurationStartTime) + 1;
          }

          // When update the page duration check if any widget has applied widget animation and if their entrance or exit speed is greater than new widget duration update that widget animation speed accordingly
          let { newEntraceAnimationSpeed, newExitAnimationSpeed } = widgetAnimationSpeedUpdateBasedOnWidgetDuration(
            targetWidget,
            newTotalTime
          );

          newArray = Object.assign([...newArray], {
            [targetWidgetIndex]: {
              ...newArray[targetWidgetIndex],
              duration: {
                ...newArray[targetWidgetIndex].duration,
                endTime: `${newEndTime}s`,
                totalTime: `${newTotalTime.toFixed(1)}s`,
              },
              data: {
                ...newArray[targetWidgetIndex].data,
                "data-entrance-animation-speed": newEntraceAnimationSpeed,
                "data-exit-animation-speed": newExitAnimationSpeed,
              },
            },
          });
        }
      }
    });

    return newArray;
  };

  const updatePageDuration = (inputSeconds, index, applyType) => {
    inputSeconds = inputSeconds.toString();

    // apply page duration in all pages
    if (applyType && (applyType === "All Pages" || applyType === "All Slides")) {
      let newPageArray = [];

      pageNodes.map((pageNode, index) => {
        let activePageTransitionSpeed = transitionSpeedUpdateBasedOnPageDuration(inputSeconds, index);

        let updatedPageNode = {
          ...pageNode,
          pageDuration: inputSeconds,
          pageTransition: {
            ...pageNodes[index].pageTransition,
            speed: activePageTransitionSpeed,
          },
        };

        newPageArray.push(updatedPageNode);
      });

      let newWidgetArray = setWidgetDurationBasedOnPageDuration(inputSeconds, index, applyType);

      //audios which have same end time like doc duration, update thet audio's end time also as page duration time update
      if (audios?.length > 0) {
        let newAudioArray = setAudioDurationBasedOnPageDuration(
          parseFloat(inputSeconds) * pageNodes.length,
          "change-duration",
          index,
          applyType
        );
        updateWidgets(
          newWidgetArray,
          newPageArray,
          blockNodes,
          false,
          false,
          false,
          false,
          false,
          false,
          newAudioArray
        );
      } else updateWidgets(newWidgetArray, newPageArray, blockNodes);
    }
    // apply page duration in active page only
    else {
      let targetIndex = index ? index : metadata.activePageIdx;

      let activePageTransitionSpeed = transitionSpeedUpdateBasedOnPageDuration(inputSeconds, index);

      let newPageArray = Object.assign([...pageNodes], {
        [targetIndex]: {
          ...pageNodes[targetIndex],
          pageDuration: inputSeconds,
          pageTransition: {
            ...pageNodes[targetIndex].pageTransition,
            speed: activePageTransitionSpeed,
          },
        },
      });

      let newWidgetArray = setWidgetDurationBasedOnPageDuration(inputSeconds, index);

      //audios which have same end time like doc duration, update thet audio's end time also as page duration time update
      if (audios?.length > 0) {
        let newAudioArray = setAudioDurationBasedOnPageDuration(inputSeconds, "change-duration", index);
        updateWidgets(
          newWidgetArray,
          newPageArray,
          blockNodes,
          false,
          false,
          false,
          false,
          false,
          false,
          newAudioArray
        );
      } else updateWidgets(newWidgetArray, newPageArray, blockNodes);
    }
  };

  return {
    updatePageDuration,
    setWidgetDurationBasedOnPageDuration,
    widgetAnimationSpeedUpdateBasedOnWidgetDuration,
    transitionSpeedUpdateBasedOnPageDuration,
  };
};

export default useUpdatePageDuration;
