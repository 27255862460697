import { useContext, useEffect, useState } from "react";
import { BASIC, DECORATED } from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";
import { calculateArrowDimensions, calculateViewBoxDimensions, drawSvgLinePattern, getCssTransformObj, getZoomedValue } from "../_helpers/utils";
import UseCheckWidgetAllignment from "./UseCheckWidgetAllignment";

const useResizeLineWidget = () => {
  let { metadata, widgets, updateWidgets, dimension } = useContext(EditorContext);

  let targetWidgetIndex = widgets.findIndex(widget => widget.id === metadata.activeWidgetId[0]);

  const [resizeLineWidget, setResizeLineWidget] = useState(false);
  const { checkWidgetAllignmentForSingleWidget } = UseCheckWidgetAllignment();

  const ResizeSvg = ({ lineThickness, contextMenu, updateContext, widgetWidth, widgetLeft, widgetTop, meta }) => {
    let viewBox = false,
      arrowRightDimension = false;
    let widgetObj = document.getElementById(metadata.activeWidgetId[0]);
    let targetElem = document.querySelector(`#${metadata.activeWidgetId[0]} svg`);
    let dataCategory = widgets[targetWidgetIndex].data["data-category"];
    let viewBoxHeightFactor = targetElem.getAttribute("data-viewbox-height-factor");
    let applicableHeight = viewBoxHeightFactor ? lineThickness * eval(viewBoxHeightFactor) : lineThickness;
    widgetWidth = widgetWidth ? `${widgetWidth}px` : widgets[targetWidgetIndex].style.width;

    targetElem.setAttribute("data-linethickness", lineThickness);

    if (dataCategory === BASIC && targetElem.getAttribute("arrow-head-direction") !== "none") {
      arrowRightDimension = calculateArrowDimensions(metadata.activeWidgetId[0]);
      viewBox = calculateViewBoxDimensions(widgetObj);
    }

    // =======  START: SVG manipulation   ======= //

    // Set linethickness in svg onject and Draw svg line patterns
    if (dataCategory === DECORATED || (dataCategory === BASIC && targetElem.getAttribute("line-type") !== "solid")) {
      drawSvgLinePattern(widgetObj, targetElem, lineThickness, widgetWidth, metadata.activeWidgetId[0]);
    }

    // Set widget & handler height if Y-resize i.e. from toolbar
    if (contextMenu) {
      let viewBoxHeightFactor = targetElem.getAttribute("data-viewbox-height-factor");
      let applicableHeight = viewBoxHeightFactor ? lineThickness * eval(viewBoxHeightFactor) : lineThickness;

      widgetObj.style.height = `${applicableHeight}px`;
      document.getElementById("dhp-widget-handler").style.height = `${getZoomedValue(
        applicableHeight,
        dimension.zoom
      )}px`;
    }

    // Apply height, width and scale on svg as per resizing
    // for decorated lines and basic lines without arrow heads (solid/dotted/dashed)
    if (
      dataCategory === DECORATED ||
      (dataCategory === BASIC && targetElem.getAttribute("arrow-head-direction") === "none")
    ) {
      document.querySelectorAll(`#${metadata.activeWidgetId[0]} svg *`).forEach(function (node) {
        if (node.getAttribute("dh-asset-properties")) {
          let thisChildNodeAttr = node.getAttribute("dh-asset-properties").replace("{", "").replace("}", "").split(",");

          thisChildNodeAttr.forEach(prop => {
            if (prop === "height") {
              if (dataCategory === "Basic") {
                if (targetElem.getAttribute("line-type") && targetElem.getAttribute("line-type") === "solid") {
                  node.setAttribute("height", lineThickness + "px");
                } else {
                  let applicableHeight = lineThickness < 5 ? 5 : lineThickness;
                  node.setAttribute("height", applicableHeight + "px");
                }
              } else if (dataCategory === "Decorated") {
                let applicableHeight = lineThickness < 20 ? 20 : lineThickness;
                node.setAttribute("height", applicableHeight + "px");
              }
            }
            if (prop === "width") {
              node.setAttribute("width", widgetWidth);
            }
            if (prop === "transform-scale") {
              let scaleFactor = eval(node.getAttribute("data-transform-scale-factor"));
              node.setAttribute("transform", `scale(${lineThickness * scaleFactor})`);
            }
          });
        }
      });

      targetElem.setAttribute("viewBox", `0 0 ${parseFloat(widgetWidth)} ${lineThickness}`);
    }
    // for basic lines with arrow heads (solid/dotted/dashed)
    else if (dataCategory === BASIC && targetElem.getAttribute("arrow-head-direction") !== "none") {
      // Y-resize i.e. from toolbar (Note : same loop is exicute 3 times cause each function have to exicute once all function is performed through all nodes otherwise viewbox will not update properly)
      if (contextMenu) {
        document.querySelectorAll(`#${metadata.activeWidgetId[0]} svg *`).forEach(function (node) {
          if (
            node.getAttribute("arrow-head") &&
            (node.getAttribute("arrow-head") === "right" || node.getAttribute("arrow-head") === "left")
          ) {
            if (node.getAttribute("dh-asset-properties")) {
              let thisChildNodeAttr = node
                .getAttribute("dh-asset-properties")
                .replace("{", "")
                .replace("}", "")
                .split(",");

              thisChildNodeAttr.forEach(prop => {
                if (prop === "transform-scale") {
                  let scaleFactor = eval(node.getAttribute("data-transform-scale-factor"));
                  node.setAttribute("transform", `scale(${lineThickness * scaleFactor})`);
                  arrowRightDimension = calculateArrowDimensions(metadata.activeWidgetId[0]);

                  if (node.getAttribute("arrow-head") === "right" || node.getAttribute("arrow-head") === "left") {
                    targetElem.setAttribute("viewBox", `0 0 ${viewBox.width} ${arrowRightDimension.height}`);
                    viewBox = calculateViewBoxDimensions(widgetObj);
                  }
                }
              });
            }
          }
        });

        document.querySelectorAll(`#${metadata.activeWidgetId[0]} svg *`).forEach(function (node) {
          if (
            node.getAttribute("arrow-head-svg") &&
            (node.getAttribute("arrow-head-svg") === "right" || node.getAttribute("arrow-head-svg") === "left")
          ) {
            if (node.getAttribute("dh-asset-properties") !== undefined) {
              let thisChildNodeAttr = node
                .getAttribute("dh-asset-properties")
                .replace("{", "")
                .replace("}", "")
                .split(",");

              thisChildNodeAttr.forEach(prop => {
                if (prop === "x") {
                  if (node.getAttribute("arrow-head-svg") === "right") {
                    let translateX = viewBox.width - arrowRightDimension.width;
                    node.setAttribute("x", translateX);
                  }
                  if (node.getAttribute("arrow-head-svg") === "left") {
                    let translateX = 0;
                    node.setAttribute("x", translateX);
                  }
                }
                if (prop === "y") {
                  if (node.getAttribute("arrow-head-svg") === "right") {
                    let translateYMiddlePercent = 0;
                    node.setAttribute("y", translateYMiddlePercent + "%");
                  }
                  if (node.getAttribute("arrow-head-svg") === "left") {
                    let translateYMiddlePercent =
                      (100 / viewBox.height) * ((viewBox.height - arrowRightDimension.height) / 2);
                    node.setAttribute("y", translateYMiddlePercent + "%");
                  }
                }
              });
            }
          }
        });

        document.querySelectorAll(`#${metadata.activeWidgetId[0]} svg *`).forEach(function (node) {
          if (
            (node.getAttribute("line-svg") && node.getAttribute("line-svg") === "true") ||
            (node.getAttribute("solid-line-object") && node.getAttribute("solid-line-object") === "true")
          ) {
            if (node.getAttribute("dh-asset-properties") !== undefined) {
              let thisChildNodeAttr = node
                .getAttribute("dh-asset-properties")
                .replace("{", "")
                .replace("}", "")
                .split(",");

              thisChildNodeAttr.forEach(prop => {
                if (prop === "height") {
                  if (targetElem.getAttribute("line-type") && targetElem.getAttribute("line-type") === "solid") {
                    node.setAttribute("height", lineThickness + "px");
                  } else {
                    let applicableHeight = lineThickness < 5 ? 5 : lineThickness;
                    node.setAttribute("height", applicableHeight + "px");
                  }
                }
                if (prop === "view-box-height") {
                  let applicableHeight = viewBox.height < 5 ? 5 : viewBox.height;
                  node.setAttribute("height", applicableHeight + "px");
                }
                if (prop === "width") {
                  if (targetElem.getAttribute("arrow-head-direction") === "right") {
                    let width = viewBox.width - arrowRightDimension.width / 4;
                    width = width < 0 ? 0 : width;
                    node.setAttribute("width", width + "px");
                  }
                  if (targetElem.getAttribute("arrow-head-direction") === "left-right") {
                    let width = viewBox.width - (arrowRightDimension.width / 4 + arrowRightDimension.width / 4);
                    width = width < 0 ? 0 : width;
                    node.setAttribute("width", width + "px");
                  }
                }
                if (prop === "x") {
                  if (targetElem.getAttribute("arrow-head-direction") === "left-right") {
                    let translateX = arrowRightDimension.width / 4;
                    node.setAttribute("x", translateX);
                  }
                }
                if (prop === "y") {
                  if (
                    targetElem.getAttribute("arrow-head-direction") === "right" ||
                    targetElem.getAttribute("arrow-head-direction") === "left-right"
                  ) {
                    let translateYMiddlePercent =
                      (100 / viewBox.height) * ((arrowRightDimension.height - lineThickness) / 2);
                    node.setAttribute("y", translateYMiddlePercent + "%");
                  }
                }
              });
            }
          }
          if (node.getAttribute("line-object") && node.getAttribute("line-object") === "true") {
            if (node.getAttribute("dh-asset-properties") !== undefined) {
              let thisChildNodeAttr = node
                .getAttribute("dh-asset-properties")
                .replace("{", "")
                .replace("}", "")
                .split(",");
              thisChildNodeAttr.forEach(prop => {
                if (prop === "transform-scale") {
                  let scaleFactor = eval(node.getAttribute("data-transform-scale-factor"));
                  node.setAttribute("transform", `scale(${lineThickness * scaleFactor})`);
                }
                if (prop === "transform-scale-translate") {
                  let scaleFactor = eval(node.getAttribute("data-transform-scale-factor"));
                  let translateYMiddlePercent = eval(node.getAttribute("data-transform-translate-const-y"));
                  node.setAttribute(
                    "transform",
                    `scale(${lineThickness * scaleFactor}) translate(0, ${translateYMiddlePercent})`
                  );
                }
              });
            }
          }
        });
      }

      // X-resize i.e. from canvas
      else {
        document.querySelectorAll(`#${metadata.activeWidgetId[0]} svg *`).forEach(function (node) {
          if (
            (node.getAttribute("line-svg") && node.getAttribute("line-svg") === "true") ||
            (node.getAttribute("solid-line-object") && node.getAttribute("solid-line-object") === "true")
          ) {
            if (node.getAttribute("dh-asset-properties") !== undefined) {
              let thisChildNodeAttr = node
                .getAttribute("dh-asset-properties")
                .replace("{", "")
                .replace("}", "")
                .split(",");
              thisChildNodeAttr.forEach(prop => {
                if (prop === "height") {
                  if (targetElem.getAttribute("line-type") && targetElem.getAttribute("line-type") === "solid") {
                    node.setAttribute("height", lineThickness + "px");
                  } else {
                    let applicableHeight = lineThickness < 5 ? 5 : lineThickness;
                    node.setAttribute("height", applicableHeight + "px");
                  }
                }
                if (prop === "view-box-height") {
                  let applicableHeight = viewBox.height < 5 ? 5 : viewBox.height;
                  node.setAttribute("height", applicableHeight + "px");
                }
                if (prop === "width") {
                  if (targetElem.getAttribute("arrow-head-direction") === "right") {
                    let width = parseFloat(widgetWidth) - arrowRightDimension.width / 4;
                    width = width < 0 ? 0 : width;
                    node.setAttribute("width", width + "px");
                    targetElem.setAttribute("viewBox", `0 0 ${parseFloat(widgetWidth)} ${viewBox.height}`);
                    calculateViewBoxDimensions(widgetObj);
                  }
                  if (targetElem.getAttribute("arrow-head-direction") === "left-right") {
                    let width =
                      parseFloat(widgetWidth) - (arrowRightDimension.width / 4 + arrowRightDimension.width / 4);
                    width = width < 0 ? 0 : width;
                    node.setAttribute("width", width + "px");
                    targetElem.setAttribute("viewBox", `0 0 ${parseFloat(widgetWidth)} ${viewBox.height}`);
                    calculateViewBoxDimensions(widgetObj);
                  }
                }
                if (prop === "y") {
                  if (
                    targetElem.getAttribute("arrow-head-direction") === "right" ||
                    targetElem.getAttribute("arrow-head-direction") === "left-right"
                  ) {
                    let translateYMiddlePercent =
                      (100 / viewBox.height) * ((arrowRightDimension.height - lineThickness) / 2);
                    node.setAttribute("y", translateYMiddlePercent + "%");
                  }
                }
              });
            }
          }
          if (node.getAttribute("line-object") && node.getAttribute("line-object") === "true") {
            if (node.getAttribute("dh-asset-properties") !== undefined) {
              let thisChildNodeAttr = node
                .getAttribute("dh-asset-properties")
                .replace("{", "")
                .replace("}", "")
                .split(",");
              thisChildNodeAttr.forEach(prop => {
                if (prop === "transform-scale") {
                  let scaleFactor = eval(node.getAttribute("data-transform-scale-factor"));
                  node.setAttribute("transform", `scale(${lineThickness * scaleFactor})`);
                }
                if (prop === "transform-scale-translate") {
                  let scaleFactor = eval(node.getAttribute("data-transform-scale-factor"));
                  let translateYMiddlePercent = eval(node.getAttribute("data-transform-translate-const-y"));
                  node.setAttribute(
                    "transform",
                    `scale(${lineThickness * scaleFactor}) translate(0, ${translateYMiddlePercent})`
                  );
                }
              });
            }
          }
        });

        document.querySelectorAll(`#${metadata.activeWidgetId[0]} svg *`).forEach(function (node) {
          if (
            node.getAttribute("arrow-head-svg") &&
            (node.getAttribute("arrow-head-svg") === "right" || node.getAttribute("arrow-head-svg") === "left")
          ) {
            if (node.getAttribute("dh-asset-properties") !== undefined) {
              let thisChildNodeAttr = node
                .getAttribute("dh-asset-properties")
                .replace("{", "")
                .replace("}", "")
                .split(",");
              thisChildNodeAttr.forEach(prop => {
                if (prop === "x") {
                  if (node.getAttribute("arrow-head-svg") === "right") {
                    let translateX = parseFloat(widgetWidth) - arrowRightDimension.width;
                    node.setAttribute("x", translateX);
                  }
                  if (node.getAttribute("arrow-head-svg") === "left") {
                    let translateX = 0;
                    node.setAttribute("x", translateX);
                  }
                }
                if (prop === "y") {
                  if (node.getAttribute("arrow-head-svg") === "right") {
                    let translateYMiddlePercent =
                      (100 / viewBox.height) * ((viewBox.height - arrowRightDimension.height) / 2);
                    node.setAttribute("y", translateYMiddlePercent + "%");
                  }
                  if (node.getAttribute("arrow-head-svg") === "left") {
                    let translateYMiddlePercent =
                      (100 / viewBox.height) * ((viewBox.height - arrowRightDimension.height) / 2);
                    node.setAttribute("y", translateYMiddlePercent + "%");
                  }
                }
              });
            }
          }
        });
      }
    }
    // =======  END: SVG manipulation   ======= //

    // Y-resize i.e. from toolbar
    if (contextMenu) {
      const { x_al, y_al } = checkWidgetAllignmentForSingleWidget(applicableHeight);

      // update context
      let newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgets[targetWidgetIndex],
          style: { ...widgets[targetWidgetIndex].style, height: applicableHeight },
          data: {
            ...widgets[targetWidgetIndex].data,
            "data-x-allignment": x_al,
            "data-y-allignment": y_al,
          },
          innerHTML: document.getElementById(metadata.activeWidgetId[0]).innerHTML,
        },
      });

      updateContext && updateWidgets(newArray);
    }
    // X-resize i.e. from canvas(Update main div and handlers width, transform property)
    else {
      let mainObj = document.getElementById(metadata.activeWidgetId[0]);
      let handler = document.getElementById("dhp-widget-handler");

      const widgetTransformStr = getCssTransformObj({
        translateX: `${widgetLeft}px`,
        translateY: `${widgetTop}px`,
        transform: meta.client.initial.transform,
        returnStr: true,
      });

      const handlerTransformStr = getCssTransformObj({
        translateX: `${getZoomedValue(parseFloat(widgetLeft), dimension.zoom)}px`,
        translateY: `${getZoomedValue(parseFloat(widgetTop), dimension.zoom)}px`,
        transform: meta.handler.style.transform,
        returnStr: true,
      });

      mainObj.style.width = widgetWidth;
      mainObj.style.transform = widgetTransformStr;

      handler.style.width = getZoomedValue(parseFloat(widgetWidth), dimension.zoom) + "px";
      handler.style.transform = handlerTransformStr;
    }
  };

  useEffect(() => {
    if (resizeLineWidget) {
      ResizeSvg(resizeLineWidget);
      setResizeLineWidget(false);
    }
  }, [resizeLineWidget]);

  return setResizeLineWidget;
};

export default useResizeLineWidget;
