import { useContext } from "react";
import { useSelector } from "react-redux";
import { GENERIC_EDITOR_WRAP_PADDING, TYPE_INFOGRAPHIC, WIDGET_HANDLER_MARGIN_LEFT, WIDGET_HANDLER_MARGIN_TOP } from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";
import { getCssTextObj, getCssTextObjToString, getCssTransformObj, getZoomedValue } from "../_helpers/utils";

const useCollaborativeSelector = () => {
  let { socketState: collaborationSocketState, widgets, dimension, documentType, metadata, isTimeLineViewOpen } = useContext(EditorContext);
  const allCompanyUsers = useSelector(state => state.company?.users);

  const updateCollaborativeWidgetSelectionStyle = ({ zoom = dimension.zoom, socketState = collaborationSocketState, companyUsers = allCompanyUsers }) => {
    if (socketState && companyUsers) {
      let collaborativeUserWidgets = [];
      for (let key in socketState?.activeUsers)
        if (key !== socketState?.currentUser && socketState?.activeUsers[key].activeWidgetId !== undefined)
          collaborativeUserWidgets.push({ userHash: key, ...socketState?.activeUsers[key] });

      let activeWidgetDetails = [];
      let addedLeft = getZoomedValue(WIDGET_HANDLER_MARGIN_LEFT + GENERIC_EDITOR_WRAP_PADDING, zoom);
      let addedTop = getZoomedValue(WIDGET_HANDLER_MARGIN_TOP + GENERIC_EDITOR_WRAP_PADDING, zoom);
      collaborativeUserWidgets?.forEach(widgetRec => {
        let userInfo = companyUsers.find(rec => rec.id === widgetRec.userId);
        const selectionArea = {
          left: [],
          right: [],
          top: [],
          bottom: [],
        };
        let collaborativeActiveWidgetId = [];
        let isGroupWidget = document.getElementById(widgetRec?.activeWidgetId[0])?.closest(".dhp-page-group");
        let targetId = isGroupWidget ? document.getElementById(widgetRec?.activeWidgetId[0]).closest(".dhp-root-widget").getAttribute("id") : widgetRec?.activeWidgetId[0];
        let widgetInfo = widgets.find(rec => rec.id === targetId);
        // for video editor
        let hideSelection = isTimeLineViewOpen && metadata?.activeBlockId !== widgetInfo?.blockId;
        const blockRect = document.getElementById(widgetInfo?.blockId)?.getBoundingClientRect();
        let isRuntimeOperation = document.querySelector(`#${widgetInfo?.blockId} .dhp-runtime-group`);
        const { top: editorOuterWrapTop } = document.querySelectorAll(".dhp-page-canvas")[0].getBoundingClientRect();
        let widgetStyle = {};
        if (widgetRec?.activeWidgetId.length > 1 && blockRect && !hideSelection) {
          widgetRec?.activeWidgetId?.forEach(awId => {
            if (document.getElementById(awId)) {
              const { left, right, top, bottom } = isRuntimeOperation ? document.getElementById(`${awId}-copy`)?.getBoundingClientRect() : document.getElementById(awId)?.getBoundingClientRect();
              selectionArea.left = [...selectionArea.left, left];
              selectionArea.right = [...selectionArea.right, right];
              selectionArea.top = [...selectionArea.top, top];
              selectionArea.bottom = [...selectionArea.bottom, bottom]
            }
          })
          selectionArea.top = Math.min(...selectionArea.top) - blockRect.top;
          selectionArea.left = Math.min(...selectionArea.left) - blockRect.left;
          selectionArea.right = Math.max(...selectionArea.right) - blockRect.left;
          selectionArea.bottom = Math.max(...selectionArea.bottom) - blockRect.top;
          widgetStyle = {
            transform: `translate(${selectionArea.left}px, ${selectionArea.top}px) scale(1, 1) rotate(0deg)`,
            width: `${selectionArea.right - selectionArea.left}px`,
            height: `${selectionArea.bottom - selectionArea.top}px`,
            top:
              documentType === TYPE_INFOGRAPHIC
                ? `${Math.abs(blockRect.top - editorOuterWrapTop) + addedTop}px`
                : isTimeLineViewOpen
                ? 0
                : `${
                    Math.abs(blockRect.top - editorOuterWrapTop) +
                    WIDGET_HANDLER_MARGIN_TOP +
                    GENERIC_EDITOR_WRAP_PADDING
                  }px`,
            left:
              documentType === TYPE_INFOGRAPHIC
                ? `${addedLeft}px`
                : isTimeLineViewOpen
                ? 0
                : `${WIDGET_HANDLER_MARGIN_LEFT + GENERIC_EDITOR_WRAP_PADDING}px`,
          };
          collaborativeActiveWidgetId = widgetRec?.activeWidgetId;
        }
        // sigle widget selection with rotate angle
        if (widgetRec?.activeWidgetId.length === 1 && blockRect && !hideSelection) {
          let isGroupWidget = document.getElementById(widgetRec?.activeWidgetId)?.closest(".dhp-page-group");
          let targetId = isGroupWidget ? document.getElementById(widgetRec?.activeWidgetId).closest(".dhp-root-widget").getAttribute("id") : widgetRec?.activeWidgetId;
          let widgetDOMStyle = isRuntimeOperation ? { transform: document.getElementById(`${targetId}-copy`).style.transform, ...JSON.parse(JSON.stringify(document.getElementById(`${targetId}-copy`).getBoundingClientRect())) } : getCssTextObj(document.getElementById(targetId).style.cssText);
          let {
            translate: { x: widgetTransX, y: widgetTransY } = {},
            rotate: { theta: widgetTheta },
          } = getCssTransformObj({
            transform: widgetDOMStyle.transform
          });
          if (isRuntimeOperation) {
            widgetTransX = widgetDOMStyle.left - blockRect.left;
            widgetTransY = widgetDOMStyle.top - blockRect.top;
          }
          widgetStyle = {
            transform: `translate(${
              isRuntimeOperation ? widgetTransX : getZoomedValue(parseFloat(widgetTransX), zoom)
            }px, ${
              isRuntimeOperation ? widgetTransY : getZoomedValue(parseFloat(widgetTransY), zoom)
            }px) scale(1, 1) rotate(${widgetTheta})`,
            width: `${
              isRuntimeOperation ? widgetDOMStyle.width : getZoomedValue(parseFloat(widgetDOMStyle.width), zoom)
            }px`,
            height: `${
              isRuntimeOperation ? widgetDOMStyle.height : getZoomedValue(parseFloat(widgetDOMStyle.height), zoom)
            }px`,
            top:
              documentType === TYPE_INFOGRAPHIC
                ? `${Math.abs(blockRect.top - editorOuterWrapTop) + addedTop}px`
                : isTimeLineViewOpen
                ? 0
                : `${
                    Math.abs(blockRect.top - editorOuterWrapTop) +
                    WIDGET_HANDLER_MARGIN_TOP +
                    GENERIC_EDITOR_WRAP_PADDING
                  }px`,
            left:
              documentType === TYPE_INFOGRAPHIC
                ? `${addedLeft}px`
                : isTimeLineViewOpen
                ? 0
                : `${WIDGET_HANDLER_MARGIN_LEFT + GENERIC_EDITOR_WRAP_PADDING}px`,
          };
          collaborativeActiveWidgetId = [targetId]
        }

        if (collaborativeActiveWidgetId[0]) {
          // check if exist update the count
          let widgetIds = collaborativeActiveWidgetId.join(" ");
          let activeWidgetIndex = activeWidgetDetails?.findIndex(aw => aw?.widgetId === widgetIds);
          if (activeWidgetIndex > -1) {
            activeWidgetDetails[activeWidgetIndex].count = activeWidgetDetails[activeWidgetIndex].count + 1;
          }
          // else add
          else {
            activeWidgetDetails.push({
              email: userInfo?.email,
              firstname: userInfo?.firstname,
              lastname: userInfo?.lastname,
              id: userInfo?.id,
              image: userInfo?.image,
              widgetStyle,
              widgetId: widgetIds,
              color: widgetRec.color,
              count: 0,
            });
          }
        }
      });

      activeWidgetDetails.forEach(wz => {
        let curSelection = document.querySelector(`[data-widget-id="${wz.widgetId}"].collborative-selector`);
        if (curSelection)
          curSelection.style = getCssTextObjToString({ ...wz.widgetStyle, outline: `2px solid ${wz.color}` });
      })
    }
  }

  return updateCollaborativeWidgetSelectionStyle;
}

export default useCollaborativeSelector;
