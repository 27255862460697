import { useContext } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";

const useDeleteAudio = () => {
  let { metadata, audios, setAudios, updateMetadata, rightContextMenu, updateRightContextMenu } =
    useContext(EditorContext);

  const deleteAudio = () => {
    let newAudioArray = audios.filter(audio => audio.id !== metadata.activeAudioId);
    setAudios(newAudioArray);
    updateMetadata({ ...metadata, activeAudioId: false });

    if (rightContextMenu.enable)
      updateRightContextMenu({
        ...rightContextMenu,
        enable: false,
      });
  };

  return { deleteAudio };
};

export default useDeleteAudio;
