// ENV TYPE
export const ENV_PRODUCTION = "production";
export const ENV_DEVELOPMENT = "development";

// ENV ROLE
export const ROLE_MANAGER = "manager";

// APP SUPPORT EMAIL
export const SUPPORT_EMAIL = "support@dochipo.com";

// COMMENT UPGRADE MODAL HTML THUMB DIA
export const COMMENT_MODAL_THUMB_WIDTH = 208;

// EMAIL UPGRADE MODAL HTML THUMB DIA
export const EMAIL_MODAL_THUMB_WIDTH = 260;

// VERSION UPGRADE MODAL HTML THUMB DIA
export const VERSION_MODAL_THUMB_WIDTH = 260;

// DOWNLOAD DPI FACTORS
export const DPI_RATIO = {
    high: 2.083333333 * 2,
    medium: 2.083333333,
    normal: 1
};

// ALLOWED HEIGHT WIDTH IN DOWNLOAD MEDIUM/HEIGH QUALITY(px)
export const ALLOWED_DIMENSION = {
    height: 4500,
    width: 8000
}