import React, { useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import cx from "classnames";

import global from "../../scss/dhp.scss";

import { Icon } from "../../components/ui/icon";
import EditorCanvasController from "./EditorCanvasController";
import EditorCanvas from "./EditorCanvas";
import { EditorContext } from "./EditorLayout";
import { TYPE_INFOGRAPHIC } from "../../constants/editor";
import useSelectable from "../../hooks/useSelectable";
import useEditorDocumentProcessing from "../../hooks/useEditorDocumentProcessing";
import RouteLeavingGuard from "../../components/Editor/Widgets/Common/RouteLeavingGuard";
import FindAndReplace from "../../components/Editor/FindAndReplace";

let style = Object.assign({}, global);

const EditorCanvasPanel = props => {
  const canvasPanelRef = useRef();
  const { dragSelectStart } = useSelectable();
  const {
    metadata,
    updateMetadata,
    documentType,
    rightContextMenu,
    updateRightContextMenu,
    isDocumentReady,
    isTimeLineViewOpen,
    showFindAndReplace,
  } = useContext(EditorContext);

  const userInfo = useSelector(state => state?.auth?.user);

  // Editor document process (e.g., Saving changes, screenshot generate, version generate)
  useEditorDocumentProcessing(props.pageSorterList);

  //function to detect click on outside gray area of canvas
  const activateOutSideCanvasArea = e => {
    if (e.target.classList.contains("canvas-panel-area")) {
      updateMetadata({
        ...metadata,
        activeOutSideCanvasArea: true,
        activeAudioId: false,
        activeWidgetId: false,
        activeWidgetType: false,
      });
      updateRightContextMenu({
        ...rightContextMenu,
        enable: false,
      });
    }
  };

  const mouseDownSubscribers = e => {
    // below func. call order is important (don't change)
    activateOutSideCanvasArea(e);

    const isLeftClick = e.button === 0;
    const isWidgetEventActive = localStorage.getItem("widget.event.isActive") === "true";
    const isRestrictedArea =
      e.target.closest(".block-resize-handler") ||
      e.target.closest(".block-config") ||
      e.target.closest(".gridAction-DropdownMenu") ||
      e.target.closest(".pageDuration-DropdownMenu") ||
      e.target.closest(".dhp-page-progress-bar") ||
      document.querySelector(".tabel-modal") ||
      document.querySelector(".pagesetup-modal");

    // NOTE:::: Only when left mouse button is pressed AND not clicked on either of the restricted areas AND "widget.event.isActive" is not "true"
    if (isLeftClick && !isRestrictedArea && !isWidgetEventActive) {
      dragSelectStart(e);
    }
  };

  useEffect(() => {
    if (canvasPanelRef.current) canvasPanelRef.current.style.overflow = isDocumentReady ? "" : "hidden";
  }, [isDocumentReady]);

  return (
    <>
      {!props.pageSorterList && (
        <div
          ref={canvasPanelRef}
          id="canvas-panel-area"
          className={style["canvas-panel-area"]}
          onMouseDown={e => mouseDownSubscribers(e)}>
          <span className={style["panel-toggle"]} onClick={props.toggle}>
            <Icon icon="ui-arrow-left" />
          </span>
          <div className={style["canvas-block"]}>
            <div
              className={cx(style["editor-outer-wrap"], {
                [style["pb-5"]]: documentType === TYPE_INFOGRAPHIC,
                [style["page-topRight-option"]]:
                  !isTimeLineViewOpen && documentType !== TYPE_INFOGRAPHIC && userInfo.video_enabled,
              })}>
              <EditorCanvasController {...props} />
              <EditorCanvas {...props} />
            </div>
          </div>

          {/* Display when area selection */}
          <div id="dhp-area-selection" className={cx(style["dhp-area-selection"], style["d-none"])}></div>
        </div>
      )}

      {props.pageSorterList && (
        <>
          <EditorCanvasController
            pageSorterList={props.pageSorterList}
            activePageListHover={props.activePageListHover}
          />
          <EditorCanvas {...props} />
        </>
      )}

      {/* Route leaving gaurd to check if document save processing */}
      <RouteLeavingGuard />
      {showFindAndReplace?.isActive && <FindAndReplace />}
    </>
  );
};
//Props type validation
EditorCanvasPanel.propTypes = {
  pageSorterList: PropTypes.bool,
  activePageListHover: PropTypes.bool,
  toggle: PropTypes.func,
};

export default EditorCanvasPanel;
