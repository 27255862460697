// HyperLink Types
export const HYPERLINK_TYPE_EMAIL = "Email";
export const HYPERLINK_TYPE_PAGE = "Page";
export const HYPERLINK_TYPE_WEBSITE = "Website";
export const HYPERLINK_TYPE_SLIDE = "Slide";

export const FADE_EFFECT_OBJ = {
    0: ["None", "", null, undefined],
    500: ["0.5 sec"],
    1000: ["1.0 sec", "1 sec"],
    2000: ["2.0 sec", "2 sec"],
};
export const FADE_INTERVEL_OBJ = {
    500: [0, 100, 200, 300, 400, 500],
    1000: [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000],
    2000: [0, 200, 400, 600, 800, 1000, 1200, 1400, 1600, 1800, 2000],
};

export const HTML_PLAYER_PADDING = 100; // left+right or top+bottom
export const HTML_PLAYER_PADDING_FULL_SCREEN = 4; // left+right or top+bottom