import React, { useEffect, useRef, useState } from "react";
import { FADE_EFFECT_OBJ, FADE_INTERVEL_OBJ } from "../constants/previewPresent";
import { floatToFloatSec, getHMSToSec, playMedia } from "../_helpers/utils";

const useAudioPlayer = ({ docPagesMeta, origin, activePageIdx, muted }) => {
  const audioIntervalId = useRef();
  const [docDuration, setDocDuration] = useState();
  const [pageStartTimes, setpageStartTimes] = useState();
  const [documentTimer, setDocumentTimer] = useState(false);
  const [audioPlayState, setAudioPlayState] = useState(false);
  const [userToggleMute, setUserToggleMute] = useState(false);

  const audioPlayHelper = {
    getAudioData: audioObj => {
      let fadeData = {};
      const audio = document.getElementById(`player-${audioObj.id}`);
      const durationInHMS = audioObj.data["data-duration"];
      const durationInSec = getHMSToSec({ hms: durationInHMS });

      const fadeInValue = audioObj.data["data-fade-in-value"];
      const fadeOutValue = audioObj.data["data-fade-out-value"];
      const fadeInMs = parseInt(Object.keys(FADE_EFFECT_OBJ).find(k => FADE_EFFECT_OBJ[k].includes(fadeInValue)));
      const fadeOutMs = parseInt(Object.keys(FADE_EFFECT_OBJ).find(k => FADE_EFFECT_OBJ[k].includes(fadeOutValue)));

      const trim = audioObj.data["data-trim"];
      const trimArray = trim ? trim.split(",")?.map(e => parseFloat(e)) : null;
      const isTrimmed = Boolean(trimArray?.length === 2);
      const trimStart = isTrimmed ? trimArray?.[0] : 0;
      const trimStop = isTrimmed ? trimArray?.[1] : durationInSec;

      const volume = parseInt(audioObj.data["data-volume"] ?? 100);
      const startTimeMs = parseFloat(audioObj.startTime) * 1000;
      let stopTimeMs = parseFloat(audioObj.endTime) * 1000;
      const trimStartTimeMs = trimStart * 1000;
      const trimStopTimeMs = trimStop * 1000;
      const trimmedDurationMs = trimStopTimeMs - trimStartTimeMs;

      const fadeInStartTimeMs = startTimeMs;
      const fadeInStopTimeMs = startTimeMs + fadeInMs;
      const fadeOutStartTimeMs = stopTimeMs - fadeOutMs;
      const fadeOutStopTimeMs = stopTimeMs;

      if (fadeInMs > 0 || fadeOutMs > 0) {
        fadeData = audioPlayHelper.getFadeData({ volume, fadeInStartTimeMs, fadeInMs, fadeOutStartTimeMs, fadeOutMs });
      }

      return {
        audio,
        audioCurTimeMs: floatToFloatSec(audio.currentTime) * 1000,
        volume,
        startTimeMs,
        stopTimeMs,
        trimStartTimeMs,
        trimStopTimeMs,
        trimmedDurationMs,
        fadeInStartTimeMs,
        fadeInStopTimeMs,
        fadeOutStartTimeMs,
        fadeOutStopTimeMs,
        ...fadeData,
        isMuted: Boolean(volume === 0),
        isLooped: Boolean(stopTimeMs > trimStopTimeMs),
        isTrimmed: Boolean(trimStartTimeMs > 0 || trimStopTimeMs < stopTimeMs),
        isFadeIn: Boolean(fadeInMs > 0),
        isFadeOut: Boolean(fadeOutMs > 0),
      };
    },

    getFadeData: ({ volume, fadeInStartTimeMs, fadeInMs, fadeOutStartTimeMs, fadeOutMs }) => {
      let fadeInIntervals = [];
      let fadeInVolumes = [];
      let fadeOutIntervals = [];
      let fadeOutVolumes = [];

      if (fadeInMs > 0) {
        const intervals = FADE_INTERVEL_OBJ[fadeInMs];
        const volumeFactor = volume / (intervals.length - 1);

        for (let i = 0; i < intervals.length; i++) {
          fadeInIntervals = [...fadeInIntervals, fadeInStartTimeMs + intervals[i]];

          if (i === 0) fadeInVolumes = [...fadeInVolumes, 0];
          if (i > 0 && i < intervals.length - 1) fadeInVolumes = [...fadeInVolumes, parseInt(i * volumeFactor)];
          if (i === intervals.length - 1) fadeInVolumes = [...fadeInVolumes, volume];
        }
      }

      if (fadeOutMs > 0) {
        const intervalObj = JSON.parse(JSON.stringify(FADE_INTERVEL_OBJ[fadeOutMs]));
        const intervals = intervalObj.reverse();
        const volumeFactor = volume / (intervals.length - 1);

        for (let i = intervals.length - 1; i >= 0; i--) {
          fadeOutIntervals = [...fadeOutIntervals, fadeOutStartTimeMs + intervals[i]];

          if (i === intervals.length - 1) fadeOutVolumes = [...fadeOutVolumes, volume];
          if (i > 0 && i < intervals.length - 1) fadeOutVolumes = [...fadeOutVolumes, parseInt(i * volumeFactor)];
          if (i === 0) fadeOutVolumes = [...fadeOutVolumes, 0];
        }
      }

      return { fadeInIntervals, fadeInVolumes, fadeOutIntervals, fadeOutVolumes };
    },

    getPageStartTimes: () => {
      let pageStartTimes = [];
      let documentDurationMs = 0;

      docPagesMeta?.pageNodes.forEach(pageObj => {
        const pageDurationMs = parseFloat(pageObj.pageDuration) * 1000;
        const pageStartTimeMs = documentDurationMs;

        documentDurationMs += pageDurationMs;
        pageStartTimes = [...pageStartTimes, pageStartTimeMs];
      });

      return pageStartTimes;
    },
    getactvePageStartTime: ({ pageIdx }) => {
      let timeNow = 0;
      docPagesMeta?.pageNodes?.forEach(pn => {
        if (pn.pageIdx < pageIdx) {
          timeNow += parseFloat(pn.pageDuration);
        }
      });

      return timeNow * 1000;
    },
    getDocumentDurationMS: () => {
      let totalDuartion = 0;
      docPagesMeta?.pageNodes?.forEach(pn => {
        totalDuartion += parseFloat(pn.pageDuration);
      });

      return totalDuartion * 1000;
    },
    setAudioCurTime: ({ timer }) => {
      docPagesMeta?.audios?.forEach(audioObj => {
        const { audio, startTimeMs, stopTimeMs, trimStartTimeMs, trimStopTimeMs } =
          audioPlayHelper.getAudioData(audioObj);

        if (audio) {
          if (timer > startTimeMs && timer < stopTimeMs) {
            // when widget is within playable time range
            const widgetPlayedDurationMs = timer - startTimeMs;
            const audioTrimmedDurationMs = trimStopTimeMs - trimStartTimeMs;
            const audioCurTimeWithinPlayableRange =
              (audioTrimmedDurationMs > widgetPlayedDurationMs
                ? widgetPlayedDurationMs
                : widgetPlayedDurationMs % audioTrimmedDurationMs) / 1000;
            audio.currentTime = trimStartTimeMs / 1000 + audioCurTimeWithinPlayableRange;
          } else {
            audio.currentTime = trimStartTimeMs / 1000;
            // audio.currentTime = startTimeMs;
          }
          audio.pause();
        }
      });
    },

    audioPlay: ({ timer }) => {
      docPagesMeta?.audios?.forEach(audioObj => {
        const {
          audio,
          audioCurTimeMs,
          volume,
          startTimeMs,
          stopTimeMs,
          trimStartTimeMs,
          trimStopTimeMs,
          fadeInIntervals,
          fadeInVolumes,
          fadeOutIntervals,
          fadeOutVolumes,
          isMuted,
          isLooped,
          isFadeIn,
          isFadeOut,
        } = audioPlayHelper.getAudioData(audioObj);

        if (audio) {
          if (timer === startTimeMs) {
            if (isMuted) audio.muted = false;
            if (!isFadeIn) audio.volume = volume / 100;
            audio.currentTime = trimStartTimeMs / 1000;
            playMedia(audio);
            audio.setAttribute("data-playing", "true");
          }

          // fadeIn audio effect
          if (isFadeIn && fadeInIntervals.includes(timer)) {
            const idx = fadeInIntervals.indexOf(timer);
            audio.volume = fadeInVolumes[idx] / 100;
          }

          // fadeOut audio effect
          if (isFadeOut && fadeOutIntervals.includes(timer)) {
            const idx = fadeOutIntervals.indexOf(timer);
            audio.volume = fadeOutVolumes[idx] / 100;
          }

          if (timer > startTimeMs && timer < stopTimeMs) {
            if (!(audioCurTimeMs >= trimStartTimeMs && audioCurTimeMs <= trimStopTimeMs)) {
              // when audio is outside playable time range
              audio.currentTime = trimStartTimeMs / 1000;
              // audio.volume = volume / 100;
              if (isLooped) {
                if (audio.paused) {
                  playMedia(audio);
                  audio.setAttribute("data-playing", "true");
                }
              } else {
                audio.pause();
              }
            } else {
              // audio.volume = volume / 100;
              if (audio.paused) {
                // audioPlayHelper.setAudioCurTime({ timer }); // only for download
                playMedia(audio);
                audio.setAttribute("data-playing", "true");
              }
            }
          }

          if (timer === stopTimeMs) {
            audio.pause();
            if (isMuted) audio.muted = true;
            audio.removeAttribute("data-playing");
            audio.currentTime = trimStartTimeMs / 1000;
          }
        }
      });
    },

    // pause/stop
    stopAudioPlay: () => {
      if (audioIntervalId.current) {
        clearInterval(audioIntervalId.current);
        audioIntervalId.current = null;
      }
      document.querySelectorAll('audio[data-playing="true"], video').forEach(audioNode => {
        audioNode.pause();
      });
    },
  };

  useEffect(() => {
    if (docPagesMeta?.audios && docPagesMeta?.pageNodes.length > 0 && !activePageIdx) {
      setDocDuration(audioPlayHelper.getDocumentDurationMS);
    }
  }, [docPagesMeta]);

  useEffect(() => {
    if (!userToggleMute && !muted) {
      setUserToggleMute(true);
      setAudioPlayState("play");
    }
  }, [muted]);

  useEffect(() => {
    if (!audioIntervalId.current && audioPlayState && audioPlayState === "play") {
      let timer = activePageIdx ? audioPlayHelper.getactvePageStartTime({ pageIdx: activePageIdx - 1 }) : 0; // only for download

      if (timer === 0) {
        audioPlayHelper.audioPlay({ timer });
      }

      audioIntervalId.current = setInterval(() => {
        timer += 20;
        audioPlayHelper.audioPlay({ timer });
        setDocumentTimer(timer);
      }, 20);
    }
    if (audioPlayState === "stop") {
      audioPlayHelper.stopAudioPlay();
    }
  }, [audioPlayState]);

  //for preview & present handle pause
  useEffect(() => {
    if (
      (documentTimer >= docDuration && origin === "preview") ||
      (origin === "present" &&
        pageStartTimes &&
        (documentTimer >= pageStartTimes[activePageIdx] || documentTimer >= docDuration))
    )
      setAudioPlayState("stop");
  }, [documentTimer]);

  // for Present
  useEffect(() => {
    if (docPagesMeta?.audios) {
      if (activePageIdx === 1) {
        setDocDuration(audioPlayHelper.getDocumentDurationMS);
        setpageStartTimes(audioPlayHelper.getPageStartTimes);
      }
      audioPlayHelper.setAudioCurTime({
        timer: audioPlayHelper.getactvePageStartTime({ pageIdx: activePageIdx - 1 ?? 0 }),
      });
      setUserToggleMute(false);
    }
  }, [docPagesMeta?.audios, activePageIdx]);

  // STOP audio on unmount
  useEffect(() => {
    return () => {
      audioPlayHelper.stopAudioPlay();
    }
  }, [])
};

export default useAudioPlayer;
