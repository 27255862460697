import React, { useEffect, useState } from "react";
import global from "../../scss/dhp.scss";
import { BLACK_COLOR, MAP, WHITE_COLOR } from "../../constants/editor";

let style = Object.assign({}, global);

const MapInfoTooltip = () => {
    const editorMapTooltip = {
        enable: false,
        style: {
            position: "fixed",
            padding: "5px",
            borderRadius: "5px",
            display: "inline-block",
        },
        content: {}
    };

    const [tooltip, setTooltip] = useState(editorMapTooltip);

    const handleMouseMove = e => {
        if ((e.target.closest(".present-content .dhp-widgets")?.getAttribute("data-asset-type") === MAP ||
            e.target.closest(".preview-content .dhp-widgets")?.getAttribute("data-asset-type") === MAP) &&
            e.target.closest("svg")?.getAttribute("data-caption-on") === "true" &&
            e.target.tagName === "path"
        ) {
            let title = e.target.getAttribute("title");
            let dataCaptionType = e.target.closest("svg").getAttribute("data-caption-type");
            let background = dataCaptionType === "Light" ? WHITE_COLOR : BLACK_COLOR;
            let color = dataCaptionType === "Light" ? BLACK_COLOR : WHITE_COLOR;
            const x = e.clientX;
            const y = e.clientY;
            let tableCaptionObj;
            let targetPath = e.target.closest("path");
            const dataVersion = parseInt(e.target.closest(".dhp-widgets")?.getAttribute("data-version"));

            if (targetPath) {
                const attributes = targetPath.attributes;
                for (let i = 0; i < attributes.length; i++) {
                    const attributeName = attributes[i].name;
                    let attributeValue = attributes[i].value;

                    // version-specific logic
                    if (dataVersion >= 4 && attributeName.startsWith("data-")) {
                        attributeValue = JSON.parse(attributeValue);
                        tableCaptionObj = {
                            ...tableCaptionObj,
                            [`${attributeValue.name}`]: attributeValue.value ? attributeValue.value : 0,
                        };
                    } else if (attributeName.startsWith("data-")) {
                        let formattedKey = attributeName.replace(/^data-/, ''); // Removed "data-"
                        // Capitalize first character
                        formattedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
                        tableCaptionObj = { ...tableCaptionObj, [formattedKey]: attributeValue ? attributeValue : 0 };
                    }
                }
            }

            setTooltip({
                ...tooltip,
                enable: true,
                style: {
                    ...tooltip.style,
                    left: x + 10,
                    top: y + 10,
                    background: background,
                    color: color,
                },
                content: {
                    ...tooltip.content,
                    areaTitle: title,
                    tableCaptionObj: tableCaptionObj,
                },
            });
        }
        else hideTooltip()
    };

    const hideTooltip = () => {
        setTooltip({
            ...tooltip,
            enable: false,
            content: {},
        });
    }

    useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("wheel", hideTooltip);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("wheel", hideTooltip);
        }
    }, [])

    return (
        <div style={tooltip.style} id="map-tooltip">
            <strong className={style["d-block"]}>{tooltip.content.areaTitle}</strong>
            {tooltip.content.tableCaptionObj && (<ul>
                {Object.entries(tooltip.content.tableCaptionObj).map(([key, value]) => {
                    let formattedKey = key.replace(/_/g, " ");
                    return (
                        <li key={key}>
                            {formattedKey}: {value}
                        </li>
                    );
                })}
            </ul>)}
        </div>
    );
};

export default MapInfoTooltip;
