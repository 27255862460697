import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import SliderControll from "../SliderControll";
import { contextualConfig } from "../editor_config";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import UseCheckWidgetAllignment from "../../../hooks/UseCheckWidgetAllignment";
import {
  calculateNewPositionOnRotatedObjectResize,
  getCssTransformObj,
  getUpdatedGroupInnerWidgetPos,
  getZoomedValue,
  updateHeightAndTopForInactiveGroupChildren,
} from "../../../_helpers/utils";
import useCollaborativeSelector from "../../../hooks/useCollaborativeSelector";
import useFindHighlighter from "../../../hooks/useFindHighlighter";

let style = Object.assign({}, global);

const Spacing = () => {
  let { metadata, widgets, updateWidgets, dimension } = useContext(EditorContext);

  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
  let groupWidgetInitHeight = isGroupWidget && parseFloat(isGroupWidget.style.height);

  const [letterSpacing, setLetterSpacing] = useState();
  const [lineHeight, setLineHeight] = useState();
  const [updateSpacingContext, setUpdateSpacingContext] = useState(false);
  const [updateHeightContext, setUpdateHeightContext] = useState(false);
  const [loadCurrentFormatingHeight, setLoadCurrentFormatingHeight] = useState(true);
  const [loadCurrentFormatingSpace, setLoadCurrentFormatingSpace] = useState(true);

  const { checkWidgetAllignmentForSingleWidget } = UseCheckWidgetAllignment();
  const updateCollaborativeWidgetSelectionStyle = useCollaborativeSelector();
  const updateFindHighlighter = useFindHighlighter();

  //Load current formatting
  useEffect(() => {
    if (metadata.activeWidgetId) {
      let innerHtmlflag = document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`);
      setLoadCurrentFormatingHeight(true);
      setLoadCurrentFormatingSpace(true);
      setLetterSpacing(parseInt(innerHtmlflag.style.letterSpacing));
      setLineHeight(innerHtmlflag.style.lineHeight);
    }
  }, [metadata.activeWidgetId]);

  useEffect(() => {
    let newArray;

    if (!loadCurrentFormatingSpace) {
      document.querySelector(
        "#" + metadata.activeWidgetId[0] + " .dhp-widget-inner"
      ).style.letterSpacing = `${letterSpacing}px`;
    }

    // if the text is under a group widget
    if (isGroupWidget) {
      // Get group updated height and inner widget updated top and height according group
      let { groupDivHeight, parcentHeight, parcentTop } = getUpdatedGroupInnerWidgetPos(
        targetId,
        id,
        document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight
      );

      if (!loadCurrentFormatingSpace) {
        // update DOM and group handler
        document.getElementById(targetId).style.height = `${groupDivHeight}px`;
        document.getElementById(id).style.height = parcentHeight;
        document.getElementById(id).style.top = parcentTop;
        document.getElementById("dhp-widget-handler").style.height = `${getZoomedValue(
          groupDivHeight,
          dimension.zoom
        )}px`;

        // update height and top for inactive child widgets
        updateHeightAndTopForInactiveGroupChildren({
          group: isGroupWidget,
          groupOldHeight: groupWidgetInitHeight,
          groupNewHeight: groupDivHeight,
          activeChildId: id,
        });
      }

      newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgets[targetWidgetIndex],
          style: {
            ...widgets[targetWidgetIndex].style,
            height: `${groupDivHeight}px`,
          },
          innerHTML: document.getElementById(targetId).innerHTML,
        },
      });
    }
    // if the text is a main widget
    else {
      const newHeight = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight;
      const { x_al, y_al } = checkWidgetAllignmentForSingleWidget(newHeight);

      const activeWidgetDOM = document.getElementById(metadata.activeWidgetId[0]);
      const widgetObject = widgets[targetWidgetIndex];
      const {
        translate: { x: widgetTransX, y: widgetTransY },
        rotate: { theta: widgetTheta },
      } = getCssTransformObj({
        transform: widgetObject.style.transform,
      });
      // calculate new transform
      const { left, top } = calculateNewPositionOnRotatedObjectResize(
        parseFloat(widgetTransX),
        parseFloat(widgetTransY),
        parseFloat(widgetObject.style.width),
        parseFloat(newHeight),
        parseFloat(widgetObject.style.width),
        parseFloat(widgetObject.style.height),
        parseFloat(widgetTheta)
      );
      const widgetTransformStr = getCssTransformObj({
        translateX: `${left}px`,
        translateY: `${top}px`,
        transform: widgetObject.style.transform,
        returnStr: true,
      });

      // set handler
      let handler = document.getElementById("dhp-widget-handler");
      const handlerTransformStr = getCssTransformObj({
        translateX: `${getZoomedValue(left, dimension.zoom)}px`,
        translateY: `${getZoomedValue(top, dimension.zoom)}px`,
        transform: widgetObject.style.transform,
        returnStr: true,
      });

      // update handler and widget height in dom
      if (!loadCurrentFormatingSpace) {
        activeWidgetDOM.style.height = `${newHeight}px`;
        activeWidgetDOM.style.transform = widgetTransformStr;
        handler.style.height = `${getZoomedValue(newHeight, dimension.zoom)}px`;
        handler.style.transform = handlerTransformStr;
      }

      newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgetObject,
          style: {
            ...widgetObject.style,
            height: `${newHeight}px`,
            transform: widgetTransformStr,
          },
          data: {
            ...widgetObject.data,
            "data-x-allignment": x_al,
            "data-y-allignment": y_al,
          },
          innerHTML: document.getElementById(metadata.activeWidgetId[0]).innerHTML,
        },
      });
    }

    updateCollaborativeWidgetSelectionStyle({ zoom: dimension.zoom }); // update collaboration selection
    updateFindHighlighter()
    updateSpacingContext && updateWidgets(newArray);
    setUpdateSpacingContext(false);
  }, [letterSpacing, updateSpacingContext]);

  useEffect(() => {
    let newArray;

    if (!loadCurrentFormatingHeight) {
      document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style.lineHeight = lineHeight;
    }

    // if the text is under a group widget
    if (isGroupWidget) {
      // Get group updated height and inner widget updated top and height according group
      let { groupDivHeight, parcentHeight, parcentTop } = getUpdatedGroupInnerWidgetPos(
        targetId,
        id,
        document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight
      );

      if (!loadCurrentFormatingHeight) {
        // update DOM and group handler
        document.getElementById(targetId).style.height = `${groupDivHeight}px`;
        document.getElementById(id).style.height = parcentHeight;
        document.getElementById(id).style.top = parcentTop;
        document.getElementById("dhp-widget-handler").style.height = `${getZoomedValue(
          groupDivHeight,
          dimension.zoom
        )}px`;

        // update height and top for inactive child widgets
        updateHeightAndTopForInactiveGroupChildren({
          group: isGroupWidget,
          groupOldHeight: groupWidgetInitHeight,
          groupNewHeight: groupDivHeight,
          activeChildId: id,
        });
      }

      newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgets[targetWidgetIndex],
          style: {
            ...widgets[targetWidgetIndex].style,
            height: `${groupDivHeight}px`,
          },
          innerHTML: document.getElementById(targetId).innerHTML,
        },
      });
    }
    // if the text is a main widget
    else {
      const newHeight = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight;
      const { x_al, y_al } = checkWidgetAllignmentForSingleWidget(newHeight);

      const activeWidgetDOM = document.getElementById(metadata.activeWidgetId[0]);
      const widgetObject = widgets[targetWidgetIndex];
      const {
        translate: { x: widgetTransX, y: widgetTransY },
        rotate: { theta: widgetTheta },
      } = getCssTransformObj({
        transform: widgetObject.style.transform,
      });
      // calculate new transform
      const { left, top } = calculateNewPositionOnRotatedObjectResize(
        parseFloat(widgetTransX),
        parseFloat(widgetTransY),
        parseFloat(widgetObject.style.width),
        parseFloat(newHeight),
        parseFloat(widgetObject.style.width),
        parseFloat(widgetObject.style.height),
        parseFloat(widgetTheta)
      );
      const widgetTransformStr = getCssTransformObj({
        translateX: `${left}px`,
        translateY: `${top}px`,
        transform: widgetObject.style.transform,
        returnStr: true,
      });

      // set handler
      let handler = document.getElementById("dhp-widget-handler");
      const handlerTransformStr = getCssTransformObj({
        translateX: `${getZoomedValue(left, dimension.zoom)}px`,
        translateY: `${getZoomedValue(top, dimension.zoom)}px`,
        transform: widgetObject.style.transform,
        returnStr: true,
      });

      // update handler and widget height in dom
      if (!loadCurrentFormatingHeight) {
        activeWidgetDOM.style.height = `${newHeight}px`;
        activeWidgetDOM.style.transform = widgetTransformStr;
        handler.style.height = `${getZoomedValue(newHeight, dimension.zoom)}px`;
        handler.style.transform = handlerTransformStr;
      }
      
      newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgetObject,
          style: {
            ...widgetObject.style,
            height: `${newHeight}px`,
            transform: widgetTransformStr,
          },
          data: {
            ...widgetObject.data,
            "data-x-allignment": x_al,
            "data-y-allignment": y_al,
          },
          innerHTML: document.getElementById(metadata.activeWidgetId[0]).innerHTML,
        },
      });
    }

    updateCollaborativeWidgetSelectionStyle({ zoom: dimension.zoom }); // update collaboration selection
    updateFindHighlighter()
    updateHeightContext && updateWidgets(newArray);
    setUpdateHeightContext(false);
  }, [lineHeight, updateHeightContext]);

  return (
    <li className={style["toolset-group"]}>
      <UncontrolledDropdown setActiveFromChild className={cx(style["toolset-item"], style["spacing-wrap"])}>
        <DropdownToggle
          tag="span"
          className={cx(style["toolset-action"], style["custom-tooltip"], style["spacing-toggle"])}>
          <Icon icon="ui-line-height-spacing" />
          <div className={cx(style["custom-tooltip-content"], style["top"])}>Spacing</div>
        </DropdownToggle>

        <DropdownMenu className={cx(style["shadow"], style["spacing-menu"], style["border-0"], style["rounded"])}>
          <div className={style["line-controls-wrap"]}>
            <SliderControll
              Slider={letterSpacing}
              setSlider={setLetterSpacing}
              sliderMin={contextualConfig.LetterSpacing.minValue}
              sliderMax={contextualConfig.LetterSpacing.maxValue}
              sliderLabel={"Letter Spacing"}
              setUpdateContext={setUpdateSpacingContext}
              setLoadCurrentFormating={setLoadCurrentFormatingSpace}
            />
          </div>

          <div className={cx(style["line-controls-wrap"], style["mb-0"])}>
            <SliderControll
              Slider={lineHeight}
              setSlider={setLineHeight}
              sliderMin={contextualConfig.LineHeight.minValue}
              sliderMax={contextualConfig.LineHeight.maxValue}
              sliderLabel={"Line Height"}
              step={0.1}
              setUpdateContext={setUpdateHeightContext}
              setLoadCurrentFormating={setLoadCurrentFormatingHeight}
            />
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </li>
  );
};

export default Spacing;
