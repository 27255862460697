import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import {
  RGBToHex,
  calculateNewPositionOnRotatedObjectResize,
  changeBucketColorAccordingBackground,
  getCssTransformObj,
  getInterpolateColors,
  getSvgContentFromUrl,
  hexToRGB,
  processSVG,
  shortNumberIntToString,
} from "../../../_helpers/utils";
import { fetchCountryList, fetchRegionList } from "../../../store/actions/editorActions";
import { BLACK_COLOR, MAP, WHITE_COLOR } from "../../../constants/editor";
import { contextualConfig, widgetConfig } from "../editor_config";
import ColorPicker from "../../ui/colorPicker";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import useAlignment from "../../../hooks/useAlignment";
import useAddWidget from "../../../hooks/useAddWidget";
import useElementInnerHtml from "../../../hooks/useElementInnerHtml";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import AssetsLoader from "../../ui/loader/assetsLoader";
import parserLoaderSvg from "../../../assets/images/dh-loader.svg";

let style = Object.assign({}, global);

const SearchComponent = props => {
  return (
    <div className={cx(style["search-area"], style["mb-3"])}>
      <span className={style["searchicon"]}>
        <Icon icon="ui-search" />
      </span>
      <Input
        type="text"
        id={`search-${props.searchItem}`}
        className={style["form-control"]}
        placeholder={`Search ${props.searchItem}`}
        onChange={e => props.setSearchvalue(e.target.value)}
        autoComplete="off" //need to discard this autocomplete once ui input field is marged
      />
    </div>
  );
};
SearchComponent.propTypes = {
  searchItem: PropTypes.string,
  setSearchvalue: PropTypes.func.isRequired,
};

const Region = props => {
  const dispatch = useDispatch();

  const regions = useSelector(state => state?.editor?.availableRegionLists?.regions);
  const asstesLoading = useSelector(state => state?.editor?.regionLoading);

  const [searchRegionValue, setSearchRegionValue] = useState("");
  const [hoverActive, setHoverActive] = useState();
  const [hoverActiveIndex, setHoverActiveIndex] = useState();

  //1st time world map svg will be selected as by defult region
  useEffect(() => {
    if (regions?.length > 0 && !props.svgContent && !props.editable)
      props.getSvg(regions[0].image, regions[0].name, "region");
  }, [regions]);

  //fetch region api if not present
  useEffect(() => {
    if (!regions) dispatch(fetchRegionList());
  }, []);

  // Available regions list modification based on search result
  const searchedRegionsList = regions?.filter(region =>
    region.name.toLowerCase().includes(searchRegionValue.toLowerCase())
  );

  const loadSvg = region => {
    props.setSvgContent(null);
    document.getElementById("mapLoader").style.display = "flex";

    setTimeout(() => {
      props.getSvg(region.image, region.name, "region");
    }, 100);
  };

  return (
    <>
      <SearchComponent setSearchvalue={setSearchRegionValue} searchItem={"Regions"} />

      <ul
        className={cx(
          style["row"],
          style["region-list"],
          style["customScroll"],
          style["scroll-Y"],
          !asstesLoading && searchedRegionsList?.length === 0 ? "row-cols-1" : "row-cols-2"
        )}>
        {asstesLoading && <AssetsLoader count={20} />}

        {!asstesLoading &&
          searchedRegionsList?.length > 0 &&
          searchedRegionsList?.map((region, idx) => (
            <li key={idx} className={cx(style["col"], style["skeleton-loader-area"], style["region-item"])}>
              <div
                className={cx(style["asset-item"])}
                onClick={() => loadSvg(region)}
                onMouseEnter={() => {
                  setHoverActive(true), setHoverActiveIndex(idx);
                }}
                onMouseLeave={() => {
                  setHoverActive(false), setHoverActiveIndex(false);
                }}>
                <div className={style["item-holder"]}>
                  <img
                    className={cx({
                      [style["hov-active"]]: hoverActive && hoverActiveIndex === idx,
                    })}
                    src={region.image}
                  />
                </div>
              </div>
              <span className={style["region-name"]}>{region.name}</span>
            </li>
          ))}

        {!asstesLoading && searchedRegionsList?.length === 0 && (
          <div className={cx(style["alert"], style["alert-block"], style["alert-danger"], style["w-100"])}>
            No results found
          </div>
        )}
      </ul>
    </>
  );
};
Region.propTypes = {
  getSvg: PropTypes.func.isRequired,
  svgContent: PropTypes.object,
  editable: PropTypes.object,
  setSvgContent: PropTypes.func,
};

const Countries = props => {
  const dispatch = useDispatch();

  const resourceOptions = useSelector(state => state?.editor?.availableCountryLists?.maps);

  const [countries, setCountries] = useState();
  const [searchCountryValue, setSearchCountryValue] = useState("");

  //fetch countries
  useEffect(() => {
    setCountries(resourceOptions);
  }, [resourceOptions]);

  //fetch Country api
  useEffect(() => {
    dispatch(fetchCountryList());
  }, []);

  // Available Countries list modification based on search result
  const searchedCountriesList = countries?.filter(country =>
    country.name.toLowerCase().includes(searchCountryValue.toLowerCase())
  );

  const loadSvg = country => {
    props.setSvgContent(null);
    document.getElementById("mapLoader").style.display = "block";

    setTimeout(() => {
      props.getSvg(country.image, country.name, "country");
    }, 100);
  };

  return (
    <>
      <SearchComponent setSearchvalue={setSearchCountryValue} searchItem={"Countries"} />

      <ul className={cx(style["countries-list"], style["customScroll"], style["scroll-Y"])}>
        {searchedCountriesList?.map((country, idx) => (
          <React.Fragment key={idx}>
            {(idx === 0 || country.name[0] !== searchedCountriesList[idx - 1]?.name[0]) && (
              <li>
                <h6 className={style["fw-7"]}>{country.name[0]}</h6>
              </li>
            )}
            <li className={cx(style["skeleton-loader-area"])}>
              <span className={cx(style["country-name"], style["w-100"])} onClick={() => loadSvg(country)}>
                {country.name}
              </span>
            </li>
          </React.Fragment>
        ))}

        {searchedCountriesList?.length === 0 && (
          <div className={cx(style["alert"], style["alert-block"], style["alert-danger"])}>No results found</div>
        )}
      </ul>
    </>
  );
};
Countries.propTypes = {
  getSvg: PropTypes.func.isRequired,
  setSvgContent: PropTypes.func,
};

const TableColor = props => {
  const [isTableColorClicked, setIsTableColorClicked] = useState();

  const targetModalContainer = document.getElementById("editorBody");

  return (
    <UncontrolledDropdown
      className={cx(
        style["color-section-block"],
        style["color-picker-dropdown"],
        style["d-flex"],
        style["justify-content-center"],
        props.disableDisplayIndex?.includes(props.index) ? `disabled` : `show`
      )}>
      <DropdownToggle tag="a" className={cx(style["p-0"], style["d-flex"])}>
        <div
          onClick={() => setIsTableColorClicked(true)}
          style={
            props.entry[props.tableHerader] === "transparent"
              ? {
                  backgroundImage: `url(${contextualConfig.colorUrl.midium})`,
                  backgroundSize: "cover",
                  width: "20px",
                  height: "20px",
                }
              : { backgroundColor: props.entry[props.tableHerader], width: "20px", height: "20px" }
          }></div>
      </DropdownToggle>

      {isTableColorClicked && (
        <DropdownMenu
          container={targetModalContainer}
          className={cx(style["border-0"], style["p-0"], style["map-color-dropdown"])}>
          <ColorPicker
            color={!props.selectedAreaColor ? props.entry[props.tableHerader] : props.selectedAreaColor}
            setColor={props.setSelectedAreaColor}
            eyedropperColor={props.eyedropperColor}
            setEyedropperColor={props.setEyedropperColor}
            setUpdateContext={props.setupadateData}
          />
        </DropdownMenu>
      )}
    </UncontrolledDropdown>
  );
};
TableColor.propTypes = {
  disableDisplayIndex: PropTypes.array,
  index: PropTypes.number,
  entry: PropTypes.object,
  tableHerader: PropTypes.string,
  selectedAreaColor: PropTypes.string,
  setSelectedAreaColor: PropTypes.func,
  eyedropperColor: PropTypes.string,
  setEyedropperColor: PropTypes.func,
  setupadateData: PropTypes.func,
};

const TableData = props => {
  let tableHeraders = [];
  Object.entries(props.mapData[0]).forEach(key => {
    tableHeraders.push(key[0]);
  });

  const [searchTableData, setSearchTableData] = useState("");
  const [activeAreaIndex, setActiveAreaIndex] = useState();
  const [selectedAreaColor, setSelectedAreaColor] = useState();
  const [disableDisplayIndex, setDisableDisplayIndex] = useState([]);
  const [eyedropperColor, setEyedropperColor] = useState();
  const [upadateData, setupadateData] = useState();
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    top: 0,
    left: 0,
    columnKey: null,
  });
  const [tableHeight, setTableHeight] = useState(390);

  const rightContextRef = useRef(null);
  useOnClickOutside(rightContextRef, () => contextMenu.visible && hideContextMenu()); // Handling contextMenu outside click

  const canAddNewColumn = tableHeraders.length < 9;
  const isDeleteDisabled =
    tableHeraders.length <= 5 ||
    (contextMenu.columnKey && tableHeraders.length > 5 && tableHeraders.indexOf(contextMenu.columnKey) <= 3) ||
    (props.dataValue === contextMenu.columnKey && props.isShowAreaValueMap);

  // Available tableData list modification based on search result
  const searchedTableDataList = props.mapData?.filter(md =>
    md[props.mapAreaName]?.toLowerCase().includes(searchTableData?.toLowerCase())
  );

  //update display status of selected area of map
  const toggleDisplayArea = status => {
    let activeArea = searchedTableDataList[activeAreaIndex]?.Id;
    let displayStatus = searchedTableDataList[activeAreaIndex]?.Display;
    let mapDataIndex = props.mapData.findIndex(md => md.Id === activeArea);
    let newMapData = Object.assign([...props.mapData]);

    document.querySelectorAll(`#mapContent svg *`).forEach(element => {
      if (element.tagName === "path" && element.getAttribute("id") === activeArea) {
        element.style.display = displayStatus ? "none" : "block";
      }
    });

    newMapData = Object.assign([...newMapData], {
      [mapDataIndex]: {
        ...newMapData[mapDataIndex],
        Display: !displayStatus,
      },
    });

    props.updateMapData(newMapData);

    //For disbale the row which country or states are hide
    let hideIndexes = [...disableDisplayIndex];
    if (status === "add") {
      hideIndexes.push(mapDataIndex);
    } else {
      hideIndexes = hideIndexes.filter(hi => hi !== mapDataIndex);
    }
    setDisableDisplayIndex(hideIndexes);
  };

  const addNewColumn = () => {
    if (canAddNewColumn) {
      let newColumnIndex = tableHeraders.length - 3;

      // if conflicts then increment the index
      while (tableHeraders.includes(`Column_${newColumnIndex}`)) {
        newColumnIndex++;
      }

      const newColumnKey = `Column_${newColumnIndex}`;
      const newMapData = props.mapData.map(entry => ({
        ...entry,
        [newColumnKey]: "",
      }));

      // Update the map data after adding column
      props.updateMapData(newMapData);
    }
  };

  const handleContextMenu = (e, columnKey) => {
    e.preventDefault();

    // Update context menu state
    setContextMenu({
      visible: true,
      top: e.clientY,
      left: e.clientX,
      columnKey: columnKey,
    });
  };

  const hideContextMenu = () => {
    setContextMenu({
      ...contextMenu,
      visible: false,
    });
  };

  const handleAddColumn = () => {
    addNewColumn();
    hideContextMenu();
  };

  const handleDeleteColumn = () => {
    if (!isDeleteDisabled) {
      const { columnKey } = contextMenu;
      // copy of the map data
      const newMapData = [...props.mapData];
      // removing data from each entry
      newMapData.forEach(entry => {
        delete entry[columnKey];
      });
      // Updating map data after deleting the column
      props.updateMapData(newMapData);
    }
    hideContextMenu();
  };

  const handleRowHover = (entry, status) => {
    document.querySelectorAll(`#mapContent svg *`).forEach(element => {
      if (element.tagName === "path" && element.getAttribute("title") === entry[props.mapAreaName]) {
        element.style.opacity = status === "enter" ? 0.5 : 1;
      }
    });
  };

  const handleDoubleClick = (e, columnIndex, index = null) => {
    if (disableDisplayIndex?.includes(index)) {
      e.preventDefault();
      return;
    }

    if (columnIndex >= 4) {
      const block = e.currentTarget;

      if (block.getAttribute("contentEditable") !== "true") {
        // Replace existing spaces with non-breaking spaces
        const content = block.innerText.replace(/ /g, "\u00A0");
        block.innerText = ""; // Clear the existing content

        // Create a text node for each character, including spaces
        content.split("").forEach(char => {
          const newNode = document.createTextNode(char);
          block.appendChild(newNode);
        });

        // Make the block editable and focus
        block.contentEditable = true;
        block.focus();

        // Move caret to the end
        const selection = window.getSelection();
        const range = document.createRange();
        const childNodes = block.childNodes;
        const lastNode = childNodes.length > 0 ? childNodes[childNodes.length - 1] : null;

        if (lastNode) {
          range.setStart(lastNode, lastNode.length);
          range.collapse(true);

          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    }
  };

  //For table Data (td)
  const handleBlurForTableData = (e, rowIndex, columnHeader) => {
    e.currentTarget.contentEditable = false;

    let mapDataIndex = props.mapData.findIndex(md => md.Id === rowIndex);

    const inputValue = e.target.innerText;
    const parsedValue = parseFloat(inputValue);

    // Keep only the digits within the allowed range (-1 billion to 1 billion)
    const restrictedValue = isNaN(parsedValue) ? "" : Math.min(Math.max(parsedValue, -1000000000), 1000000000);

    if (!isNaN(restrictedValue)) {
      // Checking if restrictedValue is a valid number or an empty string before applying toFixed
      let formattedValue =
        restrictedValue === ""
          ? ""
          : restrictedValue % 1 === 0
          ? restrictedValue.toFixed(0)
          : restrictedValue.toFixed(2);
      const newMapData = [...props.mapData];
      formattedValue = formattedValue === "" ? "" : parseFloat(formattedValue);
      newMapData[mapDataIndex][columnHeader] = formattedValue;
      e.target.innerText = formattedValue;

      // Update the mapData
      props.updateMapData(newMapData);
      if (props.legendContent) props.setLegendContent(); // reset legend content if, update table during map update
      if (props.isShowAreaValueMap) props.updateDataAcoordingAreaValue(); // update area value map according new value
    }
  };

  //For table Head(th)
  const handleBlurForTableHead = (e, columnHeader) => {
    e.currentTarget.contentEditable = false;

    const newTrimmedHeader =
      e.target.innerText.trim().charAt(0).toUpperCase() + e.target.innerText.trim().slice(1).replace(/\s/g, "_");

    const lowercasedHeaders = tableHeraders.map(header => header.toLowerCase());

    // If new header is blank or already exists, keep it as it is
    if (newTrimmedHeader.length === 0 || lowercasedHeaders.includes(newTrimmedHeader.toLowerCase())) {
      e.target.innerText = columnHeader.replace(/_/g, "\u00A0");
      return;
    }

    // Update if there are changes
    if (tableHeraders.includes(columnHeader) && newTrimmedHeader !== columnHeader) {
      const newMapData = props.mapData.map(entry => {
        const updatedEntry = {};

        Object.keys(entry).forEach(key => {
          // Update the header if it matches the columnHeader
          if (key === columnHeader) {
            updatedEntry[newTrimmedHeader] = entry[key];
          } else {
            updatedEntry[key] = entry[key];
          }
        });

        return updatedEntry;
      });

      // Updating mapData with the modified array
      props.updateMapData(newMapData);

      // if updated column header is set as area value
      if (columnHeader === props.dataValue) {
        props.setDataValue(newTrimmedHeader);
        document.querySelector(`#mapContent svg`).setAttribute("data-area-value", newTrimmedHeader); //DOM attribute update
        if (props.legendContent) props.setLegendContent(); // reset legend content if, update table during map update
        if (props.isShowAreaValueMap) props.updateDataAcoordingAreaValue(); // update area value map according new value
      }
    }
  };

  const validateInputForTableHead = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.target.blur();
    }

    if (e.key === "-") {
      e.preventDefault();
    }

    if (e.key === " ") {
      e.preventDefault();
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      range.insertNode(document.createTextNode("\u00A0"));
      range.collapse(false);
    }

    // Check if the first character is a number
    if (e.key.length === 1 && /^\d$/.test(e.key) && e.target.textContent.length === 0) {
      e.preventDefault();
    }
  };

  const validateInputForTableData = e => {
    //regular expression for allowed key
    const allowedKeysRegex = /^-?[\d.]*$|^Backspace$|^Delete$|^ArrowLeft$|^ArrowRight$|^Enter$/;
    const currentValue = e.target.innerText;
    const cursorPosition = window.getSelection().getRangeAt(0).startOffset;
    const decimalIndex = currentValue.indexOf(".");

    if ((e.ctrlKey || e.metaKey) && ["a", "c", "x", "v"].includes(e.key.toLowerCase())) {
      // Allow Ctrl/Cmd + A, Ctrl/Cmd + C, Ctrl/Cmd + X, and Ctrl/Cmd + V
      return;
    }

    if (!allowedKeysRegex.test(e.key)) {
      e.preventDefault();
    }

    if (e.key === "." && currentValue.includes(".")) {
      // Prevent entering more than one decimal point
      e.preventDefault();
    }

    if (currentValue.includes("-") && e.key === "-") {
      // Prevent entering more than one negative sign
      e.preventDefault();
    }

    if (
      decimalIndex !== -1 &&
      cursorPosition > decimalIndex &&
      e.key !== "Backspace" &&
      e.key !== "Delete" &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight"
    ) {
      // Check for two digits after the decimal point
      const digitsAfterDecimal = currentValue.split(".")[1]?.length || 0;
      if (digitsAfterDecimal >= 2) {
        e.preventDefault();
      }
    }

    if (e.key === "Enter") {
      e.preventDefault();
      e.target.blur();
    }

    // Prevent negative sign if it's not the first character
    if (e.key === "-" && cursorPosition > 0) {
      e.preventDefault();
    }
  };

  const handlePasteForTableHead = e => {
    e.preventDefault();

    //text without formatting
    const plainText = e.clipboardData.getData("text/plain");
    // Replace spaces with non-breaking spaces
    const textToInsert = plainText.replace(/ /g, "\u00A0");

    // Inserting text at the current caret position
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      selection.deleteFromDocument();
      selection.getRangeAt(0).insertNode(document.createTextNode(textToInsert));
    }
  };

  const handlePasteForTableData = e => {
    e.preventDefault();

    // text without formatting
    const plainText = (e.clipboardData || window.clipboardData).getData("text/plain");

    // parseFloat
    const parsedValue = parseFloat(plainText);

    // Validation
    if (!isNaN(parsedValue)) {
      // toFixed for two digits after the decimal point
      const formattedValue = parsedValue % 1 === 0 ? parsedValue.toFixed(0) : parsedValue.toFixed(2);

      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(formattedValue));
      }
    }
  };

  //update color if user chose color from eyedropper
  useEffect(() => {
    if (eyedropperColor) {
      setSelectedAreaColor(eyedropperColor);
      setEyedropperColor();
    }
  }, [eyedropperColor]);

  //update color of selected area of map
  useEffect(() => {
    if (selectedAreaColor) {
      let activeArea = searchedTableDataList[activeAreaIndex]?.Id;
      let mapDataIndex = props.mapData.findIndex(md => md.Id === activeArea);
      let newMapData = Object.assign([...props.mapData]);

      document.querySelectorAll(`#mapContent svg *`).forEach(element => {
        if (element.tagName === "path" && element.getAttribute("id") === activeArea) {
          element.style.fill = selectedAreaColor;
          if (document.querySelector("#mapContent svg").getAttribute("data-border-on") === "false")
            element.style.stroke = selectedAreaColor;
        }
      });

      newMapData = Object.assign([...newMapData], {
        [mapDataIndex]: {
          ...newMapData[mapDataIndex],
          Color: selectedAreaColor,
        },
      });

      upadateData && props.updateMapData(newMapData);
      if (props.isShowAreaValueMap) props.switchOffAreaValueToggle(); // if area value is on then on color change keep the toggle off
      if (props.legendContent) props.setLegendContent(); // if area value legend is on during edit, then reset the legend
    }
  }, [selectedAreaColor]);

  useEffect(() => {
    let hideIndexes = [];

    searchedTableDataList.forEach((dataList, index) => {
      if (!dataList.Display) hideIndexes.push(index);
    });

    setDisableDisplayIndex(hideIndexes);
  }, []);

  // To Adjust Table height
  useEffect(() => {
    const tableElement = document.querySelector(".map-table-wrap .table");
    const lgScreen = document.querySelector(".full-screen-modal");

    if (!tableElement) {
      setTableHeight("auto");
      return;
    }

    const newHeight = tableElement.offsetHeight;

    if (!lgScreen) {
      setTableHeight(Math.min(newHeight, 390));
    } else {
      const maxHeight = lgScreen.offsetHeight - 210;
      setTableHeight(Math.min(newHeight, maxHeight));
    }
  }, [searchedTableDataList]);

  return (
    <>
      <SearchComponent setSearchvalue={setSearchTableData} searchItem={props.mapAreaName} />

      <div
        className={cx(style["d-flex"], style["align-items-center"], style["map-table-section"])}
        style={{ height: tableHeight }}>
        <div
          className={cx(style["map-table-wrap"], style["customScroll"], style["scroll-X"], style["scroll-Y"])}
          style={{ height: tableHeight }}>
          {searchedTableDataList?.length > 0 && (
            <Table bordered>
              <thead>
                <tr>
                  {tableHeraders.map((tableHerader, idx) => (
                    <React.Fragment key={idx}>
                      {idx === 0 && <th></th>}
                      {tableHerader !== "Id" && (
                        <th
                          onContextMenu={e => handleContextMenu(e, tableHerader)}
                          onDoubleClick={e => handleDoubleClick(e, idx)}
                          onBlur={e => handleBlurForTableHead(e, tableHerader)}
                          onKeyDown={e => validateInputForTableHead(e)}
                          onPaste={e => handlePasteForTableHead(e)}>
                          {tableHerader.replace(/_/g, "\u00A0")}
                        </th>
                      )}
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>
                {searchedTableDataList?.map((entry, index) => (
                  <tr
                    key={index}
                    className={!entry["Display"] ? `disabled` : `show`}
                    onMouseDown={() => {
                      setActiveAreaIndex(index), setSelectedAreaColor();
                    }}
                    onMouseEnter={() => handleRowHover(entry, "enter")}
                    onMouseLeave={() => handleRowHover(entry, "leave")}>
                    <th scope="row">{index + 1}</th>

                    {tableHeraders.map((tableHerader, headerIndex) => (
                      <React.Fragment key={headerIndex}>
                        {tableHerader === "Display" && (
                          <td>
                            {entry[tableHerader] && (
                              <div
                                className={cx({
                                  [style["disabled"]]: props.mapData.length === 1,
                                })}>
                                <Icon
                                  icon="eye-cross"
                                  id={`display-hover-hide-${index}`}
                                  onClick={() => toggleDisplayArea("add")}
                                />
                                <UncontrolledTooltip placement="top" target={`display-hover-hide-${index}`}>
                                  Hide
                                </UncontrolledTooltip>
                              </div>
                            )}
                            {!entry[tableHerader] && (
                              <div>
                                <Icon
                                  icon="eye2"
                                  id={`display-hover-show-${index}`}
                                  onClick={() => toggleDisplayArea("remove")}
                                />
                                <UncontrolledTooltip placement="top" target={`display-hover-show-${index}`}>
                                  Show
                                </UncontrolledTooltip>
                              </div>
                            )}
                          </td>
                        )}

                        {tableHerader === "Color" && (
                          <td>
                            <TableColor
                              disableDisplayIndex={disableDisplayIndex}
                              index={index}
                              entry={entry}
                              tableHerader={tableHerader}
                              selectedAreaColor={selectedAreaColor}
                              setSelectedAreaColor={setSelectedAreaColor}
                              eyedropperColor={eyedropperColor}
                              setEyedropperColor={setEyedropperColor}
                              upadateData={upadateData}
                              setupadateData={setupadateData}
                            />
                          </td>
                        )}

                        {tableHerader !== "Color" && tableHerader !== "Display" && tableHerader !== "Id" && (
                          <td
                            className={cx(style["text-left"])}
                            onDoubleClick={e => {
                              handleDoubleClick(e, headerIndex, index);
                            }}
                            onBlur={e => handleBlurForTableData(e, entry.Id, tableHerader)}
                            onKeyDown={e => validateInputForTableData(e)}
                            onPaste={e => handlePasteForTableData(e)}>
                            {entry[tableHerader]}
                          </td>
                        )}
                      </React.Fragment>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          {searchedTableDataList?.length === 0 && (
            <div className={cx(style["alert"], style["alert-block"], style["alert-danger"])}>No results found</div>
          )}
        </div>

        {searchedTableDataList?.length > 0 && (
          <div className={cx(style["addNewColumn"], { [style["disabled"]]: !canAddNewColumn })}>
            <span className={cx(style["d-flex"])} onClick={addNewColumn}>
              <Icon icon="ui-plus" />
            </span>
          </div>
        )}

        {contextMenu.visible && (
          <div
            ref={rightContextRef}
            className={cx(style["add-delete-column"])}
            id="contextMenu"
            style={{
              top: contextMenu.top,
              left: contextMenu.left,
            }}>
            <ul className={style["toolbar-group"]}>
              <li onClick={handleAddColumn} className={cx({ [style["disabled"]]: !canAddNewColumn })}>
                <Icon icon="ui-plus" additionalclass={"mr-2"} /> Add Column
              </li>
              <li onClick={handleDeleteColumn} className={cx({ [style["disabled"]]: isDeleteDisabled })}>
                <Icon icon="ui-trash" additionalclass={"mr-2"} /> Delete Column
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};
TableData.propTypes = {
  mapData: PropTypes.array,
  updateMapData: PropTypes.func.isRequired,
  mapAreaName: PropTypes.string.isRequired,
  isShowAreaValueMap: PropTypes.bool,
  switchOffAreaValueToggle: PropTypes.func.isRequired,
  dataValue: PropTypes.string,
  setDataValue: PropTypes.func,
  updateDataAcoordingAreaValue: PropTypes.func,
  legendContent: PropTypes.object,
  setLegendContent: PropTypes.func,
};

const ColorAndBorder = props => {
  const targetModalContainer = document.getElementById("editorBody");

  const [isMapBorderEnabled, setIsMapBorderEnabled] = useState();
  const [mapBorderColor, setMapBorderColor] = useState();
  const [eyedropperColorForBorder, setEyedropperColorForBorder] = useState();
  const [borderIconBucketColor, setBorderIconBucketColor] = useState("light");
  const [eyedropperColorForMap, setEyedropperColorForMap] = useState();
  const [mapIconBucketColor, setMapIconBucketColor] = useState("light");
  const [loadCurrentFormatting, setLoadCurrentFormatting] = useState(true);

  const handleMapBorderToggle = () => {
    let elem = document.querySelector(`#mapContent svg`);

    // Switch off border toggle
    if (isMapBorderEnabled) {
      // set area color to hide the border
      document.querySelectorAll(`#mapContent svg *`).forEach(element => {
        if (element.tagName === "path") {
          element.style.stroke = element.style.fill;
        }
      });
      setMapBorderColor(false);
      elem.setAttribute("data-border-on", false);
    }
    // Switch on border toggle
    else {
      setMapBorderColor(widgetConfig[MAP].default_border_color); // set map default border color
      elem.setAttribute("data-border-on", true);
    }

    setLoadCurrentFormatting(false);
    setIsMapBorderEnabled(!isMapBorderEnabled);
  };

  // change map border color
  useEffect(() => {
    if (!loadCurrentFormatting && mapBorderColor) {
      // DOM upadate
      document.querySelectorAll(`#mapContent svg *`).forEach(element => {
        if (element.tagName === "path") {
          element.style.stroke = mapBorderColor;
        }
      });

      document.querySelector(`#mapContent svg`).setAttribute("data-map-border-color", mapBorderColor);
    }

    //set icon bucket color
    if (mapBorderColor)
      setBorderIconBucketColor(
        changeBucketColorAccordingBackground(
          mapBorderColor === "transparent" ? mapBorderColor : hexToRGB(mapBorderColor)
        )
      );
  }, [mapBorderColor]);

  useEffect(() => {
    if (eyedropperColorForBorder) setMapBorderColor(eyedropperColorForBorder);
  }, [eyedropperColorForBorder]);

  // change map color
  useEffect(() => {
    if (!loadCurrentFormatting && props.mapColor) {
      // DOM update
      document.querySelectorAll(`#mapContent svg *`).forEach(element => {
        if (element.tagName === "path") {
          element.style.fill = props.mapColor;
        }
      });

      document.querySelector(`#mapContent svg`).setAttribute("data-map-color", props.mapColor); // set new color in DOM Attribute

      // update map data
      const newMapData = props.mapData.map(entry => ({
        ...entry,
        Color: props.mapColor,
      }));

      props.updateMapData(newMapData);
      if (!isMapBorderEnabled) setMapBorderColor(props.mapColor); // change map border color according map color if border color swich is off
      if (props.isShowAreaValueMap) props.switchOffAreaValueToggle(); // if area value is on then on color change keep the toggle off
      if (props.legendContent) props.setLegendContent(); // if area value legend is on during edit, then reset the legend
    }

    //set icon bucket color
    if (props.mapColor)
      setMapIconBucketColor(
        changeBucketColorAccordingBackground(
          props.mapColor === "transparent" ? props.mapColor : hexToRGB(props.mapColor)
        )
      );
  }, [props.mapColor]);

  useEffect(() => {
    if (eyedropperColorForMap) props.setMapColor(eyedropperColorForMap);
  }, [eyedropperColorForMap]);

  // load current formatting
  useEffect(() => {
    let elem = document.querySelector(`#mapContent svg`);

    setLoadCurrentFormatting(true);
    setIsMapBorderEnabled(elem.getAttribute("data-border-on") === "true" ? true : false);
    props.setMapColor(elem.getAttribute("data-map-color"));
    setMapBorderColor(elem.getAttribute("data-map-border-color"));
  }, []);

  return (
    <>
      <h6 className={cx(style["mb-4"], style["fw-7"], style["font-15"], style["d-flex"], style["align-items-center"])}>
        <Link
          to="#"
          className={cx(style["d-flex"], style["color-black"])}
          onClick={e => (e.preventDefault(), props.setSettingsTabCurrentCaption("*"))}>
          <Icon icon="ui-arrow-left" additionalclass="mr-2" />
        </Link>
        Color and Border
      </h6>

      <div className={cx(style["map-settings-option"], style["w-75"], style["pr-0"])}>
        <div className={cx(style["custom-control"], style["custom-switch"], style["mb-3"])}>
          <Input
            type="checkbox"
            className={style["custom-control-input"]}
            defaultChecked={isMapBorderEnabled}
            id="customSwitchForMapBorder"
            value={isMapBorderEnabled}
            onClick={handleMapBorderToggle}
          />

          <label className={style["custom-control-label"]} htmlFor="customSwitchForMapBorder">
            Show Border
          </label>
        </div>

        <div className={cx(style["line-controls-wrap"], style["margin-color"])}>
          <div className={cx(style["slidelabel"], style["pl-0"])}>Map Color</div>
          <UncontrolledDropdown
            setActiveFromChild
            className={cx(style["color-section-block"], style["color-picker-dropdown"])}
            onClick={() => setLoadCurrentFormatting(false)}>
            <DropdownToggle tag="a" className={style["p-0"]}>
              <Icon
                additionalclass={mapIconBucketColor}
                icon="ui-fill-color2"
                style={
                  props.mapColor === "transparent"
                    ? {
                        backgroundImage: `url(${contextualConfig.colorUrl.midium})`,
                        backgroundSize: "cover",
                      }
                    : { backgroundColor: props.mapColor }
                }
              />
            </DropdownToggle>
            <DropdownMenu
              container={targetModalContainer}
              className={cx(style["border-0"], style["p-0"], style["map-color-dropdown"])}>
              <ColorPicker
                color={props.mapColor}
                setColor={props.setMapColor}
                eyedropperColor={eyedropperColorForMap}
                setEyedropperColor={setEyedropperColorForMap}
              />
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>

        {isMapBorderEnabled && (
          <div className={cx(style["line-controls-wrap"], style["margin-color"])}>
            <div className={cx(style["slidelabel"], style["pl-0"])}>Border Color</div>
            <UncontrolledDropdown
              setActiveFromChild
              className={cx(style["color-section-block"], style["color-picker-dropdown"])}
              onClick={() => setLoadCurrentFormatting(false)}>
              <DropdownToggle tag="a" className={style["p-0"]}>
                <Icon
                  additionalclass={borderIconBucketColor}
                  icon="ui-fill-color2"
                  style={
                    mapBorderColor === "transparent"
                      ? {
                          backgroundImage: `url(${contextualConfig.colorUrl.midium})`,
                          backgroundSize: "cover",
                        }
                      : { backgroundColor: mapBorderColor }
                  }
                />
              </DropdownToggle>
              <DropdownMenu
                container={targetModalContainer}
                className={cx(style["border-0"], style["p-0"], style["map-color-dropdown"])}>
                <ColorPicker
                  color={mapBorderColor}
                  setColor={setMapBorderColor}
                  eyedropperColor={eyedropperColorForBorder}
                  setEyedropperColor={setEyedropperColorForBorder}
                />
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )}
      </div>
    </>
  );
};
ColorAndBorder.propTypes = {
  mapData: PropTypes.array,
  updateMapData: PropTypes.func.isRequired,
  setSettingsTabCurrentCaption: PropTypes.func.isRequired,
  mapColor: PropTypes.string,
  setMapColor: PropTypes.func,
  isShowAreaValueMap: PropTypes.bool,
  switchOffAreaValueToggle: PropTypes.func.isRequired,
  legendContent: PropTypes.object,
  setLegendContent: PropTypes.func,
};

const Caption = props => {
  const availableCaptionTypes = ["Light", "Dark"];

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle2 = () => setDropdownOpen(prevState => !prevState);

  const toggleDataCaption = () => {
    let elem = document.querySelector(`#mapContent svg`);

    // switch off caption
    if (props.isShowCaption) {
      elem.setAttribute("data-caption-on", false);
      elem.setAttribute("data-caption-type", widgetConfig[MAP].default_caption_type);

      props.setCaptionType(widgetConfig[MAP].default_caption_type);
    }
    // switch on caption
    else {
      elem.setAttribute("data-caption-on", true);
    }

    props.setIsShowCaption(!props.isShowCaption);
  };

  // Load current formatting
  useEffect(() => {
    let elem = document.querySelector(`#mapContent svg`);

    props.setIsShowCaption(elem.getAttribute("data-caption-on") === "true" ? true : false);
    props.setCaptionType(elem.getAttribute("data-caption-type"));
  }, []);

  return (
    <>
      <h6 className={cx(style["mb-4"], style["fw-7"], style["font-15"], style["d-flex"], style["align-items-center"])}>
        <Link
          to="#"
          className={cx(style["d-flex"], style["color-black"])}
          onClick={e => (e.preventDefault(), props.setSettingsTabCurrentCaption("*"))}>
          <Icon icon="ui-arrow-left" additionalclass="mr-2" />
        </Link>
        Caption
      </h6>
      <div className={cx(style["map-settings-option"], style["pr-0"])}>
        <div className={cx(style["custom-control"], style["custom-switch"], style["mb-3"])}>
          <Input
            type="checkbox"
            className={style["custom-control-input"]}
            defaultChecked={props.isShowCaption}
            id="customSwitchForCaption"
            value={props.isShowCaption}
            onClick={toggleDataCaption}
          />
          <label className={cx(style["custom-control-label"])} htmlFor="customSwitchForCaption">
            Show Caption on Hover
          </label>
        </div>

        {props.isShowCaption && (
          <div className={cx(style["line-controls-wrap"], style["flex-column"], style["align-items-start"])}>
            <div className={cx(style["slidelabel"], style["pr-0"], style["w-100"])}>Caption Style</div>
            <Dropdown isOpen={dropdownOpen} toggle={toggle2} className={style["border-style"]}>
              <DropdownToggle className={style["text-capitalize"]}>
                {props.captionType}
                <Icon icon="ui-arrow-down" />
              </DropdownToggle>

              <DropdownMenu className={cx(style["shadow"], style["border-0"], style["rounded"])}>
                {availableCaptionTypes.map(availableCaptionType => (
                  <React.Fragment key={availableCaptionType}>
                    <DropdownItem
                      className={cx(style["text-capitalize"], {
                        [style["active"]]: availableCaptionType === props.captionType,
                      })}
                      tag="a"
                      onClick={() => {
                        props.setCaptionType(availableCaptionType),
                          document
                            .querySelector(`#mapContent svg`)
                            .setAttribute("data-caption-type", availableCaptionType);
                      }}>
                      {/* <span className={availableCaptionType}></span> */}
                      {availableCaptionType}
                    </DropdownItem>
                  </React.Fragment>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        )}
      </div>
    </>
  );
};
Caption.propTypes = {
  isShowCaption: PropTypes.bool,
  setIsShowCaption: PropTypes.func,
  captionType: PropTypes.string,
  setCaptionType: PropTypes.func.isRequired,
  setSettingsTabCurrentCaption: PropTypes.func.isRequired,
};

const Data = props => {
  const targetModalContainer = document.getElementById("editorBody");
  const availableLegendPositions = ["Right", "Left", "Bottom", "Top"];
  const availableDataTypes = [];
  Object.entries(props.mapData[0]).forEach(key => {
    if (!["Id", "Display", "Color", "Countries", "States"].includes(key[0])) {
      availableDataTypes.push(key[0]);
    }
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [eyeDropperForStartColor, setEyeDropperForStartColor] = useState();
  const [startcolorIconBucket, setStartcolorIconBucket] = useState();
  const [eyeDropperForEndColor, setEyeDropperForEndColor] = useState();
  const [endColorIconBucket, setEndColorIconBucket] = useState();
  const [dropdownOpenForLegend, setDropdownOpenForLegend] = useState(false);
  const [showLegend, setShowLegend] = useState();

  const toggle2 = () => setDropdownOpen(prevState => !prevState);

  const toggle3 = () => setDropdownOpenForLegend(prevState => !prevState);

  const toggleAreaValueButton = () => {
    // remove area value color if switch is off
    if (props.isShowAreaValueMap) {
      let elem = document.querySelector(`#mapContent svg`);

      // DOM update
      document.querySelectorAll(`#mapContent svg *`).forEach(element => {
        if (element.tagName === "path") {
          element.style.fill = elem.getAttribute("data-map-color");
          if (document.querySelector("#mapContent svg").getAttribute("data-border-on") === "false")
            element.style.stroke = elem.getAttribute("data-map-color");
        }
      });

      //DOM attribute update
      elem.setAttribute("data-area-value-on", false);
      elem.setAttribute("data-area-value", availableDataTypes[0]);
      elem.removeAttribute("data-area-start-color");
      elem.removeAttribute("data-area-end-color");
      elem.setAttribute("data-area-legend-on", false);
      elem.removeAttribute("data-area-legend-position");

      // update mapdata
      const newMapData = props.mapData.map(entry => ({
        ...entry,
        Color: props.mapColor ? props.mapColor : widgetConfig[MAP].default_color,
      }));

      props.updateMapData(newMapData);

      // reset start color, end color, legend toggle and legend position
      props.setStartColor();
      props.setEndColor();
      setShowLegend();
      props.setLegendPosition();
    }
    // switch on area value map toggle and set default start color and end color
    else {
      let elem = document.querySelector(`#mapContent svg`);

      //DOM attribute update
      elem.setAttribute("data-area-value-on", true);
      elem.setAttribute("data-area-value", availableDataTypes[0]);
      elem.setAttribute("data-area-start-color", widgetConfig[MAP].default_start_color);
      elem.setAttribute("data-area-end-color", widgetConfig[MAP].default_end_color);

      //local state update
      props.setDataValue(availableDataTypes[0]);
      props.setStartColor(widgetConfig[MAP].default_start_color);
      props.setEndColor(widgetConfig[MAP].default_end_color);
    }

    props.setIsShowAreaValueMap(!props.isShowAreaValueMap);
  };

  const toggleLegend = () => {
    let elem = document.querySelector(`#mapContent svg`);

    // switch off legend
    if (showLegend) {
      elem.setAttribute("data-area-legend-on", false);
      elem.removeAttribute("data-area-legend-position");
      props.setLegendPosition();
    }
    // switch on legend
    else {
      elem.setAttribute("data-area-legend-on", true);
      elem.setAttribute("data-area-legend-position", widgetConfig[MAP].default_legend_position);
      props.setLegendPosition(widgetConfig[MAP].default_legend_position);
    }

    setShowLegend(!showLegend);
  };

  useEffect(() => {
    if (eyeDropperForStartColor) props.setStartColor(eyeDropperForStartColor);
  }, [eyeDropperForStartColor]);

  useEffect(() => {
    if (eyeDropperForEndColor) props.setEndColor(eyeDropperForEndColor);
  }, [eyeDropperForEndColor]);

  useEffect(() => {
    if (props.startColor) {
      document.querySelector(`#mapContent svg`).setAttribute("data-area-start-color", props.startColor);

      setStartcolorIconBucket(
        changeBucketColorAccordingBackground(
          props.startColor === "transparent" ? props.startColor : hexToRGB(props.startColor)
        )
      );
    }
  }, [props.startColor]);

  useEffect(() => {
    if (props.endColor) {
      document.querySelector(`#mapContent svg`).setAttribute("data-area-end-color", props.endColor);

      setEndColorIconBucket(
        changeBucketColorAccordingBackground(
          props.endColor === "transparent" ? props.endColor : hexToRGB(props.endColor)
        )
      );
    }
  }, [props.endColor]);

  // set area value color depends on start color and end color
  useEffect(() => {
    if (props.startColor && props.endColor && props.isShowAreaValueMap) {
      props.updateDataAcoordingAreaValue();
    }
  }, [props.startColor, props.endColor, props.dataValue, props.isShowAreaValueMap]);

  // load current formatting
  useEffect(() => {
    let elem = document.querySelector(`#mapContent svg`);

    props.setIsShowAreaValueMap(elem.getAttribute("data-area-value-on") === "true" ? true : false);
    if (props.setLegendContent) props.setLegendContent();

    if (elem.getAttribute("data-area-value-on") === "true") {
      props.setDataValue(elem.getAttribute("data-area-value"));
      props.setStartColor(elem.getAttribute("data-area-start-color"));
      props.setEndColor(elem.getAttribute("data-area-end-color"));
      setShowLegend(elem.getAttribute("data-area-legend-on") === "true" ? true : false);
      if (elem.getAttribute("data-area-legend-on") === "true")
        props.setLegendPosition(elem.getAttribute("data-area-legend-position"));
    }
  }, []);

  return (
    <>
      <h6 className={cx(style["mb-3"], style["fw-7"], style["font-15"], style["d-flex"], style["align-items-center"])}>
        <Link
          to="#"
          className={cx(style["d-flex"], style["color-black"])}
          onClick={e => (e.preventDefault(), props.setSettingsTabCurrentCaption("*"))}>
          <Icon icon="ui-arrow-left" additionalclass="mr-2" />
        </Link>
        Data
      </h6>
      <div className={cx(style["map-settings-option"], style["pr-0"], style["data"])}>
        <div className={cx(style["custom-control"], style["custom-switch"], style["mb-3"])}>
          <Input
            type="checkbox"
            className={style["custom-control-input"]}
            defaultChecked={props.isShowAreaValueMap}
            id="customSwitchForAreaValueMap"
            value={props.isShowAreaValueMap}
            onClick={toggleAreaValueButton}
          />

          <label className={style["custom-control-label"]} htmlFor="customSwitchForAreaValueMap">
            Show Area Value Map
          </label>
        </div>

        {props.isShowAreaValueMap && (
          <>
            <div className={style["line-controls-wrap"]}>
              {/* <div className={cx(style["slidelabel"], style["pl-0"])}>Show Data Column</div> */}
              <Dropdown isOpen={dropdownOpen} toggle={toggle2} className={cx(style["border-style"])}>
                <DropdownToggle className={style["text-capitalize"]}>
                  <span className={cx(style["text-truncate"])}>{props.dataValue.replaceAll("_", " ")}</span>
                  <Icon icon="ui-arrow-down" />
                </DropdownToggle>

                <DropdownMenu className={cx(style["shadow"], style["border-0"], style["rounded"])}>
                  {availableDataTypes.map((availableDataType, idx) => (
                    <React.Fragment key={idx}>
                      <DropdownItem
                        className={cx(style["text-capitalize"], {
                          [style["active"]]: availableDataType === props.dataValue,
                        })}
                        tag="a"
                        onClick={() => {
                          props.setDataValue(availableDataType),
                            document
                              .querySelector(`#mapContent svg`)
                              .setAttribute("data-area-value", availableDataType);
                        }}>
                        {/* <span className={availableDataType}></span> */}
                        {availableDataType.replaceAll("_", " ")}
                      </DropdownItem>
                    </React.Fragment>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>

            <div className={cx(style["line-controls-wrap"], style["margin-color"])}>
              <div className={style["slidelabel"]}>Area-Value Start Color</div>
              <UncontrolledDropdown
                setActiveFromChild
                className={cx(style["color-section-block"], style["color-picker-dropdown"])}>
                <DropdownToggle tag="a" className={style["p-0"]}>
                  <Icon
                    additionalclass={startcolorIconBucket}
                    icon="ui-fill-color2"
                    style={
                      props.startColor === "transparent"
                        ? {
                            backgroundImage: `url(${contextualConfig.colorUrl.midium})`,
                            backgroundSize: "cover",
                          }
                        : { backgroundColor: props.startColor }
                    }
                  />
                </DropdownToggle>
                <DropdownMenu
                  container={targetModalContainer}
                  className={cx(style["border-0"], style["p-0"], style["map-color-dropdown"])}>
                  <ColorPicker
                    color={props.startColor}
                    setColor={props.setStartColor}
                    eyedropperColor={eyeDropperForStartColor}
                    setEyedropperColor={setEyeDropperForStartColor}
                    isHideTransparentOpt={true}
                  />
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>

            <div className={cx(style["line-controls-wrap"], style["margin-color"])}>
              <div className={style["slidelabel"]}>Area-Value End Color</div>
              <UncontrolledDropdown
                setActiveFromChild
                className={cx(style["color-section-block"], style["color-picker-dropdown"])}>
                <DropdownToggle tag="a" className={style["p-0"]}>
                  <Icon
                    additionalclass={endColorIconBucket}
                    icon="ui-fill-color2"
                    style={
                      props.endColor === "transparent"
                        ? {
                            backgroundImage: `url(${contextualConfig.colorUrl.midium})`,
                            backgroundSize: "cover",
                          }
                        : { backgroundColor: props.endColor }
                    }
                  />
                </DropdownToggle>
                <DropdownMenu
                  container={targetModalContainer}
                  className={cx(style["border-0"], style["p-0"], style["map-color-dropdown"])}>
                  <ColorPicker
                    color={props.endColor}
                    setColor={props.setEndColor}
                    eyedropperColor={eyeDropperForEndColor}
                    setEyedropperColor={setEyeDropperForEndColor}
                    isHideTransparentOpt={true}
                  />
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>

            <h6 className={cx(style["fw-7"], style["font-15"], style["mb-3"])}>Legend</h6>

            <div className={cx(style["custom-control"], style["custom-switch"], style["mb-3"])}>
              <Input
                type="checkbox"
                className={style["custom-control-input"]}
                defaultChecked={showLegend}
                id="customSwitchForLegend"
                value={showLegend}
                onClick={toggleLegend}
              />

              <label className={style["custom-control-label"]} htmlFor="customSwitchForLegend">
                Show Legend
              </label>
            </div>

            {showLegend && (
              <div className={cx(style["line-controls-wrap"], style["flex-column"], style["align-items-start"])}>
                <div className={cx(style["slidelabel"], style["p-0"])}>Legend Position</div>
                <Dropdown isOpen={dropdownOpenForLegend} toggle={toggle3} className={cx(style["border-style"])}>
                  <DropdownToggle className={style["text-capitalize"]}>
                    {props.legendPosition}
                    <Icon icon="ui-arrow-down" />
                  </DropdownToggle>

                  <DropdownMenu className={cx(style["shadow"], style["border-0"], style["rounded"])}>
                    {availableLegendPositions.map((availableLegendPosition, idx) => (
                      <React.Fragment key={idx}>
                        <DropdownItem
                          className={cx(style["text-capitalize"], {
                            [style["active"]]: availableLegendPosition === props.legendPosition,
                          })}
                          tag="a"
                          onClick={() => {
                            props.setLegendPosition(availableLegendPosition),
                              document
                                .querySelector(`#mapContent svg`)
                                .setAttribute("data-area-legend-position", availableLegendPosition);
                          }}>
                          {/* <span className={availableLegendPosition}></span> */}
                          {availableLegendPosition}
                        </DropdownItem>
                      </React.Fragment>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
Data.propTypes = {
  mapData: PropTypes.array,
  updateMapData: PropTypes.func.isRequired,
  setSettingsTabCurrentCaption: PropTypes.func.isRequired,
  isShowAreaValueMap: PropTypes.bool,
  setIsShowAreaValueMap: PropTypes.func,
  dataValue: PropTypes.string.isRequired,
  setDataValue: PropTypes.func.isRequired,
  legendPosition: PropTypes.string,
  setLegendPosition: PropTypes.func,
  mapColor: PropTypes.string,
  updateDataAcoordingAreaValue: PropTypes.func.isRequired,
  startColor: PropTypes.string,
  setStartColor: PropTypes.func.isRequired,
  endColor: PropTypes.string,
  setEndColor: PropTypes.func.isRequired,
  setLegendContent: PropTypes.func,
};

const MapTooltip = props => {
  const style = {
    position: "fixed",
    top: props.position.y + 10,
    left: props.position.x + 10,
    background: props.hoverAreaColor === "Light" ? WHITE_COLOR : BLACK_COLOR,
    color: props.hoverAreaColor === "Light" ? BLACK_COLOR : WHITE_COLOR,
    padding: "5px",
    borderRadius: "5px",
    display: "inline-block",
  };

  return (
    <div className="px-2 shadow" style={style}>
      <div className="fw-7"> {props.hoveredAreaName} </div>
      <ul>
        {Object.keys(props.captionObject).map(keyName => (
          <li key={keyName}>
            {keyName?.replaceAll("_", " ")} : {props.captionObject[keyName]}
          </li>
        ))}
      </ul>
    </div>
  );
};
MapTooltip.propTypes = {
  position: PropTypes.object,
  hoveredAreaName: PropTypes.string,
  dataValue: PropTypes.string,
  captionObject: PropTypes.object,
  hoverAreaColor: PropTypes.string,
};

const MapContent = props => {
  const [hoveredAreaName, setHoveredAreaName] = useState();
  const [hoverAreaColor, setHoverAreaColor] = useState();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isAllLegendNull, setIsAllLegendNull] = useState();
  const [legendClass, setLegendClass] = useState();
  const [captionObject, setCaptionObject] = useState();

  const handleMouseMove = e => {
    if (props.isShowCaption && e.target.tagName === "path") {
      let hovAreaName = e.target.getAttribute("title");
      let hoverAreaValues = props.mapData.filter(md => md[props.mapAreaName] === hovAreaName)[0];
      let captionObj;

      for (const [key, value] of Object.entries(hoverAreaValues)) {
        if (!["Color", "Countries", "Display", "Id", "States"].includes(key))
          captionObj = { ...captionObj, [`${key}`]: value ? value : 0 };
      }

      setHoveredAreaName(hovAreaName);
      setCaptionObject(captionObj);
      setHoverAreaColor(props.captionType);
      setMousePosition({ x: e.clientX, y: e.clientY });
    } else setHoveredAreaName();
  };

  // check wether all legends value are same
  useEffect(() => {
    if (props.legendValueArray?.length > 0) {
      let result = props.legendValueArray.every((val, i, arr) => val === arr[0]);
      setIsAllLegendNull(result);
    }
  }, [props.legendValueArray]);

  //add legend class name according to legend length in map modal
  useEffect(() => {
    if (props.legendPosition) {
      let legendClassName = props.getLegendClassAccordingTolength();
      setLegendClass(legendClassName);
    } else setLegendClass();
  }, [props.legendValueArray, props.legendPosition, props.legendContent]);

  return (
    <>
      <div className={cx(style["mapWrap"], style[`${legendClass}`])} id="mapWrap">
        <div
          className={cx(style["mapContent"], style[`${props.legendPosition}-side-legend`])}
          id="mapContent"
          dangerouslySetInnerHTML={{ __html: props.svgContent.outerHTML }}
          onMouseMove={e => {
            handleMouseMove(e);
          }}
          onMouseLeave={() => setHoveredAreaName()}></div>

        {props.legendValueArray?.length > 0 &&
          props.colorRangeArray?.length > 0 &&
          props.legendPosition &&
          !props.legendContent && (
            <div
              className={cx(style["legend-position"], style[`legend-pos-${props.legendPosition}`])}
              id="legend-position">
              <div className={cx(style["fw-7"], style["mb-2"], style["text-align"])}>
                {props.dataValue.replaceAll("_", " ")}
              </div>
              {!isAllLegendNull && (
                <ul>
                  {props.colorRangeArray.map((color, idx) => (
                    <li key={idx}>
                      <div style={{ backgroundColor: RGBToHex(`rgb(${color[0]}, ${color[1]}, ${color[2]})`) }}></div>
                      {idx === 0 && `< ${shortNumberIntToString(props.legendValueArray[idx])}`}
                      {idx !== 0 &&
                        idx !== props.colorRangeArray?.length - 1 &&
                        `${shortNumberIntToString(props.legendValueArray[idx - 1])} - ${shortNumberIntToString(
                          props.legendValueArray[idx]
                        )}`}
                      {idx === props.colorRangeArray?.length - 1 &&
                        `> ${shortNumberIntToString(props.legendValueArray[props.legendValueArray?.length - 1])}`}
                    </li>
                  ))}
                </ul>
              )}

              {isAllLegendNull && (
                <ul>
                  <li>
                    <div
                      style={{
                        backgroundColor: RGBToHex(
                          `rgb(${props.colorRangeArray[0][0]}, ${props.colorRangeArray[0][1]}, ${props.colorRangeArray[0][2]})`
                        ),
                      }}></div>
                    {shortNumberIntToString(props.legendValueArray[0])}
                  </li>
                </ul>
              )}
            </div>
          )}

        {props.legendContent && <div dangerouslySetInnerHTML={{ __html: props.legendContent.outerHTML }}></div>}
      </div>

      {hoveredAreaName && (
        <MapTooltip
          hoveredAreaName={hoveredAreaName}
          dataValue={props.dataValue}
          captionObject={captionObject}
          hoverAreaColor={hoverAreaColor}
          position={mousePosition}
        />
      )}
    </>
  );
};
MapContent.propTypes = {
  svgContent: PropTypes.object,
  captionType: PropTypes.string,
  isShowCaption: PropTypes.bool,
  mapData: PropTypes.array,
  dataValue: PropTypes.string.isRequired,
  colorRangeArray: PropTypes.array,
  legendPosition: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  mapAreaName: PropTypes.string,
  legendValueArray: PropTypes.array,
  legendContent: PropTypes.object,
  getLegendClassAccordingTolength: PropTypes.func,
};

const Map = ({ closeModal, modalFullScreen, setModalFullScreen, editable }) => {
  let { metadata, widgets, updateWidgets } = useContext(EditorContext);

  const [mapAreaName, setMapAreaName] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [editModeActiveTab, setEditModeActiveTab] = useState(0);
  const [svgContent, setSvgContent] = useState();
  const [mapName, setMapName] = useState();
  const [mapData, setMapData] = useState();
  const [editDataModeOn, setEditDataModeOn] = useState();
  const [settingsTabCurrentCaption, setSettingsTabCurrentCaption] = useState("*");
  const [mapColor, setMapColor] = useState();
  const [isShowCaption, setIsShowCaption] = useState();
  const [captionType, setCaptionType] = useState();
  const [isShowAreaValueMap, setIsShowAreaValueMap] = useState();
  const [startColor, setStartColor] = useState();
  const [endColor, setEndColor] = useState();
  const [dataValue, setDataValue] = useState();
  const [colorRangeArray, setColorRangeArray] = useState();
  const [legendValueArray, setLegendValueArray] = useState();
  const [legendPosition, setLegendPosition] = useState();
  const [addWidgetClicked, setAddWidgetClicked] = useState(false);
  const [dataparam, setDataParam] = useState();
  const [assetInnerContent, setAssetInnerContent] = useState();
  const [legendContent, setLegendContent] = useState();
  const [cusetomClass, setCusetomClass] = useState();
  const [mapDia, setMapDia] = useState();
  const [customCss, setCustomCss] = useState(false);

  const { postion: getPosition } = useAlignment(addWidgetClicked, mapDia?.width, mapDia?.height, "middle-center");
  const getNewWidgetObject = useAddWidget(addWidgetClicked, MAP, dataparam, getPosition, mapDia?.width, mapDia?.height);
  useElementInnerHtml(
    addWidgetClicked,
    setAddWidgetClicked,
    MAP,
    assetInnerContent,
    dataparam,
    getNewWidgetObject,
    customCss,
    false,
    cusetomClass
  );

  // get new svg when map is changed
  const getSvg = async (imgUrl, targetMapName, mapStatus) => {
    let innerContent;

    try {
      innerContent = await getSvgContentFromUrl(imgUrl);
    } catch (err) {
      // Block of code to handle errors
    } finally {
      document.getElementById("mapLoader").style.display = "none";
    }

    innerContent.setAttribute("data-map", mapStatus);
    innerContent.setAttribute("data-map-name", targetMapName);
    innerContent.setAttribute("data-border-on", true);
    innerContent.setAttribute("data-map-color", widgetConfig[MAP].default_color);
    innerContent.setAttribute("data-map-border-color", widgetConfig[MAP].default_border_color);
    innerContent.setAttribute("data-caption-on", true);
    innerContent.setAttribute("data-caption-type", widgetConfig[MAP].default_caption_type);
    innerContent.setAttribute("data-area-value-on", false);
    innerContent.setAttribute("data-area-value", widgetConfig[MAP].dafault_area_value);
    innerContent.setAttribute("data-area-start-color", widgetConfig[MAP].default_start_color);
    innerContent.setAttribute("data-area-end-color", widgetConfig[MAP].default_end_color);
    innerContent.setAttribute("data-area-legend-on", false);
    // innerContent.setAttribute("data-area-legend-position", false);

    // set default color and stroke on svg
    innerContent?.querySelectorAll(`svg *`).forEach(element => {
      if (element.tagName === "path") {
        element.style.stroke = widgetConfig[MAP].default_border_color; // set stroke

        // set default color for country svg as the svg are in black in color
        if (mapStatus !== "region") {
          element.style.fill = widgetConfig[MAP].default_color;
        }
      }
    });

    setIsShowCaption(true);
    setCaptionType(widgetConfig[MAP].default_caption_type);
    setDataValue(widgetConfig[MAP].dafault_area_value);
    if (isShowAreaValueMap) switchOffAreaValueToggle(); // if area value is on then on Map change keep the toggle off
    if (legendContent) setLegendContent(); // if area value is on then on Map change keep the toggle off
    setMapAreaName(mapStatus === "region" ? "Countries" : "States");
    setMapName(targetMapName);
    setSvgContent(innerContent);
    genarateMapData(innerContent, mapStatus);
  };

  const genarateMapData = (mapContent, mapStatus, editStatus) => {
    let newmapData = [];
    let areaName = mapStatus === "region" ? "Countries" : "States";

    mapContent.querySelectorAll(`svg *`).forEach(element => {
      if (element.tagName === "path") {
        let newObj;

        // genarate data for new map insertion
        if (!editStatus) {
          newObj = {
            Id: element.getAttribute("id"),
            Display: true,
            Color: widgetConfig[MAP].default_color,
            [`${areaName}`]: element.getAttribute("title"),
            Population: Math.floor(Math.random() * (100 - 10 + 1)) + 10,
          };
        }
        // fill the data for map upadte
        else {
          newObj = {
            Id: element.getAttribute("id"),
            Display: element.style.display && element.style.display === "none" ? false : true,
            Color: element.style.fill ? RGBToHex(element.style.fill) : widgetConfig[MAP].default_color,
            [`${areaName}`]: element.getAttribute("title"),
          };

          // get all table values after countries from dynamic attributes
          if (parseInt(document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-version")) >= 4) {
            Object.keys(element.dataset).forEach(value => {
              let tableObj = JSON.parse(element.getAttribute(`data-${value}`));
              newObj = { ...newObj, [`${tableObj.name}`]: tableObj.value };
            });
          } else {
            Object.keys(element.dataset).forEach(value => {
              let keyName = value.charAt(0).toUpperCase() + value.slice(1);
              newObj = { ...newObj, [`${keyName}`]: element.getAttribute(`data-${value}`) };
            });
          }
        }
        newmapData.push(newObj);
      }
    });

    setMapData(sortDataByValue(newmapData, areaName));
  };

  const sortDataByValue = (data, areaName) => {
    let sortedData;

    sortedData = data.sort(function (a, b) {
      // here a , b is whole object, you can access its property
      //convert both to lowercase
      let x = a[areaName].toLowerCase();
      let y = b[areaName].toLowerCase();

      //compare the word which is comes first
      if (x > y) {
        return 1;
      }
      if (x < y) {
        return -1;
      }
      return 0;
    });

    return sortedData;
  };

  // switch of area value map toggle if map color is changed from any different tab
  const switchOffAreaValueToggle = () => {
    setIsShowAreaValueMap(!isShowAreaValueMap);
    setLegendPosition();

    let elem = document.querySelector(`#mapContent svg`);

    //DOM attribute update
    if (elem) {
      elem.setAttribute("data-area-value-on", false);
      elem.removeAttribute("data-area-start-color");
      elem.removeAttribute("data-area-end-color");
      elem.setAttribute("data-area-legend-on", false);
      elem.removeAttribute("data-area-legend-position", widgetConfig[MAP].default_legend_position);
    }
  };

  // return max value from area map value selected array
  const getMaxValueofSelectedAreaValue = () => {
    let selectedAreaValueArray = [];

    mapData.forEach(md => {
      if (parseFloat(md[dataValue]) !== "NaN") selectedAreaValueArray.push(md[dataValue]);
    });

    let maxValue = Math.max(...selectedAreaValueArray);

    return maxValue;
  };

  // return min value from area map value selected array
  const getMinValueofSelectedAreaValue = () => {
    let selectedAreaValueArray = [];

    mapData.forEach(md => {
      if (parseFloat(md[dataValue]) !== "NaN") selectedAreaValueArray.push(md[dataValue]);
    });

    let minValue = Math.min(...selectedAreaValueArray);

    return minValue;
  };

  //update Data Acoording AreaValue
  const updateDataAcoordingAreaValue = () => {
    let maxValue = getMaxValueofSelectedAreaValue();
    let minValue = getMinValueofSelectedAreaValue();
    let InterpolateColors = getInterpolateColors(hexToRGB(startColor), hexToRGB(endColor), 5);
    setColorRangeArray(InterpolateColors);

    // Legend calculation
    let range = maxValue - minValue;
    let lb = range / 5;

    let twentyPercent = minValue + 1 * lb;
    let fortyPercent = minValue + 2 * lb;
    let sixtyPercent = minValue + 3 * lb;
    let eightyPercent = minValue + 4 * lb;

    let arrValue = [twentyPercent, fortyPercent, sixtyPercent, eightyPercent];
    setLegendValueArray([twentyPercent, fortyPercent, sixtyPercent, eightyPercent]);

    //apply color on svg
    document.querySelectorAll(`#mapContent svg *`).forEach(element => {
      if (element.tagName === "path") {
        let targetColor;
        let targetData = mapData.filter(md => md[mapAreaName] === element.getAttribute("title"));
        let value = targetData[0][dataValue];

        if (value === "")
          targetColor = RGBToHex(
            `rgb(${InterpolateColors[0][0]}, ${InterpolateColors[0][1]}, ${InterpolateColors[0][2]})`
          );
        else if (value < arrValue[0])
          targetColor = RGBToHex(
            `rgb(${InterpolateColors[0][0]}, ${InterpolateColors[0][1]}, ${InterpolateColors[0][2]})`
          );
        else if (value >= arrValue[0] && value <= arrValue[1])
          targetColor = RGBToHex(
            `rgb(${InterpolateColors[1][0]}, ${InterpolateColors[1][1]}, ${InterpolateColors[1][2]})`
          );
        else if (value > arrValue[1] && value <= arrValue[2])
          targetColor = RGBToHex(
            `rgb(${InterpolateColors[2][0]}, ${InterpolateColors[2][1]}, ${InterpolateColors[2][2]})`
          );
        else if (value > arrValue[2] && value <= arrValue[3])
          targetColor = RGBToHex(
            `rgb(${InterpolateColors[3][0]}, ${InterpolateColors[3][1]}, ${InterpolateColors[3][2]})`
          );
        else if (value > arrValue[3])
          targetColor = RGBToHex(
            `rgb(${InterpolateColors[4][0]}, ${InterpolateColors[4][1]}, ${InterpolateColors[4][2]})`
          );
        else
          targetColor = RGBToHex(
            `rgb(${InterpolateColors[4][0]}, ${InterpolateColors[4][1]}, ${InterpolateColors[4][2]})`
          );

        element.style.fill = targetColor;
        if (document.querySelector("#mapContent svg").getAttribute("data-border-on") === "false")
          element.style.stroke = targetColor;
        targetData[0]["Color"] = targetColor;
      }
    });

    if (document.querySelector(`#mapContent svg`).getAttribute("data-area-legend-on") === "true")
      setLegendPosition(document.querySelector(`#mapContent svg`).getAttribute("data-area-legend-position"));
  };

  // get legend class name according to legend length
  const getLegendClassAccordingTolength = () => {
    let lengthArray = [];

    // get legends length
    document.querySelectorAll(`#mapWrap #legend-position ul li`).forEach(element => {
      lengthArray.push(element.innerText.length);
    });

    let maxValue = Math.max(...lengthArray); // get max value from length array
    let legendClassName = maxValue <= 12 ? "smallLegend" : "largeLegend";

    return legendClassName;
  };

  const insertMap = () => {
    setTimeout(() => {
      let dataAttr = { ...widgetConfig[MAP].dataAttr };

      // set all table values after countries on each path of svg (for hover in editor)
      document.querySelectorAll(`#mapContent svg *`).forEach(element => {
        if (element.tagName === "path") {
          let targetData = mapData.filter(md => md[mapAreaName] === element.getAttribute("title"));
          let Objketarray = Object.keys(targetData[0]);

          for (let i = 4; i < Objketarray.length; i++) {
            let value = targetData[0][Objketarray[i]];
            element.setAttribute(`data-${[i - 4]}`, JSON.stringify({ name: Objketarray[i], value: value }));
          }
        }
      });

      let mapSelector = document.querySelector("#mapContent svg");
      let mapBoundry = mapSelector.getBBox();

      // create map element to insert in editor
      let newElem = document.createElement("div");
      let newMapElem = document.createElement("div");
      newMapElem.innerHTML = document.querySelector(`#mapContent`).innerHTML;
      let newLegendElem;
      if (document.querySelector(`#mapWrap #legend-position`)) {
        newMapElem.classList.add(`map-svg`);
        newMapElem.classList.add(`${legendPosition}-side-legend`);
        newLegendElem = document.createElement("div");
        newLegendElem.innerHTML = document.querySelector(`#mapWrap #legend-position`).outerHTML;

        // I think this setCusetomClass is no more required (by Deepanjan)
        // add legend class name according to legend length
        // let legendClassName = getLegendClassAccordingTolength();
        // setCusetomClass(legendClassName);
      }
      let lagendBoundry,
        mapWithLegend,
        legendScale = { x: 1, y: 0.7 };
      const mapDiaWithoutLegend = {
        width: (widgetConfig[MAP]?.height / mapBoundry.height) * mapBoundry.width,
        height: widgetConfig[MAP]?.height,
      };
      if (newLegendElem) {
        const lagentDom = document.querySelector(`#mapWrap #legend-position`).getBoundingClientRect();
        let legendBoundryPadding = 8; // 4px from top left bottom right

        // calculate scale dynamically for best fit of legend
        if (["Top", "Bottom"].includes(legendPosition)) {
          let legendActualWidth =
            document.querySelector("#mapWrap #legend-position > ul").querySelectorAll("li").length === 1
              ? document.querySelector("#mapWrap #legend-position > ul > li").clientWidth
              : document.querySelector("#mapWrap #legend-position > ul").clientWidth;
          let newScaleX = (mapDiaWithoutLegend.width - legendBoundryPadding) / legendActualWidth;
          legendScale.x = newScaleX < legendScale.x ? newScaleX : legendScale.x;
        } else {
          let newScaleY = (mapDiaWithoutLegend.height - legendBoundryPadding) / lagentDom.height;
          legendScale.y = newScaleY < legendScale.y ? newScaleY : legendScale.y;
        }

        lagendBoundry = {
          height: ["Top", "Bottom"].includes(legendPosition)
            ? lagentDom.height * legendScale.x + legendBoundryPadding
            : 0,
          width: ["Top", "Bottom"].includes(legendPosition)
            ? 0
            : lagentDom.width * legendScale.y + legendBoundryPadding,
        };

        setMapDia({
          height: mapDiaWithoutLegend.height + lagendBoundry.height,
          width: mapDiaWithoutLegend.width + lagendBoundry.width,
        });

        switch (legendPosition) {
          case "Right":
            mapWithLegend = `<div style="float: left; height: 100%; width: ${mapDiaWithoutLegend?.width}px">${
              document.querySelector(`#mapContent`).innerHTML
            }</div><div style="float:right; height: 100%; width: ${
              lagendBoundry?.width
            }px; display: flex; justify-content: center; align-items: center;"><div style="transform: scale(${
              legendScale.y
            }); width: max-content; line-height:1.5rem" class="legend-position" id = "legend-position">${
              document.querySelector(`#mapWrap #legend-position`).innerHTML
            }</div></div>`;
            break;
          case "Left":
            mapWithLegend = `<div style="float:left; height: 100%; width: ${
              lagendBoundry?.width
            }px; display: flex; justify-content: center; align-items: center;"><div style="transform: scale(${
              legendScale.y
            }); width: max-content; line-height:1.5rem" class="legend-position" id ="legend-position">${
              document.querySelector(`#mapWrap #legend-position`).innerHTML
            }</div></div><div style="float: right; height: 100%; width: ${mapDiaWithoutLegend?.width}px">${
              document.querySelector(`#mapContent`).innerHTML
            }</div>`;
            break;
          case "Top":
            {
              mapWithLegend = `<div style="width: 100%; height: ${
                lagendBoundry?.height
              }px; display: flex; justify-content: center; align-items: center;"><div style="transform: scale(${
                legendScale.x
              }); width: max-content; line-height:1.5rem;position: relative; left:0" class="legend-position legend-pos-Top" id="legend-position">${
                document.querySelector(`#mapWrap #legend-position`).innerHTML
              }</div></div><div style=" width: 100%; height: ${mapDiaWithoutLegend?.height}px">${
                document.querySelector(`#mapContent`).innerHTML
              }</div>`;
              setCustomCss({ display: "flex", "flex-direction": "column" });
            }
            break;
          case "Bottom":
            {
              mapWithLegend = `<div style=" width: 100%; height: ${mapDiaWithoutLegend?.height}px">${
                document.querySelector(`#mapContent`).innerHTML
              }</div><div style="width: 100%; height: ${
                lagendBoundry?.height
              }px; display: flex; justify-content: center; align-items: center;"><div style="transform: scale(${
                legendScale.x
              }); width: max-content; line-height:1.5rem;position: relative; left:0" class="legend-position legend-pos-Bottom" id="legend-position">${
                document.querySelector(`#mapWrap #legend-position`).innerHTML
              }</div></div>`;
              setCustomCss({ display: "flex", "flex-direction": "column" });
            }
            break;
          default:
            break;
        }
      } else
        setMapDia({
          height: mapDiaWithoutLegend.height,
          width: mapDiaWithoutLegend.width,
        });

      newElem.innerHTML = newLegendElem
        ? mapWithLegend
        : `<div style="height:100%; width:100%">${newMapElem.innerHTML}</div>`;

      setAssetInnerContent(newElem);
      setDataParam(dataAttr);
      setAddWidgetClicked(true);

      // Close the modal after map insertion
      let userAgent = navigator.userAgent;
      let isFirefox = userAgent.match(/firefox|fxios/i);

      if (isFirefox)
        setTimeout(() => {
          closeModal();
          setModalFullScreen(false);
        }, 1000);
      else
        setTimeout(() => {
          closeModal();
          setModalFullScreen(false);
        }, 1000);
    }, 1);
  };

  const updateMap = () => {
    let targetWidgetIndex = widgets.findIndex(widget => widget.id === metadata.activeWidgetId[0]);

    // set all table values after countries on each path of svg (for hover in editor)
    document.querySelectorAll(`#mapContent svg *`).forEach(element => {
      if (element.tagName === "path") {
        // remove all data attributes from the path befor update new one
        Object.keys(element.dataset).forEach(value => {
          element.removeAttribute(`data-${value}`);
        });

        let targetData = mapData.filter(md => md[mapAreaName] === element.getAttribute("title"));
        let Objketarray = Object.keys(targetData[0]);

        for (let i = 4; i < Objketarray.length; i++) {
          let value = targetData[0][Objketarray[i]];
          element.setAttribute(`data-${[i - 4]}`, JSON.stringify({ name: Objketarray[i], value: value }));
        }
      }
    });

    let mapDomInnerSelector = document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`);
    let mapSelector = document.querySelector("#mapContent svg");
    let mapBoundry = mapSelector.getBBox();

    // create map element to update in editor
    let newElem = document.createElement("div");
    let newMapElem = document.createElement("div");
    newMapElem.innerHTML = document.querySelector(`#mapContent`).innerHTML;
    let newLegendElem;
    if (document.querySelector(`#mapWrap #legend-position`)) {
      newMapElem.classList.add(`map-svg`);
      newMapElem.classList.add(`${legendPosition}-side-legend`);
      newLegendElem = document.createElement("div");
      newLegendElem.innerHTML = document.querySelector(`#mapWrap #legend-position`).outerHTML;

      // I think this setCusetomClass is no more required (by Deepanjan)
      // add legend class name according to legend length
      // let legendClassName = getLegendClassAccordingTolength();
      // document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`).classList.remove("smallLegend");
      // document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`).classList.remove("largeLegend");
      // document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`).classList.add(legendClassName);
    }
    // remove legend class name if there is no legend
    else {
      // keeping this as there could be old Map (version < 5.0) in DOM
      mapDomInnerSelector.classList.remove("smallLegend");
      mapDomInnerSelector.classList.remove("largeLegend");
    }

    let {
      scale: { x: oldMapScale = 1 },
    } = getCssTransformObj({
      transform: mapDomInnerSelector.style.transform,
    });

    let updatedMapDia,
      lagendBoundry,
      mapWithLegend,
      widgetInnerCustomCss,
      legendScale = { x: 1, y: 0.7 };
    const mapDiaWithoutLegend = {
      width: (widgetConfig[MAP]?.height / mapBoundry.height) * mapBoundry.width,
      height: widgetConfig[MAP]?.height,
    };
    if (newLegendElem) {
      const lagentDom = document.querySelector(`#mapWrap #legend-position`).getBoundingClientRect();
      let legendBoundryPadding = 8; // 4px from top left bottom right

      // calculate scale dynamically for best fit of legend
      if (["Top", "Bottom"].includes(legendPosition)) {
        let legendActualWidth =
          document.querySelector("#mapWrap #legend-position > ul").querySelectorAll("li").length === 1
            ? document.querySelector("#mapWrap #legend-position > ul > li").clientWidth
            : document.querySelector("#mapWrap #legend-position > ul").clientWidth;
        let newScaleX = (mapDiaWithoutLegend.width - legendBoundryPadding) / legendActualWidth;
        legendScale.x = newScaleX < legendScale.x ? newScaleX : legendScale.x;
      } else {
        let newScaleY = (mapDiaWithoutLegend.height - legendBoundryPadding) / lagentDom.height;
        legendScale.y = newScaleY < legendScale.y ? newScaleY : legendScale.y;
      }

      lagendBoundry = {
        height: ["Top", "Bottom"].includes(legendPosition)
          ? lagentDom.height * legendScale.x + legendBoundryPadding
          : 0,
        width: ["Top", "Bottom"].includes(legendPosition) ? 0 : lagentDom.width * legendScale.y + legendBoundryPadding,
      };

      updatedMapDia = {
        height: mapDiaWithoutLegend.height + lagendBoundry.height,
        width: mapDiaWithoutLegend.width + lagendBoundry.width,
      };

      switch (legendPosition) {
        case "Right":
          mapWithLegend = `<div style="float: left; height: 100%; width: ${mapDiaWithoutLegend?.width}px">${
            document.querySelector(`#mapContent`).innerHTML
          }</div><div style="float:right; height: 100%; width: ${
            lagendBoundry?.width
          }px; display: flex; justify-content: center; align-items: center;"><div style="transform: scale(${
            legendScale.y
          }); width: max-content; line-height:1.5rem" class="legend-position" id = "legend-position">${
            document.querySelector(`#mapWrap #legend-position`).innerHTML
          }</div></div>`;
          break;
        case "Left":
          mapWithLegend = `<div style="float:left; height: 100%; width: ${
            lagendBoundry?.width
          }px; display: flex; justify-content: center; align-items: center;"><div style="transform: scale(${
            legendScale.y
          }); width: max-content; line-height:1.5rem" class="legend-position" id ="legend-position">${
            document.querySelector(`#mapWrap #legend-position`).innerHTML
          }</div></div><div style="float: right; height: 100%; width: ${mapDiaWithoutLegend?.width}px">${
            document.querySelector(`#mapContent`).innerHTML
          }</div>`;
          break;
        case "Top":
          {
            mapWithLegend = `<div style="width: 100%; height: ${
              lagendBoundry?.height
            }px; display: flex; justify-content: center; align-items: center;"><div style="transform: scale(${
              legendScale.x
            }); width: max-content; line-height:1.5rem;position: relative; left:0" class="legend-position legend-pos-Top" id="legend-position">${
              document.querySelector(`#mapWrap #legend-position`).innerHTML
            }</div></div><div style=" width: 100%; height: ${mapDiaWithoutLegend?.height}px">${
              document.querySelector(`#mapContent`).innerHTML
            }</div>`;
            widgetInnerCustomCss = { display: "flex", "flex-direction": "column" };
          }
          break;
        case "Bottom":
          {
            mapWithLegend = `<div style=" width: 100%; height: ${mapDiaWithoutLegend?.height}px">${
              document.querySelector(`#mapContent`).innerHTML
            }</div><div style="width: 100%; height: ${
              lagendBoundry?.height
            }px; display: flex; justify-content: center; align-items: center;"><div style="transform: scale(${
              legendScale.x
            }); width: max-content; line-height:1.5rem;position: relative; left:0" class="legend-position legend-pos-Bottom" id="legend-position">${
              document.querySelector(`#mapWrap #legend-position`).innerHTML
            }</div></div>`;
            widgetInnerCustomCss = { display: "flex", "flex-direction": "column" };
          }
          break;
        default:
          break;
      }
    } else
      updatedMapDia = {
        height: mapDiaWithoutLegend.height,
        width: mapDiaWithoutLegend.width,
      };

    newElem.innerHTML = newLegendElem
      ? mapWithLegend
      : `<div style="height:100%; width:100%">${newMapElem.innerHTML}</div>`;

    // newElem.innerHTML = newLegendElem ? `${newMapElem.outerHTML}${newLegendElem.innerHTML}` : `${newMapElem.outerHTML}`;
    let widgetInner = document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`);
    widgetInner.innerHTML = newElem.innerHTML;
    if (widgetInnerCustomCss) {
      widgetInner.style.display = "flex";
      widgetInner.style.flexDirection = "column";
    } else {
      widgetInner.style.display = "";
      widgetInner.style.flexDirection = "";
    }

    widgetInner.style.height = `${updatedMapDia.height}px`;
    widgetInner.style.width = `${updatedMapDia.width}px`;

    let widgetObject = widgets[targetWidgetIndex];
    // adjust left top for rotated map update
    const {
      translate: { x: widgetTransX, y: widgetTransY },
      rotate: { theta: widgetTheta },
    } = getCssTransformObj({
      transform: widgetObject.style.transform,
    });

    const { left, top } = calculateNewPositionOnRotatedObjectResize(
      parseFloat(widgetTransX),
      parseFloat(widgetTransY),
      parseFloat(updatedMapDia.width * oldMapScale),
      parseFloat(updatedMapDia.height * oldMapScale),
      parseFloat(widgetObject.style.width),
      parseFloat(widgetObject.style.height),
      parseFloat(widgetTheta)
    );
    const widgetTransformStr = getCssTransformObj({
      translateX: left + "px",
      translateY: top + "px",
      transform: widgetObject.style.transform,
      returnStr: true,
    });

    let newArray = Object.assign([...widgets], {
      [targetWidgetIndex]: {
        ...widgetObject,
        style: {
          ...widgetObject.style,
          height: `${updatedMapDia.height * oldMapScale}px`,
          width: `${updatedMapDia.width * oldMapScale}px`,
          transform: widgetTransformStr,
        },
        data: {
          ...widgetObject.data,
          "data-version": "5.0",
        },
        innerHTML: document.getElementById(metadata.activeWidgetId[0]).innerHTML,
      },
    });

    updateWidgets(newArray);
    closeModal(); // Close the modal after map update done
    setModalFullScreen(false);
  };

  useEffect(() => {
    // open modal after Double click and edit map
    if (editable) {
      let version = parseInt(editable.getAttribute("data-version"));
      let innerContent = editable.querySelector("svg");
      let isAreaValueOn = innerContent.getAttribute("data-area-value-on") === "true";
      let legendContentFromResponse = editable.querySelector("#legend-position");
      let curLegendPosition = innerContent.getAttribute("data-area-legend-position");
      if (version > 4 && isAreaValueOn && curLegendPosition) {
        // apply restucturing
        let sandbox = document.createElement("div");
        sandbox.innerHTML = legendContentFromResponse.outerHTML;
        document.body.appendChild(sandbox);
        let legendSandbox = sandbox.querySelector("#legend-position");
        legendSandbox.classList.add(`legend-pos-${curLegendPosition}`);
        legendSandbox.style.cssText = "";
        legendContentFromResponse = legendSandbox;
        document.body.removeChild(sandbox);
      }

      let mapStatus = innerContent.getAttribute("data-map");

      document.getElementById("mapLoader").style.display = "none";

      setEditDataModeOn(true);
      setIsShowCaption(innerContent.getAttribute("data-caption-on") === "true" ? true : false);
      setCaptionType(innerContent.getAttribute("data-caption-type"));
      setDataValue(innerContent.getAttribute("data-area-value"));
      setMapAreaName(mapStatus === "region" ? "Countries" : "States");
      setMapName(innerContent.getAttribute("data-map-name"));
      if (version <= 4) {
        // trim old Map on edit
        (async () => {
          const sandbox = document.createElement("div");
          const svgClone = innerContent.cloneNode(true);
          sandbox.appendChild(svgClone);
          document.body.appendChild(sandbox);
          const processedSVG = await processSVG(svgClone);
          setSvgContent(processedSVG);
          document.body.removeChild(sandbox);
        })();
      } else setSvgContent(innerContent);

      setIsShowAreaValueMap(isAreaValueOn);
      setStartColor(innerContent.getAttribute("data-area-start-color"));
      setEndColor(innerContent.getAttribute("data-area-end-color"));
      setLegendPosition(isAreaValueOn ? curLegendPosition : false);
      setLegendContent(legendContentFromResponse);
      genarateMapData(innerContent, mapStatus, "edit");
    }
    // open modal 1st time
    else {
      setEditDataModeOn(false);
    }
  }, []);

  return (
    <div className={cx(style["map-modal-wrap"], style["table-modal-wrap"])} id="map-modal">
      <div className={cx(style["d-flex"], style["justify-content-between"], style["modal-header"])}>
        {!editDataModeOn && <h4 className={cx(style["fw-7"], style["mb-0"])}>Maps</h4>}
        {editDataModeOn && (
          <div className={cx(style["d-flex"], style["align-items-center"])}>
            <Icon icon="ui-arrow-left" additionalclass="mr-2 cursor-pointer" onClick={() => setEditDataModeOn(false)} />
            <h4 className={cx(style["fw-7"], style["mb-0"])}>{mapName}</h4>
          </div>
        )}

        <div>
          <span
            className={cx(style["expand-btn"], style["rounded"])}
            onClick={() => setModalFullScreen(!modalFullScreen)}>
            <Icon icon={modalFullScreen ? "ui-collapse" : "ui-expand"} />
          </span>
          <span
            className={cx(style["cross-modal"], style["rounded"])}
            onClick={() => {
              closeModal(), setModalFullScreen(false);
            }}>
            <Icon icon="ui-close" />
          </span>
        </div>
      </div>

      <Row className={cx(style["table-modal-tab"], style["map-modal-tab"], style["m-0"])}>
        <Col xs={4} className={cx(style["TMleft-pane"], style["pt-3"])}>
          {!editDataModeOn && (
            <>
              <Nav tabs className={cx(style["mb-0"], style["pill-tab"])}>
                <NavItem>
                  <NavLink className={cx({ [style["active"]]: activeTab === 0 })} onClick={() => setActiveTab(0)}>
                    <Icon icon="ui-marker" additionalclass="mr-2" /> Regions
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={cx({ [style["active"]]: activeTab === 1 })} onClick={() => setActiveTab(1)}>
                    <Icon icon="ui-globe-earth" additionalclass="mr-2" /> Countries
                  </NavLink>
                </NavItem>
              </Nav>

              <div
                className={cx(style["mt-3"], style["tab-pane"], style["tab-regions"], {
                  [style["active"]]: activeTab === 0,
                })}>
                <Region getSvg={getSvg} svgContent={svgContent} editable={editable} setSvgContent={setSvgContent} />
              </div>

              <div
                className={cx(style["mt-3"], style["tab-pane"], style["tab-countries"], {
                  [style["active"]]: activeTab === 1,
                })}>
                <Countries getSvg={getSvg} setSvgContent={setSvgContent} />
              </div>
            </>
          )}

          {editDataModeOn && (
            <>
              <Nav tabs className={cx(style["mb-3"], style["border-bottom-tab"])}>
                <NavItem className={cx(style["m-0"], style["p-0"])}>
                  <NavLink
                    className={cx({ [style["active"]]: editModeActiveTab === 0 })}
                    onClick={() => {
                      setEditModeActiveTab(0), setSettingsTabCurrentCaption("*");
                    }}>
                    Data
                  </NavLink>
                </NavItem>
                <NavItem className={cx(style["m-0"], style["p-0"])}>
                  <NavLink
                    className={cx({ [style["active"]]: editModeActiveTab === 1 })}
                    onClick={() => setEditModeActiveTab(1)}>
                    Settings
                  </NavLink>
                </NavItem>
              </Nav>

              <div className={cx(style["tab-pane"], { [style["active"]]: editModeActiveTab === 0 })}>
                <TableData
                  mapData={mapData}
                  updateMapData={setMapData}
                  mapAreaName={mapAreaName}
                  isShowAreaValueMap={isShowAreaValueMap}
                  switchOffAreaValueToggle={switchOffAreaValueToggle}
                  updateDataAcoordingAreaValue={updateDataAcoordingAreaValue}
                  dataValue={dataValue}
                  setDataValue={setDataValue}
                  legendContent={legendContent}
                  setLegendContent={setLegendContent}
                />
              </div>

              <div className={cx(style["tab-pane"], { [style["active"]]: editModeActiveTab === 1 })}>
                {settingsTabCurrentCaption === "*" && (
                  <ul className={cx(style["map-settings"])}>
                    <li onClick={() => setSettingsTabCurrentCaption("ColorAndBorder")}>
                      <Icon icon="ui-globe" additionalclass="mr-2" /> Color and Border
                    </li>
                    <li onClick={() => setSettingsTabCurrentCaption("Caption")}>
                      <Icon icon="ui-topic" additionalclass="mr-2" /> Caption
                    </li>
                    <li onClick={() => setSettingsTabCurrentCaption("Data")}>
                      <Icon icon="ui-data1" additionalclass="mr-2" /> Data
                    </li>
                  </ul>
                )}
                {settingsTabCurrentCaption === "ColorAndBorder" && (
                  <ColorAndBorder
                    mapData={mapData}
                    updateMapData={setMapData}
                    setSettingsTabCurrentCaption={setSettingsTabCurrentCaption}
                    mapColor={mapColor}
                    setMapColor={setMapColor}
                    isShowAreaValueMap={isShowAreaValueMap}
                    switchOffAreaValueToggle={switchOffAreaValueToggle}
                    legendContent={legendContent}
                    setLegendContent={setLegendContent}
                  />
                )}
                {settingsTabCurrentCaption === "Caption" && (
                  <Caption
                    isShowCaption={isShowCaption}
                    setIsShowCaption={setIsShowCaption}
                    captionType={captionType}
                    setCaptionType={setCaptionType}
                    setSettingsTabCurrentCaption={setSettingsTabCurrentCaption}
                  />
                )}
                {settingsTabCurrentCaption === "Data" && (
                  <Data
                    mapData={mapData}
                    updateMapData={setMapData}
                    setSettingsTabCurrentCaption={setSettingsTabCurrentCaption}
                    isShowAreaValueMap={isShowAreaValueMap}
                    setIsShowAreaValueMap={setIsShowAreaValueMap}
                    dataValue={dataValue}
                    setDataValue={setDataValue}
                    legendPosition={legendPosition}
                    setLegendPosition={setLegendPosition}
                    mapColor={mapColor}
                    updateDataAcoordingAreaValue={updateDataAcoordingAreaValue}
                    startColor={startColor}
                    setStartColor={setStartColor}
                    endColor={endColor}
                    setEndColor={setEndColor}
                    setLegendContent={setLegendContent}
                  />
                )}
              </div>
            </>
          )}
        </Col>

        <Col xs={8} className={cx(style["TMright-pane"], style["pt-3"])}>
          <div className={cx(style["position-relative"], style["text-center"], style["py-2"], style["mb-2"])}>
            <h6 className={cx(style["fw-7"], style["mb-0"])}>{mapName} Map</h6>

            {!editDataModeOn && (
              <div
                className={cx(
                  style["edit-map"],
                  style["d-flex"],
                  style["align-items-center"],
                  style["pr-3"],
                  style["mt-2"]
                )}
                onClick={() => setEditDataModeOn(true)}>
                <Icon icon="ui-edit" additionalclass="mr-2" /> Edit
              </div>
            )}
          </div>

          <div className={style["total-map-wrap"]}>
            <div
              id="mapLoader"
              className={cx(style["align-items-center"], style["justify-content-center"], style["mapLoader"])}>
              <div className={style["parser-loader"]}>
                <img src={parserLoaderSvg} />
              </div>
            </div>

            {svgContent && (
              <MapContent
                svgContent={svgContent}
                isShowCaption={isShowCaption}
                captionType={captionType}
                mapData={mapData}
                dataValue={dataValue}
                colorRangeArray={colorRangeArray}
                legendPosition={legendPosition}
                mapAreaName={mapAreaName}
                legendValueArray={legendValueArray}
                legendContent={legendContent}
                getLegendClassAccordingTolength={getLegendClassAccordingTolength}
              />
            )}
          </div>

          <div className={style["Mfooter"]}>
            <div className={cx(style["align-items-center"], style["d-flex"])}>
              {editable && (
                <Button cssModule={style} color="primary" onClick={updateMap}>
                  Update
                </Button>
              )}
              {!editable && (
                <Button cssModule={style} color="primary" onClick={insertMap}>
                  Insert
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
Map.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalFullScreen: PropTypes.bool,
  setModalFullScreen: PropTypes.func,
  editable: PropTypes.object,
};

export default Map;
