import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import {
  Button,
} from "reactstrap";
import { useHistory } from "react-router-dom";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import crown from "../../../assets/images/ui-crown.svg";
import { COMPANY_SUPERADMIN } from "../../../constants/company";
import { useCheckCompanyPlanInfo } from "../../../hooks/useCheckCompanyPlanInfo";

let style = Object.assign({}, global);

const BrandSetup = ({ icon, heading, content, buttonLabel }) => {
  // check is this company is paid or not
  const paidCompanyInfo = useCheckCompanyPlanInfo(true);
  const isSuperAdmin = paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN;

  return (
    <div className={cx(style["text-center"], style["use-color-info"], style["font-14"], style["mt-4"])}>
      <Icon icon={icon} />
      <h6 className={cx(style["fw-7"])}>{heading}</h6>
      <span>{content}</span>
      {isSuperAdmin && <SuperAdminButton buttonLabel={buttonLabel} />}
      {!isSuperAdmin && <InvitedUserButton />}
    </div>
  );
};

const SuperAdminButton = ({ buttonLabel }) => {
  const history = useHistory();
  const goto = () => history.push("/companies/settings/brands");
  return (
    <Button
        type="submit"
        color=""
        onClick={goto}
        className={cx(style["btn-border"], style["mt-3"])}
        cssModule={style}
        disabled={false}
      >
        {buttonLabel}
        <span className={cx(style["crown-icon"], style["ml-2"], style["d-flex"])}>
          <img src={crown} width="20" className={style["img-fluid"]} alt="Upgrade" />
        </span>
      </Button>
  );
};

const InvitedUserButton = () => {
  return (
    <div
      className={cx(
        style["text-center"],
        style["mt-3"],
        style["bg-white"],
        style["border"],
        style["rounded"],
        style["p-2"]
      )}
    >
      Only the Admin can set up
    </div>
  );
};

// Props type validation
BrandSetup.propTypes = {
  icon: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

export default BrandSetup;
