import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FontFaceObserver from "fontfaceobserver";
import { fetchDocumentDetails, updateDocumentReducer } from "../../store/actions/documentActions";
import { getResourceEntries } from "../../store/actions/resourcesActions";
import { alignSvg, displayAbstractShapes, prepareVideoHtml } from "../../_helpers/utils";
import useDocumentParser from "../../hooks/useDocumentParser";
import useCustomFonts from "../../hooks/useCustomFonts";
import useDeletedCustomFonts from "../../hooks/useDeletedCustomFonts";
import HTMLPlayer from "./HTMLPlayer";

const Play = () => {
  const dispatch = useDispatch();
  const { recordId } = useParams();
  const { start: initDocumentParser } = useDocumentParser();
  useCustomFonts(); // use active custom fonts
  useDeletedCustomFonts(); // use deleted custom fonts

  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const [docPagesMeta, setDocPagesMeta] = useState();
  const [filteredMeta, setFilteredMeta] = useState(); // only for download

  const fonts = useSelector(state => state?.resources?.fonts);
  const { documentDetails } = useSelector(state => state.document);
  const docFonts = documentDetails?.data?.version?.rawdata?.metadata?.fonts;
  const dimension = documentDetails?.data?.version?.rawdata?.metadata?.dimension;
  const recordFullData = documentDetails?.data;

  const loadGoogleFonts = () => {
    let usedFonts = docFonts?.map(name => name.toLowerCase());
    let filteredFonts = fonts?.filter(f => usedFonts.includes(f.name.toLowerCase()));
    let promises = [];
    filteredFonts?.forEach(font => {
      let link = document.createElement("link");
      link.href = font.value;
      link.rel = "stylesheet";
      let linkSelector = document.querySelector('link[href="' + font.value + '"]');
      if (!linkSelector) {
        // append font cdn if not available in dom
        document.head.appendChild(link);

        if (font.normal_weight)
          promises.push(new FontFaceObserver(font.name, { weight: font.normal_weight, style: "normal" }).load());
        if (font.bold_weight)
          promises.push(new FontFaceObserver(font.name, { weight: font.bold_weight, style: "normal" }).load());
        if (font.normal_italic_weight)
          promises.push(new FontFaceObserver(font.name, { weight: font.normal_italic_weight, style: "italic" }).load());
        if (font.bold_italic_weight)
          promises.push(new FontFaceObserver(font.name, { weight: font.bold_italic_weight, style: "italic" }).load());
      }
    });
    Promise.all(promises)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setAlert(!alert);
      });
  };

  useEffect(() => {
    if (docFonts && fonts && recordFullData?.id === recordId) {
      loadGoogleFonts();
    }
  }, [recordFullData, fonts]);

  useEffect(() => {
    if (!dimension || loading || recordFullData?.id !== recordId) return;
    alignSvg();
    displayAbstractShapes();
    prepareVideoHtml();
    getSelectedPagesMeta(docPagesMeta); // only for download
  }, [loading, recordId, docPagesMeta]);

  const getSelectedPagesMeta = (meta) => {
    if (!meta?.pageNodes) return;
    let selectedPages = [1,2,3,4,5,6,7,8,9];
    let pageNodes = JSON.parse(JSON.stringify(meta.pageNodes))
    let activePageNodes = pageNodes.filter(pg => selectedPages.includes(pg.pageIdx + 1));
    let pageNodesUpadted = activePageNodes.map((pg, index) => {
      return { ...pg, pageIdx: index }
    })
    setFilteredMeta({ ...meta, pageNodes:JSON.parse(JSON.stringify(pageNodesUpadted)), allPageNodes:JSON.parse(JSON.stringify(meta.pageNodes)) })
  }

  useEffect(() => {
    if (!docPagesMeta && recordFullData?.version && !loading) {
      if (recordFullData?.version?.rawdata?.contexts?.pageNodes.length > 0) {
        setDocPagesMeta(recordFullData.version.rawdata.contexts);
      } else {
        initDocumentParser({ data: recordFullData }).then(meta => {
          setDocPagesMeta(meta);
        });
      }
    }
  }, [documentDetails, loading]);

  useEffect(() => {
    dispatch(updateDocumentReducer({ flashMessage: null }));
    dispatch(fetchDocumentDetails(recordId));
    setLoading(true);
    if (!fonts) {
      dispatch(getResourceEntries("fonts"));
      setLoading(true);
    }

    // apply background color in body
    document.body.style.backgroundColor = "#f2f2f2";
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  return (
    <>
      {/* <HTMLPlayer docPagesMeta={filteredMeta} dimension={dimension} origin={"play"} /> */}
      <HTMLPlayer docPagesMeta={docPagesMeta} dimension={dimension} origin={"play"} />
    </>
  );
};

export default Play;
