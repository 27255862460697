import { useContext } from 'react'
import { GROUP_WIDGET, TABLE, TEXT } from '../constants/editor';
import { useCheckCompanyPlanInfo } from './useCheckCompanyPlanInfo';
import { useContextualUpgrade } from './useContextualUpgrade';
import { useDispatch, useSelector } from 'react-redux';
import { EditorContext } from '../containers/editor/EditorLayout';
import { COMPANY_SUPERADMIN } from '../constants/company';
import { aiTranslate as translate } from '../store/actions/aiActions';
import { calculateNewPositionOnRotatedObjectResize, getCssTransformObj} from '../_helpers/utils';
import useGroupUngroup from './useGroupUngroup';

const useAiTranslate = ({ selectedPageIds, destinationLanguage, setDestinationLanguage, setDefaultValue, setDisable, setIsProcessing, setErrorMessage, setSuccessMessage }) => {
    const { widgets, updateWidgets, metadata, updateMetadata, dimension } = useContext(EditorContext);
    const languages = useSelector(state => state.ai?.languages?.data);
    const dispatch = useDispatch();
    const showUpgrade = useContextualUpgrade();
    const paidCompanyInfo = useCheckCompanyPlanInfo(true);
    const { adjustChildWidgetsForGroupRotation } = useGroupUngroup();

    const aiTranslate = {
        textLimits: {
            maxCharacterLimit: 1000,
            maxTextLimit: 128,
            emptyTextErrorMessage: "Please add text to your document and try again.",
            textOverflowErrorMessage: "Please reduce the amount of text for translation and try again.",
            monthlyLimitReachedErrorMessage: "You have consumed the maximum number of pages for the current month.",
            serviceUnavailableErrorMessage: "The service is currently unavailable. Please try again later."
        },

        successMessage: "Translated successfully",

        getWidgetType: (element) => element.getAttribute("data-asset-type"),

        getTextFromInnerWidget: (element, elementType) => {
            const el = element.getElementsByClassName("dhp-widget-inner")?.[0];
            if (!el) return [];
            if (elementType === TEXT) {
                const childElements = el.childNodes;
                const text = Array.from(childElements).map(child => {
                    if (child.nodeType === Node.TEXT_NODE) return child.textContent.trim();
                    if (child.tagName && ["ol", "ul"].includes(child.tagName.toLowerCase()))
                        return Array.from(child.getElementsByTagName("li")).map(li => li.textContent.trim()).join("\n");
                    if (child.tagName && ["div", "font", "span"].includes(child.tagName.toLowerCase())) return child.textContent.trim(); // allowing font tag for old texts
                }).join("\n");
                return [text];
            }
            else if (elementType === TABLE) {
                let collectedText = [];
                const cellElements = el.querySelectorAll("th, td");
                cellElements.forEach(cell => {
                    collectedText.push(aiTranslate.getTextFromTableCell(cell));
                });
                return collectedText;
            }
        },

        getTextFromTableCell: (cell) => {
            const divs = cell.querySelectorAll("div:not(.resizer-r)");
            // Map through the divs and extract text content
            const divTexts = Array.from(divs).map(div => {
                const childElements = div.childNodes;
                return Array.from(childElements).map(child => {
                    if (child.nodeType === Node.TEXT_NODE) {
                        const trimmedText = child.textContent.trim();
                        // Only include non-empty trimmed text
                        return trimmedText.length > 0 ? trimmedText : null;
                    } else if (child.nodeType === Node.ELEMENT_NODE) {
                        // For element nodes, like <span>, extract their text content
                        if (child.tagName.toLowerCase() === 'span') {
                            const spanText = child.textContent.trim();
                            return spanText.length > 0 ? spanText : null;
                        }
                    }
                    return null;
                }).filter(text => text !== null).join(" ");
            }).filter(text => text.trim().length > 0); // Filter out empty div results
            // Join each div's text with a newline, avoiding an initial newline
            return divTexts.join("\n");
        },

        getTextFromWidget: (widget) => {
            const texts = [];
            const widgetType = widget.data?.["data-asset-type"];

            if (!widgetType) return texts;

            if ([TEXT, TABLE].includes(widgetType)) {
                texts.push(...aiTranslate.getTextFromInnerWidget(document.getElementById(widget.id), widgetType));
            } else if (widgetType === GROUP_WIDGET) {
                const groupDOM = document.getElementById(widget.id);
                groupDOM.querySelectorAll(".dhp-page-widget").forEach(innerWidget => {
                    const innerWidgetType = aiTranslate.getWidgetType(innerWidget);
                    if ([TEXT, TABLE].includes(innerWidgetType)) {
                        texts.push(...aiTranslate.getTextFromInnerWidget(innerWidget, innerWidgetType));
                    }
                });
            }
            return texts;
        },

        getUniqueTexts: () => {
            const uniqueTexts = new Set();
            widgets.forEach(widget => {
                if (selectedPageIds.includes(widget.pageId)) {
                    const widgetTexts = aiTranslate.getTextFromWidget(widget);
                    widgetTexts.forEach(text => { if (text !== "\n") uniqueTexts.add(text) });
                }
            });
            return Array.from(uniqueTexts);
        },

        validateLength: (texts) => {
            let allTexts = texts.filter(el => el !== "")
            const isMaxlengthExit = allTexts.some(txt => txt.length > aiTranslate.textLimits.maxCharacterLimit)
            if ((allTexts?.length > aiTranslate.textLimits.maxTextLimit || isMaxlengthExit) || allTexts?.length === 0) {
                setErrorMessage(allTexts?.length === 0 ? aiTranslate.textLimits.emptyTextErrorMessage : aiTranslate.textLimits.textOverflowErrorMessage);
                return;
            }
            return allTexts;
        },

        isNestedElement: (element) => {
            const divs = element.querySelectorAll("div");
            const lists = element.querySelectorAll("ul, ol");

            return divs.length > 1 || lists.length >= 1;
        },

        replaceTextInElement: (el, translatedText, elementType) => {
            const isSingleNode = !aiTranslate.isNestedElement(el);
            const translatedLines = isSingleNode ? [translatedText] : elementType === TABLE ? translatedText : translatedText?.split('\n')?.filter(el => el !== "");

            const replaceTextRecursively = (element, lines, elementType, parentType) => {
                if (elementType === TEXT) {
                    if ((isSingleNode && parentType === TEXT) || element.tagName?.toLowerCase() === "li") {
                        const newDiv = document.createElement("div");
                        newDiv.innerHTML = lines.shift() || '';
                        element.innerHTML = newDiv.innerHTML;
                    }
                    else if (element.nodeType === Node.TEXT_NODE) {
                        element.textContent = lines.shift() || ''; // Use an empty string if no more lines
                    }
                    else {
                        const childNodes = Array.from(element.childNodes);
                        for (const child of childNodes) {
                            replaceTextRecursively(child, lines, TEXT, parentType);
                        }
                    }
                }

                if (elementType === TABLE) {
                    const cellElements = element.querySelectorAll("th, td");
                    cellElements.forEach(cell => {
                        let curText = aiTranslate.getTextFromTableCell(cell);
                        let curTranslatedText = translatedLines[curText];
                        if (curText && curTranslatedText) {
                            const translatedSubLines = curTranslatedText.split('\n');
                            replaceTextRecursively(cell, translatedSubLines, TEXT, TABLE);
                        }
                    })
                }
            };

            replaceTextRecursively(el, translatedLines, elementType, elementType);
        },

        adjustFontSizeToFit: ({ element, translatedText, elementType, parentType, widgetRef }) => {
            const { clientHeight: containerHeight, clientWidth: containerWidth } = element;
            const lineHeight = Math.floor(parseFloat(window.getComputedStyle(element).lineHeight))
            const originalFontSize = parseFloat(element.style.fontSize)
            const clonedElement = element.cloneNode(true);
            aiTranslate.replaceTextInElement(clonedElement, translatedText, elementType)

            const sandBox = document.createElement("div");
            Object.assign(sandBox.style, {
                width: `${containerWidth}px`,
                height: `${containerHeight}px`,
                position: "absolute",
                visibility: "hidden",
            });

            sandBox.appendChild(clonedElement);
            document.body.appendChild(sandBox);

            if (elementType === TEXT) {
                const numLinesPrev = parseInt(element.clientHeight / lineHeight);
                let numLinesNow = parseInt(clonedElement.clientHeight / lineHeight);

                let adjustmentStep = .5;  // How much we adjust font size in each iteration
                let newFontSize = originalFontSize;
                let adjutedFontSize = false;
                if (numLinesPrev < numLinesNow) {
                    while ((clonedElement.clientHeight > containerHeight) && newFontSize > originalFontSize / 2) {
                        newFontSize -= adjustmentStep;
                        clonedElement.style.fontSize = newFontSize + "px";
                        numLinesNow = parseInt(clonedElement.clientHeight / lineHeight);
                    }
                    adjutedFontSize = true;
                } if (numLinesPrev > numLinesNow && !adjutedFontSize) {
                    while (clonedElement.clientHeight < containerHeight) {
                        newFontSize += adjustmentStep;
                        clonedElement.style.fontSize = newFontSize + "px";
                        numLinesNow = parseInt(clonedElement.clientHeight / lineHeight);
                    }
                }
                let newHeight = parentType === GROUP_WIDGET ? clonedElement.clientHeight : sandBox.getElementsByClassName("dhp-widget-inner")[0].getBoundingClientRect().height;
                let newWidth = parentType === GROUP_WIDGET ? clonedElement.clientWidth : sandBox.getElementsByClassName("dhp-widget-inner")[0].getBoundingClientRect().width;
                element.style.fontSize = (newFontSize - adjustmentStep) + "px";  // decrease more .5px for best-fit 

                if (parentType !== GROUP_WIDGET) {
                    element.innerHTML = clonedElement.innerHTML
                    const widgetTransform = widgetRef.style.transform
                    const {
                        translate: { x: widgetTransX, y: widgetTransY },
                        rotate: { theta: widgetTheta },
                    } = getCssTransformObj({
                        transform: widgetTransform,
                    });

                    let prevWidth = parseFloat(widgetRef.style.width)
                    let prevHeight = parseFloat(widgetRef.style.height)
                    const { left, top } = calculateNewPositionOnRotatedObjectResize(parseFloat(widgetTransX), parseFloat(widgetTransY), parseFloat(newWidth), parseFloat(newHeight), prevWidth, prevHeight, 0, parseFloat(widgetTheta))

                    const widgetTransformStr = getCssTransformObj({
                        translateX: `${left}px`,
                        translateY: `${top}px`,
                        transform: widgetTransform,
                        returnStr: true,
                    });
                    widgetRef.style.transform = widgetTransformStr
                    document.body.removeChild(sandBox);
                    return { newHeight, widgetTransformStr, updatedHtml: widgetRef.innerHTML };
                } else {
                    element.innerHTML = clonedElement.innerHTML
                    document.body.removeChild(sandBox);
                    return { newHeight, updatedHtml: clonedElement.innerHTML };
                }
            }

            if (elementType === TABLE) {
                let { scale } = getCssTransformObj({
                    transform: element.style.transform,
                });

                let newHeight = clonedElement.querySelector(".dhp-widget-inner > table").clientHeight * scale.x;
                let newWidth = clonedElement.querySelector(".dhp-widget-inner > table").clientWidth * scale.x;

                if (parentType !== GROUP_WIDGET) {
                    element.innerHTML = clonedElement.innerHTML
                    const widgetTransform = widgetRef.style.transform
                    const {
                        translate: { x: widgetTransX, y: widgetTransY },
                        rotate: { theta: widgetTheta },
                    } = getCssTransformObj({
                        transform: widgetTransform,
                    });


                    let prevWidth = parseFloat(widgetRef.style.width)
                    let prevHeight = parseFloat(widgetRef.style.height)
                    const { left, top } = calculateNewPositionOnRotatedObjectResize(parseFloat(widgetTransX), parseFloat(widgetTransY), parseFloat(newWidth), parseFloat(newHeight), prevWidth, prevHeight, 0, parseFloat(widgetTheta))

                    const widgetTransformStr = getCssTransformObj({
                        translateX: `${left}px`,
                        translateY: `${top}px`,
                        transform: widgetTransform,
                        returnStr: true,
                    });
                    widgetRef.style.transform = widgetTransformStr
                    document.body.removeChild(sandBox);
                    return { newHeight, widgetTransformStr, updatedHtml: widgetRef.innerHTML };
                } else {
                    element.innerHTML = clonedElement.innerHTML
                    document.body.removeChild(sandBox);
                    return { newHeight, updatedHtml: clonedElement.innerHTML };
                }
            }
        },

        updateWidgetWithTranslatedText: async () => {
            aiTranslate.toggleInProgress("add");
            updateMetadata({ ...metadata, activeWidgetId: false, activeWidgetType: false })
            const uniqueTexts = aiTranslate.validateLength(aiTranslate.getUniqueTexts());
            if (uniqueTexts) {
                const translationResp = await dispatch(translate({ texts: uniqueTexts, target_language: languages.find(lang => lang.name === destinationLanguage)?.code, used: selectedPageIds.length }));
                const translationsMap = translationResp?.result?.data;
                if (translationsMap) {
                    const allWidgets = JSON.parse(JSON.stringify(widgets))
                    allWidgets.forEach(widget => {
                        if (selectedPageIds.includes(widget.pageId) && widget.data) {
                            const assetType = widget.data["data-asset-type"];

                            if ([TEXT, TABLE].includes(assetType)) {
                                const widgetDOM = document.getElementById(widget.id)
                                const innerElement = widgetDOM.getElementsByClassName("dhp-widget-inner")?.[0];
                                if (innerElement) {
                                    const curContent = assetType === TABLE ? "" : aiTranslate.getTextFromInnerWidget(widgetDOM, assetType)?.[0];
                                    const { newHeight, widgetTransformStr, updatedHtml } = aiTranslate.adjustFontSizeToFit({ element: innerElement, translatedText: assetType === TABLE ? translationsMap : translationsMap[curContent], elementType: assetType, widgetRef: widgetDOM })
                                    widget.style = { ...widget.style, height: newHeight, transform: widgetTransformStr }

                                    widget.innerHTML = updatedHtml;
                                }
                            }

                            if (assetType === GROUP_WIDGET && document.getElementById(widget.id).querySelectorAll(`.dhp-page-widget[data-asset-type='${TEXT}'], .dhp-page-widget[data-asset-type='${TABLE}']`)) {
                                const groupDOM = document.getElementById(widget.id);
                                groupDOM.querySelectorAll(".dhp-page-widget").forEach(innerWidget => {
                                    const innerAssetType = aiTranslate.getWidgetType(innerWidget);
                                    if ([TEXT, TABLE].includes(innerAssetType)) {
                                        const innerElement = innerWidget.getElementsByClassName("dhp-widget-inner")?.[0];
                                        if (innerElement) {
                                            const curContent = innerAssetType === TABLE ? "" : aiTranslate.getTextFromInnerWidget(innerWidget, innerAssetType)?.[0];
                                            aiTranslate.adjustFontSizeToFit({ element: innerElement, translatedText: innerAssetType === TABLE ? translationsMap : translationsMap[curContent], elementType: innerAssetType, parentType: GROUP_WIDGET, widgetRef: groupDOM });
                                        }
                                    }
                                });
                                // update into context
                                let { widgetStyle: groupWidgetNewStyle, newHtml } = aiTranslate.getGroupUpdatedDiaAndPosition(groupDOM);
                                widget.innerHTML = newHtml;
                                widget.style = { ...widget.style, height: groupWidgetNewStyle.height, width: groupWidgetNewStyle.width, transform: groupWidgetNewStyle.transform, }
                            }
                        }
                    });
                    setSuccessMessage(aiTranslate.successMessage);
                    updateWidgets(allWidgets);
                }
                else {
                    setErrorMessage(translationResp?.error?.response?.data?.message === "Rate limit exceeded"
                        ? aiTranslate.textLimits.monthlyLimitReachedErrorMessage
                        : aiTranslate.textLimits.serviceUnavailableErrorMessage)
                }
            }

            setDefaultValue({ name: "Select Language" });
            setDestinationLanguage(false);
            setDisable(true);
            aiTranslate.toggleInProgress("remove");
        },

        getGroupUpdatedDiaAndPosition: (wz) => {
            const { handlerSelection, groupSelection, newHtml } = adjustChildWidgetsForGroupRotation(wz);
            let widgetStyle = {
                handlerTransform: handlerSelection.transform,
                handlerWidth: handlerSelection.width,
                handlerHeight: handlerSelection.height,
                handlerLeft: handlerSelection.left,
                handlerTop: handlerSelection.top,
                transform: groupSelection.transform,
                width: groupSelection.width,
                height: groupSelection.height,
                left: groupSelection.left,
                top: groupSelection.top
            };

            return { widgetStyle, newHtml };
        },

        toggleInProgress: (actionType) => {
            document.getElementById("editorBody")?.classList?.[actionType]?.("pe-none");
            localStorage.setItem("widget.event.isActive", actionType === "add" ? "true" : "false");
            setIsProcessing(actionType === "add" ? true : false)
        },

        handleTranslate: () => {
            if (paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && !paidCompanyInfo?.isPaid) showUpgrade("aiTranslate");
            else aiTranslate.updateWidgetWithTranslatedText();
        }
    }
    return aiTranslate.handleTranslate
}

export default useAiTranslate