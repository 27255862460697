import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connectJotformForms, fetchJotformForms, getConnectedAccounts } from "../store/actions/companyActions";

const useJotform = assetName => {
  const { thirdPartyAccounts, jotformList } = useSelector(state => state.company);
  const dispatch = useDispatch();

  const [apiKey, setApiKey] = useState();
  const [forms, setForms] = useState(null);
  const [isCurrentFormAvailable, setIsCurrentFormAvailable] = useState(false);
  const [isSectionReady, setIsSectionReady] = useState(false);

  useEffect(() => {
    if (apiKey) {
      dispatch(connectJotformForms(apiKey)).then(res => {
        if (res.results.success) {
          dispatch(getConnectedAccounts());
          setIsCurrentFormAvailable(true);
        }
      });
    }
  }, [apiKey]);

  useEffect(() => {
    let formData = [];
    if (jotformList) {
      jotformList?.items?.map(formList => {
        if (formList.status === "ENABLED") {
          formData.push({
            id: formList.id,
            title: formList.title,
            _links: {
              display: formList.url,
            },
          });
        }
      });
      setForms(formData);
    }
  }, [jotformList]);

  useEffect(() => {
    if (thirdPartyAccounts) {
      let isAvailable = thirdPartyAccounts?.details?.find(item => item.provider === assetName);
      if (isAvailable) setIsCurrentFormAvailable(true);
      else setIsSectionReady(true);
    } else dispatch(getConnectedAccounts());
  }, [thirdPartyAccounts]);

  useEffect(() => {
    if (isCurrentFormAvailable) dispatch(fetchJotformForms());
  }, [isCurrentFormAvailable]);

  // JotForm login if not loggedin previously
  const login = () => {
    JF.login(
      function success() {
        const apiKey = JF.getAPIKey();
        if (apiKey) setApiKey(apiKey);
      },
      function error() {}
    );
  };

  return { forms, isLoggedin: isCurrentFormAvailable, login, isSectionReady };
};

export default useJotform;
