import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { EditorContext } from "../../../containers/editor/EditorLayout";
import { TABLE } from "../../../constants/editor";
import UseCheckWidgetAllignment from "../../../hooks/UseCheckWidgetAllignment";
import {
  calculateNewPositionOnRotatedObjectResize,
  getCssTransformObj,
  getUpdatedGroupInnerWidgetPos,
  getZoomedValue,
  updateHeightAndTopForInactiveGroupChildren,
} from "../../../_helpers/utils";
import FontFamilySchemes from "./FontFamilySchemes";

let style = Object.assign({}, global);

const FontFamily = ({ defaultFontSet, setFontFamily, sourceComponent }) => {
  let { metadata, widgets, updateWidgets, dimension, changeBySocket } = useContext(EditorContext);

  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);

  const [defaultValue, setDefaultValue] = useState();

  const { checkWidgetAllignmentForSingleWidget } = UseCheckWidgetAllignment();

  const fonts = JSON.parse(localStorage?.getItem("allFonts"));

  const updateFontFamily = fontFamily => {
    if (sourceComponent !== TABLE) {
      let index = fonts.findIndex(font => font.name.toLowerCase() === fontFamily.replace(/["]+/g, "").toLowerCase());
      let currentelem = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner");
      let groupWidgetInitHeight = isGroupWidget && parseFloat(isGroupWidget.style.height);
      let newArray;

      currentelem.style.fontFamily = `"${fontFamily}"`;

      if (parseInt(currentelem.style.fontWeight) !== 700) {
        currentelem.style.fontWeight = parseInt(fonts[index].normal_weight);
      }
      currentelem.querySelectorAll("*").forEach(node => {
        if (node.style?.fontWeight && parseInt(node.style?.fontWeight) !== 700) {
          node.style.fontWeight = parseInt(fonts[index].normal_weight);
        }
      });

      if (fonts[index].bold_weight === "") {
        currentelem.style.fontWeight = parseInt(fonts[index].normal_weight);

        // remove font weight from all child node if font family dosen't support it
        currentelem.querySelectorAll("*").forEach(node => {
          if (node.style?.fontWeight) {
            node.style.fontWeight = parseInt(fonts[index].normal_weight);
          }
        });
      }
      if (
        ((parseInt(currentelem.style.fontWeight) === 700 && fonts[index].bold_italic_weight === "") ||
          (parseInt(currentelem.style.fontWeight) === parseInt(fonts[index].normal_weight) &&
            fonts[index].normal_italic_weight === "")) &&
        fonts[index]?.source !== "Custom"
      ) {
        currentelem.style.fontStyle = "normal";

        // remove fontStyle from all child node if font family dosen't support it
        currentelem.querySelectorAll("*").forEach(node => {
          if (node.style?.fontStyle) {
            node.style.fontStyle = "normal";
          }
        });
      }

      if (fonts[index]?.source === "Custom" && fonts[index]?.style) {
        currentelem.style.fontStyle = fonts[index].style;
        currentelem.querySelectorAll("*").forEach(node => {
          if (node.style?.fontStyle) {
            node.style.fontStyle = fonts[index].style;
          }
        });
      }

      // if the text is under a group widget
      if (isGroupWidget) {
        // Get group updated height and inner widget updated top and height according group
        let { groupDivHeight, parcentHeight, parcentTop } = getUpdatedGroupInnerWidgetPos(
          targetId,
          id,
          currentelem.offsetHeight
        );

        // update DOM and group handler
        document.getElementById(targetId).style.height = `${groupDivHeight}px`;
        document.getElementById(id).style.height = parcentHeight;
        document.getElementById(id).style.top = parcentTop;
        document.getElementById("dhp-widget-handler").style.height = `${getZoomedValue(
          groupDivHeight,
          dimension.zoom
        )}px`;

        // update height and top for inactive child widgets
        updateHeightAndTopForInactiveGroupChildren({
          group: isGroupWidget,
          groupOldHeight: groupWidgetInitHeight,
          groupNewHeight: groupDivHeight,
          activeChildId: id,
        });

        newArray = Object.assign([...widgets], {
          [targetWidgetIndex]: {
            ...widgets[targetWidgetIndex],
            style: {
              ...widgets[targetWidgetIndex].style,
              height: `${groupDivHeight}px`,
            },
            innerHTML: document.getElementById(targetId).innerHTML,
          },
        });
      }
      // if the text is a main widget
      else {
        const { x_al, y_al } = checkWidgetAllignmentForSingleWidget(currentelem.offsetHeight);
        const widgetObject = widgets[targetWidgetIndex];
        const {
          translate: { x: widgetTransX, y: widgetTransY },
          rotate: { theta: widgetTheta },
        } = getCssTransformObj({
          transform: widgetObject.style.transform,
        });
        // calculate new transform
        const { left, top } = calculateNewPositionOnRotatedObjectResize(
          parseFloat(widgetTransX),
          parseFloat(widgetTransY),
          parseFloat(widgetObject.style.width),
          parseFloat(currentelem.offsetHeight),
          parseFloat(widgetObject.style.width),
          parseFloat(widgetObject.style.height),
          parseFloat(widgetTheta)
        );
        const widgetTransformStr = getCssTransformObj({
          translateX: `${left}px`,
          translateY: `${top}px`,
          transform: widgetObject.style.transform,
          returnStr: true,
        });

        newArray = Object.assign([...widgets], {
          [targetWidgetIndex]: {
            ...widgetObject,
            style: {
              ...widgetObject.style,
              height: `${currentelem.offsetHeight}px`,
              transform: widgetTransformStr,
            },
            data: {
              ...widgetObject.data,
              "data-x-allignment": x_al,
              "data-y-allignment": y_al,
            },
            innerHTML: document.getElementById(metadata.activeWidgetId[0]).innerHTML,
          },
        });
      }

      updateWidgets(newArray);
    }

    if (setFontFamily) setFontFamily(fontFamily);
  };

  const fetchFontFamilyFromActiveWidget = () => {
    if (metadata.activeWidgetId && sourceComponent !== TABLE) {
      let fontFamilyValue = document
        .querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`)
        ?.style?.fontFamily.replace(/["]+/g, "");

      setDefaultValue({ name: fontFamilyValue });
    }
  };

  // Load current widget font family
  useLayoutEffect(() => {
    fetchFontFamilyFromActiveWidget();
  }, [metadata.activeWidgetId]);

  useEffect(() => {
    if (defaultFontSet) setDefaultValue({ name: defaultFontSet });
  }, [defaultFontSet]);

  //check font-family is applied or not for collaboration
  useEffect(() => {
    if (changeBySocket && widgets[targetWidgetIndex]?.innerHTML) {
      fetchFontFamilyFromActiveWidget();
    }
  }, [changeBySocket, widgets[targetWidgetIndex]?.innerHTML]);

  return (
    <li className={style["toolset-group"]}>
      <div className={cx(style["toolset-item"], style["family-wrap"], style["font-common-action"])}>
        <div className={cx(style["form-group"])}>
          {defaultValue && (
            <FontFamilySchemes
              defaultValue={defaultValue}
              getOptionValue={option => option.name}
              getOptionLabel={option => <span style={{ fontFamily: `"${option.name}"` }}>{option.name}</span>}
              options={fonts}
              updateState={option => updateFontFamily(option.name)}
              noOptionsMessage={() => "No results found"}
              isHideBrand={false}
            />
          )}
        </div>
      </div>
    </li>
  );
};

FontFamily.propTypes = {
  defaultFontSet: PropTypes.string,
  setFontFamily: PropTypes.func,
  sourceComponent: PropTypes.string,
};

export default FontFamily;
