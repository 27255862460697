import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Form, Button } from "reactstrap";
import cx from "classnames";

import {
  PUBLISH_COMPANY_TEMPLATE_SUCCESS,
  PUBLISH_COMPANY_TEMPLATE_FAILED,
  PUBLISH_COMPANY_TEMPLATE_INFO_SUCCESS,
  PUBLISH_COMPANY_TEMPLATE_INFO_FAILED,
  GENERATE_DOCUMENT_THUMB_SUCCESS,
} from "../../../store/actions/actionTypes";
import { fetchBrands } from "../../../store/actions/companyActions";
import {
  createDocumentThumb,
  publishCompanyTemplate,
  publishCompanyTemplateInfo,
  updateDocumentReducer,
} from "../../../store/actions/documentActions";
import { preventFormSubmitOnEnter as preventSubmit } from "../../../_helpers/utils";
import { documentInfo } from "../_utils";
import { Input } from "../../ui/input";
import { Icon } from "../../ui/icon";
import ColumnLayoutLoader from "../../ui/loader/columnLayoutLoader";
import SingleLineLoader from "../../ui/loader/singleLineLoader";
import { ProcessLoader } from "../../ui/loader/processLoader";
import { FlashAlert } from "../../ui/flashAlert";

import global from "../../../scss/dhp.scss";
import Sparkle from "../../../assets/images/sparkle.svg";

let style = Object.assign({}, global);

const publishAsCompanyTemplateModal = ({ document: data, _helpers }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const alertData = {
    show: false,
    type: "",
  };
  const errorTypes = [PUBLISH_COMPANY_TEMPLATE_FAILED, PUBLISH_COMPANY_TEMPLATE_INFO_FAILED];
  const processEndTypes = [PUBLISH_COMPANY_TEMPLATE_SUCCESS, PUBLISH_COMPANY_TEMPLATE_FAILED];

  const documentData = useSelector(state => state?.document?.documentDetails?.data);

  const { data: documentName } = documentInfo.get(documentData, "document_name");
  const { data: isPublishedAsCompanyTemplate } = documentInfo.get(documentData, "published_company_template");

  const loaderConfig = {
    layout: 1,
    section: 1,
    col_per_section: {
      1: 2,
    },
    show_page_label: true,
    show_section_label: false,
    show_field_label: true,
    show_field: true,
    loader_height: "200px",
  };

  const form = {
    document_name: {
      value: documentName,
      valid: true,
    },
    publish_under_brand: {
      value: false,
      valid: true,
    },
    brand_name: {
      value: null,
      options: [],
      validation: {
        required: true,
      },
      valid: false,
    },
  };

  const [formData, setFormData] = useState(form);
  const [formValid, setFormValid] = useState(true);
  const [formLabels, setFormLabels] = useState({});
  const [defaultBrand, setDefaultBrand] = useState();
  const [isPublished, setIsPublished] = useState(false);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [fetchingTemplateInfo, setFetchingTemplateInfo] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [alert, setAlert] = useState(alertData);
  const [disable, setDisable] = useState({ publish: false });

  const { type, companyTemplateInfo } = useSelector(state => state.document);
  const { brandInfo } = useSelector(state => state.company);
  const companyId = useSelector(state => state.auth?.user?.company?.id);

  const toggleAlert = () => setAlert({ ...alert, show: !alert.show });

  const checkValidity = (value, rules) => {
    let isValid = true;
    if (rules.required) isValid = value.trim() !== "" && isValid;
    return isValid;
  };

  const updateForm = (e, field) => {
    const updatedForm = { ...formData };
    if (field === "publish_under_brand") {
      updatedForm[field].value = e?.target?.checked ?? e;
      if (!e?.target?.checked) {
        updatedForm.brand_name.value = null;
        updatedForm.brand_name.valid = true;
      } else {
        if (!defaultBrand) updatedForm.brand_name.valid = false;
        else updatedForm.brand_name.value = defaultBrand.id;
      }
    }
    if (field === "brand_name") {
      updatedForm[field].value = e.id;
      updatedForm[field].valid = checkValidity(updatedForm[field].value, updatedForm[field].validation);
    }
    setFormData(updatedForm);
    setFormValid(!Object.keys(updatedForm).some(field => !updatedForm[field].valid));
  };

  const handleSubmit = e => {
    if (formValid) {
      e.preventDefault();
      setIsMounted(true);
      const payload = {
        documentId: data.id,
        brandId: formData.brand_name.value,
      };
      dispatch(publishCompanyTemplate(payload));
      setDisable({ publish: true });
    }
  };

  const postApiCallActions = () => {
    if (type === PUBLISH_COMPANY_TEMPLATE_SUCCESS) {
      setIsMounted(false);
      setPublishSuccess(true);
      documentInfo.set(data, { published_company_template: true });
    }
    if (type === PUBLISH_COMPANY_TEMPLATE_INFO_SUCCESS) {
      setIsMounted(false);
      setFetchingTemplateInfo(false);
      if (companyTemplateInfo?.brand && brandInfo?.total > 0) {
        const associatedBrand = brandInfo?.brands?.find(e => e.id === companyTemplateInfo?.brand);
        const brandObj = { id: associatedBrand?.id, name: associatedBrand?.brand_name };
        updateForm(true, "publish_under_brand");
        updateForm(brandObj, "brand_name");
        setDefaultBrand(brandObj);
        document.getElementById("publish_under_brand").checked = true;
      }
    }
    if (errorTypes.includes(type)) {
      setIsMounted(false);
      setAlert({ ...alert, show: true, type: "error" });
    }
  };

  const gotoBrand = () => {
    history.push(
      formData.brand_name.value
        ? `/companies/settings/brands/${formData.brand_name.value}`
        : "/companies/settings/brands"
    );
  };

  useEffect(() => {
    if (isMounted && type) postApiCallActions();
  }, [type, isMounted]);

  useEffect(() => {
    if (!documentData?.document_name) return;
    let updatedFormData = JSON.parse(JSON.stringify(formData));
    updatedFormData.document_name.value = documentData?.document_name;
    setFormData(updatedFormData);
  }, [documentData?.document_name]);

  useEffect(() => {
    if (brandInfo?.total > 0) {
      const updatedForm = { ...formData };
      updatedForm.brand_name.options = brandInfo?.brands?.map(e => ({ id: e.id, name: e.brand_name }));
      setFormData(updatedForm);
    }
    if (isPublished && brandInfo?.total >= 0) {
      // trigger screenshot generation if opened from editor (so that new thumb is ready before publish)
      if (_helpers.ref === "editor") {
        dispatch(
          createDocumentThumb({
            companyId: companyId,
            entryId: data.id,
            slug: "documents",
          })
        ).then(r => {
          if (r.type === GENERATE_DOCUMENT_THUMB_SUCCESS) dispatch(publishCompanyTemplateInfo(data.id));
        });
      } else dispatch(publishCompanyTemplateInfo(data.id));
    }
  }, [brandInfo, isPublished]);

  useEffect(() => {
    // bugfix ~ wrong message immediately republish as company template modal
    dispatch(updateDocumentReducer({ flashMessage: null }));

    // regular functionalities
    const isPublished = isPublishedAsCompanyTemplate || data.published_company_template;
    setIsPublished(isPublished);
    setFormLabels({
      modalName: isPublished ? "Republish as Company Template" : "Publish as Company Template",
      ButtonName: isPublished ? "Republish" : "Publish",
      successMessage: isPublished
        ? "Document is republished as a company template!"
        : "Document is published as a company template!",
    });
    if (_helpers.ref !== "editor") dispatch(fetchBrands({ page: 1, limit: 100 }));
    if (isPublished) {
      setIsMounted(true);
      setFetchingTemplateInfo(true);
    }
  }, []);

  return (
    <React.Fragment>
      {!fetchingTemplateInfo && <h4 className={cx(style["fw-7"], style["mb-4"])}>{formLabels?.modalName}</h4>}

      <span className={cx(style["cross-modal"], style["rounded"])} onClick={_helpers?.modal?.toggle}>
        <Icon icon="ui-close" />
      </span>

      {fetchingTemplateInfo && (
        <>
          <ColumnLayoutLoader {...loaderConfig} />
          <SingleLineLoader width={75} height="34px" />
          <SingleLineLoader width={25} height="24px" />
        </>
      )}

      {!publishSuccess && (
        <Form
          onKeyDown={e => preventSubmit(e)}
          onSubmit={e => handleSubmit(e)}
          className={cx({ [style["d-none"]]: fetchingTemplateInfo })}>
          <Input
            cssModule={style}
            returnType="formGroup"
            type="text"
            label="Document Name"
            readOnly={true}
            value={formData.document_name.value}
          />

          <div className={cx(style["custom-control"], style["custom-switch"], style["my-4"])}>
            <Input
              cssModule={style}
              returnType="noGroup"
              type="checkbox"
              className={style["custom-control-input"]}
              id="publish_under_brand"
              label="Publish under a brand"
              defaultChecked={formData.publish_under_brand.value}
              onChange={e => updateForm(e, "publish_under_brand")}
            />
          </div>

          {formData.publish_under_brand.value && (
            <div
              className={cx(
                style["d-flex"],
                style["justify-content-between"],
                style["my-4"],
                style["select-manage-brand"]
              )}>
              <Input
                cssModule={style}
                returnType="formGroup"
                type="dropdown"
                label="Brand"
                required={true}
                classNamePrefix="select"
                getOptionValue={option => option?.name}
                getOptionLabel={option => <span>{option?.name}</span>}
                options={formData.brand_name.options}
                updateState={e => updateForm(e, "brand_name")}
                defaultValue={defaultBrand}
              />
              <Link
                to="#"
                className={cx(style["rounded"], style["settings-icon"], style["custom-tooltip"])}
                onClick={gotoBrand}>
                <div className={cx(style["d-flex"])}>
                  <Icon icon="ui-settings" />
                </div>
                <div className={cx(style["custom-tooltip-content"], style["bottom"], style["mt-5"])}>Manage Brand</div>
              </Link>
            </div>
          )}

          <div className={cx(style["my-4"])}>
            You can unpublish a company template at any time. Further, you can always edit this document and republish
            it.
          </div>

          <div className={cx(style["align-items-center"], style["d-flex"])}>
            <Button type="submit" color="primary" cssModule={style} disabled={!formValid || disable.publish}>
              {formLabels?.ButtonName}
            </Button>
            <ProcessLoader isOpen={disable.publish && !processEndTypes.includes(type)} />
            <FlashAlert
              isOpen={alert.show}
              toggle={toggleAlert}
              type={alert.type}
              wrapperClass="ml-2"
              message={alert.message}
            />
          </div>
        </Form>
      )}

      {publishSuccess && (
        <div className={cx(style["download-link"], style["download-steps"], style["text-center"])}>
          <img src={Sparkle} width="70" alt="" />
          <h6 className={cx(style["fw-7"], style["pb-1"], style["mt-4"])}>{formLabels?.successMessage}</h6>
          <p className={style["mb-0"]}>
            All your company templates are available on the <br />
            <b>Templates</b> page. <Link to="/templates/companies">Click here</Link> to view them
          </p>
        </div>
      )}
    </React.Fragment>
  );
};

publishAsCompanyTemplateModal.propTypes = {
  document: PropTypes.object.isRequired,
  _helpers: PropTypes.object.isRequired,
};

export default publishAsCompanyTemplateModal;
