import React, { useContext } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import useWidgetHandler from "../../../hooks/useWidgetHandler";

let style = Object.assign({}, global);

const Lock = props => {
  const { toggleEventHandlers: toggleWidgetEventHandlers } = useWidgetHandler();

  let { metadata, widgets, updateWidgets } = useContext(EditorContext);

  let isGroupWidget = document.getElementById(metadata.activeWidgetId[0])?.closest(".dhp-page-group");
  let targetId = isGroupWidget
    ? document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-root-widget").getAttribute("id")
    : metadata.activeWidgetId[0];
  let widgetIndex = widgets.findIndex(widget => widget.id === targetId);

  const lockWidget = () => {
    let newClassList;
    let newArray = Object.assign([...widgets]);

    metadata.activeWidgetId.forEach(id => {
      let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
      let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
      let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);

      //Add disable class for locked widget
      if (!widgets[targetWidgetIndex].data["data-layer-locked"]) {
        newClassList = [...widgets[targetWidgetIndex].classLists, "draggable-disabled"];
        toggleWidgetEventHandlers({ action: "HIDE" });
      }
      //remove disable class for unlocked widget
      else {
        newClassList = widgets[targetWidgetIndex].classLists.filter(className => className !== "draggable-disabled");
        toggleWidgetEventHandlers({ action: "SHOW" });
      }

      newArray = Object.assign([...newArray], {
        [targetWidgetIndex]: {
          ...newArray[targetWidgetIndex],
          data: {
            ...newArray[targetWidgetIndex].data,
            "data-layer-locked": !newArray[targetWidgetIndex].data["data-layer-locked"],
          },
          classLists: newClassList,
        },
      });
    });

    updateWidgets(newArray);

    // Add group selected class for multi selction and single selected class forsingle selction in DOM
    setTimeout(() => {
      if (metadata.activeWidgetId.length > 1) {
        metadata.activeWidgetId.forEach(id => {
          let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
          let targetId = isGroupWidget
            ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id")
            : id;
          document.getElementById(targetId).classList.add("group-selected");
        });
      } else {
        let isGroupWidget = document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-page-group");
        let targetId = isGroupWidget
          ? document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-root-widget").getAttribute("id")
          : metadata.activeWidgetId[0];
        document.getElementById(targetId).classList.add("single-selected");
      }
    }, 10);
  };

  return (
    <li
      className={cx(style["toolset-group"], {
        [style["active"]]: widgets[widgetIndex]?.data["data-layer-locked"],
      })}>
      <div className={cx(style["toolset-item"], style["lock-wrap"])}>
        <span className={cx(style["toolset-action"], style["custom-tooltip"])} onClick={lockWidget}>
          <Icon icon="ui-lock2" />
          {!props.more && !widgets[widgetIndex]?.data["data-layer-locked"] && (
            <div className={cx(style["custom-tooltip-content"], style["top"])}>Lock</div>
          )}
          {!props.more && widgets[widgetIndex]?.data["data-layer-locked"] && (
            <div className={cx(style["custom-tooltip-content"], style["top"])}>Unlock</div>
          )}
          {props.more && "Lock"}
        </span>
      </div>
    </li>
  );
};
Lock.propTypes = {
  more: PropTypes.bool,
};

export default Lock;
