import React, { useEffect, useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import cx from "classnames";
import Select from "react-select";
import PropTypes from "prop-types";
import global from "../../scss/dhp.scss";
import LoadFlagImage from "./LoadFlagImage";
import { Icon } from "./icon";

let style = Object.assign({}, global);

const selectStyles = {
  control: provided => ({
    ...provided,
    minWidth: 240,
    margin: 8,
    flexDirection: "row-reverse",
    paddingLeft: "5px",
  }),
};

const hideSearchStyle = {
  control: provided => ({
    ...provided,
    display: "none"
  }),
};

const Select2 = ({ updateState, customLabel, labelField, subField, isDisabled, dropDownMenuClass, ...props }) => {
  const [value, setValue] = useState(props?.defaultValue);
  const onSelectChange = value => {
    toggle();
    if (props?.target !== "filter_records") setValue(value);
    if (subField) updateState(value, props.name, subField);
    else updateState(value, props.name);
  };

  // For reposition dropdown list on user input when position is top
  const onSearchChange = e => {
    let optionPosition = e.target.closest(".searchList-area");
    const transform = optionPosition.style.transform;
    const re = /translate3d\((?<x>.*?)px, (?<y>.*?)px, (?<z>.*?)px/;
    const results = re.exec(transform);
    if (results.groups.y < 0) {
      setTimeout(() => {
        optionPosition.style.transform = `translate3d(0px, -${optionPosition.offsetHeight + 8}px, 0px)`;
      }, 3);
    }
  };

  const handleMenuOpen = () => {
    setTimeout(() => {
      const currentDropdown = document.querySelectorAll(".searchList-area.show")[0];
      if (currentDropdown) {
        const selectedEl = currentDropdown.getElementsByClassName("select__option--is-selected")[0];
        const isBulkAddModal = document.querySelector(".BulkAdd-table");
        if (selectedEl && !isBulkAddModal) {
          selectedEl.scrollIntoView({ block: "nearest", inline: "start" });
        }else if(selectedEl && isBulkAddModal){
          selectedEl.scrollIntoView({ block: "nearest", inline: "nearest" });
        }
      }
    }, 15);
  };

  const stylesToApply = props.options?.length === 1 ? hideSearchStyle : selectStyles;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  useEffect(() => {
    if (dropdownOpen) handleMenuOpen(); // To fix reset dropdown scrolled position
  }, [dropdownOpen]);

  useEffect(() => {
    setValue(props?.defaultValue);
  }, [props?.defaultValue]);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className={style["custom_select2"]} disabled={isDisabled}>
      <DropdownToggle className={cx(style["w-100"], { [style[dropDownMenuClass]]: dropDownMenuClass })}>
        {value ? <SelectLabel customLabel={customLabel} labelField={labelField} buttonValue={value} /> : "\u00A0"}
        <Icon icon="ui-arrow-down" />
      </DropdownToggle>
      <DropdownMenu className={`searchList-area p-0 border-0 ${dropDownMenuClass ?? ""}`}>
        <Select
          autoFocus
          classNamePrefix="select"
          backspaceRemovesValue={false}
          components={{ DropdownIndicator, IndicatorSeparator: null }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          onKeyDown={e => onSearchChange(e)}
          onChange={onSelectChange}
          placeholder=""
          styles={stylesToApply}
          tabSelectsValue={false}
          value={value}
          menuShouldScrollIntoView={false}
          noOptionsMessage={() => "No results found"}
          {...props}
        />
      </DropdownMenu>
    </Dropdown>
  );
};

Select2.propTypes = {
  updateState: PropTypes.func,
  customLabel: PropTypes.bool,
  labelField: PropTypes.string,
  subField: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.object,
  isDisabled: PropTypes.bool,
  dropDownMenuClass: PropTypes.string,
};

const SelectLabel = props => {
  return (
    <div>
      {props.customLabel && (
        <span>
          <LoadFlagImage isoCode={props.buttonValue.iso_code} />{" "}
          {props.labelField ? `+${props.buttonValue[props.labelField]}` : props.buttonValue.name}
        </span>
      )}
      {!props.customLabel && props.buttonValue.name}
      {!props.customLabel && !props.buttonValue.name && props.buttonValue.firstname && props.buttonValue.lastname && (
        <span>
          {props.buttonValue.firstname} {props.buttonValue.lastname}
        </span>
      )}
    </div>
  );
};

SelectLabel.propTypes = {
  customLabel: PropTypes.bool,
  buttonValue: PropTypes.object,
  labelField: PropTypes.string,
};

const DropdownIndicator = () => <Icon icon="ui-search" />;

export default Select2;
