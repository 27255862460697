import React, { useEffect, useState } from "react";
import cx from "classnames";
import { Link } from "react-router-dom";
import { Button, Card, CardText, Input, UncontrolledTooltip } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import TypeformLogo from "../../../assets/images/Typeform-Logo.svg";
import JotformLogo from "../../../assets/images/Jotform-Logo.svg";
import global from "../../../scss/dhp.scss";

import AssetName from "./Common/AssetName";
import useTypeform from "../../../hooks/useTypeform";
import useJotform from "../../../hooks/useJotform";
import { getConnectedAccounts } from "../../../store/actions/companyActions";
import { Icon } from "../../ui/icon";
import useAlignment from "../../../hooks/useAlignment";
import useAddWidget from "../../../hooks/useAddWidget";
import useElementInnerHtml from "../../../hooks/useElementInnerHtml";
import { widgetConfig } from "../editor_config";
import useDragDropWidget from "../../../hooks/useDragDropWidget";
import AssetsLoader from "../../ui/loader/assetsLoader";
import TypeformSvg from "../../../assets/images/Typeform-Symbol.svg";
import JotformSvg from "../../../assets/images/Jotform-Symbol.svg";
import { THIRD_PARTY_APPS } from "../../../constants/editor";

let style = Object.assign({}, global);

const svgIconList = {
  typeforms: TypeformSvg,
  jotforms: JotformSvg,
};

const TypeForm = ({ assetName, companyId, userId, setIsLoggedin, setFormList }) => {
  const { forms, isLoggedin, login, isSectionReady } = useTypeform(userId, companyId, assetName);

  useEffect(() => {
    setIsLoggedin(isLoggedin);
  }, [isLoggedin]);

  useEffect(() => {
    if (forms) setFormList(forms);
  }, [forms]);

  return (
    <>
      {!isLoggedin && isSectionReady && (
        <Card body className={cx(style["mr-20"], style["connect-wrap"])}>
          <figure>
            <img src={TypeformLogo} alt="TypeForm" className={cx(style["w-100"])} />
          </figure>
          <CardText className="text-center">
            Add{" "}
            <Link to="//www.typeform.com/" target="_blank" className={style["text-capitalize"]}>
              Typeforms
            </Link>{" "}
            into DocHipo documents
          </CardText>
          <Button className={style["btn-border"]} onClick={login}>
            Connect
          </Button>
        </Card>
      )}
    </>
  );
};

const JotForm = ({ assetName, setIsLoggedin, setFormList }) => {
  const { forms, isLoggedin, login, isSectionReady } = useJotform(assetName);
  useEffect(() => {
    setIsLoggedin(isLoggedin);
  }, [isLoggedin]);

  useEffect(() => {
    if (forms) setFormList(forms);
  }, [forms]);

  return (
    <>
      {!isLoggedin && isSectionReady && (
        <Card body className={cx(style["mr-20"], style["connect-wrap"])}>
          <figure>
            <img src={JotformLogo} alt="JotForm" className={cx(style["w-100"])} />
          </figure>
          <CardText className="text-center">
            Add{" "}
            <Link to="//www.jotform.com/" target="_blank" className={style["text-capitalize"]}>
              Jotforms
            </Link>{" "}
            into DocHipo documents
          </CardText>
          <Button className={style["btn-border"]} onClick={login}>
            Connect
          </Button>
        </Card>
      )}
    </>
  );
};

const Form = ({ handleWidgetAction, assetName, assetType }) => {
  const dispatch = useDispatch();
  const { start: initDragDropWidget } = useDragDropWidget();

  const { thirdPartyAccounts } = useSelector(state => state.company);
  const { user: userInfo, uid: userId } = useSelector(state => state.auth);

  const [isLoggedin, setIsLoggedin] = useState(false);
  const [formList, setFormList] = useState();
  const [assetInnerContent, setAssetInnerContent] = useState();
  const [addWidgetClicked, setAddWidgetClicked] = useState(false);
  const [dataparam, setDataParam] = useState();
  const [widgetWidth, setWidgetWidth] = useState();
  const [widgetHeight, setWidgetHeight] = useState();
  const [formData, setformData] = useState();

  // Set Tooltip boundry
  const tooltipBoundry = document.getElementById("editorBody");

  const { postion: dropPosition } = useAlignment(addWidgetClicked, widgetWidth, widgetHeight, "middle-center");
  const getNewWidgetObject = useAddWidget(
    addWidgetClicked,
    assetType,
    dataparam,
    dropPosition,
    widgetWidth,
    widgetHeight
  );
  useElementInnerHtml(
    addWidgetClicked,
    setAddWidgetClicked,
    assetType,
    assetInnerContent,
    dataparam,
    getNewWidgetObject
  );

  // Filter form by name
  const filterForm = value => {
    const lowerCaseValue = value.toLowerCase().trim();
    if (!lowerCaseValue) {
      setformData(formList);
    } else {
      const filteredFormList = formList?.filter(form => {
        return form.title.toLowerCase().includes(lowerCaseValue);
      });
      setformData(filteredFormList);
    }
  };

  const Sections = {
    Typeform: TypeForm,
    Jotform: JotForm,
  };

  const Component = Sections[assetName];

  const insertForm = link => {
    // Generate node for iframe
    let node = document.createElement("iframe");
    node.src = `${link}?${widgetConfig[assetType]?.linkParams}`;
    node.style.cssText = widgetConfig[assetType]?.iframeInnerHTML?.cssText ?? "";
    node.classList = "customapp-frame";

    setAssetInnerContent(node.outerHTML);
    setWidgetWidth(widgetConfig[assetType]?.width);
    setWidgetHeight(widgetConfig[assetType]?.height);
    setDataParam({ ...widgetConfig[assetType].dataAttr, "data-form-type": assetType });
  };

  // trigger widget INSERT
  useEffect(() => {
    if ((assetInnerContent, widgetWidth && widgetHeight && dataparam)) {
      setAddWidgetClicked(true);
    }
  }, [assetInnerContent, widgetWidth, widgetHeight, dataparam]);

  // Update form data on formlist change
  useEffect(() => {
    if (formList) setformData(formList);
  }, [formList]);

  useEffect(() => {
    if (!thirdPartyAccounts) dispatch(getConnectedAccounts());
  }, []);

  return (
    <div className={cx(style["editor-asset-inner"], style["typeform-asset"])}>
      <AssetName handleWidgetAction={handleWidgetAction} assetName={assetName} />

      {isLoggedin && (
        <div className={cx(style["search-field"], style["pt-0"], style["pl-0"])}>
          <div className={cx(style["search-area"], style["clearfix"], style["mb-3"])}>
            <span className={style["searchicon"]}>
              <Icon icon="ui-search" />
            </span>
            <Input
              type="text"
              id="widgetSearch"
              onKeyUp={e => filterForm(e.target.value)}
              className={style["form-control"]}
              placeholder={`Search ${assetName}s`}
            />
          </div>
        </div>
      )}

      <Component
        assetName={assetName}
        companyId={userInfo?.company?.id}
        userId={userId}
        setIsLoggedin={setIsLoggedin}
        setFormList={setFormList}
      />

      {formList?.length === 0 && (
        <p className={cx(style["mr-20"], style["text-center"], style["font-base"])}>
          You do not have any <br /> published {`${assetName}` + "s"} yet in <br />
          your account
        </p>
      )}

      <div className={cx(style["customScroll"], style["scroll-Y"], style["assets-wrapper"])}>
        <ul className={cx(style["row"], style["row-cols-2"])}>
          {!formList && isLoggedin && <AssetsLoader count={6} />}
          {formData?.map(formInfo => (
            <li
              key={formInfo.id}
              id={`${assetType}${formInfo.id}`}
              className={cx(style["col"], style["skeleton-loader-area"])}>
              <div
                className={cx(style["asset-item"], style["flex-column"], style["form-item"])}
                onClick={() => insertForm(formInfo?._links?.display)}
                onMouseDown={e =>
                  initDragDropWidget(
                    {
                      asset: assetType,
                      value: formInfo?._links?.display,
                    },
                    e
                  )
                }>
                {THIRD_PARTY_APPS.includes(assetType) && <img src={svgIconList[assetType]} width="30" alt="" />}
                {!THIRD_PARTY_APPS.includes(assetType) && <Icon icon="ui-typeform2" />}
                <span className={style["item-name"]}>{formInfo.title}</span>
                <UncontrolledTooltip
                  placement="bottom"
                  target={`${assetType}${formInfo.id}`}
                  delay={{ show: 200, hide: 0 }}
                  boundariesElement={tooltipBoundry}>
                  <span className={style["item-name"]}>{formInfo.title}</span>
                </UncontrolledTooltip>
              </div>
            </li>
          ))}
        </ul>
        {formList?.length > 0 && formData?.length === 0 && (
          <ul className={cx(style["row"], style["row-cols-1"])}>
            <li className={style["col"]}>
              <div className={cx(style["alert"], style["alert-block"], style["alert-danger"])}>
                No matching Forms found
              </div>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

//Props type validation
TypeForm.propTypes = {
  setIsLoggedin: PropTypes.func.isRequired,
  assetName: PropTypes.string,
  setFormList: PropTypes.func,
  companyId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

JotForm.propTypes = {
  setIsLoggedin: PropTypes.func.isRequired,
  assetName: PropTypes.string,
  setFormList: PropTypes.func,
};

//Props type validation
Form.propTypes = {
  handleWidgetAction: PropTypes.func.isRequired,
  assetName: PropTypes.string,
  assetType: PropTypes.string,
};

export default Form;
