import { useContext, useEffect, useState } from 'react'
import { EditorContext } from '../containers/editor/EditorLayout';
import useFind from './useFind';
import useReplace from './useReplace';
import { debounceTrailing } from '../_helpers/utils';

const useFindAndReplace = () => {
    const { showFindAndReplace, setShowFindAndReplace, widgets, metadata } = useContext(EditorContext);

    const initialState = {
        findKey: "",
        replaceKey: "",
        intFind: false,
        matches: 0,
        allMatches: false,
        widgetMatchesById: false,
        activeIndex: -1,
        displayIndex: false,
        activeWidgetId: false,
        activeBlockId: false,
        caseInsensitive: false,
        isLocked: false,
        lockedInPages: []
    }
    const [state, setState] = useState(initialState);
    const [replaceInprogress, setReplaceInprogress] = useState(false);
    const { collectWidgets, heiglightMatch, removeHeighlighter } = useFind({ state });
    const { replaceCurrentMatch, replaceAllMatches } = useReplace({ state })

    useEffect(() => {
        if (!showFindAndReplace.isActive) {
            removeHeighlighter()
        }
        return () => {
            setState(initialState)
        }
    }, [showFindAndReplace?.isActive])

    useEffect(() => {
        if (!state.findKey || widgets.length > 0) {
            setState({ ...state, intFind: false, matches: 0, activeIndex: -1, isLocked: false })
            findAndReplaceHelper.highlighter?.classList?.add("d-none")
        }
    }, [state.findKey, state.caseInsensitive])

    useEffect(() => {
        if (!replaceInprogress && widgets.length > 0 && state.findKey) {
            let { widgetMatches: allMatches, totalCount: matches, widgetMatchesById, lockedInPages } = collectWidgets()
            let activeIndex = state.activeIndex > matches - 1 ? 0 : state.activeIndex;
            setState({ ...state, matches, allMatches, widgetMatchesById, activeIndex, lockedInPages, isLocked: allMatches?.[activeIndex]?.widgetMeta?.isLocked });
            setTimeout(() => {
                if (matches > 0) heiglightMatch(activeIndex, allMatches, false)
                else removeHeighlighter()
            }, 100)
        }
        if (replaceInprogress) setReplaceInprogress(!replaceInprogress)
    }, [widgets])

    const findAndReplaceHelper = {
        highlighter: document.getElementById('dhp-find-highlighter'),
        customSelectionRange: document.createRange(),
        previous: () => {
            if (!state.intFind) {
                let { widgetMatches: allMatches, totalCount: matches, widgetMatchesById, lockedInPages } = collectWidgets()
                let activeIndex = 0;
                if (state.activeIndex === -1)
                    activeIndex = findAndReplaceHelper.findMatchIndexFromActivePage("prev", allMatches);

                setState({
                    ...state,
                    activeIndex,
                    intFind: true,
                    matches,
                    allMatches,
                    widgetMatchesById,
                    lockedInPages,
                    isLocked: allMatches[activeIndex]?.widgetMeta?.isLocked,
                });
                heiglightMatch(activeIndex, allMatches);
            }
            else {
                let activeIndex = state.activeIndex - 1 < 0 ? state.matches - 1 : state.activeIndex - 1;
                if (state.activeIndex === -1)
                    activeIndex = findAndReplaceHelper.findMatchIndexFromActivePage();

                setState({ ...state, activeIndex, isLocked: state.allMatches[activeIndex].widgetMeta.isLocked })
                heiglightMatch(activeIndex);
            }

        },
        next: () => {
            if (!state.intFind) {
                let { widgetMatches: allMatches, totalCount: matches, widgetMatchesById, lockedInPages } = collectWidgets()
                let activeIndex = 0;
                if (state.activeIndex === -1)
                    activeIndex = findAndReplaceHelper.findMatchIndexFromActivePage("next", allMatches);

                setState({
                  ...state,
                  activeIndex,
                  intFind: true,
                  matches,
                  allMatches,
                  widgetMatchesById,
                  lockedInPages,
                  isLocked: allMatches[activeIndex]?.widgetMeta?.isLocked,
                });
                heiglightMatch(activeIndex, allMatches);
            }
            else {
                let activeIndex = state.activeIndex + 1 > state.matches - 1 ? 0 : state.activeIndex + 1;
                if (state.activeIndex === -1)
                    activeIndex = findAndReplaceHelper.findMatchIndexFromActivePage("next");

                setState({ ...state, activeIndex, isLocked: state.allMatches[activeIndex].widgetMeta.isLocked })
                heiglightMatch(activeIndex);
            }
        },
        replaceCurrent: debounceTrailing(() => {
            setReplaceInprogress(true)
            removeHeighlighter();
            replaceCurrentMatch()
            findAndReplaceHelper.collectWidgetsAfterReplace();
        }, 100),
        replaceAll: debounceTrailing(() => {
            setReplaceInprogress(true)
            removeHeighlighter();
            replaceAllMatches();
            findAndReplaceHelper.collectWidgetsAfterReplace();
        }, 100),
        collectWidgetsAfterReplace: () => {
            setTimeout(() => {
                let { widgetMatches: allMatches, totalCount: matches, widgetMatchesById, lockedInPages } = collectWidgets()
                let newActiveIndex = state.activeIndex > matches - 1 ? 0 : state.activeIndex;
                setState({ ...state, matches, allMatches, activeIndex: newActiveIndex, widgetMatchesById, lockedInPages, isLocked: allMatches?.[newActiveIndex]?.widgetMeta?.isLocked })
                if (matches > 0)
                    heiglightMatch(newActiveIndex, allMatches);
                else
                    setShowFindAndReplace({ ...showFindAndReplace, curState: { ...showFindAndReplace.curState, widgetRef: false } })
                setReplaceInprogress(false)
            }, 0)
        },
        findMatchIndexFromActivePage: (actionType, allMatches) => {
            let matchIndexInActivePage = actionType === "next" ?
                allMatches.findIndex(wz => wz.widgetMeta.activeBlockId === metadata.activeBlockId) :
                allMatches.findLastIndex(wz => wz.widgetMeta.activeBlockId === metadata.activeBlockId);
            return matchIndexInActivePage >= 0 ? matchIndexInActivePage : 0;
        }
    }

    return { findAndReplaceHelper, state, setState, replaceInprogress, setReplaceInprogress };
}

export default useFindAndReplace