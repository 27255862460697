import React from 'react'
import PreLoader from "../../assets/images/dh-loader.svg";
import PropTypes from "prop-types";
import cx from "classnames";
import global from "../../scss/dhp.scss";

let style = Object.assign({}, global);

const ParserLoader = ({ origin }) => {
    const loaderHeight = 60;

    return (
        <div className={cx(style["align-items-center"], style["d-flex"], style["justify-content-center"])} style={{ height: origin === "present" ? "100vh" : "calc(100vh - 60px)" }}>
            <img
                src={PreLoader}
                height={loaderHeight}
                alt="" />
        </div>
    )
}
export default ParserLoader;


ParserLoader.propTypes = {
    origin: PropTypes.string
};
