import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";

import * as constant from "../constants/editor";
import { widgetConfig } from "../components/Editor/editor_config";
import { changeWidgetColorAccordingBackground, getCssTextObjToString, hexToRGB } from "../../src/_helpers/utils";
import { EditorContext } from "../containers/editor/EditorLayout";
import useWidgetHandler from "./useWidgetHandler";
import { triggerUnsplashDownload } from "../store/actions/resourcesActions";

const useElementInnerHtml = (
  addWidgetClicked,
  setAddWidgetClicked,
  assettype,
  innerContent,
  param,
  widgetObject,
  customCss = {},
  brandText,
  cusetomClass
) => {
  const dispatch = useDispatch();
  let { metadata, backgroundColors, widgets, updateWidgets, updateMetadata } = useContext(EditorContext);

  const { toggleEventHandlers: toggleWidgetEventHandlers } = useWidgetHandler();
  useEffect(() => {
    if (addWidgetClicked && widgetObject) {
      let tag,
        classList,
        cssText,
        dataAttribute,
        node,
        widgetColor,
        activeBlockColor,
        cssValue = "";
      let index = backgroundColors.findIndex(backgroundColor => backgroundColor.blockId === metadata.activeBlockId);

      if (index !== -1) activeBlockColor = hexToRGB(backgroundColors[index]?.style.backgroundColor);
      widgetColor = changeWidgetColorAccordingBackground(activeBlockColor);
      setAddWidgetClicked && setAddWidgetClicked(false);

      // Set tag, classlist, style, data attribute for TEXT widget innerContent
      if (constant.TEXT === assettype) {
        tag = widgetConfig[assettype][param["data-heading"]].tag;
        classList = widgetConfig[assettype][param["data-heading"]].innerHTML.classList;
        cssText = {
          ...widgetConfig[assettype][param["data-heading"]].innerHTML.style,
          ...customCss,
          color: widgetColor,
        };
        dataAttribute = widgetConfig[assettype][param["data-heading"]].innerHTML.dataAttr;
      }
      // Set tag, classlist, style, data attribute for Video widget innerContent
      else if (constant.VIDEO === assettype) {
        tag = widgetConfig[assettype][param["data-source"]].tag;
        classList = widgetConfig[assettype][param["data-source"]].innerHTML.classList;
        cssText = widgetConfig[assettype][param["data-source"]].innerHTML.style;
        dataAttribute = widgetConfig[assettype][param["data-source"]].innerHTML.dataAttr;
      }
      // Set tag, classlist, style, data attribute for UPLOADED SVG widget innerContent
      else if (constant.UPLOAD === assettype && param["data-file-type"] === constant.SVG) {
        tag = widgetConfig[assettype][param["data-file-type"]].tag;
        classList = widgetConfig[assettype][param["data-file-type"]].innerHTML.classList;
        cssText = { ...widgetConfig[assettype][param["data-file-type"]].innerHTML.style, fill: widgetColor };
        dataAttribute = widgetConfig[assettype][param["data-file-type"]].innerHTML.dataAttr;
      }
      // Set tag, classlist, style, data attribute for MAP widget innerContent
      else if (constant.MAP === assettype) {
        tag = widgetConfig[assettype].tag;
        classList = cusetomClass
          ? `${widgetConfig[assettype].innerHTML.classList} ${cusetomClass}`
          : widgetConfig[assettype].innerHTML.classList;
        dataAttribute = widgetConfig[assettype].innerHTML.dataAttr;
        cssText = { ...widgetConfig[assettype].innerHTML.style, ...customCss };
      }
      // Set tag, classlist, style, data attribute for OTHER widget innerContent
      else {
        if (!assettype) return; // Return if assetType is not available
        tag = widgetConfig[assettype].tag;
        classList = widgetConfig[assettype].innerHTML.classList;
        dataAttribute = widgetConfig[assettype].innerHTML.dataAttr;
        cssText =
          widgetConfig[assettype].tag === "svg"
            ? { ...widgetConfig[assettype].innerHTML.style, fill: widgetColor }
            : widgetConfig[assettype].innerHTML.style;

        //for any mono icon drop in dark background
        if (widgetConfig[assettype].tag === "svg" && param?.["data-scheme"] === constant.MONO) {
          innerContent.querySelectorAll("*").forEach(function (node) {
            if (node.style?.fill) node.style.fill = widgetColor;
          });
        }
      }

      // Create Node
      if (tag !== "svg") {
        node = document.createElement(tag);
        if ([constant.TEXT, constant.TYPE_FORM, constant.JOT_FORM].includes(assettype)) node.innerHTML = innerContent;
        else if ([constant.TABLE, constant.COLLAGE, constant.MAP].includes(assettype)) node = innerContent;
        else if (
          [constant.PICTURE, constant.UPLOAD, constant.VIDEO, constant.ANIMATION].includes(assettype) &&
          param["data-file-type"] !== constant.SVG &&
          param["data-source"] !== constant.YOUTUBE
        ) {
          let wrapperDiv = document.createElement("div");
          wrapperDiv.classList.add("dhp-widget-inner");
          let wrapper = constant.VIDEO === assettype ? document.createElement("video") : document.createElement("img");
          if (constant.VIDEO === assettype) wrapper.poster = param["data-poster"];

          wrapperDiv.style.cssText = getCssTextObjToString({
            ...cssText,
            height: constant.ANIMATION === assettype ? "100%" : `${widgetObject.style.height}px`,
            width: constant.ANIMATION === assettype ? "100%" : `${widgetObject.style.width}px`,
          });

          wrapper.style.cssText =
            constant.VIDEO === assettype
              ? getCssTextObjToString(widgetConfig[assettype][constant.STOCK].childElement.style)
              : getCssTextObjToString(widgetConfig[assettype].childElement.style);

          cssText = {
            height: `${widgetObject.style.height}px`,
            width: `${widgetObject.style.width}px`,
            overflow: "hidden",
          };

          wrapper.src = innerContent;
          wrapperDiv.appendChild(wrapper);
          node.appendChild(wrapperDiv);
        } else node.src = innerContent;
      } else {
        node = innerContent;
      }

      cssValue = getCssTextObjToString(cssText);
      node.classList = classList;

      if (constant.ANIMATION !== assettype && constant.QR_CODE !== assettype) {
        node.style.cssText = cssValue;
      }

      for (const [key, value] of Object.entries(dataAttribute)) {
        if (constant.VIDEO === assettype && param["data-source"] !== constant.YOUTUBE)
          node.getElementsByTagName("video")[0].setAttribute(key, value);
        else node.setAttribute(key, value);
      }

      let newWidgetObj;
      // Apply dynamic height for brand text insertion
      if (constant.TEXT === assettype && cssValue && brandText) {
        let phedoElem = document.createElement("div");
        phedoElem.id = "p1";
        phedoElem.style.cssText = "visibility:hidden";
        phedoElem.innerHTML = node.outerHTML;

        $(`#${metadata.activeBlockId}`).append(phedoElem.outerHTML);
        let elemHeight = document.querySelector("#p1 .primary-text-widget").offsetHeight;
        document.getElementById("p1").remove();

        newWidgetObj = {
          ...widgetObject,
          style: {
            ...widgetObject.style,
            height: `${elemHeight}px`,
          },
          innerHTML: node.outerHTML,
        };
      } else {
        newWidgetObj = { ...widgetObject, innerHTML: node.outerHTML };
      }

      updateWidgets([...widgets, newWidgetObj]);
      updateMetadata({ ...metadata, activeWidgetId: [widgetObject.id], activeWidgetType: [assettype] });
      setTimeout(() => {
        toggleWidgetEventHandlers({ action: "SHOW" });
        // video will play after insert
        if (
          constant.VIDEO === assettype &&
          [constant.STOCK, constant.BRAND, constant.UPLOAD_VIDEO].includes(param["data-source"])
        ) {
          document.getElementById(widgetObject.id).setAttribute("data-playing", true);
          document.querySelector(`#${widgetObject.id} video`).setAttribute("loop", "loop");
        }

        // trigger unsplash download
        if (assettype === constant.PICTURE && param["data-origin"] === "unsplash") {
          dispatch(triggerUnsplashDownload(param["data-download"]));
        }
      }, 50);
    }
  }, [widgetObject]);
};

export default useElementInnerHtml;
