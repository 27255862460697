import { useEffect, useState } from "react";

const useResizer = (resizableContainer, resizer, colIdx, columnWidthInfo) => {
  if (!resizableContainer || !resizer) return;

  const [resizedWidth, setresizedWidth] = useState();
  useEffect(() => {
    const resizableElem = resizableContainer.current;
    let width = 0;
    let x = 0;
    const resizerRight = resizer.current;

    const onMouseMoveRightResize = event => {
      const dx = event.clientX - x;
      width = width + dx;
      x = event.clientX;

      // Check is column width is available or not
      let defaultMinWidth = columnWidthInfo?.minWidth ? parseInt(columnWidthInfo.minWidth) : undefined;

      // if column min width available then check diff between width and default column
      if (defaultMinWidth) {
        if (width >= defaultMinWidth) {
          resizableElem.style.width = `${width}px`;
        }
      } else resizableElem.style.width = `${width}px`;

      if (colIdx >= 0 && width >= defaultMinWidth) {
        resizableElem.style.maxWidth = `${width}px`;
        // Select all elements with the specified class
        const elements = document.querySelectorAll(`.table-col-${colIdx}`);
        // Loop through each element and update its width
        elements.forEach(element => {
          element.style.width = `${width}px`;
          element.style.maxWidth = `${width}px`; // Update the width to the desired value
        });
      }
    };

    const onMouseUpRightResize = () => {
      resizerRight?.parentNode?.classList.remove("resizer-wrapper");
      setresizedWidth(resizableElem.style.width);
      document.removeEventListener("mousemove", onMouseMoveRightResize);
      document.removeEventListener("mouseup", onMouseUpRightResize);
    };

    const onMouseDownResize = event => {
      resizerRight?.parentNode?.classList.add("resizer-wrapper");
      x = event.clientX;
      const styles = window.getComputedStyle(resizableElem);
      width = parseInt(styles.width);
      document.addEventListener("mousemove", onMouseMoveRightResize);
      document.addEventListener("mouseup", onMouseUpRightResize);
    };

    resizerRight.addEventListener("mousedown", onMouseDownResize);

    return () => {
      resizerRight.removeEventListener("mousedown", onMouseDownResize);
      document.removeEventListener("mouseup", onMouseUpRightResize);
    };
  }, []);
  return resizedWidth;
};

export default useResizer;
