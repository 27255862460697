import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import global from "../../scss/dhp.scss";

let style = Object.assign({}, global);


export const Icon = ({ icon, additionalclass, ...props }) => {
  let addclass = "";

  if (additionalclass) {
    additionalclass.split(" ")?.forEach(cls => (addclass += style[cls] ? `${style[cls]} ` : ""));
    addclass = addclass.trim();
  }

  return <i className={cx(`icon-${icon}`, addclass)} {...props}></i>;
};

Icon.propTypes = {
  icon: PropTypes.string,
  additionalclass: PropTypes.string,
};