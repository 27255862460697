import React, { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import cx from "classnames";
import { Link } from "react-router-dom";

import global from "../../scss/dhp.scss";
import { userStatusList } from "../Company/CompanyConfig";
import LoadFlagImage from "./LoadFlagImage";
import { Tooltip, UncontrolledTooltip } from "reactstrap";
import MultiColorList from "./MultiColorList";
import { Icon } from "./icon";

let style = Object.assign({}, global);

const dateFormat = {
  "DD-MM-YYYY": "ddd DD MMM",
  "MM-DD-YYYY": "ddd MMM DD",
};

export const DataFormatter = ({
  type,
  slug,
  format,
  data,
  link,
  customMsg,
  showBlank,
  rowNo = 0,
  additionalclass,
  source,
}) => {
  const uniqueId = `col-${rowNo}-${type}`;
  const currCell = useRef(null);

  let addclass = "";

  let InlineStyle = {};

  if (source && source === "listview") {
    InlineStyle = { width: "inherit", padding: "0 .5rem 0 1.5rem" };
  }

  const { countries: countryList } = useSelector(state => state?.resources);
  let gmtValue = sessionStorage.getItem("_dh_gmtvalue");

  const userInfo = useSelector(state => state?.auth?.user);

  const [tooltipText, setTooltipText] = useState("");

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [copied, setCopied] = useState(false);

  const toggle = () => {
    if (currCell.current?.scrollWidth > currCell.current?.offsetWidth) {
      setTooltipText(currCell.current?.children[0]?.innerText);
      setTooltipOpen(!tooltipOpen);
    }
  };

  let result = "";
  let customVal = "";
  let filteredCountry = null;

  // Select date and timeform mat from user information
  const defaultFormats = {
    "date-time": `${dateFormat[userInfo.date_format] || "MMM ddd DD"}, yyyy ${
      userInfo?.time_format?.substring(0, 2) == 24 ? "HH:mm" : "hh:mm A"
    }`,
  };

  const applicableFormat = format == "default" ? defaultFormats[type] : format;

  if (additionalclass) {
    additionalclass.split(" ")?.forEach(cls => (addclass += style[cls] ? `${style[cls]} ` : ""));
    addclass = addclass.trim();
  }

  switch (type) {
    case "text":
      let textValue;
      // Check if the slug has nested key or direct key
      if (slug.includes(".")) {
        const keys = slug.split(".");
        textValue = keys.reduce((acc, key) => acc?.[key], data);
      } else {
        textValue = data?.[slug];
      }
      result = (
        <>
          {!link && <span className={style["text-capitalize"]}>{textValue ?? ""}</span>}
          {link && slug === "document_name" && <Link to={`${link}/${data.id}/preview`}>{textValue ?? ""}</Link>}
        </>
      );
      break;

    case "copy-text":
      let copytextValue;
      // Check if the slug has nested key or direct key
      if (slug.includes(".")) {
        const keys = slug.split(".");
        copytextValue = keys.reduce((acc, key) => acc?.[key], data);
      } else {
        copytextValue = data?.[slug];
      }
      const handleCopy = () => {
        if (copytextValue) {
          navigator.clipboard.writeText(copytextValue);
          setCopied(true);
          setTimeout(() => setCopied(false), 500);
        }
      };
      result = (
        <>
          <span className={style["text-capitalize"]}>{copytextValue ?? ""}
            <span onClick={handleCopy} className={cx({ [style["text-success"]]: copied })}>
              <Icon icon="ui-clone" additionalclass="ml-2 font-15 cursor-pointer" />
            </span>
          </span>
        </>
      );
      break;

    case "email":
      result = (
        <span>
          {slug.includes(".") ? slug.split(".").reduce((acc, key) => acc?.[key], data) ?? "" : data?.[slug] ?? ""}
        </span>
      );
      break;

    case "currency":
      let number = Math.round(data?.[slug] || 0);
      result = (
        <span>
          {data?.currency ?? ""} {number ?? ""}
        </span>
      );
      break;

    case "link":
      result = (
        <>
          {data?.[slug] && (
            <Link className={style["text-primary"]} to={{ pathname: data?.[slug] }} target="_blank">
              VIEW
            </Link>
          )}
        </>
      );
      break;

    case "user":
      slug?.map(e => {
        const keys = e.split(".");
        let value = data;
        keys.forEach(key => {
          value = value?.[key];
        });
        if (value) {
          result += value + " ";
        }
      });
      result = <span className={style["text-capitalize"]}>{result.trim()}</span>;
      break;

    case "user_info":
      slug.map(e => {
        if (data?.[e]) result += data?.[e] + " ";
      });

      result = (
        <>
          {!link && <span>{result.trim()}</span>}
          {link && <Link to={`${link}/${data.id}`}>{result.trim()}</Link>}
          {data?.["superuser"] && <span className={style["user-role-text"]}>Super Admin</span>}
        </>
      );

      break;

    case "date-time":
      let resultDate = "";
      let dateValue;

      // Check if the slug has nested key or direct key
      if (slug.includes(".")) {
        const keys = slug.split(".");
        dateValue = keys.reduce((acc, key) => acc?.[key], data);
      } else {
        dateValue = data?.[slug];
      }

      if (dateValue) {
        if (!gmtValue) gmtValue = setGMTAtSession(userInfo?.time_zone);
        resultDate = moment(dateValue * 1000)
          .utcOffset(gmtValue)
          .format(applicableFormat);
      }

      result = (
        <>
          {dateValue && <span>{resultDate}</span>}
          {!dateValue && ((showBlank && <span></span>) || (!showBlank && <span>Never Logged In</span>))}
        </>
      );
      break;

    case "status":
      result = (
        <span className={cx(style["user-status"], style["text-capitalize"], style[userStatusList[data?.[slug]]])}>
          {" "}
          {userStatusList[data?.[slug]]}
        </span>
      );
      break;

    case "tags":
      result = <span>{data?.[slug]?.join(", ")}</span>;
      break;

    case "dataobj":
      result = (
        <>
          {(data?.action === "Created" || data?.action === "Updated" || data?.action === "Deleted") &&
            data?.record_type === "documents" &&
            data?.[slug]?.link && <Link to={`/${data?.[slug]?.link}/?ref=info`}>{data?.[slug]?.name}</Link>}
          {!data?.[slug]?.link && <span>{data?.[slug]?.name}</span>}
        </>
      );
      break;

    case "boolean":
      customVal = data?.[slug] || false;
      if (customMsg) {
        customVal = customVal === true || customVal === "True" ? customMsg[0] : customMsg[1];
      }
      result = <span className={style["text-capitalize"]}>{customVal}</span>;
      break;

    case "multiColor":
      customVal = data?.[slug];

      result = <MultiColorList colors={customVal ?? []} />;
      break;

    case "country":
      // filter country from data
      if (data?.[slug]) {
        filteredCountry = countryList?.find(country => country.iso_code === data[slug]);
        if (filteredCountry) {
          customVal = filteredCountry.name;
          result = (
            <span>
              <LoadFlagImage isoCode={filteredCountry.iso_code} /> {filteredCountry.name}
            </span>
          );
        }
      } else result = <span className={style["text-capitalize"]}></span>;
      break;

    case "custom-font-style":
      let fontFamily = data?.[slug[0]] || false;
      let fontStyle = data?.[slug[1]] || false;
      let fontWeight = data?.[slug[2]] || false;
      result = <span style={{ fontFamily: `"${fontFamily}"`, fontStyle, fontWeight }}>{fontFamily}</span>;
      break;

    default:
      break;
  }
  let cellData = (
    <div className={cx("text-truncate", addclass)} ref={currCell} style={InlineStyle} id={uniqueId}>
      {result}
      <Tooltip isOpen={tooltipOpen} target={uniqueId} toggle={toggle}>
        {tooltipText}
      </Tooltip>
    </div>
  );
  return cellData;
};

DataFormatter.propTypes = {
  type: PropTypes.string.isRequired,
  slug: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  format: PropTypes.string,
  data: PropTypes.object.isRequired,
  customMsg: PropTypes.array,
  showBlank: PropTypes.bool,
  rowNo: PropTypes.string,
  additionalclass: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  source: PropTypes.string,
};
