import React, { useState, useEffect } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import global from "../../scss/dhp.scss";
import { Nav, NavItem, NavLink } from "reactstrap";

let style = Object.assign({}, global);

const TabSchemeSelector = ({ schemeTypeArray, setSchemeType, schemeType, activeTabIndex }) => {
  const [activeTab, setActiveTab] = useState(activeTabIndex ?? 0);

  const updateTabTypeScheme = (index, schemeType) => {
    if (activeTab !== index) setActiveTab(index);
    setSchemeType(schemeType);
  };

  useEffect(() => {
    if (activeTabIndex !== undefined) setActiveTab(activeTabIndex);
  }, [activeTabIndex]);

  return (
    <Nav tabs className={style["line-tabs"]}>
      {schemeTypeArray?.map((type, index) => (
        <NavItem key={type}>
          <NavLink
            className={activeTab === index ? "active" : ""}
            onClick={() => updateTabTypeScheme(index, type)}
          >
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

// PropTypes validation
TabSchemeSelector.propTypes = {
  schemeTypeArray: PropTypes.array.isRequired,
  setSchemeType: PropTypes.func.isRequired,
  schemeType: PropTypes.string.isRequired,
  activeTabIndex: PropTypes.number,
};

export default TabSchemeSelector;
