import cx from "classnames";
import React, { useState, useEffect, useContext } from "react";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Form, UncontrolledDropdown } from "reactstrap";
import PropTypes from "prop-types";
import {
  calculateNewZoomValue,
  convertToSlug,
  generateSmartResizedWidgets,
  getKeyBySlug,
  validateNumberInput
} from "../../../_helpers/utils";
import { Icon } from "../../ui/icon";
import { Input } from "../../ui/input";
import {
  LENGTH_UNITS_CONVERSION_FACTORS,
  ORIENTATION_TYPE,
  PAGETYPE_CUSTOM_LABEL,
  PAGETYPE_CUSTOM_VALUE,
  PAGETYPE_STANDARD_VALUE,
  RESIZER_TYPE,
  SPINNER_FIELDS_META,
  TEMPLATE_GROUP_VALUE_ADVERTISEMENT,
  TEMPLATE_GROUP_VALUE_COMMON,
  TEMPLATE_GROUP_VALUE_SOCIAL_GRAPHIC,
  TEMPLATE_GROUP_VALUE_VIDEO,
  TEMPLATE_GROUP_VALUE_WEB_BANNER,
} from "../../../constants/pageSizeModal";
import { EditorContext } from "../../../containers/editor/EditorLayout";

import global from "../../../scss/dhp.scss";
import { useDispatch } from "react-redux";
import { updateDocument } from "../../../store/actions/documentActions";
import { useLocalStorage } from "../../../hooks/useStorage";
import useGoToPage from "../../../hooks/useGoToPage";
import useControllerToSection from "../../../hooks/useControllerToSection";

let style = Object.assign({}, global);

const PageSizeModal = ({ selectedPageType, currentGroupName, availableTemplatePageSizes, dimension, ...props }) => {
  const {
    widgets,
    updateWidgets,
    pageNodes,
    blockNodes,
    dimension: pageDimension,
    updateDimension: updatePageDimension,
    metadata,
    updateMetadata,
    updateDocumentType,
    setTextFontSize,
    isTimeLineViewOpen,
    setIsTimeLineViewOpen,
  } = useContext(EditorContext);
  const dispatch = useDispatch();
  const { controllerToSection } = useControllerToSection();
  const { goToPage } = useGoToPage();
  const [templateSizes] = useLocalStorage("templateSizes");

  const [resizeType, setResizeType] = useState(
    pageDimension.size === PAGETYPE_CUSTOM_VALUE ? PAGETYPE_CUSTOM_VALUE : PAGETYPE_STANDARD_VALUE
  );
  const [uom, setUom] = useState();
  const [pageDiaInCurrentUOM, setPageDiaInCurrentUOM] = useState();
  const [pageDia, setpageDia] = useState({
    name:
      pageDimension.size === PAGETYPE_CUSTOM_VALUE
        ? "Select Page Size"
        : selectedPageType.find(type => type.value === pageDimension.size).label,
    value:
      pageDimension.size === PAGETYPE_CUSTOM_VALUE
        ? ""
        : selectedPageType.find(type => type.value === pageDimension.size).value,
  });

  const scaleFactor = dimension.zoom / 100;

  const [disableButton, setDisableButton] = useState(false);
  const [pageHeight, setPageHeight] = useState(dimension?.displayUnit !== "px" ? parseFloat(dimension.height) : parseInt(dimension.height));
  const [pageWidth, setPageWidth] = useState(dimension?.displayUnit !== "px" ? parseFloat(dimension.width) : parseInt(dimension.width));
  const [curOrientation, setCurOrientation] = useState(pageDimension?.orientation);

  const updateDimension = data => {
    let value = data.value;
    let curPageIndex = availableTemplatePageSizes?.metadata?.pageSizeIndexes[value];
    setpageDia({ name: data.label, value });
    if (curOrientation === "portrait") {
      let curPageHeight = parseInt(curPageIndex?.height);
      let curPageWidth = parseInt(curPageIndex?.width);
      setPageHeight(curPageHeight);
      setPageWidth(curPageWidth);
      setPageDiaInCurrentUOM({
        height: uom.factor === 1 ? curPageHeight : parseFloat((curPageHeight * uom.factor).toFixed(2)),
        width: uom.factor === 1 ? curPageWidth : parseFloat((curPageWidth * uom.factor).toFixed(2)),
      });
    } else {
      let curPageHeight = parseInt(curPageIndex?.height);
      let curPageWidth = parseInt(curPageIndex?.width);
      setPageHeight(curPageWidth);
      setPageWidth(curPageHeight);
      setPageDiaInCurrentUOM({
        height: uom.factor === 1 ? curPageWidth : parseFloat((curPageWidth * uom.factor).toFixed(2)),
        width: uom.factor === 1 ? curPageHeight : parseFloat((curPageHeight * uom.factor).toFixed(2)),
      });
    }
  };

  const updateOrientation = type => {
    setCurOrientation(type);
    let curPageIndex = availableTemplatePageSizes?.metadata?.pageSizeIndexes[pageDia.value];
    let curPageHeight = parseInt(curPageIndex.height ?? pageDimension.height);
    let curPageWidth = parseInt(curPageIndex.width ?? pageDimension.width);
    if (type === "portrait") {
      setPageHeight(curPageHeight);
      setPageWidth(curPageWidth);
      setPageDiaInCurrentUOM({
        height: uom.factor === 1 ? curPageHeight : parseFloat((curPageHeight * uom.factor).toFixed(2)),
        width: uom.factor === 1 ? curPageWidth : parseFloat((curPageWidth * uom.factor).toFixed(2)),
      });
    } else {
      setPageWidth(curPageHeight);
      setPageHeight(curPageWidth);
      setPageDiaInCurrentUOM({
        height: uom.factor === 1 ? curPageWidth : parseFloat((curPageWidth * uom.factor).toFixed(2)),
        width: uom.factor === 1 ? curPageHeight : parseFloat((curPageHeight * uom.factor).toFixed(2)),
      });
    }
  };

  const checkRange = e => {
    let curValue =
      uom.name === "px"
        ? parseInt(e.currentTarget.value ? e.currentTarget.value : 0)
        : parseFloat(parseFloat(e.currentTarget.value ? e.currentTarget.value : 0).toFixed(2));
    let curMax = uom.name === "px" ? parseInt(e.currentTarget.max) : parseFloat(e.currentTarget.max);
    let curMin = uom.name === "px" ? parseInt(e.currentTarget.min) : parseFloat(e.currentTarget.min);
    if (e.currentTarget.id === "Height") {
      let newHeight = curValue >= curMax ? curMax : curValue <= curMin ? curMin : curValue;
      setPageDiaInCurrentUOM({ ...pageDiaInCurrentUOM, height: newHeight });
      let newHeightInPx = newHeight / uom.factor;
      setPageHeight(newHeightInPx);
    } else {
      let newWidth = curValue >= curMax ? curMax : curValue <= curMin ? curMin : curValue;
      setPageDiaInCurrentUOM({ ...pageDiaInCurrentUOM, width: newWidth });
      let newWidthInPx = newWidth / uom.factor;
      setPageWidth(newWidthInPx);
    }
  };

  const onIncrease = target => {
    if (target === "Height") {
      let newHeight =
        pageDiaInCurrentUOM.height + 1 < SPINNER_FIELDS_META[uom.name].maxHeight
          ? uom.factor === 1
            ? pageDiaInCurrentUOM.height + 1
            : parseFloat((pageDiaInCurrentUOM.height + 1).toFixed(2))
          : SPINNER_FIELDS_META[uom.name].maxHeight;
      setPageDiaInCurrentUOM({ ...pageDiaInCurrentUOM, height: newHeight });
      let newHeightInPx = newHeight / uom.factor;
      setPageHeight(newHeightInPx);
    } else {
      let newWidth =
        pageDiaInCurrentUOM.width + 1 < SPINNER_FIELDS_META[uom.name].maxWidth
          ? uom.factor === 1
            ? pageDiaInCurrentUOM.width + 1
            : parseFloat((pageDiaInCurrentUOM.width + 1).toFixed(2))
          : SPINNER_FIELDS_META[uom.name].maxWidth;
      setPageDiaInCurrentUOM({ ...pageDiaInCurrentUOM, width: newWidth });
      let newWidthInPx = newWidth / uom.factor;
      setPageWidth(newWidthInPx);
    }
  };

  const onDecrease = target => {
    if (target === "Height") {
      let newHeight =
        pageDiaInCurrentUOM.height - 1 > SPINNER_FIELDS_META[uom.name].minHeight
          ? uom.factor === 1
            ? pageDiaInCurrentUOM.height - 1
            : parseFloat((pageDiaInCurrentUOM.height - 1).toFixed(2))
          : SPINNER_FIELDS_META[uom.name].minHeight;
      setPageDiaInCurrentUOM({ ...pageDiaInCurrentUOM, height: newHeight });
      let newHeightInPx = newHeight / uom.factor;
      setPageHeight(newHeightInPx);
    } else {
      let newWidth =
        pageDiaInCurrentUOM.width - 1 > SPINNER_FIELDS_META[uom.name].minWidth
          ? uom.factor === 1
            ? pageDiaInCurrentUOM.width - 1
            : parseFloat((pageDiaInCurrentUOM.width - 1).toFixed(2))
          : SPINNER_FIELDS_META[uom.name].minWidth;
      setPageDiaInCurrentUOM({ ...pageDiaInCurrentUOM, width: newWidth });
      let newWidthInPx = newWidth / uom.factor;
      setPageWidth(newWidthInPx);
    }
  };

  const onChangeHandler = (e, target) => {
    if (target === "Width")
      setPageDiaInCurrentUOM({
        ...pageDiaInCurrentUOM,
        width: uom.name === "px" ? parseInt(e.currentTarget.value ?? 0) : parseFloat(e.currentTarget.value ?? 0),
      });
    else
      setPageDiaInCurrentUOM({
        ...pageDiaInCurrentUOM,
        height: uom.name === "px" ? parseInt(e.currentTarget.value ?? 0) : parseFloat(e.currentTarget.value ?? 0),
      });
  };

  const validatePageSizeRange = (fieldType, value, uom) => {
    let curValue = uom.name === "px" ? parseInt(value) : parseFloat(value);

    if (uom.name !== "px") return curValue;

    if (fieldType === "Height") {
      return curValue >= SPINNER_FIELDS_META["px"].maxHeight
        ? SPINNER_FIELDS_META["px"].maxHeight
        : curValue <= SPINNER_FIELDS_META["px"].minHeight
          ? SPINNER_FIELDS_META["px"].minHeight
          : curValue;
    } else {
      return curValue >= SPINNER_FIELDS_META["px"].maxWidth
        ? SPINNER_FIELDS_META["px"].maxWidth
        : curValue <= SPINNER_FIELDS_META["px"].minWidth
          ? SPINNER_FIELDS_META["px"].minWidth
          : curValue;
    }
  };

  const applySmartResize = async () => {
    let { height: oldHeight, width: oldWidth, unit, displayUnit, size } = pageDimension;
    // No Change
    let label = resizeType === PAGETYPE_CUSTOM_VALUE ? PAGETYPE_CUSTOM_LABEL : pageDia?.name;
    let value = resizeType === PAGETYPE_CUSTOM_VALUE ? PAGETYPE_CUSTOM_VALUE : pageDia?.value;

    let curPageHeight = validatePageSizeRange("Height", pageHeight, uom);
    let curPageWidth = validatePageSizeRange("Width", pageWidth, uom)

    if (
      curPageWidth === oldWidth &&
      curPageHeight === oldHeight &&
      size === value &&
      (resizeType === PAGETYPE_STANDARD_VALUE ||
        (resizeType === PAGETYPE_CUSTOM_VALUE && uom?.name === unit && (!displayUnit || displayUnit === uom?.name)))
    ) {
      props.setShowModal(!props.showModal);
      return;
    }
    setDisableButton(true);
    let allPageNodes = Object.assign([], pageNodes);
    let allBlockNodes = Object.assign([], blockNodes);
    allPageNodes = allPageNodes.map(pn => {
      return {
        ...pn,
        style: { ...pn.style, height: `${curPageHeight}px`, width: `${curPageWidth}px` },
      };
    });

    allBlockNodes = allBlockNodes.map(bn => {
      return {
        ...bn,
        style: { ...bn.style, height: `${curPageHeight}px`, width: `${curPageWidth}px` },
      };
    });

    let allUpdatedWidgets = Object.assign([], widgets);
    // Only Width Increase
    if (curPageWidth > oldWidth && curPageHeight === oldHeight) {
      let shiftX = (curPageWidth - oldWidth) / 2;
      allUpdatedWidgets = generateSmartResizedWidgets(
        1,
        shiftX,
        0,
        curPageHeight,
        curPageWidth,
        widgets,
        dimension.zoom,
        false,
        setTextFontSize
      );
    }

    // Only Width Decrease
    if (curPageWidth < oldWidth && curPageHeight === oldHeight) {
      let decreseFactor = curPageWidth / oldWidth;
      let shiftY = (oldHeight - curPageHeight * decreseFactor) / 2;
      allUpdatedWidgets = generateSmartResizedWidgets(
        decreseFactor,
        0,
        shiftY,
        curPageHeight,
        curPageWidth,
        widgets,
        dimension.zoom,
        false,
        setTextFontSize
      );
    }

    // Only Height Increase
    if (curPageHeight > oldHeight && curPageWidth === oldWidth) {
      let shiftY = (curPageHeight - oldHeight) / 2;
      allUpdatedWidgets = generateSmartResizedWidgets(
        1,
        0,
        shiftY,
        curPageHeight,
        curPageWidth,
        widgets,
        dimension.zoom,
        false,
        setTextFontSize
      );
    }

    // Only Height Decrease
    if (curPageHeight < oldHeight && curPageWidth === oldWidth) {
      let decreseFactor = curPageHeight / oldHeight;
      let shiftX = (oldWidth - curPageWidth * decreseFactor) / 2;
      allUpdatedWidgets = generateSmartResizedWidgets(
        decreseFactor,
        shiftX,
        0,
        curPageHeight,
        curPageWidth,
        widgets,
        dimension.zoom,
        false,
        setTextFontSize
      );
    }

    // Both Height & Width Increase or Decrease
    if ((curPageHeight > oldHeight && curPageWidth > oldWidth) || (curPageHeight < oldHeight && curPageWidth < oldWidth)) {
      let increseFactor = Math.min(curPageHeight / oldHeight, curPageWidth / oldWidth);
      let shiftX = (curPageWidth - oldWidth * increseFactor) / 2;
      let shiftY = (curPageHeight - oldHeight * increseFactor) / 2;
      allUpdatedWidgets = generateSmartResizedWidgets(
        increseFactor,
        shiftX,
        shiftY,
        curPageHeight,
        curPageWidth,
        widgets,
        dimension.zoom,
        false,
        setTextFontSize
      );
    }

    // if Height Decrease  & Width Increase
    if (curPageHeight < oldHeight && curPageWidth > oldWidth) {
      let decreseFactor = Math.min(curPageHeight / oldHeight, curPageWidth / oldWidth);
      let shiftY = (curPageHeight - oldHeight * decreseFactor) / 2;
      let shiftX = (curPageWidth - oldWidth * decreseFactor) / 2;
      allUpdatedWidgets = generateSmartResizedWidgets(
        decreseFactor,
        shiftX,
        shiftY,
        curPageHeight,
        curPageWidth,
        widgets,
        dimension.zoom,
        false,
        setTextFontSize
      );
    }
    // if Height Increase & Width Decrease
    if (curPageHeight > oldHeight && curPageWidth < oldWidth) {
      let decreseFactor = Math.min(curPageHeight / oldHeight, curPageWidth / oldWidth);
      let shiftY = (curPageHeight - oldHeight * decreseFactor) / 2;
      let shiftX = (curPageWidth - oldWidth * decreseFactor) / 2;
      allUpdatedWidgets = generateSmartResizedWidgets(
        decreseFactor,
        shiftX,
        shiftY,
        curPageHeight,
        curPageWidth,
        widgets,
        dimension.zoom,
        false,
        setTextFontSize
      );
    }

    let newZoomVal = calculateNewZoomValue(curPageWidth, curPageHeight);
    // apply adjustment fot firefox and safari start
    let userAgent = navigator.userAgent;
    let isFirefox = userAgent.match(/firefox|fxios/i);
    let isSafari = !userAgent.match(/chrome/i) && userAgent.match(/safari/i);
    let curEditorWrap = document.getElementById("generic-editor-wrap");
    if (curEditorWrap && (isFirefox || isSafari)) {
      curEditorWrap.style.width = `${curPageWidth * (newZoomVal / 100)}px`;
      // curEditorWrap.style.overflow = `hidden`;
    }
    // apply adjustment fot firefox and safari end

    // Updating document for social graphic, web banner, advertisement and video for granular structure
    if (
      [
        TEMPLATE_GROUP_VALUE_SOCIAL_GRAPHIC,
        TEMPLATE_GROUP_VALUE_WEB_BANNER,
        TEMPLATE_GROUP_VALUE_ADVERTISEMENT,
        TEMPLATE_GROUP_VALUE_VIDEO,
      ].includes(currentGroupName)
    ) {
      updateDocumentType(label.toLowerCase());
      let templateTypeId = getKeyBySlug(templateSizes, convertToSlug(label));
      if (templateTypeId)
        await dispatch(updateDocument(props?.documentId, { template_type: label, template_type_id: templateTypeId }));
    }
    updatePageDimension({
      ...pageDimension,
      height: curPageHeight,
      width: curPageWidth,
      label,
      size: value,
      orientation: curOrientation,
      displayUnit: resizeType === PAGETYPE_CUSTOM_VALUE ? uom.name : pageDimension.unit,
      zoom: calculateNewZoomValue(curPageWidth, curPageHeight),
    });

    updateWidgets(
      allUpdatedWidgets,
      allPageNodes,
      allBlockNodes,
      false,
      false,
      false,
      {
        ...pageDimension,
        height: curPageHeight,
        width: curPageWidth,
        label,
        size: value,
        orientation: curOrientation,
        displayUnit: resizeType === PAGETYPE_CUSTOM_VALUE ? uom.name : pageDimension.unit,
        zoom: calculateNewZoomValue(curPageWidth, curPageHeight),
      },
      "pageResized",
      label.toLowerCase()
    );
    updateMetadata({
      ...metadata,
      activeOutSideCanvasArea: true,
      activeWidgetId: false,
      activeWidgetType: false,
    });

    setDisableButton(false);
    props.setShowModal(!props.showModal);

    // keep controller to active page
    setTimeout(() => {
      goToPage();
      controllerToSection();
    }, 300)
  };

  const applyResize = () => {
    if (!isTimeLineViewOpen) applySmartResize();
    else {
      setIsTimeLineViewOpen(false);
      setTimeout(() => {
        applySmartResize();
      }, 100);
    }
  };

  useEffect(() => {
    setUom(LENGTH_UNITS_CONVERSION_FACTORS?.find(mu => mu.name === (pageDimension?.displayUnit ?? pageDimension.unit)));
  }, [pageDimension]);

  useEffect(() => {
    if (uom?.factor) {
      let height = uom.name === "px" ? parseInt(pageHeight) : parseFloat((pageHeight * uom.factor).toFixed(2));
      height =
        height >= SPINNER_FIELDS_META[uom.name].maxHeight
          ? SPINNER_FIELDS_META[uom.name].maxHeight
          : height <= SPINNER_FIELDS_META[uom.name].minHeight
            ? SPINNER_FIELDS_META[uom.name].minHeight
            : height;

      let width = uom.name === "px" ? parseInt(pageWidth) : parseFloat((pageWidth * uom.factor).toFixed(2));
      width =
        width >= SPINNER_FIELDS_META[uom.name].maxWidth
          ? SPINNER_FIELDS_META[uom.name].maxWidth
          : width <= SPINNER_FIELDS_META[uom.name].minWidth
            ? SPINNER_FIELDS_META[uom.name].minWidth
            : width;

      setPageDiaInCurrentUOM({ height, width });
    }
  }, [uom]);

  return (
    <>
      <h4 className={cx(style["fw-7"], style["mb-4"])}>Select Page Size</h4>
      <span className={cx(style["cross-modal"], style["rounded"])} onClick={() => props.setShowModal(!props.showModal)}>
        <Icon icon="ui-close" />
      </span>
      <Form>
        <ul className={cx(style["setup-area"], style["mb-4"])}>
          <li className={style["mb-2"]}>
            {RESIZER_TYPE?.map((field, key) => (
              <Input
                returnType="noGroup"
                type={field.type}
                value={field.value}
                id={field.value}
                name={field.name}
                text={field.text}
                defaultChecked={field.value === resizeType}
                className={style["custom-control-input"]}
                required={false}
                onChange={() => setResizeType(field.value)}
                key={key}
              />
            ))}
          </li>

          {resizeType === PAGETYPE_STANDARD_VALUE && (
            <li>
              <Input
                type="dropdown"
                name="standardSizes"
                label="Standard Page Size"
                defaultValue={pageDia}
                getOptionValue={option => option.value}
                getOptionLabel={option => <span>{option.label}</span>}
                options={selectedPageType}
                updateState={option => updateDimension(option)}
                noOptionsMessage={() => "No results found"}
              />
            </li>
          )}

          {resizeType === PAGETYPE_STANDARD_VALUE &&
            pageDia?.value &&
            currentGroupName === TEMPLATE_GROUP_VALUE_COMMON &&
            ORIENTATION_TYPE?.map((field, key) => (
              <li className={style["port-land"]} key={key}>
                <Input
                  returnType="noGroup"
                  type={field.type}
                  value={field.value}
                  id={field.value}
                  name={field.name}
                  text={field.text}
                  defaultChecked={field.value === curOrientation}
                  icon={"ui-documents"}
                  className={style["custom-control-input"]}
                  required={false}
                  onChange={() => updateOrientation(field.value)}
                />
              </li>
            ))}

          {resizeType === PAGETYPE_CUSTOM_VALUE && (
            <li className={cx(style["custom"], style["pb-3"])}>
              <h6 className={cx(style["fw-6"], style["mb-3"])}>Custom Dimension</h6>
              <div className="align-items-center d-flex" style={{ gap: ".75rem" }}>
                {pageDiaInCurrentUOM &&
                  SPINNER_FIELDS_META?.label.map((field, key) => (
                    <Input
                      key={field}
                      returnType="formGroup"
                      id={field}
                      type="spinner"
                      className="custom-width"
                      spinnerClass="input-spinner"
                      value={field === "Width" ? pageDiaInCurrentUOM?.width : pageDiaInCurrentUOM?.height}
                      label={field}
                      max={
                        field === "Width"
                          ? SPINNER_FIELDS_META[uom.name].maxWidth
                          : SPINNER_FIELDS_META[uom.name].maxHeight
                      }
                      min={
                        field === "Width"
                          ? SPINNER_FIELDS_META[uom.name].minWidth
                          : SPINNER_FIELDS_META[uom.name].minHeight
                      }
                      required={false}
                      onBlur={e => checkRange(e)}
                      onKeyDown={e => validateNumberInput(e)}
                      onChange={e => onChangeHandler(e, field)}
                      onDecrease={target => onDecrease(target)}
                      onIncrease={target => onIncrease(target)}
                      tabIndex={key + 1}
                      step="any"
                    />
                  ))}
                <UncontrolledDropdown className={style["border-style"]}>
                  <DropdownToggle>
                    {uom?.name}
                    <Icon icon="ui-arrow-down" />
                  </DropdownToggle>

                  <DropdownMenu className={cx(style["shadow-sm"], style["border-0"], style["rounded"])}>
                    {LENGTH_UNITS_CONVERSION_FACTORS?.map((mu, i) => (
                      <DropdownItem
                        tag="a"
                        key={i}
                        onClick={() => setUom(mu)}
                        className={cx({
                          [style["active"]]: mu.name === uom?.name,
                        })}>
                        {mu.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </li>
          )}
        </ul>
        <Button
          type="button"
          color="primary"
          onClick={applyResize}
          disabled={disableButton || (resizeType === PAGETYPE_STANDARD_VALUE && !pageDia?.value)}>
          Apply
        </Button>
      </Form>
    </>
  );
};

export default PageSizeModal;

PageSizeModal.propTypes = {
  selectedPageType: PropTypes.array.isRequired,
  currentGroupName: PropTypes.string.isRequired,
  availableTemplatePageSizes: PropTypes.object.isRequired,
  dimension: PropTypes.object.isRequired,
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  documentId: PropTypes.string.isRequired,
};
