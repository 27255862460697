import { useContext } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";
import { getRandomString } from "../_helpers/utils";
import useSetDimension from "./useSetDimension";

const useAddBlock = () => {
  let { metadata, blockNodes } = useContext(EditorContext);

  const { setDimensionAddBlockEvent } = useSetDimension();

  const addBlock = (pageObj, blockIdx) => {
    let targetBlockId = "dhp-block-" + getRandomString(8);
    let activePageId = pageObj ? pageObj.pageId : metadata.activePageId;
    let currentAvailableBlocks = blockNodes.length;
    let targetBlockIdx = blockIdx
      ? blockIdx
      : currentAvailableBlocks === 0
      ? metadata.activeBlockIdx
      : metadata.activeBlockIdx + 1;

    let blockObj = {
      pageId: activePageId,
      blockId: targetBlockId,
      blockIdx: targetBlockIdx,
      style: {
        position: "relative",
        overflow: "hidden",
      },
    };

    let backgroundColorObj = {
      pageId: activePageId,
      blockId: targetBlockId,
      style: {
        backgroundColor: "#ffffff",
        inset: "0px",
        position: "absolute",
        zIndex: 10,
      },
    };

    setDimensionAddBlockEvent(pageObj, blockObj, backgroundColorObj);
  };

  return { addBlock };
};

export default useAddBlock;
