import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useSocket } from "./useSocket";
import useSelectable from "./useSelectable";
import { useParams } from "react-router-dom";
import {
  ACTIVE_WIDGET_CHANGE,
  COLLABORATION_RECEIVE,
  COLLABORATION_SEND,
  COLLABORATION_STATUS,
  DOCUMENT_VERSION_RESTORE_AFTER,
  EXIT_COLLABORATION_ROOM,
  JOIN_COLLABORATION_ROOM,
} from "../constants/socket";
import { GROUP_WIDGET, TYPE_INFOGRAPHIC } from "../constants/editor";
import { calculateNewZoomValue } from "../_helpers/utils";
import useSetDimension from "./useSetDimension";
import { fetchDocumentDetails } from "../store/actions/documentActions";
import useDocumentReader from "./useDocumentReader";

const useEditorCollaboration = () => {
  let {
    metadata,
    updateMetadata,
    pageNodes,
    updatePageNodes,
    blockNodes,
    updateBlockNodes,
    widgets,
    updateWidgets,
    backgroundColors,
    updateBackgroundColors,
    backgroundImages,
    updateBackgroundImages,
    socketState,
    updateSocketState,
    dimension,
    updateDimension,
    documentType,
    documentName,
    updateDocumentName,
    setChangeBySocket,
    isDocumentReady,
    setIsDocumentReady,
    audios, setAudios
  } = useContext(EditorContext);

  const dispatch = useDispatch();

  const [startCollaboration, setStartCollaboration] = useState(false);
  const [fitToScreen, setFitToScreen] = useState(true);

  const authState = useSelector(state => state?.auth);

  const userId = authState?.uid;
  const companyId = authState?.user?.company?.id;

  const webSocket = useSocket();
  const { start: initSelectable, hideAllWidgetSelection } = useSelectable();
  const { setTotalHeight } = useSetDimension();
  const { start: initDocumentReader } = useDocumentReader(fitToScreen, setFitToScreen);

  const { id } = useParams();

  const widgetsRef = useRef();
  const backgroundColorsRef = useRef();
  const backgroundImagesRef = useRef();
  const blockNodesRef = useRef();
  const pageNodesRef = useRef();
  const metadataRef = useRef();
  const dimensionRef = useRef();
  const documentNameRef = useRef();
  const audiosRef = useRef();
  const userHash = useRef();
  const socketStateRef = useRef();
  const activeWidgetSubscriptionRef = useRef();
  const canvasZoom = useRef();
  const isOffline = useRef();

  const selectorList = [
    { type: "page", key: "pageId", uKeys: ["pageId", "pageIdx", "style", "pageDuration", "pageTransition"] },
    { type: "block", key: "blockId", uKeys: ["blockId", "blockIdx", "style"] },
    { type: "widget", key: "id", uKeys: ["id", "pageId", "blockId", "innerHTML", "style", "data", "duration"] },
    { type: "bgColor", key: "blockId", uKeys: ["pageId", "blockId", "style"] },
    { type: "bgImage", key: "blockId", uKeys: ["pageId", "blockId", "style"] },
    { type: "audio", key: "id", uKeys: ["id", "startTime","endTime", "data"] }
  ];

  const sendData = (actionType, data, documentId) => {
    if (webSocket?.readyState !== 1) return;
    const subscribe = {
      type: COLLABORATION_SEND,
      data,
      userId,
      companyId,
      documentId,
      actionType,
    };
    webSocket.send(JSON.stringify(subscribe));
  };

  const exitRoom = () => {
    if (webSocket?.readyState === 1) {
      const subscribe = {
        type: EXIT_COLLABORATION_ROOM,
        userId,
        companyId,
        documentId: id,
        userHash: userHash.current,
      };
      webSocket.send(JSON.stringify(subscribe));
    }
  }

  const handleUnload = e => {
    e.preventDefault();
    exitRoom();
  }

  const mapFromArray = (array, prop) => {
    let map = {};
    for (let i = 0; i < array.length; i++) {
      map[array[i][prop]] = array[i];
    }
    return map;
  }

  const isEqualData = (a, b, checkingKeys) => {
    if (!checkingKeys) return;
    let changed = [];
    checkingKeys.forEach(criteria => {
      if (a[criteria] && b[criteria] && !_.isEqual(a[criteria], b[criteria])) changed.push(true);
    })
    return changed.length > 0 ? false : true;
  }

  const getDelta = (o, n, type) => {
    let delta = {
      added: [],
      deleted: [],
      updated: []
    };
    let selector = selectorList.find(s => s.type === type);
    let selectorKey = selector.key ?? "id";
    let mapO = mapFromArray(o, selectorKey);
    let mapN = mapFromArray(n, selectorKey);

    for (let selectorKey in mapO) {
      if (!mapN.hasOwnProperty(selectorKey))
        delta.deleted.push(mapO[selectorKey]);
      else if (!isEqualData(mapN[selectorKey], mapO[selectorKey], selector.uKeys))
        delta.updated.push(mapN[selectorKey]);
    }

    for (let selectorKey in mapN)
      if (!mapO.hasOwnProperty(selectorKey))
        delta.added.push(mapN[selectorKey])

    return delta;
  }

  const addOrUpdateObjects = (array, objectsToAdd, selector = "id") => {
    // Loop through each object to add
    objectsToAdd.forEach(objectToAdd => {
      // Check if the object already exists in the array
      const existingObjectIndex = array.findIndex(object => object[selector] === objectToAdd[selector]);

      if (existingObjectIndex !== -1) {
        // If the object exists, update its properties
        array[existingObjectIndex] = {
          ...array[existingObjectIndex],
          ...objectToAdd
        };
      } else {
        // If the object doesn't exist, add it to the array
        array.push(objectToAdd);
      }
    });

    return array;
  }

  const deleteObjects = (array, objectsToDelete, selector = "id") => {
    const ids = objectsToDelete.map(item => item[selector]);
    return array.filter(obj => !ids.includes(obj[selector]));
  }

  const getMatchingUnmatchingIds = (array1, array2) => {
    const matchingIds = [];
    const unmatchingIds = [];
    if (array1 && array2)
      array1.forEach(id => {
        if (array2.some(obj => obj.id === id))
          matchingIds.push(id);
        else
          unmatchingIds.push(id)
      });
    return { matchingIds, unmatchingIds };
  }

  const getSelectedWidgetsType = (selectedWidgets) => {
    if (!selectedWidgets || selectedWidgets.length === 0) return false;
    let types = [];
    selectedWidgets.forEach(rec => {
      if (document.getElementById(rec)) types.push(document.getElementById(rec).dataset.assetType);
    })
    return types;
  }

  const isSortedArray = (array, sortBy) => {
    for (let i = 0; i < array.length - 1; i++) {
      if (array[i][sortBy] > array[i + 1][sortBy])
        return false;
    }
    return true;
  }

  const applyInfographicResize = () => {
    let curEditorWrap = document.getElementById("generic-editor-wrap");
    if (curEditorWrap) {
      curEditorWrap.style.width = `${parseInt(dimensionRef.current.width)}px`;
      curEditorWrap.style.transform = `scale(1)`;
    }
    let curEditorOuterWrap = document.querySelector(".editor-outer-wrap");
    if (curEditorOuterWrap) {
      curEditorOuterWrap.style.width = `${parseInt(dimensionRef.current.width)}px`;
      curEditorOuterWrap.style.height = `${parseInt(dimensionRef.current.height) + 80}px`; // as 80 is the bottom padding of canvas area
    }
  }

  const applyOtherDocumentResize = () => {
    let newZoomVal = calculateNewZoomValue(dimensionRef.current.width, dimensionRef.current.height)
    // apply adjustment fot firefox and safari start
    let userAgent = navigator.userAgent;
    let isFirefox = userAgent.match(/firefox|fxios/i);
    let isSafari = !userAgent.match(/chrome/i) && userAgent.match(/safari/i);
    let curEditorWrap = document.getElementById("generic-editor-wrap");
    if (curEditorWrap && (isFirefox || isSafari)) {
      curEditorWrap.style.width = `${dimensionRef.current.width * (newZoomVal / 100)}px`;
      // curEditorWrap.style.overflow = `hidden`;
    }
    // apply adjustment fot firefox and safari end
  }

  const checkReindexed = (arr1, arr2, key = "id") => {
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i][key] !== arr2[i][key]) { // Check if objects have matching key in same index
        return true; // Arrays have been re-indexed
      }
    }
    return false;
  }

  const handleOnline = () => {
    if (isOffline?.current) {
      exitRoom();
      setChangeBySocket(true);
      updateMetadata({
        ...metadata,
        activeOutSideCanvasArea: true,
        activeWidgetId: false,
        activeWidgetType: false
      });
      let docId = window.location.pathname.split("/")[2];

      const retryFetchDocumentDetails = () => {
        dispatch(fetchDocumentDetails(docId)).then(resp => {
          if (resp?.result?.data) {
            setIsDocumentReady(false);
            initDocumentReader({ data: resp.result.data });
          } else {
            setTimeout(retryFetchDocumentDetails, 2000); // Retry after 2 seconds
          }
        });
      };
      retryFetchDocumentDetails();
    }
  };

  const handleOffline = () => {
    setStartCollaboration(false);
    isOffline.current = true;
  }

  const handleVersionRestore = () => {
    let docId = window.location.pathname.split("/")[2];
    dispatch(fetchDocumentDetails(docId)).then(resp => {
      if (resp?.result?.data) {
        setIsDocumentReady(false);
        initDocumentReader({ data: resp.result.data });
      }
    });
  }

  useEffect(() => {
    if (webSocket?.readyState === 1) {
      if (!startCollaboration) {
        const subscribe = {
          type: JOIN_COLLABORATION_ROOM,
          userId,
          companyId,
          documentId: id,
        };
        webSocket.send(JSON.stringify(subscribe));
        setStartCollaboration(true)
      }
      webSocket.addEventListener("message", onMessageWS); // inject msg lisner

      return () => {
        webSocket.removeEventListener("message", onMessageWS); // remove msg lisner
      }
    }
    if (webSocket?.readyState === 3) setStartCollaboration(false)
  }, [webSocket?.readyState]);

  // when document is ready assign Refs
  useEffect(() => {
    if (isDocumentReady) {
      widgetsRef.current = Object.assign(widgets);
      blockNodesRef.current = Object.assign(blockNodes);
      pageNodesRef.current = Object.assign(pageNodes);
      dimensionRef.current = Object.assign(dimension);
      backgroundColorsRef.current = Object.assign(backgroundColors);
      backgroundImagesRef.current = Object.assign(backgroundImages);
      audiosRef.current = Object.assign(audios ?? []);
      metadataRef.current = Object.assign(metadata);
      documentNameRef.current = documentName;
      canvasZoom.current = dimension.zoom
    }

    return () => {
      widgetsRef.current = null;
      blockNodesRef.current = null;
      pageNodesRef.current = null;
      dimensionRef.current = null;
      backgroundColorsRef.current = null;
      backgroundImagesRef.current = null;
      audiosRef.current = null;
      metadataRef.current = null;
      documentNameRef.current = null;
    }
  }, [isDocumentReady])

  useEffect(() => {
    if (dimensionRef?.current && isDocumentReady && (((parseInt(dimensionRef.current.height) !== parseInt(dimension.height)) && documentType !== TYPE_INFOGRAPHIC) || parseInt(dimensionRef.current.width) !== parseInt(dimension.width))) {
      sendData("dimensionUpdate", { widgets, blockNodes, pageNodes, dimension }, id);
      widgetsRef.current = Object.assign(widgets);
      blockNodesRef.current = Object.assign(blockNodes);
      pageNodesRef.current = Object.assign(pageNodes);
      dimensionRef.current = Object.assign(dimension);
      setChangeBySocket(false);
    }
    canvasZoom.current = dimension.zoom;
  }, [dimension]);

  useEffect(() => {
    if (!_.isEqual(blockNodesRef.current, blockNodes) && isDocumentReady && (((parseInt(dimensionRef.current.height) === parseInt(blockNodes[0].style.height)) && documentType !== TYPE_INFOGRAPHIC) || parseInt(dimensionRef.current.width) === parseInt(blockNodes[0].style.width))) {
      let isReindexed = blockNodesRef?.current.length === blockNodes.length ? checkReindexed(blockNodesRef?.current, blockNodes, "blockId") : false;
      let pageNodesDelta = isReindexed ? { updated: pageNodes } : getDelta(pageNodesRef.current, pageNodes, "page", isEqualData);
      let blockNodesDelta = isReindexed ? { updated: blockNodes } : getDelta(blockNodesRef.current, blockNodes, "block", isEqualData);
      // let widgetsDelta = isReindexed ? { updated: widgets } : blockNodesDelta?.deleted?.length > 0 ? getDelta(widgetsRef.current, widgets, "widget", isEqualData) : false;
      let widgetsDelta = isReindexed ? { updated: widgets } : getDelta(widgetsRef.current, widgets, "widget", isEqualData);
      let backgroundColorsDelta = isReindexed ? { updated: backgroundColors } : getDelta(backgroundColorsRef.current, backgroundColors, "bgColor", isEqualData);
      let backgroundImagesDelta = isReindexed ? { updated: backgroundImages } : getDelta(backgroundImagesRef.current, backgroundImages, "bgImage", isEqualData);
      sendData("blockNodesUpdate", { pageNodesDelta, blockNodesDelta, widgetsDelta, backgroundColorsDelta, backgroundImagesDelta, isReindexed }, id);
      blockNodesRef.current = Object.assign(blockNodes);
      pageNodesRef.current = Object.assign(pageNodes);
      setChangeBySocket(false);
      if (blockNodesDelta?.deleted?.length > 0 || blockNodesDelta?.added?.length > 0) {
        widgetsRef.current = Object.assign(widgets);
        backgroundColorsRef.current = Object.assign(backgroundColors);
        backgroundImagesRef.current = Object.assign(backgroundImages);
      }
      if (isReindexed) {
        // need to remove collaborative selections (page re-order by current user || current user active page deleted by collaborative user)
        let curSocketState = socketStateRef?.current ? Object.assign(socketStateRef?.current) : {};
        let allActiveUserWidgets = []
        let allOtherUserWidgets = []
        for (let key in curSocketState?.activeUsers) {
          if (key === userHash.current)
            allActiveUserWidgets.push({ userHash: key, activeWidgetId: false });
          else {
            if (curSocketState?.activeUsers[key]?.activeWidgetId?.length > 0) allOtherUserWidgets.push(...curSocketState?.activeUsers[key]?.activeWidgetId)
            allActiveUserWidgets.push({ userHash: key, activeWidgetId: curSocketState?.activeUsers[key]?.activeWidgetId });
          }
        }

        socketStateRef.current = Object.assign(curSocketState)
        updateSocketState(curSocketState);
        if (allOtherUserWidgets.length > 0) {
          const subscribe = {
            type: ACTIVE_WIDGET_CHANGE,
            userId,
            companyId,
            documentId: id,
            activeWidgetId: false,
            userHash: userHash.current,
            needToSyncOthers: true,
            allActiveUserWidgets
          };
          webSocket?.send(JSON.stringify(subscribe));
        }
      }
    }
  }, [blockNodes]);

  // Halndle "pageDuration", "pageTransition"
  useEffect(() => {
    if (!_.isEqual(pageNodesRef.current, pageNodes) && pageNodesRef.current?.length === pageNodes?.length && isDocumentReady) {
      let pageNodesDelta = getDelta(pageNodesRef.current, pageNodes, "page", isEqualData);
      sendData("pageNodesUpdate", { pageNodesDelta }, id);
      pageNodesRef.current = Object.assign(pageNodes);
      setChangeBySocket(false);
    }
  }, [pageNodes]);

  useEffect(() => {
    if (!_.isEqual(backgroundColorsRef.current, backgroundColors) && isDocumentReady) {
      let backgroundColorsDelta = getDelta(backgroundColorsRef.current, backgroundColors, "bgColor", isEqualData);
      sendData("backgroundColorUpdate", { backgroundColorsDelta }, id);
      backgroundColorsRef.current = Object.assign(backgroundColors);
      setChangeBySocket(false);
    }
  }, [backgroundColors]);

  useEffect(() => {
    if (!_.isEqual(backgroundImagesRef.current, backgroundImages) && isDocumentReady) {
      let backgroundImagesDelta = getDelta(backgroundImagesRef.current, backgroundImages, "bgImage", isEqualData);
      sendData("backgroundImageUpdate", { backgroundImagesDelta }, id);
      backgroundImagesRef.current = Object.assign(backgroundImages);
      setChangeBySocket(false);
    }
  }, [backgroundImages]);

  useEffect(() => {
    if (!_.isEqual(widgetsRef?.current, widgets) && isDocumentReady && (((parseInt(dimensionRef.current.height) === parseInt(blockNodes[0].style.height)) && documentType !== TYPE_INFOGRAPHIC) || parseInt(dimensionRef.current.width) === parseInt(blockNodes[0].style.width))) {
      let isReindexed = widgetsRef?.current.length === widgets.length ? checkReindexed(widgetsRef?.current, widgets) : false;
      let widgetsDelta = isReindexed ? { updated: widgets } : getDelta(widgetsRef.current, widgets, "widget", isEqualData);
      sendData("widgetUpdate", { widgetsDelta, isReindexed }, id);
      widgetsRef.current = Object.assign(widgets);
      setChangeBySocket(false);
    }
  }, [widgets]);

  useEffect(() => {
    if (documentNameRef.current !== documentName && isDocumentReady) {
      sendData("documentNameUpdate", { documentName }, id);
      documentNameRef.current = documentName;
    }
  }, [documentName])

  useEffect(() => {
    if (!_.isEqual(audiosRef.current, audios) && isDocumentReady) {
      let audiosDelta = getDelta(audiosRef.current, audios, "audio", isEqualData);
      sendData("audioUpdate", { audiosDelta }, id);
      audiosRef.current = Object.assign(audios);
      setChangeBySocket(false);
    }
  }, [audios]);

  useEffect(() => {
    setChangeBySocket(false);
    if (startCollaboration && isDocumentReady && userHash?.current && !_.isEqual(metadataRef.current?.activeWidgetId, metadata?.activeWidgetId)) {
      let activeWidgetId = metadata?.activeWidgetId;
      let needToSyncOthers = false;
      let allActiveUserWidgets = [];
      if (metadata.activeWidgetId.length === 1) {
        if (metadata.activeWidgetType[0] === GROUP_WIDGET) {
          let selectedInnerWidgets = [];
          [...document.querySelector(`#${metadata?.activeWidgetId[0]}`).children].forEach(wz => {
            selectedInnerWidgets.push(wz.getAttribute("id"));
          })

          if (_.isEqual(metadataRef.current?.activeWidgetId, selectedInnerWidgets)) {
            // Case: new group created
            // Need inform Others
            for (let key in socketState?.activeUsers) {
              if (key === userHash.current)
                allActiveUserWidgets.push({ userHash: key, activeWidgetId });
              else {
                let otherUserActiveWidgets = [];
                socketState?.activeUsers[key]?.activeWidgetId?.forEach(activeWzId => {
                  if (widgets.findIndex(wz => wz.id === activeWzId) > -1)
                    otherUserActiveWidgets.push(activeWzId)
                })
                allActiveUserWidgets.push({ userHash: key, activeWidgetId: otherUserActiveWidgets });
                needToSyncOthers = true;
              }
            }
            // needToSyncOthers = true;
          }
          metadataRef.current = { ...metadata, activeWidgetId, activeWidgetType: [GROUP_WIDGET] };
        }
        else {
          // Case: widget inside group or single selected
          let isParentGroupWidget = metadata?.activeWidgetId ? document.querySelector(`#${metadata?.activeWidgetId[0]}`)?.closest(".dhp-root-widget") ?? false : false;
          activeWidgetId = isParentGroupWidget?.classList?.contains("dhp-page-group") ? [isParentGroupWidget.getAttribute("id")] : metadata?.activeWidgetId
          metadataRef.current = { ...metadata, activeWidgetId: activeWidgetId.length > 0 ? activeWidgetId : false, activeWidgetType: getSelectedWidgetsType(activeWidgetId) };
          needToSyncOthers = false;
        }
      }
      else {
        // Case: multiple widget selected
        activeWidgetId = metadata?.activeWidgetId ? metadata?.activeWidgetId : []
        metadataRef.current = { ...metadata, activeWidgetId: activeWidgetId?.length > 0 ? activeWidgetId : false, activeWidgetType: getSelectedWidgetsType(activeWidgetId) };
        needToSyncOthers = false;
      }
      const subscribe = {
        type: ACTIVE_WIDGET_CHANGE,
        userId,
        companyId,
        documentId: id,
        activeWidgetId,
        userHash: userHash.current,
        needToSyncOthers,
        ["allActiveUserWidgets"]: allActiveUserWidgets
      };

      if (!_.isEqual(activeWidgetSubscriptionRef.current, subscribe)) webSocket?.send(JSON.stringify(subscribe));
      activeWidgetSubscriptionRef.current = subscribe;
    }
    else metadataRef.current = Object.assign(metadata);
  }, [metadata]);

  // handle unload event
  useEffect(() => {
    window.addEventListener("unload", handleUnload);
    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      exitRoom();
      window.removeEventListener("unload", handleUnload);
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    }
  }, []);

  // RECEIVER 
  const onMessageWS = useCallback(message => {
    const response = JSON.parse(message?.data);
    if (
      [COLLABORATION_RECEIVE, COLLABORATION_STATUS, DOCUMENT_VERSION_RESTORE_AFTER].includes(response.type) &&
      response?.companyId === companyId &&
      response?.documentId === id
    ) {
      switch (response?.actionType) {
        case "syncUsers":
          if (response?.joining) {
            updateSocketState({
              activeUsers: response?.activeUsers,
              currentUser: response?.currentUser,
            });
            socketStateRef.current = {
              ...socketState, activeUsers: response?.activeUsers,
              currentUser: response?.currentUser,
            }
            userHash.current = response?.currentUser;
          } else {
            if (response?.needToSyncOthers) {
              let updatedSelectedWidgets = response?.activeUsers[userHash.current]?.activeWidgetId;
              let activeBlockId = !updatedSelectedWidgets?.length > 0 ? false : widgetsRef.current.find(wz => wz.id === updatedSelectedWidgets[0])?.blockId ?? false;
              let updatedMetadata = { ...metadataRef.current, activeWidgetId: updatedSelectedWidgets?.length > 0 ? updatedSelectedWidgets : false, activeWidgetType: getSelectedWidgetsType(updatedSelectedWidgets) };
              updateMetadata({ ...updatedMetadata })
              initSelectable({ activeWidgetId: updatedSelectedWidgets, activeBlockId, zoom: canvasZoom.current, finalUpdate: true });
              hideAllWidgetSelection({ exceptions: updatedSelectedWidgets, type: "group" });
              metadataRef.current = { ...updatedMetadata };
            }
            else {
              let curActiveWidgetIds = [];
              if ((!response?.activeWidgetId || response?.activeWidgetId?.length === 0) && metadataRef?.current?.activeWidgetId?.length > 0) {
                metadataRef?.current?.activeWidgetId?.forEach(wzId => {
                  // check Exists in DOM
                  if (document.getElementById(wzId)) curActiveWidgetIds.push(wzId)
                })
                let activeBlockId = !curActiveWidgetIds.length > 0 ? false : widgetsRef.current.find(wz => wz.id === curActiveWidgetIds[0])?.blockId ?? false;
                let updatedMetadata = { ...metadataRef.current, activeWidgetId: curActiveWidgetIds, activeWidgetType: getSelectedWidgetsType(curActiveWidgetIds), activeBlockId };
                updateMetadata({ ...updatedMetadata });
                initSelectable({ activeWidgetId: curActiveWidgetIds, activeBlockId, zoom: canvasZoom.current, finalUpdate: true });
                metadataRef.current = { ...updatedMetadata };
              }
            }
            socketStateRef.current = {
              ...socketState, activeUsers: response?.activeUsers,
              currentUser: userHash.current,
            }
            updateSocketState({ activeUsers: response?.activeUsers, currentUser: userHash.current });
          }

          break;

        case "widgetUpdate":
          {
            let { widgetsDelta: { added, deleted, updated } = {}, isReindexed } = response?.data ?? {};
            if (isReindexed) {
              // change widget Layer
              widgetsRef.current = Object.assign(updated);
              setChangeBySocket(true);
              updateWidgets(updated);
            }
            else {
              let newArray = Object.assign(widgetsRef?.current ?? [])
              // delete
              newArray = deleteObjects(newArray, deleted)
              // update 
              newArray = addOrUpdateObjects(newArray, updated);
              // add
              newArray = addOrUpdateObjects(newArray, added);

              // If another user active widget deleted
              let isGroupWidget = document.getElementById(metadataRef?.current?.activeWidgetId[0])?.closest(".dhp-page-group");
              let targetId = isGroupWidget ? [document.getElementById(metadataRef?.current?.activeWidgetId[0]).closest(".dhp-root-widget").getAttribute("id")] : metadataRef?.current?.activeWidgetId;

              if (deleted.length > 0 && targetId) {
                let { matchingIds, unmatchingIds } = getMatchingUnmatchingIds(targetId, deleted);
                if (matchingIds.length > 0) {
                  let updatedMetadata = { ...metadataRef.current, activeWidgetId: unmatchingIds.length > 0 ? unmatchingIds : false, activeWidgetType: getSelectedWidgetsType(unmatchingIds) };
                  metadataRef.current = { ...updatedMetadata };
                  updateMetadata({ ...updatedMetadata });
                  initSelectable({ activeWidgetId: updatedMetadata.activeWidgetId, activeBlockId: metadataRef.current.activeBlockId, zoom: canvasZoom.current, finalUpdate: true, isCollaborationChanges: true });
                }
              }
              widgetsRef.current = newArray;
              setChangeBySocket(true);
              updateWidgets(newArray);
              if (updated.length > 0) {
                let { matchingIds } = getMatchingUnmatchingIds(targetId, updated);
                if (matchingIds.length > 0)
                  initSelectable({ activeWidgetId: metadataRef.current.activeWidgetId, activeBlockId: metadataRef.current.activeBlockId, zoom: canvasZoom.current, finalUpdate: true, isCollaborationChanges: true });
              }
            }
          }
          break;

        case "backgroundColorUpdate":
          {
            let { backgroundColorsDelta: { added, deleted, updated } = {} } = response?.data ?? {};
            let bgColorSelector = selectorList.find(s => s.type === "bgColor")?.key;
            let newArray = Object.assign(backgroundColorsRef?.current ?? []);
            // delete
            newArray = deleteObjects(newArray, deleted, bgColorSelector)
            // update 
            newArray = addOrUpdateObjects(newArray, updated, bgColorSelector);
            // add
            newArray = addOrUpdateObjects(newArray, added, bgColorSelector);

            backgroundColorsRef.current = newArray;
            setChangeBySocket(true);
            updateBackgroundColors(newArray);
          }
          break;

        case "backgroundImageUpdate":
          {
            let { backgroundImagesDelta: { added, deleted, updated } = {} } = response?.data ?? {};
            let bgImageSelector = selectorList.find(s => s.type === "bgImage")?.key;
            let newArray = Object.assign(backgroundImagesRef?.current ?? []);
            // delete
            newArray = deleteObjects(newArray, deleted, bgImageSelector)
            // update 
            newArray = addOrUpdateObjects(newArray, updated, bgImageSelector);
            // add
            newArray = addOrUpdateObjects(newArray, added, bgImageSelector);

            backgroundImagesRef.current = newArray;
            setChangeBySocket(true);
            updateBackgroundImages(newArray);
          }
          break;

        case "blockNodesUpdate":
          {
            let { pageNodesDelta: { added: addedPageNodes, deleted: deletedPageNodes, updated: updatedPageNodes } = {}, blockNodesDelta: { added: addedBlockNodes, deleted: deletedBlockNodes, updated: updatedBlockNodes } = {}, widgetsDelta: { added: addedWidgets, deleted: deletedWidgets, updated: updatedWidgets } = {}, backgroundColorsDelta: { added: addedBackgroundColors, deleted: deletedBackgroundColors, updated: updatedBackgroundColors } = {}, backgroundImagesDelta: { added: addedBackgroundImages, deleted: deletedBackgroundImages, updated: updatedBackgroundImages } = {}, isReindexed } = response?.data ?? {};
            if (isReindexed) {
              let updatedMetadata = {
                ...metadataRef.current,
                activeOutSideCanvasArea: true,
                activeWidgetId: false,
                activeWidgetType: false
              };
              metadataRef.current = { ...updatedMetadata };
              updateMetadata(updatedMetadata);
              pageNodesRef.current = Object.assign(updatedPageNodes);
              blockNodesRef.current = Object.assign(updatedBlockNodes);
              widgetsRef.current = Object.assign(updatedWidgets);
              setChangeBySocket(true);
              updateWidgets(updatedWidgets, updatedPageNodes, updatedBlockNodes);
              // updateBlockNodes(updatedBlockNodes, updatedPageNodes)
            }
            else {
              let pageSelector = selectorList.find(s => s.type === "page")?.key;
              let blockSelector = selectorList.find(s => s.type === "block")?.key;
              let widgetSelector = selectorList.find(s => s.type === "widget")?.key;
              let bgColorSelector = selectorList.find(s => s.type === "bgColor")?.key;
              let bgImageSelector = selectorList.find(s => s.type === "bgImage")?.key;
              let newPageNodesArray = Object.assign(pageNodesRef?.current ?? []);
              // delete
              newPageNodesArray = deleteObjects(newPageNodesArray, deletedPageNodes, pageSelector)
              // update 
              newPageNodesArray = addOrUpdateObjects(newPageNodesArray, updatedPageNodes, pageSelector);
              // add
              newPageNodesArray = addOrUpdateObjects(newPageNodesArray, addedPageNodes, pageSelector);

              let newBlockNodesArray = Object.assign(blockNodesRef?.current ?? []);
              // delete
              newBlockNodesArray = deleteObjects(newBlockNodesArray, deletedBlockNodes, blockSelector)
              // update 
              newBlockNodesArray = addOrUpdateObjects(newBlockNodesArray, updatedBlockNodes, blockSelector);
              // add
              newBlockNodesArray = addOrUpdateObjects(newBlockNodesArray, addedBlockNodes, blockSelector);

              let newWidgetsArray = Object.assign(widgetsRef?.current ?? []);
              // delete
              newWidgetsArray = deleteObjects(newWidgetsArray, deletedWidgets, widgetSelector)
              // update 
              newWidgetsArray = addOrUpdateObjects(newWidgetsArray, updatedWidgets, widgetSelector);
              // add
              newWidgetsArray = addOrUpdateObjects(newWidgetsArray, addedWidgets, widgetSelector);

              let newBackgroundColorsArray = Object.assign(backgroundColorsRef?.current ?? []);
              // delete
              newBackgroundColorsArray = deleteObjects(newBackgroundColorsArray, deletedBackgroundColors, bgColorSelector)
              // update 
              newBackgroundColorsArray = addOrUpdateObjects(newBackgroundColorsArray, updatedBackgroundColors, bgColorSelector);
              // add
              newBackgroundColorsArray = addOrUpdateObjects(newBackgroundColorsArray, addedBackgroundColors, bgColorSelector);

              let newBackgroundImagesArray = Object.assign(backgroundImagesRef?.current ?? []);
              // delete
              newBackgroundImagesArray = deleteObjects(newBackgroundImagesArray, deletedBackgroundImages, bgImageSelector)
              // update 
              newBackgroundImagesArray = addOrUpdateObjects(newBackgroundImagesArray, updatedBackgroundImages, bgImageSelector);
              // add
              newBackgroundImagesArray = addOrUpdateObjects(newBackgroundImagesArray, addedBackgroundImages, bgImageSelector);

              let isActiveUserBlockDeleted = deletedBlockNodes.find(bn => bn.blockId === metadataRef?.current?.activeBlockId);

              // re-arrange PageNodes and BlockNodes if not sorted (page re-order by collaborative user)
              if (!isSortedArray(newBlockNodesArray, "blockIdx")) {
                newPageNodesArray = newPageNodesArray.sort((a, b) => a.pageIdx - b.pageIdx);
                newBlockNodesArray = newBlockNodesArray.sort((a, b) => a.blockIdx - b.blockIdx);
              }

              // re-indexing on delete page block
              newPageNodesArray = newPageNodesArray.map((page, index) => ({ ...page, pageIdx: index }));
              newBlockNodesArray = newBlockNodesArray.map((block, index) => ({ ...block, blockIdx: index }));

              if (!_.isEqual(blockNodesRef?.current, newBlockNodesArray)) {
                pageNodesRef.current = newPageNodesArray;
                blockNodesRef.current = newBlockNodesArray;
                widgetsRef.current = newWidgetsArray;
                backgroundColorsRef.current = newBackgroundColorsArray;
                backgroundImagesRef.current = newBackgroundImagesArray;
                setChangeBySocket(true);
                if (isActiveUserBlockDeleted) {
                  let updatedMetadata = { ...metadataRef.current, activeWidgetId: false, activeWidgetType: false, activeOutSideCanvasArea: true };
                  metadataRef.current = { ...updatedMetadata };
                  updateMetadata({ ...updatedMetadata })
                  updateWidgets(newWidgetsArray, newPageNodesArray, newBlockNodesArray, newBackgroundColorsArray, newBackgroundImagesArray);
                }
                else {
                  updateWidgets(newWidgetsArray, newPageNodesArray, newBlockNodesArray, newBackgroundColorsArray, newBackgroundImagesArray);
                }
                setTimeout(() => {
                  initSelectable({ activeWidgetId: metadataRef.current.activeWidgetId, activeBlockId: metadataRef.current.activeBlockId, zoom: canvasZoom.current, finalUpdate: true, isCollaborationChanges: true });
                }, 100)
              }

              // If Page or Block deleted
              if (deletedBlockNodes.length > 0 && metadataRef?.current?.activeBlockId) {
                // update new active block in context
                let activeBlockId =
                  metadataRef?.current.activeBlockIdx === newBlockNodesArray.length
                    ? newBlockNodesArray[metadataRef?.current.activeBlockIdx - 1].blockId
                    : newBlockNodesArray[metadataRef?.current.activeBlockIdx].blockId;
                let activeBlockIdx =
                  metadataRef?.current.activeBlockIdx === newBlockNodesArray.length ? metadataRef?.current.activeBlockIdx - 1 : metadataRef?.current.activeBlockIdx;
                let activePageId = newBlockNodesArray[activeBlockIdx].pageId;
                let activePageIdx = documentType === TYPE_INFOGRAPHIC ? 0 : activeBlockIdx;

                setTimeout(() => {
                  // calculate contoller new top
                  let activeControllerTop = documentType === TYPE_INFOGRAPHIC ? (activeBlockIdx === 0 ? 0 : document.getElementById(activeBlockId)?.offsetTop ?? 0 * canvasZoom.current / 100) : (activeBlockIdx === 0 ? 0 : document.getElementById(activePageId)?.offsetTop ?? 0);

                  let updatedMetadata = {
                    ...metadataRef.current,
                    activeOutSideCanvasArea: activeBlockId ? false : true,
                    activePageId,
                    activeBlockId,
                    activePageIdx,
                    activeBlockIdx,
                    activeWidgetId: isActiveUserBlockDeleted ? false : metadataRef.current.activeWidgetId,
                    activeWidgetType: isActiveUserBlockDeleted ? false : metadataRef.current.activeWidgetType,
                    pageCount: newPageNodesArray.length,
                    blockCount: newBlockNodesArray.length,
                    pageController: {
                      ...metadataRef.current.pageController,
                      style: { ...metadataRef.current.pageController.style, top: activeControllerTop + "px" },
                    }
                  };
                  updateMetadata({ ...updatedMetadata });
                  metadataRef.current = { ...updatedMetadata };
                }, 200)
              }
              if (documentType === TYPE_INFOGRAPHIC) {
                setTimeout(() => {
                  setTotalHeight(newBlockNodesArray, undefined, undefined, canvasZoom.current, dimensionRef.current)
                }, 150);
              }
            }
          }
          break;

        case "pageNodesUpdate":
          {
            let { pageNodesDelta: { added, deleted, updated } = {} } = response?.data ?? {};
            let pageSelector = selectorList.find(s => s.type === "page")?.key;
            let newArray = Object.assign(pageNodesRef?.current ?? []);
            // delete
            newArray = deleteObjects(newArray, deleted, pageSelector)
            // update 
            newArray = addOrUpdateObjects(newArray, updated, pageSelector);
            // add
            newArray = addOrUpdateObjects(newArray, added, pageSelector);

            pageNodesRef.current = newArray;
            setChangeBySocket(true);
            updatePageNodes(newArray);
            updateBlockNodes(blockNodesRef.current, newArray);
          }
          break;

        case "dimensionUpdate":
          if (!_.isEqual(dimensionRef?.current, response?.data?.dimension)) {
            let newZoom = documentType === TYPE_INFOGRAPHIC ? 100 : calculateNewZoomValue(response?.data?.dimension?.width, response?.data?.dimension?.height);
            dimensionRef.current = { ...response?.data?.dimension, zoom: newZoom };
            setChangeBySocket(true);
            updateDimension({
              ...response?.data?.dimension, zoom: newZoom
            });
            pageNodesRef.current = response?.data?.pageNodes;
            blockNodesRef.current = response?.data?.blockNodes;
            widgetsRef.current = response?.data?.widgets;
            updateWidgets(
              response?.data?.widgets,
              response?.data?.pageNodes,
              response?.data?.blockNodes,
              false,
              false,
              false,
              {
                ...response?.data?.dimension, zoom: newZoom
              },
              "pageResized"
            );
            let newMeta = {
              ...metadataRef.current,
              activeOutSideCanvasArea: true,
              activeWidgetId: false,
              activeWidgetType: false
            };
            metadataRef.current = newMeta;
            updateMetadata(newMeta);
            if (documentType === TYPE_INFOGRAPHIC) applyInfographicResize();
            else applyOtherDocumentResize();
          }
          break

        case "documentNameUpdate":
          if (documentNameRef?.current !== response?.data?.documentName) {
            documentNameRef.current = response?.data?.documentName;
            updateDocumentName(response?.data?.documentName);
          }
          break

        case "versionRestore":
          handleVersionRestore();

        case "audioUpdate":
          if (!_.isEqual(audiosRef?.current, response?.data?.audios)) {
            let { audiosDelta: { added, deleted, updated } = {} } = response?.data ?? {};
            let audioSelector = selectorList.find(s => s.type === "audio")?.key;
            let newArray = Object.assign(audiosRef?.current ?? []);
            // delete
            newArray = deleteObjects(newArray, deleted, audioSelector)
            // update 
            newArray = addOrUpdateObjects(newArray, updated, audioSelector);
            // add
            newArray = addOrUpdateObjects(newArray, added, audioSelector);

            audiosRef.current = newArray;
            setChangeBySocket(true);
            setAudios(newArray);
          }
          break;
        default:
          break;
      }
    }
  }, []);
};

export default useEditorCollaboration;
