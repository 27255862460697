import { useContext } from "react";
import { widgetConfig } from "../components/Editor/editor_config";
import { EditorContext } from "../containers/editor/EditorLayout";
import { getDocumentDuration, getRandomString } from "../_helpers/utils";
import useAddBlock from "./useAddBlock";

const useAddPage = () => {
  let { metadata, pageNodes, transitionAppliedAllPages } = useContext(EditorContext);

  const { addBlock } = useAddBlock();

  const addPage = pageIdx => {
    //  prepare target page canvas
    let availablePageCount = pageNodes.length;
    let targetPageId = "dhp-page-" + getRandomString(8);
    let targetPageIdx = pageIdx
      ? pageIdx
      : availablePageCount == 0
      ? metadata.activePageIdx
      : metadata.activePageIdx + 1;
    let availableTime;

    if (availablePageCount !== 0) {
      let getDocDurationInfo = getDocumentDuration(pageNodes, metadata.activePageIdx);
      availableTime = getDocDurationInfo.document.available;
    }
    let pageDuration = !availableTime || availableTime >= 5 ? "5.0s" : `${availableTime.toFixed(1)}s`;

    let pageObj = {
      pageId: targetPageId,
      pageIdx: targetPageIdx,
      pageDuration: pageDuration,
      style: {
        position: "relative",
      },
      pageTransition: {
        enabled: transitionAppliedAllPages ? pageNodes[0].pageTransition.enabled : false,
        type: transitionAppliedAllPages ? pageNodes[0].pageTransition.type : widgetConfig.page_transition.defaultType,
        speed: transitionAppliedAllPages
          ? pageNodes[0].pageTransition.speed
          : widgetConfig.page_transition.defaultSpeed,
      },
    };

    addBlock(pageObj, pageIdx);
  };

  return { addPage };
};

export default useAddPage;
