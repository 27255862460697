import { useEffect, useState, useContext } from "react";
import * as constant from "../constants/editor";
import { widgetConfig } from "../components/Editor/editor_config";
import { EditorContext } from "../containers/editor/EditorLayout";
import useAddWidget from "./useAddWidget";
import useElementInnerHtml from "./useElementInnerHtml";
import useReplaceWidget from "./useReplaceWidget";
import {
  searchNodeByPoint,
  getFileType,
  getSvgContentFromUrl,
  getImgDimensionFromUrl,
  RGBToHex,
  getZoomedValue,
} from "../_helpers/utils";
import { useContextualUpgrade } from "./useContextualUpgrade";
import { useCheckCompanyPlanInfo } from "./useCheckCompanyPlanInfo";
import { COMPANY_SUPERADMIN } from "../constants/company";
import useTextFocusOut from "./useTextFocusOut";
import useSetSvgColorOnWidgetInsertion from "./useSetSvgColorOnWidgetInsertion";
import typeFormDragImage from "../assets/images/typeform-image.png";
import jotFormDragImage from "../assets/images/jotform-image.png";

const useDragDropWidget = () => {
  const { metadata, updateMetadata, backgroundImages, updateBackgroundImages, dimension } = useContext(EditorContext);
  // useContextualUpgrade Hook to show update modal
  const showUpgrade = useContextualUpgrade();

  // check is this company is paid or not
  const paidCompanyInfo = useCheckCompanyPlanInfo(true);

  const [assetType, setAssetType] = useState();
  const [dataParam, setDataParam] = useState();
  const [dropAction, setDropAction] = useState();
  const [dropPosition, setDropPosition] = useState();
  const [dropZoneData, setDropZoneData] = useState();
  const [assetInnerContent, setAssetInnerContent] = useState();
  const [widgetWidth, setWidgetWidth] = useState();
  const [widgetHeight, setWidgetHeight] = useState();
  const [dropCoordinates, setDropCoordinates] = useState();
  const [addWidgetClicked, setAddWidgetClicked] = useState(false);
  const [customCss, setCustomCss] = useState();
  const [brandText, setbrandText] = useState();

  const { handleFocusOut } = useTextFocusOut();

  const getNewWidgetObject = useAddWidget(
    addWidgetClicked,
    assetType,
    dataParam,
    dropPosition,
    widgetWidth,
    widgetHeight
  );

  useElementInnerHtml(
    addWidgetClicked,
    setAddWidgetClicked,
    assetType,
    assetInnerContent,
    dataParam,
    getNewWidgetObject,
    customCss,
    brandText
  );

  const { start: initReplaceWidget, isWidgetReplaceable } = useReplaceWidget();

  const DND = {
    meta: false,
    dragPathWidth: {
      [constant.TEXT_FRAME]: 176,
      [constant.BACKGROUND]: 100,
      [constant.LINE]: 150,
      [constant.SHAPE]: 176,
      [constant.ICON]: 176,
      [constant.STICKER]: 176,
      [constant.ILLUSTRATION]: {
        [constant.SOLO]: 176,
        [constant.SCENE]: 176,
      },
      [constant.PICTURE]: 150,
      [constant.UPLOAD]: 150,
      [constant.VIDEO]: 240,
      [constant.ANIMATION]: 176,
      [constant.COLLAGE]: 240,
      [constant.TYPE_FORM]: 110,
      [constant.JOT_FORM]: 110,
    },
    customDragImage: {
      [constant.TYPE_FORM]: typeFormDragImage,
      [constant.JOT_FORM]: jotFormDragImage,
    },

    prepareDropPosition: () => {
      if ([constant.COLLAGE].includes(assetType)) {
        // set default drop position for exceptional widgets which expand end-to-end of the canvas
        setDropPosition([{ left: 0, top: 0 }]);
      } else {
        const { left: activeBlockLeft, top: activeBlockTop } = dropZoneData?.blockNode?.getBoundingClientRect();
        const zoomFactor = 100 / parseFloat(dimension.zoom);
        const pos = {
          left: (dropCoordinates.x - activeBlockLeft) * zoomFactor - widgetWidth / 2,
          top: (dropCoordinates.y - activeBlockTop) * zoomFactor - widgetHeight / 2,
        };
        setDropPosition([pos]);
      }
    },

    prepareInnerHtmlAndDimension: async (data, dropData) => {
      let svgContent;
      const { asset, value, poster, filename, brandDetails, source } = data;

      if ([constant.TEXT].includes(asset)) {
        setAssetInnerContent(widgetConfig[asset].content);
        setWidgetWidth(widgetConfig[asset][value].width);
        setWidgetHeight(widgetConfig[asset][value].height * constant.LINE_HEIGHT);
        if (brandDetails) {
          const fonts = JSON.parse(localStorage?.getItem("allFonts"));

          let index = fonts.findIndex(
            font => font.name.toLowerCase() === brandDetails?.font_family.replace(/["]+/g, "").toLowerCase()
          );

          setCustomCss({
            "font-size": brandDetails?.font_size + "px",
            "font-family": brandDetails?.font_family,
            "font-weight": brandDetails?.bold
              ? parseInt(fonts[index].bold_weight)
              : parseInt(fonts[index].normal_weight),
            "font-style": brandDetails?.italic ? "italic" : "normal",
          });
          setbrandText(true);
        }
      } else if ([constant.COLLAGE].includes(asset)) {
        let { width: activeBlockWidth, height: activeBlockHeight } = dropData?.blockNode?.getBoundingClientRect();
        activeBlockWidth = (activeBlockWidth * 100) / parseFloat(dimension.zoom);
        activeBlockHeight = (activeBlockHeight * 100) / parseFloat(dimension.zoom);

        const node = document.createElement("div");
        node.innerHTML = value;
        node.querySelector(".main-content").style.minHeight = `${activeBlockHeight}px`;
        node.querySelector(".collage-container").style.gap = "10px";

        setAssetInnerContent(node);
        setWidgetWidth(activeBlockWidth);
        setWidgetHeight(activeBlockHeight);
      } else if ([constant.TYPE_FORM, constant.JOT_FORM].includes(asset)) {
        let node = document.createElement("iframe");
        node.src = `${value}?${widgetConfig[asset]?.linkParams}`;
        node.style.cssText = widgetConfig[asset]?.iframeInnerHTML?.cssText ?? "";
        node.classList = "customapp-frame";
        setAssetInnerContent(node.outerHTML);
        setWidgetWidth(widgetConfig[asset].width);
        setWidgetHeight(widgetConfig[asset].height);
      } else if (
        [constant.PICTURE, constant.VIDEO, constant.ANIMATION].includes(asset) ||
        ([constant.UPLOAD].includes(asset) && getFileType(filename) !== constant.SVG)
      ) {
        setAssetInnerContent(value);

        if (
          [constant.PICTURE, constant.UPLOAD].includes(asset) ||
          ([constant.VIDEO].includes(asset) && [constant.BRAND, constant.UPLOAD_VIDEO].includes(source))
        ) {
          const { width: imgWidth, height: imgHeight } = await getImgDimensionFromUrl(
            asset === constant.VIDEO ? poster : value
          );
          const imgAspectRatio = imgWidth / imgHeight;
          let { width: activeBlockWidth } = dropData?.blockNode?.getBoundingClientRect();
          activeBlockWidth = (activeBlockWidth * 100) / parseFloat(dimension.zoom);
          const width = imgWidth <= activeBlockWidth ? imgWidth : activeBlockWidth;
          const height = imgWidth <= activeBlockWidth ? imgHeight : width / imgAspectRatio;

          setWidgetWidth(width);
          setWidgetHeight(height);
        } else {
          setWidgetWidth(widgetConfig[asset].width);
          setWidgetHeight(widgetConfig[asset].height);
        }
      } else {
        const getTrimmedSVG = ![constant.SHAPE, constant.LINE].includes(asset); // avoid svg trim for LINES, SHAPES
        svgContent = await getSvgContentFromUrl(value, getTrimmedSVG);

        if (
          [constant.ILLUSTRATION, constant.STICKER, constant.ICON, constant.TEXT_FRAME].includes(asset) ||
          ([constant.UPLOAD].includes(asset) && getFileType(filename) === constant.SVG)
        ) {
          svgContent.querySelectorAll(`svg *`).forEach(node => {
            if (
              node.nodeName === "defs" ||
              node.parentNode.nodeName === "defs" ||
              node.parentNode.nodeName === "linearGradient"
            )
              return false;

            if (
              (node.nodeName !== "g" && node.style && RGBToHex(node.style.fill) !== "") ||
              node?.getAttribute("fill")
            ) {
              const currentIconColor = node?.getAttribute("fill")
                ? node?.getAttribute("fill")
                : RGBToHex(node.style.fill);
              node.dataset.class = `${currentIconColor}body`;
            }

            if (node.tagName === "title") node.remove(); // fremove title tag for uploaded svg
          });
        }

        setAssetInnerContent(svgContent);

        if ([constant.LINE, constant.UPLOAD].includes(asset)) {
          setWidgetWidth(svgContent?.viewBox?.baseVal?.width ?? widgetConfig[asset].width);
          setWidgetHeight(svgContent?.viewBox?.baseVal?.height ?? widgetConfig[asset].height);
        } else if (constant.SHAPE === asset) {
          let svgRatio = svgContent?.viewBox?.baseVal?.width / svgContent?.viewBox?.baseVal?.height;
          let svgHeight = widgetConfig[asset].width / svgRatio;
          setWidgetWidth(widgetConfig[asset].width);
          setWidgetHeight(svgHeight);
        } else {
          let svgRatio = svgContent?.viewBox?.baseVal?.height / svgContent?.viewBox?.baseVal?.width;
          let svgWidth = widgetConfig[asset].height / svgRatio;
          setWidgetWidth(svgWidth);
          setWidgetHeight(widgetConfig[asset].height);
        }
      }
    },

    prepareDataParam: data => {
      const paramObj = {};
      const { asset, value, category, scheme, source, origin, download, poster, duration, url, key, filename } = data;

      // get dynamic data params
      if ([constant.LINE, constant.SHAPE].includes(asset)) paramObj["data-category"] = category;
      if ([constant.SHAPE, constant.ICON].includes(asset)) paramObj["data-scheme"] = scheme;
      if ([constant.PICTURE].includes(asset)) {
        paramObj["data-source"] = source;
        paramObj["data-origin"] = origin;
        paramObj["data-download"] = download;
      }
      if ([constant.VIDEO].includes(asset) && [constant.STOCK].includes(source)) {
        paramObj["data-origin"] = origin;
        paramObj["data-poster"] = poster;
        paramObj["data-url"] = value;
        paramObj["data-duration"] = duration;
      }
      if ([constant.VIDEO].includes(asset) && [constant.YOUTUBE].includes(source)) {
        paramObj["data-url"] = url;
        paramObj["data-key"] = key;
      }
      if ([constant.VIDEO].includes(asset) && [constant.BRAND, constant.UPLOAD_VIDEO].includes(source)) {
        paramObj["data-poster"] = poster;
        paramObj["data-url"] = value;
        paramObj["data-duration"] = duration;
      }
      if ([constant.UPLOAD].includes(asset)) {
        paramObj["data-file-type"] = getFileType(filename);
        paramObj["data-duration"] = duration;
      }
      if ([constant.ANIMATION].includes(asset)) {
        paramObj["data-duration"] = duration;
      }
      paramObj["data-x-allignment"] = false;
      paramObj["data-y-allignment"] = false;

      // merge dynamic and predefined data params
      if ([constant.TEXT].includes(asset)) {
        setDataParam({ ...widgetConfig[asset][value].dataAttr, ...paramObj });
      } else if ([constant.VIDEO].includes(asset)) {
        setDataParam({ ...widgetConfig[asset][source].dataAttr, ...paramObj });
      } else {
        setDataParam({ ...widgetConfig[asset].dataAttr, ...paramObj });
      }
    },

    updateBackground: (data, dropData) => {
      const targetIdx = backgroundImages.findIndex(entry => entry.blockId === dropData.blockId);
      const newBackgroundImgUrl = data.value;
      const newBackgroundImageObj = {
        blockId: dropData.blockId,
        pageId: dropData.pageId,
        style: { ...widgetConfig[constant.BACKGROUND].image.style, backgroundImage: `url('${newBackgroundImgUrl}')` },
      };

      if (targetIdx === -1) {
        // new
        updateBackgroundImages([...backgroundImages, newBackgroundImageObj]);
      } else {
        // update
        updateBackgroundImages(
          backgroundImages.map((entry, idx) =>
            idx === targetIdx
              ? { ...entry, style: { ...entry.style, backgroundImage: `url('${newBackgroundImgUrl}')` } }
              : entry
          )
        );
      }
    },

    updateActivePageAndBlock: dropData => {
      // BUGFIX ~ delete active widgets when performing DND
      const correctedMeta = !document.getElementById(metadata.activeWidgetId[0])
        ? { activeWidgetId: false, activeWidgetType: false }
        : {};

      updateMetadata({
        ...metadata,
        activePageId: dropData.pageId,
        activePageIdx: parseInt(dropData.pageIdx),
        activeBlockId: dropData.blockId,
        activeBlockIdx: parseInt(dropData.blockIdx),
        ...correctedMeta,
      });
    },

    validateDropZone: (data, e) => {
      const { node: isDroppedOnBlock = false } = searchNodeByPoint({
        className: "dhp-page-block",
        x: e.pageX,
        y: e.pageY,
      });
      const { node: isDroppedOnWidget = false, childNode: isDroppedOnChild = false } =
        isDroppedOnBlock &&
        searchNodeByPoint({
          className: "dhp-page-widget",
          x: e.pageX,
          y: e.pageY,
          childClass: ["collage-item"],
        });
      const { replaceType = false } = isDroppedOnWidget && isWidgetReplaceable(isDroppedOnWidget, data, true);
      const isDroppedOnReplaceableWidget = replaceType ? isDroppedOnWidget : false;

      if (isDroppedOnBlock) {
        const asset = data.asset;
        const action = isDroppedOnReplaceableWidget ? constant.REPLACE : constant.INSERT;
        const dropData = {
          pageId: isDroppedOnBlock.closest(".dhp-page-canvas").getAttribute("id"),
          pageIdx: isDroppedOnBlock.closest(".dhp-page-canvas").getAttribute("data-page-idx"),
          blockId: isDroppedOnBlock.getAttribute("id"),
          blockIdx: isDroppedOnBlock.getAttribute("data-block-idx"),
          blockNode: isDroppedOnBlock,
          widgetId: isDroppedOnReplaceableWidget && isDroppedOnReplaceableWidget.getAttribute("id"),
          widgetIdx: isDroppedOnReplaceableWidget && isDroppedOnReplaceableWidget.getAttribute("data-widget-idx"),
        };
        const dropCords = {
          x: e.pageX,
          y: e.pageY,
        };

        DND.updateActivePageAndBlock(dropData);

        if (asset === constant.BACKGROUND) {
          DND.updateBackground(data, dropData);
        } else {
          setAssetType(asset);
          setDropAction(action);
          setDropZoneData(dropData);

          if (action === constant.INSERT) {
            setDropCoordinates(dropCords);
            DND.prepareDataParam(data);
            DND.prepareInnerHtmlAndDimension(data, dropData);
          }
          if (action === constant.REPLACE) {
            initReplaceWidget(isDroppedOnReplaceableWidget, data, isDroppedOnChild);
          }
        }
      }
    },

    showUpgradeModal: data => {
      const { asset, value, thumb } = data;
      const assetUrl = [constant.COLLAGE].includes(asset) ? thumb : value;
      showUpgrade("premiumAsset", assetUrl, { assetType: asset });
    },

    validateDropPermission: ({ e, client: { data } }) => {
      if (data?.premium && paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && !paidCompanyInfo?.isPaid)
        DND.showUpgradeModal(data);
      else DND.validateDropZone(data, e);
    },

    toggleDragPath: ({ action, client, dragPath }) => {
      if (action === "SHOW") {
        dragPath.node.classList.remove("d-none");
        client.node.classList.add("disable-pointer");
        document.getElementsByTagName("body")[0].classList.add("grabbing");
      }
      if (action === "HIDE") {
        dragPath.node.innerHTML = "";
        dragPath.node.style.cssText = "";
        dragPath.node.classList.add("d-none");
        client.node.classList.remove("disable-pointer");
        document.getElementsByTagName("body")[0].classList.remove("grabbing");
      }
    },

    defocusReplaceableWidgets: () => {
      document.querySelectorAll(".replaceable-widget")?.forEach(widget => {
        widget.classList.remove("replaceable-widget");
        if (widget.classList.contains("dhp-page-widget")) widget.style.opacity = "1";
      });
    },

    focusReplaceableWidgets: ({ e, client: { data } }) => {
      DND.defocusReplaceableWidgets();
      const { node: isOverWidget = false, childNode: isOverChild = false } = searchNodeByPoint({
        className: "dhp-page-widget",
        x: e.pageX,
        y: e.pageY,
        childClass: ["collage-item"],
      });
      const { replaceType = false } = isOverWidget && isWidgetReplaceable(isOverWidget, data, true);

      if (["imageType", "videoType"].includes(replaceType)) {
        isOverWidget.classList.add("replaceable-widget");
        isOverWidget.style.opacity = "0.3";
      } else if (isOverChild && ["collageType"].includes(replaceType)) {
        isOverChild.classList.add("replaceable-widget");
      }
    },

    updateDragPathCss: ({ e, client, dragPath }) => {
      const dragPathDim = {
        width: dragPath.node.clientWidth,
        height: dragPath.node.clientHeight,
      };

      const pos = {
        left: `${e.pageX - dragPathDim.width / 2}px`,
        top: `${e.pageY - dragPathDim.height / 2}px`,
      };

      dragPath.node.style.transform = `translate(${pos.left}, ${pos.top})`;
      if (!dragPath.node.style.width && !client.data.brandDetails) dragPath.node.style.width = `${dragPathDim.width}px`;
    },

    generateDragPathHTML: ({ client, dragPath }) => {
      const { asset, value, scheme, source, poster, thumb } = client.data;

      if (asset === constant.TEXT) {
        const clientComputedStyle = window.getComputedStyle(client.node);

        dragPath.node.innerHTML = client.node.innerHTML;

        // dragpath html for brand text
        if (client.data.brandDetails) {
          dragPath.node.style.cssText = `
            font-size: ${clientComputedStyle.getPropertyValue("font-size")};
            font-family : ${client.data.brandDetails.font_family};
            font-weight: ${client.data.brandDetails.bold ? 700 : 400};
            font-style: ${client.data.brandDetails.italic} ? "italic" : "normal";
          `;
        }
        // dragpath html for preset / normal text
        else {
          dragPath.node.style.cssText = `
            font-size: ${clientComputedStyle.getPropertyValue("font-size")};
            font-weight: ${clientComputedStyle.getPropertyValue("font-weight")};
          `;
        }
      } else if ([constant.TYPE_FORM, constant.JOT_FORM].includes(asset)) {
        const src = DND.customDragImage[asset];
        const width = getZoomedValue(DND.dragPathWidth[asset], dimension.zoom);

        dragPath.node.innerHTML = `<img src="${src}" />`;
        dragPath.node.style.width = `${width}px`;
      } else {
        const src =
          [constant.VIDEO].includes(asset) && [constant.STOCK, constant.BRAND, constant.UPLOAD_VIDEO].includes(source)
            ? poster
            : [constant.COLLAGE].includes(asset)
            ? thumb
            : value;

        const width = [constant.ILLUSTRATION].includes(asset)
          ? getZoomedValue(DND.dragPathWidth[asset][scheme], dimension.zoom)
          : getZoomedValue(DND.dragPathWidth[asset], dimension.zoom);

        dragPath.node.innerHTML = `<img src="${src}" />`;
        dragPath.node.style.width = `${width}px`;
      }
    },

    start: (data, e) => {
      e.preventDefault();

      // Focusout triggerd if any text or text frame widget is contenteditable
      if (document.querySelector(".dhp-content-editable-true-text")) handleFocusOut();

      const dragPath = document.getElementById("drag-path-display");

      let props = {
        isDragging: true,
        client: {
          node: e.target,
          data: data,
        },
        dragPath: {
          node: dragPath,
        },
        // canvas: document.getElementById("canvas-panel-area"), | TODO:::: for drag to scroll custom implementation
      };

      DND.meta = props;
      DND.generateDragPathHTML({ ...props });
      document.addEventListener("mousemove", DND.drag);
      document.addEventListener("mouseup", DND.stop);
    },

    drag: e => {
      let meta = DND.meta;
      if (meta?.isDragging) {
        e.preventDefault();
        DND.toggleDragPath({ action: "SHOW", ...meta });
        DND.updateDragPathCss({ e, ...meta });
        DND.focusReplaceableWidgets({ e, ...meta });
      }
    },

    stop: e => {
      let meta = DND.meta;
      if (meta?.isDragging) {
        e.preventDefault();
        DND.toggleDragPath({ action: "HIDE", ...meta });
        DND.defocusReplaceableWidgets();
        DND.validateDropPermission({ e, ...meta });
        document.removeEventListener("mousemove", DND.drag);
        document.removeEventListener("mouseup", DND.stop);
        meta = false;
      }
    },
  };

  // set dropPosition for widget INSERT
  useEffect(() => {
    if (
      dropAction === constant.INSERT &&
      !dropPosition &&
      dropZoneData &&
      dropCoordinates &&
      widgetWidth &&
      widgetHeight &&
      assetType
    ) {
      DND.prepareDropPosition();
    }
  }, [dropAction, dropPosition, dropZoneData, dropCoordinates, widgetWidth, widgetHeight, assetType]);

  // trigger widget INSERT
  useEffect(() => {
    if (dropAction === constant.INSERT && assetType && dataParam && dropPosition && assetInnerContent) {
      setAddWidgetClicked(true);
    }
  }, [dropAction, assetType, dataParam, dropPosition, assetInnerContent]);

  // reset all local states when widget drag-drop action INSERT is completed
  useEffect(() => {
    if (!addWidgetClicked) {
      setAssetType();
      setDataParam();
      setDropAction();
      setDropPosition();
      setDropZoneData();
      setAssetInnerContent();
      setWidgetWidth();
      setWidgetHeight();
      setDropCoordinates();
      setCustomCss();
      setbrandText();
    }
  }, [addWidgetClicked]);

  return { start: DND.start };
};

export default useDragDropWidget;
