import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";

import Logo from "../../assets/images/logo-icon.svg";
import global from "../../scss/dhp.scss";

let style = Object.assign({}, global);

export default function DocHipoLogo() {
  return (
    <Link to="/">
      <span className={cx(style["logo-icon"], style["mr-2"])}>
        <img src={Logo} width="30" alt="" />
      </span>
      <span className={style["logo-text"]}>
        <span className={style["logo-red"]}>D</span>
        <span className={style["logo-yellow"]}>o</span>
        <span className={style["logo-green"]}>c</span>
        Hipo
      </span>
    </Link>
  );
}
