import { useContext } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";
import { getHMSToSec, getRandomString, getSecToHMS } from "../_helpers/utils";
import { AUDIO } from "../constants/editor";
import { widgetConfig } from "../components/Editor/editor_config";
import { duration } from "moment";

const useAudio = () => {
  let { audios, setAudios, metadata, pageNodes, isTimeLineViewOpen, setIsTimeLineViewOpen, updateMetadata } =
    useContext(EditorContext);
  const addAudio = (url, duration, filename, audioFilename, source) => {
    const activePageIndex = metadata?.activePageIdx;
    const durations = pageNodes.map(node => parseFloat(node.pageDuration) || 0);
    const startTime = durations.slice(0, activePageIndex).reduce((total, duration) => total + duration, 0);
    const endTime = durations.reduce((total, duration) => total + duration, 0);
    //array of colors
    const colors = ["#FAEABC", "#D0D0F0", "#FBE7C6", "#F6D0CA", "#CADEEE", "#F5D8C3", "#DDEBD5", "#F9E3C3"];
    let lastColor = audios?.length > 0 ? audios[audios.length - 1].color : null;

    // Extract the file type from the file name
    const getFileType = filename => {
      return filename.split(".").pop() || "";
    };

    // Function to get a random color skipping the last used color
    const getRandomColor = () => {
      let availableColors = lastColor ? colors.filter(color => color !== lastColor) : colors;
      const randomIndex = Math.floor(Math.random() * availableColors.length);
      return availableColors[randomIndex];
    };

    const fileType = getFileType(filename);
    const durationInSec = getHMSToSec({ hms: duration });
    const trimDuration = endTime > durationInSec ? durationInSec : endTime - startTime;
    const newAudioId = `dhp-audio-${getRandomString(8)}`;

    let newAudio = {
      id: newAudioId,
      src: url,
      startTime: `${startTime}s`,
      endTime: `${endTime.toFixed(1)}s`,
      originalDuration: `${duration}`,
      data: {
        ...widgetConfig[AUDIO][source].dataAttr,
        "data-file-type": fileType,
        "data-file-name": audioFilename,
        "data-duration": duration,
        "data-trim": `${0}, ${trimDuration}`,
        "data-trimmed-duration": getSecToHMS({ sec: trimDuration }),
      },
      color: getRandomColor(),
    };
    if (!audios || !Array.isArray(audios)) {
      setAudios([newAudio]);
    } else {
      setAudios([...audios, newAudio]);
    }

    if (isTimeLineViewOpen) {
      if (isTimeLineViewOpen === "page-timeline") {
        setIsTimeLineViewOpen("document-timeline"); // when insert any audio if page timeline vieww is open switch to documnet timeline
      }

      setTimeout(() => {
        document.getElementById(newAudioId).scrollIntoView(); // scroll to last added audio if it is not in viewport
        updateMetadata({
          ...metadata,
          activeWidgetId: false,
          activeWidgetType: false,
          activeAudioId: newAudioId,
        });
      }, 100);
    }
  };

  const AUD = {
    common: {
      updateReactDom: ({ audioId, newData = {} }) => {
        // update react DOM - modify shadow DOM on save event
        setAudios(
          audios.map(audio => {
            if (audio.id === audioId) {
              return {
                ...audio,
                data: {
                  ...audio.data,
                  ...newData,
                },
              };
            } else {
              return audio;
            }
          })
        );
      },

      getAudioNodeAndDataRef: ({ audioId, audioObj }) => {
        if (!audioObj) audioObj = audios?.find(a => a.id === audioId);

        const trim = audioObj.data["data-trim"];
        const durationInHMS = audioObj.data["data-duration"];
        const durationInSec = getHMSToSec({ hms: durationInHMS });
        const trimArray = trim ? trim.split(",")?.map(e => parseFloat(e)) : null;
        const isTrimmed = Boolean(trimArray?.length === 2);
        const trimStart = isTrimmed ? trimArray?.[0] : 0;
        const trimEnd = isTrimmed ? trimArray?.[1] : durationInSec;

        return {
          src: audioObj.src,
          startTime: parseFloat(audioObj.startTime),
          endTime: parseFloat(audioObj.endTime),
          originalDuration: audioObj.originalDuration,
          volume: audioObj.data["data-volume"],
          durationInSec,
          durationInHMS,
          trimStart,
          trimEnd,
          isLooped: audioObj.data["data-loop"],
          isTrimmed: Boolean(trimStart > 0 || trimEnd < durationInSec),
        };
      },
    },

    volume: {
      fetch: ({ audioId, audioObj }) => {
        const data = AUD.common.getAudioNodeAndDataRef({ audioId, audioObj });
        return data;
      },

      apply: ({ audioId, audioObj, volume, finalUpdate }) => {
        // const { audio } = AUD.common.getAudioNodeAndDataRef({ audioId, audioObj });

        // apply volume
        // audio.volume = volume / 100;

        // update context
        if (finalUpdate) {
          AUD.common.updateReactDom({
            audioId,
            newData: { "data-volume": volume },
          });
        }
      },
    },

    trim: {
      fetch: ({ audioId, audioObj }) => {
        const data = AUD.common.getAudioNodeAndDataRef({ audioId, audioObj });
        return data;
      },

      apply: ({ audioId, trimData, finalUpdate }) => {
        // update context
        if (finalUpdate) {
          let audioIndex = audios.findIndex(a => a.id === audioId);
          let pageAudDuration = parseFloat(audios[audioIndex].endTime) - parseFloat(audios[audioIndex].startTime);
          let trimmedAudDuration = parseFloat(trimData.trimmedDuration);
          let originalAudDuration = parseFloat(getHMSToSec({ hms: audios[audioIndex].originalDuration }));
          let newTrimEndPoint, newTrimmedDuration;

          // if page audio duration is greater than trimmed audio duration and audio trimmed end point is not last point that means not equal to origanl audio duration end point then extend trim part till page audio duration
          if (pageAudDuration > trimmedAudDuration && parseFloat(trimData.trimEnd) !== originalAudDuration) {
            let extraDuration = pageAudDuration - trimmedAudDuration;
            newTrimEndPoint =
              parseFloat(trimData.trimEnd) + extraDuration > originalAudDuration
                ? originalAudDuration
                : parseFloat(trimData.trimEnd) + extraDuration; // if it cross original duration then set original duration as trim end point and loop furter till page audio duration else set estra page aud duration time as new trimmed end time
          }

          newTrimEndPoint = newTrimEndPoint ? newTrimEndPoint : trimData.trimEnd;
          newTrimmedDuration = newTrimEndPoint - parseFloat(trimData.trimStart);

          AUD.common.updateReactDom({
            audioId,
            newData: {
              "data-trim": `${trimData.trimStart},${newTrimEndPoint.toFixed(1)}`,
              "data-trimmed-duration": getSecToHMS({ sec: newTrimmedDuration }),
            },
          });
        }
      },
    },
  };

  return {
    addAudio,
    fetchVolume: AUD.volume.fetch,
    applyVolume: AUD.volume.apply,
    fetchTrim: AUD.trim.fetch,
    applyTrim: AUD.trim.apply,
  };
};

export default useAudio;
