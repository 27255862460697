import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { AI_WRITER } from "../../../constants/editor";
import { getWidgetAndParentsDomReference } from "../../../_helpers/utils";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { useContextualUpgrade } from "../../../hooks/useContextualUpgrade";
import { useCheckCompanyPlanInfo } from "../../../hooks/useCheckCompanyPlanInfo";
import { COMPANY_SUPERADMIN } from "../../../constants/company";

let style = Object.assign({}, global);

const AiWriter = props => {
  const { metadata } = useContext(EditorContext);
  const showUpgrade = useContextualUpgrade();
  const paidCompanyInfo = useCheckCompanyPlanInfo(true);
  const [isDisabled, setIsDisabled] = useState(false);

  const checkValidity = () => {
    const {
      widget: { data: widgetData, isLocked: isWidgetLocked, isGroupSelected, isGroupWidget, isChildWidget },
    } = getWidgetAndParentsDomReference(metadata.activeWidgetId[0]);

    const isListType = ["UL", "OL"].includes(widgetData?.subwidget);

    if (isWidgetLocked || isGroupSelected || isGroupWidget || isChildWidget || isListType) setIsDisabled(true);
    else setIsDisabled(false);
  };

  const upgradeSubscriptionCheck = () => {
    if (paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && !paidCompanyInfo?.isPaid) showUpgrade("aiWriter");
    else props?.setmoreSecondaryOptionClicked(AI_WRITER);
  };

  useEffect(() => {
    if (metadata.activeWidgetId) checkValidity();
  }, [metadata.activeWidgetId]);

  return (
    <li className={cx(style["toolset-group"], { [style["disable"]]: isDisabled })}>
      <UncontrolledDropdown className={cx(style["toolset-item"], style["aiwriter-wrap"])}>
        <DropdownToggle
          tag="span"
          className={cx(style["toolset-action"], style["custom-tooltip"])}
          onClick={() => !isDisabled && upgradeSubscriptionCheck()}>
          <Icon icon="ui-pen" />
          AI Writer
        </DropdownToggle>
      </UncontrolledDropdown>
    </li>
  );
};
AiWriter.propTypes = {
  setmoreSecondaryOptionClicked: PropTypes.func,
};

export default AiWriter;
