import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Button } from "reactstrap";
import { useParams } from "react-router-dom";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { DataFormatter } from "../../ui/dataFormatter";
import { useDispatch, useSelector } from "react-redux";
import { restoreDocumentVersion } from "../../../store/actions/documentActions";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { RESTORE_DOCUMENT_VERSION_SUCCESS } from "../../../store/actions/actionTypes";
import { useSocket } from "../../../hooks/useSocket";
import { DOCUMENT_VERSION_RESTORE } from "../../../constants/socket";

let style = Object.assign({}, global);

const RestoreVersionModal = ({ closeModal, selectedVersionInfo, parentModalClose }) => {
  const dispatch = useDispatch();
  const webSocket = useSocket();
  const { id: documentId } = useParams();
  const { setIsDocumentReady, HistoryList } = useContext(EditorContext);
  const [isProcessDone, setIsProcessDone] = useState();
  const isVersionRestored = useSelector(state => state.document?.versionRestored);
  const authState = useSelector(state => state?.auth);

  const userId = authState?.uid;
  const companyId = authState?.user?.company?.id;

  const close = () => closeModal();

  const handleSubmit = () => {
    dispatch(restoreDocumentVersion(documentId, selectedVersionInfo?.id)).then((res)=>{
      if(res?.data?.success && res?.type === RESTORE_DOCUMENT_VERSION_SUCCESS && webSocket?.readyState === 1){
          const subscribe = {
            type: DOCUMENT_VERSION_RESTORE,
            userId,
            companyId,
            documentId
          };
          webSocket.send(JSON.stringify(subscribe));
      }
    });
    setIsProcessDone(true);
    if (parentModalClose) parentModalClose();
  };

  useEffect(() => {
    if (isProcessDone && isVersionRestored) {
      HistoryList.length = 1; // Reset history list to disable undo redo
      setIsDocumentReady(false)
      close();
    }
  }, [isVersionRestored]);

  return (
    <React.Fragment>
      <h4 className={cx(style["fw-7"], style["mb-4"])}>Restore this version</h4>

      <span aria-label="Close" className={cx(style["cross-modal"], style["rounded"])} onClick={close}>
        <Icon icon="ui-close" />
      </span>

      <div>
        <div className={cx(style["delete-msg"], style["font-base"], style["color-33"], style["mb-4"])}>
          Your current document will revert to the version created on{" "}
          <DataFormatter
            type="date-time"
            slug="modified_on"
            format="default"
            data={selectedVersionInfo}
            additionalclass="d-inline-flex"
          />
          . Are you sure to restore?
        </div>

        <Button type="submit" color="primary" onClick={handleSubmit}>
          Yes
        </Button>
      </div>
    </React.Fragment>
  );
};

//Props type validation
RestoreVersionModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  selectedVersionInfo: PropTypes.object.isRequired,
  parentModalClose: PropTypes.func,
};

export default RestoreVersionModal;
