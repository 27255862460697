import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input as RInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Tooltip,
} from "reactstrap";
import cx from "classnames";

import { getRandomString } from "../../_helpers/utils";
import { Icon } from "./icon";

import global from "../../scss/dhp.scss";
import userAvatar from "../../assets/images/ui-user.svg";

let style = Object.assign({}, global);

const _helpers = {
  concat: (fields, data) => {
    let textToDisplay = "";
    fields.forEach(field => (textToDisplay += data[field] ? `${data[field]} ` : ""));
    return textToDisplay.trim();
  },
  concat_all: (primary, muted, data) => {
    return _helpers.concat(primary, data) + (muted ? "  -  " + _helpers.concat(muted, data) : "");
  },
};

const Selection = ({ options, value, ...props }) => {
  const [firstItem, setFirstItem] = useState(false);
  const [moreItems, setMoreItems] = useState(0);
  const [placeholder, setPlaceholder] = useState("Choose Options");

  useEffect(() => {
    if (options.length > 0 && value) {
      let firstOption = options.find(option => option[props.optionValue] === value[0]) ?? false;
      setFirstItem(firstOption ? _helpers.concat(props.optionTextPrimary, firstOption) : false);
      setMoreItems(value.length - 1);
    }
  }, [options, value]);

  useEffect(() => {
    if (props?.placeholder) setPlaceholder(props.placeholder);
  }, [props?.placeholder]);

  return (
    <div className={cx(style["align-items-center"], style["d-flex"], style["justify-content-between"], style["w-100"])}>
      <h6 className={cx(style["mb-0"], style["text-truncate"], { [style["text-muted"]]: !firstItem })}>
        {firstItem ? firstItem : placeholder}
      </h6>
      <div className={cx(style["align-items-center"], style["d-flex"], style["pl-3"])}>
        {moreItems > 0 && <span className={style["more-user"]}>+{moreItems}</span>}
        <Icon icon="ui-arrow-down" />
      </div>
    </div>
  );
};

const Option = ({ option, index, id, ...props }) => {
  const textPrimaryRef = useRef();
  const textMutedRef = useRef();
  const thumb = {
    src: option[props?.optionImage] ?? props?.optionImageNoPreview,
    loading: true,
    default: false,
  };
  const [textPrimary, setTextPrimary] = useState("");
  const [textMuted, setTextMuted] = useState("");
  const [optionThumb, setOptionThumb] = useState(thumb);
  const [showTextPrimaryTooltip, setShowTextPrimaryTooltip] = useState(false);
  const [showTextMutedTooltip, setShowTextMutedTooltip] = useState(false);

  const handleImageLoad = () => setOptionThumb({ ...optionThumb, loading: false });

  const handleImageError = () => setOptionThumb({ ...optionThumb, src: userAvatar, loading: false, default: true });

  const toggleTextPrimaryTooltip = () =>
    setShowTextPrimaryTooltip(
      !showTextPrimaryTooltip && textPrimaryRef.current.scrollWidth > textPrimaryRef.current.offsetWidth
    );

  const toggleTextMutedTooltip = () =>
    setShowTextMutedTooltip(
      !showTextMutedTooltip && textMutedRef.current.scrollWidth > textMutedRef.current.offsetWidth
    );

  const changeHandler = e => {
    let targetValue = e.target.getAttribute("data-record-id");
    props.setValue(e.target.checked ? [...props.value, targetValue] : props.value.filter(v => v !== targetValue));
  };

  useEffect(() => {
    setTextPrimary(_helpers.concat(props.optionTextPrimary, option));
    if (props?.optionTextMuted) setTextMuted(_helpers.concat(props.optionTextMuted, option));

    const image = new Image();
    image.src = thumb.src;
    image.addEventListener("load", handleImageLoad);
    image.addEventListener("error", handleImageError);
    return () => {
      image.removeEventListener("load", handleImageLoad);
      image.removeEventListener("error", handleImageError);
    };
  }, []);

  return (
    <div className={cx(style["custom-control"], style["custom-checkbox"])}>
      <RInput
        type="checkbox"
        id={`item-selection-${props.uniqueId}-${index}`}
        className={style["custom-control-input"]}
        data-record-id={option[props.optionValue]}
        onChange={e => changeHandler(e)}
      />
      <Label
        htmlFor={`item-selection-${props.uniqueId}-${index}`}
        className={cx(style["align-items-center"], style["d-flex"], style["custom-control-label"])}>
        {props?.optionImage && (
          <div className={cx(style["thumbnail-img"])}>
            {!optionThumb.loading && (
              <img
                src={optionThumb.src}
                className={cx(style["img-fluid"], style["rounded-circle"], {
                  [style["default"]]: optionThumb.default,
                })}
                alt="Option image"
              />
            )}
          </div>
        )}

        <div className={style["user-info"]}>
          <h6
            className={cx(style["m-0"], style["text-truncate"])}
            ref={textPrimaryRef}
            id={`text-primary-tooltip-${id}`}>
            {textPrimary}
          </h6>
          <div
            className={cx(style["text-muted"], style["text-truncate"])}
            ref={textMutedRef}
            id={`text-muted-tooltip-${id}`}>
            {textMuted}
          </div>
        </div>
      </Label>
      <Tooltip
        placement="bottom"
        isOpen={showTextPrimaryTooltip}
        target={`text-primary-tooltip-${id}`}
        toggle={toggleTextPrimaryTooltip}
        boundariesElement={document.getElementById("app")}>
        {textPrimary}
      </Tooltip>
      <Tooltip
        placement="bottom"
        isOpen={showTextMutedTooltip}
        target={`text-muted-tooltip-${id}`}
        toggle={toggleTextMutedTooltip}
        boundariesElement={document.getElementById("app")}>
        {textMuted}
      </Tooltip>
    </div>
  );
};

const MultiSelect = ({ updateState, ...props }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState([]);
  const [uniqueId, setUniqueId] = useState();
  const [searchKeyLength, setSearchKeyLength] = useState(0);
  const [visibleOptions, setVisibleOptions] = useState(0);
  const [noOptionMessage, setNoOptionMessage] = useState("No options");
  const [noResultMessage, setNoResultMessage] = useState("No results found");
  const [noOutcome, setNoOutcome] = useState({
    type: false,
    message: false,
  });

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const searchHandler = e => {
    if (options.length > 0) {
      const keyword = e.target.value.trim();
      const result = options.map(option => {
        let matchFound = [...props.optionTextPrimary, ...(props?.optionTextMuted ?? [])]?.some(item =>
          option[item].toLowerCase().includes(keyword.toLowerCase())
        );
        if (matchFound) {
          return { ...option, display: "block" };
        } else {
          return { ...option, display: "none" };
        }
      });
      setOptions(result);
      setSearchKeyLength(e.target.value.length);
    }
  };

  const cancelSearch = () => {
    if (options.length > 0 && document.getElementById(`search-box-${uniqueId}`).value !== "") {
      document.getElementById(`search-box-${uniqueId}`).value = "";
      document.getElementById(`search-box-${uniqueId}`).focus();
      setOptions(props.options.map(option => ({ ...option, display: "block" })));
      setSearchKeyLength(0);
    }
  };

  const toggleMasterSelection = e => {
    if (options.length > 0) {
      let items = document.querySelectorAll(`*[id^="item-selection-${uniqueId}"]`);
      [...items].forEach(item => (item.checked = e.target.checked));
      setValue(e.target.checked ? options.map(option => option[props.optionValue]) : []);
    }
  };

  const displaySelectedOptions = () => {
    let items = document.querySelectorAll(`*[id^="item-selection-${uniqueId}"]`);
    [...items].forEach(item => (item.checked = value.find(v => v === item.getAttribute("data-record-id"))));
    document.getElementById(`master-selection-${uniqueId}`).checked = value.length === options.length;
  };

  useEffect(() => {
    if (options.length > 0) {
      document.getElementById(`master-selection-${uniqueId}`).checked = value.length === options.length;
      updateState(value);
    }
  }, [value]);

  useEffect(() => {
    if (options && noOptionMessage && noResultMessage) {
      if (options.length === 0) setNoOutcome({ type: "no_option_provided", message: noOptionMessage });
      else {
        let visible = options.filter(option => option.display === "block");
        setNoOutcome(
          visible.length > 0 ? { type: false, message: false } : { type: "no_search_result", message: noResultMessage }
        );
        setVisibleOptions(visible.length);
      }
    }
  }, [options, noOptionMessage, noResultMessage]);

  useEffect(() => {
    if (props?.noOptionMessage) setNoOptionMessage(props.noOptionMessage);
    if (props?.noResultMessage) setNoResultMessage(props.noResultMessage);
  }, [props?.noOptionMessage, props?.noResultMessage]);

  useEffect(() => {
    if (searchKeyLength === 0) setOptions(props.options.map(option => ({ ...option, display: "block" })));
    if (props?.defaultValue) setValue(props.defaultValue);
  }, [props.options, props?.defaultValue]);

  useEffect(() => {
    if (dropdownOpen) {
      setOptions(props.options.map(option => ({ ...option, display: "block" })));
      if (props.options.length > 0 && value.length > 0) displaySelectedOptions();
    }
  }, [dropdownOpen]);

  useEffect(() => {
    setUniqueId(getRandomString(18));
  }, []);

  return (
    <UncontrolledDropdown isOpen={dropdownOpen} toggle={toggle} className={style["multi-select-dropdown"]}>
      <DropdownToggle caret tag="a" className={style["rounded"]}>
        <Selection options={options} value={value} {...props} />
      </DropdownToggle>
      <DropdownMenu className={cx(style["shadow"], style["border-0"], style["rounded"])}>
        <div className={cx(style["search"], style["mb-2"], style["px-3"], style["py-2"])}>
          <InputGroup className={style["flex-nowrap"]}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText className={style["bg-white"]}>
                <Icon icon="ui-search" />
              </InputGroupText>
            </InputGroupAddon>
            <RInput
              type="text"
              autoComplete="off"
              id={`search-box-${uniqueId}`}
              onChange={e => searchHandler(e)}
              autoFocus={true}
            />
            <InputGroupAddon
              addonType="append"
              className={cx({ [style["d-none"]]: searchKeyLength === 0 })}
              style={{ cursor: "pointer" }}
              onClick={() => cancelSearch()}>
              <InputGroupText className={style["bg-white"]}>
                <Icon icon="ui-close" />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </div>

        {options.length > 0 && (
          <div
            className={cx(style["mx-3"], {
              [style["d-none"]]: visibleOptions !== options.length,
            })}>
            <div className={cx(style["custom-control"], style["custom-checkbox"], style["pb-1"])}>
              <RInput
                type="checkbox"
                className={style["custom-control-input"]}
                id={`master-selection-${uniqueId}`}
                onChange={e => toggleMasterSelection(e)}
              />
              <Label className={style["custom-control-label"]} htmlFor={`master-selection-${uniqueId}`}>
                Select All
              </Label>
            </div>
            <DropdownItem divider />
          </div>
        )}

        <div className={cx(style["multi-user-list"], style["customScroll"], style["scroll-Y"])}>
          {noOutcome.type && (
            <div className={cx(style["pb-2"], style["px-3"], style["text-muted"])}>{noOutcome.message}</div>
          )}
          {options.length > 0 &&
            options.map((option, i) => (
              <DropdownItem
                tag="div"
                key={i}
                toggle={false}
                className={cx({ [style["d-none"]]: option?.display === "none" })}>
                <Option
                  id={option.id}
                  option={option}
                  index={i}
                  value={value}
                  setValue={setValue}
                  uniqueId={uniqueId}
                  {...props}
                />
              </DropdownItem>
            ))}
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

Selection.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  optionValue: PropTypes.string.isRequired,
  optionTextPrimary: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
};

Option.propTypes = {
  option: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
  uniqueId: PropTypes.string.isRequired,
  optionValue: PropTypes.string.isRequired,
  optionTextPrimary: PropTypes.array.isRequired,
  optionImage: PropTypes.string,
  optionImageNoPreview: PropTypes.string,
  optionTextMuted: PropTypes.array,
};

MultiSelect.propTypes = {
  updateState: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  optionValue: PropTypes.string.isRequired,
  optionTextPrimary: PropTypes.array.isRequired,
  optionImage: PropTypes.string,
  optionImageNoPreview: PropTypes.string,
  optionTextMuted: PropTypes.array,
  defaultValue: PropTypes.array,
  placeholder: PropTypes.string,
  noOptionMessage: PropTypes.string,
  noResultMessage: PropTypes.string,
};

export default MultiSelect;
