import { useCallback, useContext, useEffect, useLayoutEffect, useState } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";
import useSetActivePageBlock from "./useSetActivePageBlock";
import useTextFocusOut from "./useTextFocusOut";

const useBlockActiveOnscroll = (activeBlockId, ignoreNextScrollEvent, isResizing) => {
  let {
    blockNodes,
    pageNodes,
    updateScrollEvent,
    rightContextMenu,
    updateRightContextMenu,
    isDocumentReady,
    isTimeLineViewOpen,
  } = useContext(EditorContext);
  const [intersecting, setIntersecting] = useState();
  const [currentTop, setCurrentTop] = useState(); // Using this state to update top of page block controller. if only set state then controlller scroller will work
  const [isScrolling, setIsScrolling] = useState(false);
  const [elementList, setElementList] = useState([]);
  const [disableAutoScroll, setDisableAutoScroll] = useState(false);
  const scroller = document.getElementById("canvas-panel-area");

  let y = scroller?.scrollTop;
  const offsetTop = 330;
  const offsetBottom = 400;
  let timeoutId;

  // Activate Page Bloc
  useSetActivePageBlock(intersecting, isResizing);
  const { handleFocusOut } = useTextFocusOut();

  const getTargetPageBlock = pageNum => {
    setCurrentTop(y);
    let filteredNode = blockNodes.find(elem => elem.blockId === activeBlockId);

    /**
     * Below this condition "filteredNode.blockIdx + pageNum !== blockNodes.length"
     * To check next target page block is available or not
     * If not available then auto active should not work
     */
    if (filteredNode && filteredNode.blockIdx + pageNum !== blockNodes.length) {
      let activePageIdx = pageNodes?.find(page => page.pageId === filteredNode.pageId)?.pageIdx;
      setIntersecting({
        targetBlockIdx: filteredNode.blockIdx + pageNum < 0 ? 0 : filteredNode.blockIdx + pageNum,
        targetPageIdx: activePageIdx + pageNum < 0 ? 0 : activePageIdx + pageNum,
        disableAutoScroll: true,
      });
    }
  };

  if (ignoreNextScrollEvent) {
    setTimeout(function () {
      updateScrollEvent(false);
    }, 700);
  }

  const isInViewport = elem => {
    const bounding = elem.getBoundingClientRect();
    const topInView = bounding.bottom >= offsetTop && bounding.top <= offsetBottom;
    return topInView;
  };

  const handleNavigation = useCallback(
    event => {
      // Apply scroll active function if only canvas panel area scrolling
      if (event.target !== scroller) return;

      // Focusout triggerd if any text or text frame widget is contenteditable
      if (document.querySelector(".dhp-content-editable-true-text")) handleFocusOut();
      // disable context menu when scroll active
      if (rightContextMenu.enable)
        updateRightContextMenu({
          ...rightContextMenu,
          enable: false,
        });

      if (!activeBlockId) return;

      if (ignoreNextScrollEvent) {
        setDisableAutoScroll(true);
        return;
      }

      let currentRect = document.querySelector(`#${activeBlockId}`)?.getBoundingClientRect();
      if (!currentRect) return;

      if (y > scroller?.scrollTop) {
        if (currentRect.top > offsetTop) {
          getTargetPageBlock(-1); // Set active top page
        }
      } else if (y < scroller?.scrollTop) {
        // previous value 230
        if (currentRect.bottom < offsetBottom) {
          getTargetPageBlock(1); // Set active Bottom page
        }
      }
      y = scroller?.scrollTop;

      // Check is scroll stopped
      setIsScrolling(true);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setIsScrolling(false), 800);
    },
    [y, activeBlockId, ignoreNextScrollEvent]
  );

  /**
   * Below code to check is activeBlockId is present is view port or not
   * If not then check which block is present inview port and activate this block
   */
  useLayoutEffect(() => {
    if (!isScrolling && activeBlockId && !disableAutoScroll) {
      let currentElem = document.getElementById(activeBlockId);

      // Return if this is activeblock is already active
      if (isInViewport(currentElem)) return;

      // To check each element position in the viewport
      elementList.forEach(element => {
        if (isInViewport(element)) {
          let targetBlockId = element?.getAttribute("data-block-idx");
          let targetPageId = element?.parentNode?.getAttribute("data-page-idx");

          if (targetBlockId && targetPageId) {
            setIntersecting({
              targetBlockIdx: targetBlockId,
              targetPageIdx: targetPageId,
              disableAutoScroll: true,
            });
          }
        }
      });
    }
  }, [isScrolling]);

  useEffect(() => {
    if (disableAutoScroll) {
      setTimeout(function () {
        setDisableAutoScroll(false);
      }, 1500);
    }
  }, [disableAutoScroll]);

  // Fetch current page block node list
  useEffect(() => {
    if (isDocumentReady) {
      let elements = document.querySelectorAll(".dhp-page-block");
      setElementList(elements);
    }
  }, [blockNodes, isDocumentReady]);

  useEffect(() => {
    if (!isTimeLineViewOpen) window.addEventListener("scroll", handleNavigation, true);
    return () => {
      window.removeEventListener("scroll", handleNavigation, true);
    };
  }, [handleNavigation, isTimeLineViewOpen]);
};

export default useBlockActiveOnscroll;
