import React from "react";
import { Form as RForm } from "reactstrap";

export const Form = ({ children, ...props }) => {
  return (
    <RForm noValidate className={props.className} {...props}>
      {children}
    </RForm>
  );
};
