import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { DropdownItem, DropdownMenu, DropdownToggle, Input as RInput, UncontrolledDropdown } from "reactstrap";

import global from "../../../scss/dhp.scss";

import AssetName from "./Common/AssetName";
import AssetSchemeTypeNav from "./Common/AssetSchemeTypeNav";
import { Icon } from "../../ui/icon";
import { Input } from "../../ui/input";
import { widgetConfig } from "../editor_config";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { getCssTextObjToString, stringTocamelCase } from "../../../_helpers/utils";

let style = Object.assign({}, global);

const AnimationTypeDropdown = props => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState("");

  let type =
    props.schemeType === "entry"
      ? widgetConfig.widget_animation.entrance_types
      : widgetConfig.widget_animation.exit_types;
  const animationTypeList = type
    .map(availableTransitionType => ({
      ...availableTransitionType,
      options: availableTransitionType?.options?.filter(option =>
        option.name.toLowerCase().includes(searchKeyWord.toLowerCase())
      ),
    }))
    .filter(availableTransitionType => availableTransitionType?.options?.length > 0);

  const toggleTransitionTypeDropdown = () => {
    setDropdownOpen(prevState => !prevState);

    if (dropdownOpen) {
      setSearchKeyWord("");
      return;
    }

    // set dropdown position on selected item
    setTimeout(() => {
      const currentDropdown = document.querySelectorAll(".dropdown-menu-right.show")[0];
      if (currentDropdown) {
        const selectedEl = currentDropdown.querySelectorAll(".active.dropdown-item")[0];
        if (selectedEl) {
          selectedEl.scrollIntoView({ block: "nearest", inline: "start" });
        }
      }
    }, 15);
  };

  return (
    <div className={style["line-controls-wrap"]}>
      <div className={style["slidelabel"]}>Type</div>

      <UncontrolledDropdown
        className={style["border-style"]}
        isOpen={dropdownOpen}
        toggle={toggleTransitionTypeDropdown}>
        <DropdownToggle className={style["text-truncate"]}>
          {props.type}
          <Icon icon="ui-arrow-down" />
        </DropdownToggle>

        <DropdownMenu right className={cx(style["shadow-sm"], style["border-0"], style["rounded"])}>
          <div className={cx(style["search"], style["px-3"], style["pt-2"])}>
            <Input
              cssModule={style}
              returnType="inputGroup"
              addonType="prepend"
              type="search"
              id="search-transition-type"
              name="search-transition-type"
              onChange={e => setSearchKeyWord(e.target.value.trim())}
              autoFocus={true}
            />
          </div>

          <div className={cx(style["customScroll"], style["scroll-Y"])}>
            {animationTypeList.length === 0 && <div className={cx(style["not-found"])}>No results found</div>}

            {animationTypeList.length > 0 &&
              animationTypeList.map((availableAnimationType, i) => (
                <div className={style["mb-2"]} key={i}>
                  <DropdownItem header>{availableAnimationType.groupName}</DropdownItem>

                  {availableAnimationType.options.map(option => (
                    <DropdownItem
                      tag="a"
                      key={option.name}
                      className={cx({
                        [style["active"]]: option.name === props.type,
                      })}
                      onClick={() => {
                        props.setLoadFormatting(false), props.updateType(option.name);
                      }}>
                      {option.name}
                    </DropdownItem>
                  ))}
                </div>
              ))}
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};
//Props type validation
AnimationTypeDropdown.propTypes = {
  type: PropTypes.string.isRequired,
  updateType: PropTypes.func.isRequired,
  setLoadFormatting: PropTypes.func,
  schemeType: PropTypes.string,
};

const AnimationSpeedDropdown = props => {
  return (
    <div className={cx(style["line-controls-wrap"], style["speed"])}>
      <div className={style["slidelabel"]}>Speed</div>

      <UncontrolledDropdown className={style["border-style"]}>
        <DropdownToggle>
          {props.speed}
          <Icon icon="ui-arrow-down" />
        </DropdownToggle>

        <DropdownMenu className={cx(style["shadow-sm"], style["border-0"], style["rounded"])}>
          {widgetConfig.widget_animation.speeds.map((speed, i) => (
            <DropdownItem
              tag="a"
              key={i}
              onClick={() => {
                props.setLoadFormatting(false), props.updateSpeed(speed.name);
              }}
              className={cx(
                {
                  // if already one animation applied then check should be >= with page diration but if none type of animation applied then it should be only > to page duration
                  [style["disabled"]]:
                    (!props.isOneApplied && parseFloat(speed.value) >= props.targetValue) ||
                    (props.isOneApplied && parseFloat(speed.value) > props.targetValue),
                },
                {
                  [style["active"]]: speed.name === props.speed,
                }
              )}>
              {speed.name} <div className={cx(style["text-muted"], style["ml-2"])}>{speed.value} sec</div>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};
//Props type validation
AnimationSpeedDropdown.propTypes = {
  speed: PropTypes.string.isRequired,
  updateSpeed: PropTypes.func.isRequired,
  setLoadFormatting: PropTypes.func,
  targetValue: PropTypes.number,
  isOneApplied: PropTypes.bool,
};

const EntranceAnimation = props => {
  let { metadata, pageNodes, widgets, updateWidgets, animationObj, setAnimationObj, changeBySocket } =
    useContext(EditorContext);

  const [loadFormatting, setLoadFormatting] = useState(true);
  const [isEntranceEnable, setIsEntranceEnable] = useState();
  const [entranceAnimationType, setEntranceAnimationType] = useState();
  const [entranceAnimationSpeed, setEntranceAnimationSpeed] = useState();
  const [showEntranceAnimation, setShowEntranceAnimation] = useState(false);
  const [reducableValue, setReducableValue] = useState();
  const [isExitApplied, setIsExitApplied] = useState();

  const loadCurrentFormatting = () => {
    if (!document.getElementById(metadata.activeWidgetId[0])) return;

    setLoadFormatting(true);
    let sppedLimit = checkSpeedDisableOptionsForEntry();

    // for multiple widget selection
    if (metadata.activeWidgetId.length > 1) {
      let EntranceToggleStatusForMultiSelection = checkAnimationTypeOfMultiWidgets();

      // if all selected widgets have no animation or if they have animation there type is not same
      if (!EntranceToggleStatusForMultiSelection) {
        setIsEntranceEnable(false);
        setEntranceAnimationType(widgetConfig.widget_animation.defaultEntryType);
        setEntranceAnimationSpeed(sppedLimit > 1 ? widgetConfig.widget_animation.defaultSpeed : "Fast");
      }
      // if all selected widgets have same animation type
      else {
        setIsEntranceEnable(true);
        setEntranceAnimationType(
          document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-entrance-animation-type")
        );
        setEntranceAnimationSpeed(sppedLimit > 1 ? widgetConfig.widget_animation.defaultSpeed : "Fast");
      }
    }
    // for single widget / group widget selection / group inner widget selection
    else {
      let isGroupWidget = document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-page-group");
      let targetId = isGroupWidget
        ? document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-root-widget").getAttribute("id")
        : metadata.activeWidgetId[0];
      let activeElem = document.getElementById(targetId);

      // if active widget has animation
      if (activeElem.getAttribute("data-entrance-animation") === "true") {
        setIsEntranceEnable(true);
        setEntranceAnimationType(activeElem.getAttribute("data-entrance-animation-type"));
        setEntranceAnimationSpeed(activeElem.getAttribute("data-entrance-animation-speed"));
      }
      // if active widget has no animation set default
      else {
        setIsEntranceEnable(false);
        setEntranceAnimationType(widgetConfig.widget_animation.defaultEntryType);
        // if already one animation applied then check should be >= with page diration but if none type of animation applied then it should be only > to page duration
        if (!isExitApplied)
          setEntranceAnimationSpeed(sppedLimit > 1 ? widgetConfig.widget_animation.defaultSpeed : "Fast");
        else setEntranceAnimationSpeed(sppedLimit >= 1 ? widgetConfig.widget_animation.defaultSpeed : "Fast");
      }
    }
  };

  const checkAnimationTypeOfMultiWidgets = () => {
    let toggleStatusForMultiSelection;
    let animationType = document
      .getElementById(metadata.activeWidgetId[0])
      .getAttribute("data-entrance-animation-type");
    toggleStatusForMultiSelection = true;

    // if all selected widget have same animation type return true else return false.
    metadata.activeWidgetId.forEach(id => {
      let activeElem = document.getElementById(id);
      if (
        !activeElem.getAttribute("data-entrance-animation") ||
        activeElem.getAttribute("data-entrance-animation") === "false" ||
        activeElem.getAttribute("data-entrance-animation-type") !== animationType
      ) {
        toggleStatusForMultiSelection = false;
        return toggleStatusForMultiSelection;
      }
    });

    return toggleStatusForMultiSelection;
  };

  const checkSpeedDisableOptionsForEntry = () => {
    if (!document.getElementById(metadata.activeWidgetId[0])) return;

    let isGroupWidget = document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-page-group");
    let targetId = isGroupWidget
      ? document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-root-widget").getAttribute("id")
      : metadata.activeWidgetId[0];
    let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
    let ActiveWidgetDuration = widgets[targetWidgetIndex]?.duration?.totalTime
      ? parseFloat(widgets[targetWidgetIndex]?.duration?.totalTime)
      : parseFloat(pageNodes?.[metadata.activePageIdx]?.pageDuration);

    let exitSpeed =
      document.getElementById(targetId).getAttribute("data-exit-animation") === "true"
        ? document.getElementById(targetId).getAttribute("data-exit-animation-speed")
        : false;

    if (exitSpeed) {
      exitSpeed = parseFloat(
        widgetConfig.widget_animation.speeds[
          widgetConfig.widget_animation.speeds.findIndex(speed => speed.name === exitSpeed)
        ].value
      );
      setIsExitApplied(true);
    } else {
      exitSpeed = 0;
      setIsExitApplied(false);
    }

    setReducableValue(ActiveWidgetDuration - exitSpeed);
    return ActiveWidgetDuration - exitSpeed;
  };

  const playEntranceAnimation = () => {
    let transitionSpeed = entranceAnimationSpeed;
    let timeoutValue =
      parseFloat(
        widgetConfig.widget_animation.speeds[
          widgetConfig.widget_animation.speeds.findIndex(speed => speed.name === transitionSpeed)
        ].value
      ) * 1000;

    // set the animation status true
    setShowEntranceAnimation(true);
    setTimeout(() => {
      setShowEntranceAnimation(false);
    }, timeoutValue);
  };

  useEffect(() => {
    if (entranceAnimationType && entranceAnimationSpeed) {
      let classname = stringTocamelCase(entranceAnimationType);

      metadata.activeWidgetId.forEach(id => {
        let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
        let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
        let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
        let element = document.getElementById(targetId);
        let activeWidgetTransform = element.style.transform;

        // modifications during animations
        if (showEntranceAnimation) {
          // prepare child element
          element.classList.add(classname); // Add animation class according to animation type
          element.style.transform = "";
          element.style["animation-duration"] = `${
            widgetConfig.widget_animation.speeds[
              widgetConfig.widget_animation.speeds.findIndex(speed => speed.name === entranceAnimationSpeed)
            ].value
          }s`; // Add animation dureation according to animation speed

          // create wrapper div
          let parent = element.parentNode;
          let wrapper = document.createElement("div");
          wrapper.classList.add("play-animation");
          wrapper.style.cssText = getCssTextObjToString({
            transform: activeWidgetTransform,
            position: "absolute",
            width: element.style.width,
            height: element.style.height,
            "z-index": 50,
            "pointer-events": "none",
          });

          parent.replaceChild(wrapper, element); // set the wrapper as child (instead of the element)
          wrapper.appendChild(element); // set element as child of wrapper
          props.removeHandlerDuringAnimation();
          setAnimationObj({
            ...animationObj,
            animationActive: true,
            animationClassName: classname,
            targetWidgets: metadata.activeWidgetId,
          });
        }
        // remove all modification once animation played
        else {
          if (animationObj.animationActive) {
            element.classList.remove(classname); // remove animation class
            element.style.transform = widgets[targetWidgetIndex].style.transform; // add, removed transform property
            element.parentNode.replaceWith(element); // replace with actual div
            props.addHandlerAfterAnimation();
            setAnimationObj({ ...animationObj, animationActive: false, animationClassName: "", targetWidgets: false });
          }
        }
      });
    }
  }, [showEntranceAnimation]);

  useEffect(() => {
    if (!loadFormatting) {
      let newArray = Object.assign([...widgets]);

      metadata.activeWidgetId.forEach(id => {
        let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
        let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
        let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);

        newArray = Object.assign([...newArray], {
          [targetWidgetIndex]: {
            ...newArray[targetWidgetIndex],
            data: {
              ...newArray[targetWidgetIndex].data,
              "data-entrance-animation": isEntranceEnable,
              "data-entrance-animation-type": entranceAnimationType,
              "data-entrance-animation-speed": entranceAnimationSpeed,
            },
          },
        });
      });

      updateWidgets(newArray);
      isEntranceEnable && playEntranceAnimation();
    }
  }, [isEntranceEnable, entranceAnimationType, entranceAnimationSpeed, loadFormatting]);

  useEffect(() => {
    if (isEntranceEnable) checkSpeedDisableOptionsForEntry();
  }, [isEntranceEnable]);

  useEffect(() => {
    if (metadata.activeWidgetId) loadCurrentFormatting();
  }, [metadata.activeWidgetId]);

  useEffect(() => {
    if (metadata.activeWidgetId) loadCurrentFormatting();
  }, []);

  //update widget animation for collaborative users
  let isGroupWidget = document.getElementById(metadata.activeWidgetId[0])?.closest(".dhp-page-group");
  let targetId = isGroupWidget
    ? document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-root-widget").getAttribute("id")
    : metadata.activeWidgetId[0];
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
  useEffect(() => {
    if (changeBySocket && widgets[targetWidgetIndex]) loadCurrentFormatting();
  }, [changeBySocket, widgets[targetWidgetIndex]]);

  useEffect(() => {
    loadCurrentFormatting();
  }, [widgets[targetWidgetIndex]?.duration]);

  return (
    <>
      <div className={cx(style["custom-control"], style["custom-switch"], style["mb-3"])}>
        <RInput
          type="checkbox"
          className={style["custom-control-input"]}
          defaultChecked={isEntranceEnable}
          id="customSwitchForEntanceAnimation"
          checked={isEntranceEnable}
          onClick={() => {
            setLoadFormatting(false), setIsEntranceEnable(!isEntranceEnable);
          }}
        />

        <label className={style["custom-control-label"]} htmlFor="customSwitchForEntanceAnimation">
          Apply Animation
        </label>
      </div>

      {isEntranceEnable && (
        <>
          <AnimationTypeDropdown
            type={entranceAnimationType}
            updateType={setEntranceAnimationType}
            setLoadFormatting={setLoadFormatting}
            schemeType={"entry"}
          />

          <AnimationSpeedDropdown
            speed={entranceAnimationSpeed}
            updateSpeed={setEntranceAnimationSpeed}
            setLoadFormatting={setLoadFormatting}
            targetValue={reducableValue}
            isOneApplied={isExitApplied}
          />

          <div
            className={cx(style["mt-auto"], style["text-center"], style["fixed-bottom"])}
            onClick={playEntranceAnimation}>
            <span className={cx(style["btn"], style["btn-border"])}>
              <Icon icon="ui-play" additionalclass={"mr-2"} /> Play Animation
            </span>
          </div>
        </>
      )}
    </>
  );
};
//Props type validation
EntranceAnimation.propTypes = {
  removeHandlerDuringAnimation: PropTypes.func,
  addHandlerAfterAnimation: PropTypes.func,
};

const ExitAnimation = props => {
  let { metadata, pageNodes, widgets, updateWidgets, animationObj, setAnimationObj, changeBySocket } =
    useContext(EditorContext);

  const [loadFormatting, setLoadFormatting] = useState(true);
  const [isExitEnable, setIsExitEnable] = useState();
  const [exitAnimationType, setExitAnimationType] = useState();
  const [exitAnimationSpeed, setExitAnimationSpeed] = useState();
  const [showExitAnimation, setShowExitAnimation] = useState(false);
  const [reducableValue, setReducableValue] = useState();
  const [isEntranceApplied, setIsEntranceApplied] = useState();

  const loadCurrentFormatting = () => {
    if (!document.getElementById(metadata.activeWidgetId[0])) return;

    setLoadFormatting(true);
    let sppedLimit = checkSpeedDisableOptionsForExit();

    // for multiple widget selection
    if (metadata.activeWidgetId.length > 1) {
      let ExitToggleStatusForMultiSelection = checkAnimationTypeOfMultiWidgets();

      // if all selected widgets have no animation or if they have animation there type is not same
      if (!ExitToggleStatusForMultiSelection) {
        setIsExitEnable(false);
        setExitAnimationType(widgetConfig.widget_animation.defaultExitType);
        setExitAnimationSpeed(sppedLimit > 1 ? widgetConfig.widget_animation.defaultSpeed : "Fast");
      }
      // if all selected widgets have same animation type
      else {
        setIsExitEnable(true);
        setExitAnimationType(
          document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-exit-animation-type")
        );
        setExitAnimationSpeed(sppedLimit > 1 ? widgetConfig.widget_animation.defaultSpeed : "Fast");
      }
    }
    // for single widget / group widget selection / group inner widget selection
    else {
      let isGroupWidget = document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-page-group");
      let targetId = isGroupWidget
        ? document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-root-widget").getAttribute("id")
        : metadata.activeWidgetId[0];
      let activeElem = document.getElementById(targetId);

      // if active widget has animation
      if (activeElem.getAttribute("data-exit-animation") === "true") {
        setIsExitEnable(true);
        setExitAnimationType(activeElem.getAttribute("data-exit-animation-type"));
        setExitAnimationSpeed(activeElem.getAttribute("data-exit-animation-speed"));
      }
      // if active widget has no animation set default
      else {
        setIsExitEnable(false);
        setExitAnimationType(widgetConfig.widget_animation.defaultExitType);
        // if already one animation applied then check should be >= with page diration but if none type of animation applied then it should be only > to page duration
        if (!isEntranceApplied)
          setExitAnimationSpeed(sppedLimit > 1 ? widgetConfig.widget_animation.defaultSpeed : "Fast");
        else setExitAnimationSpeed(sppedLimit >= 1 ? widgetConfig.widget_animation.defaultSpeed : "Fast");
      }
    }
  };

  const checkAnimationTypeOfMultiWidgets = () => {
    let toggleStatusForMultiSelection;
    let animationType = document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-exit-animation-type");
    toggleStatusForMultiSelection = true;

    // if all selected widget have same type animation then return true else return false.
    metadata.activeWidgetId.forEach(id => {
      let activeElem = document.getElementById(id);
      if (
        !activeElem.getAttribute("data-exit-animation") ||
        activeElem.getAttribute("data-exit-animation") === "false" ||
        activeElem.getAttribute("data-exit-animation-type") !== animationType
      ) {
        toggleStatusForMultiSelection = false;
        return toggleStatusForMultiSelection;
      }
    });

    return toggleStatusForMultiSelection;
  };

  const checkSpeedDisableOptionsForExit = () => {
    if (!document.getElementById(metadata.activeWidgetId[0])) return;

    let isGroupWidget = document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-page-group");
    let targetId = isGroupWidget
      ? document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-root-widget").getAttribute("id")
      : metadata.activeWidgetId[0];
    let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
    let ActiveWidgetDuration = widgets[targetWidgetIndex]?.duration?.totalTime
      ? parseFloat(widgets[targetWidgetIndex]?.duration?.totalTime)
      : parseFloat(pageNodes?.[metadata.activePageIdx]?.pageDuration);

    let entrySpeed =
      document.getElementById(targetId).getAttribute("data-entrance-animation") === "true"
        ? document.getElementById(targetId).getAttribute("data-entrance-animation-speed")
        : false;

    if (entrySpeed) {
      entrySpeed = parseFloat(
        widgetConfig.widget_animation.speeds[
          widgetConfig.widget_animation.speeds.findIndex(speed => speed.name === entrySpeed)
        ].value
      );
      setIsEntranceApplied(true);
    } else {
      entrySpeed = 0;
      setIsEntranceApplied(false);
    }

    setReducableValue(ActiveWidgetDuration - entrySpeed);
    return ActiveWidgetDuration - entrySpeed;
  };

  const playExitAnimation = () => {
    let transitionSpeed = exitAnimationSpeed;
    let timeoutValue =
      parseFloat(
        widgetConfig.widget_animation.speeds[
          widgetConfig.widget_animation.speeds.findIndex(speed => speed.name === transitionSpeed)
        ].value
      ) * 1000;

    // set the animation status true
    setShowExitAnimation(true);
    setTimeout(() => {
      setShowExitAnimation(false);
    }, timeoutValue);
  };

  useEffect(() => {
    if (exitAnimationType && exitAnimationSpeed) {
      let classname = stringTocamelCase(exitAnimationType);

      metadata.activeWidgetId.forEach(id => {
        let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
        let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
        let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
        let element = document.getElementById(targetId);
        let activeWidgetTransform = element.style.transform;

        // modifications during animations
        if (showExitAnimation) {
          // prepare child element
          element.classList.add(classname); // Add animation class according to animation type
          element.style.transform = "";
          element.style["animation-duration"] = `${
            widgetConfig.widget_animation.speeds[
              widgetConfig.widget_animation.speeds.findIndex(speed => speed.name === exitAnimationSpeed)
            ].value
          }s`; // Add animation dureation according to animation speed

          // create wrapper div
          let parent = element.parentNode;
          let wrapper = document.createElement("div");
          wrapper.classList.add("play-animation");
          wrapper.style.cssText = getCssTextObjToString({
            transform: activeWidgetTransform,
            position: "absolute",
            width: element.style.width,
            height: element.style.height,
            "z-index": 50,
            "pointer-events": "none",
          });

          parent.replaceChild(wrapper, element); // set the wrapper as child (instead of the element)
          wrapper.appendChild(element); // set element as child of wrapper
          props.removeHandlerDuringAnimation();
          setAnimationObj({
            ...animationObj,
            animationActive: true,
            animationClassName: classname,
            targetWidgets: metadata.activeWidgetId,
          });
        }
        // remove all modification once animation played
        else {
          if (animationObj.animationActive) {
            element.classList.remove(classname); // remove animation class
            element.style.transform = widgets[targetWidgetIndex].style.transform; // add, removed transform property
            element.parentNode.replaceWith(element); // replace with actual div
            props.addHandlerAfterAnimation();
            setAnimationObj({ ...animationObj, animationActive: false, animationClassName: "", targetWidgets: false });
          }
        }
      });
    }
  }, [showExitAnimation]);

  useEffect(() => {
    if (!loadFormatting) {
      let newArray = Object.assign([...widgets]);

      metadata.activeWidgetId.forEach(id => {
        let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
        let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
        let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);

        newArray = Object.assign([...newArray], {
          [targetWidgetIndex]: {
            ...newArray[targetWidgetIndex],
            data: {
              ...newArray[targetWidgetIndex].data,
              "data-exit-animation": isExitEnable,
              "data-exit-animation-type": exitAnimationType,
              "data-exit-animation-speed": exitAnimationSpeed,
            },
          },
        });
      });

      updateWidgets(newArray);
      isExitEnable && playExitAnimation();
    }
  }, [isExitEnable, exitAnimationType, exitAnimationSpeed, loadFormatting]);

  useEffect(() => {
    if (isExitEnable) checkSpeedDisableOptionsForExit();
  }, [isExitEnable]);

  useEffect(() => {
    if (metadata.activeWidgetId) loadCurrentFormatting();
  }, [metadata.activeWidgetId]);

  useEffect(() => {
    if (metadata.activeWidgetId) loadCurrentFormatting();
  }, []);

  //update widget animation for collaborative users
  let isGroupWidget = document.getElementById(metadata.activeWidgetId[0])?.closest(".dhp-page-group");
  let targetId = isGroupWidget
    ? document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-root-widget").getAttribute("id")
    : metadata.activeWidgetId[0];
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
  useEffect(() => {
    if (changeBySocket && widgets[targetWidgetIndex]) loadCurrentFormatting();
  }, [changeBySocket, widgets[targetWidgetIndex]]);

  useEffect(() => {
    loadCurrentFormatting();
  }, [widgets[targetWidgetIndex]?.duration]);

  return (
    <>
      <div className={cx(style["custom-control"], style["custom-switch"], style["mb-3"])}>
        <RInput
          type="checkbox"
          className={style["custom-control-input"]}
          defaultChecked={isExitEnable}
          id="customSwitchForExitAnimation"
          checked={isExitEnable}
          onClick={() => {
            setLoadFormatting(false), setIsExitEnable(!isExitEnable);
          }}
        />

        <label className={style["custom-control-label"]} htmlFor="customSwitchForExitAnimation">
          Apply Animation
        </label>
      </div>

      {isExitEnable && (
        <>
          <AnimationTypeDropdown
            type={exitAnimationType}
            updateType={setExitAnimationType}
            setLoadFormatting={setLoadFormatting}
            schemeType={"exit"}
          />

          <AnimationSpeedDropdown
            speed={exitAnimationSpeed}
            updateSpeed={setExitAnimationSpeed}
            setLoadFormatting={setLoadFormatting}
            targetValue={reducableValue}
            isOneApplied={isEntranceApplied}
          />

          <div
            className={cx(style["mt-auto"], style["text-center"], style["fixed-bottom"])}
            onClick={playExitAnimation}>
            <span className={cx(style["btn"], style["btn-border"])}>
              <Icon icon="ui-play" additionalclass={"mr-2"} /> Play Animation
            </span>
          </div>
        </>
      )}
    </>
  );
};
//Props type validation
ExitAnimation.propTypes = {
  removeHandlerDuringAnimation: PropTypes.func,
  addHandlerAfterAnimation: PropTypes.func,
};

const WidgetAnimation = props => {
  let { metadata, widgets, animationObj, setAnimationObj } = useContext(EditorContext);

  const animationSchemes = ["entrance", "exit"];

  const [schemeType, setSchemeType] = useState(props.assetSchemeType ? props.assetSchemeType : "entrance");

  const removeHandlerDuringAnimation = () => {
    document.getElementById("dhp-widget-handler").style.display = "none"; // hide widget handler
    if (document.querySelector(".child-selected"))
      document.querySelector(".child-selected").classList.add("widget-animate"); // hide group widget handler
    document.querySelectorAll(".group-selected").forEach(element => {
      element.classList.add("widget-animate"); // hide multi select widget handler
    });
    // keep collaborative user's selection
    let collaborativeSelction = [];
    document.querySelectorAll(".collborative-selector").forEach(selection => {
      collaborativeSelction.push({ id: selection.id, style: selection.style.cssText });
      selection.style.display = "none";
    });
    sessionStorage.setItem("collaborativeSelction", JSON.stringify(collaborativeSelction));
  };

  const addHandlerAfterAnimation = () => {
    document.getElementById("dhp-widget-handler").style.display = "block"; // show widget handler
    if (document.querySelector(".child-selected"))
      document.querySelector(".child-selected").classList.remove("widget-animate"); // show group widget handler
    document.querySelectorAll(".group-selected").forEach(element => {
      element.classList.remove("widget-animate"); // show multi select widget handler
    });
    // apply & remove collaborative user's selection
    let collaborativeSelction = sessionStorage.getItem("collaborativeSelction");
    if (collaborativeSelction) {
      collaborativeSelction = JSON.parse(collaborativeSelction);
      collaborativeSelction.forEach(selection => {
        let domSelection = document.getElementById(selection.id);
        domSelection.style.cssText = selection.style;
        domSelection.style.display = "";
      });
      sessionStorage.removeItem("collaborativeSelction");
    }
    // when forcefully animation is stoped
    if (document.querySelector(".widget-animate")) {
      document.querySelectorAll(".widget-animate").forEach(element => {
        element.classList.remove("widget-animate");
      });
    }
  };

  const removeAnimationWrapper = () => {
    if (animationObj.animationActive) {
      animationObj.targetWidgets.forEach(id => {
        let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
        let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
        let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
        let element = document.getElementById(targetId);
        if (element.parentNode.classList.contains("play-animation")) {
          element.classList.remove(animationObj.animationClassName); // remove animation class
          element.style.transform = widgets[targetWidgetIndex].style.transform; // add, removed transform property
          element.parentNode.replaceWith(element); // replace with actual div
          addHandlerAfterAnimation();
        }
      });
      setAnimationObj({ animationActive: false, animationClassName: "", targetWidgets: false });
    }
  };

  useEffect(() => {
    if (props.assetSchemeType) setSchemeType(props.assetSchemeType); // set scheme type when widget animation is open from page timeline section
  }, [props.assetSchemeType, metadata.activeWidgetId]);

  useEffect(() => {
    if (!metadata.activeWidgetId) props.handleWidgetAction("*", false, false, false, "returnToWidgetList");
  }, [metadata.activeWidgetId]);

  useEffect(() => {
    window.addEventListener("keydown", removeAnimationWrapper);
    window.addEventListener("mousedown", removeAnimationWrapper);
    window.addEventListener("scroll", removeAnimationWrapper);

    return () => {
      window.removeEventListener("keydown", removeAnimationWrapper);
      window.removeEventListener("mousedown", removeAnimationWrapper);
      window.removeEventListener("scroll", removeAnimationWrapper);
    };
  });

  return (
    <div className={cx(style["editor-asset-inner"], style["widgetanimation-asset"])}>
      <AssetName handleWidgetAction={props.handleWidgetAction} assetName={props.assetName} />
      <div className={style["pr-20"]}>
        <AssetSchemeTypeNav
          schemeTypeArray={animationSchemes}
          setSchemeType={setSchemeType}
          schemeType={schemeType}
          assetType={props.assetType}
          activeTabIndex={schemeType === "exit" ? 1 : 0}
        />

        {schemeType === "entrance" && (
          <EntranceAnimation
            removeHandlerDuringAnimation={removeHandlerDuringAnimation}
            addHandlerAfterAnimation={addHandlerAfterAnimation}
          />
        )}

        {schemeType === "exit" && (
          <ExitAnimation
            removeHandlerDuringAnimation={removeHandlerDuringAnimation}
            addHandlerAfterAnimation={addHandlerAfterAnimation}
          />
        )}
      </div>
    </div>
  );
};
//Props type validation
WidgetAnimation.propTypes = {
  handleWidgetAction: PropTypes.func.isRequired,
  assetName: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
  assetSchemeType: PropTypes.string,
};

export default WidgetAnimation;
