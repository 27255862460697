import { useContext } from "react";
import { getCssTransformObj } from "../_helpers/utils";
import { EditorContext } from "../containers/editor/EditorLayout";
import UseCheckWidgetAllignment from "./UseCheckWidgetAllignment";
import useDeleteWidget from "./useDeleteWidget";
import useCheckWidgetPosition from "./useCheckWidgetPosition";
const useTableResize = () => {
  let { widgets, updateWidgets } = useContext(EditorContext);

  const { checkWidgetAllignmentForSingleWidget } = UseCheckWidgetAllignment();
  const deleteWidgetIfOutside = useCheckWidgetPosition();
  const setDeleteWidget = useDeleteWidget();

  const tableResizeStart = data => {
    let curInnerWidget = document.querySelector(`#${data.client.id} .dhp-widget-inner`);
    let { scale } = getCssTransformObj({
      transform: curInnerWidget.style.transform,
    });
    curInnerWidget.style.height = `${parseFloat(data.client.node.style.height) / scale.x}px`;
    curInnerWidget.style.width = `${parseFloat(data.client.node.style.width) / scale.x}px`;
  };

  const tableResize = (meta, widgetWidth) => {
    let curInnerWidget = document.querySelector(`#${meta.client.id} .dhp-widget-inner`);
    curInnerWidget.style.transform = `scale(${widgetWidth / parseFloat(curInnerWidget.style.width)})`;
  };

  const tableResizeStop = meta => {
    let updatedCss = meta.client.current;
    let { x_al, y_al } = checkWidgetAllignmentForSingleWidget(
      updatedCss.height,
      updatedCss.transform,
      updatedCss.width
    );
    let isDletable = deleteWidgetIfOutside(meta.client.id);

    // Check widget position during resize, if it is outside canvas area delete the widget else upadte new position
    if (isDletable) setDeleteWidget(true);
    else {
      updateWidgets(
        widgets.map((widget, idx) => {
          if (idx === meta.client.idx) {
            return {
              ...widget,
              style: {
                ...widget.style,
                ...updatedCss,
              },
              data: {
                ...widget.data,
                "data-x-allignment": x_al,
                "data-y-allignment": y_al,
              },
              innerHTML: document.getElementById(meta.client.id).innerHTML,
            };
          } else {
            return widget;
          }
        })
      );
    }
  };
  return [tableResizeStart, tableResize, tableResizeStop];
};
export default useTableResize;
