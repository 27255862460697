import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, Label, Input as RInput, Button } from "reactstrap";
import cx from "classnames";
import { useSelector } from "react-redux";
import { TEMPLATE_TYPE_PRESENTATION } from "../../constants/pageSizeModal";
import { getRandomString } from "../../_helpers/utils";
import { Icon } from "./icon";

import global from "../../scss/dhp.scss";
import CanvasPageHtmlGenaretor from "../Editor/CanvasPageHtmlGenaretor";
import { EditorContext } from "../../containers/editor/EditorLayout";
import useTextFocusOut from "../../hooks/useTextFocusOut";

let style = Object.assign({}, global);

const _helpers = {
  concat: (selectedValues, options, type) => {
    let textToDisplay = type === TEMPLATE_TYPE_PRESENTATION ? "Slide" : "Page";
    if (options.length === selectedValues.length) return `All ${textToDisplay}s`;
    let values = selectedValues.sort((a, b) => {
      return a - b;
    });
    values.forEach(value => (textToDisplay += ` ${value},`));
    return textToDisplay.replace(/,\s*$/, "");
  },
};

const Selection = ({ options, value, ...props }) => {
  const [selectedValue, setSelectedValue] = useState(false);
  const [placeholder, setPlaceholder] = useState(props.origin === "ai-translate" ? props?.placeholder : "Choose Options");

  useEffect(() => {
    if (value.length > 0) {
      setSelectedValue(_helpers.concat(value, options, props?.type));
    } else setSelectedValue(false);
  }, [value]);

  useEffect(() => {
    if (props?.placeholder) setPlaceholder(props.placeholder);
  }, [props?.placeholder]);

  return (
    <div className={cx(style["align-items-center"], style["d-flex"], style["justify-content-between"], style["w-100"])}>
      <h6 className={cx(style["mb-0"], style["text-truncate"])}>{selectedValue ? selectedValue : placeholder}</h6>
      <div className={cx(style["align-items-center"], style["d-flex"], style["pl-3"])}>
        <Icon icon="ui-arrow-down" />
      </div>
    </div>
  );
};

const Option = ({ option, index, ...props }) => {
  const changeHandler = e => {
    let targetValue = parseInt(e.target.getAttribute("data-record-id"));
    props.setValue(e.target.checked ? [...props.value, targetValue] : props.value.filter(v => v !== targetValue));
    let notSelectedValues = document.querySelectorAll(`*[id^="item-selection"]:not(:checked)`);
    let selectedValues = document.querySelectorAll(`*[id^="item-selection"]:checked`);
    if (selectedValues.length >= props.maxLimit) notSelectedValues.forEach(x => x.setAttribute("disabled", true));
    else notSelectedValues.forEach(x => x.removeAttribute("disabled"));
  };

  useEffect(() => {
    if (option?.html) {
      let newNode = document.createElement("div");
      newNode.innerHTML = option?.html;

      // add class if text has background color
      newNode.querySelectorAll(".primary-text-widget").forEach(element => {
        if (element.style.backgroundColor !== "transparent") {
          element.classList.add("text-background-active");
        }
      });

      option.html = newNode.innerHTML;
    }
  });

  return (
    <div className={cx(style["custom-control"], style["custom-checkbox"])}>
      <RInput
        type="checkbox"
        id={`item-selection-${props.uniqueId}-${index}`}
        className={style["custom-control-input"]}
        data-record-id={option[props.optionValue]}
        onChange={e => changeHandler(e)}
      />
      <Label
        htmlFor={`item-selection-${props.uniqueId}-${index}`}
        className={cx(style["align-items-center"], style["d-flex"], style["custom-control-label"])}>
        {option?.page && (
          <div className={cx(style["pages"], style["mr-3"], style["ml-1"])}>
            <CanvasPageHtmlGenaretor figureWidth={60} pageNode={option?.page} docPagesMeta={props?.docPagesMeta} />
          </div>
        )}
        {option?.html && (
          <div className={cx(style["pages"], style["mr-3"], style["ml-1"])}>
            <figure className={style["m-0"]} dangerouslySetInnerHTML={{ __html: option?.html }}></figure>
          </div>
        )}

        <div className={cx(style["font-14"])}>
          {props.type === TEMPLATE_TYPE_PRESENTATION ? "Slide" : "Page"} {option.id}
        </div>
      </Label>
    </div>
  );
};

const MultiSelectPages = ({ updateState, options, ...props }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [value, setValue] = useState([]);
  const [uniqueId, setUniqueId] = useState();
  const { metadata } = useContext(EditorContext)
  useTextFocusOut(true);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const {
    documentDetails,
    documentDetails: {
      data: {
        template_info: { type: type },
      },
    } = { data: {} },
  } = useSelector(state => state.document) ?? {};
  const documentType = type?.toLowerCase();
  const { height, width } = documentDetails?.data?.version?.rawdata?.metadata?.dimension ?? {
    height: parseInt(options[0].page.style.height),
    width: parseInt(options[0].page.style.width),
  };

  const maxLimit = props?.maxLimit || 100;
  const toggleMasterSelection = e => {
    if (options.length > 0) {
      let items = document.querySelectorAll(`*[id^="item-selection-${uniqueId}"]`);
      [...items].forEach(item => (item.checked = e.target.checked));
      setValue(e.target.checked ? options.map(option => option[props.optionValue]) : []);
    }
  };

  const updateSelectedPages = () => {
    updateState(value);
    setDropdownOpen(!dropdownOpen);
  };

  const resizePage = () => {
    let screenWidth = 60;
    let scaledval = screenWidth / width;

    document.querySelectorAll("figure")?.forEach(fig => {
      fig.style.transform = `scale(${scaledval}) translateX(0px)`;
      fig.style.transformOrigin = "left top";
      fig.style.height = `${height * scaledval}px`;
    });
  };

  const setValueForAiTranslate = () => {
    let newValue = Array.from(Array(options.length).keys(), n => n + 1);
    setValue(newValue)
  }

  useEffect(() => {
    if (options.length > 0 && options.length <= maxLimit && document.getElementById(`master-selection-${uniqueId}`))
      document.getElementById(`master-selection-${uniqueId}`).checked = value.length === options.length;
    updateState(value);
  }, [options, value]);

  useEffect(() => {
    if (dropdownOpen) {
      if (!props.isContextData) {
        resizePage();
      }
      if (value.length > 0) {
        let items = document.querySelectorAll(`*[id^="item-selection-${uniqueId}"]`);
        [...items].forEach(item => (item.checked = value.includes(parseInt(item.getAttribute("data-record-id")))));
      }
      if (options.length > 0 && options.length <= maxLimit) {
        document.getElementById(`master-selection-${uniqueId}`).checked = value.length === options.length;
      }
      if (value.length >= maxLimit) {
        let notSelectedValues = document.querySelectorAll(`*[id^="item-selection"]:not(:checked)`);
        notSelectedValues.forEach(x => x.setAttribute("disabled", true));
      }
      updateState(value);
    }
  }, [dropdownOpen]);


  useEffect(() => {
    if (props.origin === "ai-translate" && metadata?.activePageIdx >= 0 && (value?.length <= 1 && !value.includes(metadata?.activePageIdx + 1))) {
      setValue([metadata?.activePageIdx + 1]);
    }
  }, [props?.origin, metadata?.activePageIdx])


  useEffect(() => {
    if (props.origin === "ai-translate" && ((value?.length === options.length - 1) || (value?.length - 1 === options.length))) {
      setValueForAiTranslate();
    }
  }, [props?.origin, options.length])

  useEffect(() => {
    setUniqueId(getRandomString(18));
    if (maxLimit >= options.length && props?.origin !== "ai-translate") {
      setValueForAiTranslate();
    }
    if (props?.origin === "ai-translate") {
      setValue([metadata?.activePageIdx + 1]);
    }
  }, []);

  return (
    <UncontrolledDropdown isOpen={dropdownOpen} toggle={toggle} className={style["multi-select-dropdown"]}>
      <DropdownToggle caret tag="a">
        <Selection options={options} value={value} type={documentType} {...props} />
      </DropdownToggle>
      <DropdownMenu className={cx(style["shadow"], style["border-0"], style["rounded"])}>
        {options.length > 0 && options.length <= maxLimit && (
          <div className={cx(style["mx-3"])}>
            <div className={cx(style["custom-control"], style["custom-checkbox"], style["pb-1"])}>
              <RInput
                type="checkbox"
                className={style["custom-control-input"]}
                id={`master-selection-${uniqueId}`}
                onChange={e => toggleMasterSelection(e)}
              />
              <Label className={style["custom-control-label"]} htmlFor={`master-selection-${uniqueId}`}>
                All {documentType === TEMPLATE_TYPE_PRESENTATION ? "Slides" : "Pages"}
              </Label>
            </div>
          </div>
        )}

        <div className={cx(style["customScroll"], style["scroll-Y"], style["mt-2"], style["mb-3"])}>
          <ul>
            {options.length > 0 &&
              options.map((option, i) => (
                <li className={cx(style["px-3"], style["mb-3"])} key={i}>
                  <Option
                    option={option}
                    index={i}
                    value={value}
                    setValue={setValue}
                    uniqueId={uniqueId}
                    type={documentType}
                    {...props}
                  />
                </li>
              ))}
          </ul>
        </div>

        <div className={style["px-3"]}>
          <Button
            color="primary"
            className={cx(style["btn-block"], style["my-2"])}
            disabled={value.length <= 0}
            onClick={updateSelectedPages}>
            Done
          </Button>
          {value.length >= maxLimit && (
            <div className={style["mc-status-msg"]}>
              Max {maxLimit} {documentType === TEMPLATE_TYPE_PRESENTATION ? "slides" : "pages"} allowed
            </div>
          )}
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

Selection.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
};

Option.propTypes = {
  option: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
  uniqueId: PropTypes.string,
  optionValue: PropTypes.string.isRequired,
  type: PropTypes.string,
  maxLimit: PropTypes.number,
  docPagesMeta: PropTypes.object
};

MultiSelectPages.propTypes = {
  updateState: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  optionValue: PropTypes.string.isRequired,
  maxLimit: PropTypes.number,
  isContextData: PropTypes.bool,
  origin: PropTypes.string,
};

export default MultiSelectPages;
