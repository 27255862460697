import { useContext } from "react";

import { EditorContext } from "../containers/editor/EditorLayout";
import * as constant from "../constants/editor";

const useControllerToSection = () => {
  let { documentType, metadata, updateMetadata, dimension, isTimeLineViewOpen } = useContext(EditorContext);

  const controllerToSection = () => {
    if (metadata.activeBlockId) {
      let activeControllerTop;
      let currentZoom = dimension.zoom / 100;
      if (constant.TYPE_INFOGRAPHIC === documentType) {
        activeControllerTop =
          metadata.activeBlockIdx === 0 ? 0 : document.getElementById(metadata.activeBlockId).offsetTop * currentZoom;
      } else if (isTimeLineViewOpen) {
        activeControllerTop = 0;
      } else {
        activeControllerTop =
          metadata.activeBlockIdx === 0 ? 0 : document.getElementById(metadata.activePageId).offsetTop;
      }

      updateMetadata({
        ...metadata,
        pageController: {
          ...metadata.pageController,
          style: { ...metadata.pageController.style, top: activeControllerTop + "px" },
        },
      });
    }
  };

  return { controllerToSection };
};
export default useControllerToSection;
