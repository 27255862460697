import {
  ASSET_FETCH_FAILED,
  DELETE_ACCOUNT_FAILED,
  DELETE_ACCOUNT_START,
  DELETE_ACCOUNT_SUCCESS,
  FETCH_ALL_USER_LIST_FAILED,
  FETCH_ALL_USER_LIST_START,
  FETCH_ALL_USER_LIST_SUCCESS,
  FETCH_MANAGER_ASSET_CATEGORY_SUCCESS,
  FETCH_MANAGER_USERS_FAILED,
  FETCH_MANAGER_USERS_START,
  FETCH_MANAGER_USERS_SUCCESS,
  MANAGER_ASSET_FETCH_START,
  MANAGER_ASSET_FETCH_SUCCESS,
  MANAGER_ASSET_UPDATE_START,
  MANAGER_ASSET_UPDATE_SUCCESS,
  MANAGER_ASSET_UPLOAD_FAILED,
  MANAGER_ASSET_UPLOAD_START,
  MANAGER_ASSET_UPLOAD_SUCCESS,
  DOWNGRADE_ACCOUNT_START,
  DOWNGRADE_ACCOUNT_SUCCESS,
  DOWNGRADE_ACCOUNT_FAILED,
  MANAGER_BULK_ASSET_UPLOAD_START,
  MANAGER_BULK_ASSET_UPLOAD_SUCCESS,
  MANAGER_BULK_ASSET_UPLOAD_FAILED,
  GET_PRO_ONCE_DEALS_START,
  GET_PRO_ONCE_DEALS_SUCCESS,
  GET_PRO_ONCE_DEALS_FAILED,
  UPDATE_PROMO_CODE_STATUS_START,
  UPDATE_PROMO_CODE_STATUS_SUCCESS,
  UPDATE_PROMO_CODE_STATUS_FAILED,
} from "../actions/actionTypes";

export const managerReducer = (originalState = {}, action) => {
  const state = {
    ...originalState,
    type: action.type,
    flashMessage: null,
    status: action?.error?.response?.status ?? null,
  };

  switch (action.type) {
    case MANAGER_ASSET_FETCH_START:
      return { ...state, loading: true };

    case MANAGER_ASSET_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        assetList: action.assetLists.data,
        total: action.assetLists.total_count,
        type: action.assetLists.asset_type,
        flashMessage: null,
      };

    case ASSET_FETCH_FAILED:
      return { ...state, loading: false };

    case MANAGER_ASSET_UPLOAD_START:
      return { ...state, loading: true, uploaded: null };

    case MANAGER_ASSET_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        uploaded: true,
        assetList: null,
        flashMessage: { type: "success", message: "Asset successfully uploaded" },
      };

    case MANAGER_ASSET_UPDATE_START:
      return { ...state, loading: action.reload ? true : false, uploaded: null };

    case MANAGER_ASSET_UPDATE_SUCCESS:
      if (action.reload) {
        return {
          ...state,
          loading: false,
          uploaded: true,
          assetList: null,
          flashMessage: { type: "success", message: "Asset successfully updated" },
        };
      }
      return {
        ...state,
        loading: false,
        uploaded: true,
        flashMessage: { type: "success", message: "Asset successfully updated" },
      };

    case MANAGER_ASSET_UPLOAD_FAILED:
      return { ...state, loading: false };

    case FETCH_MANAGER_ASSET_CATEGORY_SUCCESS:
      return { ...state, categories: { ...state.categories, [action.data.asset_type]: action.data.data } };

    case DELETE_ACCOUNT_START:
      return { ...state, loading: true, deleted: null };

    case DELETE_ACCOUNT_SUCCESS:
      return { ...state, loading: false, deleted: true };

    case DELETE_ACCOUNT_FAILED:
      return { ...state, loading: false, deleted: false };

    case DOWNGRADE_ACCOUNT_START:
      return { ...state, loading: true, downgraded: null };

    case DOWNGRADE_ACCOUNT_SUCCESS:
      return { ...state, loading: false, downgraded: true };

    case DOWNGRADE_ACCOUNT_FAILED:
      return { ...state, loading: false, downgraded: false };

    case FETCH_MANAGER_USERS_START:
      return { ...state, loading: true };

    case FETCH_MANAGER_USERS_SUCCESS:
      return { ...state, loading: false, ...action.data };

    case FETCH_MANAGER_USERS_FAILED:
      return { ...state, loading: false };

    case FETCH_ALL_USER_LIST_START:
      return { ...state, fetchLoading: true };

    case FETCH_ALL_USER_LIST_SUCCESS:
      return { ...state, fetchLoading: false, allUserDatas: action.data.users };

    case FETCH_ALL_USER_LIST_FAILED:
      return { ...state, fetchLoading: false };

    case MANAGER_BULK_ASSET_UPLOAD_START:
      return { ...state, loading: true, bulkUploaded: null };

    case MANAGER_BULK_ASSET_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        bulkUploaded: true,
        assetList: null,
        flashMessage: { type: "success", message: "Asset successfully uploaded" },
      };
    case MANAGER_BULK_ASSET_UPLOAD_FAILED:
      return { ...state, loading: false };
    
    case GET_PRO_ONCE_DEALS_START:
      return { ...state, loading: true };

    case GET_PRO_ONCE_DEALS_SUCCESS:
      return { ...state, loading: false, ...action.data };

    case GET_PRO_ONCE_DEALS_FAILED:
      return { ...state, loading: false };
    
    case UPDATE_PROMO_CODE_STATUS_START:
      return { ...state, loading: true };

    case UPDATE_PROMO_CODE_STATUS_SUCCESS:
      return { ...state, loading: false, promoData: action.data };

    case UPDATE_PROMO_CODE_STATUS_FAILED:
      return { ...state, loading: false, promoData: action.error.response.data };

    default:
      return state;
  }
};
