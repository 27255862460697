import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { DropdownItem, TabPane } from "reactstrap";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import useAlignment from "../../../hooks/useAlignment";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { checkChildOutsideParent, getCssTransformObj, getCssTransformObjToString } from "../../../_helpers/utils";
import UseCheckWidgetAllignment from "../../../hooks/UseCheckWidgetAllignment";
import useSelectable from "../../../hooks/useSelectable";
import useDeleteWidget from "../../../hooks/useDeleteWidget";

let style = Object.assign({}, global);

const Align = props => {
  let { metadata, widgets, updateWidgets, dimension } = useContext(EditorContext);

  let allignmentArray = ["top", "left", "middle", "center", "bottom", "right"];
  let activeid = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(activeid)?.closest(".dhp-page-group");
  let targetId = isGroupWidget
    ? document.getElementById(activeid)?.closest(".dhp-root-widget").getAttribute("id")
    : activeid;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
  let widgetWidth = parseFloat(widgets[targetWidgetIndex]?.style.width);
  let widgetHeight = parseFloat(widgets[targetWidgetIndex]?.style.height);

  const [isAllignedClicked, setIsAllignedClicked] = useState();
  const [allignment, setAllignment] = useState();
  const [verticalAllignment, setVerticalAllignment] = useState(widgets[targetWidgetIndex]?.data["data-y-allignment"]);
  const [horaizentalAllignment, setHoraizentalAllignment] = useState(
    widgets[targetWidgetIndex]?.data["data-x-allignment"]
  );
  const [hStatusArray, setHStatusArray] = useState();
  const [vStatusArray, setVStatusArray] = useState();
  const [mStatusArray, setMStatusArray] = useState();

  const { postion: getPosition, excludeId } = useAlignment(isAllignedClicked, widgetWidth, widgetHeight, allignment);
  const { checkWidgetAllignmentForMultiSelectWidget } = UseCheckWidgetAllignment();
  const setDeleteWidget = useDeleteWidget();

  //Set allignment value
  const handleAllignment = value => {
    props.setDropdownOpen(false);
    setIsAllignedClicked(true);
    setAllignment(value);

    if (metadata.activeWidgetId.length === 1) {
      if (value === "left" || value === "center" || value === "right") {
        setHoraizentalAllignment(value);
        setVerticalAllignment(widgets[targetWidgetIndex].data["data-y-allignment"]); //Need to set this value cause otherwise after rotation it will take local state value
      }
      if (value === "top" || value === "middle" || value === "bottom") {
        setVerticalAllignment(value);
        setHoraizentalAllignment(widgets[targetWidgetIndex].data["data-x-allignment"]); //Need to set this value cause otherwise after rotation it will take local state value
      }
    }
  };

  const setHandler = () => {
    let activeWidgetIndexArray = [];
    let parentPos = document.getElementById(metadata.activeBlockId).getBoundingClientRect();

    // create acive widget index array
    metadata.activeWidgetId.forEach(id => {
      let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
      let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
      let widgetIndex = widgets.findIndex(widget => widget.id === targetId);
      activeWidgetIndexArray.push(widgetIndex);
    });

    let childPosition = document.getElementById(widgets[activeWidgetIndexArray[0]].id).getBoundingClientRect();
    let leftValue = childPosition.left - parentPos.left;
    let topValue = childPosition.top - parentPos.top;
    let rightValue = childPosition.right - parentPos.left;
    let bottomValue = childPosition.bottom - parentPos.top;

    // calculate most left, top, right, bottom in active widgets
    activeWidgetIndexArray.forEach(value => {
      let currentChildPosition = document.getElementById(widgets[value].id).getBoundingClientRect();
      let currentLeftValue = currentChildPosition.left - parentPos.left;
      let currentTopValue = currentChildPosition.top - parentPos.top;
      let currentRightValue = currentChildPosition.right - parentPos.left;
      let currentBottomValue = currentChildPosition.bottom - parentPos.top;

      if (currentLeftValue < leftValue) {
        leftValue = currentLeftValue;
      }
      if (currentTopValue < topValue) {
        topValue = currentTopValue;
      }
      if (currentRightValue > rightValue) {
        rightValue = currentRightValue;
      }
      if (currentBottomValue > bottomValue) {
        bottomValue = currentBottomValue;
      }
    });

    // update handler
    document.getElementById(
      "dhp-widget-handler"
    ).style.transform = `translate(${leftValue}px, ${topValue}px) scale(1, 1) rotate(0deg)`;
    document.getElementById("dhp-widget-handler").style.width = `${rightValue - leftValue}px`;
    document.getElementById("dhp-widget-handler").style.height = `${bottomValue - topValue}px`;

    // update resizer and rotater
    document.querySelector(".resizers .nw").style.cursor = "url(../assets/images/135.png) 15 15, auto";
    document.querySelector(".resizers .ne").style.cursor = "url(../assets/images/45.png) 15 15, auto";
    document.querySelector(".resizers .sw").style.cursor = "url(../assets/images/45.png) 15 15, auto";
    document.querySelector(".resizers .se").style.cursor = "url(../assets/images/135.png) 15 15, auto";
    document.querySelector(".rotator").style.cursor = 'url("/b527cdea43a312dcc59d6e8e9c3bad43.png") 15 15, auto';
  };

  //Set rotate widget transform property for single widget selection
  const setRotateWidgetStyle = () => {
    let newArray = Object.assign([...widgets]);
    let parentPos = document.getElementById(metadata.activeBlockId).getBoundingClientRect();
    let isWidgetsOutsideCanvas;

    if (metadata.activeWidgetId.length === 1) {
      let widgetTransformObj;
      let widgetTransform = widgets[targetWidgetIndex].style.transform;
      let childPosition = document.getElementById(targetId).getBoundingClientRect();
      let zoomval = 100 / parseFloat(dimension.zoom);

      if (getPosition[0].left !== undefined) {
        let left =
          allignment === "left"
            ? (parentPos.left - childPosition.left) * zoomval
            : allignment === "right"
            ? Math.abs(parentPos.left - childPosition.left) * zoomval
            : getPosition[0].left;
        widgetTransformObj = getCssTransformObj({ transform: widgetTransform, translateX: `${left}px` });
      }

      if (getPosition[0].top !== undefined) {
        let top =
          allignment === "top" || allignment === "bottom"
            ? Math.abs(parentPos.top - childPosition.top) * zoomval
            : getPosition[0].top;
        widgetTransformObj = getCssTransformObj({ transform: widgetTransform, translateY: `${top}px` });
      }

      let widgetTransformString = getCssTransformObjToString({ transformObj: widgetTransformObj });

      document.getElementById(targetId).style.transform = widgetTransformString;
      document.getElementById(targetId).style.visibility = "visible";

      newArray = Object.assign([...newArray], {
        [targetWidgetIndex]: {
          ...widgets[targetWidgetIndex],
          style: {
            ...widgets[targetWidgetIndex].style,
            transform: widgetTransformString,
          },
          data: {
            ...widgets[targetWidgetIndex].data,
            "data-x-allignment": horaizentalAllignment,
            "data-y-allignment": verticalAllignment,
          },
        },
      });
    } else {
      metadata.activeWidgetId.forEach((id, index) => {
        if (id !== excludeId) {
          let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
          let targetId = isGroupWidget
            ? document.getElementById(id)?.closest(".dhp-root-widget").getAttribute("id")
            : id;
          let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);

          let widgetTransformObj;
          let widgetTransform = document.getElementById(targetId).style.transform;
          let widgettranslateValue = getCssTransformObj({ transform: widgetTransform, exclude: ["rotate", "scale"] });
          let curLeftValue = parseFloat(widgettranslateValue.translate.x);
          let curTopValue = parseFloat(widgettranslateValue.translate.y);
          let childPosition = document.getElementById(targetId).getBoundingClientRect();
          let excludeIdRect = document.getElementById(excludeId)?.getBoundingClientRect();
          let zoomval = 100 / parseFloat(dimension.zoom);

          if (getPosition[0].left !== undefined) {
            let newLeft;

            if (allignment === "center") newLeft = getPosition[index].left;
            else {
              let a =
                allignment === "left"
                  ? (excludeIdRect.left - parentPos.left) * zoomval
                  : (excludeIdRect.left + excludeIdRect.width - parentPos.left) * zoomval;
              let b =
                allignment === "left"
                  ? (childPosition.left - parentPos.left) * zoomval
                  : (childPosition.left + childPosition.width - parentPos.left) * zoomval;
              let c = b - a;
              newLeft = curLeftValue - c;
            }

            widgetTransformObj = getCssTransformObj({ transform: widgetTransform, translateX: `${newLeft}px` });
          }
          if (getPosition[0].top !== undefined) {
            let newTop;

            if (allignment === "middle") newTop = getPosition[index].top;
            else {
              let a =
                allignment === "top"
                  ? (excludeIdRect.top - parentPos.top) * zoomval
                  : (excludeIdRect.top + excludeIdRect.height - parentPos.top) * zoomval;
              let b =
                allignment === "top"
                  ? (childPosition.top - parentPos.top) * zoomval
                  : (childPosition.top + childPosition.height - parentPos.top) * zoomval;
              let c = b - a;
              newTop = curTopValue - c;
            }

            widgetTransformObj = getCssTransformObj({ transform: widgetTransform, translateY: `${newTop}px` });
          }

          let widgetTransformString = getCssTransformObjToString({ transformObj: widgetTransformObj });

          document.getElementById(targetId).style.transform = widgetTransformString;
          document.getElementById(targetId).style.visibility = "visible";

          newArray = Object.assign([...newArray], {
            [targetWidgetIndex]: {
              ...widgets[targetWidgetIndex],
              style: {
                ...widgets[targetWidgetIndex].style,
                transform: widgetTransformString,
              },
              data: {
                ...widgets[targetWidgetIndex].data,
                "data-x-allignment": horaizentalAllignment,
                "data-y-allignment": verticalAllignment,
              },
            },
          });
        }
      });

      setHandler(); // set handler
      isWidgetsOutsideCanvas = checkChildOutsideParent({
        parent: `#${metadata.activeBlockId}`,
        child: document.getElementById("dhp-widget-handler"),
      });
    }

    // Check multi widget position after align, if all are outside canvas, delete them else upadte their new positions
    if (isWidgetsOutsideCanvas) setDeleteWidget(true);
    else updateWidgets(newArray);
    setIsAllignedClicked(false);
  };

  useEffect(() => {
    if (!getPosition) return;
    metadata.activeWidgetId.forEach((id, index) => {
      if (id !== excludeId) {
        let widgetTransformObj;
        let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
        let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
        let widgetIndex = widgets.findIndex(widget => widget.id === targetId);
        let widgetTransform = widgets[widgetIndex].style.transform;

        if (getPosition[index].left !== undefined)
          widgetTransformObj = getCssTransformObj({
            transform: widgetTransform,
            translateX: `${getPosition[index].left}px`,
          });

        if (getPosition[index].top !== undefined)
          widgetTransformObj = getCssTransformObj({
            transform: widgetTransform,
            translateY: `${getPosition[index].top}px`,
          });

        let widgetTransformString = getCssTransformObjToString({ transformObj: widgetTransformObj });

        // 1st update in DOM only to solve rotate widget issue (Update context in separate)
        document.getElementById(targetId).style.transform = widgetTransformString;
        document.getElementById(targetId).style.visibility = "hidden";
        document.getElementById(targetId).setAttribute("data-x-allignment", horaizentalAllignment);
        document.getElementById(targetId).setAttribute("data-y-allignment", verticalAllignment);
      }
    });

    //Reset tarnsform property agin if its allign to left, top, right and bottom
    if (metadata.activeWidgetId.length === 1) {
      let isGroupWidget = document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-page-group");
      let targetId = isGroupWidget
        ? document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-root-widget").getAttribute("id")
        : metadata.activeWidgetId[0];

      if (
        document.getElementById(targetId).getAttribute("data-value") &&
        parseInt(document.getElementById(targetId).getAttribute("data-value")) !== 0
      ) {
        setTimeout(() => {
          setRotateWidgetStyle();
        }, 5);
      } else setRotateWidgetStyle();
    } else {
      setTimeout(() => {
        setRotateWidgetStyle();
      }, 5);
    }
  }, [getPosition]);

  // to set widget status for lebel disable (Only for single widgte selection)
  useEffect(() => {
    if (metadata.activeWidgetId.length === 1) {
      if (widgets[targetWidgetIndex]?.data["data-x-allignment"] === "tripple")
        setHStatusArray(["left", "center", "right"]);
      else setHStatusArray([widgets[targetWidgetIndex]?.data["data-x-allignment"]]);

      if (widgets[targetWidgetIndex]?.data["data-y-allignment"] === "tripple")
        setVStatusArray(["top", "middle", "bottom"]);
      else setVStatusArray([widgets[targetWidgetIndex]?.data["data-y-allignment"]]);
    }
  }, [widgets[targetWidgetIndex]?.data["data-x-allignment"], widgets[targetWidgetIndex]?.data["data-y-allignment"]]);

  // Trigger Whenever active widget's transform, property will change (only for multi widget selection)
  useEffect(() => {
    if (metadata.activeWidgetId.length > 1 && !isAllignedClicked)
      setMStatusArray(checkWidgetAllignmentForMultiSelectWidget());
  }, [isAllignedClicked]);

  useEffect(() => {
    if (metadata.activeWidgetId.length > 1) setMStatusArray(checkWidgetAllignmentForMultiSelectWidget());
  }, []);

  return (
    <TabPane tabId={props.idx}>
      <ul className={cx(style["row"], style["row-cols-2"])}>
        {allignmentArray.map((allignment, idx) => (
          <React.Fragment key={idx}>
            <li className={style["col"]}>
              <DropdownItem
                tag="a"
                onClick={() => handleAllignment(allignment)}
                className={cx(style["text-capitalize"], {
                  [style["disabled"]]:
                    hStatusArray?.includes(allignment) ||
                    vStatusArray?.includes(allignment) ||
                    mStatusArray?.includes(allignment),
                })}>
                <Icon icon={`ui-${allignment}-align`} />
                {allignment}
              </DropdownItem>
            </li>
          </React.Fragment>
        ))}
      </ul>
    </TabPane>
  );
};
Align.propTypes = {
  idx: PropTypes.number,
  setDropdownOpen: PropTypes.func,
};

export default Align;
