import React from "react";
import cx from "classnames";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import DocHipoLogo from "./DocHipoLogo";
import DocHipo404 from "../../assets/images/dochipo-404.png";
import exclamation from "../../assets/images/exclamation-mark.svg";
import global from "../../scss/dhp.scss";

let style = Object.assign({}, global);

const NotFound = () => {
  return (
    <section className={style["page-not-found-wrap"]}>
      <Container cssModule={style} className={cx(style["h-100"], style["d-none"], style["d-lg-block"])}>
        <Row
          cssModule={style}
          className={cx(
            style["justify-content-center"],
            style["align-items-center"],
            style["row-reverse"],
            style["text-center"],
            style["text-md-left"],
            style["h-100"]
          )}>
          <Col cssModule={style} lg={6} md={6} className={cx(style["pt-5"], style["pt-md-0"])}>
            <img src={DocHipo404} className={style["img-fluid"]} alt="reset" />
          </Col>

          <Col cssModule={style} lg={5} md={6} className={cx(style["mr-auto"], style["py-5"], style["py-md-0"])}>
            <h1>Oops!</h1>
            <h3 className={style["m-0"]}>Page not found</h3>
            <p className={style["my-4"]}>We’re sorry, the page you requested could not be found.</p>
            <Link to="/" className={cx(style["btn"], style["btn-arrow-animation"])}>
              <span className={style["circle"]}>
                <span className={cx(style["icon"], style["arrow"])}></span>
              </span>
              <span className={style["button-text"]}>Go back Home</span>
            </Link>
          </Col>
        </Row>
      </Container>

      <Container cssModule={style} className={cx(style["mobile-view"], style["d-block"], style["d-lg-none"])}>
        <Row cssModule={style} className={cx(style["p-0"])}>
          <Col xs="12" cssModule={style} className={cx(style["d-flex"], style["justify-content-center"], style["align-items-center"])}>
            <div className={cx(style["logo-wraper"], style["w-100"])}>
              <DocHipoLogo />
            </div>
          </Col>
          <Col xs="12" cssModule={style} className={cx(style["text-center"])}>
            <div className={cx(style["mobile-content"], style["d-flex"], style["justify-content-center"], style["align-items-center"], style["flex-column"])}>
              <div className={style["info"]}>
                <div className={style["error-img"]}>
                  <img src={exclamation} className={style["img-fluid"]} alt="reset" />
                </div>
                <h3 className={style["m-0"]}>Oops! Page not found</h3>
                <p className={style["my-4"]}>We’re sorry, the page you requested could not be found. Please go back to Sign in page.</p>
                <p className={style["m-0"]}>Back to <Link to="/" className={cx(style[""])}>Sign in</Link> page</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default NotFound;
