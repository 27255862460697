import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { TabPane, Tooltip } from "reactstrap";
import { useSelector } from "react-redux";

import global from "../../../scss/dhp.scss";

import { AppContext } from "../../../contexts";
import BrandkitTabPaneSubTitle from "./BrandkitTabPaneSubTitle";
import {
  RGBToHex,
  getClassListToString,
  getFileType,
  getImgDimensionFromUrl,
  getSvgContentFromUrl,
} from "../../../_helpers/utils";
import { BRAND, SVG, UPLOAD, VIDEO } from "../../../constants/editor";
import useTextFocusOut from "../../../hooks/useTextFocusOut";
import useDragDropWidget from "../../../hooks/useDragDropWidget";
import useAlignment from "../../../hooks/useAlignment";
import useAddWidget from "../../../hooks/useAddWidget";
import useElementInnerHtml from "../../../hooks/useElementInnerHtml";
import { widgetConfig } from "../editor_config";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import AssetsLoader from "../../ui/loader/assetsLoader";

let style = Object.assign({}, global);

const BrandLogos = props => {
  const { start: initDragDropWidget } = useDragDropWidget();
  const setTextFocusOut = useTextFocusOut();

  const [isAssetLoad, setIsAssetLoad] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const scrollLeftPane = () => {
    setTooltipOpen(false);
  };

  useEffect(() => {
    document.querySelector(".assets-wrapper").addEventListener("scroll", scrollLeftPane);

    return () => {
      document.querySelector(".assets-wrapper")?.removeEventListener("scroll", scrollLeftPane);
    };
  }, []);

  return (
    <>
      <li key={props.logo.id} className={cx(style["col"], style["skeleton-loader-area"])}>
        <div className={cx(style["asset-item"])} id={`asset-image-${props.logo.id}`}>
          <div
            className={style["item-holder"]}
            onClick={() => {
              if (document.querySelector(".dhp-content-editable-true-text")) setTextFocusOut(true);
              props.insertElem(props.logo.url, props.logo.name, props.logo.duration);
            }}
            onMouseDown={e =>
              initDragDropWidget(
                {
                  asset: UPLOAD,
                  value: props.logo.url,
                  filename: props.logo.name,
                  duration: props.logo?.duration,
                },
                e
              )
            }>
            {!isAssetLoad && <span className={style["loader-item"]}></span>}

            <img
              src={props.logo.url}
              onLoad={() => setIsAssetLoad(true)}
              className={cx({ [style["d-none"]]: !isAssetLoad })}
            />
          </div>
        </div>

        {isAssetLoad && (
          <Tooltip
            className="asset-hover-tooltip"
            placement="right"
            isOpen={tooltipOpen}
            target={`asset-image-${props.logo.id}`}
            toggle={toggle}
            boundariesElement={document.getElementById("app")}>
            <div
              className={getClassListToString([
                "image-preview",
                getFileType(props.logo.name) === "svg" ? "svg-preview" : "preview-picture",
              ])}>
              <img src={props.logo.url} alt="" className={style["img-fluid"]} />
            </div>
          </Tooltip>
        )}
      </li>
    </>
  );
};
BrandLogos.propTypes = {
  logo: PropTypes.object.isRequired,
  insertElem: PropTypes.func.isRequired,
};

const BrandImages = props => {
  const { start: initDragDropWidget } = useDragDropWidget();
  const setTextFocusOut = useTextFocusOut();

  const [isAssetLoad, setIsAssetLoad] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const scrollLeftPane = () => {
    setTooltipOpen(false);
  };

  useEffect(() => {
    document.querySelector(".assets-wrapper").addEventListener("scroll", scrollLeftPane);

    return () => {
      document.querySelector(".assets-wrapper")?.removeEventListener("scroll", scrollLeftPane);
    };
  }, []);

  return (
    <>
      <li key={props.image.id} className={cx(style["col"], style["skeleton-loader-area"])}>
        <div className={cx(style["asset-item"])} id={`asset-image-${props.image.id}`}>
          <div
            className={style["item-holder"]}
            onClick={() => {
              if (document.querySelector(".dhp-content-editable-true-text")) setTextFocusOut(true);
              props.insertElem(props.image.url, props.image.name);
            }}
            onMouseDown={e =>
              initDragDropWidget(
                {
                  asset: UPLOAD,
                  value: props.image.url,
                  filename: props.image.name,
                },
                e
              )
            }>
            {!isAssetLoad && <span className={style["loader-item"]}></span>}

            <img
              src={props.image.url}
              onLoad={() => setIsAssetLoad(true)}
              className={cx({ [style["d-none"]]: !isAssetLoad })}
            />
          </div>
        </div>

        {isAssetLoad && (
          <Tooltip
            className="asset-hover-tooltip"
            placement="right"
            isOpen={tooltipOpen}
            target={`asset-image-${props.image.id}`}
            toggle={toggle}
            boundariesElement={document.getElementById("app")}>
            <div
              className={getClassListToString([
                "image-preview",
                getFileType(props.image.name) === "svg" ? "svg-preview" : "preview-picture",
              ])}>
              <img src={props.image.url} alt="" className={style["img-fluid"]} />
            </div>
          </Tooltip>
        )}
      </li>
    </>
  );
};
BrandImages.propTypes = {
  image: PropTypes.object.isRequired,
  insertElem: PropTypes.func.isRequired,
};

const BrandkitAssests = () => {
  let { metadata, dimension } = useContext(EditorContext);
  let { brandInfo } = useContext(AppContext);

  const { loading } = useSelector(state => state.company);

  const [assetType, setAssetType] = useState();
  const [assetInnerContent, setAssetInnerContent] = useState();
  const [widgetWidth, setWidgetWidth] = useState();
  const [widgetHeight, setWidgetHeight] = useState();
  const [dataparam, setDataParam] = useState();
  const [addWidgetClicked, setAddWidgetClicked] = useState(false);
  const [playVideo, setPlayVideo] = useState({});
  const [isAssetLoad, setIsAssetLoad] = useState(false);

  const setTextFocusOut = useTextFocusOut();
  const { postion: getPosition } = useAlignment(addWidgetClicked, widgetWidth, widgetHeight, "middle-center");
  const getNewWidgetObject = useAddWidget(
    addWidgetClicked,
    assetType,
    dataparam,
    getPosition,
    widgetWidth,
    widgetHeight
  );
  useElementInnerHtml(
    addWidgetClicked,
    setAddWidgetClicked,
    assetType,
    assetInnerContent,
    dataparam,
    getNewWidgetObject
  );
  const { start: initDragDropWidget } = useDragDropWidget();

  // insert Brand logo and images
  const insertElem = async (url, fileName, duration) => {
    let innerContent, paramObj;

    if (duration)
      paramObj = {
        "data-file-type": getFileType(fileName),
      };
    else
      paramObj = {
        "data-file-type": getFileType(fileName),
        "data-duration": duration,
      };

    if (getFileType(fileName) !== SVG) {
      innerContent = url;

      const { width: imgWidth, height: imgHeight } = await getImgDimensionFromUrl(url);
      const imgAspectRatio = imgWidth / imgHeight;
      let { width: activeBlockWidth } = document.getElementById(`${metadata.activeBlockId}`)?.getBoundingClientRect();
      activeBlockWidth = (activeBlockWidth * 100) / parseFloat(dimension.zoom);
      const width = imgWidth <= activeBlockWidth ? imgWidth : activeBlockWidth;
      const height = imgWidth <= activeBlockWidth ? imgHeight : width / imgAspectRatio;

      setWidgetWidth(width);
      setWidgetHeight(height);
    } else {
      innerContent = await getSvgContentFromUrl(url);

      //Update multicolor uploaded svg innerhtml with data attribute for different color path after insertion
      innerContent.querySelectorAll(`svg *`).forEach(node => {
        if (
          node.nodeName === "defs" ||
          node.parentNode.nodeName === "defs" ||
          node.parentNode.nodeName === "linearGradient"
        )
          return false;

        if (node.nodeName !== "g" && node.style && RGBToHex(node.style.fill) !== "") {
          const currentIconColor = RGBToHex(node.style.fill);
          node.dataset.class = `${currentIconColor}body`;
        }

        if (node.tagName === "title") node.remove(); // fremove title tag for uploaded svg
      });

      setWidgetWidth(innerContent?.viewBox?.baseVal?.width ?? widgetConfig[UPLOAD].width);
      setWidgetHeight(innerContent?.viewBox?.baseVal?.height ?? widgetConfig[UPLOAD].height);
    }

    setDataParam({ ...widgetConfig[UPLOAD].dataAttr, ...paramObj });
    setAssetInnerContent(innerContent);
    setAssetType(UPLOAD);
    setAddWidgetClicked(true);
  };

  const playVideoOnHover = index => () => {
    if (!playVideo[index]) {
      setPlayVideo({ [index]: true });

      // Your existing code for removing preview loader
      let intervalId = setInterval(() => {
        let runTimeVideo = document.getElementById("video-pen");
        let overlayLoader = document.getElementById("video-overlay");
        if (runTimeVideo && runTimeVideo.readyState == 4) {
          clearInterval(intervalId);
          overlayLoader.style.display = "none";
        }
      }, 300);
    }
  };

  const stopVideoOnMouseLeave = index => () => {
    setPlayVideo({ [index]: false });
  };

  // insert Brand Video
  const insertVideo = async (url, poster, duration, assetLoadComplete) => {
    if (!assetLoadComplete) return;

    const { width: imgWidth, height: imgHeight } = await getImgDimensionFromUrl(poster);
    const imgAspectRatio = imgWidth / imgHeight;
    let { width: activeBlockWidth } = document.getElementById(`${metadata.activeBlockId}`)?.getBoundingClientRect();
    activeBlockWidth = (activeBlockWidth * 100) / parseFloat(dimension.zoom);
    const width = imgWidth <= activeBlockWidth ? imgWidth : activeBlockWidth;
    const height = imgWidth <= activeBlockWidth ? imgHeight : width / imgAspectRatio;

    if (document.querySelector(".dhp-content-editable-true-text")) setTextFocusOut(true);

    setTimeout(() => {
      let paramObj = {
        "data-poster": poster,
        "data-url": url,
        "data-duration": duration,
      };

      setWidgetWidth(width);
      setWidgetHeight(height);
      setAssetInnerContent(url);
      setDataParam({ ...widgetConfig[VIDEO].BRAND.dataAttr, ...paramObj });
      setAssetType(VIDEO);
      setAddWidgetClicked(true);
    }, 1);
  };

  return (
    <>
      <TabPane tabId="3" className={style["active"]}>
        {!brandInfo?.brandDetails && loading && (
          <ul className={cx(style["row-cols-2"], style["row"])}>
            <AssetsLoader count={8} />
          </ul>
        )}
        {brandInfo?.brandDetails && !loading && (
          <section>
            <div className={style["mb-4"]}>
              <BrandkitTabPaneSubTitle title={"Brand Logos"} isIcon={"none"} />
              {brandInfo?.brandDetails?.logos?.length > 0 && (
                <ul className={cx(style["row"], style["row-cols-2"])} onContextMenu={e => e.preventDefault()}>
                  {brandInfo?.brandDetails?.logos.map(logo => (
                    <BrandLogos key={logo.id} logo={logo} insertElem={insertElem} />
                  ))}
                </ul>
              )}

              {!brandInfo?.brandDetails?.logos?.length > 0 && (
                <div className={cx(style["text-center"], style["bg-secondary"], style["rounded"], style["p-2"])}>
                  No brand logos yet
                </div>
              )}
            </div>

            <div className={style["mb-4"]}>
              <BrandkitTabPaneSubTitle title={"Brand Images"} isIcon={"none"} />
              {brandInfo?.brandDetails?.images?.length > 0 && (
                <ul className={cx(style["row"], style["row-cols-2"])} onContextMenu={e => e.preventDefault()}>
                  {brandInfo?.brandDetails?.images?.map(image => (
                    <BrandImages key={image.id} image={image} insertElem={insertElem} />
                  ))}
                </ul>
              )}

              {!brandInfo?.brandDetails?.images?.length > 0 && (
                <div className={cx(style["text-center"], style["bg-secondary"], style["rounded"], style["p-2"])}>
                  No brand images yet
                </div>
              )}
            </div>

            <div className={cx(style["mb-4"], style["brand-video"])}>
              <BrandkitTabPaneSubTitle title={"Brand Videos"} isIcon={"none"} />
              {brandInfo?.brandDetails?.videos?.length > 0 && (
                <ul className={cx(style["row"], style["row-cols-1"])} onContextMenu={e => e.preventDefault()}>
                  {brandInfo?.brandDetails?.videos?.map((video, index) => (
                    <li key={index} className={cx(style["col"], style["skeleton-loader-area"])}>
                      <div
                        className={cx(style["asset-item"], style["main-heading"])}
                        onMouseEnter={playVideoOnHover(index)}
                        onMouseLeave={stopVideoOnMouseLeave(index)}
                        onClick={() => insertVideo(video.url, video.thumb, video.duration, isAssetLoad)}
                        onMouseDown={e => {
                          isAssetLoad &&
                            initDragDropWidget(
                              {
                                asset: VIDEO,
                                value: video.url,
                                source: BRAND,
                                poster: video.thumb,
                                duration: video.duration,
                              },
                              e
                            );
                        }}>
                        {!playVideo[index] && !isAssetLoad && <span className={style["loader-item"]}></span>}

                        {!playVideo[index] && (
                          <img
                            src={video.thumb}
                            onLoad={() => setIsAssetLoad(true)}
                            className={cx({ [style["d-none"]]: !isAssetLoad })}
                            onError={e => {
                              e.target.onerror = null;
                              e.target.src = video.thumb;
                            }}
                          />
                        )}

                        {playVideo[index] && isAssetLoad && (
                          <div className={style["preview-pen"]} id="video-preview">
                            <video id="video-pen" autoPlay muted loop poster={video?.thumb}>
                              <source src={video?.url} />
                            </video>
                            <div className={style["overlay"]} id="video-overlay">
                              <div className={style["loader"]}></div>
                            </div>
                          </div>
                        )}

                        {isAssetLoad && <span className={style["duration"]}>{video.duration}</span>}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              {!brandInfo?.brandDetails?.videos?.length > 0 && (
                <div className={cx(style["text-center"], style["bg-secondary"], style["rounded"], style["p-2"])}>
                  No brand videos yet
                </div>
              )}
            </div>
          </section>
        )}
      </TabPane>
    </>
  );
};

export default BrandkitAssests;
