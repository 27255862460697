import React, { useEffect, useLayoutEffect, useState } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../scss/dhp.scss";
import { Icon } from "./icon";
import ColorPicker from "./colorPicker";
import { getRandomHexColorCode } from "../../_helpers/utils";

let style = Object.assign({}, global);

const ColorBox = ({ index, setCurrentColorIdx, color, updateColor, deleteColor, setBucketOpen }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => {
    setDropdownOpen(prevState => !prevState);
    setBucketOpen(!dropdownOpen);
  };

  return (
    <div className={style["for-settings"]}>
      <div className={style["color-container"]}>
        {deleteColor && (
          <span aria-label="Close" className={cx(style["delete-color"])} onClick={() => deleteColor(index)}>
            <Icon icon="ui-close" />
          </span>
        )}
        <UncontrolledDropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle
            tag="div"
            className={cx(style["color-box"], { [style["border"]]: color === "#ffffff" })}
            style={{ backgroundColor: color }}
            onClick={() => setCurrentColorIdx(index)}
            caret
          />

          <DropdownMenu className={cx(style["border-0"], style["p-0"])} right={true}>
            <ColorPicker
              color={color}
              setColor={updateColor}
              isHideTransparentOpt={true}
              eyedropperColor={color}
              setEyedropperColor={updateColor}
              isHideBrand={true}
            />
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </div>
  );
};

ColorBox.propTypes = {
  index: PropTypes.number.isRequired,
  setCurrentColorIdx: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  updateColor: PropTypes.func.isRequired,
  deleteColor: PropTypes.func,
  setBucketOpen: PropTypes.func.isRequired,
};

const ColorPalette = ({
  defaultColors = [],
  setDefaultColors,
  showNewOption = true,
  maxColor = 6,
  paletteLebel,
  updateFunction,
  disableDeleteLastColor,
}) => {
  const [colors, setColors] = useState(defaultColors);
  const [currentColorIdx, setCurrentColorIdx] = useState();
  const [bucketOpen, setBucketOpen] = useState();

  const updateColor = colorCode => {
    let newColorArray = [...colors];
    newColorArray[currentColorIdx] = colorCode;
    setDefaultColors(newColorArray);
    if (updateFunction && !bucketOpen) updateFunction(newColorArray);
  };

  const addNewColor = () => {
    let newColor = getRandomHexColorCode();
    setDefaultColors(prevItems => [...prevItems, newColor]);
    if (updateFunction) updateFunction([...colors, newColor]);
  };

  const deleteColor = idx => {
    let newColorArray = [...colors];
    newColorArray.splice(idx, 1);
    setDefaultColors(newColorArray);
    if (updateFunction) updateFunction(newColorArray);
  };

  useLayoutEffect(() => {
    setColors(defaultColors);
  }, [defaultColors]);

  useEffect(() => {
    if (bucketOpen === false && updateFunction) {
      updateFunction(colors);
    }
  }, [bucketOpen]);

  return (
    <div className={style["color-palette"]}>
      {paletteLebel && (
        <div className={cx(style["d-flex"], style["justify-content-between"])}>
          <label className={cx(style["color-palette-label"], style["fw-6"], style["mb-2"])}>{paletteLebel}</label>
        </div>
      )}
      <div className={style["color-palette-list"]}>
        {colors.map((color, index) => (
          <ColorBox
            key={index}
            index={index}
            color={color}
            updateColor={updateColor}
            setCurrentColorIdx={setCurrentColorIdx}
            deleteColor={disableDeleteLastColor && colors.length <= 1 ? null : deleteColor}
            setBucketOpen={setBucketOpen}
          />
        ))}
        {showNewOption && colors.length < maxColor && (
          <div className={cx(style["color-box"], style["add-new"])} onClick={addNewColor}>
            <Icon icon="ui-plus" />
          </div>
        )}
      </div>
    </div>
  );
};

ColorPalette.propTypes = {
  defaultColors: PropTypes.array.isRequired,
  setDefaultColors: PropTypes.func.isRequired,
  maxColor: PropTypes.number,
  showNewOption: PropTypes.bool,
  paletteLebel: PropTypes.string,
  updateFunction: PropTypes.func,
  disableDeleteLastColor: PropTypes.bool,
};

export default ColorPalette;
