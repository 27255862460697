import React, { useContext } from "react";

import { AppContext } from "../../contexts";
import Modal from "../ui/modal";
import UpgradeModal from "./Modal/UpgradeModal";

const ContextualUpgrade = () => {
  const { upgradeModalStatus, updateUpgradeModalStatus, upgradeModalData } = useContext(AppContext);
  return (
    <>
      {upgradeModalStatus && (
        <Modal
          showModal={upgradeModalStatus}
          setShowModal={updateUpgradeModalStatus}
          component={UpgradeModal}
          upgradeType={upgradeModalData?.upgradeType}
          modalWidgetUrl={upgradeModalData?.widgetUrl}
          data={upgradeModalData?.data}
          modalClassName="subscription-modal"
          className="modal-lg"
        />
      )}
    </>
  );
};

export default ContextualUpgrade;
