import { useContext } from "react";
import { getCssTransformObj, getUnScaledValue } from "../_helpers/utils";
import { EditorContext } from "../containers/editor/EditorLayout";
import UseCheckWidgetAllignment from "./UseCheckWidgetAllignment";
const useCropToShapeResize = () => {
  let { widgets, updateWidgets, dimension } = useContext(EditorContext);

  const { checkWidgetAllignmentForSingleWidget } = UseCheckWidgetAllignment();

  const cropToShapeResizeStart = data => {
    let widgetInner = data.client.node.getElementsByClassName("dhp-widget-inner")[0];
    const { scale } = getCssTransformObj({
      transform: widgetInner.style.transform,
    });
    data.client.innerScale = scale.x;
  };

  const cropToShapeResize = (meta, widgetWidth) => {
    let widgetInner = meta.client.node.getElementsByClassName("dhp-widget-inner")[0];
    let widgetInnerScale =
      widgetWidth / (getUnScaledValue(meta.client.initial.width, dimension.zoom) / meta.client.innerScale);
    const transformStr = getCssTransformObj({
      scaleX: widgetInnerScale,
      scaleY: widgetInnerScale,
      transform: widgetInner.style.transform,
      exclude: ["rotate"],
      returnStr: true,
    });
    widgetInner.style.transform = transformStr;
    // set flippable div height & width
    widgetInner.parentNode.style.width = `${widgetWidth}px`;
    widgetInner.parentNode.style.height = `${widgetWidth}px`;
  };

  const cropToShapeResizeStop = meta => {
    let updatedCss = meta.client.current;
    const { x_al, y_al } = checkWidgetAllignmentForSingleWidget(
      updatedCss.height,
      updatedCss.transform,
      updatedCss.width
    );

    updateWidgets(
      widgets.map((widget, idx) => {
        if (idx === meta.client.idx) {
          return {
            ...widget,
            style: {
              ...widget.style,
              ...updatedCss,
            },
            data: {
              ...widget.data,
              "data-x-allignment": x_al,
              "data-y-allignment": y_al,
            },
            innerHTML: document.getElementById(meta.client.id).innerHTML,
          };
        } else {
          return widget;
        }
      })
    );
  };
  return [cropToShapeResizeStart, cropToShapeResize, cropToShapeResizeStop];
};
export default useCropToShapeResize;
