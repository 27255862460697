import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

let style = Object.assign({}, global);

const AssetsLoader = ({ count }) => {
  return [...Array(count)].map((_, i) => (
    <li key={i} className={cx(style["col"], style["skeleton-loader-area"])}>
      <div className={cx(style["asset-item"])}>
        <span className={style["loader-item"]}></span>
      </div>
    </li>
  ));
};

//Props type validation
AssetsLoader.propTypes = {
  count: PropTypes.number.isRequired,
};

export default AssetsLoader;
