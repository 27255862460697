import React, { useContext } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import useDeleteWidget from "../../../hooks/useDeleteWidget";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import useDeleteAudio from "../../../hooks/useDeleteAudio";

let style = Object.assign({}, global);

const Delete = props => {
  let { metadata } = useContext(EditorContext);

  const setDeleteWidget = useDeleteWidget();
  const { deleteAudio } = useDeleteAudio();

  const deleteAction = () => {
    // delete active widget
    if (metadata.activeWidgetId) setTimeout(() => setDeleteWidget(true), 30);
    // delete active audio
    else if (metadata.activeAudioId) deleteAudio();
  };

  return (
    <li className={style["toolset-group"]}>
      <div className={cx(style["toolset-item"], style["trash-wrap"])}>
        <span className={cx(style["toolset-action"], style["custom-tooltip"])} onClick={deleteAction}>
          <Icon icon="ui-trash" />
          {(props.more || props.rightContext) && <>Delete {props.rightContext && <span>Del</span>}</>}
          {!props.more && !props.rightContext && (
            <div className={cx(style["custom-tooltip-content"], style["top"])}>Delete</div>
          )}
        </span>
      </div>
    </li>
  );
};
Delete.propTypes = {
  more: PropTypes.bool,
  rightContext: PropTypes.bool,
};

export default Delete;
