import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import useClonePage from "../../../hooks/useClonePage";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import * as constant from "../../../constants/editor";
import { getDocumentDuration } from "../../../_helpers/utils";

let style = Object.assign({}, global);

const ClonePage = props => {
  let { pageNodes, metadata, documentType } = useContext(EditorContext);

  const [isCloneDisable, setIsCloneDisable] = useState(false);

  const { clone } = useClonePage();

  useEffect(() => {
    // Disable page clone option if total doc duration riched
    if (pageNodes.length > 0 && documentType !== constant.TYPE_INFOGRAPHIC) {
      let getDocDurationInfo = getDocumentDuration(pageNodes, metadata.activePageIdx);
      let availableTime = getDocDurationInfo.document.available;

      if (availableTime < parseFloat(pageNodes[metadata.activePageIdx]?.pageDuration)) setIsCloneDisable(true);
      else setIsCloneDisable(false);
    }
  }, [pageNodes.length, pageNodes[metadata.activePageIdx]?.pageDuration]);

  return (
    <>
      <li
        className={cx(style["custom-tooltip"], {
          [style["disabled"]]: isCloneDisable,
        })}>
        <span
          onClick={() => {
            if (isCloneDisable) return;
            clone();
          }}
          className={style["clone"]}>
          <Icon icon="ui-clone" />
        </span>
        <div className={cx(style["custom-tooltip-content"], props.pageSorterList ? [style["left"]] : [style["right"]])}>
          Clone {props.pageType}
        </div>
      </li>
    </>
  );
};

//Props type validation
ClonePage.propTypes = {
  documentType: PropTypes.string.isRequired,
  pageType: PropTypes.string.isRequired,
  pageSorterList: PropTypes.bool,
};

export default ClonePage;
