import { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { injectFonts } from '../_helpers/utils';

const useDeletedCustomFonts = () => {
    const documentFonts = useSelector(state => state?.document?.documentDetails?.data?.version?.rawdata?.contexts?.fonts);
    const versionFonts = useSelector(state => state?.document?.versionDetailInfo?.data?.version?.rawdata?.contexts?.fonts);
    const templateData = useSelector(state => state?.template?.templateDetails?.data?.filedata);
    const customFonts = useSelector(state => state?.resources?.custom_fonts?.all?.fonts);
    const getFilteredFonts = (data, filterValues) => data.filter(obj => filterValues.includes(obj.name));

    useEffect(() => {
        if (customFonts && customFonts?.length > 0) {
            const allDeletedFonts = customFonts.filter(font => !font?.enabled);
            if (templateData) {
                const templateFonts = JSON.parse(templateData)?.metadata?.fonts;
                if (templateFonts && templateFonts.length > 0)
                    injectFonts(getFilteredFonts(allDeletedFonts, templateFonts))
            }
            if (documentFonts && documentFonts.length > 0)
                injectFonts(getFilteredFonts(allDeletedFonts, documentFonts))
            if (versionFonts && versionFonts.length > 0)
                injectFonts(getFilteredFonts(allDeletedFonts, versionFonts))
        }
        return () => {
            injectFonts([])
        }
    }, [customFonts, templateData, documentFonts, versionFonts]);
}

export default useDeletedCustomFonts