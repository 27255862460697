import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useCheckCompanyPlanInfo = (flatten = false) => {
  const { company: companyDetails, user: userInfo } = useSelector(state => state.auth);
  const [companyInfo, setCompanyInfo] = useState({ isPaid: false, isLoading: true });

  useEffect(() => {
    if (companyDetails) {
      let updatedContent = { ...companyInfo };
      if (companyDetails?.plan_id) updatedContent["isPaid"] = true;
      else updatedContent["isPaid"] = false;

      if (flatten) {
        updatedContent["subscription_quantity"] = companyDetails?.subscription_quantity;
        updatedContent["active_user_count"] = companyDetails?.active_user_count + companyDetails?.invited_user_count;
        updatedContent["companyRole"] = userInfo?.role;
      }
      updatedContent["isLoading"] = false;

      setCompanyInfo(updatedContent);
    }
  }, [companyDetails]);

  return companyInfo;
};
