import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { STOCK_SERVICE_PROVIDERS as stockSPs, UTM_PARAMS as utmParams } from "../../../../constants/editor";

const StockSPs = ({ fileType, excludeList }) => {
  const finalSPs = {};

  Object.keys(stockSPs).forEach(sp => {
    if (!excludeList.includes(sp)) {
      finalSPs[sp] = stockSPs[sp];
    }
  });

  return (
    <>
      {Object.keys(finalSPs).map((sp, i) => (
        <React.Fragment key={i}>
          <Link to={`${finalSPs[sp].url[fileType]}${utmParams}`} target="_blank">
            {finalSPs[sp].name}
          </Link>
          {Object.keys(finalSPs).length === 2 && Object.keys(finalSPs).length !== i + 1 && "\u00A0and\u00A0"}
          {Object.keys(finalSPs).length > 2 && Object.keys(finalSPs).length !== i + 1 && ",\u00A0"}
        </React.Fragment>
      ))}
    </>
  );
};

//Props type validation
StockSPs.propTypes = {
  fileType: PropTypes.string,
  excludeList: PropTypes.array,
};

export default StockSPs;
