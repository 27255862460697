import React, { Fragment, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import cx from "classnames";

import global from "../../scss/dhp.scss";
import { Icon } from "../../components/ui/icon";

import { EditorContext } from "../../containers/editor/EditorLayout";
import useReplaceDuplicateShapeId from "../../hooks/useReplaceDuplicateShapeId";
import useSetActivePageBlock from "../../hooks/useSetActivePageBlock";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Popover,
  PopoverBody,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import * as constant from "../../constants/editor";
import useAddPage from "../../hooks/useAddPage";
import useClonePage from "../../hooks/useClonePage";
import useDeletePage from "../../hooks/useDeletePage";
import Modal from "../ui/modal";
import DeleteElemModal from "./Modals/DeleteElemModal";
import { getDocumentDuration, getSecToHMS, searchNodeByPoint } from "../../_helpers/utils";
import AssetsLoader from "../ui/loader/assetsLoader";
import useUpdatePageDuration from "../../hooks/useUpdatePageDuration";

let style = Object.assign({}, global);

const Widget = props => {
  const [sorterInnerHtml, setSorterInnerHtml] = useState();

  const { replaceDuplicateIdFromSVG } = useReplaceDuplicateShapeId();

  useEffect(() => {
    let targetDOM = document.createElement("div");
    targetDOM.innerHTML = props.widget.innerHTML;

    targetDOM.querySelectorAll(".dhp-page-widget").forEach(element => {
      element.removeAttribute("id");
      element.classList.remove("child-selected");
    });

    // set preload none for video type widget otherwise memory will increase
    if (targetDOM.querySelector(".dhp-widget-inner").childNodes[0]?.tagName === "VIDEO") {
      targetDOM.querySelector(".dhp-widget-inner").childNodes[0].setAttribute("preload", "none");
    }

    replaceDuplicateIdFromSVG(); // Replace duplicate id for shape use in shape border

    setSorterInnerHtml(targetDOM.innerHTML);
  }, [props.widget]);

  return (
    <>
      <div
        style={{ ...props.widget.style }}
        {...props.widget.data}
        {...(props.widget.classLists.includes("dhp-widget-hyperlinked") && {
          className: "nav-widget dhp-widget-hyperlinked",
        })}
        {...(!props.widget.classLists.includes("dhp-widget-hyperlinked") && { className: "nav-widget" })}
        dangerouslySetInnerHTML={{ __html: sorterInnerHtml }}></div>
    </>
  );
};

const DocumentTimeLineGenaretor = props => {
  const resizeContainerRef = useRef();
  const transitionRef = useRef();
  const actionRef = useRef();
  const tooltipRef = useRef();
  const resizerRef = useRef();
  const sortableItemRef = useRef();
  const { replaceDuplicateIdFromSVG } = useReplaceDuplicateShapeId();
  let {
    metadata,
    dimension,
    pageNodes,
    blockNodes,
    backgroundColors,
    backgroundImages,
    widgets,
    updateScrollEvent,
    documentType,
    handleWidgetAction,
    updateBlockNodes,
    updateWidgets,
    updateMetadata,
    isTimeLineViewOpen,
    changeBySocket,
    playingPageIdx,
  } = useContext(EditorContext);

  let figureHeight = 90;
  let figureWidth = (figureHeight / dimension.height) * dimension.width;
  let pageSorterScreenHeight = 90;
  let docHeight = dimension.height;
  let scaledval = pageSorterScreenHeight / docHeight;
  let pageType = (documentType === documentType) === constant.TYPE_PRESENTATION ? "Slide" : "Page";
  let targetContainer = document.getElementsByClassName("dhp-contentArea");
  let modalData = {
    title: `Delete this ${pageType}`,
    body: `Are you sure to delete this ${pageType}?`,
    btnLabel: "Delete",
  }; // delete page modal data

  // local states
  const [resizableContainerWidth, setResizableContainerWidth] = useState();
  const [renderRepeatedPages, setRenderRepeatedPages] = useState([]);
  const [activeContainerInfo, setActiveContainerInfo] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showModalStatus, setShowModalStatus] = useState(false);
  const [isAddDisable, setIsAddDisable] = useState(false);
  const [isCloneDisable, setIsCloneDisable] = useState(false);
  const [visibleFigIndexes, setVisibleFigIndexes] = useState();
  const [resizingTimeDuration, setResizingTimeDuration] = useState();
  const [popoverOpen, setPopoverOpen] = useState();

  // custom hooks
  useSetActivePageBlock(activeContainerInfo);
  const { addPage } = useAddPage();
  const { clone } = useClonePage();
  const { deletePage } = useDeletePage();
  const { updatePageDuration } = useUpdatePageDuration();

  // all useEffects
  useEffect(() => {
    document.getElementById("timeline-body")?.addEventListener("scroll", getFigIndexInViewPort);
    document.querySelector(".panel-toggle")?.addEventListener("click", onViewPorResize);

    return () => {
      document.getElementById("timeline-body")?.removeEventListener("scroll", getFigIndexInViewPort);
      document.querySelector(".panel-toggle")?.removeEventListener("click", onViewPorResize);
    };
  });

  useEffect(() => {
    setContainerWidthBasedOnScale();
    window.addEventListener("resize", onViewPorResize);

    return () => {
      window.removeEventListener("resize", onViewPorResize);
    };
  }, []);

  // for scale wise page rendering
  useEffect(() => {
    setContainerWidthBasedOnScale();
  }, [props.scaleWidth, pageNodes.length]);

  // Handle collaboration
  useEffect(() => {
    setContainerWidthBasedOnScale();
  }, [pageNodes, changeBySocket]);

  //Calculate the count how much time the figure will repeat in the contianer
  useEffect(() => {
    let repeatedPages = [];
    let ittarateNumber = Math.ceil(resizableContainerWidth / figureWidth) + 1; // add +1 for load one extra fig to avoid loading while resizing

    for (let i = 0; i <= ittarateNumber; i++) {
      repeatedPages.push(props.pageNode.pageId);
    }
    setRenderRepeatedPages(repeatedPages);

    onViewPorResize();
  }, [resizableContainerWidth, pageNodes]);

  // Disable page add, clone option if total doc duration riched
  useEffect(() => {
    if (pageNodes.length > 0 && documentType !== constant.TYPE_INFOGRAPHIC) {
      let getDocDurationInfo = getDocumentDuration(pageNodes, metadata.activePageIdx);
      let availableTime = getDocDurationInfo.document.available;

      if (availableTime < 1) setIsAddDisable(true);
      else setIsAddDisable(false);

      if (availableTime < parseFloat(pageNodes[metadata.activePageIdx]?.pageDuration)) setIsCloneDisable(true);
      else setIsCloneDisable(false);
    }
  }, [pageNodes.length, pageNodes[metadata.activePageIdx]?.pageDuration]);

  // scroll to the active page when last page is added from the timeline
  useEffect(() => {
    setTimeout(() => {
      let targetOffset = 0;

      document.querySelectorAll(".scene-item").forEach(element => {
        targetOffset += element.offsetWidth;
      });

      let totalContainerRight = document.querySelector(".timeline-body").getBoundingClientRect().right;
      let addIconRight = document.querySelector(".add-scene")?.getBoundingClientRect().right;

      if (addIconRight && addIconRight >= totalContainerRight && blockNodes.length - 1 === metadata.activeBlockIdx) {
        document.querySelector(".timeline-body").scrollLeft = targetOffset;
      }
    }, 100);
  }, [metadata.activeBlockIdx]);

  // set blue line on active page in timeline section
  useEffect(() => {
    setTimeout(() => {
      if (metadata.activePageIdx === parseInt(props.index) && playingPageIdx === null) {
        document.querySelector(`#fig-${props.index}`)?.classList.add("active");
      } else {
        document.querySelector(`#fig-${props.index}`)?.classList.remove("active");
      }
    }, 100);
  }, [metadata.activePageIdx]);

  // Set Active container to active page
  const setActivePage = currentActiveIndex => {
    updateScrollEvent(true);

    setActiveContainerInfo({
      targetPageIdx: currentActiveIndex,
      targetBlockIdx: currentActiveIndex,
      disableAutoScroll: false,
    });
  };

  // handle click on transition in page container
  const handleTransitionClick = () => {
    if (document.getElementById("editorBody").classList.contains("hide-secondAside")) props.toggle();
    handleWidgetAction("page-transition", "Page Transition", "page_transition", 5, false, "page-setup");
  };

  // handle toggle of tripple dot in page container
  const toggle = () => {
    setDropdownOpen(prevState => !prevState);
  };

  // add page from triple dot in page container
  const add = () => {
    if (isAddDisable) return;
    updateScrollEvent(true); // To prevent auto scroll
    addPage();
  };

  //Function to show the delete modal
  const showModal = () => {
    setShowModalStatus(!showModalStatus);
  };

  //Function for close delete modal
  const closeModal = () => {
    setShowModalStatus(!showModalStatus);
  };

  // delete page from triple dot in page container
  const deletePageBlock = () => {
    updateScrollEvent(true); // To prevent auto scroll
    deletePage();
  };

  // set containerwidth based on scale(gape btw 0.00 to 0.01 timing)
  const setContainerWidthBasedOnScale = () => {
    let pageDuration = pageNodes[props.index].pageDuration;
    let preValue = parseInt(pageDuration.split(".")[0]);
    let postValue = parseInt(pageDuration.split(".")[1]);
    postValue = postValue ? postValue : 0;
    let targetContainerWidth = props.scaleWidth * preValue + (props.scaleWidth / 10) * postValue;
    setResizableContainerWidth(targetContainerWidth);
  };

  const onViewPorResize = () => {
    setTimeout(() => {
      getFigIndexInViewPort();
    }, 1000);
  };

  // get the fighure indexes which is in viewport
  const getFigIndexInViewPort = () => {
    let visibleIndexArray = [];
    document.querySelectorAll(".scene-item .content figure").forEach((element, idx) => {
      let isFigVisable = isElementVisible(element);
      if (isFigVisable) visibleIndexArray.push(element.getAttribute("id"));
    });
    setVisibleFigIndexes(visibleIndexArray);
  };

  // check when scroll timeline view which figure portion is visible
  const isElementVisible = element => {
    const elementLeft = element.getBoundingClientRect().left;
    const elementRight = elementLeft + element.getBoundingClientRect().width;
    const viewportLeft = window.pageXOffset;
    const viewportRight = viewportLeft + window.innerWidth;

    return elementRight > viewportLeft && elementLeft < viewportRight;
  };

  // page resize and sort
  const pageEvents = {
    meta: false,

    sort: {
      reIndexListItems: ({ client }) => {
        // To prevent auto scroll
        updateScrollEvent(true);
        const items = document.querySelectorAll(".sortable-item");

        let activeObj = {};
        let sortedPageNodes = [];
        let sortedBlockNodes = [];
        let linkedWidgetList = [];
        let newArray = Object.assign([...widgets]);

        [...items].forEach((item, newIdx) => {
          const oldIdx = item.getAttribute("data-index");
          sortedPageNodes.push({ ...pageNodes[oldIdx], pageIdx: newIdx });
          sortedBlockNodes.push({ ...blockNodes[oldIdx], blockIdx: newIdx });

          if (item.getAttribute("data-canvas-id") === client.pageId) {
            activeObj = {
              activePageId: client.pageId,
              activePageIdx: newIdx,
              activeBlockId: client.blockId,
              activeBlockIdx: newIdx,
              disableAutoScroll: false,
            };
          }
        });

        // get the widget list which are linked
        document.querySelectorAll(".dhp-widget-hyperlinked").forEach(element => {
          if (element.getAttribute("data-hyperlink-type") === "Page" && element.getAttribute("id"))
            linkedWidgetList.push(element.getAttribute("id"));
        });

        //update links in widget array
        linkedWidgetList.forEach(id => {
          let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
          let targetId = isGroupWidget
            ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id")
            : id;
          let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
          let getPageNode = sortedPageNodes.filter(
            sortedPageNode =>
              sortedPageNode.pageId === document.getElementById(id).getAttribute("data-hyperlink-pageid")
          );
          let getPageIdx = getPageNode[0].pageIdx;

          if (isGroupWidget) {
            document.getElementById(id).setAttribute("data-hyperlink-url", getPageIdx);

            newArray = Object.assign([...newArray], {
              [targetWidgetIndex]: {
                ...widgets[targetWidgetIndex],
                innerHTML: document.getElementById(targetId).innerHTML,
              },
            });
          } else {
            newArray = Object.assign([...newArray], {
              [targetWidgetIndex]: {
                ...widgets[targetWidgetIndex],
                data: {
                  ...widgets[targetWidgetIndex].data,
                  "data-hyperlink-url": getPageIdx,
                },
              },
            });
          }
        });

        updateWidgets(newArray, sortedPageNodes, sortedBlockNodes, false, false, false);
        updateMetadata({
          ...metadata,
          ...activeObj,
          pageController: {
            ...metadata.pageController,
            style: {
              ...metadata.pageController.style,
              top: metadata.activeBlockIdx === 0 ? 0 : document.getElementById(client.pageId).offsetTop + "px",
            },
          },
        });
      },

      sort: ({ container, client, scrollContainer, mouse, scrollType, lastIntersectedIndex }) => {
        const x = {
          DEFAULT: mouse.current.x,
          FORCED_LEFT: scrollContainer.rect.left + 1,
          FORCED_RIGHT: scrollContainer.rect.right - 1,
        };

        const { node: isItemIntersected } = searchNodeByPoint({
          className: "sortable-item",
          x: x[scrollType],
          y: client.center.y,
          exclude: client.node,
        });
        const intersectedIndex = isItemIntersected ? parseInt(isItemIntersected.getAttribute("data-index")) : false;

        if (isItemIntersected && intersectedIndex !== lastIntersectedIndex) {
          pageEvents.meta.lastIntersectedIndex = intersectedIndex;

          const items = [...document.querySelectorAll(".sortable-item")];
          const domIndexes = {
            client: -1,
            intersectedItem: -1,
          };

          items.forEach((item, i) => {
            const dataIndex = parseInt(item.getAttribute("data-index"));
            if (dataIndex === client.idx) domIndexes.client = i;
            if (dataIndex === intersectedIndex) domIndexes.intersectedItem = i;
          });

          const isIntersectedItemAdjacentToClient = Math.abs(domIndexes.client - domIndexes.intersectedItem) === 1;

          if (isIntersectedItemAdjacentToClient) {
            const newNode = mouse.initial.directionX === "RIGHT" ? isItemIntersected : client.node;
            const refNode = mouse.initial.directionX === "RIGHT" ? client.node : isItemIntersected;
            container.node.insertBefore(newNode, refNode);
          } else {
            // NOTE:::: When mouse moves extremely fast, some of the items skip intersecting and they remain unsorted.
            // Example 1 (mouse movement = RIGHT):::: At critical situation, a five page document may look like (1, 2, 5, 3, 4). i.e. After intersecting item (1 and 2), the mouse pointer jumps directly into item (5) by skipping item (3 and 4).
            // Example 2 (mouse movement = LEFT):::: The similar issue may appear for opposite direction (5, 4, 1, 3, 2)
            // Solution:::: For thsese kind of scenarios, sorting needs to be done within a range of unsortedIndexes (according to direction LEFT / RIGHT)

            if (mouse.initial.directionX === "RIGHT") {
              const unsortedIndexes = {
                start: domIndexes.client + 1,
                end: domIndexes.intersectedItem,
              };

              for (let i = unsortedIndexes.start; i <= unsortedIndexes.end; i++) {
                container.node.insertBefore(items[i], client.node);
              }
            }

            if (mouse.initial.directionY === "LEFT") {
              const unsortedIndexes = {
                start: domIndexes.client - 1,
                end: domIndexes.intersectedItem,
              };

              for (let i = unsortedIndexes.start; i >= unsortedIndexes.end; i--) {
                container.node.insertBefore(client.node, items[i]);
              }
            }
          }

          // NOTE:::: when doing insertBefore operation, sometimes scrollContainer's scrollWidth is changing for a moment and as a result,
          // the scrollbar is automatically moving from it's position. To prevent this, last known scrollLeft is re-assigning to the scrollContainer.
          if (
            mouse.initial.directionX === "LEFT" &&
            scrollContainer.node.scrollLeft !== scrollContainer.current.scrollLeft
          ) {
            scrollContainer.node.scrollLeft = scrollContainer.current.scrollLeft;
          }
        }
      },

      dragToScroll: ({ scrollContainer, mouse }) => {
        const dataScroll = pageEvents.meta.scrollContainer.node.getAttribute("data-scroll");

        if (dataScroll === "enabled" && mouse.initial.directionX) {
          // NOTE:::: mouse.initial.directionX is IMPORTANT because all conditions requires to know the INITIAL directionX so that later, the scrollType can be decided.
          if (
            mouse.current.x >= scrollContainer.rect.left &&
            mouse.current.x <= scrollContainer.rect.right &&
            mouse.current.directionX
          ) {
            // Condition-1: mouse INSIDE scrollContainer area (default scroll)
            // NOTE:::: mouse.current.directionX IS NOT FALSE (because INSIDE scrollContainer area CURRENT directionX can be interchanged)
            const {
              scrollFactor,
              initial: { scrollLeft: scrollLeftInitial },
            } = scrollContainer;

            const dx = Math.abs(mouse.current.x - mouse.initial.x);

            const scrollAmount =
              mouse.initial.directionX === "RIGHT"
                ? scrollLeftInitial + dx * scrollFactor
                : scrollLeftInitial - dx * scrollFactor;

            const newScrollLeft = scrollAmount >= 0 ? scrollAmount : 0;

            scrollContainer.node.scrollLeft = newScrollLeft;
            pageEvents.meta.scrollContainer.current.scrollLeft = newScrollLeft;
            pageEvents.meta.scrollContainer.scrollType = "DEFAULT";

            pageEvents.sort.sort({ ...pageEvents.meta, scrollType: "DEFAULT" });
          } else {
            // Condition-2: mouse OUTSIDE scrollContainer area (force scroll)
            // NOTE:::: mouse.current.directionX IS NOT CONSIDERED (because OUTSIDE scrollContainer area CURRENT directionX is not important as once it's OUTSIDE, INITIAL directionX is referenced in further nested conditions as follows)
            setTimeout(() => {
              if (mouse.initial.directionX === "RIGHT" && mouse.current.x > scrollContainer.rect.right) {
                const scrollAmount = scrollContainer.node.scrollLeft + 3;
                const newScrollLeft = scrollAmount >= 0 ? scrollAmount : 0;

                scrollContainer.node.scrollLeft = newScrollLeft;
                pageEvents.meta.scrollContainer.current.scrollLeft = newScrollLeft;
                pageEvents.meta.scrollContainer.scrollType = "FORCED_RIGHT";

                pageEvents.sort.sort({ ...pageEvents.meta, scrollType: "FORCED_RIGHT" });

                if (scrollContainer.node.scrollLeft < scrollContainer.scrollLeftMax) {
                  pageEvents.sort.dragToScroll({ ...pageEvents.meta });
                }
              }

              if (mouse.initial.directionX === "LEFT" && mouse.current.x < scrollContainer.rect.left) {
                const scrollAmount = scrollContainer.node.scrollLeft - 3;
                const newScrollLeft = scrollAmount >= 0 ? scrollAmount : 0;

                scrollContainer.node.scrollLeft = newScrollLeft;
                pageEvents.meta.scrollContainer.current.scrollLeft = newScrollLeft;
                pageEvents.meta.scrollContainer.scrollType = "FORCED_LEFT";

                pageEvents.sort.sort({ ...pageEvents.meta, scrollType: "FORCED_LEFT" });

                if (scrollContainer.node.scrollLeft > 0) {
                  pageEvents.sort.dragToScroll({ ...pageEvents.meta });
                }
              }
            }, 100);
          }
        }
      },

      updateDragPathCss: ({ client, dragPath, scrollContainer, mouse }) => {
        // bounding dragPath to scrollContainer area
        if (mouse.current.x >= scrollContainer.rect.left && mouse.current.x <= scrollContainer.rect.right) {
          /*<<< BACKUP:::: in-case the item need to be displayed as vertically center aligned with respect to mouse pointer, enable the below line and disable the line after.
          const posLeft = mouse.current.x - scrollContainer.rect.left - dragPath.node.clientWidth / 2;
          >>>*/
          const posLeft = mouse.current.x - scrollContainer.rect.left - client.pointerOffset.x;
          dragPath.node.style.transform = `translate(${`${posLeft}px`}, 0px)`;
        }
      },

      toggleDragPath: ({ action, client, dragPath }) => {
        if (action === "SHOW") {
          dragPath.node.classList.remove("d-none");
          client.node.classList.add("disable-pointer");
          document.getElementsByTagName("body")[0].classList.add("grabbing");
        }
        if (action === "HIDE") {
          dragPath.node.innerHTML = "";
          dragPath.node.style.cssText = "";
          dragPath.node.classList.add("d-none");
          client.node.classList.remove("disable-pointer");
          document.getElementsByTagName("body")[0].classList.remove("grabbing");
        }
      },

      togglePlaceholder: ({ action, client }) => {
        if (action === "SHOW") {
          client.contentNode.querySelectorAll("figure").forEach(elm => elm.classList.add("d-none"));
          client.transitionNode.classList.add("d-none");
          client.placeholderNode.classList.remove("d-none");
        }
        if (action === "HIDE") {
          client.contentNode.querySelectorAll("figure").forEach(elm => elm.classList.remove("d-none"));
          client.transitionNode.classList.remove("d-none");
          client.placeholderNode.classList.add("d-none");
        }
      },

      isMouseDirectionChanged: ({ scrollContainer, mouse }) => {
        if (
          mouse.initial.directionX &&
          mouse.current.directionX &&
          mouse.initial.directionX !== mouse.current.directionX
        ) {
          const scrollLeft = scrollContainer.node.scrollLeft;
          pageEvents.meta.scrollContainer.initial.scrollLeft = scrollLeft;
          pageEvents.meta.scrollContainer.current.scrollLeft = scrollLeft;
          pageEvents.meta.mouse.initial.x = mouse.current.x;
          pageEvents.meta.lastIntersectedIndex = false;

          // NOTE:::: `scrollContainer.scrollType = DEFAULT` (IS EQUALS TO) `INSIDE scrollContainer area` and CURRENT directionX can be interchanged, hence CURRENT directionX is updated.
          // So for other scrollTypes (FORCED_LEFT & FORCED_RIGHT) / `OUTSIDE scrollContainer area` if CURRENT directionX is updated then the scroll behaviour will be malfunctioning as once it's OUTSIDE, CURRENT directionX is not important.
          if (scrollContainer.scrollType === "DEFAULT")
            pageEvents.meta.mouse.initial.directionX = mouse.current.directionX;
        }
      },

      generateDragPathHTML: ({ client, dragPath }) => {
        dragPath.node.innerHTML = client.contentNode.outerHTML;
        replaceDuplicateIdFromSVG(dragPath.node, false, "nav-widget"); // Replace duplicate id for shape use in shape border
      },

      start: (data, e) => {
        e.preventDefault();
        let isRestrictedArea;
        const isLeftClick = e.button === 0;

        ["resizer", "page-transition-icon", "more-action"].forEach(cls => {
          if (e.target.closest(`.${cls}`) || e.target.classList.contains(cls)) isRestrictedArea = true;
        });

        if (!isLeftClick || isRestrictedArea) return;

        const client = sortableItemRef.current;
        const clientContent = client.querySelector(".content");
        const clientPlaceholder = clientContent.querySelector(".placeholder");
        const dragPath = document.getElementById("document-timeline-sort-path-display");
        const scrollContainer = document.querySelector(".timeline-body.customScroll");

        const { top: clientTop, left: clientLeft, height: clientHeight } = client.getBoundingClientRect();
        const {
          width: scrollContainerWidth,
          left: scrollContainerLeft,
          right: scrollContainerRight,
        } = scrollContainer.getBoundingClientRect();

        let props = {
          isSorting: true,
          isFirstEvent: true,
          container: {
            node: client.parentNode,
          },
          client: {
            node: client,
            idx: parseInt(data.idx),
            pageId: data.pageId,
            blockId: data.blockId,
            contentNode: clientContent,
            placeholderNode: clientPlaceholder,
            transitionNode: transitionRef.current,
            center: {
              y: clientTop + clientHeight / 2,
            },
            pointerOffset: {
              x: e.pageX - clientLeft + 0, // NOTE:::: here "0" is constant as "clientLeft" is somehow lacking by 22px due to some unknown reason.
            },
          },
          dragPath: {
            node: dragPath,
          },
          scrollContainer: {
            node: scrollContainer,
            rect: { left: scrollContainerLeft, right: scrollContainerRight },
            scrollFactor: scrollContainer.scrollWidth / scrollContainerWidth,
            scrollLeftMax: scrollContainer.scrollWidth - scrollContainerWidth,
            scrollType: false,
            initial: {
              scrollLeft: scrollContainer.scrollLeft,
            },
            current: {
              scrollLeft: false,
            },
          },
          mouse: {
            initial: {
              x: e.pageX,
              directionX: false,
            },
            current: {
              x: false,
              directionX: false,
            },
          },
          lastIntersectedIndex: false,
        };

        pageEvents.meta = props;
        pageEvents.sort.generateDragPathHTML({ ...props });
        scrollContainer.setAttribute("data-scroll", "enabled");
        document.addEventListener("mousemove", pageEvents.sort.sorting);
        document.addEventListener("mouseup", pageEvents.sort.stop);
      },

      sorting: e => {
        const meta = pageEvents.meta;
        if (meta?.isSorting) {
          e.preventDefault();
          meta.mouse.current.x = e.pageX;

          if (!meta.mouse.initial.directionX)
            meta.mouse.initial.directionX = e.movementX === 0 ? false : e.movementX > 0 ? "RIGHT" : "LEFT";
          if (meta.mouse.initial.directionX)
            meta.mouse.current.directionX = e.movementX === 0 ? false : e.movementX > 0 ? "RIGHT" : "LEFT";

          pageEvents.sort.isMouseDirectionChanged({ ...meta });
          pageEvents.sort.togglePlaceholder({ action: "SHOW", ...meta });
          pageEvents.sort.toggleDragPath({ action: "SHOW", ...meta });
          pageEvents.sort.updateDragPathCss({ ...meta });
          pageEvents.sort.dragToScroll({ ...meta });

          if (meta.isFirstEvent) {
            meta.isFirstEvent = false;
          }
        }
      },

      stop: e => {
        let meta = pageEvents.meta;
        if (meta?.isSorting) {
          e.preventDefault();
          pageEvents.sort.togglePlaceholder({ action: "HIDE", ...meta });
          pageEvents.sort.toggleDragPath({ action: "HIDE", ...meta });
          if (!meta.isFirstEvent) pageEvents.sort.reIndexListItems({ ...meta });
          meta.scrollContainer.node.removeAttribute("data-scroll");
          document.removeEventListener("mousemove", pageEvents.sort.sorting);
          document.removeEventListener("mouseup", pageEvents.sort.stop);
          meta = false;
        }
      },
    },

    resize: {
      updateReactDom: ({ client }) => {
        updatePageDuration(client.current.duration, client.index);
      },

      resizeOnAutoScroll: ({ delta }) => {
        const meta = pageEvents.meta;
        const {
          client: { node: container, minWidth: cminw, maxWidth: cmaxw },
        } = meta;
        const { width: containerWidth } = container.getBoundingClientRect();

        let width = containerWidth + delta;
        let resizeData = { width: containerWidth };

        if (width < cminw) resizeData = { ...resizeData, width: cminw };
        else if (width > cmaxw) resizeData = resizeData = { ...resizeData, width: cmaxw };
        else resizeData = { ...resizeData, width };

        meta.client.node.style.width = `${resizeData.width}px`;
        meta.client.initial.width = resizeData.width - delta;
        meta.mouse.initial.x = meta.mouse.current.x;
        meta.client.current = {
          ...resizeData,
          duration: parseFloat((resizeData.width / props.scaleWidth).toFixed(1)),
        };
        setResizableContainerWidth(resizeData.width);
        pageEvents.resize.updateTooltip({ ...meta });
        if (meta.client.current.width > meta.client.initial.width) props.setIsResizing(meta.client.current);
      },

      autoScroll: ({
        client: { node: client },
        mouse: {
          current: { x: mcx },
          intervalId,
        },
        scrollContainer: {
          node: scrollContainer,
          rect: { left: scrollContainerLeft, right: scrollContainerRight },
          autoScrollDx: dX,
        },
      }) => {
        pageEvents.meta.mouse.intervalId = setInterval(() => {
          if (scrollContainerRight - mcx <= 100) {
            scrollContainer.scrollLeft = scrollContainer.scrollLeft + 10;
            pageEvents.resize.resizeOnAutoScroll({ delta: 10 });
          } else if (mcx - scrollContainerLeft <= 100) {
            scrollContainer.scrollLeft = scrollContainer.scrollLeft - 10;
            pageEvents.resize.resizeOnAutoScroll({ delta: -10 });
          } else clearInterval(intervalId);
        }, 30);
      },

      updateTooltip: ({ client }) => {
        setPopoverOpen(true);
        setResizingTimeDuration(getSecToHMS({ sec: client?.current.duration }));

        // set tooltip left
        if (document.querySelector(".scene-duration-tooltip .popover")) {
          let currentClientRect = client.node.getBoundingClientRect();
          let tolltipLeft = currentClientRect.left + currentClientRect.width - 59;
          let popOverTransform = document
            .querySelector(".scene-duration-tooltip .popover")
            ?.style.transform.split("(")[1];
          popOverTransform = popOverTransform?.split(",");

          if (popOverTransform) {
            let newPopOverTransform = `translate3d(${tolltipLeft}px,${parseInt(popOverTransform[1])}px, ${parseInt(
              popOverTransform[2]
            )}px)`;
            document.querySelector(".scene-duration-tooltip .popover").style.transform = newPopOverTransform;
          }
        }
      },

      toggleResizeActions: ({ action }) => {
        const transition = transitionRef.current;
        const moreAction = actionRef.current;
        [transition, moreAction].forEach(item => {
          if (item) item.classList[action === "HIDE" ? "add" : "remove"]("d-none");
        });

        // hide other scene's hover elements(more action, resizer) on resize time
        if (action === "HIDE") {
          document.querySelectorAll(".page-number-tooltip").forEach(element => {
            element.style.display = "none";
          });
          document.querySelectorAll(".hover-resizer").forEach(element => {
            element.style.display = "none";
          });
          document.querySelectorAll(".more-action").forEach(element => {
            element.style.display = "none";
          });
        } else {
          document.querySelectorAll(".page-number-tooltip").forEach(element => {
            element.style.display = "block";
          });
          document.querySelectorAll(".hover-resizer").forEach(element => {
            element.style.display = "flex";
          });
          document.querySelectorAll(".more-action").forEach(element => {
            element.style.display = "block";
          });
        }
      },

      toggleEventCursorToDefault: ({ action }) => {
        const config = {
          SHOW: "ew-resize",
          HIDE: "",
        };
        document.getElementsByTagName("body")[0].style.cursor = config[action];
      },

      start: e => {
        if (e.button !== 0) return; // only applicable for left mouse click
        const container = resizeContainerRef.current;
        const currentContainerIndex = container.closest(".pagelist-item").getAttribute("data-index");
        const scrollContainer = document.querySelector(".timeline-body.customScroll");
        const containerRect = container?.getBoundingClientRect();
        const scrollContainerRect = scrollContainer.getBoundingClientRect();
        const scrollFactor = scrollContainer.scrollWidth / scrollContainerRect.width;
        const {
          activePage: {
            current: activePageDuration,
            scaledWidth: { min: minWidth, max: maxWidth },
          },
        } = getDocumentDuration(pageNodes, metadata.activePageIdx, props.scaleWidth);

        const data = {
          isResizing: true,
          isFirstEvent: true,
          client: {
            node: container,
            minWidth,
            maxWidth,
            initial: {
              width: containerRect.width,
              duration: activePageDuration,
            },
            current: {
              width: false,
              duration: false,
            },
            index: currentContainerIndex,
          },
          mouse: {
            initial: {
              x: e.pageX,
            },
            current: {
              x: false,
            },
            intervalId: false,
          },
          scrollContainer: {
            node: scrollContainer,
            rect: { left: scrollContainerRect.left, right: scrollContainerRect.right },
            autoScrollDx: scrollFactor * 10,
          },
        };

        pageEvents.meta = data;
        document.addEventListener("mousemove", pageEvents.resize.resizing);
        document.addEventListener("mouseup", pageEvents.resize.stop);
      },

      resizing: e => {
        const meta = pageEvents.meta;
        if (meta?.isResizing) {
          const {
            client: {
              // node: client,
              minWidth: cminw,
              maxWidth: cmaxw,
              initial: { width: ciw },
            },
            mouse: {
              initial: { x: mix },
              current: { x: mcx },
              intervalId,
            },
            scrollContainer: {
              rect: { left: scrollContainerLeft, right: scrollContainerRight },
            },
          } = meta;

          // event operations
          if (meta.isFirstEvent) {
            meta.isFirstEvent = false;
            pageEvents.resize.toggleResizeActions({ action: "HIDE" });
            pageEvents.resize.toggleEventCursorToDefault({ action: "SHOW" });
          }

          if (intervalId) {
            clearInterval(intervalId);
            meta.mouse.intervalId = false;
          }

          meta.mouse.current.x = e.pageX;

          // determine size of client after resize
          const dX = e.pageX - mix;

          let width;
          let resizeData = { width: ciw };

          // East Resizer | Right
          const delta = { w: dX };
          width = ciw + delta.w;

          if (width < cminw) resizeData = { ...resizeData, width: cminw };
          else if (width > cmaxw) resizeData = resizeData = { ...resizeData, width: cmaxw };
          else resizeData = { ...resizeData, width };

          // auto scroll during resize
          if (scrollContainerRight - mcx <= 100 || mcx - scrollContainerLeft <= 100) {
            pageEvents.resize.autoScroll({ ...meta });
          }
          // apply size of client after resize
          else {
            setResizableContainerWidth(resizeData.width);
            meta.client.current = {
              ...resizeData,
              duration: parseFloat((resizeData.width / props.scaleWidth).toFixed(1)),
            };

            pageEvents.resize.updateTooltip({ ...meta });
          }
        }
      },

      stop: () => {
        let meta = pageEvents.meta;
        if (meta?.isResizing) {
          if (!meta.isFirstEvent) {
            pageEvents.resize.updateReactDom({ ...meta });
          }
          props.setIsResizing(meta.client.current.width);
          clearInterval(meta.mouse.intervalId);
          pageEvents.resize.toggleResizeActions({ action: "SHOW" });
          pageEvents.resize.toggleEventCursorToDefault({ action: "HIDE" });
          document.removeEventListener("mousemove", pageEvents.resize.resizing);
          document.removeEventListener("mouseup", pageEvents.resize.stop);
          meta = false;
          setPopoverOpen(false);
        }
      },
    },
  };

  return (
    <>
      <>
        <li
          ref={sortableItemRef}
          className={cx(style["pagelist-item"], style["sortable-item"], style["scene-item"], style["custom-tooltip"], {
            [style["disable"]]: playingPageIdx !== null,
          })}
          id={`fig-${props.index}`}
          data-index={props.index}
          data-canvas-id={props.pageNode.pageId}
          onMouseDown={e => {
            setActivePage(props.index),
              pageEvents.sort.start(
                {
                  idx: props.index,
                  pageId: props.pageNode.pageId,
                  blockId: blockNodes[props.index].blockId,
                },
                e
              );
          }}>
          {/* Page Wise Container */}
          <div
            ref={resizeContainerRef}
            className={cx(style["d-flex"], style["content"], style["skeleton-loader-area"])}
            style={{ width: `${resizableContainerWidth}px`, overflow: "hidden" }}>
            {renderRepeatedPages.map((renderRepeatedPage, i) => (
              <Fragment key={i}>
                <figure
                  className={cx(style["m-0"])}
                  id={`figure-${props.index}${i}`}
                  style={{
                    height: figureHeight,
                    width: figureWidth,
                  }}>
                  {visibleFigIndexes?.includes(`figure-${props.index}${i}`) && (
                    <div data-page-idx={props.pageNode.pageIdx}>
                      {blockNodes.map(
                        blockNode =>
                          blockNode.pageId == renderRepeatedPage && (
                            <div
                              key={blockNode.blockId}
                              className="nav-block"
                              id={`dhp-nav-block-${blockNode.blockIdx}`}
                              data-block-idx={blockNode.blockIdx}
                              style={{
                                ...blockNode.style,
                                transform: `scale(${scaledval + 0.002})`,
                              }}>
                              {backgroundColors.map(
                                (backgroundColor, idx) =>
                                  backgroundColor.blockId == blockNode.blockId && (
                                    <div
                                      key={blockNode.blockId + "-" + idx}
                                      className="dhp-page-bgcolor-overlay"
                                      style={backgroundColor.style}></div>
                                  )
                              )}

                              {backgroundImages.map(
                                (backgroundImage, idx) =>
                                  backgroundImage.blockId == blockNode.blockId && (
                                    <div
                                      key={blockNode.blockId + "-" + idx}
                                      className="dhp-page-overlay"
                                      style={backgroundImage.style}></div>
                                  )
                              )}

                              {widgets.map(
                                widget =>
                                  widget.blockId == blockNode.blockId && (
                                    <Widget
                                      key={widget.id}
                                      widget={widget}
                                      pageSorterList={props.pageSorterList}
                                      scaleFactor={1}
                                    />
                                  )
                              )}
                            </div>
                          )
                      )}
                    </div>
                  )}
                  <span className={style["loader-item"]} style={{ height: figureHeight, width: figureWidth }}></span>
                </figure>
                <div
                  className={cx(style["placeholder"], style["d-none"])}
                  style={{ width: `${resizableContainerWidth}px`, height: figureHeight, overflow: "hidden" }}></div>
              </Fragment>
            ))}
          </div>

          {/* transition icon in container */}
          <div
            ref={transitionRef}
            className={cx(style["page-transition-icon"])}
            style={{ backgroundColor: props.pageNode.pageTransition.enabled ? "#47b972" : "black" }}
            onClick={handleTransitionClick}
            id={`doc-timeline-transition-icon${props.index}`}>
            <Icon icon="ui-transition"></Icon>
            <UncontrolledTooltip
              placement="top"
              target={`doc-timeline-transition-icon${props.index}`}
              boundariesElement={document.getElementById("app")}>
              Transition
            </UncontrolledTooltip>
          </div>

          {/* more action in hover in container */}
          <div ref={actionRef} className={cx(style["more-action"])}>
            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"right"}>
              <DropdownToggle tag="a" className={cx(style["dropdown-toggle"], style["custom-tooltip"])}>
                <Icon icon="ui-more" />
              </DropdownToggle>

              <DropdownMenu
                className={cx(style["shadow"], style["border-0"], style["rounded-sm"], style["more-page-action"])}
                container={targetContainer}>
                <DropdownItem
                  tag="a"
                  onClick={add}
                  className={cx({
                    [style["disabled"]]: isAddDisable,
                  })}>
                  <Icon icon="ui-plus" /> <span>Add {pageType}</span>
                </DropdownItem>

                <DropdownItem
                  tag="a"
                  onClick={() => {
                    if (isCloneDisable) return;
                    clone();
                  }}
                  className={cx({
                    [style["disabled"]]: isCloneDisable,
                  })}>
                  <Icon icon="ui-clone" /> <span>Clone {pageType}</span>
                </DropdownItem>

                <DropdownItem
                  tag="a"
                  onClick={showModal}
                  className={cx({
                    [style["disabled"]]: pageNodes.length === 1,
                  })}>
                  <Icon icon="ui-trash" /> <span>Delete {pageType}</span>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            {showModalStatus && (
              <Modal
                showModal={showModalStatus}
                setShowModal={setShowModalStatus}
                component={DeleteElemModal}
                modalTitle={modalData?.title}
                modalBody={modalData?.body}
                modalButtonLabel={modalData?.btnLabel}
                deleteElem={deletePageBlock}
                closeModal={closeModal}
              />
            )}
          </div>

          {/* resizer in hover in container */}
          <div
            className={cx(style["resizer"], { "hover-resizer": metadata.activePageIdx !== parseInt(props.index) })}
            ref={resizerRef}
            id={`l-resizer-${props.pageNode.pageId}`}
            onMouseDown={pageEvents.resize.start}>
            <Icon icon="ui-pause"></Icon>
          </div>

          {/* show page number tooltip in hover */}
          {!popoverOpen && (
            <div ref={tooltipRef} className={cx(style["custom-tooltip-content"], style["top"], "page-number-tooltip")}>
              Page {parseInt(props.index) + 1}
            </div>
          )}

          {/* show scene duration tooltip when resize */}
          <Popover
            className="scene-duration-tooltip"
            placement={"top"}
            isOpen={popoverOpen}
            target={`l-resizer-${props.pageNode.pageId}`}
            boundariesElement={document.getElementById("app")}>
            <PopoverBody>{resizingTimeDuration}</PopoverBody>
          </Popover>
        </li>
      </>
    </>
  );
};

export default DocumentTimeLineGenaretor;
