import React, { useContext } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";

const useGetTotalDocDuration = () => {
  let { pageNodes } = useContext(EditorContext);

  const calculatedocDuration = () => {
    let docTime = 0;

    pageNodes.forEach(pageNode => {
      docTime += parseFloat(pageNode.pageDuration);
    });
    return docTime.toFixed(1);
  };

  return { calculatedocDuration };
};

export default useGetTotalDocDuration;
