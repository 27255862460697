import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Button, Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { loadModalContent } from "../SubscriptionConfig";
import { sidebarOptions } from "../../Company/CompanyConfig";

import {
  AuditLogSection,
  DocumentCmntSection,
  DownloadQualitySection,
  GenericSection,
  PremiumAssetSection,
  PremiumTemplateSection,
  RemoveBGSection,
  UnlimitedDocsSection,
  UserAddSection,
  VersionHistorySection,
  AIImageSection,
  AIWriterSection,
  publishDownloadSection,
  publishPasswordSection,
  PremiumThemesSection,
  AITranslateSection,
  CompanyTemplateSection,
  emailDocumentLinkSection,
  publishBadgeSection,
  publishEmailVerificationSection,
} from "./UpgradeModalComponents";

let style = Object.assign({}, global);

const RightHtml = {
  generic: GenericSection,
  premiumTemplate: PremiumTemplateSection,
  unlimitedDocs: UnlimitedDocsSection,
  removeBG: RemoveBGSection,
  userAdd: UserAddSection,
  versionHistory: VersionHistorySection,
  auditLog: AuditLogSection,
  downloadQuality: DownloadQualitySection,
  premiumAsset: PremiumAssetSection,
  documentCmnt: DocumentCmntSection,
  aiImage: AIImageSection,
  aiWriter: AIWriterSection,
  publishDownload: publishDownloadSection,
  publishPassword: publishPasswordSection,
  premiumThemes: PremiumThemesSection,
  aiTranslate: AITranslateSection,
  companyTemplate: CompanyTemplateSection,
  sendTenEmailDocumentLink: emailDocumentLinkSection,
  addTenRecipientsDocumentLink: emailDocumentLinkSection,
  publishBadge: publishBadgeSection,
  publishEmailVerification: publishEmailVerificationSection,
};

const UpgradeModal = ({ upgradeType = "generic", setShowModal, modalWidgetUrl, data = {} }) => {
  const history = useHistory();
  const [currentModalContent, setCurrentModalContent] = useState();

  const Component = RightHtml[upgradeType];

  const close = () => {
    setShowModal(prevState => !prevState);
  };

  const goto = () => {
    close();
    history.push(sidebarOptions.subscription_url);
  };

  // Set current modal content based on upgradeType
  useEffect(() => {
    setCurrentModalContent(loadModalContent(upgradeType, modalWidgetUrl));
  }, []);

  return (
    <React.Fragment>
      <h4 className={cx(style["fw-7"], style["mb-4"])}>{currentModalContent?.title}</h4>

      <span aria-label="Close" className={cx(style["cross-modal"], style["rounded"])} onClick={close}>
        <Icon icon="ui-close" />
      </span>

      <Row className={cx(style["upgrade-content"], style["align-items-center"], style["justify-content-center"])}>
        <Col md={6} className={style["left"]}>
          <p>{currentModalContent?.headerContent}</p>
          <p className={style["fw-7"]}>DocHipo Pro Benefits</p>
          <ul className={cx(style["list-style-tick"], style["mb-3"])}>
            {currentModalContent?.description?.map((description, idx) => (
              <li key={idx} dangerouslySetInnerHTML={{ __html: description }}></li>
            ))}
          </ul>
          <Button color="primary" onClick={goto} className="w-100">
            Upgrade
          </Button>
          <span
            className={cx(
              style["w-100"],
              style["d-flex"],
              style["justify-content-center"],
              style["mt-1"],
              style["color-black"]
            )}>
            <span className={cx(style["fw-7"], style["pr-1"])}>100% </span> money-back guarantee for 7 days
          </span>
        </Col>

        <Col md={6} className={style["right"]}>
          <div className={style["right-con"]}>
            <Component imageUrl={modalWidgetUrl} {...data} />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

UpgradeModal.propTypes = {
  upgradeType: PropTypes.string,
  modalWidgetUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  data: PropTypes.object,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default UpgradeModal;
