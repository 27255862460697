import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";

import global from "../../scss/dhp.scss";
import { EditorContext } from "../../containers/editor/EditorLayout";
import { ANIMATION, PICTURE, STOCK, SVG, UPLOAD, VIDEO } from "../../constants/editor";

let style = Object.assign({}, global);

const SliderControll = props => {
  let { metadata } = useContext(EditorContext);
  let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const [inputValue, setInputValue] = useState(props.SliderInputValue ? props.SliderInputValue : 0);

  const updateSliderValueOnchange = e => {
    if (e.target?.validity.patternMismatch) return;

    setInputValue(e.target.value);
  };

  const updateSliderValue = e => {
    if (e && ((e.type === "keyup" && 13 === e.keyCode) || e.type === "blur")) {
      if (isNaN(Math.round(e.target.value)) || e.target.value === "") {
        props.setSlider(props.Slider);
        setInputValue(props.Slider);
      } else if (parseInt(e.target.value) < props.sliderMin) {
        props.setSlider(props.sliderMin);
      } else if (parseInt(e.target.value) > props.sliderMax) {
        props.setSlider(props.sliderMax);
      } else {
        props.setSlider(Math.round(e.target.value));
      }
      props?.setUpdateContext && props?.setUpdateContext(true);
      props?.setLoadCurrentFormating && props?.setLoadCurrentFormating(false);
    }
  };

  const updateSliderValueOnInput = e => {
    let isFloat = e.target.step.includes(".");

    props.setSlider(isFloat ? parseFloat(e.target.value) : Math.round(e.target.value));
    props.setSliderInputValue &&
      props.setSliderInputValue(isFloat ? parseFloat(e.target.value) : Math.round(e.target.value));

    if (isSafari && props.sliderLabel === "Border Radius")
      document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style.willChange = "";
    if (isSafari && props.sliderName === "shadow")
      document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style.willChange = "transform";
  };

  const handleMouseUp = () => {
    let currentElem = document.getElementById(metadata.activeWidgetId[0]);
    let flipableAvailable =
      metadata.activeWidgetType[0] === PICTURE ||
      metadata.activeWidgetType[0] === ANIMATION ||
      (metadata.activeWidgetType[0] === VIDEO && currentElem.dataset.source === STOCK) ||
      (metadata.activeWidgetType[0] === UPLOAD && currentElem.dataset.fileType !== SVG);
    let targetElem = flipableAvailable
      ? currentElem?.querySelector(".flippable")
      : currentElem?.querySelector(".dhp-widget-inner");
    let filterValue = targetElem?.style.filter;

    if (isSafari && filterValue !== "none" && props.sliderLabel === "Border Radius") {
      document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style.willChange = "transform";
    }
  };

  useEffect(() => {
    if (props.setSliderInputValue) {
      props.setSliderInputValue(props.Slider);
      setInputValue(props.Slider ? props.Slider : 0);
    }
  }, [props.Slider]);

  return (
    <>
      {props.sliderLabel && <div className={style["slidelabel"]}>{props.sliderLabel}</div>}

      <Input
        type="range"
        min={props.sliderMin}
        max={props.sliderMax}
        step={props?.step ?? 1}
        className="slider"
        value={props.Slider ? props.Slider : 0}
        id="sliderRange"
        onMouseDown={() => {
          props?.setUpdateContext && props?.setUpdateContext(false);
          props?.setLoadCurrentFormating && props?.setLoadCurrentFormating(false);
        }}
        onMouseUp={() => {
          props?.setUpdateContext && props?.setUpdateContext(true), handleMouseUp();
        }}
        onChange={e => updateSliderValueOnInput(e)}
        {...props.events}
      />

      {props.SliderInputValue === undefined && !props.hideSliderValue && <span className={style["value"]}>{props.Slider}</span>}

      {props.SliderInputValue !== undefined && (
        <Input
          name="slider-value"
          className={style["value"]}
          type="text"
          pattern="[0-9]*"
          value={inputValue}
          onChange={e => updateSliderValueOnchange(e)}
          // onBlur={e => updateSliderValue(e)}
          onKeyUp={e => updateSliderValue(e)}
        />
      )}
    </>
  );
};

//Props type validation
SliderControll.propTypes = {
  Slider: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSlider: PropTypes.func.isRequired,
  SliderInputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSliderInputValue: PropTypes.func,
  sliderMax: PropTypes.number.isRequired,
  sliderMin: PropTypes.number.isRequired,
  sliderLabel: PropTypes.string,
  step: PropTypes.number,
  setUpdateContext: PropTypes.func,
  setLoadCurrentFormating: PropTypes.func,
  sliderName: PropTypes.string,
  hideSliderValue : PropTypes.bool
};

export default SliderControll;
