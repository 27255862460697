import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { DropdownMenu, DropdownToggle, Input as RInput, UncontrolledDropdown } from "reactstrap";
import QRCode from "react-qr-code";

import global from "../../../scss/dhp.scss";

import AssetName from "./Common/AssetName";
import ColorPicker from "../../ui/colorPicker";
import { changeBucketColorAccordingBackground, hexToRGB, RGBToHex } from "../../../_helpers/utils";
import { Icon } from "../../ui/icon";
import { contextualConfig, widgetConfig } from "../editor_config";
import useElementInnerHtml from "../../../hooks/useElementInnerHtml";
import useAddWidget from "../../../hooks/useAddWidget";
import useAlignment from "../../../hooks/useAlignment";
import SliderControll from "../SliderControll";
import { QR_CODE } from "../../../constants/editor";
import { EditorContext } from "../../../containers/editor/EditorLayout";

let style = Object.assign({}, global);

const QrCode = props => {
  let { metadata, widgets, updateWidgets } = useContext(EditorContext);
  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);

  const [qrCodeValue, setQrCodeValue] = useState("");
  const [qrCodeColor, setQrCodeColor] = useState(widgetConfig[QR_CODE].defaultQrCodeColor);
  const [iconBucketQrCodeColor, setIconBucketQrCodeColor] = useState("light");
  const [qrCodeBackgroundColor, setQrCodeBackgroundColor] = useState(
    widgetConfig[QR_CODE].defaultQrCodeBackgroundColor
  );
  const [iconBucketQrCodeBackgroundColor, setIconBucketQrCodeBackgroundColor] = useState("light");
  const [margin, setMargin] = useState(widgetConfig[QR_CODE].defaultMargin);
  const [marginInputValue, setMarginInputValue] = useState();
  const [charLength, setCharLength] = useState(0);
  const [assetInnerContent, setAssetInnerContent] = useState();
  const [addWidgetClicked, setAddWidgetClicked] = useState(false);
  const [dataparam, setDataParam] = useState();
  const [eyedropperQrCodeColor, setEyedropperQrCodeColor] = useState();
  const [eyedropperQrCodeBgColor, setEyedropperQrCodeBgColor] = useState();

  const { postion: getPosition } = useAlignment(
    addWidgetClicked,
    widgetConfig[props.assetType]?.width,
    widgetConfig[props.assetType]?.height,
    "middle-center"
  );
  const getNewWidgetObject = useAddWidget(
    addWidgetClicked,
    props.assetType,
    dataparam,
    getPosition,
    widgetConfig[QR_CODE].width,
    widgetConfig[QR_CODE].height
  );
  useElementInnerHtml(
    addWidgetClicked,
    setAddWidgetClicked,
    props.assetType,
    assetInnerContent,
    dataparam,
    getNewWidgetObject
  );

  //update color if user chose color from eyedropper
  useEffect(() => {
    if (eyedropperQrCodeColor) {
      setQrCodeColor(eyedropperQrCodeColor);
      setEyedropperQrCodeColor();
    }
  }, [eyedropperQrCodeColor]);

  //Change Colorpicker icon bucket color, based on selected color for QR code color
  useEffect(() => {
    setIconBucketQrCodeColor(
      changeBucketColorAccordingBackground(qrCodeColor === "transparent" ? qrCodeColor : hexToRGB(qrCodeColor))
    );
  }, [qrCodeColor]);

  //update color if user chose color from eyedropper
  useEffect(() => {
    if (eyedropperQrCodeBgColor) {
      setQrCodeBackgroundColor(eyedropperQrCodeBgColor);
      setEyedropperQrCodeBgColor();
    }
  }, [eyedropperQrCodeBgColor]);

  //Change Colorpicker icon bucket color based on selected color for QR code Background color
  useEffect(() => {
    setIconBucketQrCodeBackgroundColor(
      changeBucketColorAccordingBackground(
        qrCodeBackgroundColor === "transparent" ? qrCodeBackgroundColor : hexToRGB(qrCodeBackgroundColor)
      )
    );
  }, [qrCodeBackgroundColor]);

  // update svg DOM margin and background color on change of background color, margin value and qr code value
  useEffect(() => {
    document.querySelector("#qr_code svg").style.height = "100%";
    document.querySelector("#qr_code svg").style.width = "100%";
    // document.querySelector("#qr_code svg").style.opacity = 1;

    if (qrCodeValue) {
      document.querySelector("#qr_code svg").style.padding = `${margin}%`;
      document.querySelector("#qr_code svg").style.backgroundColor = qrCodeBackgroundColor;
    } else {
      document.querySelector("#qr_code svg").style.padding = `${widgetConfig[QR_CODE].defaultMargin}%`;
      document.querySelector("#qr_code svg").style.backgroundColor = widgetConfig[QR_CODE].defaultQrCodeBackgroundColor;
    }
  }, [margin, qrCodeBackgroundColor, qrCodeValue]);

  // Load current formating of active widget if present, if not show default value
  useEffect(() => {
    if (
      metadata.activeWidgetId.length === 1 &&
      metadata.activeWidgetId[0] &&
      metadata.activeWidgetType[0] === QR_CODE
    ) {
      let multiColorSet = [];
      let activeQRCode = document.getElementById(metadata?.activeWidgetId[0]);
      activeQRCode.querySelectorAll(` svg *`).forEach(function (node) {
        if (node.nodeName !== "title") {
          let currentIconColor =
            window.getComputedStyle(node).fill === "rgba(0, 0, 0, 0)"
              ? "transparent"
              : RGBToHex(window.getComputedStyle(node).fill);

          multiColorSet.push(currentIconColor);
        }
      });
      setQrCodeValue(activeQRCode.getAttribute("data-qr-value"));
      setQrCodeBackgroundColor(multiColorSet[0]);
      setQrCodeColor(multiColorSet[1]);
      setMargin(activeQRCode.firstChild.style.padding.slice(0, -1));
      setCharLength(activeQRCode.getAttribute("data-qr-value").length);
    } else {
      setQrCodeValue("");
      setQrCodeBackgroundColor(widgetConfig[QR_CODE].defaultQrCodeBackgroundColor);
      setQrCodeColor(widgetConfig[QR_CODE].defaultQrCodeColor);
      setMargin(widgetConfig[QR_CODE].defaultMargin);
      setCharLength(0);
      document.getElementById("qrCodeText").value = "";
    }
  }, [metadata.activeWidgetId[0]]);

  // insert QR Code
  const insertQR = () => {
    setTimeout(() => {
      let dataAttr = {
        ...widgetConfig[QR_CODE].dataAttr,
        "data-qr-value": qrCodeValue,
      };
      let innerContent = document.querySelector("#qr_code svg");

      innerContent.style.opacity = 1;

      setAssetInnerContent(innerContent);
      setDataParam(dataAttr);
      setAddWidgetClicked(true);
    }, 1);
  };

  // update QR Code
  const updateQR = () => {
    let newArray;

    //Create node element according to updated QR Code
    let node = document.createElement("svg");
    node.innerHTML = document.querySelector("#qr_code svg").innerHTML;
    node.classList = document.querySelector("#" + id + " .dhp-widget-inner").classList;
    node.style.cssText = document.querySelector("#" + id + " .dhp-widget-inner").style.cssText;
    node.style.padding = `${margin}%`;
    node.style.backgroundColor = qrCodeBackgroundColor;
    node.style.height = "100%";
    node.style.width = "100%";
    node.setAttribute("xmlns", document.querySelector("#qr_code svg").getAttribute("xmlns"));
    node.setAttribute("viewBox", document.querySelector("#qr_code svg").getAttribute("viewBox"));
    node.setAttribute("width", document.querySelector("#qr_code svg").getAttribute("width"));
    node.setAttribute("height", document.querySelector("#qr_code svg").getAttribute("height"));

    if (isGroupWidget) {
      document.getElementById(id).innerHTML = node.outerHTML;
      document.getElementById(id).setAttribute("data-qr-value", qrCodeValue);

      newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgets[targetWidgetIndex],
          innerHTML: document.getElementById(targetId).innerHTML,
        },
      });
    } else {
      newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgets[targetWidgetIndex],
          data: {
            ...widgets[targetWidgetIndex].data,
            "data-qr-value": qrCodeValue,
          },
          innerHTML: node.outerHTML,
        },
      });
    }
    updateWidgets(newArray);
  };

  return (
    <div className={cx(style["editor-asset-inner"], style["qrcode-asset"])}>
      <AssetName handleWidgetAction={props.handleWidgetAction} assetName={props.assetName} />

      <div className={cx(style["customScroll"], style["scroll-Y"], style["assets-wrapper"])}>
        <div id="qr_code" className={cx(style["qr-genarate"], { [style["disabled"]]: !qrCodeValue })}>
          <QRCode
            value={qrCodeValue ? qrCodeValue : widgetConfig[QR_CODE].defaultValue}
            bgColor={qrCodeValue ? qrCodeBackgroundColor : widgetConfig[QR_CODE].defaultQrCodeBackgroundColor}
            fgColor={qrCodeValue ? qrCodeColor : widgetConfig[QR_CODE].defaultQrCodeColor}
            size={widgetConfig[QR_CODE].defaultSize}
          />
        </div>

        <label className={cx(style["custom-control-label"], style["fw-6"], style["mb-1"], style["font-15"])}>
          URL OR Text
        </label>
        <div className={cx(style["form-group"], style["required-mark"], style["mb-2"])}>
          <RInput
            type="text"
            id="qrCodeText"
            value={qrCodeValue}
            className={style["form-control"]}
            autoComplete="off"
            maxLength={widgetConfig[QR_CODE].defaultLength}
            onChange={e => {
              setQrCodeValue(e.target.value), setCharLength(e.target.value.length);
            }}
          />
          <p className={cx(style["m-0"], style["d-flex"], style["justify-content-end"])}>
            <span>{charLength}/200</span>
          </p>
        </div>

        {/* QR Code Customized section */}
        <label
          className={cx(
            style["custom-control-label"],
            style["fw-6"],
            style["font-15"],
            style["mb-2"],
            style["d-block"]
          )}>
          Customize
        </label>

        <div className={style["line-controls-wrap"]}>
          <div className={cx(style["slidelabel"], style["pl-0"])}>QR Code Color</div>
          <UncontrolledDropdown
            setActiveFromChild
            className={cx(style["color-section-block"], style["color-picker-dropdown"])}>
            <DropdownToggle tag="a" className={style["p-0"]}>
              <Icon
                additionalclass={iconBucketQrCodeColor}
                icon="ui-fill-color2"
                style={
                  qrCodeColor === "transparent"
                    ? {
                        backgroundImage: `url(${contextualConfig.colorUrl.midium})`,
                        backgroundSize: "cover",
                      }
                    : { backgroundColor: qrCodeColor }
                }
              />
            </DropdownToggle>
            <DropdownMenu className={cx(style["border-0"], style["p-0"])}>
              <ColorPicker
                color={qrCodeColor}
                setColor={setQrCodeColor}
                eyedropperColor={eyedropperQrCodeColor}
                setEyedropperColor={setEyedropperQrCodeColor}
              />
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>

        <div className={style["line-controls-wrap"]}>
          <div className={cx(style["slidelabel"], style["pl-0"])}>Background Color</div>
          <UncontrolledDropdown
            setActiveFromChild
            className={cx(style["color-section-block"], style["color-picker-dropdown"])}>
            <DropdownToggle tag="a" className={style["p-0"]}>
              <Icon
                additionalclass={iconBucketQrCodeBackgroundColor}
                icon="ui-fill-color2"
                style={
                  qrCodeBackgroundColor === "transparent"
                    ? {
                        backgroundImage: `url(${contextualConfig.colorUrl.midium})`,
                        backgroundSize: "cover",
                      }
                    : { backgroundColor: qrCodeBackgroundColor }
                }
              />
            </DropdownToggle>
            <DropdownMenu className={cx(style["border-0"], style["p-0"])}>
              <ColorPicker
                color={qrCodeBackgroundColor}
                setColor={setQrCodeBackgroundColor}
                eyedropperColor={eyedropperQrCodeBgColor}
                setEyedropperColor={setEyedropperQrCodeBgColor}
              />
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className={style["qr-margin"]}>
          <div className={style["font-15"]}>Margin</div>
          <div className={style["line-controls-wrap"]}>
            <SliderControll
              Slider={margin}
              setSlider={setMargin}
              SliderInputValue={marginInputValue}
              setSliderInputValue={setMarginInputValue}
              sliderMax={widgetConfig[QR_CODE].maxMargin}
              sliderMin={widgetConfig[QR_CODE].minMargin}
            />
          </div>
        </div>

        {/* Display Apply button if QR code value is given */}
        {qrCodeValue && (
          <div className={cx(style["mt-auto"], style["text-center"], style["fixed-bottom"])}>
            {metadata.activeWidgetId.length === 1 &&
            metadata.activeWidgetId[0] &&
            metadata.activeWidgetType[0] === QR_CODE ? (
              <span className={cx(style["btn"], style["btn-border"])} onClick={updateQR}>
                Update QR Code
              </span>
            ) : (
              <span className={cx(style["btn"], style["btn-border"])} onClick={insertQR}>
                Generate QR Code
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
//Props type validation
QrCode.propTypes = {
  handleWidgetAction: PropTypes.func.isRequired,
  assetName: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
};

export default QrCode;
