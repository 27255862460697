import React from "react";
import PropTypes from "prop-types";
import {
  Input as RInput,
  InputGroup,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Button,
  CustomInput,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";
import cx from "classnames";

import { Icon } from "./icon";
import Select2 from "./Select2";
import MultiSelect from "./multiSelect";
import TagsInput from "./tagsInput";
import ContentEditable from "./ContentEditable";
import Multiline from "./multiline";
import MultiSelectPage from "./multiSelectPage";

import global from "../../scss/dhp.scss";
import LoadFlagImage from "./LoadFlagImage";
import EmailRecipient from "./emailRecipient";
import ColorPalette from "./ColorPalette";
import Calendar from "./Calendar";

let style = Object.assign({}, global);

export const Input = ({
  returnType,
  addonType,
  groupId,
  type,
  toggleHandler,
  checkBoxClass,
  updateState,
  spinnerClass,
  onIncrease,
  onDecrease,
  extn,
  isdOptions,
  fields,
  phoneDefaultValue,
  radioClass,
  customFileClass,
  maxLength,
  minLength,
  inputGroupClass,
  multilineClass,
  decreasedisable,
  increasedisable,
  formGroupClass,
  ...props
}) => {
  let inputElement;
  let inputGroupAddon;
  let inputComponent;
  let inputType = ["search"].includes(type) ? "text" : ["password_length"].includes(type) ? "password" : type;

  let rInput = (
    <RInput
      autoComplete="off"
      type={inputType}
      maxLength={maxLength ?? 255}
      minLength={minLength ?? 1}
      cssModule={style}
      {...props}
    />
  );

  if (!returnType || returnType === "formGroup") {
    switch (type) {
      case "text":
        inputElement = (
          <InputGroup
            cssModule={style}
            className={cx(style["icon-right"], { [style["disable-field"]]: props.readOnly })}>
            {rInput}
            {props.readOnly && (
              <InputGroupAddon addonType="append" cssModule={style} className={cx(style["input-group-addon"])}>
                <Icon icon="ui-lock2" />
              </InputGroupAddon>
            )}
          </InputGroup>
        );
        break;

      case "url":
        inputElement = (
          <InputGroup
            cssModule={style}
            className={cx(style["icon-right"], { [style["disable-field"]]: props.readOnly })}>
            {rInput}
            {props.readOnly && (
              <InputGroupAddon addonType="append" cssModule={style} className={cx(style["input-group-addon"])}>
                <Icon icon="ui-lock2" />
              </InputGroupAddon>
            )}
          </InputGroup>
        );
        break;

      case "email":
        inputElement = (
          <InputGroup
            className={cx(style["icon-right"], {
              [style["disable-field"]]: props.readOnly,
            })}>
            {rInput}
            {props.readOnly && (
              <div className="input-group-addon">
                <Icon icon="ui-lock2" />
              </div>
            )}
          </InputGroup>
        );
        break;

      case "password":
      case "text_password":
        inputElement = (
          <InputGroup cssModule={style} className={style["icon-right"]}>
            {rInput}
            <InputGroupAddon
              addonType="append"
              cssModule={style}
              className={cx(style["input-group-addon"], style["togglepassword"])}>
              <Link to="#" onClick={e => e.preventDefault()}>
                <Icon
                  icon={type === "text_password" ? "eye-cross" : "eye2"}
                  onClick={() => toggleHandler(props.name)}
                />
              </Link>
            </InputGroupAddon>
          </InputGroup>
        );
        break;

      case "phone":
        inputElement = (
          <InputGroup cssModule={style} className={cx(style["icon-left"], style["field-with-dropdown"])}>
            <InputGroupAddon addonType="append" cssModule={style} className={style["input-group-addon"]}>
              <Select2
                classNamePrefix="select"
                customLabel={true}
                labelField="isd_code"
                defaultValue={phoneDefaultValue}
                getOptionLabel={option => (
                  <span className={style["option-label"]}>
                    <LoadFlagImage isoCode={option.iso_code} />
                    {option.name} <span className={cx(style["isd-code"])}> +{option.isd_code}</span>
                  </span>
                )}
                subField="iso_code"
                updateState={updateState}
                getOptionValue={option => [option.name, option.isd_code]}
                options={isdOptions}
                cssModule={style}
                {...props}
              />
            </InputGroupAddon>

            <RInput
              type="text"
              pattern="[0-9]*"
              value={fields["pnumber"]}
              onChange={e => updateState(e, props.name, "pnumber")}
              className={style["px-1"]}
              cssModule={style}
              {...props}
            />
            {extn && (
              <InputGroupAddon
                addonType="prepend"
                cssModule={style}
                className={cx(style["input-group-addon"], style["w-25"])}>
                <RInput
                  type="number"
                  placeholder="Extn"
                  value={fields["extn"]}
                  onChange={e => updateState(e, props.name, "extn")}
                  cssModule={style}
                  {...props}
                />
              </InputGroupAddon>
            )}
          </InputGroup>
        );
        break;

      case "checkbox":
        inputElement = (
          <div className={cx(style["custom-control"], style["custom-checkbox"])}>
            {rInput}
            <Label className={style["custom-control-label"]} htmlFor={props.id}>
              {props.text}
            </Label>
          </div>
        );
        break;

      case "radio":
        inputElement = (
          <div className={cx(style["custom-control"], style["custom-radio"], style["custom-control-inline"])}>
            {rInput}
            <Label className={style["custom-control-label"]} htmlFor={props.id}>
              {props.text}
            </Label>
          </div>
        );
        break;

      case "dropdown":
        inputElement = <Select2 updateState={updateState} cssModule={style} {...props} />;
        break;

      case "colorpalette":
        inputElement = <ColorPalette {...props} />;
        break;

      case "password_length":
        inputElement = rInput;
        break;

      case "spinner":
        {
          inputElement = (
            <div className="input-area">
              <InputGroup className={inputGroupClass}>
                <InputGroupAddon addonType="prepend">
                  <Button
                    type="button"
                    outline
                    color="secondary"
                    className="btn-minus btn-decrement"
                    disabled={decreasedisable ?? false}>
                    {decreasedisable && <span className="disable">-</span>}
                    {!decreasedisable && <strong onClick={() => onDecrease(props.id)}>-</strong>}
                  </Button>
                </InputGroupAddon>
                <RInput autoComplete="off" type="number" cssModule={style} {...props} />{" "}
                <InputGroupAddon addonType="append">
                  <Button
                    type="button"
                    outline
                    color="secondary"
                    className="btn-plus btn-increment"
                    disabled={increasedisable ?? false}>
                    {increasedisable && <span className="disable">+</span>}
                    {!increasedisable && <strong onClick={() => onIncrease(props.id)}>+</strong>}
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </div>
          );
        }
        break;

      case "tag":
        inputElement = <TagsInput updateState={updateState} cssModule={style} {...props} />;
        break;

      case "file":
        inputElement = (
          <>
            <Label for={props.id}>{props.name}</Label>
            <CustomInput type="file" id={props.id} name="customFile" label={props.filename} {...props} />
          </>
        );
        break;

      case "multi_select":
        inputElement = <MultiSelect updateState={updateState} cssModule={style} {...props} />;
        break;

      case "multiline":
        const property = maxLength ? { ...props, maxLength } : props;
        inputElement = <Multiline className={style["form-control"]} {...property} />;
        break;

      case "multi_select_page":
        inputElement = <MultiSelectPage updateState={updateState} cssModule={style} {...props} />;
        break;

      case "social":
        inputElement = (
          <InputGroup
            className={cx(style["icon-right"], {
              [style["disable-field"]]: props.readOnly,
            })}>
            {rInput}
            {props.readOnly && (
              <div className="input-group-addon">
                <img src={props.addonicon} alt="social Icon" className={style["pr-3"]} />
              </div>
            )}
          </InputGroup>
        );
        break;

      case "email_recipient":
        inputElement = <EmailRecipient updateState={updateState} cssModule={style} {...props} />;
        break;

      case "date_calendar":
        inputElement = <Calendar updateState={updateState} cssModule={style} {...props} />;
        break;

      default:
        break;
    }

    inputComponent = (
      <FormGroup
        cssModule={style}
        className={cx(
          {
            [style[spinnerClass]]: type === "spinner",
            [style["required-mark"]]: props.required,
            [style[checkBoxClass]]: type === "checkbox",
            [style[customFileClass]]: type === "file",
            [style[multilineClass]]: multilineClass,
          },
          radioClass,
          formGroupClass
        )}>
        {props.label && (
          <Label className={cx(style["align-items-center"], style["d-flex"])}>
            <div>{props.label}</div>
            {props.showinfo && (
              <div className={style["pl-2"]}>
                <Icon icon="information" id="FieldInfoToolTip" additionalclass="font-14" />
                <UncontrolledTooltip placement="bottom" target="FieldInfoToolTip" innerClassName="mw-100">
                  {props.showinfo}
                </UncontrolledTooltip>
              </div>
            )}
          </Label>
        )}
        {inputElement}
        {type !== "password_length" && props?.showerror !== "" && (
          <div className={cx(style["alert"], style["alert-danger"])}>{props.showerror}</div>
        )}
        {type === "password_length" && (
          <div
            className={cx(
              style["align-items-center"],
              style["d-flex"],
              style["justify-content-between"],
              style["w-100"]
            )}>
            <div
              className={cx(style["alert"], {
                [style["text-danger"]]: props?.showerror !== "",
                [style["text-success"]]: props?.showsuccess !== "",
              })}>
              {props?.showerror !== "" && props.showerror}
              {props?.showsuccess !== "" && (
                <div className={cx(style["align-items-center"], style["d-flex"], style["w-100"])}>
                  <Icon icon="tick-success" additionalclass="mr-1"></Icon>
                  {props.showsuccess}
                </div>
              )}
            </div>
            <span>
              {props.length} / {props.limit}
            </span>
          </div>
        )}
      </FormGroup>
    );
  }

  if (returnType && returnType === "inputGroup") {
    switch (type) {
      case "search":
        inputGroupAddon = (
          <InputGroupAddon addonType={addonType}>
            <InputGroupText className={style["bg-white"]}>
              <Icon icon="ui-search" />
            </InputGroupText>
          </InputGroupAddon>
        );
        inputComponent = (
          <InputGroup id={groupId} cssModule={style} className={style["flex-nowrap"]}>
            {addonType === "prepend" && inputGroupAddon}
            {rInput}
            {addonType === "append" && inputGroupAddon}
          </InputGroup>
        );
        break;

      default:
        break;
    }
  }

  if (returnType && returnType === "noGroup") {
    switch (type) {
      case "text":
        inputComponent = rInput;
        break;

      case "textarea":
        inputComponent = rInput;
        break;

      case "checkbox":
        inputComponent = (
          <React.Fragment>
            {rInput}
            <Label className={style["custom-control-label"]} htmlFor={props?.id}>
              {props?.label}
            </Label>
          </React.Fragment>
        );
        break;

      case "contentEditable":
        inputComponent = <ContentEditable {...props} />;
        break;

      case "radio":
        inputComponent = (
          <div
            className={cx(
              style["custom-control"],
              style["custom-radio"],
              style["custom-control-inline"],
              style[`${["portrait", "landscape"].includes(props.value) ? props.value : ""}`]
            )}>
            {rInput}
            <Label className={style["custom-control-label"]} htmlFor={props.id}>
              {props.text}
            </Label>
            {props.icon && <Icon icon={props.icon} />}
          </div>
        );
        break;

      default:
        break;
    }
  }

  return inputComponent;
};

Input.propTypes = {
  extn: PropTypes.bool,
  isdOptions: PropTypes.array,
  fields: PropTypes.object,
  updateState: PropTypes.func,
  phoneDefaultValue: PropTypes.object,
  type: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  toggleHandler: PropTypes.func,
  checkBoxClass: PropTypes.string,
  returnType: PropTypes.string,
  addonType: PropTypes.string,
  groupId: PropTypes.string,
  text: PropTypes.any,
  id: PropTypes.string,
  showerror: PropTypes.string,
  showsuccess: PropTypes.string,
  length: PropTypes.number,
  limit: PropTypes.number,
  spinnerClass: PropTypes.string,
  onIncrease: PropTypes.func,
  onDecrease: PropTypes.func,
  radioClass: PropTypes.string,
  customFileClass: PropTypes.string,
  inputGroupClass: PropTypes.string,
  multilineClass: PropTypes.string,
  decreasedisable: PropTypes.bool,
  increasedisable: PropTypes.bool,
  formGroupClass: PropTypes.string,
};
