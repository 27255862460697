import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { TABLE, TEXT } from "../../../constants/editor";
import useFontFormatting from "../../../hooks/useFontFormatting";

let style = Object.assign({}, global);

const Underline = ({ fontUnderline, setFontUnderline, sourceComponent }) => {
  let { metadata, widgets, doubleClickActive, selectionRange } = useContext(EditorContext);

  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
  let timeOut;

  const [isUnderLine, setIsUnderLine] = useState();

  const { updateTextDecoration, checkSelectedPartFormat } = useFontFormatting();

  const handleOnClick = () => {
    // if double click active return false cause selcted part will be underline
    if (doubleClickActive) return;

    // set italic for table
    if (setFontUnderline) setFontUnderline(!fontUnderline);
    else {
      updateTextDecoration();

      let gradActive = document.getElementById(id).getAttribute("data-grad-scolor");
      let textDecorationValue = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style
        .textDecoration;

      if (gradActive) textDecorationValue = textDecorationValue.split(" ")[0];
      textDecorationValue === "underline" ? setIsUnderLine("none") : setIsUnderLine("underline");
    }
  };

  const loadCurrentFormatting = () => {
    timeOut = setTimeout(() => {
      if (metadata.activeWidgetId && sourceComponent !== TABLE) {
        let gradActive = document.getElementById(id).getAttribute("data-grad-scolor");
        let innerHtmlflag = document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`);
        if (innerHtmlflag) {
          if (gradActive) setIsUnderLine(innerHtmlflag.style.textDecoration?.split(" ")[0]);
          else setIsUnderLine(innerHtmlflag.style.textDecoration);
        }
      }
    }, 10);
  };

  // Load current formatting for selected part of text
  useEffect(() => {
    if (selectionRange) {
      let { isUnderLineSelPart } = checkSelectedPartFormat();
      setIsUnderLine(isUnderLineSelPart ? "underline" : isUnderLineSelPart);
    }
  }, [selectionRange]);

  //Load current formatting for text
  useEffect(() => {
    if (metadata.activeWidgetType[0] === TEXT) {
      loadCurrentFormatting();
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [metadata.activeWidgetId, widgets[targetWidgetIndex]?.innerHTML]);

  // Load current formatting for table
  useEffect(() => {
    if (fontUnderline) setIsUnderLine("underline");
    else setIsUnderLine("none");
  }, [fontUnderline]);

  return (
    <li
      className={cx(style["toolset-group"], {
        [style["active"]]: isUnderLine === "underline",
      })}
      onClick={handleOnClick}>
      <div className={cx(style["toolset-item"], style["underline-wrap"])} id="underline-wrap">
        <span className={cx(style["toolset-action"], style["custom-tooltip"])}>
          <Icon icon="ui-underline" />
          <div className={cx(style["custom-tooltip-content"], style["top"])}>Underline</div>
        </span>
      </div>
    </li>
  );
};

Underline.propTypes = {
  fontUnderline: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setFontUnderline: PropTypes.func,
  sourceComponent: PropTypes.string,
};

export default Underline;
