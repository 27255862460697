import React, { useState, useEffect, useContext } from "react";
import cx from "classnames";
import { DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, TabContent, UncontrolledDropdown } from "reactstrap";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { widgetConfig } from "../editor_config";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import LoadToolbarComponent from "./LoadToolbarComponent";
import { AI_WRITER, ARRANGE, LIST, OPACITY, SVG, TRANSFORM, UPLOAD, VIDEO } from "../../../constants/editor";
import TransformAction from "./TransformAction";
import ListAction from "./ListAction";
import WidgetOpacity from "./WidgetOpacity";
import Layer from "./Layer";
import Align from "./Align";
import Rotate from "./Rotate";
import AiWriterAction from "./AiWriterAction";

let style = Object.assign({}, global);

const More = props => {
  let { metadata } = useContext(EditorContext);

  const [activeTab, setActiveTab] = useState(0);
  const [moreContextualMenubarList, setMoreContextualMenubarList] = useState(null);
  const [arrangeContextualMenubarList, setArrangeContextualMenubarList] = useState(null);
  const [moreSecondaryOptionClicked, setmoreSecondaryOptionClicked] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const iconClassName = {
    Layer: "bring-front",
    Align: "left-align",
    Rotate: "rotate-right",
  };
  const wrapClassName = moreSecondaryOptionClicked ? `${moreSecondaryOptionClicked.toLowerCase()}-wrap` : "more-wrap";

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleDropDown = e => {
    if (e.target.closest("#linkToAnotherPageForm")) setDropdownOpen(true);
    else setDropdownOpen(prevState => !prevState);

    // prevent to show transform or list or any secondary option from more option after outside click
    if (!document.querySelector(".more-menu").classList.contains("show")) setmoreSecondaryOptionClicked(false);
  };

  // set more contextual menu bar list according active widet type
  useEffect(() => {
    setMoreContextualMenubarList(widgetConfig[metadata.activeWidgetType[0]]?.contextualMenu?.more);
    return () => setMoreContextualMenubarList(null);
  }, [metadata.activeWidgetId]);

  // set arrange sub menus when arrange menu option is clicked from more section
  useEffect(() => {
    if (moreSecondaryOptionClicked === "Arrange") {
      if (
        metadata.activeWidgetType[0] === UPLOAD &&
        document.getElementById(metadata.activeWidgetId[0])?.dataset?.fileType === SVG
      ) {
        setArrangeContextualMenubarList(widgetConfig[metadata.activeWidgetType[0]][SVG]?.contextualMenu?.arrange);
      } else if (metadata.activeWidgetType[0] === VIDEO) {
        setArrangeContextualMenubarList(
          widgetConfig[metadata.activeWidgetType[0]][
            document.getElementById(metadata.activeWidgetId[0])?.dataset?.source
          ]?.contextualMenu?.arrange
        );
      } else {
        setArrangeContextualMenubarList(widgetConfig[metadata.activeWidgetType[0]]?.contextualMenu?.arrange);
      }

      return () => setArrangeContextualMenubarList(null);
    }
  }, [moreSecondaryOptionClicked]);

  return (
    <li className={style["toolset-group"]}>
      <UncontrolledDropdown
        className={cx(style["toolset-item"], style[wrapClassName], "more-menu")}
        isOpen={dropdownOpen}
        toggle={e => toggleDropDown(e)}>
        <DropdownToggle
          tag="span"
          className={cx(style["p-0"], style["toolset-action"], style["more-action"], style["custom-tooltip"])}
          onClick={() => setmoreSecondaryOptionClicked(false)}>
          <Icon icon="ui-more" />
          <div className={cx(style["custom-tooltip-content"], style["top"])}>More</div>
        </DropdownToggle>

        {!moreSecondaryOptionClicked && (
          <DropdownMenu className={cx(style["border-0"], style["shadow"])}>
            <ul>
              {moreContextualMenubarList?.map((contextMenue, idx) => (
                <LoadToolbarComponent
                  key={idx}
                  component={contextMenue}
                  more={true}
                  setmoreSecondaryOptionClicked={setmoreSecondaryOptionClicked}
                  toggle={props.toggle}
                />
              ))}
            </ul>
          </DropdownMenu>
        )}

        {moreSecondaryOptionClicked === TRANSFORM && <TransformAction />}

        {moreSecondaryOptionClicked === LIST && <ListAction />}

        {moreSecondaryOptionClicked === OPACITY && <WidgetOpacity isFromMore={true} />}

        {moreSecondaryOptionClicked === ARRANGE && (
          <DropdownMenu className={cx(style["shadow"], style["border-0"], style["rounded"])}>
            <Nav pills>
              {arrangeContextualMenubarList?.map((contextMenue, idx) => (
                <NavItem key={idx}>
                  <NavLink
                    className={cx({ active: activeTab === idx })}
                    onClick={() => {
                      toggle(idx);
                    }}>
                    <Icon icon={`ui-${iconClassName[contextMenue]}`} />
                    {contextMenue}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>

            <TabContent activeTab={activeTab} className={style["mt-3"]}>
              <Layer idx={0} setDropdownOpen={setDropdownOpen} />
              <Align idx={1} setDropdownOpen={setDropdownOpen} />
              <Rotate idx={2} setDropdownOpen={setDropdownOpen} dropdownOpen={dropdownOpen} />
            </TabContent>
          </DropdownMenu>
        )}

        {moreSecondaryOptionClicked === AI_WRITER && <AiWriterAction />}
      </UncontrolledDropdown>
    </li>
  );
};
More.propTypes = {
  toggle: PropTypes.func,
};

export default More;
