import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";
import {
  customRound,
  floatToFloatSec,
  getHMSToSec,
  operateStorageData,
  playMedia,
  stringTocamelCase,
} from "../_helpers/utils";
import {
  BRAND,
  COLLAGE,
  DOCUMENT_PLAY_DATA_STORAGE_KEY,
  PAGE_PLAY_DATA_STORAGE_KEY,
  PAGE_TIMELINE_LEFT_SECTION,
  STOCK,
  UPLOAD_VIDEO,
  VIDEO,
} from "../constants/editor";

let intervalId;

const animationSpeedToDurationMsObj = {
  Fast: 500,
  Medium: 1000,
  Slow: 2000,
};

const usePagePlay = () => {
  const {
    widgets,
    pageNodes,
    pagePlayData,
    setPagePlayData,
    whichPagePlaying,
    setWhichPagePlaying,
    playingPageIdx,
    setPlayingPageIdx,
    metadata,
    updateMetadata,
    isTimeLineViewOpen,
    handleWidgetAction,
  } = useContext(EditorContext);
  const [action, setAction] = useState(null);

  const getPageData = () => {
    let storage_data;
    if (!pagePlayData.pageId) {
      storage_data = operateStorageData({
        type: "session",
        action: "get",
        key: isTimeLineViewOpen === "page-timeline" ? PAGE_PLAY_DATA_STORAGE_KEY : DOCUMENT_PLAY_DATA_STORAGE_KEY,
        isParseable: true,
        defaultData: {},
      });
      console.log(storage_data);
    }
    const pageId = pagePlayData.pageId ?? storage_data.pageId;
    const pageIdx = parseInt(pagePlayData.pageIdx ?? storage_data.pageIdx);
    const pageObj = pageNodes[pageIdx];
    const pageDurationMs = parseFloat(pageObj?.pageDuration ?? 0) * 1000;

    return {
      pageId,
      pageIdx,
      pageObj,
      pageDurationMs,
      scrollData: getScrollData(),
    };
  };

  const getScrollData = () => {
    const scrollContainer = document.getElementById("timeline-body");
    const count = Math.ceil(scrollContainer.scrollWidth / scrollContainer.offsetWidth);
    const breakPoints = [...Array(count)].map((_, i) => scrollContainer.offsetWidth * i);
    const rangePoints = breakPoints.map((p, i) => {
      const nextPoint = breakPoints[i + 1];
      return {
        min: p - PAGE_TIMELINE_LEFT_SECTION,
        max: (nextPoint ? nextPoint - 1 : scrollContainer.scrollWidth) - PAGE_TIMELINE_LEFT_SECTION,
      };
    });

    return { rangePoints };
  };

  const getWidgetData = ({ widgetObj, pageDurationMs }) => {
    const { startTime = 0, endTime = pageDurationMs } = widgetObj?.duration ?? {};
    const startTimeMs = parseFloat(startTime) * 1000;
    const endTimeMs = parseFloat(endTime) * 1000;
    const stopTimeMs = endTimeMs > pageDurationMs ? pageDurationMs : endTimeMs;
    const widgetNode = document.getElementById(widgetObj.id);
    const assetType = widgetObj.data["data-asset-type"];
    const source = widgetObj.data["data-source"];

    return { startTimeMs, stopTimeMs, widgetNode, assetType, source };
  };

  const getVideoNodeAndDataRef = ({ widgetId, widgetNode, videoNode }) => {
    const widget = widgetId ? document.getElementById(widgetId) : widgetNode;
    const assetTypeCollage = widget.dataset.assetType === COLLAGE;
    const video = assetTypeCollage ? videoNode : widget?.querySelector("video");
    const { duration: durationInHMS, volume, trim, loop } = assetTypeCollage ? video?.dataset : widget?.dataset;
    const durationInSec = getHMSToSec({ hms: durationInHMS });
    const trimArray = trim ? trim.split(",")?.map(e => parseFloat(e)) : null;
    const isTrimmed = Boolean(trimArray?.length === 2);
    const trimStart = isTrimmed ? trimArray?.[0] : 0;
    const trimEnd = isTrimmed ? trimArray?.[1] : durationInSec;

    return {
      widget,
      video,
      src: video.src,
      poster: video.poster,
      volume: volume ? parseInt(volume) : 100,
      durationInSec,
      durationInHMS,
      trimStart,
      trimEnd,
      isMuted: Boolean(video.getAttribute("muted")),
      isLooped: Boolean(loop === "true"),
      isTrimmed: Boolean(trimStart > 0 || trimEnd < durationInSec),
    };
  };

  const resumeDomPlayEnv = ({ timer, pageDurationMs, pageId }) => {
    // setting environment for resuming page play where left off (widget show/hide)
    widgets.forEach(w => {
      if (w.pageId === pageId && !w.data["data-layer-hidden"]) {
        const { startTimeMs, stopTimeMs, widgetNode } = getWidgetData({ widgetObj: w, pageDurationMs });
        widgetNode.style.display = timer >= startTimeMs && timer <= stopTimeMs ? "block" : "none";
      }
    });

    // setting environment for resuming page play where left off (page transition + widget animation)
    document.querySelectorAll(".play-animation").forEach(element => {
      element.style.animationPlayState = "";
    });
  };

  const disableInactivePages = ({ action, pageId }) => {
    if (!isTimeLineViewOpen) {
      if (action === "apply") {
        document.querySelectorAll(".dhp-page-canvas").forEach(page => {
          if (page.id !== pageId) {
            const block = page.querySelector(".dhp-page-block");
            block.classList.add("disable");
          }
        });
      }
      if (action === "revoke") {
        document.querySelectorAll(".dhp-page-block").forEach(block => {
          block.classList.remove("disable");
        });
      }
    }
  };

  const disableAssetPane = () => {
    const assetWrapper = document.querySelector(".dhp-secondAside .assets-wrapper");
    if (assetWrapper) assetWrapper.style.display = "none";
    handleWidgetAction("*", false, false, 0, "returnToWidgetList");
  };

  const setDomPlayEnvToCustomTime = ({ pageId, pageObj, pageDurationMs, timer }) => {
    setPageTransitionCurTime({ pageObj, pageDurationMs, timer });

    widgets.forEach(w => {
      if (w.pageId === pageId && !w.data["data-layer-hidden"]) {
        const { startTimeMs, stopTimeMs, widgetNode, assetType, source } = getWidgetData({
          widgetObj: w,
          pageDurationMs,
        });

        widgetNode.style.display = timer >= startTimeMs && timer <= stopTimeMs ? "block" : "none";

        if (assetType && startTimeMs < pageDurationMs) {
          setAnimationCurTime({ widgetNode, widgetObj: w, startTimeMs, stopTimeMs, timer });
        }

        if (assetType === VIDEO && [STOCK, BRAND, UPLOAD_VIDEO].includes(source) && startTimeMs < pageDurationMs) {
          setVideoCurTime({ widgetNode, timer, startTimeMs, stopTimeMs });
        }

        if (assetType === COLLAGE && startTimeMs < pageDurationMs) {
          widgetNode.querySelectorAll("video").forEach(videoNode => {
            setVideoCurTime({ widgetNode, videoNode, timer, startTimeMs, stopTimeMs });
          });
        }
      }
    });
  };

  const stopDomPlay = () => {
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }
    document.querySelectorAll(".play-animation").forEach(animationNode => {
      animationNode.style.animationPlayState = "paused";
    });
    document.querySelectorAll('video[data-playing="true"]').forEach(videoNode => {
      videoNode.pause();
    });
  };

  const stopPagePlay = ({ action }) => {
    stopDomPlay();
    disableInactivePages({ action: "revoke" });
    setPagePlayData({
      action: null,
      pageId: null,
      pageIdx: null,
      pageTimer: null,
      pageProgress: 0,
      pageDurationMs: 0,
      scrollData: null,
    });

    if (isTimeLineViewOpen === "page-timeline") {
      // applicable only for page play from timeline (page timeline)
      const scrollContainer = document.getElementById("timeline-body");
      if (scrollContainer) {
        scrollContainer.scrollLeft = 0;
        scrollContainer.dataset.scrollLeft = 0;
      }
    }

    if (isTimeLineViewOpen !== "document-timeline") {
      // applicable only for page play (page timeline/page top)
      setPlayingPageIdx(null);

      if (action === "natural_stop") {
        setWhichPagePlaying({
          action: "natural_stop",
          playingPageIdx: pagePlayData.pageIdx,
        });
      }
    }
  };

  const setVideoCurTimeToDefault = ({ pageId, pageIdx }) => {
    setTimeout(() => {
      const pageObj = pageNodes[pageIdx];
      const pageDurationMs = parseFloat(pageObj?.pageDuration ?? 0) * 1000;

      widgets.forEach(w => {
        if (w.pageId === pageId && !w.data["data-layer-hidden"]) {
          const { startTimeMs, stopTimeMs, widgetNode, assetType, source } = getWidgetData({
            widgetObj: w,
            pageDurationMs,
          });

          if (!widgetNode) return;

          if (assetType === VIDEO && [STOCK, BRAND, UPLOAD_VIDEO].includes(source)) {
            const { trimStart: trimStartTime } = getVideoNodeAndDataRef({ widgetNode });
            setVideoCurTime({ widgetNode, timer: trimStartTime, startTimeMs, stopTimeMs });
          }

          if (assetType === COLLAGE) {
            widgetNode.querySelectorAll("video").forEach(videoNode => {
              const { trimStart: trimStartTime } = getVideoNodeAndDataRef({ widgetNode, videoNode });
              setVideoCurTime({ widgetNode, videoNode, timer: trimStartTime, startTimeMs, stopTimeMs });
            });
          }
        }
      });
    }, 30);
  };

  const setVideoCurTime = ({ widgetNode, videoNode, timer, startTimeMs, stopTimeMs }) => {
    const {
      video,
      trimStart: trimStartTime,
      trimEnd: trimStopTime,
    } = getVideoNodeAndDataRef({ widgetNode, videoNode });

    if (timer > startTimeMs && timer < stopTimeMs) {
      // when widget is within playable time range
      const widgetPlayedDurationMs = timer - startTimeMs;
      const videoTrimmedDurationMs = (trimStopTime - trimStartTime) * 1000;
      const videoCurTimeWithinPlayableRange =
        (videoTrimmedDurationMs > widgetPlayedDurationMs
          ? widgetPlayedDurationMs
          : widgetPlayedDurationMs % videoTrimmedDurationMs) / 1000;

      video.currentTime = trimStartTime + videoCurTimeWithinPlayableRange;
    } else {
      video.currentTime = trimStartTime;
    }
  };

  const videoPlay = ({ widgetNode, videoNode, timer, startTimeMs, stopTimeMs }) => {
    const {
      video,
      volume,
      trimStart: trimStartTime,
      trimEnd: trimStopTime,
      isMuted,
      isLooped,
    } = getVideoNodeAndDataRef({ widgetNode, videoNode });
    const videoCurTime = floatToFloatSec(video.currentTime);

    if (timer === startTimeMs) {
      if (isMuted) video.muted = false;
      video.currentTime = trimStartTime;
      video.volume = volume / 100;
      playMedia(video);
      video.setAttribute("data-playing", "true");
    }

    if (timer > startTimeMs && timer < stopTimeMs) {
      if (!(videoCurTime >= trimStartTime && videoCurTime <= trimStopTime)) {
        // when video is outside playable time range
        video.currentTime = trimStartTime;
        if (isLooped) {
          if (video.paused) playMedia(video);
        } else {
          video.pause();
        }
      } else {
        if (video.paused) playMedia(video);
      }
    }

    if (timer === stopTimeMs) {
      video.pause();
      if (isMuted) video.muted = true;
      video.removeAttribute("data-playing");
      video.currentTime = trimStartTime;
    }
  };

  const setAnimationCurTime = ({ widgetNode, widgetObj, startTimeMs, stopTimeMs, timer }) => {
    let animationNode;
    let widgetChildNode;
    const showEntryAnimation = widgetObj.data["data-entrance-animation"];
    const entryAnimationSpeed = widgetObj.data["data-entrance-animation-speed"];
    const entryAnimationType = widgetObj.data["data-entrance-animation-type"];
    const entryAnimationDurationMs = showEntryAnimation && animationSpeedToDurationMsObj[entryAnimationSpeed];
    const entryAnimationStopTime = showEntryAnimation && startTimeMs + entryAnimationDurationMs;
    const entryAnimationClassName = showEntryAnimation && stringTocamelCase(entryAnimationType);

    const showExitAnimation = widgetObj.data["data-exit-animation"];
    const exitAnimationSpeed = widgetObj.data["data-exit-animation-speed"];
    const exitAnimationType = widgetObj.data["data-exit-animation-type"];
    const exitAnimationDurationMs = showExitAnimation && animationSpeedToDurationMsObj[exitAnimationSpeed];
    const exitAnimationClassName = showExitAnimation && stringTocamelCase(exitAnimationType);
    const exitAnimationStartTime = showExitAnimation && stopTimeMs - exitAnimationDurationMs;

    if (!showEntryAnimation && !showExitAnimation) return;

    if (timer > startTimeMs && timer < stopTimeMs) {
      // when widget is within playable time range
      // detecting animation node
      if (!widgetNode.childNodes[0].classList.contains("animation-node")) {
        widgetChildNode = widgetNode.childNodes[0];
        animationNode = document.createElement("div");
        animationNode.classList.add("animation-node");
        animationNode.style.height = "100%";
        animationNode.style.width = "100%";
        widgetNode.replaceChild(animationNode, widgetChildNode);
        animationNode.appendChild(widgetChildNode);
      } else {
        widgetChildNode = widgetNode.childNodes[0].childNodes[0];
        animationNode = widgetNode.childNodes[0];
      }

      // set animation current time
      // entry animation playable range
      if (showEntryAnimation && timer >= startTimeMs && timer < entryAnimationStopTime) {
        const currentTimerDurationMs = timer - startTimeMs;
        const animationPlayedDurationMs = parseInt(
          animationNode.getAttribute("data-entry-animation-played-duration") ?? 0
        );

        // adding entry animation props
        animationNode.classList.add("play-animation");
        animationNode.classList.add(entryAnimationClassName);
        animationNode.style.animationDuration = `${entryAnimationDurationMs}ms`;
        animationNode.style.animationPlayState = "paused";
        animationNode.style.animationDelay =
          currentTimerDurationMs > animationPlayedDurationMs
            ? `-${currentTimerDurationMs - animationPlayedDurationMs}ms`
            : `${animationPlayedDurationMs - currentTimerDurationMs}ms`;

        // removing exit animation props
        animationNode.classList.remove(exitAnimationClassName);
        animationNode.removeAttribute("data-exit-animation-played-duration");
      }
      // exit animation playable range
      else if (showExitAnimation && timer >= exitAnimationStartTime && timer < stopTimeMs) {
        const currentTimerDurationMs = timer - exitAnimationStartTime;
        const animationPlayedDurationMs = parseInt(
          animationNode.getAttribute("data-exit-animation-played-duration") ?? 0
        );

        // adding exit animation props
        animationNode.classList.add("play-animation");
        animationNode.classList.add(exitAnimationClassName);
        animationNode.style.animationDuration = `${exitAnimationDurationMs}ms`;
        animationNode.style.animationPlayState = "paused";
        animationNode.style.animationDelay =
          currentTimerDurationMs > animationPlayedDurationMs
            ? `-${currentTimerDurationMs - animationPlayedDurationMs}ms`
            : `${animationPlayedDurationMs - currentTimerDurationMs}ms`;

        // removing entry animation props
        animationNode.classList.remove(entryAnimationClassName);
        animationNode.removeAttribute("data-entry-animation-played-duration");
      }
      // removing entry and exit animation props outside playable range
      else {
        animationNode.classList.remove("play-animation");
        animationNode.classList.remove(entryAnimationClassName);
        animationNode.classList.remove(exitAnimationClassName);
        animationNode.style.animationDuration = "";
        animationNode.style.animationPlayState = "";
        animationNode.style.animationDelay = "";
        animationNode.removeAttribute("data-entry-animation-played-duration");
        animationNode.removeAttribute("data-exit-animation-played-duration");
      }
    } else {
      if (widgetNode.childNodes[0].classList.contains("animation-node")) {
        // when widget is outside playable time range but animation props were added on last preview
        widgetChildNode = widgetNode.childNodes[0].childNodes[0];
        animationNode = widgetNode.childNodes[0];

        // removing animation node (replacing with original node)
        animationNode.replaceWith(widgetChildNode);
      }
    }
  };

  const animationPlay = ({ widgetNode, widgetObj, startTimeMs, stopTimeMs, timer }) => {
    const showEntryAnimation = widgetObj.data["data-entrance-animation"];
    const entryAnimationSpeed = widgetObj.data["data-entrance-animation-speed"];
    const entryAnimationType = widgetObj.data["data-entrance-animation-type"];
    const entryAnimationDurationMs = showEntryAnimation && animationSpeedToDurationMsObj[entryAnimationSpeed];
    const entryAnimationStopTime = showEntryAnimation && startTimeMs + entryAnimationDurationMs;
    const entryAnimationClassName = showEntryAnimation && stringTocamelCase(entryAnimationType);

    const showExitAnimation = widgetObj.data["data-exit-animation"];
    const exitAnimationSpeed = widgetObj.data["data-exit-animation-speed"];
    const exitAnimationType = widgetObj.data["data-exit-animation-type"];
    const exitAnimationDurationMs = showExitAnimation && animationSpeedToDurationMsObj[exitAnimationSpeed];
    const exitAnimationClassName = showExitAnimation && stringTocamelCase(exitAnimationType);
    const exitAnimationStartTime = showExitAnimation && stopTimeMs - exitAnimationDurationMs;

    if (!showEntryAnimation && !showExitAnimation) return;

    // detecting animation node
    let animationNode;
    let widgetChildNode;

    if (!widgetNode.childNodes[0].classList.contains("animation-node")) {
      widgetChildNode = widgetNode.childNodes[0];
      animationNode = document.createElement("div");
      animationNode.classList.add("animation-node");
      animationNode.style.height = "100%";
      animationNode.style.width = "100%";
      widgetNode.replaceChild(animationNode, widgetChildNode);
      animationNode.appendChild(widgetChildNode);
    } else {
      widgetChildNode = widgetNode.childNodes[0].childNodes[0];
      animationNode = widgetNode.childNodes[0];
    }

    // play / pause animations
    if (showEntryAnimation && timer === startTimeMs) {
      // entry animation start
      animationNode.classList.add("play-animation");
      animationNode.classList.add(entryAnimationClassName);
      animationNode.style.animationDuration = `${entryAnimationDurationMs}ms`;
    }

    if (showEntryAnimation && timer > startTimeMs && timer < entryAnimationStopTime) {
      animationNode.setAttribute("data-entry-animation-played-duration", timer);
    }

    if (showEntryAnimation && timer === entryAnimationStopTime) {
      // entry animation stop
      animationNode.classList.remove("play-animation");
      animationNode.classList.remove(entryAnimationClassName);
      animationNode.style.animationDuration = "";
      animationNode.style.animationPlayState = "";
      animationNode.style.animationDelay = "";
      animationNode.removeAttribute("data-entry-animation-played-duration");
    }

    if (showExitAnimation && timer === exitAnimationStartTime) {
      // exit animation start
      animationNode.classList.add("play-animation");
      animationNode.classList.add(exitAnimationClassName);
      animationNode.style.animationDuration = `${exitAnimationDurationMs}ms`;
    }

    if (showExitAnimation && timer > exitAnimationStartTime && timer < stopTimeMs) {
      animationNode.setAttribute("data-exit-animation-played-duration", timer - exitAnimationStartTime);
    }

    if (showExitAnimation && timer === stopTimeMs) {
      // exit animation stop
      animationNode.classList.remove("play-animation");
      animationNode.classList.remove(exitAnimationClassName);
      animationNode.style.animationDuration = "";
      animationNode.style.animationPlayState = "";
      animationNode.style.animationDelay = "";
      animationNode.removeAttribute("data-exit-animation-played-duration");
    }

    if (timer === stopTimeMs) {
      // widget/page duration end
      // removing animation node (replacing with original node)
      animationNode.replaceWith(widgetChildNode);
    }
  };

  const widgetPlay = ({ timer, pageDurationMs, pageId }) => {
    // widget play-pause
    widgets.forEach(w => {
      if (w.pageId === pageId && !w.data["data-layer-hidden"]) {
        const { startTimeMs, stopTimeMs, widgetNode, assetType, source } = getWidgetData({
          widgetObj: w,
          pageDurationMs,
        });

        if (!widgetNode) return;

        if (timer === startTimeMs) {
          widgetNode.style.display = "block";
        }

        if (assetType && startTimeMs < pageDurationMs) {
          animationPlay({ widgetNode, widgetObj: w, startTimeMs, stopTimeMs, timer });
        }

        if (assetType === VIDEO && [STOCK, BRAND, UPLOAD_VIDEO].includes(source) && startTimeMs < pageDurationMs) {
          videoPlay({ widgetNode, timer, startTimeMs, stopTimeMs });
        }

        if (assetType === COLLAGE && startTimeMs < pageDurationMs) {
          widgetNode.querySelectorAll("video").forEach(videoNode => {
            videoPlay({ widgetNode, videoNode, timer, startTimeMs, stopTimeMs });
          });
        }

        if (timer === stopTimeMs && stopTimeMs < pageDurationMs) {
          widgetNode.style.display = "none";
        }

        if (timer === pageDurationMs) {
          // page duration end
          widgetNode.style.display = "block";
        }
      }
    });
  };

  const setPageTransitionCurTime = ({ pageObj, pageDurationMs, timer }) => {
    let wrapperNode;
    let animationNode;
    const pageNode = document.getElementById(pageObj.pageId);
    const blockNode = pageNode.querySelector(".dhp-page-block");
    const showEntryAnimation = pageObj.pageTransition.enabled;
    const entryAnimationSpeed = pageObj.pageTransition.speed;
    const entryAnimationType = pageObj.pageTransition.type;
    const entryAnimationDurationMs = showEntryAnimation && animationSpeedToDurationMsObj[entryAnimationSpeed];
    const entryAnimationStopTime = showEntryAnimation && entryAnimationDurationMs;
    const entryAnimationClassName = showEntryAnimation && stringTocamelCase(entryAnimationType);

    // reset playing page props
    pageNode.classList.add("page-playing");
    blockNode.style.pointerEvents = "none";
    blockNode.classList.add("canvas-disabled");
    localStorage.setItem("widget.event.isActive", "true");
    setPlayingPageIdx(pageObj.pageIdx);

    if (!showEntryAnimation) return;

    if (timer > 0 && timer < pageDurationMs) {
      // when page is within playable time range
      // detecting wrapper/animation node
      if (!blockNode.parentNode.parentNode.classList.contains("wrapper-node")) {
        animationNode = document.createElement("div");
        animationNode.classList.add("animation-node");

        wrapperNode = document.createElement("div");
        wrapperNode.classList.add("wrapper-node");
        wrapperNode.style.overflow = "hidden";
        wrapperNode.style.height = "100%";
        wrapperNode.style.width = "100%";
        wrapperNode.appendChild(animationNode);

        blockNode.replaceWith(wrapperNode);
        wrapperNode.childNodes[0].appendChild(blockNode);
      } else {
        wrapperNode = pageNode.querySelector(".wrapper-node");
        animationNode = pageNode.querySelector(".animation-node");
      }

      // set page transition current time
      // page transition playable range
      if (showEntryAnimation && timer > 0 && timer < entryAnimationStopTime) {
        const currentTimerDurationMs = timer - 0;
        const animationPlayedDurationMs = parseInt(
          animationNode.getAttribute("data-page-transition-played-duration") ?? 0
        );

        animationNode.classList.add("play-animation");
        animationNode.classList.add(entryAnimationClassName);
        animationNode.style.animationDuration = `${entryAnimationDurationMs}ms`;
        animationNode.style.animationPlayState = "paused";
        animationNode.style.animationDelay =
          currentTimerDurationMs > animationPlayedDurationMs
            ? `-${currentTimerDurationMs - animationPlayedDurationMs}ms`
            : `${animationPlayedDurationMs - currentTimerDurationMs}ms`;
      }
      // removing page transition props outside playable range
      else {
        animationNode.classList.remove("play-animation");
        animationNode.classList.remove(entryAnimationClassName);
        animationNode.style.animationDuration = "";
        animationNode.style.animationPlayState = "";
        animationNode.style.animationDelay = "";
        animationNode.removeAttribute("data-page-transition-played-duration");
      }
    } else {
      if (blockNode.parentNode.parentNode.classList.contains("wrapper-node")) {
        // when page is outside playable time range but page transition props were added on last preview
        wrapperNode = pageNode.querySelector(".wrapper-node");

        // removing wrapper/animation node (replacing with original node)
        wrapperNode.replaceWith(blockNode);
      }
    }
  };

  const pageTransitionPlay = ({ pageObj, pageDurationMs, timer }) => {
    const pageNode = document.getElementById(pageObj.pageId);
    const blockNode = pageNode.querySelector(".dhp-page-block");
    const showEntryAnimation = pageObj.pageTransition.enabled;
    const entryAnimationSpeed = pageObj.pageTransition.speed;
    const entryAnimationType = pageObj.pageTransition.type;
    const entryAnimationDurationMs = showEntryAnimation && animationSpeedToDurationMsObj[entryAnimationSpeed];
    const entryAnimationStopTime = showEntryAnimation && entryAnimationDurationMs;
    const entryAnimationClassName = showEntryAnimation && stringTocamelCase(entryAnimationType);

    if (!showEntryAnimation) return;

    // detecting wrapper/animation node
    let wrapperNode;
    let animationNode;

    if (!blockNode.parentNode.parentNode.classList.contains("wrapper-node")) {
      animationNode = document.createElement("div");
      animationNode.classList.add("animation-node");

      wrapperNode = document.createElement("div");
      wrapperNode.classList.add("wrapper-node");
      wrapperNode.style.overflow = "hidden";
      wrapperNode.style.height = "100%";
      wrapperNode.style.width = "100%";
      wrapperNode.appendChild(animationNode);

      blockNode.replaceWith(wrapperNode);
      wrapperNode.childNodes[0].appendChild(blockNode);
    } else {
      wrapperNode = pageNode.querySelector(".wrapper-node");
      animationNode = pageNode.querySelector(".animation-node");
    }

    // play / pause animations
    if (showEntryAnimation && timer === 0) {
      // page transition start
      animationNode.classList.add("play-animation");
      animationNode.classList.add(entryAnimationClassName);
      animationNode.style.animationDuration = `${entryAnimationDurationMs}ms`;
    }

    if (showEntryAnimation && timer > 0 && timer < entryAnimationStopTime) {
      animationNode.setAttribute("data-page-transition-played-duration", timer);
    }

    if (showEntryAnimation && timer === entryAnimationStopTime) {
      // page transition stop
      animationNode.classList.remove("play-animation");
      animationNode.classList.remove(entryAnimationClassName);
      animationNode.style.animationDuration = "";
      animationNode.style.animationPlayState = "";
      animationNode.style.animationDelay = "";
      animationNode.removeAttribute("data-page-transition-played-duration");
    }

    if (showEntryAnimation && timer === pageDurationMs) {
      // page duration end
      // removing wrapper/animation node (replacing with original node)
      wrapperNode.replaceWith(blockNode);
    }
  };

  const pagePlay = ({ timer, pageDurationMs, pageObj, pageId, pageIdx, scrollData }) => {
    if (!pageObj) return;
    const pageNode = document.getElementById(pageObj.pageId);
    const blockNode = pageNode.querySelector(".dhp-page-block");
    const progress = customRound((100 / pageDurationMs) * timer, 2);
    const storage_data = operateStorageData({
      type: "session",
      action: "get",
      key: PAGE_PLAY_DATA_STORAGE_KEY,
      isParseable: true,
      defaultData: {
        action: "play",
      },
    });

    setPagePlayData({
      action: timer === pageDurationMs ? "natural_stop" : storage_data.action,
      pageId: pageId ?? storage_data.pageId,
      pageIdx: pageIdx ?? storage_data.pageIdx,
      pageTimer: timer,
      pageProgress: progress,
      pageDurationMs: pageDurationMs,
      scrollData,
    });

    if (timer === 0) {
      // page play start
      pageNode.classList.add("page-playing");
      blockNode.style.pointerEvents = "none";
      blockNode.classList.add("canvas-disabled");
      pageTransitionPlay({ timer, pageDurationMs, pageObj });
      localStorage.setItem("widget.event.isActive", "true");
      if (isTimeLineViewOpen !== "document-timeline") setPlayingPageIdx(pageIdx ?? storage_data.pageIdx);
    }

    if (timer === pageDurationMs) {
      // page play stop
      pageNode.classList.remove("page-playing");
      blockNode.style.pointerEvents = "";
      blockNode.classList.remove("canvas-disabled");
      pageTransitionPlay({ timer, pageDurationMs, pageObj });
      localStorage.removeItem("widget.event.isActive");
    }
  };

  useEffect(() => {
    if (action === "play" && !intervalId) {
      let timer = pagePlayData?.pageTimer ?? 0;
      const { pageId, pageIdx, pageObj, pageDurationMs, scrollData } = getPageData();

      if (!pageId || pageIdx < 0 || !pageObj) return;

      disableInactivePages({ action: "apply", pageId });
      resumeDomPlayEnv({ timer, pageDurationMs, pageId });

      if (timer === 0) {
        pagePlay({ timer, pageDurationMs, pageObj, pageId, pageIdx, scrollData });
        widgetPlay({ timer, pageDurationMs, pageId });
      }

      intervalId = setInterval(() => {
        timer += 100;
        widgetPlay({ timer, pageDurationMs, pageId });
        pagePlay({ timer, pageDurationMs, pageObj, pageId, pageIdx, scrollData });
      }, 100);
    }

    if (action === "pause") {
      stopDomPlay();
    }

    if (action === "natural_stop") {
      stopPagePlay({ action });
    }

    if (action === "force_stop") {
      const { pageId, pageIdx, pageObj, pageDurationMs } = getPageData();
      stopPagePlay({ action });
      widgetPlay({ timer: pageDurationMs, pageDurationMs, pageId, pageIdx });
      pagePlay({ timer: pageDurationMs, pageDurationMs, pageObj });
    }

    return () => {
      stopDomPlay();
    };
  }, [action]);

  useLayoutEffect(() => {
    if (pagePlayData.action === "play" && isTimeLineViewOpen !== "document-timeline") {
      // reset environments ready for page play
      disableAssetPane();
      updateMetadata({ ...metadata, activeWidgetId: false, activeWidgetType: false });
    }
    setAction(pagePlayData.action);
  }, [pagePlayData.action]);

  useLayoutEffect(() => {
    if (whichPagePlaying.action === "pause" && whichPagePlaying.triggerPoint === "slider") {
      const timer = pagePlayData?.pageTimer ?? 0;
      const { pageId, pageObj, pageDurationMs } = getPageData();
      setDomPlayEnvToCustomTime({ pageId, pageObj, pageDurationMs, timer });
    }
  }, [whichPagePlaying]);

  useLayoutEffect(() => {
    // when not in playing mode, pre-load video at change of active page (so that it reduce buffering time when playing)
    if (metadata.activePageId && !playingPageIdx) {
      setVideoCurTimeToDefault({ pageId: metadata.activePageId, pageIdx: metadata.activePageIdx });
    }
  }, [metadata.activePageId, playingPageIdx]);

  return {
    setVideoCurTimeToDefault,
  };
};

export default usePagePlay;
