import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { TabPane } from "reactstrap";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { AppContext } from "../../../contexts";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import useThemeColor from "../../../hooks/useThemeColor";
import MultiColorList from "../../ui/MultiColorList";
import global from "../../../scss/dhp.scss";
import { Icon } from "../../../components/ui/icon";
import BrandkitTabPaneSubTitle from "./BrandkitTabPaneSubTitle";
import AssetsLoader from "../../ui/loader/assetsLoader";

let style = Object.assign({}, global);

const BrandkitColours = props => {
  let timeOut;
  let { metadata, pageNodes, setActiveColorThemePageIdx, setIsTimeLineViewOpen } = useContext(EditorContext);
  let { brandInfo } = useContext(AppContext);

  const { loading } = useSelector(state => state.company);

  const { changeColorTheme } = useThemeColor();

  const [activeThemeIndex, setActiveThemeIndex] = useState();
  const [isShownAppliedSuccessfully, setIsShownAppliedSuccessfully] = useState();

  const colorArrays = [
    { id: `${brandInfo?.brandDetails?.name}1`, colors: brandInfo?.brandDetails?.primary_color || [] },
    { id: `${brandInfo?.brandDetails?.name}2`, colors: brandInfo?.brandDetails?.secondary_color || [] },
    { id: `${brandInfo?.brandDetails?.name}3`, colors: brandInfo?.brandDetails?.other_color || [] },
  ];

  const hasColors = colorArrays.some(item => item.colors.length > 0);

  const applyBrandColorTheme = item => {
    setActiveThemeIndex(item.id);
    setActiveColorThemePageIdx(metadata.activePageIdx);
    changeColorTheme(item.id, item.colors);
  };

  const disableMessageAfterSomeSec = () => {
    timeOut = setTimeout(() => {
      setIsShownAppliedSuccessfully(false);
    }, 2000);
  };

  useEffect(() => {
    if (isShownAppliedSuccessfully) {
      disableMessageAfterSomeSec();
    }
  }, [isShownAppliedSuccessfully]);

  useEffect(() => {
    return () => {
      setActiveThemeIndex(false);
      clearTimeout(timeOut);
    };
  }, []);

  return (
    <>
      <TabPane tabId="1" className={style["active"]}>
        <section>
          <div className={style["mb-4"]}>
            <BrandkitTabPaneSubTitle
              title={"Brand Color Theme"}
              tooltipId="brandColorTheme"
              tooltipContent={"Apply brand color theme on the entire document"}
            />
            <ul
              className={cx(
                style["color-list"],
                style["row"],
                style["asset-theme-color-loading"],
                style["row-cols-1"]
              )}>
              {loading && <AssetsLoader count={6} />}
              {brandInfo?.brandDetails &&
                hasColors &&
                !loading &&
                colorArrays.map(
                  item =>
                    item.colors.length > 0 && (
                      <li
                        key={item.id}
                        className={cx(style["color-list-item"], {
                          [style["active"]]: activeThemeIndex === item.id,
                        })}
                        onClick={() => applyBrandColorTheme(item)}>
                        <MultiColorList key={item.id} colors={item.colors} />
                        <div className={style["shuffle"]}>
                          <Icon icon="ui-shuffle" additionalclass="" />
                        </div>
                      </li>
                    )
                )}
            </ul>
            {brandInfo?.brandDetails && !loading && !hasColors && (
              <div className={cx(style["text-center"], style["bg-secondary"], style["rounded"], style["p-2"])}>
                No brand color theme yet
              </div>
            )}
          </div>

          {pageNodes.length > 1 && (activeThemeIndex || isShownAppliedSuccessfully) && (
            <div className={cx(style["mt-auto"], style["text-center"], style["fixed-bottom"])}>
              {isShownAppliedSuccessfully && (
                <span className={cx(style["btn"], style["btn-border"])}>
                  <Icon icon="tick-success" /> Applied Successfully
                </span>
              )}

              {activeThemeIndex && (
                <span
                  className={cx(style["btn"], style["btn-border"])}
                  onClick={() => {
                    setIsTimeLineViewOpen(false),
                      props.setFitToScreen(true),
                      changeColorTheme(),
                      setActiveThemeIndex(false),
                      setIsShownAppliedSuccessfully(true);
                  }}>
                  Apply to All Pages
                </span>
              )}
            </div>
          )}
        </section>
      </TabPane>
    </>
  );
};
//Props type validation
BrandkitColours.propTypes = {
  setFitToScreen: PropTypes.func,
};

export default BrandkitColours;
