import React, { useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Input, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

import { StockPhotoFilters } from "../Picture";
import { StockAudioFilters } from "../Audio";
import global from "../../../../scss/dhp.scss";

import { Icon } from "../../../ui/icon";
import { debounceTrailing as debounce } from "../../../../_helpers/utils";
import StockSPs from "./StockSPs";

let style = Object.assign({}, global);

const StockVideoSourceInfo = props => {
  return (
    <div className={style["info-link"]}>
      <span className={cx(style["color-33"], style["info-icon"])}>
        <Icon icon="information"></Icon>
      </span>
      <div className={cx(style["info-link-content"], style["rounded"], style["mt-2"])}>
        {props.type === "Videos" && (
          <>
            <div className={style["fw-6"]}>{props.type} from</div>
            <div className={cx(style["d-flex"], style["justify-content-between"])}>
              <StockSPs fileType="video" excludeList={["unsplash"]} />
            </div>
          </>
        )}
        {props.type === "Audios" && (
          <>
            <div className={style["fw-6"]}>Audio tracks from</div>
            <div className={cx(style["d-flex"], style["justify-content-center"])}>
              <StockSPs fileType="audio" excludeList={["pexels", "unsplash"]} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
//Props type validation
StockVideoSourceInfo.propTypes = {
  type: PropTypes.string,
};

const AssetSearch = props => {
  // Update asset search result
  const updateKeyword = debounce(e => {
    if (e.length >= 3 || e.length === 0) {
      props.setKeyword && props.setKeyword(e);
      props.setPageNumber && props.setPageNumber(1);
      props.setYouTubePageToken && props.setYouTubePageToken("");
      props.setCategory && props.setCategory("");
    }
  }, 500);

  useEffect(() => {
    props.setIsAssetFetched && props.setIsAssetFetched(false);
  }, [props.keyword]);

  return (
    <div className={cx(style["search-field"], style["p-0"], style["mb-3"])}>
      <div className={cx(style["search-area"], style["clearfix"])}>
        <span className={style["searchicon"]}>
          <Icon icon="ui-search"></Icon>
        </span>
        <Input
          type="text"
          id="assetSearch"
          className={style["form-control"]}
          placeholder={`Search ${props.assetName}`}
          autoComplete="off"
          onChange={e => updateKeyword(e.target.value.trim())}
        />
        {props?.assetName === "Videos" && props?.schemeType === "Stock Videos" && (
          <StockVideoSourceInfo type={"Videos"} />
        )}
        {props?.assetName === "Audio" && <StockVideoSourceInfo type={"Audios"} />}
        {props?.assetName === "Pictures" && (
          <StockPhotoFilters filters={props.filters} setFilters={props.setFilters} setPage={props.setPageNumber} />
        )}
      </div>
    </div>
  );
};

//Props type validation
AssetSearch.propTypes = {
  setKeyword: PropTypes.func,
  setPageNumber: PropTypes.func,
  setCategory: PropTypes.func,
  assetName: PropTypes.string.isRequired,
  setYouTubePageToken: PropTypes.func,
  schemeType: PropTypes.string,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  setIsAssetFetched: PropTypes.func,
  keyword: PropTypes.string,
};

export default AssetSearch;
