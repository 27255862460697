import { useEffect, useState, useRef } from "react";

// Utility function for debouncing
const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const useOverflowDetection = (threshold = 20, debounceDelay = 100) => {
  const [isAtTop, setIsAtTop] = useState(false);
  const parentRef = useRef(null);
  const childRef = useRef(null);

  const checkOverflow = () => {
    if (parentRef.current && childRef.current) {
      const parent = parentRef.current;
      const child = childRef.current;

      const childRect = child.getBoundingClientRect();
      const parentRect = parent.getBoundingClientRect();
      const isAtTop = childRect.top < parentRect.top - threshold;

      setIsAtTop(isAtTop);
    } else {
      setIsAtTop(false);
    }
  };

  const debouncedCheckOverflow = debounce(checkOverflow, debounceDelay);

  // Trigger debouncedCheckOverflow when childRef becomes available
  useEffect(() => {
    if (childRef.current) {
      debouncedCheckOverflow();
    }
  }, [childRef.current]); // Listen for changes to childRef.current

  useEffect(() => {
    checkOverflow();

    const parentElement = parentRef.current;

    if (parentElement) {
      parentElement.addEventListener("scroll", debouncedCheckOverflow);
      parentElement.addEventListener("mouseenter", debouncedCheckOverflow);
      window.addEventListener("resize", debouncedCheckOverflow);
    }

    return () => {
      if (parentElement) {
        parentElement.removeEventListener("scroll", debouncedCheckOverflow);
        parentElement.removeEventListener("mouseenter", debouncedCheckOverflow);
      }
      window.removeEventListener("resize", debouncedCheckOverflow);
    };
  }, [debouncedCheckOverflow]);

  return { isAtTop, parentRef, childRef };
};

export default useOverflowDetection;
