export const planInfo = {
  free: {
    title: "Free",
    group: "Free",
    amount: 0,
    validation: "Forever free",
    btnLabel: "Current Plan",
    btnColor: "secondary",
    subscriptionPlanOffers: [
      "Free templates",
      "Drag-and-drop design editor",
      "Up to 5 documents",
      "Millions of stock photos",
      "Hundreds of free fonts",
      "Free design assets",
      "Unlimited pages",
      "Apply custom dimension",
      "Resize design with smart resize",
      "Download as JPG, PNG",
      "No watermark on downloads",
      "Embed on your website",
      "Integration with Mailchimp",
      "Integration with Typeform",
      "Two-step verification",
      "Standard email and chat support",
    ],
  },
  Pro: {
    title: "Pro",
    group: "Pro",
    icon: true,
    validation: "/user/month (excl. Taxes)",
    btnLabel: "Subscribe",
    btnColor: "primary",
    subscriptionPlanOffers: [
      "Everything in Free plan",
      "Premium templates",
      "Unlimited documents",
      "Premium design assets",
      "High resolution download",
      "AI image background remover",
      "AI image generator ",
      "AI writer",
      "AI translate",
      "Download with transparent background",
      "Download as PDF, MP4, GIF",
      "Document commenting",
      "Save document as template",
      "Password on published pages",
      "Download option on published pages",
      "On-brand design with brand kits",
      "Upload custom fonts",
      "Version history",
      "Add multiple users",
      "Real-time collaboration",
      "Audit log",
      "24x7 email and chat support",
    ],
  },
  ProUnlimited: {
    title: "Pro Unlimited",
    group: "Pro Unlimited",
    icon: true,
    // validation: "Unlimited Users/month (excl. Taxes)",
    validation: `/month (excl. Taxes)`,
    btnLabel: "Subscribe",
    btnColor: "primary",
    subscriptionPlanOffers: [
      "Everything in Free plan",
      "Premium templates",
      "Unlimited documents",
      "Premium design assets",
      "High resolution download",
      "AI image background remover",
      "AI image generator ",
      "AI writer",
      "AI translate",
      "Download with transparent background",
      "Download as PDF, MP4, GIF",
      "Document commenting",
      "Save document as template",
      "Password on published pages",
      "Download option on published pages",
      "On-brand design with brand kits",
      "Upload custom fonts",
      "Version history",
      "Add unlimited users",
      "Real-time collaboration",
      "Audit log",
      "24x7 email and chat support",
    ],
  },
};

export const productInfo = {
  "Pro Once": {
    title: "Pro Once",
    icon: true,
    validation: "/user (excl. Local Taxes)",
    btnLabel: "Buy",
    btnColor: "primary",
    subscriptionPlanOffers: [
      "Everything in Free plan",
      "Premium templates",
      "Unlimited documents",
      "Premium design assets",
      "High resolution download",
      "AI image background remover",
      "AI image generator ",
      "AI writer",
      "AI translate",
      "Download with transparent background",
      "Download as PDF, MP4, GIF",
      "Document commenting",
      "Password on published pages",
      "Download option on published pages",
      "On-brand design with brand kits",
      "Upload custom fonts",
      "Version history",
      "Add multiple users",
      "Real-time collaboration",
      "Audit log",
      "24x7 email and chat support",
    ],
  },
};

export const loadModalContent = (upgradeType = "generic") => {
  const description = [
    `<span class="fw-6">Unlimited documents</span>, thousands of <span class="fw-6">premium templates </span> and <span class="fw-6"> premium design assets</span>`,
    `<span class="fw-6">Remove image background </span>and other <span class="fw-6"> AI </span> capabilities, <span class="fw-6"> High resolution </span> download, download as <span class="fw-6"> PNG, PDF, MP4, GIF </span>`,
    `<span class="fw-6">Collaborate </span> with other users, <span class="fw-6">Comment</span> on documents, <span class="fw-6">Version</span> history, <span class="fw-6">Audit log</span> and <span class="fw-6">Priority email support</span>`,
  ];

  const modalContent = {
    generic: {
      type: "generic",
      title: "Upgrade to Pro",
      headerContent: "Unlock all the amazing Pro features that help you stay ahead of competition.",
    },
    premiumTemplate: {
      type: "premiumTemplate",
      title: "Use this Premium Template",
      headerContent: "Upgrade to Pro and unlock thousands of premium templates like this.",
    },
    unlimitedDocs: {
      type: "unlimitedDocs",
      title: "Create Unlimited Documents",
      headerContent: "Go beyond the 5 documents limit. Upgrade to Pro and unlock these benefits.",
    },
    removeBG: {
      type: "removeBG",
      title: "Remove Background",
      headerContent: "Remove background from picture. Upgrade to Pro and unlock these benefits.",
    },
    userAdd: {
      type: "userAdd",
      title: "Add Team Members",
      headerContent: "Invite team members to share and collaborate on your documents.",
    },
    versionHistory: {
      type: "versionHistory",
      title: "Version History",
      headerContent: "Preview earlier versions of your document and revert, if required.",
    },
    auditLog: {
      type: "auditLog",
      title: "Audit Log",
      headerContent: "Keep track of all your account activities in one single place.",
    },
    downloadQuality: {
      type: "downloadQuality",
      title: "Any File Type and Quality",
      headerContent: "Choose the perfect file type and quality for your downloads.",
    },
    premiumAsset: {
      type: "premiumAsset",
      title: "Use this Premium Asset",
      headerContent: "Upgrade to Pro and unlock thousands of premium assets like this.",
    },
    premiumThemes: {
      type: "premiumThemes",
      title: "Use this Premium Asset",
      headerContent: "Upgrade to Pro and unlock thousands of premium assets like this.",
    },
    documentCmnt: {
      type: "documentCmnt",
      title: "Comment on Documents",
      headerContent: "Share your documents with your team to give and receive feedback instantly.",
    },
    aiImage: {
      type: "aiImage",
      title: "Generate AI Images",
      headerContent: "Instantly turn your words into images with AI image generator.",
    },
    aiWriter: {
      type: "aiWriter",
      title: "Generate AI Content",
      headerContent: "Instantly generate content on any topic with AI content generator.",
    },
    publishDownload: {
      type: "publishDownload",
      title: "Allow Viewers to Download",
      headerContent: "Upgrade to Pro and allow your visitors to download in PDF format.",
    },
    publishPassword: {
      type: "publishPassword",
      title: "Set Password",
      headerContent: "Upgrade to Pro and share your document privately with a password.",
    },
    aiTranslate: {
      type: "aiTranslate",
      title: "Translate Content",
      headerContent: "Instantly translate your content in your preferred language.",
    },
    companyTemplate: {
      type: "companyTemplate",
      title: "Publish as Company Template",
      headerContent: "Upgrade to Pro and publish this document as a reusable company template.",
    },
    sendTenEmailDocumentLink: {
      type: "sendTenEmailDocumentLink",
      title: "Send up to 10 Emails/Day",
      headerContent: "Send up to 10 emails per day when you upgrade to DocHipo Pro.",
    },
    addTenRecipientsDocumentLink: {
      type: "addTenRecipientsDocumentLink",
      title: "Add up to 10 Recipients/Email",
      headerContent: "Add up to 10 recipients per email when you upgrade to DocHipo Pro.",
    },
    publishBadge: {
      type: "publishBadge",
      title: "Hide DocHipo Badge",
      headerContent: "Upgrade to Pro and hide the DocHipo badge from the page footer.",
    },
    publishEmailVerification : {
      type: "publishEmailVerification",
      title: "Email Verification",
      headerContent: "Sends a verification email to verify that the viewer owns the email address.",
    },
  };

  return { ...modalContent[upgradeType], description };
};
