import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input as RInput, Tooltip, } from "reactstrap";

import AssetName from "./Common/AssetName";
import AssetSearch from "./Common/AssetSearch";
import AssetCategory from "./Common/AssetCategory";
import { Icon } from "../../ui/icon";

import global from "../../../scss/dhp.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssetsList, filterAssetsList } from "../../../store/actions/editorActions";
import { DotLoader } from "../../ui/loader/dotLoader";
import AssetsLoader from "../../ui/loader/assetsLoader";
import SliderControll from "../SliderControll";
import useAudio from "../../../hooks/useAudio";
import { STOCK } from "../../../constants/editor";
import useDragDropAudio from "../../../hooks/useDragDropAudio";

let style = Object.assign({}, global);

export const StockAudioFilters = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className={cx(style["more-action"])}>
      <DropdownToggle></DropdownToggle>
      <DropdownMenu
        className={cx(
          style["dropdown-menu-right"],
          style["shadow-sm"],
          style["border-0"],
          style["p-3"],
          style["mt-2"]
        )}>
        <div className={cx(style["fw-6"], style["color-black"], style["mb-2"])}>Tracks with Content ID</div>
        <div className={cx( style["custom-control"], style["custom-checkbox"])}>
          <RInput type="checkbox" className={style["custom-control-input"]}/>
          <label className={style["custom-control-label"]}>Exclude</label>
        </div>
        <DropdownItem divider />
        <span className={cx(style["font-15"], style["d-block"], style["mb-3"])}>It is better to exclude <br></br> tracks with Content ID for YouTube videos</span>
          <Button
            type="submit"
            color="primary"
            className={style["btn-block"]}
            cssModule={style}>
            Apply Filters
          </Button>
      </DropdownMenu>
    </Dropdown>
  );
};

const AudioAsset = props => {
  const { addAudio } = useAudio();
  const audioRef = useRef(null);
  const audiotooltipRef = useRef();
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);

  const { start: initDragDropWidget } = useDragDropAudio();

  const playAudioOnClick = e => {
    e.stopPropagation();
    // Toggle the playAudio state based on the current state of the audio
    props.onPlayAudio(props.index);
  };

  const onTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const onLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

  const onSliderChange = value => {
    audioRef.current.currentTime = value;
    setCurrentTime(value);
  };

  const onEnded = () => {
    props.onPlayAudio(null); // Indicate that audio has ended
  };

  const insertAudio = (url, duration, filename, audioFilename, source) => {
    addAudio(url, duration, filename, audioFilename, source);
  };

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip && audiotooltipRef.current.scrollWidth > audiotooltipRef.current.offsetWidth);
  };

  useEffect(() => {
    // Reset currentTime to 0 when isPlaying becomes false (audio is paused)
    if (!props.isPlaying) {
      setCurrentTime(0);
    }
  }, [props.isPlaying]);

  useEffect(() => {
    const container = document.querySelector(".editor-asset-inner");

    const handleWheel = () => {
      if (showTooltip) {
        setShowTooltip(false);
      }
    };

    if (container) {
      container.addEventListener("wheel", handleWheel);
    }

    return () => {
      if (container) {
        container.removeEventListener("wheel", handleWheel);
      }
    };
  }, [showTooltip]);

  return (
    <React.Fragment>
      {props.length === props.index + 1 && (
        <li
          key={props.audio._id}
          ref={props.lastAssetElement}
          className={cx(style["col"], style["skeleton-loader-area"])}>
          <div
            className={cx(style["asset-item"])}
            onClick={() =>
              insertAudio(props.audio.audio_file, props.audio.duration, props.audio.filename, props.audio.name, STOCK)
            }
            onMouseDown={e => {
              initDragDropWidget(
                {
                  url: props.audio.audio_file,
                  duration: props.audio.duration,
                  filename: props.audio.filename,
                  audioFilename: props.audio.name,
                  source: STOCK
                },
                e
              );
            }}
            >
            <div className={cx(style["item-holder"])}>
              <div
                className={cx(
                  style["audio-play-btn"],
                  style["d-flex"],
                  style["pl-2"],
                  style["pr-1"],
                  style["cursor-pointer"]
                )}
                onClick={e => playAudioOnClick(e)}>
                <Icon icon={props.isPlaying ? "ui-circled-pause" : "ui-circled-play"}></Icon>
              </div>
              {!props.isPlaying && (
                <div className={cx(style["ml-2"], style["w-75"])}>
                  <h6 ref={audiotooltipRef} id={`tooltip-${props.audio._id}`} className={cx(style["audio-title"], style["text-truncate"], style["mb-0"])}>{props.audio.name}</h6>
                  <Tooltip
                    placement="bottom"
                    isOpen={showTooltip}
                    target={`tooltip-${props.audio._id}`}
                    toggle={toggleTooltip}
                    boundariesElement={document.getElementById("app")}
                    >
                    {props.audio.name}
                  </Tooltip>
                  <div className={cx(style["audio-duration"], style["text-muted"], style["font-13"])}>
                    {props.audio.duration}
                  </div>
                </div>
              )}

              {props.isPlaying && (
                <>
                  <audio
                    ref={audioRef}
                    src={props.audio.audio_file}
                    onTimeUpdate={onTimeUpdate}
                    onLoadedMetadata={onLoadedMetadata}
                    onEnded={onEnded}
                    autoPlay
                  />
                  <div
                    className={cx(style["ml-3"], style["info"])}
                    onClick={e => e.stopPropagation()}
                    onMouseDown={e => e.stopPropagation()}>
                    <SliderControll
                      Slider={currentTime}
                      setSlider={onSliderChange}
                      sliderMin={0}
                      sliderMax={duration}
                      step={0.1}
                      hideSliderValue
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </li>
      )}

      {props.length !== props.index + 1 && (
        <li className={cx(style["col"], style["skeleton-loader-area"])}>
         <div
            className={cx(style["asset-item"])}
            onClick={() =>
              insertAudio(props.audio.audio_file, props.audio.duration, props.audio.filename, props.audio.name, STOCK)
            }
            onMouseDown={e => {
              initDragDropWidget(
                {
                  url: props.audio.audio_file,
                  duration: props.audio.duration,
                  filename: props.audio.filename,
                  audioFilename: props.audio.name,
                  source: STOCK
                },
                e
              );
            }}
            >
            <div className={cx(style["item-holder"])}>
              <div
                className={cx(
                  style["audio-play-btn"],
                  style["d-flex"],
                  style["pl-2"],
                  style["pr-1"],
                  style["cursor-pointer"]
                )}
                onClick={e => playAudioOnClick(e)}>
                <Icon icon={props.isPlaying ? "ui-circled-pause" : "ui-circled-play"}></Icon>
              </div>
              {!props.isPlaying && (
                <div className={cx(style["ml-2"], style["w-75"])}>
                  <h6 ref={audiotooltipRef} id={`tooltip-${props.audio._id}`} className={cx(style["audio-title"], style["text-truncate"], style["mb-0"])}>{props.audio.name}</h6>
                  <Tooltip
                    placement="bottom"
                    isOpen={showTooltip}
                    target={`tooltip-${props.audio._id}`}
                    toggle={toggleTooltip}
                    boundariesElement={document.getElementById("app")}
                    >
                    {props.audio.name}
                  </Tooltip>
                  <div className={cx(style["audio-duration"], style["text-muted"], style["font-13"])}>
                    {props.audio.duration}
                  </div>
                </div>
              )}

              {props.isPlaying && (
                <>
                  <audio
                    ref={audioRef}
                    src={props.audio.audio_file}
                    onTimeUpdate={onTimeUpdate}
                    onLoadedMetadata={onLoadedMetadata}
                    onEnded={onEnded}
                    autoPlay
                  />
                  <div
                    className={cx(style["ml-3"], style["info"])}
                    onClick={e => e.stopPropagation()}
                    onMouseDown={e => e.stopPropagation()}>
                    <SliderControll
                      Slider={currentTime}
                      setSlider={onSliderChange}
                      sliderMin={0}
                      sliderMax={duration}
                      step={0.1}
                      hideSliderValue
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </li>
      )}
    </React.Fragment>
  );
};
AudioAsset.propTypes = {
  audio: PropTypes.object.isRequired,
  length: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  lastAssetElement: PropTypes.func.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  onPlayAudio: PropTypes.func.isRequired,
};


const AudioList = props => {
  const dispatch = useDispatch();
  const observer = useRef();
  const audioListRef = useRef();

  const limit = 50;

  const {
    loading: asstesLoading,
    totalAssetCount,
    availableassetLists: availableAssetLists,
  } = useSelector(state => state.editor);
  const [filterApplied, setfilterApplied] = useState(false);
  const [currentlyPlayingIndex, setCurrentlyPlayingIndex] = useState(null);

  // Function to handle audio play and puase
  const handlePlayAudio = index => {
    if (currentlyPlayingIndex === index) {
      // If the same audio is clicked again, pause it
      setCurrentlyPlayingIndex(null);
    } else {
      setCurrentlyPlayingIndex(index);
    }
  };

  let hasMoreAudio;

  if (totalAssetCount && totalAssetCount > 0) {
    let lastPage = Math.floor(totalAssetCount % limit === 0 ? totalAssetCount / limit : totalAssetCount / limit + 1);
    hasMoreAudio = props.pageNumber < lastPage;
  }

  //Fetch assets api for the 1st type insert in the widget
  useEffect(() => {
    let payLoad = {
      assetType: props.assetType,
      pageNumber: 1,
      limit: limit,
    };
    dispatch(fetchAssetsList(payLoad));
    setfilterApplied(true);
  }, []);

  //Observe the last node for pagination and update page number on scroll
  const lastAssetElement = useCallback(
    node => {
      if (asstesLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMoreAudio) props.setPageNumber(props.pageNumber + 1);
      });
      if (node) observer.current.observe(node);
    },
    [asstesLoading, hasMoreAudio]
  );

  //Fetch assets api for the 2nd time onwards(applied filter, pagination)
  useEffect(() => {
    if (filterApplied) {
      let payLoad = {
        assetType: props.assetType,
        pageNumber: props.pageNumber,
        limit: limit,
      };
      if (props.keyword) payLoad.keyword = props.keyword;
      if (props.assetCategory && !props.keyword) payLoad.assetCategory = props.assetCategory;

      if (props.pageNumber === 1) {
        audioListRef.current.scrollTop = 0;
      }
      dispatch(filterAssetsList(payLoad));
    }
  }, [props.pageNumber, props.assetCategory, props.keyword]);

  useEffect(() => {
    if (availableAssetLists) props.setIsAssetFetched(true);
  }, [availableAssetLists]);

  // To pause Audio if category change
  useEffect(() => {
    setCurrentlyPlayingIndex(null);
  }, [props.assetCategory, props.keyword]);

  return (
    <div
      className={cx(style["customScroll"], style["scroll-Y"], style["assets-wrapper"])}
      ref={audioListRef}
      onContextMenu={e => e.preventDefault()}>
      <ul className={cx(style["row"], style["row-cols-1"])}>
        {asstesLoading && props.pageNumber === 1 && <AssetsLoader count={20} />}

        {availableAssetLists?.length > 0 &&
          props.isAssetFetched &&
          availableAssetLists.map((availableAssetList, index) => (
            <AudioAsset
              key={index}
              audio={availableAssetList}
              length={availableAssetLists.length}
              lastAssetElement={lastAssetElement}
              assetType={props.assetType}
              index={index}
              isPlaying={index === currentlyPlayingIndex} // whether the audio is currently playing
              onPlayAudio={handlePlayAudio}
            />
          ))}

        {!asstesLoading && props.isAssetFetched && availableAssetLists?.length === 0 && (
          <li className={cx(style["col"])}>
            <div className={cx(style["alert"], style["alert-block"], style["alert-danger"])}>
              No matching {props.assetName.toLowerCase()} found
            </div>
          </li>
        )}
      </ul>
      {asstesLoading && props.pageNumber > 1 && <DotLoader />}
    </div>
  );
};
// Props type validation
AudioList.propTypes = {
  assetName: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
  pageNumber: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  assetCategory: PropTypes.string.isRequired,
  keyword: PropTypes.string.isRequired,
  isAssetFetched: PropTypes.bool.isRequired,
  setIsAssetFetched: PropTypes.func.isRequired
};

const Audio = props => {
  const [keyword, setKeyword] = useState("");
  const [category, setCategory] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [isAssetFetched, setIsAssetFetched] = useState(false);

  return (
    <div className={cx(style["editor-asset-inner"], style["audio-asset"])}>
      <AssetName handleWidgetAction={props.handleWidgetAction} assetName={props.assetName} />
      <div className={style["pr-20"]}>
        <AssetSearch
          assetName={props.assetName}
          keyword={keyword}
          setKeyword={setKeyword}
          setCategory={setCategory}
          setPageNumber={setPageNumber}
        />

        <AssetCategory
          category={category}
          setCategory={setCategory}
          setKeyword={setKeyword}
          setPageNumber={setPageNumber}
        />
      </div>
      <AudioList
        assetName={props.assetName}
        assetType={props.assetType}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        assetCategory={category}
        keyword={keyword}
        isAssetFetched={isAssetFetched}
        setIsAssetFetched={setIsAssetFetched}
      />
    </div>
  );
};

//Props type validation
Audio.propTypes = {
  handleWidgetAction: PropTypes.func.isRequired,
  assetName: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
};

export default Audio;
