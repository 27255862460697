import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Button } from "reactstrap";
import cx from "classnames";

import { COMPANY_STATUS_DEACTIVATED as DEACTIVATED } from "../../../constants/company";
import { DOCUMENT_TRANSFER_SUCCESS, DOCUMENT_TRANSFER_FAILED } from "../../../store/actions/actionTypes";
import { getUserInfo } from "../../../store/actions/authActions";
import { fetchCompany } from "../../../store/actions/companyActions";
import { transferDocument } from "../../../store/actions/documentActions";
import { preventFormSubmitOnEnter as preventSubmit } from "../../../_helpers/utils";
import { Input } from "../../ui/input";
import { Icon } from "../../ui/icon";
import { ProcessLoader } from "../../ui/loader/processLoader";
import { FlashAlert } from "../../ui/flashAlert";

import global from "../../../scss/dhp.scss";

let style = Object.assign({}, global);

const TransferModal = ({ document, _helpers }) => {
  const dispatch = useDispatch();
  const alertData = {
    show: false,
    type: "",
  };
  const processEndTypes = [DOCUMENT_TRANSFER_SUCCESS, DOCUMENT_TRANSFER_FAILED];

  const form = {
    target_company: {
      value: "",
      validation: {
        required: true,
      },
      valid: false,
    },
  };

  const [formData, setFormData] = useState(form);
  const [formValid, setFormValid] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [alert, setAlert] = useState(alertData);
  const [validCompanies, setValidCompanies] = useState([]);
  const [disable, setDisable] = useState({ transfer: false });

  const userInfo = useSelector(state => state?.auth?.user);
  const companies = useSelector(state => state?.company?.companies);
  const { type } = useSelector(state => state?.document);

  const toggleAlert = () => setAlert({ ...alert, show: !alert.show });

  const getValidCompanies = () =>
    setValidCompanies(
      companies.filter(company => company.id !== userInfo?.company?.id && company?.status !== DEACTIVATED)
    );

  const checkValidity = (value, rules) => {
    let isValid = true;
    if (rules.required) isValid = value.trim() !== "" && isValid;
    return isValid;
  };

  const updateForm = (e, field) => {
    let updatedForm = { ...formData };
    updatedForm[field].value = e.id;
    updatedForm[field].valid = checkValidity(updatedForm[field].value, updatedForm[field].validation);
    setFormData(updatedForm);
    setFormValid(Object.keys(formData).every(field => formData[field].valid));
  };

  const handleSubmit = e => {
    if (formValid) {
      e.preventDefault();
      setIsMounted(true);
      let payload = {
        documentId: document.id,
        companyId: formData.target_company.value,
      };
      dispatch(transferDocument(payload));
      setDisable({ transfer: true });
    }
  };

  useEffect(() => {
    if (isMounted && type && type === DOCUMENT_TRANSFER_SUCCESS) {
      setIsMounted(false);
      _helpers.modal.toggle();
    }
    if (isMounted && type && type === DOCUMENT_TRANSFER_FAILED) {
      setIsMounted(false);
      setAlert({ ...alert, show: true, type: "error" });
    }
  }, [type, isMounted]);

  useEffect(() => {
    if (userInfo && companies) getValidCompanies();
  }, [userInfo, companies]);

  useEffect(() => {
    if (!userInfo) dispatch(getUserInfo());
    if (!companies) dispatch(fetchCompany());
  }, []);

  return (
    <React.Fragment>
      <h4 className={cx(style["fw-7"], style["mb-4"])}>Transfer Document</h4>
      <Link to="#" className={cx(style["cross-modal"], style["rounded"])} onClick={_helpers?.modal?.toggle}>
        <Icon icon="ui-close" />
      </Link>

      <Form onKeyDown={e => preventSubmit(e)} onSubmit={e => handleSubmit(e)}>
        <Input
          cssModule={style}
          returnType="formGroup"
          type="dropdown"
          label="Choose Target Company"
          required={true}
          classNamePrefix="select"
          getOptionValue={option => option?.name}
          getOptionLabel={option => <span>{option?.name}</span>}
          options={validCompanies}
          updateState={e => updateForm(e, "target_company")}
        />

        <div className={cx(style["align-items-center"], style["d-flex"])}>
          <Button type="submit" color="primary" cssModule={style} disabled={!formValid || disable.transfer}>
            Proceed
          </Button>

          <ProcessLoader isOpen={disable.transfer && !processEndTypes.includes(type)} />
        </div>
      </Form>

      <FlashAlert isOpen={alert.show} toggle={toggleAlert} type={alert.type} wrapperClass="mt-3" />
    </React.Fragment>
  );
};

TransferModal.propTypes = {
  document: PropTypes.object.isRequired,
  _helpers: PropTypes.object.isRequired,
};

export default TransferModal;
