import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import AssetName from "./Common/AssetName";
import useElementInnerHtml from "../../../hooks/useElementInnerHtml";
import useAddWidget from "../../../hooks/useAddWidget";
import useAlignment from "../../../hooks/useAlignment";
import { widgetConfig } from "../editor_config";
import useDragDropWidget from "../../../hooks/useDragDropWidget";
import useTextFocusOut from "../../../hooks/useTextFocusOut";
import { LINE_HEIGHT } from "../../../constants/editor";
import AssetSchemeTypeNav from "./Common/AssetSchemeTypeNav";
import { AppContext } from "../../../contexts";
import BrandSetup from "./BrandSetup";
import BrandCompanyList from "./BrandCompanyList";
import SingleBrandkitFont from "./SingleBrandkitFont";
import { useSelector } from "react-redux";
import BrandkitTabPaneSubTitle from "./BrandkitTabPaneSubTitle";
import AssetsLoader from "../../ui/loader/assetsLoader";

let style = Object.assign({}, global);

const Text = props => {
  let { brandInfo } = useContext(AppContext);

  const themeSchemes = ["Preset", "Brand"];
  const textAssests = [
    {
      name: "Add Heading",
      value: "h1",
      class: "main-heading",
    },
    {
      name: "Add Subheading",
      value: "h2",
      class: "sub-heading",
    },
    {
      name: "Add Body Text",
      value: "normal",
      class: "default-text",
    },
  ];

  const { loading } = useSelector(state => state.company);

  const { start: initDragDropWidget } = useDragDropWidget();

  const [assetInnerContent, setAssetInnerContent] = useState();
  const [textHeading, setTextHeading] = useState();
  const [addWidgetClicked, setAddWidgetClicked] = useState(false);
  const [dataparam, setDataParam] = useState();
  const [schemeType, setSchemeType] = useState("Preset");
  const [isBrandkit, setIsBrandkit] = useState();
  const [isFetchDone, setIsFetchDone] = useState(false);

  const setTextFocusOut = useTextFocusOut();
  const { postion: getPosition } = useAlignment(
    addWidgetClicked,
    widgetConfig[props.assetType]?.[textHeading]?.width,
    widgetConfig[props.assetType]?.[textHeading]?.height * LINE_HEIGHT,
    "middle-center"
  );
  const getNewWidgetObject = useAddWidget(addWidgetClicked, props.assetType, dataparam, getPosition);
  useElementInnerHtml(
    addWidgetClicked,
    setAddWidgetClicked,
    props.assetType,
    assetInnerContent,
    dataparam,
    getNewWidgetObject
  );

  const insertText = headingType => {
    if (document.querySelector(".dhp-content-editable-true-text")) setTextFocusOut(true);

    setTimeout(() => {
      let dataAttr = { ...widgetConfig[props.assetType][headingType].dataAttr, "data-heading": headingType };

      setAssetInnerContent(widgetConfig.texts.content);
      setDataParam(dataAttr);
      setTextHeading(headingType);
      setAddWidgetClicked(true);
    }, 1);
  };

  useEffect(() => {
    if (brandInfo?.brands.length >= 0) setIsFetchDone(true);
    if (brandInfo?.brands.length > 0) setIsBrandkit(true);
  }, [brandInfo]);

  return (
    <div className={cx(style["editor-asset-inner"], style["text-asset"])}>
      <AssetName handleWidgetAction={props.handleWidgetAction} assetName={props.assetName} />

      <AssetSchemeTypeNav
        schemeTypeArray={themeSchemes}
        setSchemeType={setSchemeType}
        schemeType={schemeType}
        assetType={props.assetType}
      />

      {schemeType === "Preset" && (
        <div className={cx(style["customScroll"], style["scroll-Y"], style["assets-wrapper"])}>
          <div
            className={cx(
              style["rounded"],
              style["pt-2"],
              style["pb-1"],
              style["px-3"],
              style["mb-3"],
              style["bg-secondary"],
              style["preset-info"]
            )}>
            <BrandkitTabPaneSubTitle
              title={"Preset Text Style"}
              tooltipId="brandFontPresetStyle"
              tooltipContent={"Use different heading to better structure your document"}
            />
          </div>

          <ul className={cx(style["row"], style["row-cols-1"])}>
            {textAssests.map(textType => (
              <li key={textType.value} className={style["col"]}>
                <div
                  className={cx(style["asset-item"], style[textType.class])}
                  onClick={() => insertText(textType.value)}
                  onMouseDown={e =>
                    initDragDropWidget(
                      {
                        asset: props.assetType,
                        value: textType.value,
                      },
                      e
                    )
                  }>
                  {textType.name}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {schemeType === "Brand" && (
        <>
          {isFetchDone && !isBrandkit && (
            <BrandSetup
              icon="ui-brand-fonts"
              heading="Use Brand Text Style"
              content="Keep your design consistent
              with your brand text style."
              buttonLabel="Set up Brand Text Style"
            />
          )}

          {isFetchDone && isBrandkit && (
            <>
              <div className={style["pr-20"]}>
                <BrandCompanyList />
              </div>

              {loading && !brandInfo.brandDetails && (
                <ul
                  className={cx(
                    style["color-list"],
                    style["row"],
                    style["asset-brand-font-loading"],
                    style["row-cols-1"]
                  )}>
                  <AssetsLoader count={3} />
                </ul>
              )}

              {!loading && brandInfo.brandDetails && (
                <div className={cx(style["pr-20"], style["assets-wrapper"], style["brand-font-assets-wrapper"])}>
                  <SingleBrandkitFont />
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

//Props type validation
Text.propTypes = {
  handleWidgetAction: PropTypes.func.isRequired,
  assetName: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
};

export default Text;
