import { useContext, useEffect, useState } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";
import { getCssTransformObj } from "../_helpers/utils";

const useAlignment = (addWidgetClicked, widgetWidth, widgetHeight, allignment) => {
  let { metadata, dimension, widgets } = useContext(EditorContext);

  const [postion, setPostion] = useState(null);
  const [excludeId, setExcludeId] = useState(null);

  useEffect(() => {
    if (addWidgetClicked) {
      let left, top;
      let parentPos = document.getElementById(metadata.activeBlockId).getBoundingClientRect();
      let zoomval = 100 / parseFloat(dimension.zoom);
      let positionArray = [];
      let leftMostWidgetID, rightMostWidgetId, topMostWidgetId, bottomMostWidgetId;

      // allignment with respect to active block for single widget
      if (metadata.activeWidgetId.length === 1 || allignment === "middle-center") {
        // For both center and middle allignment for widget click and add
        if (allignment === "middle-center") {
          left = (parentPos.width / 2) * zoomval - widgetWidth / 2;
          top = (parentPos.height / 2) * zoomval - widgetHeight / 2;
        }
        // Widget top allignment with respect to active block
        else if (allignment === "top") {
          top = 0;
        }
        // Widget middle allignment with respect to active block
        else if (allignment === "middle") {
          top = (parentPos.height / 2) * zoomval - widgetHeight / 2;
        }
        // Widget bottom allignment with respect to active block
        else if (allignment === "bottom") {
          top = parentPos.height * zoomval - widgetHeight;
        }
        // Widget left allignment with respect to active block
        else if (allignment === "left") {
          left = 0;
        }
        // Widget center allignment with respect to active block
        else if (allignment === "center") {
          left = (parentPos.width / 2) * zoomval - widgetWidth / 2;
        }
        // Widget right allignment with respect to active block
        else if (allignment === "right") {
          left = parentPos.width * zoomval - widgetWidth;
        }

        positionArray.push({ left: left, top: top });
      }
      // allignment with respect to active div for multiple widgets
      else if (metadata.activeWidgetId.length > 1) {
        let activeWidgetIndexArray = [];

        // create acive widget index array
        metadata.activeWidgetId.forEach(id => {
          let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
          let targetId = isGroupWidget
            ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id")
            : id;
          let widgetIndex = widgets.findIndex(widget => widget.id === targetId);
          activeWidgetIndexArray.push(widgetIndex);
        });

        let childPosition = document.getElementById(widgets[activeWidgetIndexArray[0]].id).getBoundingClientRect();
        let leftValue = (childPosition.left - parentPos.left) * zoomval;
        let topValue = (childPosition.top - parentPos.top) * zoomval;
        let rightValue = (childPosition.right - parentPos.left) * zoomval;
        let bottomValue = (childPosition.bottom - parentPos.top) * zoomval;

        leftMostWidgetID = widgets[activeWidgetIndexArray[0]].id;
        rightMostWidgetId = widgets[activeWidgetIndexArray[0]].id;
        topMostWidgetId = widgets[activeWidgetIndexArray[0]].id;
        bottomMostWidgetId = widgets[activeWidgetIndexArray[0]].id;

        // calculate most left, top, right, bottom in active widgets
        activeWidgetIndexArray.forEach(value => {
          let currentChildPosition = document.getElementById(widgets[value].id).getBoundingClientRect();
          let currentLeftValue = (currentChildPosition.left - parentPos.left) * zoomval;
          let currentTopValue = (currentChildPosition.top - parentPos.top) * zoomval;
          let currentRightValue = (currentChildPosition.right - parentPos.left) * zoomval;
          let currentBottomValue = (currentChildPosition.bottom - parentPos.top) * zoomval;

          if (currentLeftValue < leftValue) {
            leftValue = currentLeftValue;
            leftMostWidgetID = widgets[value].id;
          }
          if (currentTopValue < topValue) {
            topValue = currentTopValue;
            topMostWidgetId = widgets[value].id;
          }
          if (currentRightValue > rightValue) {
            rightValue = currentRightValue;
            rightMostWidgetId = widgets[value].id;
          }
          if (currentBottomValue > bottomValue) {
            bottomValue = currentBottomValue;
            bottomMostWidgetId = widgets[value].id;
          }
        });

        // calculate div width height with respect to active widgets
        let divHeight = bottomValue - topValue;
        let divWidth = rightValue - leftValue;

        // Widget top allignment with respect to active div
        if (allignment === "top") {
          activeWidgetIndexArray.forEach(() => {
            positionArray.push({ left: left, top: topValue });
          });
          setExcludeId(topMostWidgetId);
        }
        // Widget middle allignment with respect to active div
        else if (allignment === "middle") {
          activeWidgetIndexArray.forEach(value => {
            let newTop = divHeight / 2 + topValue - parseFloat(widgets[value].style.height) / 2;
            positionArray.push({ left: left, top: newTop });
          });
          setExcludeId(null);
        }
        // Widget bottom allignment with respect to active div
        else if (allignment === "bottom") {
          activeWidgetIndexArray.forEach(value => {
            let rect = document.getElementById(widgets[value].id).getBoundingClientRect();
            let newTop = bottomValue - parseFloat(rect.height);
            positionArray.push({ left: left, top: newTop });
          });
          setExcludeId(bottomMostWidgetId);
        }
        // Widget left allignment with respect to active div
        else if (allignment === "left") {
          activeWidgetIndexArray.forEach(() => {
            positionArray.push({ left: leftValue, top: top });
          });
          setExcludeId(leftMostWidgetID);
        }
        // Widget center allignment with respect to active div
        else if (allignment === "center") {
          activeWidgetIndexArray.forEach(value => {
            let newLeft = divWidth / 2 + leftValue - parseFloat(widgets[value].style.width) / 2;
            positionArray.push({ left: newLeft, top: top });
          });
          setExcludeId(null);
        }
        // Widget right allignment with respect to active div
        else if (allignment === "right") {
          activeWidgetIndexArray.forEach(value => {
            let rect = document.getElementById(widgets[value].id).getBoundingClientRect();
            let newLeft = rightValue - parseFloat(rect.width);
            positionArray.push({ left: newLeft, top: top });
          });
          setExcludeId(rightMostWidgetId);
        }
      }

      setPostion(positionArray);
    }

    return () => {
      setPostion(null);
      setExcludeId(null);
    };
  }, [addWidgetClicked]);

  return { postion, excludeId };
  // return postion;
};

export default useAlignment;
