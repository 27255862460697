import React from "react";
import PropTypes from "prop-types";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import global from "../../scss/dhp.scss";
import cx from "classnames";

let style = Object.assign({}, global);

export const RMasonry = ({ module, children, isTileView}) => {
  const config = {
    documents: {
      // gutter: "24px",
      gutter: "0",
      responsive: {
        0: 1,
        520: 2,
        767: 3,
        1080: 4,
        1280: 5,
        1400: 6,
        1680: 6,
        1918: 7,
        2219: 8,
        2548: 9,
        2877: 10,
        3206: 11,
        3535: 12,
        3864: 13,
        4193: 14,
        4522: 15,
        4851: 16,
        5180: 17,
        5509: 18,
        5838: 19,
        6167: 20,
        6496: 21,
        6825: 22,
        7154: 23,
        7483: 24,
        7812: 25,
        8141: 26,
        8470: 27,
        8799: 28,
        9128: 29,
        9457: 30,
        9786: 31,
      },
    },
    templates: {
      // gutter: "24px",
      gutter: "0",
      responsive: {
        0: 1,
        520: 2,
        767: 2,
        1080: 3,
        1280: 4,
        1400: 5,
        1680: 5,
        1918: 6,
        2219: 8,
        2548: 9,
        2877: 10,
        3206: 11,
        3535: 12,
        3864: 13,
        4193: 14,
        4522: 15,
        4851: 16,
        5180: 17,
        5509: 18,
        5838: 19,
        6167: 20,
        6496: 21,
        6825: 22,
        7154: 23,
        7483: 24,
        7812: 25,
        8141: 26,
        8470: 27,
        8799: 28,
        9128: 29,
        9457: 30,
        9786: 31,
      },
    },
    editor_assets: {
      // gutter: "12px",
      gutter: "0",
      responsive: {
        9786: 2,
      },
    },
    facebook_images: {
      gutter: "24px",
      responsive: {
        9786: 4,
      },
    },
    default: {
      // gutter: "24px",
      gutter: "0",
      responsive: {
        9786: 3,
      },
    }
  };

  const view = config[module] ?? config.default;

  return (
    <ResponsiveMasonry columnsCountBreakPoints={view.responsive} className={style["dhp-responsive-masonry"]}>
      <Masonry gutter={view.gutter} className={cx(style["dhp-masonry"], {[style["tile-view"]]: isTileView})}>
        {children}
      </Masonry>
    </ResponsiveMasonry>
  );
};

RMasonry.propTypes = {
  module: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  isTileView : PropTypes.bool
};
