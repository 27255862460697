import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Icon } from "./icon";
import global from "../../scss/dhp.scss";
import { TARGET_COMPANY_TRANSFER_INFO } from "../../constants/document";
import { Toast, ToastBody } from "reactstrap";

let style = Object.assign({}, global);

export const FlashAlert = ({ isOpen, toggle, ...props }) => {
  const fadeIn = props?.fadeIn ?? 3000;
  // const fadeIn = 20000000000;
  const customComponent = {
    transfer: {
      icon: "zoom-in2",
      info: TARGET_COMPANY_TRANSFER_INFO,
      wrapperClass: "alert-danger",
    },
  };
  const alertTypes = {
    success: {
      message: props?.message || "Success",
      wrapperClass: props?.wrapperClass ? `text-success ${props?.wrapperClass}` : "text-success",
      contentClass: props?.contentClass || "",
      icon: "tick-success",
      iconAdditionalClass: "mr-1",
    },
    error: {
      message: props?.message || "Something went wrong! Please try again later.",
      wrapperClass: props?.wrapperClass ? `text-danger ${props?.wrapperClass}` : "text-danger",
      contentClass: props?.contentClass || "",
      icon: "ui-alert",
      iconAdditionalClass: "mr-1",
    },
    custom: {
      message: props.message || "Dummy message",
      wrapperClass: customComponent[props?.component]?.wrapperClass,
      contentClass: props?.contentClass || "",
      icon: customComponent[props?.component]?.icon || false,
      iconAdditionalClass: props?.iconAdditionalClass || "",
      info: customComponent[props?.component]?.info || false,
    },
  };

  const [alert, setAlert] = useState({});

  useEffect(() => {
    if (isOpen) {
      setAlert(alertTypes[props.type]);
      setTimeout(() => toggle(), fadeIn);
    }
  }, [isOpen]);

  return (
    isOpen && (
      <>
        <div className={cx(style["alert"], alert?.wrapperClass)}>
          <div className={cx(style["align-items-center"], style["d-flex"], alert?.contentClass)}>
            {alert?.icon && <Icon icon={alert?.icon} additionalclass={alert?.iconAdditionalClass}></Icon>}
            <span className={style["color-33"]}>{alert?.message}</span>
          </div>
        </div>
        {props.type === "custom" && alert.info && (
          <Toast className={cx(style["position-absolute"])}>
            <ToastBody>{alert.info}</ToastBody>
          </Toast>
        )}
      </>
    )
  );
};

FlashAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  type: PropTypes.string,
  wrapperClass: PropTypes.string,
  contentClass: PropTypes.string,
  icon: PropTypes.string,
  iconAdditionalClass: PropTypes.string,
  message: PropTypes.string,
  fadeIn: PropTypes.number,
  info: PropTypes.string,
  component: PropTypes.string,
};
