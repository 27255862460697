import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import global from "../../scss/dhp.scss";

import { widgetConfig } from "../../components/Editor/editor_config";
import { EditorContext } from "./EditorLayout";
import LoadToolbarComponent from "../../components/Editor/ContextualMenu/LoadToolbarComponent";
import { AUDIO, GIF, GROUP_WIDGET, SVG, UPLOAD, VIDEO } from "../../constants/editor";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import useClipboard from "../../hooks/useClipboard";

let style = Object.assign({}, global);

const RightContextMenubar = () => {
  let { metadata, widgets, rightContextMenu, updateRightContextMenu } = useContext(EditorContext);
  let currentBlockWidgetList = widgets.filter(widget => widget.blockId === metadata.activeBlockId);

  const rightContextRef = useRef(null);

  const [contextualMenubarList, setContextualMenubarList] = useState();
  const [isClipBoardExist, setIsClipBoardExist] = useState(false);

  const companyInfo = useSelector(state => state?.auth?.company);

  const { read: readFromClipboard } = useClipboard();

  const checkClipBoardData = async () => {
    const { dataSource, dataType, data, isValidWidget } = await readFromClipboard();

    setIsClipBoardExist(
      isValidWidget &&
        ((dataSource === "dhp" && data?.companyId === companyInfo?._id) ||
          (dataSource === "non_dhp" && dataType === "image/png"))
    );
  };

  // Hide right context menu on outsideclick
  useOnClickOutside(rightContextRef, e => {
    if (
      e.target.closest(".link-modal") === null &&
      e.target.closest(".pictureFilter-Modal") === null &&
      e.target.closest(".subscription-modal") === null &&
      e.target.closest(".remove-bg-modal") === null &&
      e.target.closest(".crop-modal") === null
    ) {
      updateRightContextMenu({
        ...rightContextMenu,
        enable: false,
      });
    }
  });

  useEffect(() => {
    checkClipBoardData();

    let contextMenuLists;
    let newRightContextMenu = JSON.parse(JSON.stringify(widgetConfig));

    if (metadata.activeWidgetType) {
      // right context menu for single widget
      if (metadata.activeWidgetId.length === 1) {
        let fileType = document.getElementById(metadata.activeWidgetId[0])?.dataset?.fileType;
        let isGroupWidget = document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-page-group");

        //for upload widget
        if (metadata.activeWidgetType[0] === UPLOAD && (fileType === SVG || fileType === GIF)) {
          contextMenuLists = newRightContextMenu[metadata.activeWidgetType[0]][fileType]?.contextualMenu?.right;
        }
        //for video widget
        else if (metadata.activeWidgetType[0] === VIDEO) {
          contextMenuLists =
            newRightContextMenu[metadata.activeWidgetType[0]][
              document.getElementById(metadata.activeWidgetId[0])?.dataset?.source
            ]?.contextualMenu?.right;
        }
        //for rest widget
        else {
          contextMenuLists = newRightContextMenu[metadata.activeWidgetType[0]]?.contextualMenu?.right;
        }

        // Add ungroup option in right context menu if group inner widget is selcted
        if (isGroupWidget && metadata.activeWidgetType[0] !== GROUP_WIDGET && !contextMenuLists.includes("UnGroup")) {
          let cloneIndex = contextMenuLists.findIndex(contextMenuList => contextMenuList === "Clone");
          contextMenuLists.splice(cloneIndex + 1, 0, "UnGroup");
        }
      }
      // right context menu for multiple widget
      else {
        if (metadata.activeWidgetType.includes("groups"))
          contextMenuLists = newRightContextMenu.shift.contextualMenu.right;
        else contextMenuLists = newRightContextMenu.shift.contextualMenu.right.filter(menu => menu !== "UnGroup"); // remove ungroup option from menu if there is no group widgetselcted
      }

      // Remove Paste option from context menu if there is no widget coppied
      if (!isClipBoardExist) {
        contextMenuLists = contextMenuLists.filter(contextMenuList => contextMenuList !== "Paste");
      }
    } else if (metadata.activeAudioId) {
      contextMenuLists = newRightContextMenu[AUDIO]?.contextualMenu?.right;
    } else {
      contextMenuLists = ["Paste"];
    }

    setContextualMenubarList(contextMenuLists);
  }, [rightContextMenu, metadata.activeWidgetType, isClipBoardExist]);

  useEffect(() => {
    setTimeout(() => {
      if (rightContextRef.current) {
        let screenHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        let contextMenuHeight = parseFloat(window.getComputedStyle(rightContextRef.current)?.height);
        let contextMenuBottom = parseFloat(rightContextMenu.top) + contextMenuHeight;
        let screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        let contextMenuWidth = parseFloat(window.getComputedStyle(rightContextRef.current)?.width);
        let contextMenueRight = parseFloat(rightContextMenu.left) + contextMenuWidth;

        let updatedTop =
          contextMenuBottom > screenHeight
            ? `${parseFloat(rightContextMenu.top) - (contextMenuBottom - screenHeight) - 24}px`
            : rightContextMenu.top;
        let updatedLeft =
          contextMenueRight > screenWidth
            ? `${parseFloat(rightContextMenu.left) - (contextMenueRight - screenWidth) - 24}px`
            : rightContextMenu.left;

        updateRightContextMenu({
          ...rightContextMenu,
          style: {
            ...rightContextMenu.style,
            top: updatedTop,
            left: updatedLeft,
            visibility: "visible",
          },
        });
      }
    }, 200);
  }, []);

  return (
    <div className={style["context-menu"]} style={rightContextMenu.style} ref={rightContextRef}>
      <ul className={style["toolbar-group"]}>
        {contextualMenubarList?.map((contextMenue, idx) => (
          <React.Fragment key={idx}>
            <LoadToolbarComponent key={idx} component={contextMenue} rightContext={true} />

            {(contextMenue === "Clone" ||
              (contextMenue === "Layer" && currentBlockWidgetList.length !== 1) ||
              contextMenue === "Link") && <hr />}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};

export default RightContextMenubar;
