import { useContext } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";

const useCheckWidgetPosition = () => {
  let { metadata, dimension } = useContext(EditorContext);

  const deleteWidgetIfOutside = (widgetId, blockId) => {
    let zoomval = 100 / parseFloat(dimension.zoom);
    let parentId = blockId ?? metadata.activeBlockId;
    let parentElem = document.getElementById(parentId);
    let parentPos = parentElem.getBoundingClientRect();
    let activeBlockWidth = parentPos.width * zoomval;
    let activeBlockHeight = parentPos.height * zoomval;
    let curWidgetId = widgetId ?? metadata.activeWidgetId[0];
    let childPos = document.getElementById(curWidgetId).getBoundingClientRect();

    let WidgetLeft = parseFloat(childPos.left - parentPos.left) * zoomval;
    let widgetTop = parseFloat(childPos.top - parentPos.top) * zoomval;
    let widgetRight = parseFloat(parentElem.style.width) + (childPos.right - parentPos.right) * zoomval;
    let widgetBottom = parseFloat(parentElem.style.height) + (childPos.bottom - parentPos.bottom) * zoomval;

    // Delete the widget if it goes outside the canvas area (TODO :: Needs to work when widget drag to one block to another)
    if (widgetRight <= 0 || widgetBottom <= 0 || WidgetLeft >= activeBlockWidth || widgetTop >= activeBlockHeight)
      return true;

    return false;
  };
  return deleteWidgetIfOutside;
};

export default useCheckWidgetPosition;