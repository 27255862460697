import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Button, Spinner } from "reactstrap";
import { floatToFloatSec, getSecToHMS, isMediaPlaying, playMedia } from "../../../_helpers/utils";
import { AUDIO, VIDEO } from "../../../constants/editor";
import { Icon } from "../../ui/icon";
import Trimmer from "../../ui/trimmer";
import useVideo from "../../../hooks/useVideo";
import useAudio from "../../../hooks/useAudio";
import global from "../../../scss/dhp.scss";
import { EditorContext } from "../../../containers/editor/EditorLayout";

let style = Object.assign({}, global);

const TrimModal = ({
  data: { audioId, widgetId, src, poster, volume, durationInSec, trimStart, trimEnd },
  fileType,
  showModal,
  setShowModal,
}) => {
  let { setIsVideoTrimApplied, isVideoTrimApplied } = useContext(EditorContext);

  const modalName = fileType === VIDEO ? "Trim Video" : "Adjust Audio";
  const mediaRef = useRef();
  const { applyTrim: applyVideoTrim } = useVideo();
  const { applyTrim: applyAudioTrim } = useAudio();
  const trimmerConfig = {
    trackHeight: 5,
    showUnitInSec: true,
    maxDuration: durationInSec,
    minDuration: 1,
    showWaveform: fileType === AUDIO,
    src,
  };

  const [defaultValue, setDefaultValue] = useState();
  const [sliderProgress, setSliderProgress] = useState(0);

  const [value, setValue] = useState();
  const [trimmedDurationSec, setTrimmedDurationSec] = useState();
  const [trimmedDurationHms, setTrimmedDurationHms] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  const onChangeHandler = data => {
    setValue(data.rangeSec);
    setTrimmedDurationSec(data.rangeDiff);
    setTrimmedDurationHms(data.rangeDiffHms);

    // replay media (video/audio) from latest trim start time
    const media = mediaRef.current;
    media.currentTime = data.rangeSec[0];
    setSliderProgress(0);
  };

  // const onKeyUpHandler = data => {
  //   console.log(data);
  // };

  const mediaPlayer = () => {
    if (isLoading) return;
    const media = mediaRef.current;
    const [startTime, stopTime] = value;
    const isPlaying = isMediaPlaying({ mediaElm: media, startTime, stopTime });

    if (isPlaying) media.pause();
    else {
      media.volume = volume / 100;
      playMedia(media);
    }
  };

  const timeUpdateHandler = e => {
    const media = e.target;
    const curTime = floatToFloatSec(media.currentTime);
    const [startTime, stopTime] = value;

    if (curTime >= startTime && curTime <= stopTime) {
      const offsetTime = curTime - startTime;
      setSliderProgress((100 / trimmedDurationSec) * offsetTime);
    } else {
      setSliderProgress(0);
      media.currentTime = startTime;
      if (!media.paused) playMedia(media);
    }
  };

  const loadedMetadataHandler = e => {
    const media = e.target;
    media.currentTime = trimStart;

    if (fileType === VIDEO) {
      const mediaPreview = document.querySelector(".media-preview");
      const aspectRatio = media.videoWidth / media.videoHeight;
      mediaPreview.style.width = mediaPreview.clientHeight * aspectRatio + "px";
    }
  };

  const eventHandler = e => {
    const media = e.target;
    const curTime = floatToFloatSec(media.currentTime);
    const stopTime = value?.[1] ?? trimEnd;
    if (["canplay", "pause"].includes(e.type)) setShowLoader(false);
    if (["canplay"].includes(e.type)) setIsLoading(false);
    if (["pause"].includes(e.type)) setIsPlaying(false);
    if (["play"].includes(e.type)) setIsPlaying(true);
    if (["ended"].includes(e.type)) playMedia(media);
    if (["waiting", "suspend", "stalled"].includes(e.type) && !media.paused && curTime < stopTime) setShowLoader(true);
  };

  const updateReactDom = () => {
    const [startTime, stopTime] = value;
    const trimData = {
      trimStart: startTime,
      trimEnd: stopTime,
      trimmedDuration: trimmedDurationSec,
    };
    if (fileType === VIDEO) applyVideoTrim({ widgetId, trimData, finalUpdate: true });
    if (fileType === AUDIO) applyAudioTrim({ audioId, trimData, finalUpdate: true });
    toggleModal();

    // for actual video on editor, updating current time to trim start time
    if (fileType === VIDEO) {
      document.querySelector(`#${widgetId} video`).currentTime = startTime;
      setIsVideoTrimApplied(!isVideoTrimApplied);
    }
  };

  const toggleModal = () => setShowModal(!showModal);

  useEffect(() => {
    const media = mediaRef.current;
    setValue([trimStart, trimEnd]);
    setDefaultValue([trimStart, trimEnd]);
    setTrimmedDurationSec(trimEnd - trimStart);
    setTrimmedDurationHms(getSecToHMS({ sec: trimEnd - trimStart }));

    ["canplay", "pause", "play", "ended", "waiting", "suspend", "stalled"].forEach(event => {
      media.addEventListener(event, eventHandler);
    });

    return () => {
      ["canplay", "pause", "play", "ended", "waiting", "suspend", "stalled"].forEach(event => {
        media.removeEventListener(event, eventHandler);
      });
    };
  }, []);

  return (
    <>
      <h4 className={cx(style["fw-7"], style["mb-0"])}>{modalName}</h4>
      <span className={cx(style["cross-modal"], style["rounded"])} onClick={toggleModal}>
        <Icon icon="ui-close" />
      </span>

      <div className={cx(style["d-flex"], style["align-items-center"], style["justify-content-center"])}>
        <div
          className={cx(
            style["media-preview"],
            style["my-4"],
            style["overflow-hidden"],
            style["rounded"],
            style[fileType]
          )}>
          {fileType === VIDEO && (
            <>
              <video
                ref={mediaRef}
                poster={poster}
                src={src}
                playsInline={true}
                disablePictureInPicture={true}
                preload="auto"
                style={{ height: "100%", width: "100%", display: "block", objectFit: "cover" }}
                onTimeUpdate={timeUpdateHandler}
                onLoadedMetadata={loadedMetadataHandler}></video>

              {isLoading ||
                (showLoader && (
                  <div className={cx(style["loader"])}>
                    <Spinner
                      color="light"
                      style={{
                        height: "4rem",
                        width: "4rem",
                        fontSize: "18px",
                      }}></Spinner>
                  </div>
                ))}
            </>
          )}

          {fileType === AUDIO && (
            <audio
              ref={mediaRef}
              src={src}
              preload="auto"
              onTimeUpdate={timeUpdateHandler}
              onLoadedMetadata={loadedMetadataHandler}></audio>
          )}
        </div>
      </div>

      <div className={cx(style["media-controller"], style["d-flex"], style["align-items-center"], style["mb-4"])}>
        <div
          className={cx(
            style["media-player"],
            style["d-flex"],
            { [style["mr-4"]]: fileType === VIDEO },
            { [style["mr-3"]]: fileType === AUDIO },
            { [style["disable"]]: isLoading }
          )}
          onClick={mediaPlayer}>
          <Icon
            icon={isPlaying ? "ui-pause-squared" : "ui-play-squared"}
            additionalclass={fileType === VIDEO ? "font-24" : ""}
          />
        </div>
        <div className={style["media-trimmer"]}>
          <Trimmer
            defaultValue={defaultValue}
            sliderProgress={sliderProgress}
            onChange={onChangeHandler}
            // onKeyUp={onKeyUpHandler}
            {...trimmerConfig}
          />
        </div>
        <div className={cx(style["media-timer"], style["d-flex"], style["ml-4"])}>
          <span>{trimmedDurationHms}</span>
        </div>
      </div>

      <div className={cx(style["align-items-start"], style["d-flex"])}>
        <Button type="button" color="primary" className={style["m-0"]} onClick={updateReactDom}>
          Apply
        </Button>
      </div>
    </>
  );
};

TrimModal.propTypes = {
  data: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default TrimModal;
