import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import * as constant from "../../../constants/editor";
import useAddPage from "../../../hooks/useAddPage";
import useAddBlock from "../../../hooks/useAddBlock";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { getDocumentDuration } from "../../../_helpers/utils";

let style = Object.assign({}, global);

const AddPage = props => {
  let { updateScrollEvent, pageNodes, metadata, documentType } = useContext(EditorContext);

  const [isAddDisable, setIsAddDisable] = useState(false);

  const { addPage } = useAddPage();
  const { addBlock } = useAddBlock();

  const add = () => {
    if (isAddDisable) return;

    // To prevent auto scroll
    updateScrollEvent(true);

    if (props.documentType === constant.TYPE_INFOGRAPHIC) addBlock();
    else addPage();
  };

  useEffect(() => {
    // Disable page add option if total doc duration riched
    if (pageNodes.length > 0 && documentType !== constant.TYPE_INFOGRAPHIC) {
      let getDocDurationInfo = getDocumentDuration(pageNodes, metadata.activePageIdx);
      let availableTime = getDocDurationInfo.document.available;

      if (availableTime < 1) setIsAddDisable(true);
      else setIsAddDisable(false);
    }
  }, [pageNodes.length, pageNodes[metadata.activePageIdx]?.pageDuration]);

  return (
    <li
      className={cx(style["custom-tooltip"], {
        [style["disabled"]]: isAddDisable,
      })}>
      <span onClick={add} className={style["add"]}>
        <Icon icon="ui-plus" />
      </span>
      <div className={cx(style["custom-tooltip-content"], props.pageSorterList ? [style["left"]] : [style["right"]])}>
        Add {props.pageType}
      </div>
    </li>
  );
};

//Props type validation
AddPage.propTypes = {
  documentType: PropTypes.string.isRequired,
  pageType: PropTypes.string.isRequired,
  pageSorterList: PropTypes.bool,
};

export default AddPage;
