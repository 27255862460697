import React, { useContext, useEffect, useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, TabPane } from "reactstrap";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import SliderControll from "../SliderControll";
import ColorBucket from "./ColorBucket";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { changeBucketColorAccordingBackground, hexToRGB, RGBToHex } from "../../../_helpers/utils";
import { Icon } from "../../ui/icon";
import { contextualConfig } from "../editor_config";

let style = Object.assign({}, global);

const Outline = props => {
  let { metadata, widgets, updateWidgets } = useContext(EditorContext);

  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isOutlineOn, setIsOutlineOn] = useState();
  const [outlineWidth, setOutlineWidth] = useState();
  const [outlineColor, setOutlineColor] = useState();
  const [iconBucketColor, seticonBucketColor] = useState();
  const [updateContext, setUpdateContext] = useState(false);
  const [eyedropperColor, setEyedropperColor] = useState();

  const toggle2 = () => setDropdownOpen(prevState => !prevState);

  const availableStyles = ["None", "Outline"];

  //update color if user chose color from eyedropper
  useEffect(() => {
    if (eyedropperColor) {
      setUpdateContext(true);
      setOutlineColor(eyedropperColor);
      setEyedropperColor();
    }
  }, [eyedropperColor]);

  useEffect(() => {
    let strokeValue = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style
      ?.webkitTextStroke;

    if (!strokeValue && isOutlineOn === "None") return;

    //Remove outline property
    if (isOutlineOn === "None") {
      document
        .querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner")
        .style.removeProperty("-webkit-text-stroke");
      props.setIsOutLineApplied(false);
    }
    //Add outline property
    else {
      document.querySelector(
        "#" + metadata.activeWidgetId[0] + " .dhp-widget-inner"
      ).style.webkitTextStroke = `${outlineWidth}px ${outlineColor}`;
      props.setIsOutLineApplied(true);
    }

    let newArray = Object.assign([...widgets], {
      [targetWidgetIndex]: {
        ...widgets[targetWidgetIndex],
        innerHTML: document.getElementById(targetId).innerHTML,
      },
    });

    updateContext && updateWidgets(newArray);
    setUpdateContext(false);
    outlineColor &&
      seticonBucketColor(
        changeBucketColorAccordingBackground(outlineColor !== "transparent" ? hexToRGB(outlineColor) : outlineColor)
      );
  }, [isOutlineOn, outlineWidth, outlineColor, updateContext]);

  useEffect(() => {
    let strokeValue = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style
      ?.webkitTextStroke;

    //Load Current Formatting if outline previously applied
    if (strokeValue) {
      let strokeArray = strokeValue.split(" ");
      let rgbValue =
        strokeArray[1] === "transparent"
          ? strokeArray[1]
          : RGBToHex(`${strokeArray[1]} ${strokeArray[2]} ${strokeArray[3]}`);

      setOutlineWidth(parseInt(strokeArray[0]));
      setOutlineColor(rgbValue);
      setIsOutlineOn("Outline");
    }
    //Default Formatting if outline is not applied previously
    else {
      setOutlineWidth(contextualConfig.Outline.defaultWidth);
      setOutlineColor(contextualConfig.Outline.color);
      setIsOutlineOn(contextualConfig.Outline.style);
    }
  }, []);

  return (
    <TabPane tabId={props.idx}>
      <div className={style["line-controls-wrap"]}>
        <div className={style["slidelabel"]}>Outline Style</div>
        <Dropdown isOpen={dropdownOpen} toggle={toggle2} className={style["border-style"]}>
          <DropdownToggle className={style["text-capitalize"]}>
            {isOutlineOn}
            <Icon icon="ui-arrow-down" />
          </DropdownToggle>

          <DropdownMenu className={cx(style["shadow"], style["border-0"], style["rounded"])}>
            {availableStyles.map(availableStyle => (
              <React.Fragment key={availableStyle}>
                <DropdownItem
                  className={style["text-capitalize"]}
                  tag="a"
                  onClick={() => {
                    setIsOutlineOn(availableStyle), setUpdateContext(true);
                  }}>
                  {availableStyle}
                </DropdownItem>
              </React.Fragment>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>

      {isOutlineOn !== "None" && (
        <>
          <div className={cx(style["line-controls-wrap"], style["with-input"])}>
            <SliderControll
              Slider={outlineWidth}
              setSlider={setOutlineWidth}
              sliderMin={contextualConfig.Outline.minWidth}
              sliderMax={contextualConfig.Outline.maxWidth}
              sliderLabel={"Outline Width"}
              setUpdateContext={setUpdateContext}
            />
          </div>

          <div className={cx(style["line-controls-wrap"], style["justify-content-start"])}>
            <div className={style["slidelabel"]}>Outline Color</div>
            <ColorBucket
              color={outlineColor}
              setColor={setOutlineColor}
              iconBucketColor={iconBucketColor}
              position={"toolbar"}
              setUpdateContext={setUpdateContext}
              eyedropperColor={eyedropperColor}
              setEyedropperColor={setEyedropperColor}
            />
          </div>
        </>
      )}
    </TabPane>
  );
};
Outline.propTypes = {
  dropdownOpen: PropTypes.bool,
  toggle2: PropTypes.func,
  idx: PropTypes.number,
  setIsOutLineApplied: PropTypes.func,
};

export default Outline;
