import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Table } from "reactstrap";

import global from "../../../scss/dhp.scss";
import { Icon } from "../../ui/icon";

import businessPng from "../../../assets/images/Business.png";
import businessWebp from "../../../assets/images/Business.webp";
import goodJobPng from "../../../assets/images/good-job.png";
import goodJobWebp from "../../../assets/images/good-job.webp";
import dogPng from "../../../assets/images/dog.png";
import dogWebp from "../../../assets/images/dog.webp";
import blackFridayPng from "../../../assets/images/black-friday-facebook-post-template.png";
import blackFridayWebp from "../../../assets/images/black-friday-facebook-post-template.webp";
import realEstatePng from "../../../assets/images/real-estate-facebook-post-template.png";
import realEstateWebp from "../../../assets/images/real-estate-facebook-post-template.webp";
import fathersDayPng from "../../../assets/images/fathers-day-facebook-post-template.png";
import fathersDayWebp from "../../../assets/images/fathers-day-facebook-post-template.webp";
import gymPng from "../../../assets/images/Gym.png";
import gymWebp from "../../../assets/images/Gym.webp";
import karaokePng from "../../../assets/images/karaoke.png";
import karaokeWebp from "../../../assets/images/karaoke.webp";
import cindyPng from "../../../assets/images/cindy.png";
import jamesPng from "../../../assets/images/james.png";
import amyPng from "../../../assets/images/amy.png";
import saraPng from "../../../assets/images/sara.png";
import aiPhoto1Jpg from "../../../assets/images/ai-photo1.jpg";
import aiPhoto1Webp from "../../../assets/images/ai-photo1.webp";
import aiPhoto2Jpg from "../../../assets/images/ai-photo2.jpg";
import aiPhoto2Webp from "../../../assets/images/ai-photo2.webp";
import aiPhoto3Jpg from "../../../assets/images/ai-photo3.jpg";
import aiPhoto3Webp from "../../../assets/images/ai-photo3.webp";
import aiPhoto4Jpg from "../../../assets/images/ai-photo4.jpg";
import aiPhoto4Webp from "../../../assets/images/ai-photo4.webp";
import aiTranslatePhotoPng from "../../../assets/images/ai-translate-photo.png";
import aiTranslatePhotoWebp from "../../../assets/images/ai-translate-photo.webp";
import sendSvg from "../../../assets/images/send.svg";

import topImage from "../../../assets/images/remove-background-img.jpg";
import bottomImage from "../../../assets/images/remove-background-img.png";
import Image from "../../ui/image";
import CanvasPageHtmlGenaretor from "../../Editor/CanvasPageHtmlGenaretor";
import { COMMENT_MODAL_THUMB_WIDTH, VERSION_MODAL_THUMB_WIDTH, EMAIL_MODAL_THUMB_WIDTH } from "../../../constants/env";
import MultiColorList from "../../ui/MultiColorList";
import { COLLAGE } from "../../../constants/editor";

let style = Object.assign({}, global);

export const GenericSection = () => {
  return (
    <div className={style["generic"]}>
      <ul>
        <li className={style["first"]}>
          <span className={style["image-holder"]}>
            {/* <img src={businessPng} className={style["img-fluid"]} alt="Upgrade" /> */}
            <Image WebpImage={businessWebp} PngImage={businessPng} />
          </span>
        </li>
        <li className={style["secend"]}>
          <span className={style["image-holder"]}>
            {/* <img src={goodJobPng} className={style["img-fluid"]} alt="Upgrade" /> */}
            <Image WebpImage={goodJobWebp} PngImage={goodJobPng} />
          </span>
          <span className={style["image-holder"]}>
            {/* <img src={dogPng} className={style["img-fluid"]} alt="Upgrade" /> */}
            <Image WebpImage={dogWebp} PngImage={dogPng} />
          </span>
        </li>
      </ul>
    </div>
  );
};

export const CompanyTemplateSection = ({ imageUrl }) => {
  return (
    <figure className={cx(style["img-border"], style["premium"])}>
      <img src={imageUrl} className={style["img-fluid"]} alt="Upgrade" />
    </figure>
  );
};

CompanyTemplateSection.propTypes = {
  imageUrl: PropTypes.string.isRequired,
};

export const PremiumTemplateSection = ({ imageUrl }) => {
  return (
    <figure className={cx(style["img-border"], style["premium"])}>
      <img src={imageUrl} className={style["img-fluid"]} alt="Upgrade" />
    </figure>
  );
};

PremiumTemplateSection.propTypes = {
  imageUrl: PropTypes.string.isRequired,
};

export const UnlimitedDocsSection = () => {
  return (
    <div className={cx(style["generic"], style["unlimited-docs"])}>
      <ul>
        <li className={style["secend"]}>
          <span className={style["image-holder"]}>
            {/* <img src={blackFridayPng} className={style["img-fluid"]} alt="Upgrade" /> */}
            <Image WebpImage={blackFridayWebp} PngImage={blackFridayPng} />
          </span>
          <span className={style["image-holder"]}>
            {/* <img src={realEstatePng} className={style["img-fluid"]} alt="Upgrade" /> */}
            <Image WebpImage={realEstateWebp} PngImage={realEstatePng} />
          </span>
          <span className={style["image-holder"]}>
            {/* <img src={fathersDayPng} className={style["img-fluid"]} alt="Upgrade" /> */}
            <Image WebpImage={fathersDayWebp} PngImage={fathersDayPng} />
          </span>
        </li>
        <li className={style["first"]}>
          <span className={style["image-holder"]}>
            {/* <img src={gymPng} className={style["img-fluid"]} alt="Upgrade" /> */}
            <Image WebpImage={gymWebp} PngImage={gymPng} />
          </span>
        </li>
      </ul>
    </div>
  );
};

export const RemoveBGSection = () => {
  const [isResizing, setIsResizing] = useState(false);
  const topImageRef = useRef();
  const handleRef = useRef();

  const clickHandler = {
    meta: false,
    start: () => {
      clickHandler.meta = { isClicked: true };
      document.addEventListener("mouseup", clickHandler.end);
    },
    end: e => {
      if (clickHandler.meta.isClicked) {
        setPositioning(e.clientX);
        document.removeEventListener("mouseup", clickHandler.end);
        clickHandler.meta = false;
      }
    },
  };

  const setPositioning = useCallback(x => {
    const { left, width } = topImageRef.current.getBoundingClientRect();
    const handleWidth = handleRef.current.offsetWidth;

    if (x >= left && x <= width + left - handleWidth) {
      handleRef.current.style.left = `${((x - left) / width) * 100}%`;
      topImageRef.current.style.clipPath = `inset(0 ${100 - ((x - left) / width) * 100}% 0 0)`;
    }
  }, []);

  const handleResize = useCallback(
    e => {
      if (e.clientX) setPositioning(e.clientX);
    },
    [setPositioning]
  );

  // Set initial positioning on component mount
  useEffect(() => {
    const { left, width } = topImageRef.current.getBoundingClientRect();
    const handleWidth = handleRef.current.offsetWidth;

    setPositioning(width / 2 + left - handleWidth / 2);
  }, [setPositioning]);

  const handleResizeEnd = useCallback(() => {
    setIsResizing(false);

    window.removeEventListener("mousemove", handleResize);
    window.removeEventListener("mouseup", handleResizeEnd);
  }, [handleResize]);

  useEffect(() => {
    if (isResizing) {
      window.addEventListener("mousemove", handleResize);
      window.addEventListener("mouseup", handleResizeEnd);
    }

    return () => {
      window.removeEventListener("mousemove", handleResize);
      window.removeEventListener("mouseup", handleResizeEnd);
    };
  }, [isResizing]);

  return (
    <>
      <div className={style["comparison-slider"]} onMouseDown={() => clickHandler.start()}>
        <div
          ref={handleRef}
          className={style["handle"]}
          onMouseDown={() => setIsResizing(true)}
          onTouchStart={() => setIsResizing(true)}>
          <div className={style["compare"]}>
            <Icon icon="ui-arrow-left-right" />
          </div>
        </div>
        <div ref={topImageRef} className={cx(style["comparison-item"], style["top"])}>
          <img draggable="false" src={topImage} />
        </div>
        <div className={style["comparison-item"]}>
          <img draggable="false" src={bottomImage} />
        </div>
      </div>
    </>
  );
};

export const UserAddSection = () => {
  return (
    <div className={style["add-user"]}>
      <figure className={style["img-border"]}>
        {/* <img src={karaokePng} className={style["img-fluid"]} alt="Upgrade" /> */}
        <Image WebpImage={karaokeWebp} PngImage={karaokePng} />
      </figure>
      <div className={style["user-list"]}>
        <ul>
          <li>
            <img src={cindyPng} className={cx(style["img-fluid"], style["mr-3"])} alt="Upgrade" />
            <span className={cx(style["color-black"], style["fw-6"])}>Cindy Lewis</span>
            <span className={cx(style["text-muted"], style["ml-auto"], style["font-15"])}>Read Only</span>
          </li>
          <li>
            <img src={jamesPng} className={cx(style["img-fluid"], style["mr-3"])} alt="Upgrade" />
            <span className={cx(style["color-black"], style["fw-6"])}>James Pate</span>
            <span className={cx(style["text-muted"], style["ml-auto"], style["font-15"])}>Read Write</span>
          </li>
          <li>
            <img src={amyPng} className={cx(style["img-fluid"], style["mr-3"])} alt="Upgrade" />
            <span className={cx(style["color-black"], style["fw-6"])}>Amy M</span>
            <span className={cx(style["text-muted"], style["ml-auto"], style["font-15"])}>Full Access</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export const VersionHistorySection = ({ imageUrl: metaInfo }) => {
  return (
    <div className={style["doc-version"]}>
      <div className={style["version-layer"]}>
        {metaInfo?.docPagesMeta?.pageNodes[0] && (
          <CanvasPageHtmlGenaretor
            figureWidth={VERSION_MODAL_THUMB_WIDTH}
            pageNode={metaInfo?.docPagesMeta?.pageNodes[0]}
            docPagesMeta={metaInfo?.docPagesMeta}
            className={style["img-border"]}
          />
        )}
      </div>
      <div className={style["version-option"]}>
        <ul>
          <li>
            <Icon icon="ui-rename" /> Name this Version
          </li>
          <li>
            <Icon icon="ui-restore" /> Restore Version
          </li>
          <li>
            <Icon icon="eye2" /> Preview
          </li>
        </ul>
      </div>
    </div>
  );
};

VersionHistorySection.propTypes = {
  imageUrl: PropTypes.string.isRequired,
};

export const AuditLogSection = () => {
  return (
    <div className={style["demo-audit"]}>
      <Table borderless className={cx(style["default-bg"], style["bg-white"])}>
        <thead>
          <tr>
            <th scope="col">Done By</th>
            <th scope="col">Action</th>
            <th scope="col">Record</th>
            <th scope="col">IP</th>
            <th scope="col">Time</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">
              <img src={cindyPng} className={style["img-fluid"]} alt="Upgrade" />
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
          </tr>
          <tr>
            <td scope="row">
              <img src={jamesPng} className={style["img-fluid"]} alt="Upgrade" />
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
          </tr>
          <tr>
            <td scope="row">
              <img src={amyPng} className={style["img-fluid"]} alt="Upgrade" />
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export const DownloadQualitySection = () => {
  return (
    <div className={style["type-quality"]}>
      <p className={style["fw-7"]}>File Type</p>
      <ul className={cx(style["type"], style["mb-4"])}>
        <li>
          <Icon icon="ui-image" /> <p className={cx(style["mb-0"], style["mt-1"], style["fw-6"])}>PNG</p>
        </li>
        <li>
          <Icon icon="ui-pdf" /> <p className={cx(style["mb-0"], style["mt-1"], style["fw-6"])}>PDF</p>
        </li>
        <li>
          <Icon icon="ui-mp4" /> <p className={cx(style["mb-0"], style["mt-1"], style["fw-6"])}>MP4</p>
        </li>
        <li>
          <Icon icon="ui-gif" /> <p className={cx(style["mb-0"], style["mt-1"], style["fw-6"])}>GIF</p>
        </li>
        <li>
          <Icon icon="ui-more" />
        </li>
      </ul>
      <p className="fw-7">Quality</p>
      <ul className="quality">
        <li>
          <Icon icon="ui-tick-box" /> FOR STANDARD SCREEN: 72 DPI
        </li>
        <li>
          <Icon icon="ui-tick-box" /> FOR RETINA SCREEN: 150 DPI
        </li>
        <li>
          <Icon icon="ui-tick-box" /> FOR PRINT: 300 DPI
        </li>
      </ul>
    </div>
  );
};

export const PremiumAssetSection = ({ imageUrl, assetType }) => {
  return (
    <div className={cx(style["premium-asset-item"], { [style["collage-item"]]: assetType === COLLAGE })}>
      <figure>
        <img src={imageUrl} className={style["img-fluid"]} alt="Upgrade" />
      </figure>
    </div>
  );
};

PremiumAssetSection.propTypes = {
  imageUrl: PropTypes.string.isRequired,
};

export const PremiumThemesSection = ({ imageUrl: metaInfo }) => {
  return (
    <div className={style["doc-theme"]}>
      <div className={cx(style["img-border"], style["mr-0"])}>
        {metaInfo?.docPagesMeta?.pageNodes[0] && (
          <CanvasPageHtmlGenaretor
            figureWidth={COMMENT_MODAL_THUMB_WIDTH}
            pageNode={metaInfo?.docPagesMeta?.pageNodes[0]}
            docPagesMeta={metaInfo?.docPagesMeta}
          />
        )}
      </div>
      <div className={style["demo-theme"]}>
        <MultiColorList colors={metaInfo?.colors || []} />
      </div>
    </div>
  );
};

PremiumThemesSection.propTypes = {
  imageUrl: PropTypes.string.isRequired,
};

export const DocumentCmntSection = ({ imageUrl: metaInfo }) => {
  return (
    <>
      <div className={style["doc-comment"]}>
        <div className={style["img-border"]}>
          {metaInfo?.docPagesMeta?.pageNodes[0] && (
            <CanvasPageHtmlGenaretor
              figureWidth={COMMENT_MODAL_THUMB_WIDTH}
              pageNode={metaInfo?.docPagesMeta?.pageNodes[0]}
              docPagesMeta={metaInfo?.docPagesMeta}
            />
          )}
        </div>
        <div className={style["demo-comment"]}>
          <div className={style["w-25"]}>
            <img src={saraPng} className={style["img-fluid"]} width="30" height="30" alt="" />
          </div>
          <div className={style["w-100"]}>
            <h4 className={style["font-18"]}>Sara Williams</h4>
            <p className={style["mb-2"]}>
              Excellent job! <Icon icon="heart1" additionalclass="color-red2 font-base" />
            </p>
            <p className={cx(style["m-0"], style["d-flex"], style["justify-content-between"], style["font-14"])}>
              <span className={style["text-muted"]}>Aug 14, 2026</span>
              <span className={style["color-black"]}>&nbsp; Reply &nbsp; Like</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

DocumentCmntSection.propTypes = {
  imageUrl: PropTypes.object.isRequired,
};

export const AIImageSection = () => {
  return (
    <div className={cx(style["demo-audit"], style["ai-upgrade"])}>
      <div
        className={cx(
          style["px-4"],
          style["py-3"],
          style["mb-4"],
          style["bg-white"],
          style["rounded-sm"],
          style["font-15"],
          style["shadow-lg"]
        )}>
        A lion sitting in the middle of a desert on a sunny afternoon
      </div>

      <ul className={cx(style["row"], style["mx-n2"])}>
        <li className={cx(style["col-6"], style["mb-3"], style["px-2"])}>
          <div className={cx(style["img-border"], style["ai-img"])}>
            <Image WebpImage={aiPhoto1Webp} PngImage={aiPhoto1Jpg} />
          </div>
        </li>
        <li className={cx(style["col-6"], style["mb-3"], style["px-2"])}>
          <div className={cx(style["img-border"], style["ai-img"])}>
            <Image WebpImage={aiPhoto2Webp} PngImage={aiPhoto2Jpg} />
          </div>
        </li>
        <li className={cx(style["col-6"], style["px-2"])}>
          <div className={cx(style["img-border"], style["ai-img"])}>
            <Image WebpImage={aiPhoto3Webp} PngImage={aiPhoto3Jpg} />
          </div>
        </li>
        <li className={cx(style["col-6"], style["px-2"])}>
          <div className={cx(style["img-border"], style["ai-img"])}>
            <Image WebpImage={aiPhoto4Webp} PngImage={aiPhoto4Jpg} />
          </div>
        </li>
      </ul>
    </div>
  );
};

export const AIWriterSection = () => {
  return (
    <div className={cx(style["demo-audit"], style["ai-writer-upgrade"])}>
      <div
        className={cx(
          style["p-3"],
          style["mb-4"],
          style["bg-white"],
          style["rounded-sm"],
          style["font-15"],
          style["shadow-sm"]
        )}>
        List five benefits of usage of green energy
      </div>
      <div
        className={cx(
          style["px-3"],
          style["py-4"],
          style["font-15"],
          style["bg-white"],
          style["rounded-sm"],
          style["shadow-sm"]
        )}>
        <ul className={cx(style["list-style-number"])}>
          <li className={cx(style["col-12"], style["font-15"], style["px-2"], style["mb-2"])}>
            Reduced carbon emissions and air pollution
          </li>
          <li className={cx(style["col-12"], style["font-15"], style["px-2"], style["mb-2"])}>
            Increased energy security and independence
          </li>
          <li className={cx(style["col-12"], style["font-15"], style["px-2"], style["mb-2"])}>
            Lower operating costs in the long run
          </li>
          <li className={cx(style["col-12"], style["font-15"], style["px-2"], style["mb-2"])}>
            Job creation and economic growth in the green energy sector
          </li>
          <li className={cx(style["col-12"], style["font-15"], style["px-2"])}>
            Improved public health and well being by reducing reliance on fossil fuels
          </li>
        </ul>
      </div>
    </div>
  );
};

export const publishDownloadSection = ({ imageUrl: metaInfo }) => {
  return (
    <>
      <div className={style["flex-column"]}>
        <div
          className={cx(
            style["demo-comment"],
            style["d-flex"],
            style["justify-content-end"],
            style["align-items-center"],
            style["mb-2"],
            style["fw-6"]
          )}>
          <span className={cx(style["bg-white"], style["p-2"], style["d-flex"], style["rounded-xs"], style["mr-3"])}>
            <Icon icon="ui-download" />
          </span>
          Download
        </div>
        <span className={cx(style["download-thumb"], style["img-border"], style["d-flex"])}>
          {metaInfo?.docPagesMeta?.pageNodes && (
            <CanvasPageHtmlGenaretor
              figureWidth={COMMENT_MODAL_THUMB_WIDTH}
              pageNode={metaInfo?.docPagesMeta?.pageNodes[0]}
              docPagesMeta={metaInfo?.docPagesMeta}
            />
          )}
          {!metaInfo?.docPagesMeta?.pageNodes && (
            <figure>
              <img src={metaInfo?.docPagesMeta?.thumb} className={style["img-fluid"]} alt="Upgrade" />
            </figure>
          )}
        </span>
      </div>
    </>
  );
};

publishDownloadSection.propTypes = {
  imageUrl: PropTypes.object.isRequired,
};

export const publishPasswordSection = () => {
  return (
    <>
      <div className={cx(style["set-password"])}>
        <h5 className={cx(style["fw-7"], style["mb-3"])}>Enter password</h5>
        <p className="">Enter password in order to view this document.</p>
        <p className={cx(style["fw-6"], style["mb-2"])}>Password</p>
        <div className={style["input-box"]}>******</div>
      </div>
    </>
  );
};

export const AITranslateSection = () => {
  console.log({ aiTranslatePhotoWebp, aiPhoto3Webp });
  return (
    <div className={style["premium-asset-item"]}>
      <Image WebpImage={aiTranslatePhotoWebp} PngImage={aiTranslatePhotoPng} />
    </div>
  );
};

export const emailDocumentLinkSection = ({ imageUrl: metaInfo }) => {
  return (
    <>
      <div className={cx(style["doc-comment"], style["send-email"])}>
        <div className={cx(style["img-border"], style["m-auto"])}>
          {metaInfo?.docPagesMeta?.pageNodes[0] && (
            <CanvasPageHtmlGenaretor
              figureWidth={EMAIL_MODAL_THUMB_WIDTH}
              pageNode={metaInfo?.docPagesMeta?.pageNodes[0]}
              docPagesMeta={metaInfo?.docPagesMeta}
            />
          )}
        </div>
        <div className={style["demo-comment"]}>
          <img src={sendSvg} className={cx(style["img-fluid"])} alt="send" />
        </div>
      </div>
    </>
  );
};

emailDocumentLinkSection.propTypes = {
  imageUrl: PropTypes.object.isRequired,
};

export const publishBadgeSection = ({ imageUrl: metaInfo }) => {
  return (
    <>
      <div className={style["flex-column"]}>
        <span className={cx(style["download-thumb"], style["img-border"], style["d-flex"])}>
          {metaInfo?.docPagesMeta?.pageNodes && (
            <CanvasPageHtmlGenaretor
              figureWidth={COMMENT_MODAL_THUMB_WIDTH}
              pageNode={metaInfo?.docPagesMeta?.pageNodes[0]}
              docPagesMeta={metaInfo?.docPagesMeta}
            />
          )}
          {!metaInfo?.docPagesMeta?.pageNodes && (
            <figure>
              <img src={metaInfo?.docPagesMeta?.thumb} className={style["img-fluid"]} alt="Upgrade" />
            </figure>
          )}
        </span>
      </div>
      <div className={cx(style["badge-footer"], style["py-3"], style["text-center"])}>
            <div>
                Made in{" "}
                <span className={style["logo-text"]}>
                  <span className={style["logo-red"]}>D</span>
                  <span className={style["logo-yellow"]}>o</span>
                  <span className={style["logo-green"]}>c</span>Hipo
                </span>
            </div>
      </div>
    </>
  );
};

publishBadgeSection.propTypes = {
  imageUrl: PropTypes.object.isRequired,
};

export const publishEmailVerificationSection = () => {
  return (
    <>
      <div className={cx(style["set-password"])}>
        <h5 className={cx(style["fw-7"], style["mb-3"])}>Verify your email</h5>
        <p className="">Enter your email to receive a verification code.</p>
        <p className={cx(style["fw-6"], style["mb-2"])}>Email</p>
        <div className={style["input-box"]}>john@email.com</div>
      </div>
    </>
  );
};
