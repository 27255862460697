import { useContext } from 'react'
import { EditorContext } from '../containers/editor/EditorLayout';
import useFind from './useFind';

const useFindHighlighter = () => {
    const { showFindAndReplace, dimension } = useContext(EditorContext);
    const { adjustHeighlighter } = useFind({ state: { ...showFindAndReplace?.curState } });
    const updateFindHighlighter = (zoom = dimension.zoom) => {
        if (!showFindAndReplace.isActive || !adjustHeighlighter) return
        if (showFindAndReplace?.curState?.widgetRef) adjustHeighlighter(showFindAndReplace.curState.widgetRef, zoom)
    }
    return updateFindHighlighter
}
export default useFindHighlighter