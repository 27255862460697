import { VALID_NAME_PATTERN } from "../../constants/user";

export const sidebarOptions = {
  title: "Company Settings",
  group: [
    {
      name: "General",
      icon_name: "general-company",
      items: [
        {
          name: "Company Details",
          icon_name: "company",
          link: "/companies/settings/overview",
          children: ["/companies/settings/update"],
        },
      ],
    },
    {
      name: "Users",
      icon_name: "team",
      items: [
        {
          name: "Manage Users",
          icon_name: "share",
          link: "/companies/settings/members",
          children: ["/companies/settings/members/invite"],
        },
      ],
    },
    {
      name: "Brand and Appearance",
      icon_name: "paint-palette",
      items: [
        {
          name: "Custom Fonts",
          icon_name: "custom-fonts",
          link: "/companies/settings/fonts",
          children: ["/companies/settings/fonts/update"],
        },
        {
          // access: "superadmin_company",
          name: "Brands",
          icon_name: "brand2",
          link: "/companies/settings/brands",
          children: ["/companies/settings/brands/"],
        },
        {
          name: "DocHipo Badge",
          icon_name: "badge",
          link: "/companies/settings/badge",
          children: ["/companies/settings/badge/"],
        },
      ],
    },
    {
      name: "Billing",
      icon_name: "billing",
      items: [
        {
          name: "Billing Plan",
          icon_name: "subscription",
          link: "/companies/settings/subscriptions/dashboard",
          children: ["/companies/settings/subscriptions/redeemcode"],
        },
        {
          name: "Invoices",
          icon_name: "invoices",
          link: "/companies/settings/subscriptions/invoice",
          children: [],
        },
      ],
    },
  ],
  subscription: false,
  subscription_url: "/companies/settings/subscriptions/dashboard",
};

export const detailsPageData = {
  title: "Company Details",
  edit_link: "/companies/settings/update",
  profile_image: false,
  enable_edit: true,
  isMoreOtion: true,
  card_data: [
    {
      ref_slug: "phone",
    },
    {
      ref_slug: "super_admin",
    },
    {
      ref_slug: "time_zone",
    },
    {
      ref_slug: "date_format",
    },
    {
      ref_slug: "time_format",
    },
  ],
  field_groups: [
    {
      name: "Company Information",
      slug: "company_information",
      fields: [
        {
          name: "Company Name",
          slug: "name",
          type: "text",
          required: true,
          min_length: 1,
          max_length: 255,
        },
        {
          name: "Alias",
          slug: "alias",
          type: "text",
          min_length: 1,
          max_length: 255,
        },
        {
          name: "Phone",
          slug: "phone",
          type: "phone",
          min_length: 1,
          max_length: 255,
        },
        {
          name: "Fax",
          slug: "fax",
          type: "phone",
          min_length: 1,
          max_length: 255,
        },
        {
          name: "Website",
          slug: "website",
          type: "url",
          min_length: 1,
          max_length: 255,
        },
        {
          name: "Super Admin",
          slug: "super_admin",
          type: "text",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      name: "Address Information",
      slug: "address_information",
      fields: [
        {
          name: "Address Line 1",
          slug: "street",
          type: "text",
          min_length: 1,
          max_length: 255,
        },
        {
          name: "Address Line 2",
          slug: "addressLine2",
          type: "text",
          min_length: 1,
          max_length: 255,
        },
        {
          name: "Country",
          slug: "country_code",
          type: "dropdown",
          datasource: {
            component: "countries",
          },
          required: true,
        },
        {
          name: "State/Province",
          slug: "state",
          type: "dropdown",
          datasource: {
            depends_on: "country_code",
            component: "states",
          },
        },
        {
          name: "City",
          slug: "city",
          type: "text",
          min_length: 1,
          max_length: 255,
        },
        {
          name: "Zip/Postal Code",
          slug: "zip",
          type: "text",
          min_length: 1,
          max_length: 10,
        },
      ],
    },
    {
      name: "Locale Information",
      slug: "locale_information",
      fields: [
        {
          name: "Time Zone",
          slug: "time_zone",
          type: "dropdown",
          datasource: {
            component: "timezones",
          },
        },
        {
          name: "Date Format",
          slug: "date_format",
          type: "dropdown",
          datasource: {
            component: "dateformats",
          },
        },
        {
          name: "Time Format",
          slug: "time_format",
          type: "dropdown",
          datasource: {
            component: "timeformats",
          },
        },
      ],
    },
  ],
};

export const userStatusList = {
  JOINED: "active",
  DEACTIVATED: "deactivated",
  INVITED: "unconfirmed",
};

export const addUserPageData = {
  field_groups: [
    {
      name: "User Information",
      slug: "user_information",
      fields: [
        {
          name: "First Name",
          slug: "firstname",
          type: "text",
          required: true,
          min_length: 1,
          max_length: 255,
          pattern: VALID_NAME_PATTERN,
        },
        {
          name: "Middle Name",
          slug: "middlename",
          type: "text",
          min_length: 1,
          max_length: 255,
          layout: "QUARTER",
          pattern: VALID_NAME_PATTERN,
        },
        {
          name: "Last Name",
          slug: "lastname",
          type: "text",
          min_length: 1,
          max_length: 255,
          required: true,
          layout: "QUARTER",
          pattern: VALID_NAME_PATTERN,
        },
        {
          name: "Email",
          slug: "email",
          type: "email",
          required: true,
        },
      ],
    },
  ],
};

export const usersPageData = {
  title: "User",
  isMoreOtion: true,
  profile_image: true,
  edit_profile_image: false,
  card_data: [
    {
      ref_slug: "firstname",
    },
    {
      ref_slug: "lastname",
    },
    {
      ref_slug: "phone",
    },
    {
      ref_slug: "email",
    },
    {
      ref_slug: "mobile",
    },
  ],
  field_groups: [
    {
      name: "User Information",
      slug: "user_information",
      fields: [
        {
          name: "Name",
          slug: "name",
          type: "name_field",
          sub_fields: ["firstname", "middlename", "lastname"],
        },
        {
          name: "First Name",
          slug: "firstname",
          type: "text",
          required: true,
          min_length: 1,
          max_length: 255,
        },
        {
          name: "Middle Name",
          slug: "middlename",
          type: "text",
          min_length: 1,
          max_length: 255,
          layout: "QUARTER",
        },
        {
          name: "Last Name",
          slug: "lastname",
          type: "text",
          min_length: 1,
          max_length: 255,
          required: true,
          layout: "QUARTER",
        },
        {
          name: "Email",
          slug: "email",
          type: "email",
          required: true,
        },
        {
          name: "Mobile",
          slug: "mobile",
          type: "phone",
          min_length: 1,
          max_length: 255,
        },
        {
          name: "Phone",
          slug: "phone",
          type: "phone",
          min_length: 1,
          max_length: 255,
        },
      ],
    },
    {
      name: "Address Information",
      slug: "address_information",
      fields: [
        {
          name: "Address Line 1",
          slug: "street",
          type: "text",
          min_length: 1,
          max_length: 255,
        },
        {
          name: "Address Line 2",
          slug: "addressLine2",
          type: "text",
          min_length: 1,
          max_length: 255,
        },
        {
          name: "Country",
          slug: "country_code",
          type: "dropdown",
          datasource: {
            component: "countries",
          },
          required: true,
        },
        {
          name: "State/Province",
          slug: "state",
          type: "dropdown",
          datasource: {
            depends_on: "country_code",
            component: "states",
          },
        },
        {
          name: "City",
          slug: "city",
          type: "text",
          min_length: 1,
          max_length: 255,
        },
        {
          name: "Zip/Postal Code",
          slug: "zip",
          type: "text",
          min_length: 1,
          max_length: 10,
        },
      ],
    },
    {
      name: "Locale Information",
      slug: "locale_information",
      fields: [
        {
          name: "Time Zone",
          slug: "time_zone",
          type: "dropdown",
          datasource: {
            component: "timezones",
          },
        },
        {
          name: "Date Format",
          slug: "date_format",
          type: "dropdown",
          datasource: {
            component: "dateformats",
          },
        },
        {
          name: "Time Format",
          slug: "time_format",
          type: "dropdown",
          datasource: {
            component: "timeformats",
          },
        },
      ],
    },
  ],
};

export const companyBrandFields = {
  field_groups: [
    {
      name: null,
      slug: null,
      display: true,
      fields: [
        {
          name: "Heading",
          slug: "name",
          type: "text_view",
          layout: "FULL",
        },
      ],
    },
    {
      name: "Brand Colors",
      slug: "brand_colors",
      add_new: true,
      max_row: 3,
      display: true,
      delete_row: true,
      fields: [
        {
          name: "Primary",
          slug: "primary_color",
          type: "colorpalette",
          next_row: "secondary_color",
          display: true,
          layout: "FULL",
          disable_delete: false,
        },
        {
          name: "Secondary",
          slug: "secondary_color",
          type: "colorpalette",
          previous_row: "primary_color",
          next_row: "other_color",
          display: false,
          layout: "FULL",
          disable_delete: false,
        },
        {
          name: "Others",
          slug: "other_color",
          type: "colorpalette",
          previous_row: "secondary_color",
          display: false,
          layout: "FULL",
          disable_delete: false,
        },
      ],
    },
    {
      name: "Brand Fonts",
      slug: "brand_fonts",
      display: true,
      fields: [
        {
          name: "Heading",
          slug: "heading",
          type: "font",
          value: {
            font_family: "Open Sans",
            font_size: 32,
            bold: false,
            italic: false,
          },
        },
        {
          name: "Subheading",
          slug: "subheading",
          type: "font",
          value: {
            font_family: "Open Sans",
            font_size: 24,
            bold: false,
            italic: false,
          },
        },
        {
          name: "Body Text",
          slug: "body_text",
          type: "font",
          value: {
            font_family: "Open Sans",
            font_size: 14,
            bold: false,
            italic: false,
          },
        },
      ],
    },
    {
      name: "Brand Logos",
      slug: "brand_logos",
      display: true,
      infomsg: `JPG, PNG, GIF, SVG. <br/> File Size: Maximum 5 MB`,
      fields: [
        {
          name: "logo",
          slug: "logos",
          type: "image",
          layout: "FULL",
          maxSize: 5,
          acceptFileType: "image/jpeg, image/png, image/gif, image/svg+xml",
        },
      ],
    },
    {
      name: "Brand Images",
      slug: "brand_images",
      display: true,
      infomsg: "JPG, PNG. <br/> File Size: Maximum 5 MB",
      fields: [
        {
          name: "image",
          slug: "images",
          type: "image",
          maxSize: 5,
          acceptFileType: "image/jpeg, image/png",
          layout: "FULL",
        },
      ],
    },
    {
      name: "Brand Videos",
      slug: "brand_videos",
      display: true,
      infomsg: "MP4, MOV, WebM. <br/> File Size: Maximum 100 MB",
      fields: [
        {
          name: "video",
          slug: "videos",
          type: "video",
          maxSize: 100,
          acceptFileType: "video/mp4, video/quicktime, .mov, video/webm",
          layout: "FULL",
        },
      ],
    },
  ],
};
