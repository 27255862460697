import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const ContentEditable = ({ onBlur, onKeyDown, value, className }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  return (
    <span
      contentEditable={true}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      suppressContentEditableWarning={true}
      ref={inputRef}
      className={className}>
      {value}
    </span>
  );
};

export default ContentEditable;

ContentEditable.propTypes = {
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
};
