import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { contextualConfig } from "../editor_config";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { TABLE } from "../../../constants/editor";

let style = Object.assign({}, global);

const TextAlign = ({ defaultFontAlign, setFontAlign, sourceComponent }) => {
  let { metadata, widgets, updateWidgets } = useContext(EditorContext);

  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);

  const [allign, setAllign] = useState();
  const [updateContext, setUpdateContext] = useState(false);

  //Load current formatting text
  useEffect(() => {
    if (metadata.activeWidgetId && sourceComponent !== TABLE) {
      let innerHtmlflag = document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`);
      setAllign(innerHtmlflag.style.textAlign);
    }
  }, [metadata.activeWidgetId]);

  //update when allignment value change
  useEffect(() => {
    if (setFontAlign) setFontAlign(allign);
    else {
      document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style.textAlign = allign;

      let newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgets[targetWidgetIndex],
          innerHTML: document.getElementById(targetId).innerHTML,
        },
      });

      updateContext && updateWidgets(newArray);
      setUpdateContext(false);
    }
  }, [allign]);

  // Load current formatting for table
  useEffect(() => {
    if (defaultFontAlign) setAllign(defaultFontAlign);
  }, [defaultFontAlign]);

  return (
    <li className={cx(style["toolset-group"], style["active"])}>
      <div className={cx(style["toolset-item"], style["align-wrap"])}>
        <span className={cx(style["toolset-action"], style["custom-tooltip"])}>
          <Icon icon="ui-align-center" />
          <div className={cx(style["custom-tooltip-content"], style["top"])}>Text Align</div>
        </span>

        <ul className={cx(style["toolbar-dropdown"], style["four-item"], style["rounded"])}>
          {contextualConfig.TextAllign.map(list => (
            <React.Fragment key={list}>
              <li
                className={cx(style["custom-tooltip"], {
                  [style["active"]]: list === allign,
                })}
                onClick={() => {
                  setAllign(list), setUpdateContext(true);
                }}>
                <span className={style["toolset-action"]}>
                  <Icon icon={`ui-align-${list}`} />
                </span>
                <div className={cx(style["custom-tooltip-content"], style["text-capitalize"])}>{list}</div>
              </li>
            </React.Fragment>
          ))}
        </ul>
      </div>
    </li>
  );
};

TextAlign.propTypes = {
  defaultFontAlign: PropTypes.string,
  setFontAlign: PropTypes.func,
  sourceComponent: PropTypes.string,
};

export default TextAlign;
