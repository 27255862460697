import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import loadable from "@loadable/component";
import { PrivateRoute } from "./_helpers";
import { AppContext } from "./contexts";
import useWindowSize from "./hooks/useWindowSize";
import BrowserScreenSize from "./containers/layouts/BrowserScreenSize";
import ScrollToTop from "./components/ui/scrollToTop";
import { useConnectSocket } from "./hooks/useSocket";
import useAcceptSocketEmit from "./hooks/useAcceptSocketEmit";
import { useDispatch, useSelector } from "react-redux";
import { getResourceEntries } from "./store/actions/resourcesActions";
import { decodeFromBase64 } from "./_helpers/utils";
import Play from "./components/Common/Play";

const SignupFinalStep = loadable(() => import("./components/public/SignupFinalStep"));
const DefaultLayout = loadable(() => import("./containers/layouts/DefaultLayout"));
const Login = loadable(() => import("./components/public/Login"));
const Signup = loadable(() => import("./components/public/Signup"));
const ProOnceCheckOut = loadable(() => import("./components/public/ProOnceCheckOut"));
const ForgotPassword = loadable(() => import("./components/public/ForgotPassword"));
const ResetPassword = loadable(() => import("./components/public/ResetPassword"));
const SignupVerification = loadable(() => import("./components/public/SignupVerification"));
const GettingReady = loadable(() => import("./components/public/GettingReady"));
const Present = loadable(() => import("./components/Common/Present"));
const Preview = loadable(() => import("./components/Common/Preview"));
const AcceptInvitation = loadable(() => import("./components/public/AcceptInvitation"));
const NotFound = loadable(() => import("./components/public/NotFound"));
const TwoStepAuthenticate = loadable(() => import("./components/public/TwoStepAuthenticate"));
const TwoStepAuthenticateBackup = loadable(() => import("./components/public/TwoStepAuthenticateBackup"));
const DeleteCompany = loadable(() => import("./components/public/DeleteCompany"));
const AuthenticatePreview = loadable(() => import("./components/public/AuthenticatePreview"));
const AllTemplates = loadable(() => import("./components/Template/AllTemplates"));
const TemplateCategory = loadable(() => import("./components/Template/TemplateCategory"));

function App() {
  const dispatch = useDispatch();
  const { width: screenWidth } = useWindowSize();
  const [flashMessage, setFlashMessage] = useState();
  const [activeCompany, setActiveCompany] = useState();
  const [upgradeModalData, setUpgradeModalData] = useState({});
  const [upgradeModalStatus, setUpgradeModalStatus] = useState(false);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isStopSaveProgress, setIsStopSaveProgress] = useState(false);
  const [socketConnectionInfo, setSocketConnectionInfo] = useState();
  const [pasteColorActive, setPasteColorActive] = useState();
  const [isEyedropperActive, setIsEyedropperActive] = useState();
  const [documentWS, setDocumentWS] = useState();
  const [restartSave, setRestartSave] = useState(false);
  const [brandInfo, setBrandInfo] = useState();
  const { loggedIn } = useSelector(state => state?.auth);

  const newStorageKey = `${process.env.NEW_STORAGE_KEY}`;
  const userStorageKey = localStorage.getItem("dh-storage-key");

  useEffect(() => {
    if (userStorageKey !== newStorageKey) {
      const newStorageKeyDecoded = decodeFromBase64(newStorageKey);

      newStorageKeyDecoded?.resources.forEach(type => {
        dispatch(getResourceEntries(type));
      });
      localStorage.setItem("dh-storage-key", newStorageKey);
    }
  }, []);

  useConnectSocket(setSocketConnectionInfo, setDocumentWS, setRestartSave); // Establish the Gloabal Socket connection

  useAcceptSocketEmit(socketConnectionInfo, setIsSaveInProgress, documentWS);

  useEffect(() => {
    if (!loggedIn) setActiveCompany({});
  }, [loggedIn]);

  if (
    !localStorage.getItem("access_token") &&
    screenWidth < 992 &&
    window.location.href.indexOf("/pub/") === -1 &&
    window.location.href.indexOf("/checkout") === -1
  ) {
    return (
      <BrowserRouter>
        <BrowserScreenSize header={true} />
      </BrowserRouter>
    );
  }

  return (
    <AppContext.Provider
      value={{
        flashMessage,
        updateFlashMessage: data => setFlashMessage(data),
        activeCompany,
        updateActiveCompany: data => setActiveCompany(data),
        upgradeModalStatus,
        updateUpgradeModalStatus: data => setUpgradeModalStatus(data),
        upgradeModalData,
        updateUpgradeModalData: data => setUpgradeModalData(data),
        isSaveInProgress,
        setIsSaveInProgress,
        isStopSaveProgress,
        setIsStopSaveProgress,
        socketConnectionInfo,
        documentWS,
        setDocumentWS,
        restartSave,
        setRestartSave,
        pasteColorActive,
        setPasteColorActive,
        isEyedropperActive,
        setIsEyedropperActive,
        brandInfo,
        setBrandInfo,
      }}>
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route exact path="/signin" name="Login Page" component={Login} />
          <Route exact path="/signup" name="Signup Page" component={Signup} />
          <Route exact path="/checkout" name="Checkout Page" component={ProOnceCheckOut} />
          <Route
            exact
            path="/pub/:companyId/documents/:documentHash"
            name="Publish Page"
            component={AuthenticatePreview}
          />
          <Route exact path="/forgot-password" name="ForgotPassword Page" component={ForgotPassword} />
          <Route exact path="/account/:token/reset-password" name="ResetPassword Page" component={ResetPassword} />
          <Route exact path="/signup/verification" name="Signup Verification Page" component={SignupVerification} />
          <Route exact path="/not-found" name="Page 404" component={NotFound} />
          <Route exact path="/getting-ready" name="Getting Ready Page" component={GettingReady} />
          <Route exact path="/signup-final" name="signup-final" component={SignupFinalStep} />
          <Route
            exact
            path="/companies/:companyId/settings/members/invite/:token/user-accept"
            name="User Invitation Accept"
            component={AcceptInvitation}
          />
          <Route
            exact
            path="/companies/:companyId/settings/delete/:token"
            name="User Invitation Accept"
            component={DeleteCompany}
          />
          <Route
            exact
            path="/signin/:token/authenticate"
            name="Two step Authenticate Page"
            component={TwoStepAuthenticate}
          />
          <Route
            exact
            path="/signin/:token/authenticate-backup"
            name="Two step Authenticate Backup Page"
            component={TwoStepAuthenticateBackup}
          />
          <PrivateRoute exact path="/:slug/:recordId/preview" name="Preview Page" component={Preview} />
          <PrivateRoute exact path="/documents/:recordId/present" name="Present Page" component={Present} />
          <PrivateRoute exact path="/documents/:recordId/play" name="Play" component={Play} />
          <PrivateRoute path="/templates/:type" name="Template type" component={TemplateCategory} />
          <PrivateRoute path="/templates" name="All Templates" component={AllTemplates} />
          <PrivateRoute path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
