import React, { useEffect, useState, useContext } from "react";
import cx from "classnames";
import { Button, Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import global from "../../scss/dhp.scss";
import WelcomePng from "../../assets/images/dhp-welcome.png";
import WelcomeWebp from "../../assets/images/dhp-welcome.webp";
import { Form } from "../ui/form";
import { Input } from "../ui/input";
import { firstCompanyUpdate, getUserInfo, logout } from "../../store/actions/authActions";
import { getipLocation, getResourceEntries } from "../../store/actions/resourcesActions";
import LoadFlagImage from "../ui/LoadFlagImage";
import Image from "../ui/image";
import { Icon } from "../ui/icon";
import { getTimezoneInfo, clearAllTextFields } from "../../_helpers/utils";
import { createCompany, switchCompany, fetchCompany, getCompanyInfo } from "../../store/actions/companyActions";
import {
  COMPANY_SWITCH_SUCCESS,
  CREATE_COMPANY_SUCCESS,
  GET_USER_INFO_SUCCESS,
  UPDATE_USER_COMPANY_INFO_SUCCESS,
} from "../../store/actions/actionTypes";
import * as constant from "../../constants/company";
import { AppContext } from "../../contexts";
import { useSocket } from "../../hooks/useSocket";
import { COMPANY_SWITCH } from "../../constants/socket";
import { ProcessLoader } from "../ui/loader/processLoader";

let style = Object.assign({}, global);

const CompanyModal = ({ type, showModal, setShowModal, showCreate }) => {
  const webSocket = useSocket();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user: userInfo, uid: userId } = useSelector(state => state?.auth);
  const successMsg = useSelector(state => state?.auth?.success_msg?.company_update);
  const { type: actionType, id: companyId } = useSelector(state => state?.company);
  const resourceOptions = useSelector(state => state?.resources);
  const { activeCompany, updateActiveCompany, isSaveInProgress, setIsSaveInProgress, setIsStopSaveProgress } =
    useContext(AppContext);

  let initialState = {
    company_name: {
      label: "Company Name",
      name: "company_name",
      value: "",
      type: "text",
      validation: {
        required: true,
      },
      valid: false,
    },
    time_zone: {
      label: "Time Zone",
      name: "time_zone",
      value: "",
      type: "dropdown",
      validation: {
        required: true,
      },
      datasource: {
        component: "timezones",
      },
      valid: false,
    },
    country_code: {
      label: "Country",
      name: "country_code",
      value: "",
      type: "dropdown",
      validation: {
        required: false,
      },
      datasource: {
        component: "countries",
      },
      valid: true,
    },
  };

  const [modalFields, setModalFields] = useState(initialState);
  const [isFormValid, setFormValid] = useState(false);
  const [isProcessDone, setIsProcessDone] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState();
  const [defaultTimezone, setDefaultTimezone] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [createCompState, setCreateCompState] = useState();

  useEffect(() => {
    Object.keys(initialState).forEach(field => {
      if (
        initialState[field].type === "dropdown" &&
        initialState[field].datasource &&
        initialState[field].datasource.component
      ) {
        dispatch(getResourceEntries(initialState[field].datasource.component));
      }
    });
    if (!resourceOptions["iplocation"]) dispatch(getipLocation("iplocation"));
  }, []);

  useEffect(() => {
    if (isProcessDone && userInfo && successMsg) {
      dispatch(getUserInfo());
    }
  }, [isProcessDone, userInfo, successMsg]);

  useEffect(() => {
    if (!isSaveInProgress && createCompState) {
      handleSubmit();
    }
  }, [isSaveInProgress]);

  useEffect(() => {
    if (!defaultTimezone) {
      let { code, gmtValue } = getTimezoneInfo();
      gmtValue = gmtValue.replace(/[a-zA-Z]+/g, "");
      let timeZones = resourceOptions["timezones"];
      if (timeZones) {
        let guisedTimezoneByValue = timeZones.filter(el => el.name.includes(gmtValue));
        let guisedTimezoneByValueAndCode = guisedTimezoneByValue.filter(el => el.name.includes(`(${code})`));
        setDefaultTimezone(
          guisedTimezoneByValueAndCode.length > 0 ? guisedTimezoneByValueAndCode[0] : guisedTimezoneByValue[0]
        );
      }
    }
    if (!defaultCountry && resourceOptions["countries"] && resourceOptions["iplocation"]) {
      let countryInfo = resourceOptions["countries"].find(
        elem => elem?.iso_code === resourceOptions["iplocation"]?.country_code
      );
      setDefaultCountry({
        name: countryInfo?.name ?? "Afghanistan",
        iso_code: countryInfo?.iso_code ?? "AF",
      });
    }
  }, [resourceOptions]);

  useEffect(() => {
    let updatedForm = { ...modalFields };
    if (type === "create" || showCreate) {
      updatedForm["country_code"].valid = false;
      updatedForm["country_code"].validation.required = true;
    }
    setModalFields(updatedForm);
  }, [type]);

  useEffect(() => {
    let updatedForm = { ...modalFields };
    if (defaultCountry && (type === "create" || showCreate)) {
      updatedForm["country_code"].value = defaultCountry.iso_code;
      updatedForm["country_code"].valid = true;
    }
    if (defaultTimezone) {
      updatedForm["time_zone"].value = defaultTimezone.name;
      updatedForm["time_zone"].valid = true;
    }
    setModalFields(updatedForm);
  }, [defaultCountry, defaultTimezone]);

  useEffect(() => {
    if (
      (actionType === CREATE_COMPANY_SUCCESS && companyId !== userInfo?.company?.id) ||
      actionType === UPDATE_USER_COMPANY_INFO_SUCCESS
    ) {
      let activeCompanyId = actionType === UPDATE_USER_COMPANY_INFO_SUCCESS ? userInfo?.company?.id : companyId;
      dispatch(switchCompany(activeCompanyId)).then(resp => {
        if (resp?.type === COMPANY_SWITCH_SUCCESS) {
          clearAllTextFields();
          dispatch(fetchCompany());

          // check is web socket is in ready state
          if (webSocket.readyState === 1) {
            const subscribe = {
              type: COMPANY_SWITCH,
              companyId: resp?.result?.id,
              prevCompanyId: activeCompany.id,
              userId,
            };
            webSocket.send(JSON.stringify(subscribe));
          }

          dispatch(getUserInfo()).then(resp => {
            if (resp?.type === GET_USER_INFO_SUCCESS) {
              updateActiveCompany(resp?.userInfo?.company);
              // dispatch(fetchAllCustomFonts(resp?.userInfo?.company?.id));
              history.push("/");
            }
          });
        }
      });
    }
  }, [actionType, companyId]);

  let modalTitle = type === "create" || showCreate ? constant.CREATE_COMPANY : constant.UPDATE_COMPANY;

  const checkValidity = ({ value, validation, type }) => {
    let isValid = true;
    if (validation.required) {
      if (type === "dropdown") isValid = value.trim() !== "" && isValid;
      else isValid = value.trim() !== "" && isValid;
    }
    return isValid;
  };

  const handleChange = (evt, field) => {
    let updatedForm = { ...modalFields };
    if (updatedForm[field].type === "dropdown") {
      if (updatedForm[field].name == "country_code") updatedForm[field].value = evt.iso_code;
      else if (updatedForm[field].name == "time_zone") updatedForm[field].value = evt.name;
      else updatedForm[field].value = evt.value;
    } else updatedForm[field].value = evt.target.value;

    updatedForm[field].valid = checkValidity(updatedForm[field]);
    setModalFields(updatedForm);
    checkFormValidity();
  };

  const checkFormValidity = () => setFormValid(Object.keys(modalFields).every(field => modalFields[field].valid));

  // For Submit modal value
  const handleSubmit = e => {
    // set saving document status false
    if (isSaveInProgress) {
      setIsStopSaveProgress(true);
      setCreateCompState(true);
      setIsSaveInProgress(false);
      return;
    }

    if (isFormValid) {
      setCreateCompState(null);
      e?.preventDefault();
      setDisableButton(true);
      let payload = {};
      for (let field in modalFields) {
        if (modalFields[field].value !== "" && field != "company_name") payload[field] = modalFields[field].value;
        if (modalFields[field].value !== "" && field == "company_name" && type !== "create")
          payload["name"] = modalFields[field].value;
        if (modalFields[field].value !== "" && field == "company_name" && type === "create")
          payload["company_name"] = modalFields[field].value;
      }
      if (type === "create") {
        payload["reporting_currency"] = "USD";
        dispatch(createCompany(payload)).then(() => setIsProcessDone(true));
      } else {
        dispatch(firstCompanyUpdate(userInfo?.company?.id, payload)).then(() => {
          dispatch(getCompanyInfo(userInfo?.company?.id));
          setIsProcessDone(true);
        });
      }
    }
  };

  const close = () => {
    if (setShowModal) setShowModal(!showModal);
  };

  const onSignOut = e => {
    e.preventDefault();
    dispatch(logout());
  };

  return (
    <>
      <Form>
        {type == "create" && (
          <span className={cx(style["cross-modal"], style["rounded"])} onClick={() => close()}>
            <Icon icon="ui-close" />
          </span>
        )}
        <Row cssModule={style} className={cx(style["d-flex"], style["align-items-center"])}>
          <Col cssModule={style} lg={6} className={cx(style["d-none"], style["d-lg-block"])}>
            <Image WebpImage={WelcomeWebp} PngImage={WelcomePng} />
          </Col>
          <Col cssModule={style} lg={6}>
            <h4 className={cx(style["fw-7"], style["mb-4"])}>{modalTitle}</h4>
            <Input
              label={modalFields.company_name.label}
              type={modalFields.company_name.type}
              name={modalFields.company_name.name}
              value={modalFields.company_name.value}
              onChange={e => handleChange(e, modalFields.company_name.name)}
              onBlur={e => handleChange(e, modalFields.company_name.name)}
              required={modalFields.company_name.validation.required}
              autoFocus
            />

            <Input
              label={modalFields.time_zone.label}
              type={modalFields.time_zone.type}
              name={modalFields.time_zone.name}
              classNamePrefix="select"
              getOptionValue={option => option?.name}
              getOptionLabel={option => <span>{option?.name}</span>}
              options={resourceOptions["timezones"] || []}
              updateState={e => handleChange(e, modalFields.time_zone.name)}
              required={modalFields.time_zone.validation.required}
              defaultValue={defaultTimezone}
            />

            {(type === "create" || showCreate) && (
              <Input
                label={modalFields.country_code.label}
                type={modalFields.country_code.type}
                name={modalFields.country_code.name}
                classNamePrefix="select"
                customLabel={true}
                getOptionValue={option => option?.name}
                getOptionLabel={option => (
                  <span className={style["option-label"]}>
                    <LoadFlagImage isoCode={option?.iso_code} /> {option?.name}
                  </span>
                )}
                options={resourceOptions["countries"] || []}
                updateState={e => handleChange(e, modalFields.country_code.name)}
                required={modalFields.country_code.validation.required}
                defaultValue={defaultCountry}
              />
            )}
            <div className={cx(style["d-flex"], style["justify-content-between"], style["align-items-center"])}>
              <div className={cx(style["d-flex"], style["align-items-center"])}>
                <Button color="primary" onClick={handleSubmit} disabled={!isFormValid || disableButton}>
                  {type === "create" || showCreate ? "Create" : "Continue"}
                </Button>

                <ProcessLoader isOpen={disableButton} />
              </div>

              {showCreate && (
                <div>
                  <span
                    className={cx(style["align-items-center"], style["d-flex"], style["cursor-pointer"])}
                    onClick={onSignOut}>
                    <Icon icon="ui-out" additionalclass="mr-2" /> Sign Out
                  </span>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

CompanyModal.propTypes = {
  type: PropTypes.string.isRequired,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  showCreate: PropTypes.bool,
};

const CommonModal = ({ showModal, setShowModal, modalTitle, modalBody, modalButtonLabel, successFunc, buttonType }) => {
  const [showLoader, setShowLoader] = useState(false);

  const handleSubmit = () => {
    setShowLoader(true);
    successFunc();
  };
  const close = () => {
    setShowModal(!showModal);
  };

  return (
    <React.Fragment>
      <h4 className={cx(style["fw-7"], style["mb-4"])}>{modalTitle}</h4>
      <span aria-label="Close" className={cx(style["cross-modal"], style["rounded"])} onClick={() => close()}>
        <Icon icon="ui-close" />
      </span>
      <div>
        <div className={cx(style["delete-msg"], style["font-base"], style["color-33"], style["mb-4"])}>{modalBody}</div>
        <div className={cx(style["d-flex"], style["align-items-center"])}>
          <Button type="submit" color={buttonType || "danger"} onClick={handleSubmit}>
            {modalButtonLabel}
          </Button>

          <ProcessLoader isOpen={showLoader} />
        </div>
      </div>
    </React.Fragment>
  );
};

CommonModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  modalBody: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  modalButtonLabel: PropTypes.string.isRequired,
  successFunc: PropTypes.func,
};

export { CompanyModal, CommonModal };
