import { useContext, useEffect } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";

const useLayer = (
  isLayerClicked,
  setIsLayerClicked,
  activeWidgetIndex,
  currentBlockWidgetList,
  layer,
  currentBlockWidgetIndex
) => {
  let { metadata, widgets, updateWidgets, rightContextMenu, updateRightContextMenu } = useContext(EditorContext);

  useEffect(() => {
    if (!isLayerClicked) return;

    let targetWidgetID = [];
    let targetWidgetIndex = [];
    let updatedWidgetObj = [...widgets];
    let spliceObj = [];

    if (rightContextMenu.enable)
      updateRightContextMenu({
        ...rightContextMenu,
        enable: false,
      });

    //get currentBlock's next widget id of active widget
    if (layer === "bringForward") {
      activeWidgetIndex.forEach((valueIndex, index) => {
        widgets.forEach((element, idx) => {
          if (
            idx > valueIndex &&
            !activeWidgetIndex.includes(idx) &&
            element.blockId === metadata.activeBlockId &&
            !targetWidgetID[index]
          ) {
            targetWidgetID.push(element.id);
          }
        });
      });
    }
    //get currentBlock's previous widget id of active widget
    else if (layer === "sendBackward") {
      activeWidgetIndex.forEach((valueIndex, index) => {
        widgets.forEach((element, idx) => {
          if (idx < valueIndex && !activeWidgetIndex.includes(idx) && element.blockId === metadata.activeBlockId) {
            targetWidgetID[index] = element.id;
          }
        });
      });
    }
    //get currentBlock's last widget id
    else if (layer === "bringToFront") {
      if (activeWidgetIndex.length > 1) {
        let activeWidgetMaxIdx = Math.max(...activeWidgetIndex);
        let currentBlockWidgetMaxIdx = Math.max(...currentBlockWidgetIndex);

        // modify active widget index if one widget is in top most layer
        if (activeWidgetMaxIdx === currentBlockWidgetMaxIdx)
          activeWidgetIndex = activeWidgetIndex.filter(value => value !== activeWidgetMaxIdx);
      }
      targetWidgetID.push(currentBlockWidgetList[currentBlockWidgetList.length - 1].id);
    }
    //get currentBlock's first widget id
    else if (layer === "sendToBack") {
      if (activeWidgetIndex.length > 1) {
        let activeWidgetMinIdx = Math.min(...activeWidgetIndex);
        let currentBlockWidgetMinIdx = Math.max(...currentBlockWidgetIndex);

        // modify active widget index if one widget is in bottom most layer
        if (activeWidgetMinIdx === currentBlockWidgetMinIdx)
          activeWidgetIndex = activeWidgetIndex.filter(value => value !== activeWidgetMinIdx);
      }
      targetWidgetID.push(currentBlockWidgetList[0].id);
    }

    targetWidgetID.forEach(id => {
      let widgetIndex = widgets.findIndex(widget => widget.id === id);
      targetWidgetIndex.push(widgetIndex);
    });

    if (layer === "bringForward" || layer === "sendBackward") {
      activeWidgetIndex.forEach((value, index) => {
        const lastValue = updatedWidgetObj?.[targetWidgetIndex[index]] ?? updatedWidgetObj[value];
        if (targetWidgetIndex[index] !== undefined) {
          updatedWidgetObj[targetWidgetIndex[index]] = updatedWidgetObj[value];
        }
        updatedWidgetObj[value] = lastValue;
      });
    } else {
      activeWidgetIndex.sort();
      // splice modified active widgets (which will rearrange)
      for (var i = activeWidgetIndex.length - 1; i >= 0; i--) {
        spliceObj = [...spliceObj, updatedWidgetObj.splice(activeWidgetIndex[i], 1)[0]];
      }

      // add widgets in new position
      spliceObj.forEach(element => updatedWidgetObj.splice(targetWidgetIndex[0], 0, element));
    }

    updateWidgets(updatedWidgetObj);
    setIsLayerClicked(false);
  }, [isLayerClicked]);
};

export default useLayer;
