import React, { useContext } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import global from "../../scss/dhp.scss";

import EditorTitlebar from "./EditorTitlebar";
import EditorToolbar from "./EditorToolbar";
import { EditorContext } from "./EditorLayout";

let style = Object.assign({}, global);

const EditorTopbar = props => {
  const { playingPageIdx } = useContext(EditorContext);

  return (
    <div className={cx(style["editor-topbar"], { [style["disable"]]: playingPageIdx !== null })}>
      <EditorTitlebar fitToScreen={props.fitToScreen} setFitToScreen={props.setFitToScreen} />
      <EditorToolbar handleWidgetAction={props.handleWidgetAction} toggle={props.toggle} />
    </div>
  );
};

EditorTopbar.propTypes = {
  handleWidgetAction: PropTypes.func.isRequired,
  toggle: PropTypes.func,
  fitToScreen: PropTypes.bool,
  setFitToScreen: PropTypes.func,
};

export default EditorTopbar;
