import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import cx from "classnames";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import global from "../../../scss/dhp.scss";
import { showLoading } from "react-redux-loading-bar";

import AssetName from "./Common/AssetName";
import {
  fetchDocumentDetails,
  fetchDocumentVersionInfo,
  getDocumentVersions,
  updateDocumentVersion,
} from "../../../store/actions/documentActions";
import { DataFormatter } from "../../ui/dataFormatter";
import MoreOption from "../../ui/MoreOption";
import { Icon } from "../../ui/icon";
import Modal from "../../ui/modal";
import RestoreVersionModal from "../Modals/RestoreVersionModal";
import Preview from "../../Common/Preview";
import useDocumentReader from "../../../hooks/useDocumentReader";
import { EditorContext } from "../../../containers/editor/EditorLayout";

let style = Object.assign({}, global);

const dateFormat = "default";

const VersionPreviewModal = ({ selectedVersionInfo, closeModal, documentId }) => {
  const dispatch = useDispatch();

  const versionData = useSelector(state => state?.document?.versionDetailInfo?.data);

  const [fetchNewVersionData, setFetchNewVersionData] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [isVideoPresent, setIsVideoPresent] = useState(false);
  const [muted, setMuted] = useState(true);

  const toggleMute = mute => {
    setMuted(mute);
    document.querySelectorAll("video, audio").forEach(v => (v.muted = mute));
  };

  const toggleModal = () => {
    setShowModal(prevState => !prevState);
  };

  useEffect(() => {
    dispatch(showLoading());

    if (selectedVersionInfo) {
      dispatch(fetchDocumentVersionInfo(documentId, selectedVersionInfo?.id)).then(() => {
        setTimeout(() => {
          setIsVideoPresent(document.querySelectorAll("#preview-box video").length > 0);
        }, 30);
      });
      setFetchNewVersionData(true);
    }

    return () => {
      setFetchNewVersionData(false);
    };
  }, []);

  return (
    <React.Fragment>
      {versionData && fetchNewVersionData && (
        <>
          <header className={cx(style["dhp-header"], style["preview-header"])}>
            <div className={style["header-left"]}>
              <h3 className={cx(style["fw-7"], style["m-0"], style["text-truncate"])}>{versionData?.document_name}</h3>
            </div>

            <div className={style["header-right"]}>
              <ul className={cx(style["d-flex"], style["align-items-center"])}>
                <li>
                  <div className={cx(style["fw-6"], style["font-15"])}>{versionData?.version?.name}</div>
                </li>
                <li className={style["date-time"]}>
                  <div className={style["font-15"]}>
                    <DataFormatter type="date-time" slug="modified_on" format={dateFormat} data={selectedVersionInfo} />
                  </div>
                </li>
                <li>
                  <span className={cx(style["custom-tooltip"], style["rounded"])} onClick={toggleModal}>
                    <Icon icon="ui-restore" />
                    <div className={cx(style["custom-tooltip-content"], style["bottom"])}>Restore this Version</div>
                  </span>
                </li>
                {isVideoPresent && (
                  <li>
                    <span
                      className={cx(style["toggle-mute"], style["custom-tooltip"], style["rounded"])}
                      onClick={() => toggleMute(!muted)}>
                      <Icon icon={`ui-${muted ? "volume-mute" : "volume"}`} />
                      <div className={cx(style["custom-tooltip-content"], style["bottom"])}>
                        {muted ? "Unmute" : "Mute"}
                      </div>
                    </span>
                  </li>
                )}
                <li>
                  <span
                    className={cx(style["close-preview"], style["custom-tooltip"], style["rounded"])}
                    onClick={closeModal}>
                    <Icon icon="ui-close" />
                    <div className={cx(style["custom-tooltip-content"], style["bottom"])}>Close</div>
                  </span>
                </li>
              </ul>
            </div>
          </header>

          <Preview versionSlug="documents" versionData={versionData} preserveWidth={true} />
        </>
      )}

      {showModal && (
        <Modal
          toggle={toggleModal}
          showModal={showModal}
          component={RestoreVersionModal}
          setShowModal={setShowModal}
          selectedVersionInfo={selectedVersionInfo}
          closeModal={toggleModal}
          parentModalClose={closeModal}
        />
      )}
    </React.Fragment>
  );
};

//Props type validation
VersionPreviewModal.propTypes = {
  selectedVersionInfo: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  documentId: PropTypes.string.isRequired,
};

const VersionItem = ({ versionData, toggleModal }) => {
  const dispatch = useDispatch();
  const inputField = useRef();

  const [isDisable, setIsDisable] = useState(true);
  const [versionName, setVersionName] = useState(versionData?.version_name);

  const defaultVersionName = `Version ${versionData?.version}`;
  const optionList = [
    {
      label: defaultVersionName === versionName ? "Name this Version" : "Remove Name",
      icon: defaultVersionName === versionName ? "ui-rename" : "ui-close",
      clickFunc: () => renameVersion(),
    },
    { label: "Restore Version", icon: "ui-restore", clickFunc: () => toggleModal(versionData, "restore") },
    { label: "Preview", icon: "eye2", clickFunc: () => toggleModal(versionData, "preview") },
  ];

  const updateVersionName = () => {
    setIsDisable(true);
    if (defaultVersionName !== versionName && versionName.trim() !== "") {
      dispatch(updateDocumentVersion(versionData.id, { name: versionName }));
    }

    if (versionName.trim() === "") setVersionName(defaultVersionName);
  };

  const renameVersion = () => {
    if (defaultVersionName === versionName) {
      setIsDisable(false);
    } else {
      setVersionName(defaultVersionName);
      dispatch(updateDocumentVersion(versionData.id, { name: defaultVersionName }));
    }
  };

  useEffect(() => {
    if (!isDisable) {
      inputField.current.focus();
    }
  }, [isDisable]);

  return (
    <>
      <li className={cx(style["virsion-item"], style["mb-0"])}>
        <div className={style["leftpanel"]}>
          <Input
            innerRef={inputField}
            type="text"
            className={cx(style["version-name"], style["my-n2"], style["h-25"], style["my-auto"])}
            disabled={isDisable}
            onBlur={updateVersionName}
            onChange={e => setVersionName(e.target.value)}
            value={versionName}
          />
          <div className={style["pl-1"]}>
            <DataFormatter type="date-time" slug="modified_on" format={dateFormat} data={versionData} />
          </div>
          <span className={style["user"]}>
            <Icon icon="ui-user" /> {versionData?.first_name} {versionData?.last_name}
          </span>
        </div>
        <MoreOption options={optionList ?? []} dropdownClass="more-action" />
      </li>
    </>
  );
};

//Props type validation for
VersionItem.propTypes = {
  versionData: PropTypes.object.isRequired,
  toggleModal: PropTypes.func,
};

const Version = props => {
  const dispatch = useDispatch();
  const { id: documentId } = useParams();
  const [fitToScreen, setFitToScreen] = useState(true);
  const { setIsDocumentReady, HistoryList } = useContext(EditorContext);

  const { start: initDocumentReader } = useDocumentReader(fitToScreen, setFitToScreen);

  const { versionList: versionInfo, versionRestored: isVersionRestored } = useSelector(state => state.document);

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState();
  const [selectedVersionInfo, setSelectedVersionInfo] = useState();

  const toggleModal = (versionData, modalType) => {
    setModalType(modalType);
    setSelectedVersionInfo(versionData);
    setShowModal(prevState => !prevState);
  };

  const closeModal = () => {
    setShowModal(prevState => !prevState);
  };

  useEffect(() => {
    dispatch(getDocumentVersions(documentId));
  }, []);

  useEffect(() => {
    if (isVersionRestored) {
      dispatch(fetchDocumentDetails(documentId)).then(resp => {
        if (resp?.result?.data) {
          initDocumentReader({ data: resp.result.data });
          setIsDocumentReady(false);
          HistoryList.length = 1; // Reset history list to disable undo redo
        }
      });
    }
  }, [isVersionRestored]);

  return (
    <>
      <div className={cx(style["editor-asset-inner"], style["version-asset"])}>
        <AssetName handleWidgetAction={props.handleWidgetAction} assetName={props.assetName} />
        <div className={cx(style["customScroll"], style["scroll-Y"], style["assets-wrapper"], style["pr-0"])}>
          {versionInfo && (
            <ul className={cx(style["versionlist"])}>
              {versionInfo?.map((version, idx) => (
                <VersionItem key={idx} versionData={version} toggleModal={toggleModal} />
              ))}
            </ul>
          )}
        </div>
      </div>
      {showModal && modalType === "restore" && (
        <Modal
          toggle={toggleModal}
          showModal={showModal}
          component={RestoreVersionModal}
          setShowModal={setShowModal}
          selectedVersionInfo={selectedVersionInfo}
          closeModal={closeModal}
        />
      )}
      {showModal && modalType === "preview" && (
        <Modal
          toggle={toggleModal}
          showModal={showModal}
          component={VersionPreviewModal}
          setShowModal={setShowModal}
          selectedVersionInfo={selectedVersionInfo}
          closeModal={closeModal}
          backdrop="static"
          modalClassName="version-modal"
          documentId={documentId}
          centered={false}
        />
      )}
    </>
  );
};

//Props type validation
Version.propTypes = {
  handleWidgetAction: PropTypes.func.isRequired,
  assetName: PropTypes.string.isRequired,
};

export default Version;
