import React from "react";
import cx from "classnames";
import global from "../../../scss/dhp.scss";

let style = Object.assign({}, global);

const SingleLineLoader = ({ width, height }) => {
  return (
    <ul
      className={cx(style["skeleton-loader-area"], style["flex-column"], style["align-items-start"], style["mb-4"])}
      style={{ width: `${width}%`, height: height }}>
      <li className={cx(style["loader-item"], style["my-2"])}></li>
    </ul>
  );
};

export default SingleLineLoader;
