import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { BRAND, STOCK, UPLOAD_VIDEO, VIDEO } from "../../../constants/editor";
import { getHMSToSec } from "../../../_helpers/utils";

let style = Object.assign({}, global);

const Loop = () => {
  let { metadata, widgets, updateWidgets, togglePlayButton, changeBySocket } = useContext(EditorContext);

  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);

  const [toggleLoop, setToggleLoop] = useState();
  const [updateContext, setUpdateContext] = useState(false);

  useEffect(() => {
    if (toggleLoop) document.querySelector(`#${metadata.activeWidgetId[0]} video`).setAttribute("loop", "loop");
    else document.querySelector(`#${metadata.activeWidgetId[0]} video`).removeAttribute("loop");

    let newArray;

    if (isGroupWidget) {
      document.getElementById(id).setAttribute("data-loop", toggleLoop);

      newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgets[targetWidgetIndex],
          innerHTML: document.getElementById(targetId).innerHTML,
        },
      });
    } else {
      if (!toggleLoop) {
        let activeVideoTrimmedDuration = parseFloat(
          getHMSToSec({
            hms:
              widgets[targetWidgetIndex].data["data-trimmed-duration"] !== ""
                ? widgets[targetWidgetIndex].data["data-trimmed-duration"]
                : widgets[targetWidgetIndex].data["data-duration"],
          })
        );
        let widgetDurationStartTime = widgets[targetWidgetIndex].duration?.startTime
          ? widgets[targetWidgetIndex].duration.startTime
          : 0;

        newArray = Object.assign([...widgets], {
          [targetWidgetIndex]: {
            ...widgets[targetWidgetIndex],
            duration: {
              ...widgets[targetWidgetIndex].duration,
              endTime: `${activeVideoTrimmedDuration}s`,
              totalTime: `${parseFloat(activeVideoTrimmedDuration) - parseFloat(widgetDurationStartTime)}s`,
            },
            data: {
              ...widgets[targetWidgetIndex].data,
              "data-loop": toggleLoop,
            },
            innerHTML: document.getElementById(metadata.activeWidgetId[0]).innerHTML,
          },
        });
      } else {
        newArray = Object.assign([...widgets], {
          [targetWidgetIndex]: {
            ...widgets[targetWidgetIndex],
            data: {
              ...widgets[targetWidgetIndex].data,
              "data-loop": toggleLoop,
            },
            innerHTML: document.getElementById(metadata.activeWidgetId[0]).innerHTML,
          },
        });
      }
    }

    updateContext && updateWidgets(newArray);
    setUpdateContext(false);
  }, [toggleLoop, updateContext]);

  useEffect(() => {
    setToggleLoop(document.getElementById(id).getAttribute("data-loop") === "true" ? true : false);
  }, [metadata.activeWidgetId]);

  //check Loop is applied or not for collaboration
  useEffect(() => {
    if (changeBySocket && widgets[targetWidgetIndex]?.innerHTML)
      setToggleLoop(document.getElementById(id).getAttribute("data-loop") === "true" ? true : false);
  }, [changeBySocket, widgets[targetWidgetIndex]?.innerHTML]);

  return (
    <li className={style["toolset-group"]}>
      <div className={cx(style["toolset-item"], style["loop-wrap"])}>
        <span
          className={cx(style["toolset-action"], style["custom-tooltip"], {
            [style["disabled"]]: togglePlayButton,
          })}
          onClick={() => {
            setToggleLoop(!toggleLoop), setUpdateContext(true);
          }}>
          <Icon icon={`ui-${!toggleLoop ? "loop" : "unloop"}`} />

          <div className={cx(style["custom-tooltip-content"], style["top"])}>
            {!toggleLoop ? "Enable Loop" : "Disable Loop"}
          </div>
        </span>
      </div>
    </li>
  );
};

export default Loop;
