import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Button } from "reactstrap";
import cx from "classnames";

import { DOCUMENT_UPDATE_SUCCESS, DOCUMENT_UPDATE_FAILED } from "../../../store/actions/actionTypes";
import { updateDocument } from "../../../store/actions/documentActions";
import {
  convertNonAsciiToClosestAsciiEquivalent as convertToAscii,
  preventFormSubmitOnEnter as preventSubmit,
} from "../../../_helpers/utils";
import { documentInfo } from "../_utils";
import { Input } from "../../ui/input";
import { Icon } from "../../ui/icon";
import { ProcessLoader } from "../../ui/loader/processLoader";
import { FlashAlert } from "../../ui/flashAlert";

import global from "../../../scss/dhp.scss";

let style = Object.assign({}, global);

const RenameModal = ({ document, _helpers, view }) => {
  const dispatch = useDispatch();
  const alertData = {
    show: false,
    type: "",
  };
  const processEndTypes = [DOCUMENT_UPDATE_SUCCESS, DOCUMENT_UPDATE_FAILED];

  const { data: documentName } = documentInfo.get(document, "document_name");

  const form = {
    document_name: {
      value: documentName,
      validation: {
        required: true,
        not_equals: documentName,
      },
      valid: false,
    },
  };

  const [formData, setFormData] = useState(form);
  const [formValid, setFormValid] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [alert, setAlert] = useState(alertData);
  const [disable, setDisable] = useState({ rename: false });

  const { type, documentDetails } = useSelector(state => state?.document);

  const toggleAlert = () => setAlert({ ...alert, show: !alert.show });

  const checkValidity = (value, rules) => {
    let isValid = true;
    if (rules.required) isValid = value.trim() !== "" && isValid;
    if (rules.not_equals) isValid = value.trim() !== rules.not_equals && isValid;
    return isValid;
  };

  const updateForm = (e, field) => {
    let updatedForm = { ...formData };
    updatedForm[field].value = convertToAscii(e.target.value);
    updatedForm[field].valid = checkValidity(updatedForm[field].value, updatedForm[field].validation);
    setFormData(updatedForm);
    setFormValid(Object.keys(formData).every(field => formData[field].valid));
  };

  const handleSubmit = e => {
    if (formValid) {
      e.preventDefault();
      setIsMounted(true);
      let payload = { document_name: formData.document_name.value };
      dispatch(updateDocument(document.id, payload));
      setDisable({ rename: true });
    }
  };

  useEffect(() => {
    if (isMounted && type && type === DOCUMENT_UPDATE_SUCCESS) {
      setIsMounted(false);
      documentInfo.set(document, { document_name: formData.document_name.value });
      _helpers.documentName.setData(formData.document_name.value);
      _helpers.modal.toggle();
      _helpers.gridView.fetch.setPayload({ ..._helpers.gridView.fetch.payload, page: 1 });
    }
    if (isMounted && type && type === DOCUMENT_UPDATE_FAILED) {
      setIsMounted(false);
      setAlert({ ...alert, show: true, type: "error" });
    }
  }, [type, isMounted]);

  return (
    <React.Fragment>
      <h4 className={cx(style["fw-7"], style["mb-4"])}>Rename</h4>
      <Link to="#" className={cx(style["cross-modal"], style["rounded"])} onClick={_helpers?.modal?.toggle}>
        <Icon icon="ui-close" />
      </Link>

      <Form className={style["mt-1"]} onKeyDown={e => preventSubmit(e)} onSubmit={e => handleSubmit(e)}>
        <Input
          cssModule={style}
          returnType="formGroup"
          type="text"
          label="Document Name"
          required={true}
          value={formData.document_name.value}
          onChange={e => updateForm(e, "document_name")}
          disabled={disable.rename}
        />

        <div className={cx(style["align-items-center"], style["d-flex"])}>
          <Button
            type="submit"
            className={style["mt-2"]}
            color="primary"
            cssModule={style}
            disabled={!formValid || disable.rename}>
            Save
          </Button>

          <ProcessLoader isOpen={disable.rename && !processEndTypes.includes(type)} />
        </div>
      </Form>

      <FlashAlert isOpen={alert.show} toggle={toggleAlert} type={alert.type} wrapperClass="mt-3" />
    </React.Fragment>
  );
};

RenameModal.propTypes = {
  document: PropTypes.object.isRequired,
  _helpers: PropTypes.object.isRequired,
  view: PropTypes.string,
};

export default RenameModal;
