import React from "react";
import global from "../../../scss/dhp.scss";
import { Icon } from "../../ui/icon";
import cx from "classnames";
import useCollage from "../../../hooks/useCollage";

let style = Object.assign({}, global);

const CollageActions = () => {
  const { deactivateCollageEvent } = useCollage();

  const validActions = [
    {
      name: "Done",
      value: "SAVE",
      icon: "ui-checkmark",
      className: "collage-action-save",
      additionalclass: "mr-2",
    },
    {
      name: "Cancel",
      value: "CANCEL",
      icon: "ui-close",
      className: "collage-action-cancel",
      additionalclass: "mr-2 font-18",
    },
  ];

  return (
    <ul className={cx(style["toolbar-group"], style["collage"])}>
      {validActions.map((action, i) => (
        <li key={i} className={cx(style["toolset-group"], action.className)}>
          <div className={style["toolset-item"]}>
            <span
              className={cx(style["toolset-action"], style["custom-tooltip"])}
              onClick={() => deactivateCollageEvent({ e: null, actionName: action.value })}>
              <Icon icon={action.icon} additionalclass={action.additionalclass} />
              {action.name}
              <div className={cx(style["custom-tooltip-content"], style["top"])}>{action.name}</div>
            </span>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default CollageActions;
