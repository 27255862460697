import React, { useContext, useState } from "react";
import cx from "classnames";
import { TabPane } from "reactstrap";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { EditorContext } from "../../../containers/editor/EditorLayout";
import useLayer from "../../../hooks/useLayer";
import SendToBack from "./SendToBack";
import BringToFront from "./BringToFront";
import BringForward from "./BringForward";
import SendBackward from "./SendBackward";

let style = Object.assign({}, global);

const Layer = props => {
  let { metadata, widgets } = useContext(EditorContext);

  let activeWidgetIndex = [];
  let currentBlockWidgetIndex = [];
  let currentBlockWidgetList;

  const [isLayerClicked, setIsLayerClicked] = useState(false);
  const [layer, setLayer] = useState();

  // Collect active widget indexes
  if (metadata.activeWidgetId) {
    metadata.activeWidgetId.forEach(id => {
      let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
      let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
      let widgetIndex = widgets.findIndex(widget => widget.id === targetId);
      if (!activeWidgetIndex.includes(widgetIndex)) activeWidgetIndex.push(widgetIndex); // if condition to avoid same index in active array
    });
  }

  // Collect widget list and index which are in active block
  currentBlockWidgetList = widgets.filter(widget => widget.blockId === metadata.activeBlockId);
  currentBlockWidgetList.forEach(element => {
    currentBlockWidgetIndex.push(widgets.findIndex(widget => widget.id === element.id));
  });

  useLayer(
    isLayerClicked,
    setIsLayerClicked,
    activeWidgetIndex,
    currentBlockWidgetList,
    layer,
    currentBlockWidgetIndex
  );

  const changeWidgetLayer = value => {
    if (props.setDropdownOpen) props.setDropdownOpen(false);
    setLayer(value);
    setIsLayerClicked(true);
  };

  return (
    <>
      {!props.rightContext && (
        <TabPane tabId={props.idx}>
          <ul className={cx(style["row"], style["row-cols-2"])}>
            <BringForward changeWidgetLayer={changeWidgetLayer} currentBlockWidgetList={currentBlockWidgetList} />

            <SendBackward changeWidgetLayer={changeWidgetLayer} currentBlockWidgetList={currentBlockWidgetList} />

            <BringToFront changeWidgetLayer={changeWidgetLayer} currentBlockWidgetList={currentBlockWidgetList} />

            <SendToBack changeWidgetLayer={changeWidgetLayer} currentBlockWidgetList={currentBlockWidgetList} />
          </ul>
        </TabPane>
      )}

      {props.rightContext && currentBlockWidgetList.length !== 1 && (
        <>
          <BringToFront
            changeWidgetLayer={changeWidgetLayer}
            currentBlockWidgetList={currentBlockWidgetList}
            rightContext={props.rightContext}
          />

          <SendToBack
            changeWidgetLayer={changeWidgetLayer}
            currentBlockWidgetList={currentBlockWidgetList}
            rightContext={props.rightContext}
          />
        </>
      )}
    </>
  );
};
Layer.propTypes = {
  idx: PropTypes.number,
  setDropdownOpen: PropTypes.func,
  rightContext: PropTypes.bool,
};

export default Layer;
