import { useContext } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";
import { BRAND, STOCK, UPLOAD_VIDEO, VIDEO } from "../constants/editor";

const useCheckPlayButtonDisable = () => {
  let { pageNodes, metadata, widgets, audios } = useContext(EditorContext);

  const checkIsVideoTypePage = (activePageId, activePageIdx, actionPoint) => {
    let targetPageIndex =
      activePageIdx !== false && activePageIdx !== undefined ? activePageIdx : metadata.activePageIdx;
    let targetPageId = activePageId ? activePageId : metadata.activePageId;
    if (!targetPageId) return;

    let activeBlockWidgetList = widgets.filter(widget => widget.pageId === targetPageId);
    let videoAsset = false;
    let widgetAnimationApplied = false;
    let widgetDurationModefied = false;
    let audioAvailable = audios?.length > 0 && actionPoint === "document-paly" ? true : false; // check any audio in document(audio option will consider only for document play not for page play)
    let pageTransitionAvailable = pageNodes[targetPageIndex]?.pageTransition?.enabled ? true : false; // check if page-transition is applied in target page or not

    document.querySelectorAll(`#${targetPageId} .dhp-page-widget`).forEach(element => {
      // check if there is any stock/uploaded or brand video in page
      if (
        element?.getAttribute("data-widget") === "MEDIA" &&
        [STOCK, BRAND, UPLOAD_VIDEO].includes(element.getAttribute("data-source"))
      ) {
        videoAsset = true;
      }

      // check if there is any video in collage
      if (element?.getAttribute("data-widget") === "COLLAGE") {
        element.querySelectorAll(".collage-item").forEach(item => {
          let targetElem = item.querySelector(".media-parent")?.childNodes[0];
          if (
            targetElem &&
            targetElem.getAttribute("data-asset-type") === VIDEO &&
            [STOCK, BRAND, UPLOAD_VIDEO].includes(targetElem.getAttribute("data-source"))
          ) {
            videoAsset = true;
          }
        });
      }
    });

    if (activeBlockWidgetList.length > 0) {
      activeBlockWidgetList.forEach(widget => {
        let elem = document.getElementById(widget.id);

        // check if there is any animation applied in any widget in the page
        if (
          elem?.getAttribute("data-entrance-animation") === "true" ||
          elem?.getAttribute("data-exit-animation") === "true"
        )
          widgetAnimationApplied = true;

        // check if widget duration is maniupulated
        if (
          (widget.duration?.startTime && parseFloat(widget.duration?.startTime) !== 0) ||
          (widget.duration?.endTime &&
            parseFloat(widget.duration?.endTime) !== parseFloat(pageNodes[targetPageIndex]?.pageDuration))
        )
          widgetDurationModefied = true;
      });
    }

    // if there is any video, widget animation, widget duration modification, page transition or audio in target page then return true else false
    if (videoAsset || widgetAnimationApplied || widgetDurationModefied || audioAvailable || pageTransitionAvailable)
      return true;
    else return false;
  };

  return { checkIsVideoTypePage };
};

export default useCheckPlayButtonDisable;
