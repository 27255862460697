import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";

import global from "../../scss/dhp.scss";

import { EditorContext } from "../../containers/editor/EditorLayout";
import { getZoomedValue } from "../../_helpers/utils";
import {
  GENERIC_EDITOR_WRAP_PADDING,
  TYPE_INFOGRAPHIC,
  WIDGET_HANDLER_MARGIN_LEFT,
  WIDGET_HANDLER_MARGIN_TOP,
} from "../../constants/editor";

let style = Object.assign({}, global);

const ActivePageSelector = () => {
  let { documentType, metadata, dimension, blockNodes, isTimeLineViewOpen } = useContext(EditorContext);

  const [selectorStyle, setSelectorStyle] = useState();

  useEffect(() => {
    let addedLeft = getZoomedValue(WIDGET_HANDLER_MARGIN_LEFT + GENERIC_EDITOR_WRAP_PADDING, dimension.zoom);
    let addedTop = getZoomedValue(WIDGET_HANDLER_MARGIN_TOP + GENERIC_EDITOR_WRAP_PADDING, dimension.zoom);
    let newTop;

    if (documentType === TYPE_INFOGRAPHIC) {
      newTop = `${parseFloat(document.querySelector(".canvas-controller").style.top) + addedTop}px`;
    } else {
      if (isTimeLineViewOpen) newTop = "0px";
      else {
        if (document.querySelector(".canvas-controller").style.top === "0px")
          newTop = `${WIDGET_HANDLER_MARGIN_TOP + GENERIC_EDITOR_WRAP_PADDING}px`;
        else newTop = document.querySelector(".canvas-controller").style.top;
      }
    }

    setSelectorStyle({
      position: "absolute",
      zIndex: "999",
      pointerEvents: "none",
      width: `${getZoomedValue(parseFloat(blockNodes[metadata.activeBlockIdx].style.width), dimension.zoom)}px`,
      height: `${getZoomedValue(parseFloat(blockNodes[metadata.activeBlockIdx].style.height), dimension.zoom)}px`,
      top: newTop,
      left:
        documentType === TYPE_INFOGRAPHIC
          ? `${addedLeft}px`
          : `${WIDGET_HANDLER_MARGIN_LEFT + GENERIC_EDITOR_WRAP_PADDING}px`,
      outline: "1px solid #00a4ef",
    });
  }, [metadata.activeBlockIdx, dimension, metadata.pageController]);

  return (
    <div id="active-page-selctor" className={cx(style["current-canvas-selected"])} style={{ ...selectorStyle }}></div>
  );
};

export default ActivePageSelector;
