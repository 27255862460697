import React, { useState } from "react";
import cx from "classnames";
import global from "../../scss/dhp.scss";
let style = Object.assign({}, global);
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import PropTypes from "prop-types";
import { Icon } from "./icon";

const MoreOption = ({ options, dropdownClass }) => {
  const [showDropdown, setShowDropdpown] = useState(false);
  const toggle = () => {
    setShowDropdpown(prevState => !prevState);
  };
  const itemSelectFunc = clickFunc => {
    clickFunc();
  };

  return (
    <React.Fragment>
      <UncontrolledDropdown isOpen={showDropdown} toggle={toggle} setActiveFromChild className={dropdownClass}>
        <DropdownToggle tag="a" className="nav-link more-link" caret></DropdownToggle>
        <DropdownMenu className={cx(style["shadow"], style["border-0"], style["rounded"])}>
          {options.map(option => (
            <DropdownItem tag="a" key={option.label} onClick={() => itemSelectFunc(option.clickFunc)}>
              <Icon icon={option.icon} additionalclass="mr-2" /> {option.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </React.Fragment>
  );
};

MoreOption.propTypes = {
  options: PropTypes.array.isRequired,
  dropdownClass: PropTypes.string,
};

export default MoreOption;
